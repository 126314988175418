'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('main', {
    url: '/main',
    templateUrl: 'app/main/main.html',
    controller: 'MainCtrl',
    title: 'Top',
    authenticate: true
  });
}]);
