'use strict';

var app = angular.module('limaApp');

app.controller('DeviceInfoCtrl', ['$scope', '$timeout', '$http', '$translate', '$sce', '$uibModal', 'Auth', 'Modal', 'socket','$window', 'limaOptionService', 'deviceInfo',
function ($scope, $timeout, $http, $translate, $sce, $modal, Auth, Modal, socket,$window, limaOptionService, deviceInfo) {

  $scope.list = deviceInfo.list;
  $scope.list.name = deviceInfo.name;

  $scope.sPreviewLoadingErrorString1 = "";
  $scope.sPreviewLoadingErrorString2 = "";
  $scope.sPreviewLoadingErrorString3 = "";

  $scope.preview = null;
  socket.on('lima:device:capture:preview.error', function (res) {
    if (res.deviceId === $scope.list.deviceId) {
      $timeout(function() {
        $scope.bPreviewLoadingError='err';
        if (res.response) {
          if (res.response.errCode) {
            $scope.sPreviewLoadingErrorString1 = $translate.instant('DEVICE.INFO.CANNOT_CAPTURE1');
            $scope.sPreviewLoadingErrorString2 =  $translate.instant('DEVICE.INFO.CANNOT_CAPTURE2');
            $scope.sPreviewLoadingErrorString3 = $translate.instant('DEVICE.INFO.ERR_CODE') + ':' + res.response.errCode;
            if (res.response.errCode === 1) {
              $scope.sPreviewLoadingErrorString3 = $translate.instant('DEVICE.INFO.ERRCODE_1');
            }
          }
        }
      $scope.bPreviewLoading = false;
        $timeout(function(){
          //5秒後に表示消す
          if ($scope.bPreviewLoadingError.length) {
            $scope.bPreviewLoadingError='';
            $scope.bPreviewLoading = false;
          }
        },5*1000);
      });
    }
  });

  socket.on('lima:device:capture:preview', function (res) {
    if (res.deviceId === $scope.list.deviceId) {
      $timeout(function() {
        $scope.bPreviewLoading = false;
        $scope.previewDateTime = new Date().setTime(res.time);
        $timeout(function(){
          $scope.preview  = res.file;
        });
      });
    }
  });

  socket.on('lima:device:status:filedownload', function (res) {
    if (res.deviceId === $scope.list.deviceId) {
      $timeout(function() {
        $scope.list.filedownloadStatus = res;
        console.log("filedownload " + res);
      });
      if ($scope.list.filedownloadStatus) {
        if ($scope.list.filedownloadStatus.status >= 2) {
          $timeout(function() {
            $scope.list.filedownloadStatus = null;
          } , 3000);
        }
      }
    }
  });

  $scope.packageType = 0;
  limaOptionService.getpackageType(function(result){
    if (result) {
      $scope.packageType = result.packageType;
    }
  });

  $scope.bPreviewLoadingError = '';

  $scope.bPreviewLoading = false;
  $scope.capturePreview = function() {
    $timeout(function() {
      $scope.bPreviewLoadingError='';
      $scope.bPreviewLoading = true;
      $scope.preview = null;
    });
    var devices = [];
    devices.push($scope.list);
    socket.emit('lima:call-request:v1:device:capture',{devices:devices});
    $timeout(function(){
      //1分後にまだ取れてなかったらエラーにする
      if ($scope.bPreviewLoading) {
        $scope.bPreviewLoadingError='error';
        $scope.bPreviewLoading = false;
        $timeout(function(){
          //5秒後に表示消す
          if ($scope.bPreviewLoadingError.length) {
            $scope.bPreviewLoadingError='';
            $scope.bPreviewLoading = false;
          }
        },5*1000);
      }
    },30*1000);
  };

  $scope.canCapturePreview = function () {
    if ($scope.list) {
      var version = $scope.list.version.split('.');
      if (version.length) {
        if (Number(version[0]) >= 10) {
          if ($scope.list.model.toLowerCase().indexOf("xmp") !== -1) {
            return true;
          }
          return false; //Android10以上はキャプチャ出来なくなった
        }
      }
      return true;
    } else {
      return false;
    }
  }


  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }

  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  //pagenation用
  $scope.captureList =
  {
    ajaxPagination: true,
    itemsByPage: 5,  //1ページに表示する数
    start: 0,         //offset
    onSelectPage : function (start) { //ページが選択された
      this.start = start;
    },
    items:[]
  };

  $scope.doCaptureList = function(list) {
    $http.get('/api/v1/device/capture/' + $scope.list.companyId + '/' + $scope.list.deviceId).
    success(function(datas) {
      $timeout(function() {
        $scope.captureList.items = _.filter(datas, function(f){
          f.thumbDateTime = $scope.formatDate($scope.StringToDate(f.dateTime),'YYYY-MM-DD hh:mm');
          if (f.url.indexOf('/preview/') === -1 ) {
            return true;
          }
        });


        // 重複を取り除く処理
        $scope.captureList.items = $scope.captureList.items.filter(function(element, index, self){
          return index === self.findIndex(function(e){
            if (e.thumbDateTime === element.thumbDateTime) {
              //新しい方を採用
              if (e.dateTime >= element.dateTime) {
                return true;
              }
            }
          });
        });
        $scope.totalItems = $scope.captureList.length;

      });
    });
  }
  $scope.doCaptureList();


  $scope.paginate = function(value) {
    var begin, end, index;
    begin = ($scope.currentPage - 1) * $scope.numPerPage;
    end = begin + $scope.numPerPage;
    index = $scope.captureList.indexOf(value);
    return (begin <= index && index < end);
  };

  $scope.isEnableDeviceControl = function(device) {
    if (device.brand.toLowerCase() === 'amazon' ) {
      if (device.model.indexOf("AFTN") !== -1) {
        return false;
        //Log.v(TAG, "はい、これはFire TV第3世代デバイスです");
      } else if ( (device.model.indexOf("AFT") !== -1)) {
        return false;
  //      Log.v(TAG, "はい、これはFire TVデバイスです。");
      }
    }

    return true;
    // Log.v(TAG, "いいえ、これはFire TVデバイスではありません");

  }


  $scope.controlLists = [];
  var getControlLists = function() {
    $http.get('/api/v1/controls').
    success(function(datas) {
      $scope.controlLists.items = datas.controls;
    });

  };
  getControlLists();

  $scope.doControlDevice = function(funcName, displayName){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant(displayName) + "を行います。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) {
        return;
      }

      //$scope.controlLists.selected
      var arrParam = [];
      var  selectedControl = _.find($scope.controlLists.items, function(list){
        return list.controlName === funcName;
      });

      if ($scope.list.deviceId){
        var param = {
          control:selectedControl,
          deviceId:$scope.list.deviceId
        }
        arrParam.push(param);
      }

      socket.emit('lima:call-request:v1:device:control',{data:arrParam});
    });
  }
  $scope.isEnableConnectId = function(list){
    if (!list.socketId.length) {
      if (list.firebaseToken) {
        if (list.firebaseToken.length) {
          return true;
        }
      }
      return false;
    }
    return true;
  }
}]);
