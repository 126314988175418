'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('manual', {
    url: '/system/manual',
    templateUrl: 'app/system/manual/manual.html',
    controller: 'SystemManualCtrl',
    authenticate: true,
    functionName: 'SystemMonitoring',
    title: 'マニュアル'
  });
}]);
