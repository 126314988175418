'use strict';

var app = angular.module('limaApp');

app.controller('ChannelCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$location','$window','$uibModal', 'Modal','$q', '$translate', 'calendarConfig',
function ($scope, socket, Auth,$http, $timeout,$location,$window,$modal, Modal, $q, $translate, calendarConfig) {



  $scope.layout = [];
  $http.get('/api/v1/layouts').
  success(function(datas) {
    $scope.layout = datas.layouts;
  });
  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  $scope.vm = this;

  // calendarConfig.templates.calendarMonthCell = 'groupedMonthEvents.html';
  calendarConfig.templates.calendarDayView = "app/channel/calendarDayView3.html";

//  calendarConfig.dateFormatter = 'moment';
  calendarConfig.dateFormatter = 'angular';
//  calendarConfig.allDateFormats.moment.date.hour = 'HH:mm';
  calendarConfig.allDateFormats.angular.date.hour = 'HH:mm';
  // $scope.$on('$destroy', function() {
  //   calendarConfig.templates.calendarMonthCell = 'mwl/calendarMonthCell.html';
  // });
  //calendarConfig.dayViewEventWidth = 210;

  //angular.element(document).ready(function ()
  //{
    $scope.vm.dayViewEventWidth = 300;
    $scope.vm.viewMinWidth =180;
    $scope.viewMinWidth =180;

    $scope.vm = {};
    $scope.vm.calendarView = 'day';
    $scope.vm.calendarTitle = '';
    $scope.vm.viewDate = new Date();
    $scope.vm.cellIsOpen = true;
    $scope.vm.events = [];
    $scope.vm.dayViewStart = new Date();
    $scope.vm.dayViewEnd = _.cloneDeep($scope.vm.dayViewStart);//$scope.vm.dayViewStart.setMinutes( $scope.vm.dayViewStart.getMinutes()+6*60);
    $scope.vm.dayViewEnd.setMinutes($scope.vm.dayViewStart.getMinutes()+12*60);
    $scope.vm.dayViewStartString = $scope.formatDate($scope.vm.dayViewStart,'hh:00');
    $scope.vm.dayViewEndString = $scope.formatDate($scope.vm.dayViewEnd,'hh:00');
    if ( $scope.vm.dayViewEnd.getHours() <= $scope.vm.dayViewStart.getHours() ) {
      $scope.vm.dayViewEndString = "23:59";
    }
    $scope.vm.dayViewStartString = '00:00';
    $scope.vm.dayViewEndString = '23:59';
    $scope.vm.width2='210';
    // $scope.vm.cellModifier = function(cell) {
    //     cell.cssClass = 'custom-template-cell';
    // };

    // vm.cellModifier = function(cell) {
    //   cell.cssClass = 'my-custom-class';
    // }
    // vm.groupEvents = function(cell) {
    //   cell.groups = {};
    //   if (cell.events) {
    //     cell.events.forEach(function(event) {
    //       cell.groups[event.type] = cell.groups[event.type] || [];
    //       cell.groups[event.type].push(event);
    //     });

    //   }
    // };


    $scope.getSelectCss = function(index){
      if (!index) {
        return "padding-left:55px;width:265px;";
      } else {
        return "width:210px;";
      }
    }
    $scope.getCalendarCss = function(index){
      if (!index) {
        return "width:265px;";
      } else {
        return "width:210px;";
      }
    }


  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = [];
  $scope.channelsInfo.selects = [];

  $scope.deleteSchedule = function(scheduleId){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "選択されたスケジュールを削除します。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      //削除

      if (scheduleId) {
        $http.delete('/api/v1/schedules/' + scheduleId).
        success(function(res) {
          $timeout(function(){
            $scope.getSchedule();
          });

        });
      }
    });
  }


  $scope.getSchedule = function (){
    var strCh = '';
    _.forEach( $scope.channelsInfo.selects , function(sel) {
      strCh += sel.channelId;
      strCh += ' ';
    });
    //対象文字列の前後の空白を削除する
    strCh = strCh.trim();

    var ss = new Date($scope.vm.viewDate.toDateString());
    var ee = _.cloneDeep(ss);
    ee.setDate(ee.getDate() + 1);

    //api/v1/schedules/?channelIds=1 2 3&start_date_time=2018-07-05 00:00:00.000&end_date_time=2018-07-05 00:45:00.000
    $http.get('/api/v1/schedules' + '?channelIds=' + strCh + '&start_date_time=' + $scope.formatDate(ss) + '&end_date_time=' + $scope.formatDate(ee)).
    success(function(datas) {
      console.log(datas);

      _.forEach($scope.channelsInfo.selects,function(sel) {
        sel.events = [];
      });
      $timeout(function(){
        console.log("更新1");
        $scope.$apply();

        _.forEach( datas , function(dt) {
          var info = _.find( $scope.channelsInfo.selects , function(sel) {
            return dt.channelId === sel.channelId ? true : false;
          });
          if (info) {
            info.events = []; //初期化
            _.forEach(dt.schedules, function(sc){
              var sh = {};
              sh.title = sc.playlistName;
              sh.startsAt= new Date(sc.startDateTime);
              //sh.startsAt.setMinutes(new Date().getTimezoneOffset());
              sh.endsAt= new Date(sc.endDateTime);
              //sh.endsAt.setMinutes(new Date().getTimezoneOffset());
              sh.type= 'success';
              sh.color= calendarConfig.colorTypes.success;
              sh.cssClass = 'custom-template-cell';
              sh.width=200;
              sh.allDay = false;
              sh.scheduleId = sc.scheduleId;
              sh.actions = [
                {
                  "label": "<i class='glyphicon glyphicon-remove'></i>",
                  "onClick": function(args) {
                    //args : 自分自身の中身
                    console.log("delete " + sh.startsAt);
                    $scope.deleteSchedule(args.calendarEvent.scheduleId);
                  }
                }
              ];
              info.events.push(sh);
            });
          }
        });

        $http.get('/api/v1/channels').
        success(function(limaChs) {
          //limaChs.channels = _.filter(limaChs.channels, function(ch){return ch.groupId ? false : true;});

          _.forEach( $scope.channelsInfo.selects , function(sel,index) {
              var bFindCh = _.find(limaChs.channels,function(ch) {
                if (sel.channelId === ch.channelId) {
                  return true;
                } else {
                  return false;
                }
              });

              var sTitle = "";
              if (bFindCh) {
//                sTitle = "ループ再生チャンネル";
                sTitle = "";
              }
              if (_.isUndefined(sel.events)) {
                sel.events = []; //初期化
                sel.sTitle = sTitle;
                var sh = {};
                sh.title = sTitle ;
                sh.startsAt= new Date();
                sh.endsAt= new Date();
                sh.type= 'warning';
                sh.color= calendarConfig.colorTypes.warning;
                sh.cssClass = 'custom-template-cell';
                sh.width='200px';
                sh.allDay = true;
                sel.events.push(sh);
              } else if (!sel.events.length) {
                sel.sTitle = sTitle;

                sel.events = []; //初期化
                var sh = {};
                sh.title = sTitle ;
                sh.startsAt= new Date();
                sh.endsAt= new Date();
                sh.type= 'warning';
                sh.color= calendarConfig.colorTypes.warning;
                sh.allDay = true;
                sel.events.push(sh);

              }
            });

            $timeout(function(){
              console.log("更新");
              $scope.$apply();
            });
        });
      });
    });

  }


  function getChannels() {
    $scope.channelsInfo.channels = [];
    $http.get('/api/v1/channels').
    success(function(datas) {
     //datas.channels = _.filter(datas.channels, function(ch){return ch.groupId ? false : true;});
      $scope.channelsInfo.channels = datas.channels;
      _.forEach($scope.channelsInfo.channels, function(ch) {
        // $http.get('/api/v1/lima/layout/dcm/' + ch.channelId + '/layout').
        // success(function(chInfo)
        {
//          ch.chInfo = chInfo;
          ch.events = [];
          var maxCount = $scope.channelsInfo.channels.length;
          if (maxCount > 3) {
            maxCount = 3;
          }
          if ($scope.channelsInfo.selects.length <= 3) {
            $scope.channelsInfo.selects.push(ch);
            if ($scope.channelsInfo.selects.length === maxCount) {
              $scope.getSchedule();
            }
          }
        }
        //);




      });
    });

  }
  getChannels();

  $scope.createChannel = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/channel/add/addChannel.html',
      controller: 'AddChannelCtrl',
      windowClass: 'modal-addchannel-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaModalAddChannel: function() {
          return {
            name : "新しいチャンネル"
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
      var postdata = {
        name    : results.name   ,
        target  : "ME-DEC" , //ME-DEC
      };
      $http.post('/api/v1/channels' , postdata)
      .success(function(res) {
        $timeout(function(){
          getChannels();
        });

      });
    }, function() {
      //閉じられた
//        console.log("aaa");
      $timeout(function(){

      });
    });

  }

  $scope.clickChannelLink = function(channelId){
    return $location.protocol() + '://'+ $location.host() + ":" + $location.port() +　"/layout/channel_" + channelId + "/channel.html";
  }


  $scope.clickLink = function(channelId , layoutId){
    return $location.protocol() + '://'+ $location.host() + ":" + $location.port() +　"/layout/channel_" + channelId + "/layout_" + layoutId + "/layout.html";
  }

  $scope.deleteLayout = function(){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "選択されたチャンネルを削除します。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      //削除
      _.forEach($scope.channels, function(ch) {
        var putdata = {};
        putdata.layout = [];
        _.filter(ch.chInfo.layout, function(ll){
          if (!ll.checked) {
            var ll2 = _.cloneDeep(ll);
            delete ll2['checked'];
            putdata.layout.push(ll2);
          }
          return !ll.checked;
        });

        // $http.put('/api/v1/lima/layout/dcm/' + ch.channelId + '/layout' , putdata).
        // success(function(res) {

        // });

      });
      getChannels();

    });
  };




  $scope.calendarView = 'day';
  $scope.viewDate =  new Date();
  var dd_s = new Date();
  var dd_e = new Date();
  dd_e.setMinutes(dd_e.getMinutes() + 120);
  // $scope.events = [
  //   {
  //     title: 'スケジュール１', // The title of the event
  //     startsAt: dd_s, // A javascript date object for when the event starts
  //     endsAt: dd_e , // Optional - a javascript date object for when the event ends
  //     color: { // can also be calendarConfig.colorTypes.warning for shortcuts to the deprecated event types
  //       primary: '#e3bc08', // the primary event color (should be darker than secondary)
  //       secondary: '#fdf1ba' // the secondary event color (should be lighter than primary)
  //     }],
  //     draggable: true, //Allow an event to be dragged and dropped
  //     resizable: true, //Allow an event to be resizable
  //     incrementsBadgeTotal: true, //If set to false then will not count towards the badge total amount on the month and year view
  //     recursOn: 'year', // If set the event will recur on the given period. Valid values are year or month
  //     cssClass: 'a-css-class-name', //A CSS class (or more, just separate with spaces) that will be added to the event when it is displayed on each view. Useful for marking an event as selected / active etc
  //     allDay: false // set to true to display the event as an all day event on the day view
  //   }

  // ];

  //$scope.vm = this;

  // calendarConfig.templates.calendarMonthCell = 'groupedMonthEvents.html';
  //calendarConfig.templates.calendarDayView = "app/channel/calendarDayView2.html";
  // $scope.$on('$destroy', function() {
  //   calendarConfig.templates.calendarMonthCell = 'mwl/calendarMonthCell.html';
  // });


  //angular.element(document).ready(function ()
  // {


  //   $scope.vm.events = [
  //     {
  //     //   title: 'Event 1',
  //     //   type: 'warning',
  //     //   color: calendarConfig.colorTypes.warning,
  //     //   startsAt: new Date(),
  //     //   endsAt: dd_e,
  //     //   draggable: true, //Allow an event to be dragged and dropped
  //     //   resizable: true
  //     // }, {
  //     //   title: 'Event 2',
  //     //   type: 'info',
  //     //   color: calendarConfig.colorTypes.info,
  //     //   startsAt: new Date()
  //     // }, {
  //     //   title: 'Event 3',
  //     //   type: 'info',
  //     //   color: calendarConfig.colorTypes.info,
  //     //   startsAt: new Date()
  //     // }, {
  //     //   title: 'Event 4',
  //     //   type: 'danger',
  //     //   color: calendarConfig.colorTypes.important,
  //     //   startsAt: new Date()
  //     // }, {
  //       title: 'Event 5',
  //       type: 'success',
  //       color: calendarConfig.colorTypes.success,
  //       startsAt: new Date()
  //     }
  //   ];

  //   $scope.vm.calendarView = 'day';
  //   $scope.vm.calendarTitle = "スケジュール２";

  //   $scope.vm.viewDate = new Date();
  //   $scope.vm.cellIsOpen = true;
  //   // $scope.vm.cellModifier = function(cell) {
  //   //   cell.cssClass = 'my-custom-class';
  //   // }

  //   $scope.vm.groupEvents = function(cell) {
  //     cell.groups = {};
  //     if (cell.events) {
  //       cell.events.forEach(function(event) {
  //         cell.groups[event.type] = cell.groups[event.type] || [];
  //         cell.groups[event.type].push(event);
  //       });

  //     }
  //   };

  // }
  // //);

  $scope.createSchedule = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/playlist.html',
      controller: 'PlaylistPopupCtrl',
      windowClass: 'modal-preview-window',
      backdrop: 'static', keyboard: false,
      size: 'lg',
      resolve: {
        // LimaPlaylistModalContentData: function() {
        //   return {
        //     playlistId : undefined
        //   };
        // }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
      // $timeout(function(){

      //   $scope.playlists = [];
      //   $scope.getPlaylist();
      // });

    }, function() {
      //
      $timeout(function(){
        $scope.getSchedule();
      });
    });
  }
}]);


