'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('editDeviceGroup', {
    url: '/deviceGroup/edit',
    templateUrl: 'app/deviceGroup/editGroup/editGroup.html',
    controller: 'EditDevicegroupCtrl',
    authenticate: true,
    functionName: 'UserGroupManagement',
    title: 'Edit Device Group'
  });
}]);
