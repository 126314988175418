'use strict';

var app = angular.module('limaApp');

app.controller('EditgroupCtrl', ['$scope', '$location', '$translate', 'Modal', 'Auth', '$uibModalInstance', 'socket', 'editUserGroupId',
function ($scope, $location, $translate, Modal, Auth, $modalInstance, socket, editUserGroupId) {

  socket.connect().then(function () {
    return socket.call('v1:user:role:findAll');
  }).then(function (res) {
    $scope.roles = res;
    return socket.call('v1:user:group:findOne', {groupId:editUserGroupId});
  }).then(function (res) {
    $scope.editgroup = res;
    $scope.selectedRole = res.roles[0].id;
  })

  $scope.updateGroup = function(groupId) {
    $scope.editgroup.roles = [];
    $scope.editgroup.roles.push({id:$scope.selectedRole});
    socket.connect().then(function () {
      return socket.call('v1:user:group:update', {groupId:groupId, group:$scope.editgroup});
    }).then(function (res) {
      $modalInstance.close();
    }, function (err) {
      if (err.message !== 'cannot remove user management role of self') {
        var msgs = [];
        _.forEach(err.errors, function (error) {
          if (error.stack.match(/instance.group.description does not meet maximum length of \d+/)) {
            msgs.push($translate.instant('USER.USERGROUP_DESCRIPTION_LENGTH_TOO_LONG'));
          } else {
            msgs.push(error.stack);
          }
        });
        $scope.msg_error = (msgs.length) ? msgs.join(', ') : (err.message || err);
        return;
      }
      Modal.confirm.yesno({
        title: $translate.instant('USER.USER_REMOVE_SELF_ROLE_TITLE'),
        html: $translate.instant('USER.USER_REMOVE_SELF_ROLE_MESSAGE'),
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        socket.connect().then(function () {
          return socket.call('v1:user:group:update', {groupId:groupId, group:$scope.editgroup, force:true});
        }).then(function (res) {
          return Auth.updateCurrentUser();
        }).then(function (res) {
          return $modalInstance.close();
        }).then(function (res) {
          LimaRedirect.go({path:'/', clearCache:true});
        }, function (err) {
          $scope.msg_error = err.message || err;
        });
      });
    });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
