'use strict';

angular.module('limaApp')
.controller('TranscodelistCtrl', ['$scope', '$stateParams', '$http', '$timeout', '$translate', '$localStorage', '$window', '$uibModal', '$q', '$filter', 'Auth', 'Modal', 'socket',
function($scope, $stateParams, $http, $timeout, $translate, $localStorage, $window, $modal, $q, $filter, Auth, Modal, socket) {
  $scope.storage = (function () {
    var key = 'limaApp:TranscodelistCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};
    var store = function () {
      $localStorage[key] = storage;
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
      angular.element($window).off('resize', onResizeA);
    });
    return storage;
  })();

  //pagenation用
  $scope.transcodelist =
  {
    ajaxPagination: true,
    itemsByPage: 15,  //1ページに表示する数
    start: 0,         //offset
    onSelectPage : function (start) { //ページが選択された
      this.start = start;
      $scope.transcode.status(start/this.itemsByPage);
    },
    checkedListAll: false
  };

  var formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  $scope.FormatTime = (function(time) {
    return formatDate(new Date(time) , 'YYYY/MM/DD hh:mm:ss');
  });

  $scope.checkedlist = [];
  $scope.checkedEndlist = [];
  $scope.UpdateCheckedList = (function() {
    $scope.checkedlist = _.filter($scope.transcodelist.items, {checked:true, status:{status:'start'}} );
    $scope.checkedEndlist = _.filter($scope.transcodelist.items, function(item){
      if (item.checked) {
        if (item.status.status !== 'start') {
          return true;
        }
      }
      return false;
    });
  });
  //Check all
  $scope.doCheckTranslistAll = function() {
    $scope.transcodelist.items = _.forEach($scope.transcodelist.items , function (v) {
      v.checked = $scope.transcodelist.checkedListAll;
    });
    $scope.UpdateCheckedList();
  };

  $scope.contentslist = [];

  $scope.transcode = (function() {

    var statusUpdateTimer = null;
    var statusUpdateDelay = 1000;
    var lastTranscodesLength = 0;
    var isEnableAbort = false;

    function confirmDelete() {
      var defer = $q.defer();

      Modal.confirm['delete'](function (yesno) {
        return !yesno ? defer.reject() : defer.resolve();
      })($translate.instant('CONTENT.TRANSCODE_LIST.CONFIRM_DELETE_TEXT'), 'no');
      return defer.promise;
    }

    function deletelist() {
      var promise = confirmDelete();
      promise.then(
        function() { //成功
          //削除
          var arrTran = _.filter($scope.transcodelist.items, {checked:true} );

          if ( arrTran.length ) {
            _.forEach(arrTran, function(elem){
              $http.delete('/api/version_2_0/content/transcodes/list/' + elem._id ).success(function () {

                $timeout(function(){
                  status();
                  $scope.transcodelist.checkedListAll = false;
                });
              });
            });
          }
        },
        function() { //失敗
          //なにもしない
        }
      );
    }

    function status(pageIndex) {
      if (_.isUndefined(pageIndex)) {
        pageIndex = $scope.transcodelist.start / $scope.transcodelist.itemsByPage;
      }

      $http.get('/api/version_2_0/content/transcodes/list?count').success(function (count) {
        $scope.transcodelist.count = count;
        $scope.numberOfEventLogPages = Math.min(Math.ceil(count / $scope.transcodelist.itemsByPage), 100);
      });

      if (statusUpdateTimer) $timeout.cancel(statusUpdateTimer);
      statusUpdateTimer = null;
      //if (contentId)
      {
        $http.get('/api/version_2_0/content/transcodes/list?offset=' + pageIndex * $scope.transcodelist.itemsByPage + '&limit=' + $scope.transcodelist.itemsByPage).success(function (arr) {
          var arrlist = arr || [];

          var startElem = _.find(arrlist, {status : { status: 'start' }} ); //進行中のものがあるかどうか
          if (!startElem) {
            startElem = _.find(arrlist, {status : { status: 'finishing' }} ); //終了処理中のものがあるかどうか
          }
          if (!startElem) {
            startElem = _.find(arrlist, {status : { status: 'aborting' }} ); //キャンセル処理中のものがあるかどうか
          }

          if (startElem) {
            statusUpdateTimer = $timeout(function () { $scope.transcode.status(pageIndex); }, statusUpdateDelay);
            $scope.transcode.IsEnableAbort = true;
          } else {
            $scope.selectColumnInfo = '';
            $scope.transcode.IsEnableAbort = false;
          }
          lastTranscodesLength = arrlist.length;

           _.forEach(arrlist, function(obj) {
              var obj_trans = _.find($scope.transcodelist.items, {status : { starttime: obj.status.starttime }} );
              if (obj_trans) {
                obj.checked = !obj_trans.checked ? false : obj_trans.checked;
              }
              $http.get('/api/version_2_0/content/contents/' + obj.task.contentId).success(function (content) {
                if (!_.find($scope.contentslist, function(listcont){ return listcont.id === content.id ? true : false; })) {
                  $scope.contentslist.push(content);
                }
              });
          });

          $scope.transcodelist.items = arrlist;
          $scope.UpdateCheckedList();

        }).error(function (err) {
          //console.log('failed to get transodings: ' + err);
          lastTranscodesLength = 0;
          $scope.transcodings = null;
          $scope.transcoded = null;
          $scope.selectColumnInfo = '';
        });
      }
    }

    function abort() {
      var arrTran = _.filter($scope.transcodelist.items, {checked:true} );

      if ( arrTran.length ) {
        Modal.confirm.yesno({
          title: $translate.instant('CONTENT.TRANSCODE.ABORT.TITLE'),
          html: $translate.instant('CONTENT.TRANSCODE.ABORT.MESSAGE'),
          btnClassYes: 'btn-danger',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-default',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {

          if (yes) {
            arrTran.forEach(function(element) {
              $http({method: 'delete', url: '/api/version_2_0/content/transcodes/' + element.task.unique});
            });
          }
        });
      }
    }
    return {
      abort: abort,
      status: status,
      deletelist: deletelist
    };
  })();

$scope.load = function() {
//    alert('Window is loaded');
  $scope.transcode.status(0);
}

$scope.getContentTitle = function(id) {
  var item =_.find($scope.contentslist, function(listcont){ return listcont.id === id ? true : false; });
  if (item) {
    return item.title;
  }
  return $translate.instant('PLAYER.CONTENTS_NOT_FOUND');
}
//////////////////////////////////////////////////////////////

  $scope.onResize = (function() {
  });

  function onResizeA() {
    $scope.onResize();
    $scope.$apply();
  }

  angular.element($window).on('resize', onResizeA);

  $scope.onResize();

  function fullBasename(path) {
    var result = '';
    if (path) {
      var paths = path.split('/');
      if (paths.length === 1) { //分割されてない
        paths = path.split('\\');
      }
      result = paths.pop();
    }
    return result;
  }

  $scope.basename = function(path) {
    var result = fullBasename(path).replace(/[\?#].*$/g, '');
    return result;
  };

  $scope.selectColumnStartTime = 0;
  $scope.selectColumnInfo = '';

  $scope.isError = function(status) {
    if (status === 'error') {
      return true;
    }
    return false;
  };


  $scope.getStatusString = function(status) {
    if (status.status === 'start') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.START');
    }
    else if (status.status === 'finished') {
      return $translate.instant('COMMON.DONE');
    }
    else if (status.status === 'finishing') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.FINISHING');
    }
    else if (status.status === 'abort') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.ABORT');
    }
    else if (status.status === 'aborting') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.ABORTING');
    }
    else if (status.status === 'error') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.ERR') + '(' + status.err + ')';
    }
    return status.status;
  };


  function createSelectColumnInfo(transobj)
  {
    $scope.selectColumnInfo = [];

    $scope.selectColumnInfo.push({title: '[' + $translate.instant('CONTENT.VIDEO_EDIT.BTN_TRANSCODE') + ']',
                                  value: ''});

    $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.STARTTIME'),
                                  value: $scope.FormatTime(transobj.status.starttime )});

    $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.ENDTIME'),
                                  value: (!_.isUndefined(transobj.status.endtime)) ? $scope.FormatTime(transobj.status.endtime ) : ''});

    // $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.CONTENT_ID'),
    //                               value: transobj.task.contentId});

    $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.SRC'),
                                  value: $scope.basename(transobj.task.infile)});

    $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.DEST'),
                                  value: $scope.basename(transobj.task.outfile)});

    $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.TITLE_STATUS'),
                                  value: $scope.getStatusString(transobj.status.status)});

    if (transobj.status.err) {
      $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.ERR'),
                                    value: transobj.status.err});
    }

    _.forEach(transobj.posttask, function(val){

      if (val.id==='new') {
        $scope.selectColumnInfo.push({title: '[' + $translate.instant('CONTENT.TRANSCODE_LIST.POST_TASK') + ']',
                                      value: $translate.instant('CONTENT.TRANSCODE_LIST.TASK_ADD_CONTENT')});
      }
      else if (val.id==='Archive') {
        $scope.selectColumnInfo.push({title: '[' + $translate.instant('CONTENT.TRANSCODE_LIST.POST_TASK') + ']',
                                      value: $translate.instant('CONTENT.TRANSCODE_LIST.TASK_ARCHIVE')});
      }
      else  {
        $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.POST_TASK'),
                                      value: val.id});
      }
      if (val.err) {
        if (_.isString(val.err)) {
          $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.TITLE_STATUS'),
                                        value: $translate.instant('CONTENT.TRANSCODE_LIST.ERR') + ' (' + val.err + ')'});
        } else {
          $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.TITLE_STATUS'),
                                        value: $translate.instant('CONTENT.TRANSCODE_LIST.ERR')});
        }
      } else {
        $scope.selectColumnInfo.push({title: $translate.instant('CONTENT.TRANSCODE_LIST.TITLE_STATUS'),
                                      value: $translate.instant('COMMON.DONE')});
      }
    });


  }

  $scope.selectcolumn = function(transobj) {
    $scope.selectColumnStartTime  = transobj.status.starttime;

    createSelectColumnInfo(transobj);
  };

  function IsExistContent(id) {
    var defer = $q.defer();

    var req2 = {method:'get', url:'/api/version_2_0/content/contents', params:{contentId:id}};
    $http(req2).then(function (res2) {
      defer.resolve();
    },
    function(){  //err
      defer.reject();
    });
    return defer.promise;
  }

  $scope.canPopupContent = function(transobj) {
    var item = _.find(transobj.posttask, function(val){ return val.id==='new' ? true : false; });
    if (item) {
      if (item.contentId) {
        return true;
      }
    } else if (!transobj.posttask) {  //コンテンツ内でのトランスコード
      if (transobj.status.status === 'finished'){
        return true;
      }
    }
    return false;

  };

  //トランスコードコンテンツ一覧画面をポップアップで開く
  $scope.popupContent = function(transobj) {
    var item = _.find(transobj.posttask, function(val){ return val.id==='new' ? true : false; });
    var contentId;
    var fileId;
    if (item) {
      if (item.contentId) {
        contentId = item.contentId;
      }
    } else if (!transobj.posttas) {  //コンテンツ内でのトランスコード
      if (transobj.status.status === 'finished'){
        contentId = transobj.task.contentId;
        fileId = transobj.task.unique; // actually, task.unique is identical to added fileId
      }
    }
    if (contentId) {
      var contents = [{contentId:contentId, fileId:fileId}];

      var modalInstance = $modal.open({
        templateUrl: 'app/content/schedule/schedule-content.html',
        controller: 'ScheduleContentCtrl',
        windowClass: 'schedule-content-modal-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          RafaleScheduleContentData: function() {
            return {
              data: contents
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
      }, function() {
        //
      });
    }
  }
}]);

