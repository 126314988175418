'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('userGroup', {
    url: '/userGroup',
    templateUrl: 'app/userGroup/userGroup.html',
    controller: 'UserGroupCtrl',
    authenticate: true,
    functionName: 'UserManagement',
    title: 'User Groups'
  });
}]);
