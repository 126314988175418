'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('modalMaterialProperty', {
    url: '/content/modalProperty',
    templateUrl: 'app/content/modalProperty.html',
    controller: 'ModalMaterialPropertyCtrl',
    title: 'Top',
    authenticate: true
  });
}]);
