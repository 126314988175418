'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('status', {
    url: '/system/status',
    templateUrl: 'app/system/status/status.html',
    controller: 'SystemStatusCtrl',
    authenticate: true,
    functionName: 'SystemMonitoring',
    title: 'System Status'
  });
}]);
