'use strict';

var app = angular.module('limaApp');


app.controller('NewNameCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$location','$cookies','$uibModalInstance','$translate','Modal','LimaNewNameData',
function($scope, socket, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,$translate, Modal,LimaNewNameData) {


  var maxStringLength = LimaNewNameData.maxStringLength;
  if (_.isUndefined(maxStringLength)){
    maxStringLength =127; //デフォルト127文字
  }

  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }
  $scope.formInput={};
  $scope.formInput.inputname=LimaNewNameData.name;
  $scope.apply = function () {
    if ($scope.formInput.inputname.length > maxStringLength) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:$translate.instant('PLAYLIST.DLG_NAME.MSG_OVER_MAXLENGTH',{length:maxStringLength}),
      modalClass:'modal-danger'});
      return;
    }

    $modalInstance.close({name:$scope.formInput.inputname});
    $modalInstance.dismiss('done');
    //$dismiss();
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }

}]);
