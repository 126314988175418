'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('local', {
    url: '/upload',
    templateUrl: 'app/content/local.html',
    controller: 'LocalCtrl',
    title: 'コンテンツ一覧',
    authenticate: true
  });
}]);
