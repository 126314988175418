'use strict';

var app = angular.module('limaApp');

app.controller('UserGroupCtrl', ['$scope', '$location', '$translate', 'Modal', 'Auth', '$uibModal', 'LimaRedirect', 'socket', '$window',
function ($scope, $location, $translate, Modal, Auth, $modal, LimaRedirect, socket, $window) {

  $window.document.title  = $translate.instant('NAVIGATION.USER_GROUPS');

  $scope.groups = {};
  var updateUserGroupInfo = function () {
    socket.connect().then(function () {
      return socket.call('v1:user:group:findAll');
    }).then(function (res) {
      $scope.groups = res || [];
      $scope.numberOfUserPages = Math.ceil($scope.groups.length / 10);
      _.forEach($scope.groups, function (group) {
        var dispRoles = '';
        _.forEach(group.roles, function (role) {
          dispRoles += '[ ' + role.name + ' ] ';
        });
        group.dispRoles = dispRoles;
      });
    });
  };
  updateUserGroupInfo();

  $scope.isEditable = function (group) {
    return group && _.isString(group.id) && group.id.substr(0,1) !== '-';
  };

  $scope.deleteGroup = function(group) {
    Modal.confirm['delete'](function (yes) {
      if (!yes) return;
      socket.connect().then(function () {
        return socket.call('v1:user:group:delete', {groupId:group.id});
      }).then(function (res) {
        $scope.msg_success = 'deleted group : ' + group.name;
        updateUserGroupInfo();
      }, function (err) {
        var msg = _.get(err, 'message');
        if (msg !== 'cannot remove user management role of self') {
          $scope.msg_error = msg;
          return;
        }
        Modal.confirm.yesno({
          title: $translate.instant('USER.USER_REMOVE_SELF_ROLE_TITLE'),
          html: $translate.instant('USER.USER_REMOVE_SELF_ROLE_MESSAGE'),
          btnClassYes: 'btn-danger',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-default',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;
          socket.connect().then(function () {
            return socket.call('v1:user:group:delete', {groupId:group.id, force:true});
          }).then(function (res) {
            return Auth.updateCurrentUser();
          }).then(function (res) {
            LimaRedirect.go({path:'/', clearCache:true});
          }, function (err) {
            err = err.message || err;
            $scope.msg_error = err;
          });
        });
      });
    })(group.name, 'yes');
  };

  $scope.addGroup = function (event) {
    var modalInstance = $modal.open({
      templateUrl: 'app/userGroup/addGroup/addGroup.html',
      controller: 'AddgroupCtrl',
      backdrop: 'static', keyboard: false,
      resolve: { }
    });
    modalInstance.result.then(function (results) { // OK
      updateUserGroupInfo(); // update the current userGroup list
    }, function () { // cancel
    });
  };

  $scope.editGroup = function (userGroup) {
    //$event.stopPropagation();
    var modalInstance = $modal.open({
      templateUrl: 'app/userGroup/editGroup/editGroup.html',
      controller: 'EditgroupCtrl',
      backdrop: 'static', keyboard: false,
      resolve: {
        editUserGroupId: function() {
          return userGroup.id;
        }
      }
    });
    modalInstance.result.then(function (results) { // OK
      updateUserGroupInfo(); // update the current userGroup list
    }, function () { // cancel
    });
  };

}]);
