'use strict';

var app = angular.module('limaApp');

app.controller('SettingDeviceCtrl', ['$scope', '$location', '$http', '$translate', '$uibModal', 'Auth', 'socket','$window','$timeout','Modal',
function ($scope, $location, $http, $translate, $modal, Auth, socket,$window, $timeout, Modal) {

  $window.document.title  = $translate.instant('NAVIGATION.DEVICESETTING');

  $scope.deviceSetting = {type:'background'};

  $scope.playConfig = {};
  $scope.playConfig.types = [
    {type:0 , value:'ダウンロードしながら再生'},
    {type:1 , value:'ダウンロード終わってから再生'},
    //{type:2 , value:'ダウンロードしない'},
  ];
  $scope.playConfig.selecttype = $scope.playConfig.types[0];

  $scope.background = {};
  $scope.background.types = [
                              {type:'none' , value:$translate.instant('DEVICE.SETTING.COLOR_DEFAULT')},
                              {type:'color_black' , value:$translate.instant('DEVICE.SETTING.COLOR_BLACK')},
                              {type:'custom' , value:$translate.instant('DEVICE.SETTING.COLOR_IMAGE'), files:{}},
                            ];
  $scope.background.selecttype = $scope.background.types[0];
  $scope.background.messageEnable = true;

  $scope.userinfo = {};
  $http.get('/api/v1/users/me').
  success(function(datas) {
    $scope.userinfo=datas;
  });


  $http.get('/api/v1/device/config').
  success(function(datas) {
    if (datas && datas.length) {
      var dataJson = datas;
      if (datas && !_.isArray(datas)) {
        dataJson = JSON.parse(datas);
      }
      $timeout(function() {
        _.forEach(dataJson , function(dt){
          var value = dt.value;
          if (dt.value && !_.isArray(dt.value)) {
            value = JSON.parse(dt.value);
          }
          if (dt.id === '-background') {
            $scope.background.messageEnable = value.messageEnable;
            if (value.type === 'custom') {
              $scope.background.selecttype = $scope.background.types[2];
              $scope.background.types[2].files.imageUrl = value.imageUrl;
              $scope.background.types[2].files.name = value.name;
            }
            if (value.type === 'color') {
              $scope.background.selecttype = $scope.background.types[1];
            }
          }
          else if (dt.id === '-play') {
            $scope.playConfig.selecttype = _.find($scope.playConfig.types , function(vl){
              if (vl.type === value.type) {
                return true;
              }
            });
          }
        });

      });
    }
  });

  $scope.doCheckMessage = function(enable){
    $scope.background.messageEnable = enable;
  }

  $scope.selectFile = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/device/setting/img-upload-dialog.html',
      controller: 'ThumbnailDialogCtrl',
      backdrop: 'static', keyboard: false,
      resolve: {
      //  content: function() {return content;}
      }
    });
    modalInstance.result.then(function (results) { // OK
      console.log(results);
      $scope.background.selecttype.files = results;
    }, function (results) { // cancel
      console.log(results);
      //content.imageUrl_ = content.imageUrl + '?' + new Date().getTime();
      //content.thumbnailUrl_ = content.thumbnailUrl + '?' + new Date().getTime();
      //$scope.selectedContent.imageUrl_ = $scope.selectedContent.imageUrl + '?' + new Date().getTime();
    });

  }

  $scope.message = {CreateSuccess:''};
  function TriggerAlertClose() {
    window.setTimeout(function () {
        $("#message1").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateSuccess = "";
        });
    }, 3000);
  }

  $scope.apply = function() {
    var postdata = [];
    var data = {};
    var value = {};
    if ($scope.background.selecttype.type === 'none'){
      value.type = $scope.background.selecttype.type;
      value.imageColor = null;
      value.imageUrl = null;
    }
    else if ($scope.background.selecttype.type === 'color_black'){
      value.type = 'color';
      value.imageColor = 0;
      value.imageUrl = null;
    }
    else if ($scope.background.selecttype.type === 'custom'){
      value.type = 'custom';
      value.imageColor = null;
      value.imageUrl = $scope.background.selecttype.files.imageUrl;
      value.name = $scope.background.selecttype.files.name;
      if (!value.imageUrl) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('DEVICE.SETTING.MESSAGE_NO_IMAGE'), modalClass:'modal-warning'});
        return;
      }

    }
    value.messageEnable = $scope.background.messageEnable;

    data.id = '-background';
    data.name = 'config.background';
    data.value = value;
    postdata.push(data);

    //'play'
    value = {};
    data = {};
    data.id = '-play';
    data.name = 'config.play';
    data.value = {type:$scope.playConfig.selecttype.type};
    postdata.push(data);

    $http.post('/api/v1/device/config' , postdata).
    success(function(datas) {
      socket.emit('lima:call-request:v1:device:config',postdata);

      $scope.message.CreateSuccess = $translate.instant('PLAYLIST.MESSAGE_NOTIFIED_REFLECTION');
      TriggerAlertClose();

    });
  }


}]);
