'use strict';

var app = angular.module('limaApp');

app.controller('OptionCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$location','$cookies','$q','$uibModal','Modal','$translate',
function($scope, socket, Auth, $http, $timeout, $location, $cookieStore, $q,$modal, Modal,$translate) {

  function GetFolderName(FileTypeID){
    var sDCMFolder=[
      {
        "FileTypeID": 4,
        "FolderName":"Telop"
      },
      {
        "FileTypeID": 16,
        "FolderName":"HTML"
      },
      {
        "FileTypeID": 128,
        "FolderName":"QuickTime"
      },
      {
        "FileTypeID": 2,
        "FolderName":"Still"
      }
    ];


    var res = _.find(sDCMFolder, function(folder) {
      if (folder.FileTypeID === FileTypeID) {
        return true;
      }
    });
    if (res) {
      return res.FolderName;
    }
    return "";
  }
  var startTime = new Date();

  $scope.tweetInfo = [];
  var arrMaterials = [];
  function getMaterials() {
    $http.get('/api/v1/content/contents').
    success(function(datas) {

      var endTime = new Date();
      endTime = endTime.getTime();

      console.log("######doingSearch 1-1 " + (endTime - startTime));


      var materials = [];
        _.forEach(datas , function(data){
          _.forEach(data , function(item){
              materials.push(item);
          });
        });
          // "videos"
          // "images": [],
          // "audios": [],
          // "HTMLs": [],
          // "telops": []
          materials = _.sortBy(materials, function(item) {
            return new Date(item.TimeStamp);
          }).reverse();

          _.filter(materials , function(item){
            {
              if (parseInt(item.MaterialID) <= 23) {
                return false;
              }
              var foldername = GetFolderName(item.FileTypeID);
              if (!foldername.length) {
                return false;
              }

              var item_url;
              var thumb;
              var item_name = item.MaterialName;
              if (item.U_LocalPath) {
                item.U_LocalPath = item.U_LocalPath.replace(foldername+'\\', '');
                item_url = "\\assets\\" + item.U_LocalPath;
                thumb = item_url.replace( item_name,  'derivatives\\' + item.MaterialID + '.jpg');
              } else {
                if (item.FileTypeID === 16) { //html
                  item_url = item.materialurl;
                } else {
                  return false; //よめないかな。とりあえず
                }
              }

              if (item.FileTypeID === 2){  //STILL
                    $scope.tweetInfo.push(
                    {
                      thumbnail:thumb,
                      name : item_name,
                      id:item.MaterialID,
                      type:item.FileTypeID,
                      image:"",
                      photo:item_url,
                      text:'',
                      text_no_hash:'',
                      checked:false

                  });

                  return true;
              }
            }
          });

          endTime = new Date();
          endTime = endTime.getTime();

          $scope.doingSearch = false;
          console.log("######doingSearch 0 " + (endTime - startTime));

        });

  }

  getMaterials();

  function toBlob() {
    var canvas = document.getElementById('canvas-h');
    if (!canvas || !canvas.getContext) {
      return null;
    }
    var base64 = canvas.toDataURL('image/png');
    // Base64からバイナリへ変換
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    var blob = new Blob([buffer.buffer], {
        type: type
    });
    return blob;
  }
  function toBinary() {
    var canvas = document.getElementById('canvas-h');
    if (!canvas || !canvas.getContext) {
      return null;
    }
    var base64 = canvas.toDataURL('image/png'),
        bin = atob(base64.replace(/^.*,/, '')),
        buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    return buffer;
}

$scope.SelectedBackground = {
  color:"#FFF",
  image:"",
  imageInfo:null
};

$scope.selectContent = function(type) {
  var modalInstance = $modal.open({
    templateUrl: 'app/content/content.html',
    controller: 'ContentModalCtrl',
    windowClass: 'modal-content-window',
    backdrop: 'static', keyboard: false,
    size: 'lg',
    resolve: {
      LimaModalContentData: function() {
        return {
          type:type
        };
      }
    }
  });
  modalInstance.result.then(function(results) {
    //選択
    if (type === 'image') {
      $scope.SelectedBackground.color="";
      $scope.SelectedBackground.image = results.photo;
      $scope.SelectedBackground.imageInfo = new Image();

      $scope.SelectedBackground.imageInfo.crossOrigin = 'anonymous';
      $scope.SelectedBackground.imageInfo.onload = function(){
        ////
      };
      $scope.SelectedBackground.imageInfo.src = $scope.SelectedBackground.image;


    }
  }, function() {
    //
  });
}

var formatDate = function (date, format) {
  if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
  format = format.replace(/YYYY/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  if (format.match(/S/g)) {
    var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
    var length = format.match(/S/g).length;
    for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
  }
  return format;
};

var nSecond = 0;
var nDuration= 10;
var uploadFiles = [];

$scope.progressType = {
  none : 0,
  doing : 1,
  finished : 2
};
$scope.optionVideo = {src:'', file_token:''};

$scope.calclateProgress = $scope.progressType.none;

  $scope.drawSecondImage = function(n, dur, nextEnable) {
    $scope.calclateProgress = $scope.progressType.doing;
    var promise = drawImage(n);
    promise.then(
      function() { //成功

        //blobでpost
        var buf = toBinary();
        // Blobを作成
        var blob = new Blob([buf.buffer], {
            type: 'image/png'
        });

        // FormDataとして送信
        var fd = new FormData();
        fd.append("image", blob);
        var request = {
            url: 'http://192.168.103.108:10080/api/v1/content/uploads',
            method: 'POST',
            // xhr2: true,
            // rawData: fd,
            data: fd,
            processData: false,
            contentType: false
        };
        $.ajax(request).done(function( res ){
          uploadFiles.push({index:n , filename:res.uploadFilename.file_token});
          nSecond++;

          if (nSecond < nDuration) {
          } else {
              //終了処理
              $scope.calclateProgress = $scope.progressType.doing;
              var postdata = {type:"", files:uploadFiles , name:'option_' + formatDate(new Date() , 'YYYYMMDD_hhmmss')};
              $http.post('/api/v1/content/extra/option', postdata).
              success(function(postres) {
                $scope.calclateProgress = $scope.progressType.finished;

                if (postres.file) {
                  $scope.optionVideo.src = postres.file;
                  $scope.optionVideo.file_token = postres.file_token;
                  $scope.optionVideo.name = postres.name;
                }
              });

          }
          $timeout(function(){
            if (nSecond < nDuration) {
              $scope.drawSecondImage(nSecond , nDuration, true);
            } else {
            }
          },10);


        });
      },
      function() { //失敗
        //なにもしない
      }
    );

  }



  $scope.registContent = function(){
    var params = {
      type:'quicktime',
      name:$scope.optionVideo.name,
      fileToken:$scope.optionVideo.file_token,
        //,
          categoryId:1,
          // materialUri":"",
          // userName:"letter",
          // password:"123456"

    };

    // $http.post('/api/v1/materials',params ).
    // success(function(data) {
    //   console.log("POST Material => " + data.materialId);
    //   Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
    //   html:'動画を登録しました。',//$translate.instant('CONTENT.ATTACHMENT.CHECK_SHOW_DETAIL'),
    //   modalClass:'modal-success'});

    // });

  }

  $scope.drawStart = function(optionType){
    if (optionType === "option_heart") {
      //ハート動画
      nSecond = 0;
      uploadFiles = [];
      $scope.calclateProgress = $scope.progressType.none;
      $scope.drawSecondImage(nSecond , nDuration, true);
    }
  }


    /*
        http://stackoverflow.com/questions/14325779/draw-heart-shape-android-canvas?rq=1
    */
    function heart_function(t) {
        var r = (Math.sin(t)*Math.sqrt(Math.abs(Math.cos(t))))/(Math.sin(t) + 7.0/5.0) -2*Math.sin(t) + 2.0;
        return r;
    }

    var CoordSupport = function() {
            this.init = function(cw, ch, x_min, x_max, y_min, y_max) {
                this.width_ = cw;
                this.height_ = ch;
                this.x_min_ = x_min;
                this.x_max_ = x_max;
                this.y_min_ = y_min;
                this.y_max_ = y_max;
                this.dx_ = (x_max - x_min) / cw;
                this.dy_ = (y_max - y_min) / ch;
            };
            this.pixel = function(x, y) {
                var px = (x - this.x_min_) / (this.x_max_ - this.x_min_) * this.width_;
                var py = (this.y_max_ - y) / (this.y_max_ - this.y_min_) * this.height_;
                return {"x": px, "y": py};
            };
    };

// var srcImage = [
// {file:"0001.jpg",img:null, index:0},
// {file:"0002.jpg",img:null, index:1},
// {file:"0003.jpg",img:null, index:2},
// {file:"0004.jpg",img:null, index:3},
// {file:"0005.jpg",img:null, index:4},
// {file:"0006.jpg",img:null, index:5},
// {file:"0007.jpg",img:null, index:6},
// {file:"0008.jpg",img:null, index:7},
// {file:"0009.jpg",img:null, index:8},
// {file:"0010.jpg",img:null, index:9},
// {file:"0011.jpg",img:null, index:10}
// ];
var point = [];

  var posImageDraw=[];

    function checkRect (now) {
        // 当たり判定
        var findRect = posImageDraw.find(function(r){
          if(
          Math.abs(r.x - now.x) < r.width/2 + now.width/2 //横の判定
          &&
          Math.abs(r.y - now.y) < r.height/2 + now.height/2 //縦の判定
        ){
          console.log("hit"); // hit
          return true;
        }

        });
        return findRect;

    }

    //画像を描画する場所を計算
    function calcDrawPos() {
      posImageDraw=[];

      point.sort(function(a,b){ //大きいものから
        if(a.y > b.y) return -1;
        if(a.y < b.y) return 1;
        return 0;
      });

      var margin_x = (1920-1080) / 2;

      var len = point.length;
      {
        var image_w = 50;
        var image_h = 50;
        for(var i = 0; i < len; i++) {

          var now = {
              x : point[i].x - image_w / 2 + margin_x,
              y : point[i].y - image_h / 2,
              width : image_w,
              height : image_h
            };

          if (i) {
            if (!checkRect(now)) {
              //OK
              posImageDraw.push(now)
            }
          } else {
            //OK
            posImageDraw.push(now)
          }

        }
        posImageDraw.push(point[len-1]);  //最後は入れる

      }

      //var png = canvas.toDataURL();
      //console.log(png.length);

    }

    function drawImage(n){
      var defer = $q.defer();

      var canvas = document.getElementById('canvas-h');
      if (!canvas || !canvas.getContext) {
          alert("not support CANVAS !");
          defer.resolve();
          return defer.promise;
      }

      var ctx = canvas.getContext('2d');

      if (!n) {
        if ($scope.SelectedBackground.color.length) {
          ctx.fillStyle = $scope.SelectedBackground.color;
          ctx.fillRect(0,0,canvas.width, canvas.height);
        } else {
          ctx.fillStyle = '#FFF';
          ctx.fillRect(0,0,canvas.width, canvas.height);
          ctx.drawImage($scope.SelectedBackground.imageInfo, 0,0);
        }
      }

      var step = parseInt(posImageDraw.length / nDuration);
      var start = step * n;
      var end = start+step;
      if (n == nDuration-1) {
        end = posImageDraw.length;
      }
      var images = [];
      for(var i = start; i < end; i++) {
        var nImageIndex = i;
        if (nImageIndex >= $scope.tweetInfo.length) {
          nImageIndex = i % $scope.tweetInfo.length
        }
        images.push( {index:i , url:$scope.tweetInfo[nImageIndex].photo} );
      }

      var promiseArray = images.map(function(imgInfo){
        var prom = new Promise(function(resolve,reject){
            var img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = function(){
              imgInfo.img = img;
                resolve();
            };
            img.src = imgInfo.url;
        });
        return prom;
      });

      Promise.all(promiseArray).then(
        function(){
          for(var i = 0; i < images.length; i++) {
            if (posImageDraw.length > images[i].index) {
              ctx.drawImage(images[i].img, 0,0,images[i].img.width,images[i].img.height,
                posImageDraw[images[i].index].x, posImageDraw[images[i].index].y ,50,50);
            }
          }
          defer.resolve();
        }
      );
      return defer.promise;
    }


    function drawImage2(n){
      point.sort(function(a,b){ //大きいものから
          if(a.y > b.y) return -1;
          if(a.y < b.y) return 1;
          return 0;
      });
      var canvas = document.getElementById('canvas-h');
        if (!canvas || !canvas.getContext) {
            alert("not support CANVAS !");
            return false;
        }

      var ctx = canvas.getContext('2d');

      n = parseInt(n);
      var len = point.length;
      var step = parseInt(len / 10);
      var findnullImage = srcImage.find(function(img){
        if (img.img == null) {
          return true;
        } else if (!img.img.width) {
          return true;
        }
      });
      if (!findnullImage) {
        var start = step * n;
        var end = start+step;
        if (n == 10) {
          end = len;
        }

        for(var i = start; i < end; i++) {
          var img = srcImage[n];

          var now = {
              x : point[i].x,
              y : point[i].y,
              width : 50,
              height : 50
            };

          // if (i != start) {
          //   if (!checkRect(now)) {
          //     ctx.drawImage(img.img, 0,0,img.img.width,img.img.height,
          //                   point[i].x, point[i].y ,50,50);

          //     drawRect.push(now);
          //   }

          // } else {
          //   ctx.drawImage(img.img, 0,0,img.img.width,img.img.height,
          //   point[i].x, point[i].y ,50,50);
          //   drawRect.push(now);
          // }

        }
        // srcImage.forEach(function(img){
        //   if (img.img !== null) {
        //     //w:h = i_w: i_h
        //     ctx.drawImage(img.img, 0,0,img.img.width,img.img.height,
        //     point[img.index*36].x, point[img.index*36].y ,50,50);
        //     //context.drawImage(imageObj, 0, 0, canvas.width, canvas.height,0,0,imageObj.width*ratio, imageObj.height*ratio);
        //   }
        // });
      }

      var png = canvas.toDataURL();
      console.log(png.length);
    }

    //ハートの基線を書く処理
    function draw_heart() {

        var canvas = document.getElementById('canvas-h');
        if (!canvas || !canvas.getContext) {
            alert("not support CANVAS !");
            return false;
        }

        var cs = new CoordSupport();
        cs.init(1080, 1080, -3, 3, -5, 2);
        canvas.width = cs.width_;
        canvas.height = cs.height_;
        canvas.width = cs.height_ / 9 * 16;

        var ctx = canvas.getContext('2d');
        ctx.strokeStyle = "pink";

        var p = cs.pixel(0, 0);
        ctx.moveTo(p.x, p.y);
        point.push({x:p.x, y:p.y});

        var n = 0;
        console.log("" + n + ": " + p.x + "  " +  p.y); n++;

        ctx.beginPath();

        var t;
        var PI2 = Math.PI * 2.0;
        var dt = PI2 / 360;
        for (t = 0 ; t <= PI2 ; t += dt) {
            var r = heart_function(t);
            var x = r * Math.cos(t);
            var y = r * Math.sin(t);
            p = cs.pixel(x, y);
            ctx.lineTo(p.x, p.y);
            point.push({x:p.x, y:p.y});
            console.log("" + n + ": " + p.x + "  " +  p.y); n++;


        }
//        ctx.stroke();

//        ctx.fillStyle = "pink";
//        ctx.fill();

        ctx.closePath();
    }


    draw_heart();
    calcDrawPos();


}]);
