'use strict';

var app = angular.module('limaApp');

app.controller('ESysLineInfoCtrl', ['$scope', '$http', '$filter', '$translate', '$timeout','$window', '$cookies','Modal', '$uibModal','Auth', 'esysInfo',
function ($scope, $http, $filter, $translate, $timeout, $window, $cookies, Modal, $modal, Auth, esysInfo) {

  $scope.esysInfo = _.cloneDeep(esysInfo.info);
  $scope.esysInfo.contractInfo.line_contract_id_str = ( '00000000' + $scope.esysInfo.contractInfo.line_contract_id ).slice( -8 );
  $scope.esysInfo.contractInfo.contents_contract_pwd = $scope.esysInfo.contractInfo.line_contract_pass;
  $scope.esysInfo.contractInfo.contents_contract_pwd_off = "********";

  $scope.showPwd = false;
  $scope.changeShowPwd = function() {
    $timeout(function() {
      $scope.showPwd = !$scope.showPwd;
    })
  }
  $scope.clearContent= function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      // html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.TRANSCODE_LIST.CONFIRM_DELETE_TEXT')}),
      html: "投稿をクリアしてよろしいですか",
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      //削除

      $http.post('/api/v1/content/extra/esys/line/clear/' + $scope.esysInfo.contractInfo.contents_contract_id).
      success(function(data) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"投稿内容をクリアしました", modalClass:'modal-warning'});
      });
    });
  }


  $scope.changeSetting = function(enablePassword , enableSetting) {
    var modalInstance = $modal.open({
      templateUrl: 'app/system/status/esys/esysLineSetting.html',
      controller: 'ESysLineSettingCtrl',
      //windowClass: 'modal-fit',
        size: 'md',
        backdrop: 'static', keyboard: false,
//      size: 'lg',
      resolve: {
        esysInfo: function() {
          return {
            info : $scope.esysInfo,
            enablePassword : enablePassword ,
            enableSetting : enableSetting
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
    }, function(res) {
      console.log(res);
      if (res) {
        if (res.m_updated) {
          $timeout(function() {
            $scope.esysInfo.contractInfo.line_contract_pass = res.contractInfo.line_contract_pass;
            $scope.esysInfo.contractInfo.contents_contract_pwd = res.contractInfo.line_contract_pass;
            $scope.esysInfo.contractInfo.generation_num = res.contractInfo.generation_num;
          })
        }
      }
    });
  }



}]);
