'use strict';

var app = angular.module('limaApp');

app.controller('RegisterCtrl', ['$scope', '$translate', 'Auth', '$http', '$location', '$cookies','Modal', 'LimaRedirect',
function ($scope, $translate, Auth, $http, $location, $cookieStore, Modal, LimaRedirect) {
  $scope.user = {};
  $scope.errors = {};

  (function (help) {
    switch (help) {
    case 'Password has expired': break;
    case 'Please login': break;
    case 'Anonymous login failed':
    case 'Failed to get anonymous login params': $translate('LOGIN.HELP.ANONYMOUS_LOGIN_FAILED').then(function (res) { $scope.help = res; }); break;
    case 'Login session has expired': $translate('LOGIN.HELP.SESSION_EXPIRED').then(function (res) { $scope.help = res; }); break;
    case 'Forbidden': $translate('LOGIN.HELP.FORBIDDEN').then(function (res) { $scope.help = res; }); break;
    default: $scope.help = help; break;
    }
  })(LimaRedirect.help);





  $scope.userGroups= undefined;
  $scope.userGroupsAdmin= undefined;

  function getUserGroups() {
    $http.get('/api/v1/user/groups').
    success(function(datas) {
      $scope.userGroups = datas;
      $scope.userGroupsAdmin = _.find(datas, function(dt){
        return dt.id === '-Administrator' ? true : false;
      });
    });
  }
  getUserGroups();

  $scope.cancel = function() {
    //window.history.back();
  }

  $scope.doRegister = function(form){
    var now = new Date();
    $scope.user.loginId = '' + now.getTime();
    $scope.user.name = 'root';
    $scope.user.displayName = 'root';
    $scope.user.password = '' + $scope.user.loginId;
    $scope.user.retypePassword = '' + $scope.user.loginId;

    // if(!form.$valid || $scope.user.password !== $scope.user.retypePassword) {
    //   return;
    // }
    try {
      if($scope.user.password.length < 8 ) {
        return;
      }
      if($scope.user.password !== $scope.user.retypePassword) {
        return;
      }
      if(!$scope.user.companyName.length ) {
        return;
      }
      // if(!$scope.user.divisionName.length ) {
      //   return;
      // }
      if(!$scope.user.loginId.length ) {
        return;
      }
      if(!$scope.user.name.length ) {
        return;
      }
      if(!$scope.user.displayName.length ) {
        return;
      }
    }catch(err) {
      return;
    }

    var postdata = {
      loginId:$scope.user.loginId,
      name: $scope.user.companyName,
      divisionName: $scope.user.divisionName,
      email: ''
    };
    $http.post('/api/v1/company' ,postdata ).
    success(function(datasCom) {
      var postdataLB = {
        server:''
      };
      $http.post('/api/v1/system/loadbalance/' + datasCom.id ,postdataLB ).
      success(function(datasLB) {
        $scope.user.companyId = datasCom.id;
        $scope.user.effective = 1;
        $scope.user.groups = [];
        $scope.user.groups.push({id:$scope.userGroupsAdmin.id});
        //$scope.user.companyId =
        $http.post('/api/v1/user/accounts' ,$scope.user ).
        success(function(datasUr) {
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"企業アカウントの登録が完了しました。<br>次のページで利用オプションを選択してください。", modalClass:'modal-success'},
          function () {
            //次
            $location.path('/regist-option').search({id: datasCom.id});
          });
        });
      });
    });




  }


}]);
