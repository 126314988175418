'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('user', {
    url: '/user',
    templateUrl: 'app/user/user.html',
    controller: 'UserCtrl',
    authenticate: true,
    functionName: 'UserManagement',
    title: 'Users'
  });
}]);
