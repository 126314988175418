'use strict';

angular.module('limaApp')
.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('transcodelist', {
    url: '/content/transcode/list',
    templateUrl: 'app/content/transcode/list/translist.html',
    controller: 'TranscodelistCtrl',
    title: 'Transcodelist',
    authenticate: true,
    functionName: 'ContentManagement' //権限：コンテンツ管理ページと同じにしておく
  });
}]);
