'use strict';

var app = angular.module('limaApp');

app.controller('EdituserCtrl', ['$scope', '$location', '$translate', '$q', 'Modal', 'Auth', '$uibModalInstance', 'LimaRedirect', 'socket', 'editUserInfo',
function ($scope, $location, $translate, $q, Modal, Auth, $modalInstance, LimaRedirect, socket, editUserInfo) {

  //$scope.selectedGroups = [];
  $scope.multiSelectTranslations = {
    selectAll: $translate.instant('MULTI_SELECT.SELECT_ALL'),
    selectNone: $translate.instant('MULTI_SELECT.SELECT_NONE'),
    reset: $translate.instant('MULTI_SELECT.RESET'),
    search: $translate.instant('MULTI_SELECT.SEARCH'),
    // nothingSelected: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
    nothingSelected: '未選択'
  };

  $scope.editUserInfo = editUserInfo;
  var editUserId = $scope.editUserInfo.userId;
  $scope.isBuiltinUser = (editUserId || '').substr(0,1) === '-';

  function getUserName(name) {
    if (name === 'administrator') {
      return $translate.instant('USER.USER_ADMINISTRATOR');
    }
    else if (name === 'manager') {
      return $translate.instant('USER.USER_MANAGER');
    }
    else if (name === 'user') {
      return $translate.instant('USER.USER_USER');
    }
    else if (name === 'viewer') {
      return $translate.instant('USER.USER_VIEWER');
    }
    return name;
  }

  socket.connect().then(function () {
    return socket.call('v1:user:account:findOne', {userId:editUserId});
  }).then(function (res) {
    $scope.edituser = res;
    $scope.edituser.effective = $scope.edituser.effective === 1;
    return socket.call('v1:user:group:findAll', {functions:true});
  }).then(function (res) {
    _.forEach(res, function (group) {
      // group.escapedName = _.escape(group.name);
      group.escapedName = getUserName(group.name);
      group.ticked = _.findIndex($scope.edituser.groups, {id:group.id}) >= 0;
    });
    $scope.groups = res;
  });

  $scope.updateUser = function(userId) {
    var user = _.cloneDeep($scope.edituser);
    user.effective = ($scope.edituser.effective) ? 1 : 0;
    if (editUserId === '-Administrator') {
      delete user.groups; // don't modify groups for admin
    } else {
      user.groups = _.map(_.filter($scope.selectedGroups, {ticked:true}), function (group) {
        return {id:group.id};
      });
    }
    socket.connect().then(function () {
      return socket.call('v1:user:account:update', {user:user, userId:user.id});
    }).then(function (res) {
      if (userId === Auth.getCurrentUser().id) Auth.updateCurrentUser();
      $modalInstance.close();
    }, function (err) {
      var msg = _.get(err, 'message');
      if (msg !== 'cannot remove user management role of self') {
        $scope.msg_error = msg;
        return;
      }
      Modal.confirm.yesno({
        title: $translate.instant('USER.USER_REMOVE_SELF_ROLE_TITLE'),
        html: $translate.instant('USER.USER_REMOVE_SELF_ROLE_MESSAGE'),
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        socket.connect().then(function () {
          return socket.call('v1:user:account:update', {user:user, userId:user.id, force:true});
        }).then(function (res) {
          return Auth.updateCurrentUser();
        }).then(function (res) {
          return $modalInstance.close();
        }).then(function (res) {
          LimaRedirect.go({path:'/', clearCache:true});
        }, function (err) {
          err = err.data || err;
          $scope.msg_error = err.message;
        });
      });
    });
  };

  $scope.retypePassword = null;
  $scope.resetPassword = function(userId) {
    if ($scope.isBuiltinUser) return;
    socket.connect().then(function () {
      return socket.call('v1:user:resetPassword', {userId:userId, password:{newPassword:$scope.edituser.retypePassword}});
    }).then(function (res) {
      $scope.msg_reset_success = 'Password reset successful';
      $scope.msg_reset_error = null;
      $scope.edituser.newPassword = null;
      $scope.edituser.retypePassword = null;
    }, function (err) {
      $scope.msg_reset_success = null;
      $scope.msg_reset_error = err;
    });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
