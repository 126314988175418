'use strict';

var app = angular.module('limaApp');
var async =require('async');
const { groupBy } = require('lodash');

app.controller('EditLayoutDlgCtrl', ['$scope', '$sce','$location', '$http', '$translate', '$uibModal', '$uibModalInstance','Auth', 'socket','$window','$cookies','$timeout','Modal','EditLayoutInfo', 'limaOptionService',
function ($scope, $sce, $location, $http, $translate, $modal, $modalInstance, Auth, socket,$window, $cookies,$timeout, Modal, EditLayoutInfo, limaOptionService) {

  $window.document.title  = "レイアウト編集";
  Auth.systemProperties().then(function (data) {
    $scope.systemDebug = data['system.debug'] || 0;
  });

  $scope.m_WebVueAppInfoURL = "";
  $http.get('/api/v1/configurations/WebVueApp').then(function (res) {
    if (res.data) {
      $scope.m_WebVueAppInfo = res.data;
      $scope.m_WebVueAppInfoURL = $scope.m_WebVueAppInfo.protocol + "://" + $scope.m_WebVueAppInfo.domain + ":" + $scope.m_WebVueAppInfo.port + "/layout/edit";
      if (EditLayoutInfo.layout) {
        $scope.m_WebVueAppInfoURL +="?layoutId=" + EditLayoutInfo.layout.layoutId;
        $scope.m_WebVueAppInfoURL +="&playlistUsed=" + EditLayoutInfo.playlistUsed;
        $scope.m_WebVueAppInfoURL += '&app_token=' + encodeURIComponent($cookies.get('token'));
      } else {
        $scope.m_WebVueAppInfoURL += '?app_token=' + encodeURIComponent($cookies.get('token'));
      }
      $scope.m_WebVueAppInfoURL = $sce.trustAsResourceUrl($scope.m_WebVueAppInfoURL);
    }
  });

  // iframeからの呼び出し
  window.addEventListener("message", (event) => {
    //if (event.origin.indexOf("/layout/edit") !== -1)
    {
      if (event.data.type === "saved"){
        $modalInstance.close();
        //$scope.$dismiss();
      }
      if (event.data.type === "cancelled"){
        $scope.$dismiss();
      }
    }
  }, false);

  $scope.packageType = 0;
  limaOptionService.getpackageType(function(result){
    if (result) {
      $scope.packageType = result.packageType;
    }
  });

  var layoutInfo = EditLayoutInfo.layout ? _.cloneDeep(EditLayoutInfo.layout) : null;
  if (layoutInfo) {
    _.forEach(layoutInfo.regions , function(rg) {
      rg.y = rg.topIndex;
      rg.x = rg.leftIndex;
      rg.w = rg.width;
      rg.h = rg.height;
      if (rg.name.toLowerCase().indexOf("all") !== -1) {
        rg.name_ui = "all";
      }
      if (rg.name.toLowerCase().indexOf("input") !== -1) {
        rg.name_ui = "Input";
      }
      if (rg.fixed) {
        rg.name_ui = "fixed";
      }
    })
  }

  var m_bChangeVal = false;
  $scope.changeRegionVal = function(){
    m_bChangeVal = true;
  }

  $scope.selectRegionCount = function() {
    //はじめて
    if (!EditLayoutInfo.layout && !m_bChangeVal) {
      $scope.layoutInfo.regions = [];
      if ($scope.layoutInfo.selectRegionCount === 2) {
        var rg = {};
        if (!$scope.layoutInfo.selectDirection) {
          rg = {x:0 , y : 0 , w : 960 , h : 1080};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:960 , y : 0 , w : 960 , h : 1080};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
        } else {
          rg = {x:0 , y : 0 , w : 1080 , h : 960};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:0 , y : 960 , w : 1080 , h : 960};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
        }
      } else {
        if (!$scope.layoutInfo.selectDirection) {
          rg = {x:0 , y : 0 , w : 960 , h : 540};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:960 , y : 0 , w : 960 , h : 540};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:0 , y : 540 , w : 960 , h : 540};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:960 , y : 540 , w : 960 , h : 540};
          rg.name_ui = 'all'; rg.fixed = 0;
          if ($scope.layoutInfo.selectRegionCount === 4) {
            $scope.layoutInfo.regions.push(rg);
          }
        } else {
          rg = {x:0 , y : 0 , w : 540 , h : 960};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:540 , y : 0 , w : 540 , h : 960};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:0 , y : 960 , w : 540 , h : 960};
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
          rg = {x:540 , y : 960 , w : 540 , h : 960};
          rg.name_ui = 'all'; rg.fixed = 0;
          if ($scope.layoutInfo.selectRegionCount === 4) {
            $scope.layoutInfo.regions.push(rg);
          }
        }

      }
    } else {
      if ($scope.layoutInfo.regions.length < $scope.layoutInfo.selectRegionCount) {
        for (var i = 0; i < ($scope.layoutInfo.selectRegionCount -$scope.layoutInfo.regions.length); i++) {
          rg = {x:0 , y : 0 , w : 0 , h : 0}
          rg.name_ui = 'all'; rg.fixed = 0;
          $scope.layoutInfo.regions.push(rg);
        }
      } else {
        for (var i = 0; i < ($scope.layoutInfo.regions.length - $scope.layoutInfo.selectRegionCount); i++) {
          $scope.layoutInfo.regions.pop();
        }

      }
    }
    $timeout(function() {
      canvasDraw();

    })

    // $scope.layoutInfo.regions = [];
    // for (var i = 0; i < $scope.layoutInfo.selectRegionCount; i++) {
    //   $scope.layoutInfo.regions.push(
    //     {x:0,y:0, w:0,h:0}
    //   );
    // }
  }


  $timeout(function(){

    $scope.layoutInfo ={
      name : layoutInfo ? layoutInfo.name : '',
      arrRegionCount : [{value:2},{value:3},{value:4}],
      selectRegionCount : layoutInfo ? layoutInfo.regions.length : 2,
      selectDirection : layoutInfo ? layoutInfo.rotate : 0,
      directions : [{value:0},{value:90},{value:270}],
      size : {
        width : 1920,
        height : 1080,
      },
      regions:layoutInfo ? layoutInfo.regions : []
    };
    if ( $scope.layoutInfo.selectRegionCount < 2 ) {
      $scope.layoutInfo.selectRegionCount  = 2
    }
    if (!layoutInfo) {
      $scope.selectRegionCount();
    }

    $timeout(function(){
          canvasDraw();
    },1000);
  });

  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

/**
■　#ff0000
■　#ffa500
■　#ffff00
■　#008000
■　#00ffff
■　#0000ff
■　#800080
 *
 *
*/
$scope.arrColor = ['#ffa500','#ffff00','#008000','#00ffff','#0000ff','#800080','#ff0000'];

  function canvasDraw() {

    var c=document.getElementById("canvasLayout");
    if (!c) {
      return;
    }
    var ctx=c.getContext("2d");

    var arrRg = [];
    var w = Number($scope.layoutInfo.size.width);
    var h = Number($scope.layoutInfo.size.height);
    var canvas_w = 1920;
    var canvas_h = 1080;
    if ($scope.layoutInfo.selectDirection !== 0 ) {
      h = Number($scope.layoutInfo.size.width);
      w = Number($scope.layoutInfo.size.height);
      canvas_w = 1080;
      canvas_h = 1920;
    }
    var diff_w = 0;
    var diff_h = 0;
    if (w > h) {
      //w : h = 1920 : yy
      //canvas_h = canvas_w * h / w;
      //diff_h = (1920 - canvas_h) / 2;
    } else {
      //canvas_w = canvas_h * w / h;
      //diff_w = (1920 - canvas_w) / 2;
    }
    ctx.clearRect(0,0,canvas_w,canvas_h);
    ctx.fillStyle = "#e9e9e9"
    ctx.lineWidth = 4;
    ctx.strokeRect(0,0,w,h);

    _.forEach($scope.layoutInfo.regions, function(region,index){
      var rect = {
        left: Number(region.x),
        top: Number(region.y),
        width: Number(region.w),
        height: Number(region.h)
      };
      var bSelect = false;
      if (!_.isUndefined($scope.SelectedRegion)) {
        if ($scope.SelectedRegion.regionId === region.regionId) {
          bSelect = true;
        }
      }
      ctx.globalAlpha = 1;
      if (bSelect) {
        ctx.fillStyle = $scope.arrColor[index];//'#fff59d';
      } else {
        ctx.fillStyle = $scope.arrColor[index];//'#e9e9e9';
      }
      ctx.fillRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

      ctx.fillStyle = $scope.arrColor[index];//"#e9e9e9"
      ctx.globalAlpha = 1;
      ctx.lineWidth = 4;
      ctx.strokeRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

    });



  }


  // function canvasDraw() {

  //   var c=document.getElementById("canvasLayout");
  //   var ctx=c.getContext("2d");

  //   var arrRg = [];
  //   var w = Number($scope.layoutInfo.size.width);
  //   var h = Number($scope.layoutInfo.size.height);
  //   var canvas_w = 1920;
  //   var canvas_h = 1920;
  //   if ($scope.layoutInfo.selectDirection !== 0 ) {
  //     h = Number($scope.layoutInfo.size.width);
  //     w = Number($scope.layoutInfo.size.height);
  //     canvas_w = 1920;
  //     canvas_h = 1920;
  //   }
  //   var diff_w = 0;
  //   var diff_h = 0;
  //   if (w > h) {
  //     //w : h = 1920 : yy
  //     canvas_h = canvas_w * h / w;
  //     diff_h = (1920 - canvas_h) / 2;
  //   } else {
  //     canvas_w = canvas_h * w / h;
  //     diff_w = (1920 - canvas_w) / 2;
  //   }
  //   ctx.clearRect(0,0,1920,1920);
  //   ctx.fillStyle = "#e9e9e9"
  //   ctx.lineWidth = 4;
  //   ctx.strokeRect(0,0,w,h);

  //   _.forEach($scope.layoutInfo.regions, function(region,index){
  //     var rect = {
  //       left: Number(region.x),
  //       top: Number(region.y),
  //       width: Number(region.w),
  //       height: Number(region.h)
  //     };
  //     var bSelect = false;
  //     if (!_.isUndefined($scope.SelectedRegion)) {
  //       if ($scope.SelectedRegion.regionId === region.regionId) {
  //         bSelect = true;
  //       }
  //     }
  //     if (bSelect) {
  //       ctx.fillStyle = '#fff59d';
  //     } else {
  //       ctx.fillStyle = '#e9e9e9';
  //     }
  //     ctx.fillRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

  //     ctx.fillStyle = "#e9e9e9"
  //     ctx.lineWidth = 4;
  //     ctx.strokeRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

  //   });



  // }

  $scope.updateCanvas = function() {
    canvasDraw() ;
  }

  $scope.DoSaveLayout = function() {
    var bNew = false;
    if (!$scope.layoutInfo.name.length) {
      //新規保存
      bNew = true;
      var modalInstance = $modal.open({
        templateUrl: 'app/playlist/newName.html',
        controller: 'NewNameCtrl',
        windowClass: 'modal-rename-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          LimaNewNameData: function() {
            return {
              name : 'レイアウト' + "_" + $scope.formatDate(new Date() , 'YYYYMMDD_hhmmss')
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        $scope.layoutInfo.name = results.name;
        $scope.saveLayout(bNew , function() {
          $modalInstance.close();
        });
      });
    } else {
      //上書き保存
      $scope.saveLayout(bNew, function() {
        $modalInstance.close();
      });
    }

  }

  $scope.IsEnableRegionInput = function(list) {
    var find = _.find($scope.layoutInfo.regions, function (rg) {
      if (rg.name_ui === "Input"){
        if (list.regionId !== rg.regionId) {
          return true;
        }
      }
    });
    return  find ? false : true;
  }

  $scope.saveLayout = function(bNew, callback) {

    var postdata = {
      name    : $scope.layoutInfo.name  ,
      width   : $scope.layoutInfo.size.width  ,
      height   : $scope.layoutInfo.size.height  ,
      rotate   : $scope.layoutInfo.selectDirection,
      companyId : Auth.getCurrentUser().companyId,
      custom: 1,
      packageType: $scope.packageType
    };

    var httpParam = {
      url: bNew ? '/api/v1/layouts' : '/api/v1/layouts/' + layoutInfo.layoutId ,
      method: '',
      data: postdata
    };

    if (!bNew) {
      httpParam.method = 'PUT';
    } else {
      httpParam.method = 'POST';
    }
    $http(httpParam)
    .success(function(data) {

      var cnt_all = 1;
      _.forEach($scope.layoutInfo.regions, function (rg) {
        rg.fixed = 0;
        if (rg.name_ui === "all") {
          rg.name = "all" + cnt_all;
          cnt_all++;
        }
        else if (rg.name_ui === "fixed") {
          rg.fixed = 1;
          rg.name = "all" + cnt_all;
          cnt_all++;
        } else {
          rg.name = rg.name_ui;
        }
      });

      async.forEachSeries($scope.layoutInfo.regions, function (rg, cb) {
        var postdataRg = {
          name    : rg.name  ,
          leftIndex : rg.x,
          topIndex:rg.y,
          width   :rg.w  ,
          height   : rg.h  ,
          zIndex   : 1,
          fixed : rg.fixed,
          layoutId : data.id
        };
        var httpParam = {
          url: bNew ? '/api/v1/regions' : '/api/v1/regions/' + rg.regionId ,
          method: '',
          data: postdataRg
        };

        if (!bNew) {
          httpParam.method = 'PUT';
        } else {
          httpParam.method = 'POST';
        }
        $http(httpParam)
        .success(function(dataRg) {
            cb(null , "region " + dataRg.id);
        });
      }, function (err) {
        callback(err);
      });
    });




  }


    $scope.cancel = function() {
    Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('PLAYLIST.MESSAGE_NO_SAVE_CLOSE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
        }, function (yes) {
            if (!yes){
                return;
            }else{
                $modalInstance.dismiss('cancel');
            }
        }
    )}
}]);

