'use strict';

var app = angular.module('limaApp');

app.controller('UserCtrl', ['$scope', '$location', '$translate', '$uibModal', 'Auth', 'Modal', 'LimaRedirect', 'socket', '$window',
function ($scope, $location, $translate, $modal, Auth, Modal, LimaRedirect, socket, $window) {

  $window.document.title  = $translate.instant('NAVIGATION.USERS');

  $scope.members = [];

  function getUserName(name) {
    if (name === 'administrator') {
      return $translate.instant('USER.USER_ADMINISTRATOR');
    }
    else if (name === 'manager') {
      return $translate.instant('USER.USER_MANAGER');
    }
    else if (name === 'user') {
      return $translate.instant('USER.USER_USER');
    }
    else if (name === 'viewer') {
      return $translate.instant('USER.USER_VIEWER');
    }
    return name;
  }

  var updateUserInfo = function () {
    socket.connect().then(function () {
      return socket.call('v1:user:account:findAll');
    }).then(function (res) {
      $scope.members = res || [];
      $scope.numberOfUserPages = Math.ceil($scope.members.length / 10);
      _.forEach($scope.members, function (member) {
        member.effective = $translate.instant((member.effective) ? 'USER.USER_EFFECTIVE' : 'USER.USER_INEFFECTIVE');
        var dispGroups = '';
        _.forEach(member.groups, function (group) {
          // dispGroups += '[ ' + group.name + ' ] ';
          dispGroups += '[ ' + getUserName(group.name) + ' ] ';

        });
        member.dispGroups = dispGroups;
      });
    }, function (err) {
      $scope.members = [{name:'error'}];
    });
  };

  socket.connect().then(function () {
    return socket.call('v1:user:group:findAll');
  }).then(function (res) {
    $scope.groups = res.data;
  }).then(function () {
    updateUserInfo();
  });

  $scope.isDeletable = function (user) {
    return user && _.isString(user.id) && user.id.substr(0,1) !== '-';
  };

  $scope.deleteUser = function(user) {
    delete $scope.msg_success;
    delete $scope.msg_error;
    Modal.confirm['delete'](function (yes) {
      if (!yes) return;

      var findAdminUsers = _.filter($scope.members, function (member) {
        var findAdmin = _.find(member.groups, function (group) {
          if (group.id.indexOf('-Administrator') !== -1 ) {
            return true;
          }
        });
        return findAdmin ? true : false;
      });
      if (findAdminUsers) {
        if (findAdminUsers.length === 1) {
          if (_.find(findAdminUsers,function(u){return u.id === user.id;})) {
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('USER.USER_DELETE_NOT_LAST_ADMIN'), modalClass:'modal-warning'});
            return;
          }

        }
      }

      socket.connect().then(function () {
        return socket.call('v1:user:account:delete', {userId:user.id});
      }).then(function (res) {
        $scope.msg_success = 'The user account "' + user.name + '" has deleted successfully.';
        updateUserInfo();
      }, function (err) {
        var msg = _.get(err, 'message');
        if (msg !== 'cannot delete own account') {
          $scope.msg_error = msg;
          return;
        }
        Modal.confirm.yesno({
          title: $translate.instant('USER.USER_DELETE_OWN_TITLE'),
          html: $translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
          btnClassYes: 'btn-danger',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-default',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;

          socket.connect().then(function () {
            return socket.call('v1:user:account:delete', {userId:user.id, force:true});
          }).then(function (res) {
            Auth.logout();
            LimaRedirect.go({path:'/', clearCache:true});
          }, function (err) {
            $scope.msg_error = _.get(err, 'message');
          });
        });
      });
    })(user.name, 'yes');
  };

  $scope.selecteffectiveVal = "";   // the filter for user (effective or not)

  $scope.clearSearch = function ($event) {
    $scope.searchResetRef = new Date();
    $scope.selecteffectiveVal = "";   // clear effective filter
  };

  $scope.addUser = function (event) {
    //$event.stopPropagation();
    delete $scope.msg_success;
    delete $scope.msg_error;
    var modalInstance = $modal.open({
      templateUrl: 'app/user/addUser/addUser.html',
      controller: 'AdduserCtrl',
      backdrop: 'static', keyboard: false,
      resolve: { }
    });
    modalInstance.result.then(function (results) { // OK
      updateUserInfo(); // update the current contents list
    }, function () { // cancel
    });
  };
  $scope.editUser = function (user) {
    //$event.stopPropagation();
    delete $scope.msg_success;
    delete $scope.msg_error;

    var findAdminUsers = _.filter($scope.members, function (member) {
      var findAdmin = _.find(member.groups, function (group) {
        if (group.id.indexOf('-Administrator') !== -1 ) {
          return true;
        }
      });
      return findAdmin ? true : false;
    });
    var IsAdmin = _.find(user.groups, function (group) {
      if (group.id.indexOf('-Administrator') !== -1 ) {
        return true;
      }
    });

    var thisIsOnlyAdmin = false;
    if (findAdminUsers) {
      if (findAdminUsers.length === 1) {
        if (IsAdmin) {
          thisIsOnlyAdmin = true;
        }
      }
    }

    var modalInstance = $modal.open({
      templateUrl: 'app/user/editUser/editUser.html',
      controller: 'EdituserCtrl',
      backdrop: 'static', keyboard: false,
      resolve: {
        editUserInfo: function() {
          return {
            userId :user.id,
            thisIsOnlyAdmin : thisIsOnlyAdmin
          }
        }
      }
    });
    modalInstance.result.then(function (results) { // OK
      updateUserInfo(); // update the current contents list
    }, function () { // cancel
    });
  };
}]);

app.directive('limaCsSelect', function () {
  return {
    require: '^stTable',
    scope: {
      row: '=limaCsSelect'
    },
    link: function (scope, element, attr, ctrl) {
      element.bind('change', function (evt) {
        scope.$apply(function () {
          ctrl.search(element[0].value , "effective");
        });
      });
    }
  };
});
