/**
 * MEDIAEDGE.RAFALE.TLSeekBar
 *
 */
'use strict';

import {TimecodeUtil, Timecode_CdrTime, Timecode_CdrTimeCode} from './TimecodeUtil.js';

var TLSeekBar = (function() {

  var AUTO = 0;						  // not implemented
  var SEC = 1;						  // 秒のみ
  var MIN_SEC = 2;					// 分秒
  var HOUR_MIN_SEC = 3;			// 時分秒(23時の次は24時)
  var HOUR_MIN_SEC_ROUND = 4;			// 時分秒
  var HOUR_MIN = 5;					// 時分(23時の次は24時)
  var HOUR_MIN_ROUND = 6;		// 時分
  var HOUR = 7;						  // 時(23時の次は24時)
  var HOUR_ROUND = 8;				// 時

  var GradationType_Unkonwn = 0;
  var GradationType_Frame  = 1;
  var GradationType_Sec = 2; 
  var GradationType_Min = 3; 
  var GradationType_30Min = 4; 
  var GradationType_Hour = 5; 

  var m_nScaleSkipCount = 0;
  var m_nScaleDivCount = 1;
  var m_nScaleIncHz = 30000;
  var m_lTimeLineOffset = 0;
  var m_llOffset = 0;
  var m_rectSeekBar;
  
  //上位から指定されて設定されるもの
  var m_llDuration = 0; //フレーム数のDuration
  var m_mgnSeekBar = {nLeft:0, nRight:0, nTop:0, nBottom:0};  //テキストを書く領域canvasの左上を０として。
  var m_dwTimeScaleNumerator =30000;
  var m_dwTimeScaleDenominator = 1001;
  var m_mgnTimeScaleLine = {nTop:0, nBottom:0}; //スケールの線を書く領域　canvasの左上を０として。
  //Canvas
  var canvas;
  var ctx;
  var canvasFont;   //'bold 16px' + ' ' + "'Helvetica Neue', Helvetica, Arial, sans-serif";
  var colorScale = '#FFF';
  var colorText = '#FFF';
  //

  function initialize(options) {
    m_mgnSeekBar.nTop = options.textTop;
    m_mgnSeekBar.nBottom = options.textBottom;

    m_dwTimeScaleNumerator = options.numerator;
    m_dwTimeScaleDenominator = options.denominator;

    m_llDuration = TimecodeUtil.SecondToFrame(options.durationSeconds , m_dwTimeScaleNumerator ,m_dwTimeScaleDenominator , options.df);

    canvas = document.getElementById(options.elementCanvasId);
    if (canvas) {
      ctx = canvas.getContext('2d');
    }
    m_mgnTimeScaleLine.nTop = 0;
    m_mgnTimeScaleLine.nBottom = options.textTop;

    canvasFont = options.canvasFont;
    colorScale = options.colorScale;
    colorText = options.colorText;
   
    CalcTimeScalePeriod();

  }

  function RectInRect(rc1 , rc2) {
    if(
      Math.abs(rc1.left - rc2.left) < (rc1.right-rc1.left)/2 + (rc2.right-rc2.left)/2 //横の判定
      &&
      Math.abs(rc1.top - rc2.top) < (rc1.bottom-rc1.top)/2 + (rc2.bottom-rc2.top)/2 //縦の判定
    ){  
      return true;
    }
    return false;  
  }

  function CalcTimeScalePeriod() {
    var  llSecs = FrameToSec(m_llDuration);
  
    m_nScaleSkipCount = 0;
    m_nScaleDivCount = 1;
    // ワイドよりも細かく
    if (llSecs < 2) {
      m_nScaleIncHz = SecToHz(1);
      m_nScaleSkipCount = 1;
      m_nScaleDivCount = 0;
    }
    else if (llSecs < 4) {
      m_nScaleIncHz = SecToHz(1);
      m_nScaleSkipCount = 5;
  //		m_nScaleDivCount = (LONG)SecToFrame(1) / 6;
      m_nScaleDivCount = 0;		// NDF処理に任せてみる
    }
    else if (llSecs < 8) {
      m_nScaleIncHz = SecToHz(1);
      m_nScaleSkipCount = (m_dwTimeScaleNumerator + m_dwTimeScaleDenominator - 1) / m_dwTimeScaleDenominator / 2;
  //		m_nScaleDivCount = 2;
      m_nScaleDivCount = 0;		// NDF処理に任せてみる
    }
    else if (llSecs < 15) {
      m_nScaleIncHz = SecToHz(2);
      m_nScaleDivCount = 2;
    }
    else if (llSecs < 30) {
      m_nScaleIncHz = SecToHz(5);
      m_nScaleDivCount = 5;
    }
    else if (llSecs < 60) {
      // 一分未満ならば
      // 10秒単位のメモリを打つ
      m_nScaleIncHz = SecToHz(10);
      m_nScaleDivCount = 5;
    }
    else if (llSecs < 180) {
      // 3分未満
      // 30秒単位
      m_nScaleIncHz = SecToHz(30);
      m_nScaleDivCount = 3;
    }
    else if (llSecs < 300) {
      // 5分未満
      // 60秒単位
      m_nScaleIncHz = SecToHz(60);
      m_nScaleDivCount = 2;
    }
    else if (llSecs < 480) {
      // 8分未満
      // 60秒単位
      m_nScaleIncHz = SecToHz(60);
      m_nScaleDivCount = 2;
    }
    else if (llSecs < 600) {
      // 10分未満
      // 2分単位
      m_nScaleIncHz = SecToHz(120);
      m_nScaleDivCount = 2;
    }
    else if (llSecs < 1200) {
      m_nScaleIncHz = SecToHz(120);
      m_nScaleDivCount = 2;
    }
    else if (llSecs < 1800) {
      // 30分未満
      // 5分単位
      m_nScaleIncHz = SecToHz(300);
      m_nScaleDivCount = 5;
    }
    else if (llSecs < 3600) {
      // 60分未満
      // 10分単位
      m_nScaleIncHz = SecToHz(600);
      m_nScaleDivCount = 5;
    }
    else if (llSecs < 7200) {
      // 2時間未満
      // 10分単位
      m_nScaleIncHz = SecToHz(600);
      m_nScaleDivCount = 2;
    }
    else if (llSecs < 10800) {
      // 3時間未満
      // 30分単位
      m_nScaleIncHz = SecToHz(1800);
      m_nScaleDivCount = 3;
    }
    else if (llSecs < 3600 * 6) {
      // 6時間未満
      // 1時間単位
      m_nScaleIncHz = SecToHz(3600);
      m_nScaleDivCount = 2;
    }
    else if (llSecs < 3600 * 12) {
      // 12時間未満
      // 2時間単位
      m_nScaleIncHz = SecToHz(7200);
      m_nScaleDivCount = 2;
    }
    else {
      // 3時間単位
      m_nScaleIncHz = SecToHz(10800);
      m_nScaleDivCount = 3;
    }
  }
  
  
  //LONG
  function FrameToSec(llHz) {
    return parseInt(llHz * m_dwTimeScaleDenominator / m_dwTimeScaleNumerator);
  }

  function GetDispTime(llPos) {

    var lValue = FrameToSec(llPos + m_llOffset + parseInt(m_dwTimeScaleNumerator / m_dwTimeScaleDenominator) / 2) + m_lTimeLineOffset;

    var lHour = parseInt(lValue / 3600);
    var lMin = parseInt((lValue % 3600) / 60);
    var lSec = lValue % 60;
    
    var ret = {};
    ret.lHour = lHour
    ret.lMin = lMin
    ret.lSec = lSec
    return ret;
  }

  function GetTimeCode(llPos, type) {
    var strReturn;
    var retDisp = GetDispTime(llPos);
    var lHour = retDisp.lHour;
    var lMin = retDisp.lMin;
    var lSec = retDisp.lSec;

    var lHour_s = ("0"+lHour).slice(-2);
    var lMin_s = ("0"+lMin).slice(-2);
    var lSec_s = ("0"+lSec).slice(-2);


    if (type === SEC) {
      // 二けたの数値
      strReturn = lSec_s;
    }
    else if (type === MIN_SEC) {
      strReturn = lMin_s + ':' + lSec_s;
    }
    else if (type === HOUR_MIN_SEC) {
      strReturn = lHour_s + ':' + lMin_s + ':' + lSec_s;	// 普通の表記
    }
    else if (type === HOUR_MIN_SEC_ROUND) {
      lHour = lHour % 24;
      lHour_s = ("0"+lHour).slice(-2);
      strReturn = lHour_s + ':' + lMin_s + ':' + lSec_s;  // 普通の表記
    }
    else if (type === HOUR_MIN) {
      strReturn = lHour_s + ':' + lMin_s;
    }
    else if (type === HOUR_MIN_ROUND) {
      lHour = lHour % 24;
      lHour_s = ("0"+lHour).slice(-2);
      strReturn = lHour_s + ':' + lMin_s;
    }
    else if (type === HOUR) {
      strReturn = lHour_s;
    }
    else if (type === HOUR_ROUND) {
      lHour = lHour % 24;
      lHour_s = ("0"+lHour).slice(-2);
      strReturn = lHour_s;
    }
    return strReturn;
  }
  
  function GetSeekBarWidth() {
    if (!canvas) {
      return 0;
    }
    var rect = canvas.getBoundingClientRect();
    m_rectSeekBar = {left:0,right:rect.width,top:0,bottom:rect.height};

    return rect.width;
  }

  function IsSecondPosition(timecode,  dwNumerator,  dwDenominator) {
    var fIs5994fps = (dwNumerator == 60000 && dwDenominator == 1001);
    var fIs2997fps = (dwNumerator == 30000 && dwDenominator == 1001);

    if (timecode.frame == 0) {
      return true;
    }
    else if (fIs2997fps) {
      return (timecode.frame == 2 && timecode.sec == 0 && (timecode.min % 10));
    }
    else if (fIs5994fps) {
      return (timecode.frame == 4 && timecode.sec == 0 && (timecode.min % 10));
    }
    else {
      return false;
    }
  }


  //int 
  function GetGradationType(llCurrent) {
    var timecode = new Timecode_CdrTimeCode(0,0,0,0);
    var dwFramesPerDay = parseInt(24 * 3600 * m_dwTimeScaleNumerator / m_dwTimeScaleDenominator);
    if (m_dwTimeScaleNumerator == 30000 && m_dwTimeScaleDenominator == 1001) {
      dwFramesPerDay = 2589408;
    }
    if (llCurrent >= dwFramesPerDay) {
      llCurrent = llCurrent % dwFramesPerDay;
    }
    TimecodeUtil.FrameToTimeCode(llCurrent, m_dwTimeScaleNumerator, m_dwTimeScaleDenominator, true, timecode);
    
    var type = GradationType_Unkonwn;
    if (IsSecondPosition(timecode, m_dwTimeScaleNumerator, m_dwTimeScaleDenominator)) {
      // 秒の境界
      type = GradationType_Sec;
      if (timecode.sec == 0) {
        type = GradationType_Min;
        if (timecode.min == 30) {
          type = GradationType_30Min;
        }
        else if (timecode.min == 0) {
          type = GradationType_Hour;
        }
      }
    }
    else if (m_nScaleSkipCount) {
      if (!(timecode.frame % m_nScaleSkipCount)) {
        type = GradationType_Frame;
      }
    }
    return type;
  }

  function CalcRectTimeScaleText(strText,nPos) {
    if (!ctx) { 
      return {left:nPos,right:nPos.width,top:0,bottom:50};
    }
    var metrics = ctx.measureText(strText);

    var rectTimeCode = {
      left:nPos,
      right:nPos + metrics.width,
      //Canvasはベースラインがtopとなる。-3は調整値
      top:m_mgnSeekBar.nBottom - 3,
      bottom:m_mgnSeekBar.nTop + 20
    };
    return rectTimeCode;
  }
  //LONGLONG
  function SecToFrame(lSec) {
    return parseInt(lSec * m_dwTimeScaleNumerator / m_dwTimeScaleDenominator);
  }

  //LONGLONG
  function GetDayFrames() {
    if (m_dwTimeScaleNumerator == 30000 && m_dwTimeScaleDenominator) {
      return 2589408;
    }
    else {
      return parseInt(24 * 3600 * 30000 / 1001);
    }
  }
  function DrawTimeScaleDF() {
    // if (!IsDrawTimeScaleEnabled()) {
    // 	return;
    // }
    if (!m_nScaleSkipCount) {
      return;
    }
    if (m_llDuration < 1) {
      return;
    }


    var llFrames = m_llDuration;

    var vecTimeScaleText = [];

    var fTempDraw = true;

    var nScaleWidth = GetSeekBarWidth();
    var nScaleStart = m_rectSeekBar.left + m_mgnSeekBar.nLeft;
    var nScaleEnd = m_rectSeekBar.right - m_mgnSeekBar.nRight;

    //_ASSERTE(m_mgnTimeScaleLine.nBottom > m_mgnTimeScaleLine.nTop);
    var nStartY = nStartY = m_rectSeekBar.top + m_mgnTimeScaleLine.nTop;
    var nEndY = m_rectSeekBar.top + m_mgnTimeScaleLine.nBottom;

    var nStartY_Hour = nStartY;
    var nStartY_30Min = Math.max(nStartY + 2, 0);
    var nStartY_Min = Math.max(nStartY + 3, 0);
    var nStartY_Sec = Math.max(nStartY + 4, 0);
    var nStartY_Frame = Math.max(nStartY + 6, 0);

    var nIndex = 0;
    var lRoundUpRate = parseInt((m_dwTimeScaleNumerator + m_dwTimeScaleDenominator - 1) / m_dwTimeScaleDenominator);

    var llCurrent = SecToFrame(m_lTimeLineOffset) + m_llOffset;
    var llAbsoluteStart = SecToFrame(m_lTimeLineOffset) + m_llOffset;

    if (m_llDuration > lRoundUpRate) {
      llCurrent -= lRoundUpRate;
    }
    llCurrent = Math.max(0, llCurrent);

    var strTimeCode_drawn = '';

    for (;;llCurrent++) {
      var llOffset = llCurrent - llAbsoluteStart;
      if (Math.abs(llOffset) > m_llDuration) {
        llOffset += GetDayFrames();
      }
      if (llOffset >= m_llDuration) {
        break;
      }
      var timecode = new Timecode_CdrTimeCode(0,0,0,0);
      TimecodeUtil.FrameToTimeCode(llCurrent, m_dwTimeScaleNumerator, m_dwTimeScaleDenominator, true, timecode);
    
      var type = GetGradationType(llCurrent);

      if (type >= GradationType_Frame) {
        var rectClient = m_rectSeekBar;

        var pt={x:0,y:0};
        var nStartX = parseInt(llOffset * nScaleWidth / (m_llDuration - 1)) + nScaleStart;
        pt.x = nStartX;
        pt.y = nStartY;

        if (type === GradationType_Hour) {
          nStartY = nStartY_Hour;
        }
        else if (type === GradationType_30Min) {
          nStartY = nStartY_30Min;
        }
        else if (type === GradationType_Min) {
          nStartY = nStartY_Min;
        }
        else if (type ===  GradationType_Sec) {
          nStartY = nStartY_Sec;
        }
        else {
          nStartY = nStartY_Frame;
        }

        if (nStartX >= m_rectSeekBar.left && nStartX <= m_rectSeekBar.right) {

          ctx.strokeStyle=colorScale;
          ctx.beginPath();
          ctx.moveTo(nStartX, nStartY);
          ctx.lineTo(nStartX, nEndY);
          ctx.closePath();
          ctx.stroke();
        }

        // TimeCodeの表示
        if (type >= GradationType_Sec) {
          // Lineを描くスペースがなければ描かない
          var fDraw = true;
          for (var j=0; j<vecTimeScaleText.length; j++) {
            if (( vecTimeScaleText[j].left <= pt.x ) && ( pt.x < vecTimeScaleText[j].right)) {
              if (( vecTimeScaleText[j].top <= pt.y ) && ( pt.y < vecTimeScaleText[j].bottom)) {
                fDraw = false;
                break;
              }
            }
          }

          // GetTimeCodeはオフセット分を内部で計算してくれる
          var strTimeCode = GetTimeCode(llOffset, HOUR_MIN_SEC);
          var rectTimeCode = {left:0,right:0,top:0,bottom:0};
          rectTimeCode = CalcRectTimeScaleText(strTimeCode,nStartX);

          // クリッピング(そもそも書かないほうが良いかも)
          // ⇒しない

          rectTimeCode.right = Math.min(rectTimeCode.right, m_rectSeekBar.right);

          // 他のタイムコード文字列領域と重なるときは書かない
          var fDrawText = true;
          for (var j=0; j<vecTimeScaleText.length; j++) {
            var rectInter;
            if (RectInRect(vecTimeScaleText[j], rectTimeCode)) {
              fDrawText = false;
              break;
            }
          }

          if (fDrawText) {
            if ( strTimeCode_drawn !== strTimeCode )  {
              ctx.font = canvasFont;
              ctx.fillStyle=colorText;
              ctx.strokeStyle=colorText;
              ctx.fillText(strTimeCode ,rectTimeCode.left,rectTimeCode.top);
              vecTimeScaleText.push(rectTimeCode);
            }

            strTimeCode_drawn = strTimeCode;	//書いたものを覚えておく
          }
        }
      }
    }
  }

  //LONGLONG 
  function SecToHz(lSec) {
    return lSec * m_dwTimeScaleNumerator;
  }


  function DrawTimeScale() {
    // if (!IsDrawTimeScaleEnabled()) {
    // 	return;
    // }

    if (m_nScaleSkipCount) {
      DrawTimeScaleDF();
      return;
    }
    if (m_llDuration < 1) {
      return;
    }
    GetSeekBarWidth();

    var llSecs = parseInt(m_llDuration * m_dwTimeScaleDenominator / m_dwTimeScaleNumerator);
    var llFrames = m_llDuration;

    var  vecTimeScaleText = [];

    var fTempDraw = true;
    // 開始点と終了点はタイムコードを描く
    //if (!(m_dwFlag & MarkedSeekBarFlag_DisableStartTc)) 
    {
      //CFont* pOldFont = NULL;
      if (m_llOffset == 0) {
        var strStartTimeCode = GetTimeCode(0, HOUR_MIN_SEC);
    
        var rectTimeCode = CalcRectTimeScaleText( strStartTimeCode,0);
        vecTimeScaleText.push(rectTimeCode);
        ctx.font = canvasFont;
        ctx.fillStyle=colorText;
        ctx.strokeStyle=colorText;
        ctx.fillText(strStartTimeCode ,rectTimeCode.left,rectTimeCode.top);
      }
    }
    /*
    //終了時のTCを書く場合
    if (!(m_dwFlag & MarkedSeekBarFlag_DisableEndTc)) {
      if (m_llOffset + m_llDuration >= m_llRealDuration) {
        var strEndTimeCode = GetTimeCode(m_llDuration, HOUR_MIN_SEC);
        var rectTimeCode = CalcRectTimeScaleText(strEndTimeCode,0);
        rectTimeCode.left = m_rectSeekBar.right - (m_rectSeekBar.right-m_rectSeekBar.left);
        rectTimeCode.right = m_rectSeekBar.right;
        ctx.font = canvasFont;
        ctx.fillStyle=colorText;
        ctx.strokeStyle=colorText;
        ctx.fillText(strStartTimeCode ,rectTimeCode.left,rectTimeCode.top);
        vecTimeScaleText.push(rectTimeCode);
      }
    }
    */
    var nScaleWidth = (m_rectSeekBar.right-m_rectSeekBar.left) - m_mgnSeekBar.nLeft - m_mgnSeekBar.nRight;
    var nScaleStart = m_rectSeekBar.left + m_mgnSeekBar.nLeft;
    var nScaleEnd = m_rectSeekBar.right - m_mgnSeekBar.nRight;

    //_ASSERTE(m_mgnTimeScaleLine.nBottom > m_mgnTimeScaleLine.nTop);
    var nStartY = nStartY = m_rectSeekBar.top + m_mgnTimeScaleLine.nTop;
    var nEndY = m_rectSeekBar.top + m_mgnTimeScaleLine.nBottom;

    var nStartY_Hour = nStartY;
    var nStartY_30Min = Math.max(nStartY + 2, 0);
    var nStartY_Min = Math.max(nStartY + 3, 0);
    var nStartY_Sec = Math.max(nStartY + 4, 0);
    var nStartY_Frame = Math.max(nStartY + 6, 0);

    var nIndex = 0;
    var lRoundUpRate = parseInt((m_dwTimeScaleNumerator + m_dwTimeScaleDenominator - 1) / m_dwTimeScaleDenominator);

    var llCurrent = SecToFrame(m_lTimeLineOffset) + m_llOffset;
    var llAbsoluteStart = SecToFrame(m_lTimeLineOffset) + m_llOffset;


    var timecodeStart = new Timecode_CdrTimeCode(0,0,0,0);;
    var timecodeEnd = new Timecode_CdrTimeCode(0,0,0,0);;
    TimecodeUtil.FrameToTimeCode(llAbsoluteStart, m_dwTimeScaleNumerator, m_dwTimeScaleDenominator, true, timecodeStart);
    TimecodeUtil.FrameToTimeCode((llAbsoluteStart + m_llDuration), m_dwTimeScaleNumerator, m_dwTimeScaleDenominator, true, timecodeEnd);

    var nSecsInterval = Math.max(parseInt(m_nScaleIncHz / m_dwTimeScaleNumerator), 1);
    var nSecsSubInterval = parseInt(nSecsInterval / m_nScaleDivCount);
    var nSecsStart = timecodeStart.hour * 3600 + timecodeStart.min * 60 + timecodeStart.sec;
    var nSecsEnd = timecodeEnd.hour * 3600 + timecodeEnd.min * 60 + timecodeEnd.sec + 1;

    nSecsStart -= nSecsStart % nSecsInterval;
    nSecsStart -= nSecsInterval;
    nSecsStart = Math.max(0, nSecsStart);

    if (nSecsStart > nSecsEnd) {		// 日またぎのチェック
      nSecsEnd = nSecsEnd + 24 * 3600;
    }

    var fRoundHour = m_lTimeLineOffset > 0 ? true : false;
    for (var nSecsCurrent = nSecsStart; nSecsCurrent < nSecsEnd; nSecsCurrent += nSecsSubInterval) {
      var timecodeCurrent = new Timecode_CdrTimeCode(0,0,0,0);;
      var timecodeCurrentAbsolute = new Timecode_CdrTimeCode(0,0,0,0);;
      var nSecsDisp = fRoundHour ? nSecsCurrent % (24 * 3600) : nSecsCurrent;			// オフセットが設定されている == 絶対時間 (24時==0時)
      timecodeCurrent.hour = parseInt(nSecsDisp / 3600);
      timecodeCurrent.min = parseInt((nSecsDisp % 3600) / 60);
      timecodeCurrent.sec = nSecsDisp % 60;
      timecodeCurrent.frame = 0;
      timecodeCurrentAbsolute.hour = parseInt(nSecsCurrent / 3600);
      timecodeCurrentAbsolute.min = parseInt((nSecsCurrent % 3600) / 60);
      timecodeCurrentAbsolute.sec = nSecsCurrent % 60;
      timecodeCurrentAbsolute.frame = 0;

      if (m_dwTimeScaleDenominator == 1001 && (m_dwTimeScaleNumerator == 30000 || m_dwTimeScaleNumerator == 60000)) {
        var fIs2997fps = !(m_dwTimeScaleNumerator == 60000 && m_dwTimeScaleDenominator == 1001) ? true : false;
        TimecodeUtil.NormalizeTimeCode(timecodeCurrent, fIs2997fps);
        TimecodeUtil.NormalizeTimeCode(timecodeCurrentAbsolute, fIs2997fps);
      }

      // 日をまたいでいるとllAbsoluteStartよりも小さくなってしまう
      llCurrent = TimecodeUtil.TimeCodeToFrame(m_dwTimeScaleNumerator, m_dwTimeScaleDenominator, true, timecodeCurrentAbsolute);

      var llOffset = llCurrent - llAbsoluteStart;
      if (Math.abs(llOffset) > m_llDuration) {
        llOffset += GetDayFrames();		
      }

      if (llOffset >= m_llDuration) {
        break;
      }

      var timecode = new Timecode_CdrTimeCode(0,0,0,0);;
      TimecodeUtil.FrameToTimeCode(llCurrent, m_dwTimeScaleNumerator, m_dwTimeScaleDenominator, true, timecode);
    
      var type = GetGradationType(llCurrent);

      var fDrawTimeCode = false;
      if (!(nSecsDisp % nSecsInterval)) {
        fDrawTimeCode = true;
      }

      if (type >= GradationType_Frame) {

        var rectClient = m_rectSeekBar;//			GetClientRect(&rectClient);

        var pt={x:0,y:0};

        var nStartX = parseInt(llOffset * nScaleWidth / (m_llDuration - 1)) + nScaleStart;
        pt.x = nStartX;
        pt.y = nStartY;

        if (type == GradationType_Hour) {
          nStartY = nStartY_Hour;
        }
        else if (type == GradationType_30Min) {
          nStartY = nStartY_30Min;
        }
        else if (type == GradationType_Min) {
          nStartY = nStartY_Min;
        }
        else if (type ==  GradationType_Sec) {
          nStartY = nStartY_Sec;
        }
        else {
          nStartY = nStartY_Frame;
        }

        if (m_mgnTimeScaleLine.nTop) {
          nStartY = Math.max(nStartY, m_rectSeekBar.top + m_mgnTimeScaleLine.nTop);
        }

        if (nStartX >= m_rectSeekBar.left && nStartX <= m_rectSeekBar.right) {
          ctx.strokeStyle=colorScale;
          ctx.beginPath();
          ctx.moveTo(nStartX, nStartY);
          ctx.lineTo(nStartX, nEndY);
          ctx.closePath();
          ctx.stroke();
        }

        // TimeCodeの表示
        if (fDrawTimeCode && type >= GradationType_Sec) {
          // Lineを描くスペースがなければ描かない
          var fDraw = true;
          for (var j=0; j<vecTimeScaleText.length; j++) {
            if (( vecTimeScaleText[j].left <= pt.x ) && ( pt.x < vecTimeScaleText[j].right)) {
              if (( vecTimeScaleText[j].top <= pt.y ) && ( pt.y < vecTimeScaleText[j].bottom)) {
                fDraw = false;
                break;
              }
            }
          }

          // GetTimeCodeはオフセット分を内部で計算してくれる
          var strTimeCode = GetTimeCode(llOffset, fRoundHour ? HOUR_MIN_SEC_ROUND : HOUR_MIN_SEC);
          var rectTimeCode = CalcRectTimeScaleText(strTimeCode,nStartX );

          // クリッピング(そもそも書かないほうが良いかも)
          // ⇒なし

          rectTimeCode.right = Math.min(rectTimeCode.right, m_rectSeekBar.right);

          // 他のタイムコード文字列領域と重なるときは書かない
          var fDrawText = true;
          for (var j=0; j<vecTimeScaleText.length; j++) {
            if (RectInRect(vecTimeScaleText[j], rectTimeCode)) {
              fDrawText = false;
              break;
            }
          }

          if (fDrawText) {
            ctx.font = canvasFont;
            ctx.fillStyle=colorText;
            ctx.strokeStyle=colorText;
            ctx.fillText(strTimeCode ,rectTimeCode.left,rectTimeCode.top);
            vecTimeScaleText.push(rectTimeCode);
          }
        }
      }
    }
  }
  return {
    initialize: initialize,
    DrawTimeScale: DrawTimeScale
  };
})();

export {TLSeekBar};
