'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('registerCompany', {
    url: '/register',
    templateUrl: 'app/company/register.html',
    controller: 'RegisterCtrl',
    title: 'サービス登録'
  })
  .state('registerOption', {
    url: '/regist-option?mail',
    templateUrl: 'app/company/registOption.html',
    controller: 'RegisterOptionCtrl',
    title: 'オプション登録'
  })
  .state('registerCompanyAdmin', {
    url: '/admin/register',
    templateUrl: 'app/company/register.html',
    controller: 'RegisterCtrl',
    title: 'サービス登録'
  })
  .state('listcompany', {
    url: '/admin/company',
    templateUrl: 'app/company/list/list.html',
    controller: 'CompanyListCtrl',
    authenticate: true,
    title: '企業一覧'
  })
  .state('settingscompany', {
    url: '/company/info',
    templateUrl: 'app/company/settings/settings.html',
    controller: 'SettingsCompanyCtrl',
    authenticate: true,
    title: '企業情報'
  });
}]);
