'use strict';

//ファイルreader関数 MyRedmine#2227
(function (module) {

  var fileReader = function ($q, $log) {

    var onLoad = function(reader, deferred, scope) {
        return function () {
          scope.$apply(function () {
            deferred.resolve(reader.result);
          });
        };
    };

    var onError = function (reader, deferred, scope) {
        return function () {
          scope.$apply(function () {
            deferred.reject(reader.result);
          });
        };
    };

    var onProgress = function(reader, scope) {
        return function (event) {
          scope.$broadcast("fileProgress",
              {
                total: event.total,
                loaded: event.loaded
              });
        };
    };

    var getReader = function(deferred, scope) {
      var reader = new FileReader();
      reader.onload = onLoad(reader, deferred, scope);
      reader.onerror = onError(reader, deferred, scope);
      reader.onprogress = onProgress(reader, scope);
      return reader;
    };

    var readAsDataURL = function (file, scope) {
      var deferred = $q.defer();

      var reader = getReader(deferred, scope);
      reader.readAsDataURL(file);

      return deferred.promise;
    };

    return {
      readAsDataUrl: readAsDataURL
    };
 };

module.factory("fileReader",
               ["$q", "$log", fileReader]);

}(angular.module("limaApp")));
//

angular.module('limaApp')
.controller('ThumbnailDialogCtrl', ['$scope', '$cookies', '$http', '$timeout', '$window', '$translate', '$uibModalInstance', 'FileUploader',  'fileReader' , 'Modal',
function($scope, $cookieStore, $http, $timeout, $window, $translate, $modalInstance, FileUploader, fileReader , Modal) {
  $scope.errorMessage = null;
  $scope.completeMessage = null;
  //$scope.content = content || {};
  $scope.temp = {
    // type: content.type,
    // thumbnailAutoUpdate: (content.type === 1 ? content.thumbnailAutoUpdate : 0) || 0
  };

  $scope.uploadResponse = null;
  $scope.close = function(){
    $modalInstance.close($scope.uploadResponse);
    $modalInstance.dismiss($scope.uploadResponse);

  }

  $scope.applyThumbnailAutoUpdate = function () {
    var data = {thumbnailAutoUpdate:(+$scope.temp.thumbnailAutoUpdate) || 0, updatedTime:$scope.content.updatedTime};
    var req = {method:'put', url:'/api/v1/device/config/upload', data:data};
    $http(req).then(function (res) {
      $scope.content.thumbnailAutoUpdate = res.data.thumbnailAutoUpdate;
      $scope.content.updatedTime = res.data.updatedTime;
      if (typeof $scope.$parent.openThumbnails === 'function') {
        //$scope.$emit('event:emit.miscs:updated', {content:res.data, closeDialog:false});
      }
    }, function (err) {
      $scope.temp.thumbnailAutoUpdate = $scope.content.thumbnailAutoUpdate;
    });
  };

  $scope.IEver = (function () { // copied from ../upload/upload.controller.js
    var rv = -1;
    if ($window.navigator.appName == 'Microsoft Internet Explorer') {
      var ua = $window.navigator.userAgent;
      var mr = ua.match(/MSIE ([0-9]{1,}[\.0-9]{0,})/i);
      if (mr && mr.length == 2)
        rv = parseFloat(mr[1]);
    } else if ($window.navigator.appName == 'Netscape') {
      var ua = $window.navigator.userAgent;
      var mr = ua.match(/Trident\/.*rv:([0-9]{1,}[\.0-9]{0,})/i);
      if (mr && mr.length == 2)
        rv = parseFloat(mr[1]);
    }
    return rv;
  })();

  $scope.selectFile = function () {
    $timeout(function () {
      angular.element('#file-select').click();
    }, 0);
  };

  //アップロードボタン押下
  $scope.uploadfile = function () {
    //autoUploadがfalseなので手動でアップロード
    $scope.uploader.uploadAll();
  };

  var token1 = $cookieStore.get('token');
  var options = {
    url: '/api/v1/device/config/upload' + '?access_token=' + encodeURIComponent(token1),
    method: 'post',
    headers: {},
    autoUpload: false       //プレビューで確認後にボタン押下で手動でアップロードさせるためfalse
  };
  var uploader = $scope.uploader = new FileUploader(options);

  var lastItem = null;
  uploader.onAfterAddingFile = function (item) {
    $scope.completeMessage = null;
    $scope.errorMessage = null;
    var file = item._file;
    if (file.type == 'image/jpeg' || file.type == 'image/pjpeg') {
      if (lastItem) lastItem.remove(); // remove from the uploading queue
      lastItem = item;
      fileReader.readAsDataUrl(file, $scope).then(function(result) { $scope.imageSrc = result; });
    } else {
      item.remove();
      $scope.errorMessage = $translate.instant('UPLOAD.MESSAGE_UNSUPPORTED' , {filename:file.name});
      $scope.imageSrc = "";
    }
  };

  uploader.onBeforeUploadItem = function(item) {
    $scope.errorMessage = null;
    $scope.completeMessage = null;
  };
  uploader.onCompleteAll = function(item, response, status, headers) { // On all loaded
  };

  uploader.onSuccessItem = function(fileItem, response, status, headers) {
    $scope.completeMessage = $translate.instant('UPLOAD.MESSAGE_DONE', {count: 1}); //完了メッセージの登録
    $scope.uploadResponse = {};
    $scope.uploadResponse.file = fileItem.file.name;
    $scope.uploadResponse.imageUrl = response.url

    if (lastItem === fileItem) lastItem = null;
    fileItem.remove();
  };

  uploader.onErrorItem = function (fileItem, response, status, headers) {
    $scope.errorMessage = response;
    if (lastItem === fileItem) lastItem = null;
    fileItem.remove();
  };

  // set the latest access token
  var token = $cookieStore.get('token');
  if (token && uploader.isHTML5)
    uploader.headers.Authorization = 'Bearer ' + token;
  else if (token)
    uploader.url += '?access_token=' + encodeURIComponent(token);

}]);

