'use strict';

var app = angular.module('limaApp');


app.controller('PlaylistSettingCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$location','$cookies','$uibModalInstance', '$translate', 'LimaPlaylistSettingData',
function($scope, socket, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,$translate, LimaPlaylistSettingData) {

  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }
  $scope.playlistId=LimaPlaylistSettingData.playlistId;
  $scope.playlist = null;
  $scope.playlistSetting = LimaPlaylistSettingData.playlistSetting;

  $http.get('/api/v1/configurations/Playlist').then(function (res) {
    if (res.data) {
      $scope.playlistConfig  = res.data;

      var data = $scope.playlistConfig.Animation;
      _.forEach(data, function(item,index){
        var newitem = {value:item};
        if (item === 'fade'){
          newitem.name = $translate.instant('PLAYLIST.SETTING.ANIMATION_FEDE');
        }
        else if (item === 'none'){
          newitem.name =$translate.instant('PLAYLIST.SETTING.ANIMATION_NONE');
        }
        $scope.playlistConfig.Animation[index] = newitem;
      });
      data = $scope.playlistConfig.ShowImageType;
      _.forEach($scope.playlistConfig.ShowImageType, function(item,index){
        var newitem = {value:item};
        if (item === 'fit'){
          newitem.name =$translate.instant('PLAYLIST.SETTING.FIT');
        }
        else if (item === 'cut'){
          newitem.name =$translate.instant('PLAYLIST.SETTING.CUT');
        }
        $scope.playlistConfig.ShowImageType[index] = newitem;
      });
      data = $scope.playlistConfig.repeatVideoType;
      _.forEach($scope.playlistConfig.repeatVideoType, function(item,index){
        var newitem = {value:item};
        if (item === 'repeat'){
          newitem.name =$translate.instant('PLAYLIST.SETTING.REPEAT');
        }
        else if (item === 'once'){
          newitem.name =$translate.instant('PLAYLIST.SETTING.ONCE');
        }
        $scope.playlistConfig.repeatVideoType[index] = newitem;
      });
    }
  });

  $scope.maxDuration = 24*60*60;  //24時間
  $scope.maxSpeed = $scope.maxDuration / 2;
  $scope.minDuration = 5;
  $scope.minSpeed = 0;

  $scope.errMessage = "";
  function checkSetting() {
    var nDuration = $scope.playlistSetting.changeContentDuration;
    if (_.isNull(nDuration)) {
      $scope.errMessage = $translate.instant('PLAYLIST.SETTING.MESSAGE_ANIMATION_NULL');
      nDuration = 0;
      return false;
    }
    var nSpeed = $scope.playlistSetting.changeContentAnimationSpeed;
    if (_.isNull(nSpeed)) {
      $scope.errMessage = $translate.instant('PLAYLIST.SETTING.MESSAGE_ANIMATION_DURATION_NULL');
      nSpeed = 0;
      return false;
    }
    if (nDuration < $scope.minDuration) {
      $scope.errMessage = $translate.instant('PLAYLIST.SETTING.MESSAGE_ANIMATION_SHORT');
      return false;
    }
    if (nDuration > $scope.maxDuration) {
      $scope.errMessage = $translate.instant('PLAYLIST.SETTING.MESSAGE_ANIMATION_LONG');
      return false;
    }
    if (nSpeed < $scope.minSpeed) {
      $scope.errMessage = $translate.instant('PLAYLIST.SETTING.MESSAGE_ANIMATION_DURATION_SHORT');
      return false;
    }
    if (nSpeed > $scope.maxSpeed) {
      $scope.errMessage = $translate.instant('PLAYLIST.SETTING.MESSAGE_ANIMATION_DURATION_LONG');
      return false;
    }

    if (nDuration / 2 < nSpeed) {
      $scope.errMessage = $translate.instant('PLAYLIST.SETTING.MESSAGE_ANIMATION_DURATION_LONG');
      return false;
    }

    return true;
  }

  $scope.apply = function () {
    if (checkSetting()) {
      $modalInstance.close($scope.playlistSetting);
      $modalInstance.dismiss('done');
    }
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }

}]);
