'use strict';

var app = angular.module('limaApp');


app.controller('ShareURLCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$location','$cookies','Modal', '$translate','$uibModalInstance','$filter', 'LimaShareURL',
function($scope, socket, Auth, $http, $timeout, $location, $cookieStore,Modal,$translate,$modalInstance,$filter, LimaShareURL) {

  $scope.formInput={};
  $scope.formInput.days = 3;
  $scope.createURL = function () {
    if ($scope.formInput.days < 1 || $scope.formInput.days > 7) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:'再生可能日数は１～７で設定してください。',
      modalClass:'modal-information'});

    } else {
      var validSec = $scope.formInput.days * 24 * 60 * 60;
      $http.get('/api/v1/playlists/' + LimaShareURL.playlist.playlistId + '/external?validSec=' + validSec).
      success(function(datas) {
        var endTime = new Date();
        endTime.setSeconds(endTime.getSeconds() + validSec);

        var mes = '<br>※' + $filter('limaFormatDate')(endTime,'YYYY/MM/DD hh:mm') + 'まで有効です。'
        //datas.url;
        copyToClipBoard(datas.url, function(ret) {
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
          html:ret ? "再生URLをクリップボードにコピーしました。" + mes : '作成に失敗しました。',
          modalClass:'modal-information'});

        });
      });
    }
  }

  function copyToClipBoard(text , callback) {

    //コピーしたいテキストを持つ要素を取得
//    let targetEle = document.getElementById('textURL');

    //ブラウザの対応状況で分岐
    if(navigator.clipboard){
      //クリップボードにコピーを実行
      navigator.clipboard.writeText(text).then(function(){
        //コピーに成功したときの処理...
        //console.log('copied.');
        callback(true);
      },function(){
        //コピーに失敗したときの処理...
        //console.log('clipboard denied.');
        callback(false);
      });
    }else if(window.clipboardData){
      //IE用の処理...もうないので
      callback(false);

    }else if(document.execCommand){

      //「input」要素を生成して属性値とstyleを設定
      let inputEle = document.createElement('input');
      inputEle.setAttribute('type', 'text');
      inputEle.setAttribute('value', text);
      inputEle.style.position = 'fixed';
      inputEle.style.left = '-100%';
      //「input」要素をbody内に挿入
      document.body.appendChild(inputEle);
      //テキストを選択状態にする
      inputEle.select();
      //コピーを実行
      let execResult = document.execCommand('copy');
      //「input」要素を削除
      inputEle.parentNode.removeChild(inputEle);
      //結果をコンソールに表示
      //console.log('execCommand : '+execResult);
      callback(execResult);

    }else{
      //クリップボードにアクセスできなかった場合の処理
      console.log('Can not copy. No permission and execCommand died.');
      callback(false);
    }

  }



  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  }

}]);
