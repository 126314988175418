'use strict';

var app = angular.module('limaApp');

app.controller('SelectExContentCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate', '$location','$cookies','$q','$uibModal','$uibModalInstance','ContentUtil',
function($scope, socket, Auth, $http, $timeout, $translate, $location, $cookieStore,$q,$modal, $modalInstance, ContentUtil) {


  $scope.m_arrExType = [
    {type:'url',      name:'URL', resource:'./resources/images/url.png', enable: true , row:2},
    {type:'youtube',  name:'YouTube', resource:'./resources/images/youtube.png', enable: true, row:2},
    {type:'telop',      name:'テロップ', resource:'./resources/images/baseline_title_black_48dp.png', enable: true, row:1},
    {type:'live',      name:'ライブ', resource:'./resources/images/live.png', enable: true, row:2},
    {type:'exinput',      name:'外部入力', resource:'./resources/images/exinput.png', enable: true, row:1},
    // {type:'autoupdated', type2:["/twitter/"],     name:'ついっとサイン', resource:'./resources/images/twit.png', enable: false},

    {type:'autoupdated', type2:["/line/"],     name:'LINE', resource:'./resources/images/LINE_Brand_icon.png', enable: false, row:1},
    {type:'autoupdated', type2:["/nds/","/kyodo_","/meteolo_weatherforecast_today/","/meteolo_weatherforecast_fewdays/","/meteolo_weatherforecast_week/"],   name:'マーカス', resource:'./resources/images/marcas.png', enable: false, row:1},

    {type:'autoupdated', type2:["/env_heatstroke_curwbgt/"],     name:'熱中症情報', resource:'./resources/images/marcas.png', enable: false, row:2},
    {type:'autoupdated', type2:["/poster/"],     name:'メニュー', resource:'./resources/images/poster.png', enable: false, row:1},


    // {type:'autoupdated', type2:"googledrive",     name:'googleドライブ', resource:'./resources/images/url.png', enable: false},
    // {type:'autoupdated', type2:"kyodo_generalnews",     name:'共同通マーカス信ニュース', resource:'./resources/images/url.png', enable: false},
    // {type:'autoupdated', type2:"kyodo_sportsnews",     name:'共同通信スポーツ', resource:'./resources/images/url.png', enable: false},

    // {type:'autoupdated', type2:"kyodo_originalnews",     name:'共同通信ニュース（オリジナル）', resource:'./resources/images/url.png', enable: false},
    // {type:'autoupdated', type2:"kyodo_originalsports",     name:'共同通信スポーツニュース（オリジナル）', resource:'./resources/images/url.png', enable: false},
    // {type:'autoupdated', type2:"kyodo_originalweather",     name:'共同通信天気（オリジナル）', resource:'./resources/images/url.png', enable: false},

    // {type:'autoupdated', type2:"kyodo_originalbousai",     name:'共同通信防災（オリジナル）', resource:'./resources/images/url.png', enable: false},
    // {type:'autoupdated', type2:"kyodo_originalmultinews",     name:'共同通信多言語マルチニュース（オリジナル）', resource:'./resources/images/url.png', enable: false},
    // {type:'autoupdated', type2:"kyodo_originalmultilang",     name:'共同通信多言語ニュース（オリジナル）', resource:'./resources/images/url.png', enable: false},


    // {type:'autoupdated', type2:"kyodo_one_week_weather",     name:'共同通信週間天気', resource:'./resources/images/url.png', enable: false},
    // {type:'autoupdated', type2:"kyodo_today_tomorrow_weather",     name:'共同通信３日間天気', resource:'./resources/images/url.png', enable: false},



  ];

  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  }

  function initAutoupdatesContent () {
    var params = {
      order: {'updatedTime':'desc'},
      type : ContentUtil.CONTENT_AUTOUPDATED
    }
    $http.get('/api/v1/content/contents' , {params:params}).
    success(function(datas) {
      _.forEach($scope.m_arrExType, function(extype) {
        if (extype.type2) {
          var find = _.find(datas, function(dt) {
            return _.find(extype.type2, function(tt) {
              return dt.imageUrl.indexOf(tt) !== -1;
            })
          });
          if (find) {
            extype.enable = true;
          }
        }
      })
    })
  }

  function getSystemInfo() {
    $http.get('/api/v1/system/systeminfo/info').
    success(function(datas) {
      if (datas.network) {
        if (!datas.network.internet) {
          _.find($scope.m_arrExType, function(extype) {
            if (extype.type === "youtube") {
              extype.enable = false;
              return true;
            }
          })
        }
      }
    });
  }

  //自動更新コンテンツがあるかどうかチェックしてアイコンだすかどうか決める
  $timeout(function() {
    initAutoupdatesContent();
    getSystemInfo();
  });

  $scope.modalUploadFile = function(list) {

    var controllerName = "";
    if (list.type==='url') {
      controllerName = "LocalModalHTMLCtrl";
    }
    else if (list.type==='youtube') {
      controllerName = "LocalModalYoutubeCtrl";
    }
    else if (list.type==='autoupdated') {
      controllerName = "LocalModalAutoUpdatedCtrl";
      $modalInstance.close(list);
      return;
    }
    else if (list.type==='live') {
      controllerName = "LocalModalLiveCtrl";
    }
    else if (list.type==='exinput') {
      var list={materials:
        [
          {
              "id": "-exinput",
              "type": 4,
              "title": "外部入力",
              "description": "",
              // "remarks": "",
              // "registeredDate": "2024-05-18T18:03:04.425Z",
              // "availableStartDate": null,
              // "availableEndDate": null,
              // "wseConfiguration": null,
              // "userName": "admin0001",
              // "companyId": "00000043"
          }
      ]};
      $modalInstance.close(list);
      return;
    }
    else if (list.type==='telop') {
      controllerName = "LocalModalTextCtrl";
    }

    var modalInstance = $modal.open({
      templateUrl: 'app/content/local.html',
      controller: controllerName,
      windowClass: 'modal-upload-window-ex',
      //windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
      size: 'lg',
      resolve: {
      }
    });
    modalInstance.result.then(function(results) {
      $modalInstance.close(results);

    }, function() {
      //閉じられた
      $modalInstance.dismiss('cancel');
//        console.log("aaa");
    });

  }



}]);

