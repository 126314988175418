'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('deviceGroup', {
    url: '/deviceGroup',
    templateUrl: 'app/deviceGroup/deviceGroup.html',
    controller: 'DeviceGroupCtrl',
    authenticate: true,
    //functionName: 'SystemManagement',
    title: 'Device Groups'
  });
}]);
