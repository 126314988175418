'use strict';

var app = angular.module('limaApp');

app.controller('SystemStatusCtrl', ['$scope', '$http', '$filter', '$translate', '$timeout','$window', '$cookies', '$uibModal', 'Auth','limaDiskService','limaOptionService',
function ($scope, $http, $filter, $translate, $timeout, $window, $cookies,$modal, Auth,limaDiskService,limaOptionService) {
  $scope.exportLogs = function () {
    $window.open('/api/v1/logs/archived?access_token=' + encodeURIComponent($cookies.get('token')), '_self');
    return false;
  };

  $window.document.title  = $translate.instant('NAVIGATION.SYSTEM_STATUS');

  //S3アップロードしてるときはSignedCookieを取得する
  $scope.awsDiskUsageInMB = {};
  $scope.awsDiskUsageInMB.licensed = 0;
  $http.get('/api/v1/configurations/AWSS3UploadServer').success(function (resAws) {
    $scope.awsDiskUsageInMB.enabled = resAws.enabled;
    if (resAws.enabled) {
      $http.get('/api/v1/system-info/aws').then(function (res) {
        $scope.awsDiskUsageInMB.use = {size: $filter('number')(Math.round(res.data.totalSize / 1024)),data:res.data.totalSize};
        //$scope.awsdiskUsageInMB.available = {size: $filter('number')(Math.round(info.diskUsageInKB.available / 1024))};
      });
    } else {
      $scope.diskUsageInMB = {};
      $http.get('/api/v1/system-info/onpre').then(function (res) {
        $scope.diskUsageInMB.use = {size: $filter('number')(Math.round(res.data.totalSize / 1024)),data:res.data.totalSize};
      });

    }
  });


  $scope.deviceTransferSizeInMB = {};
  $scope.deviceTransferSizeInMB.warning = {};
  $scope.deviceTransferSizeInMB.warning.size = limaDiskService.getTransferWarningRemainSize();  //50M

  $scope.getDiskUsage = function(){
    if ($scope.awsDiskUsageInMB.use && $scope.awsDiskUsageInMB.use.data && $scope.awsDiskUsageInMB.licensed.data) {
      var dd = $scope.awsDiskUsageInMB.use.data > $scope.awsDiskUsageInMB.licensed.data ? $scope.awsDiskUsageInMB.licensed.data : $scope.awsDiskUsageInMB.use.data;
      return $filter('number')(Math.round(dd / 1024));
    }
    return 0;
  }
  $scope.getTransferUsage = function(){
    if ($scope.deviceTransferSizeInMB.use && $scope.deviceTransferSizeInMB.use.data && $scope.deviceTransferSizeInMB.licensed.data) {
      var dd = $scope.deviceTransferSizeInMB.use.data > $scope.deviceTransferSizeInMB.licensed.data ? $scope.deviceTransferSizeInMB.licensed.data : $scope.deviceTransferSizeInMB.use.data;
      return $filter('number')(Math.round(dd / 1024 / 1024));
    }
    return 0;
  }

  function calcDeviceTransferedSize() {
    limaDiskService.getTransferInfo(function(data){
      console.log("自動更新コンテンツ転送量(MByte)：" + $filter('number')(Math.round(data.size_autoUpdated / 1024 / 1024)));
      $scope.deviceTransferSizeInMB.use = {size: $filter('number')(Math.round(data.size / 1024 / 1024)), data:data.size};
      $scope.deviceTransferSizeInMB.warning.message = '';
      if ($scope.deviceTransferSizeInMB.licensed.data <= $scope.deviceTransferSizeInMB.use.data ) {
        $scope.deviceTransferSizeInMB.warning.message = $translate.instant('PLAYLIST.MESSAGE_NO_DISK_TO_REFLECT');
      }
      else if ($scope.deviceTransferSizeInMB.licensed.data - $scope.deviceTransferSizeInMB.use.data < $scope.deviceTransferSizeInMB.warning.size ) {
        $scope.deviceTransferSizeInMB.warning.message = $translate.instant('PLAYLIST.MESSAGE_LITTLE_DISK_TO_REFLECT');
      }
    });
  }

  function calcDeviceLicensedSize() {
    var TransferMaxSize = limaOptionService.getTransferMaxSize(optionService);

    $scope.deviceTransferSizeInMB.licensed = {size: $filter('number')(Math.round(TransferMaxSize / 1024 / 1024)), data:TransferMaxSize};
    $scope.awsDiskUsageInMB.licensed = {size: $filter('number')(Math.round(TransferMaxSize / 1024 / 1024)), data: TransferMaxSize};
  }


  $scope.devicelists = [];
  var getDevicelist = function() {
    $http.get('/api/v1/device/device').
    success(function(datas) {
      $scope.devicelists = datas;
    });
  };
  //

  var optionService = null;
  limaOptionService.getOptionService(function(result) {
    optionService = result;
    calcDeviceLicensedSize();
    calcDeviceTransferedSize();
  });

   getDevicelist();


   $scope.getOption = function(optionId){
    var find = _.find($scope.optionService, function(sc){
      return sc.optionServiceId === optionId ? true : false;
    });
    if (find) {
      return find;
    }
    return null;
  }



  var m_sysInfo = {};
  Auth.systemProperties().then(function (data) {
    var versions = [];
    if (data['webapi.revision']) versions.push({name:$translate.instant('SYSTEM.STATUS.VERSIONS.WEBAPP'), value:'rev.' + data['webapi.revision']});
    if (data['database.version']) versions.push({name:$translate.instant('SYSTEM.STATUS.VERSIONS.DATABASE'), value:'rev.' + data['database.version']});
    if (versions.length) $scope.versions = versions;

    m_sysInfo.movieMaxSize = Number(data['setting.system.movieMaxSize'] || "500");
    m_sysInfo.playlistContentCount = Number(data['setting.system.playlistContentCount'] || "30");
    var temp = data['setting.system.contentEncrypt'] || "0";
    m_sysInfo.contentEncryptEnable = temp !== "0" ? true : false;

  });

  $scope.settings = [];
  function getSettingValue(datas){
    //初期化
    $scope.settings['setting.server'] = {};
    $scope.settings['setting.ui'] = {};

    _.find(datas, function(dt) {
      if (dt.name === "setting.server") {
        var obj = dt.value;
        $scope.settings['setting.server'] = obj;
      };
      if (dt.name === "setting.ui") {
        var obj = dt.value;
        $scope.settings['setting.ui'] = obj;
      };
    });

    //設定がなかったらシステムデフォルトから持ってくる
    if (_.isUndefined($scope.settings['setting.server'].contentEncryptEnable)){
      $scope.settings['setting.server'].contentEncryptEnable = m_sysInfo.contentEncryptEnable
    }
    if (_.isUndefined($scope.settings['setting.ui'].playlistContentCount)){
      $scope.settings['setting.ui'].playlistContentCount = m_sysInfo.playlistContentCount
    }
    if (_.isUndefined($scope.settings['setting.ui'].movieMaxSize)){
      $scope.settings['setting.ui'].movieMaxSize = m_sysInfo.movieMaxSize
    }

  }

  function getSetting(){
    $http.get('/api/v1/setting?companyId=' + Auth.getCurrentUser().companyId).
    success(function(data) {
      $timeout(function() {
        getSettingValue(data);
      });
    });
  }

  function getEsysInfo(){
    //LINE情報
    $http.get('/api/v1/content/extra/esys/line').
    success(function(data) {
      $scope.esysInfo = data;
      getEsysLineStatus(function() {
        //
      })
    });
  }

  //for eSys LINE
  $scope.esysInfo = {};
  function getEsysLineStatus(callback){
    $http.get('/api/v1/content/extra/esys/line/status/' + $scope.esysInfo.contractInfo.contents_contract_id).
    success(function(data) {
      //状態
      $timeout(function() {
        $scope.esysInfo.lineStatus = data;
      })
    });
  }

  function getEsysLineStatus(callback){
    $http.get('/api/v1/content/extra/esys/line/status/' + $scope.esysInfo.contractInfo.contents_contract_id).
    success(function(data) {
      //状態
      $timeout(function() {
        $scope.esysInfo.lineStatus = data;
      })
    });
  }

  function getEsysInfo(){
    //LINE情報
    $http.get('/api/v1/content/extra/esys/line').
    success(function(data) {
      $scope.esysInfo = data;
      getEsysLineStatus(function() {
        //
      })
    });
  }
  $scope.isEsysLlineEnable = function() {
    if ($scope.esysInfo) {
      if ($scope.esysInfo.contractInfo) {
        return $scope.esysInfo.contractInfo.contents_contract_id;
      }
    }
    return false;
  }

  $scope.showEsysLineInfo = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/system/status/esys/esysLineInfo.html',
      controller: 'ESysLineInfoCtrl',
      //windowClass: 'modal-fit',
        size: 'lg',
        backdrop: 'static', keyboard: false,
//      size: 'lg',
      resolve: {
        esysInfo: function() {
          return {
            info : $scope.esysInfo
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
    }, function() {
      getEsysInfo();
    });
  }

  getEsysInfo();
  getSetting();

  //for eSys LINE

}]);
