'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('system-device', {
    url: '/system/device',
    templateUrl: 'app/system/device/system-device.html',
    controller: 'SystemDeviceCtrl',
    authenticate: true,
    functionName: 'SystemMonitoring',
    title: 'System Device'
  });
}]);
