'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('contentlist', {
    url: '/contentlist',
    templateUrl: 'app/content/list/contentList.html',
    controller: 'ContentListCtrl',
    title: 'コンテンツ一覧',
    authenticate: true
  });
}]);
