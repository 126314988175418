'use strict';


var app = angular.module('limaApp');

app.controller('DeviceCtrl', ['$scope', 'socket', '$stateParams', 'Auth','$http', '$timeout', '$location','$cookies','$q','$uibModal','Modal','$translate','$window', 'limaOptionService', 'limaDeviceService',
function($scope, socket, $stateParams,Auth, $http, $timeout, $location, $cookieStore, $q,$modal, Modal,$translate,$window,limaOptionService, limaDeviceService) {

  $window.document.title  = $translate.instant('NAVIGATION.DEVICE');

  $scope.debugMode = false;
  if (!_.isUndefined($stateParams.debug)) {
    $scope.debugMode = $stateParams.debug === 'false' ? false : true;
  }

  limaDeviceService.isOverDeviceAllowed(function(bOver){
    if (bOver) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:$translate.instant('DEVICE.MESSAGE_OVER_COUNT2'),
      modalClass:'modal-danger'});
    }
  });


  $scope.controlLists = [];
  var getControlLists = function() {
    $http.get('/api/v1/controls').
    success(function(datas) {
      $scope.controlLists.items = datas.controls;
      $scope.controlLists.selected = $scope.controlLists.items[0];
    });

  };
  getControlLists();

  $scope.doDeviceCacheClear = function(){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "キャッシュクリアしますか",
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;

      var arrParam = [];
      var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
      _.forEach(selected, function(sel){
        if (sel.deviceId){
          var param = {
            deviceId:sel.deviceId
          }
          arrParam.push(param);
        }
      });

      socket.emit('lima:call-request:v1:device:cache:clear',{data:arrParam});
    });
  }
  $scope.doDeviceGotoSetting = function(){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "設定画面に移動させますか",
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;

      var arrParam = [];
      var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
      _.forEach(selected, function(sel){
        if (sel.deviceId){
          var param = {
            deviceId:sel.deviceId
          }
          arrParam.push(param);
        }
      });

      socket.emit('lima:call-request:v1:device:gui:show:setting',{data:arrParam});
    });
  }


  $scope.deviceGroups = [];
  function getDeviceGroups(callback) {
    $scope.deviceGroups = [];
    $http.get('/api/v1/device/groups').
    success(function(datas) {
      //自分が閲覧できるグループを確認
      if (Auth.isAdmin()) {
        $scope.deviceGroups.groups = datas;
      } else {
        var userId = Auth.getCurrentUser().id;
        $scope.deviceGroups.groups = _.filter(datas, function(gr){
          if (gr.typeDefault) {
            //All
            return true;
          } else {
            var userIds = JSON.parse(gr.userIds);
            var findUser = _.find(userIds, function(id) {
              return id === userId;
            });
            return findUser;
          }
        });
      }
      callback();
    });

  }



  $scope.devicelists = [];
  var getDevicelist = function() {
    $http.get('/api/v1/device/device').
    success(function(datas) {
      getDeviceGroups(function() {
        if (Auth.isAdmin()) {
          $scope.devicelists = datas;
        } else {
          $scope.devicelists = _.filter(datas, function(dt){
            //グループに含まれるデバイスだけにする
            var findGrp = _.find($scope.deviceGroups.groups, function(gp) {
              var deviceIds = JSON.parse(gp.deviceIds);
              var findDevice = _.find(deviceIds,function(dvId) {
                return dvId === dt.deviceId;
              });
              return findDevice;
            });
            return findGrp;
          });
        }
      });

    });

  };
  getDevicelist();

  $scope.getName = function(macaddr) {
    var info = _.find($scope.devicelists, function(list){
      if (list.macaddr === macaddr) {
        return true;
      }
    });

    if (info) {
      if (_.isUndefined(info.name)) {
        return info.brand + ' ' + info.model;
      } else {
        return info.name;
      }
    }
    return '';
  };

  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = [];
  function getChannels() {
    $scope.channelsInfo.channels = [];
    $http.get('/api/v1/device/groups').
    success(function(datas) {
      $scope.channelsInfo.channels = _.filter(datas, function(item){
        if (!item.typeDefault) {
          return true;
        }
      });
    });
  }
  getChannels();

  $scope.getChannel = function(list){
    if (list.channel) {
      return list.channel[0].name;
    } else if ($scope.channelsInfo.channels.length) {
      var arrName = [];
      var findGroup = _.filter(list.groupIds, function(gr){
        var find = _.filter($scope.channelsInfo.channels, function(ch) {
          if (ch.groupId === gr.groupId) {
            arrName.push(ch.name );
            return true;
          }
        });
        return gr.enableGroup;
      });
      return arrName.join(' / ');
    } else {
      return "";

    }
  }

  $scope.checkedlist = [];
  $scope.UpdateCheckedList = (function() {
    $scope.checkedlist = _.filter($scope.devicelists, function(list){return list.checked;});
  });

  $scope.deleteDevice = function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('DEVICE.SELECTED_DEVICE')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      //削除

      var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
      {
        var deferred = $q.defer();
        var promise = deferred.promise;

          var requests = _.forEach(selected, function(item){
          promise = promise.finally(function () {
            return $http.delete('/api/v1/device/device/' + item.deviceId).finally(function () {
              console.log(item.id);
            });
          });
        });

        promise = promise.finally(function () {
          console.log('end delete');
          $timeout(function(){
            //
            getDevicelist();
            $scope.checkedlist = [];

          });
        });

        deferred.resolve();

      }
    });

  };

  $scope.message = {};
  $scope.message.CreateSuccess = "";

  function TriggerAlertClose() {
    window.setTimeout(function () {
        $(".alert").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateSuccess = "";
        });
    }, 3000);
  }

  socket.on('lima:device:channel:status:updated', function (res) {
    console.log("lima:device:channel:status:updated");


  });

  socket.on('lima:device:unregistered', function (res) {
    console.log("unregistered");

    $scope.message.CreateSuccess = $translate.instant('DEVICE.DISCONNECTED');
    TriggerAlertClose();

    getDevicelist();

  });
  socket.on('lima:device:registered', function (res) {
    console.log("registered");
    $timeout(function() {
      $scope.message.CreateSuccess = $translate.instant('DEVICE.CONNECTED');
      TriggerAlertClose();

      getDevicelist();

    });

  });

  socket.on('lima:device:status:filedownload', function (res) {
    var find = _.find($scope.devicelists, function(list){
      return res.deviceId === list.deviceId ? true : false;
    });
    if (find) {
      $timeout(function() {
        find.filedownloadStatus = res;
        console.log("filedownload " + res);
      });
      if (find.filedownloadStatus) {
        if (find.filedownloadStatus.status >= 2) {
          $timeout(function() {
            find.filedownloadStatus = null;
          } , 3000);
        }
      }
    }
  });


  // $scope.changeChannel = function() {
  //   //$scope.channelsInfo.select
  //   socket.connect().then(function () {
  //     // socket.scope($scope, 'notifications:change', function (ev, notifications) {
  //     //   //console.log(notifications);
  //     //   $scope.notifications = _.filter(notifications, {active: true});
  //     //   resize.facade();
  //     // });
  //     socket.emit('lima:call-request:v1:device:channel',{id:$scope.channelsInfo.select.id});
  //   });

  //   $http.post('/api/v1/device/socket',{channelId:$scope.channelsInfo.select.id}).
  //   success(function(datas) {
  //   });

  var uploadReqInfo = {
    logFileName: '',
    deviceCount:0
  };
  $scope.uploadReqesting = false;
  function checkUploadedLog(){
    $http.get('/api/v1/device/log/').
    success(function(files) {
      console.log(files);
      var finds = _.filter(files, function(ff) {
        if (ff.indexOf(uploadReqInfo.logFileName) !== -1 ) {
          return true;
        }
      });
      if (finds.length !== uploadReqInfo.deviceCount) {
        $timeout(function(){
          checkUploadedLog();
        },5000);
      } else {
        $scope.uploadReqesting = false;

        _.forEach(finds, function(ff){
          var link = document.createElement('a');
          var fileNameIndex = ff.lastIndexOf("/") + 1;
          var filename = ff.substr(fileNameIndex);
          link.download = filename;
          link.href = ff;
          link.click();

        });

      }
    });
  }

  // }
  $scope.doCheckDebugLog = function(enable){
    var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
    socket.emit('lima:call-request:v1:device:setting',{devices:selected,level:enable ? 'debug' : 'info'});
  };

  $scope.youtube = {wait_sec :7};//XMP-8550向けYouTube再生マージン：8550にだけ効きます
  $scope.doSetYouTubeWaitSec = function(enable){
    var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
    socket.emit('lima:call-request:v1:device:setting',{devices:selected,youtube_wait_sec:$scope.youtube.wait_sec});
  };

  $scope.deviceSetting = {timer_keepalive :60*60*1000}; //default
  $scope.doSetKeepTimer = function(){
    var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
    socket.emit('lima:call-request:v1:device:setting',{devices:selected,timer_keepalive:$scope.deviceSetting.timer_keepalive});
  };



  $scope.getDeviceLog = function () {
    var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
    uploadReqInfo.deviceCount = selected.length;
    var now = new Date();
    uploadReqInfo.logFileName = ('000' + (now.getFullYear() + 0)).slice(-4) +
    ('0' + (now.getMonth() + 1)).slice(-2) +
    ('0' + (now.getDate() + 0)).slice(-2) +
    "_" +
    ('0' + (now.getHours() + 0)).slice(-2) +
    ('0' + (now.getMinutes() + 0)).slice(-2) +
    ('0' + (now.getSeconds() + 0)).slice(-2) +
    '.zip';

    $scope.uploadReqesting = false;
    $http.post('/api/v1/device/log' , {devices:selected,filename:uploadReqInfo.logFileName}).
    success(function(datas) {
      console.log(datas);

      var findSocket = _.find(datas, function(d){
        if (d.socketId.length || d.firebaseToken.length){
          return true;
        }
      });
      if (findSocket) {
        $scope.uploadReqesting = true;
        $timeout(function(){
          checkUploadedLog();
        },5000);
      }

    });


    //socket.emit('lima:call-request:v1:device:getlog',{devices:selected,filename:uploadReqInfo.logFileName});

  };

  $scope.enableDeviceLimit = 1;
  $scope.enableDeviceGroup = false;
  $scope.enableDeviceControl = false;
  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'base' ? true : false;
    });
    if (find) {
      $scope.enableDeviceLimit = find.amount;
    }
    find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.enableDeviceGroup = true;
    }
    find = _.find(result, function(op){
      return op.name === 'DeviceControl' ? true : false;
    });
    if (find) {
      $scope.enableDeviceControl = true;
    }

  });


  $scope.showDetail = function(list){
    var modalInstance = $modal.open({
      templateUrl: 'app/device/info/deviceInfo.html',
      controller: 'DeviceInfoCtrl',
      backdrop: 'static', keyboard: false,
      size: 'lg',
      resolve: {
        deviceInfo: function() {
          return {
            list:_.cloneDeep(list),
            name: $scope.getName(list.macaddr)
          };
        }
      }
    });
    modalInstance.result.then(function (results) {
      // OK
    }, function () {
      // cancel
    });




  }

  $scope.editDevice = function () {
    //$event.stopPropagation();
    var  selected = _.filter($scope.devicelists, function(list){return list.checked;});

    var modalInstance = $modal.open({
      templateUrl: 'app/device/edit/editDevice.html',
      controller: 'EditDeviceCtrl',
      backdrop: 'static', keyboard: false,
      resolve: {
        editDevice: function() {
          return {
            enableDeviceGroup: $scope.enableDeviceGroup,
            lists:_.cloneDeep(selected),
            channels:$scope.channelsInfo.channels
          };
        }
      }
    });
    modalInstance.result.then(function (results) { // OK
      {
        var deferred = $q.defer();
        var promise = deferred.promise;

        var arrPost = [];
        if ($scope.enableDeviceGroup) {
          _.forEach(selected, function(item){
            var post = {
              http : '/api/v1/device/device/' + item.deviceId + '/group',
              method : 'POST',
              item : {groupIds:results.channels}
            };
            arrPost.push(post);
          });
        }

        _.forEach(results.lists, function(item){
          var post = {
            http : '/api/v1/device/device/' + item.deviceId,
            method : 'PUT',
            item : item
          };
          arrPost.push(post);
        });


        var allowedDevices = _.filter($scope.devicelists, function(dv){
          return dv.allowed;
        });
        var enableAllowed = $scope.enableDeviceLimit - allowedDevices.length;
        if (enableAllowed < 0) {
          enableAllowed = 0;
        }

        //まず不許可にするものから
        _.filter(results.lists,function(item){
          var findDv = _.find($scope.devicelists, function(dv){
            return dv.deviceId === item.deviceId ? true : false;
          });
          if (findDv) {
            if (findDv.allowed !== item.allowed) {
              if (!item.allowed) {
                var post = {
                  http : '/api/v1/device/device/' + item.deviceId + '/allowed',
                  method : 'POST',
                  item : {allowed:item.allowed}
                };
                arrPost.push(post);
                enableAllowed += 1;
                return true;
              }
            }
          }
        });
        //許可できる数設定
        if (enableAllowed > $scope.enableDeviceLimit) {
          enableAllowed = $scope.enableDeviceLimit;
        }

        //許可にするもの.filterの戻り値は許可できなかったもの
        var arrDisable = _.filter(results.lists,function(item){
          var findDv = _.find($scope.devicelists, function(dv){
            return dv.deviceId === item.deviceId ? true : false;
          });
          if (findDv) {
            if (findDv.allowed !== item.allowed) {
              if (item.allowed) {
                if (enableAllowed > 0) {
                  var post = {
                    http : '/api/v1/device/device/' + item.deviceId + '/allowed',
                    method : 'POST',
                    item : {allowed:item.allowed}
                  };
                  enableAllowed -= 1;
                  arrPost.push(post);
                } else {
                  return true;
                }
              }
            }
          }
        });

          var requests = _.forEach(arrPost, function(item){
          promise = promise.finally(function () {
            return $http({method:item.method,url:item.http ,data:item.item}).
            success(function(datas) {
              console.log(datas);

            });
          });
        });

        promise = promise.finally(function () {
          console.log('end update');
          $timeout(function(){

            if (arrDisable.length) {
              Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
              html:$translate.instant('DEVICE.MESSAGE_OVER_COUNT'),
              modalClass:'modal-danger'});
            }
            _.forEach(results.lists, function(item){
              _.find(arrDisable, function(dv){
                if (item.deviceId === dv.deviceId) {
                  item.allowed = false; //数をオーバーしていて有効にできないデバイスは有効をOFF
                  return true;
                }
              });
              _.find($scope.devicelists, function(dv){
                if (item.deviceId === dv.deviceId) {
                  if (item.allowed !== dv.allowed) {
                    socket.emit('lima:call-request:v1:device:allow',item);
                  }
                  if (item.name !== dv.name) {
                    socket.emit('lima:call-request:v1:device:name',item);
                  }
                }
              });
            });


            $timeout(function(){
              getDevicelist();
              $scope.checkedlist = [];
            });



          });
        });

        deferred.resolve();

      }





    }, function () { // cancel
    });
  };

  var testTimer = null;
  $scope.testSocket = function() {
    if (!testTimer) {
      testTimer = setInterval(function() {
        socket.emit('lima:call-request:v1:device:test',{id:123});
//     }   , 60*60*1000);  //1時間
      }, 10*1000  );  //1時間
    }
  }
  $scope.testSocketStop = function() {
    if (testTimer) {
      clearInterval(testTimer);
      testTimer = null;

    }
  }

  $scope.isEnableConnectId = function(list){
    if (!list.socketId.length) {
      if (list.firebaseToken) {
        if (list.firebaseToken.length) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

}]);
