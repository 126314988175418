'use strict';

var app = angular.module('limaApp');

app.controller('ModalMailConfirmCtrl', ['$scope', '$http', '$filter', '$translate', '$location', '$cookies', 'Auth','$uibModalInstance', '$window', 'LimaModalMailData',
function ($scope, $http, $filter, $translate, $location, $cookies, Auth,$modalInstance,$window, LimaModalMailData) {

  $window.document.title  = $translate.instant('NAVIGATION.MAIL');

  $scope.mailData = LimaModalMailData.mailData;

  $scope.doSendMail = function() {
    $modalInstance.dismiss({event:'send', data:$scope.mailData});
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  }

}]);

app.controller('MailCtrl', ['$scope', '$http', '$filter', '$translate', '$location', '$cookies', 'Auth','$uibModal','Modal','$timeout',
function ($scope, $http, $filter, $translate, $location, $cookies, Auth,$modal,Modal,$timeout) {

  $scope.mailData = {};
  $scope.mailData.text = {title:$translate.instant('HELP.MAIL.TEXT'), value:''};
  $scope.mailData.email = {title:$translate.instant('HELP.MAIL.MAIL'), value:''};
  $scope.mailData.name = {title:$translate.instant('HELP.MAIL.NAME'), value:''};

  $scope.network = {};
  function getSystemInfo() {
    $http.get('/api/v1/system/systeminfo/info').
    success(function(datas) {
      if (datas.network) {
        $scope.network = datas.network;
      }
    });
  }
  $timeout(function(){
    getSystemInfo();
  })

  $scope.downloadApp = {};
  $http.get('/api/v1/users/me').
  success(function(datas) {
    $scope.userinfo=datas;
  });

  $scope.sendMail = function() {
    if (_.isUndefined($scope.mailData.name.value) ){
      return;
    }
    if ($scope.mailData.name.value.length === 0) {
      return;
    }
    if (_.isUndefined($scope.mailData.email.value) ){
      return;
    }
    if ($scope.mailData.email.value.length === 0) {
      return;
    }
    if (_.isUndefined($scope.mailData.text.value) ){
      return;
    }
    if ($scope.mailData.text.value.length === 0) {
      return;
    }

    var modalInstance = $modal.open({
      templateUrl: 'app/system/mail/mailconfirm.html',
      controller: 'ModalMailConfirmCtrl',
      windowClass: 'modal-property-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaModalMailData: function() {
          return {
            mailData:$scope.mailData
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
    }, function(results) {
      //
      if (results.event) {
        if (results.event === 'send') {
          var postdata = results.data;
          postdata.datetime = $filter('date')(new Date(),'yyyy-MM-dd HH:mm:ss');
          //postdata.userinfo = $scope.userinfo;
          $http.post('/api/v1/company/mail/contact' ,postdata ).
          success(function(res){
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('HELP.MAIL.MESSAGE_SENT'), modalClass:'modal-success'},
            function () {
              //初期化
              $timeout(function() {
                $scope.mailData.text.value='';
                $scope.mailData.email.value='';
                $scope.mailData.name.value='';
              });
            });
          });
        }
      }
    });
  }

}]);

app.controller('MailInquiryCtrl', ['$scope', '$http', '$filter', '$stateParams','$translate', '$location', '$cookies', 'Auth','$uibModal','Modal','$timeout',
function ($scope, $http, $filter, $stateParams, $translate, $location, $cookies, Auth,$modal,Modal,$timeout) {

  $scope.inquiry = true;
  $scope.debugMode = false;
  if (!_.isUndefined($stateParams.debug)) {
    $scope.debugMode = $stateParams.debug === 'false' ? false : true;
  }

  $scope.mailData = {};
  $scope.mailData.text = {title:$translate.instant('HELP.MAIL.TEXT'), value:''};
  $scope.mailData.email = {title:$translate.instant('HELP.MAIL.MAIL'), value:''};
  $scope.mailData.name = {title:$translate.instant('HELP.MAIL.NAME'), value:''};
  $scope.mailData.companyName = {title:"会社名", value:''};


  $scope.network = {};
  // function getSystemInfo() {
  //   $http.get('/api/v1/system/systeminfo/info').
  //   success(function(datas) {
  //     if (datas.network) {
  //       $scope.network = datas.network;
  //     }
  //   });
  // }
  // $timeout(function(){
  //   getSystemInfo();
  // })

  $scope.downloadApp = {};
  // $http.get('/api/v1/users/me').
  // success(function(datas) {
  //   $scope.userinfo=datas;
  // });

  $scope.sendMail = function() {
    if (_.isUndefined($scope.mailData.companyName.value) ){
      return;
    }
    if (_.isUndefined($scope.mailData.name.value) ){
      return;
    }
    if ($scope.mailData.name.value.length === 0) {
      return;
    }
    if (_.isUndefined($scope.mailData.email.value) ){
      return;
    }
    if ($scope.mailData.email.value.length === 0) {
      return;
    }
    if (_.isUndefined($scope.mailData.text.value) ){
      return;
    }
    if ($scope.mailData.text.value.length === 0) {
      return;
    }
    if ($scope.debugMode) {
      $scope.mailData.mailto = "tsunagaru@mediaedge.co.jp";
    }

    var modalInstance = $modal.open({
      templateUrl: 'app/system/mail/mailconfirm.html',
      controller: 'ModalMailConfirmCtrl',
      windowClass: 'modal-property-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaModalMailData: function() {
          return {
            mailData:$scope.mailData
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
    }, function(results) {
      //
      if (results.event) {
        if (results.event === 'send') {
          var postdata = results.data;
          postdata.datetime = $filter('date')(new Date(),'yyyy-MM-dd HH:mm:ss');
          //postdata.userinfo = $scope.userinfo;
          $http.post('/api/v1/company/mail/contact/external' ,postdata ).
          success(function(res){
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('HELP.MAIL.MESSAGE_SENT'), modalClass:'modal-success'},
            function () {
              //初期化
              $timeout(function() {
                $scope.mailData.text.value='';
                $scope.mailData.email.value='';
                $scope.mailData.name.value='';
                $scope.mailData.companyName.value='';

              });
            });
          });
        }
      }
    });
  }

}]);

