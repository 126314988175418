'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('playlist', {
    url: '/',
    templateUrl: 'app/playlist/playlist.html',
    controller: 'PlaylistSimpleCtrl',
    title: 'プレイリスト一覧',
    authenticate: true
  })
  .state('playlistDetail', {
//    url: '/playlist/detail',
    url: '/playlist-detail',
    templateUrl: 'app/playlist/playlist.html',
    controller: 'PlaylistCtrl',
    title: 'プレイリスト一覧',
    authenticate: true
  });
}]);
