'use strict';

var app = angular.module('limaApp');


app.controller('ConfirmCheckCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$location','$sce','Modal', '$translate','$uibModalInstance','$filter', 'ConfirmCheck',
function($scope, socket, Auth, $http, $timeout, $location, $sce,Modal,$translate,$modalInstance,$filter, ConfirmCheck) {

  $scope.ConfirmCheck=ConfirmCheck;

  $scope.trustAsHtml = function(s) {
    return $sce.trustAsHtml(s);
  }
  $scope.ok = function() {

    $modalInstance.dismiss('done');
  }


  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  }

}]);
