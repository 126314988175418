'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('mail', {
    url: '/system/mail',
    templateUrl: 'app/system/mail/mail.html',
    controller: 'MailCtrl',
    authenticate: true,
    functionName: 'SystemMonitoring',
    title: 'お問い合わせ'
  })
  .state('mail_for_opre', {
    url: '/inquiry?debug',
    templateUrl: 'app/system/mail/mail.html',
    controller: 'MailInquiryCtrl',
    //authenticate: true,
    //functionName: 'SystemMonitoring',
    title: 'お問い合わせ'
  });
}]);
