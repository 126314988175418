'use strict';

import './app/app.css';
import './app/app.js';
import './app/account/account.js';
import './app/account/login/login.controller.js';
import './app/account/login/login.css';
import './app/account/logout/logout.controller.js';
import './app/account/logout/logout.css';
import './app/account/settings/settings.controller.js';
import './app/main/main.controller.js';
import './app/main/main.css';
import './app/main/main.js';
import './app/system/system.controller.js';
import './app/system/system.css';
import './app/system/system.js';
import './app/system/status/status.controller.js';
import './app/system/status/status.css';
import './app/system/status/status.js';
import './app/user/user.controller.js';
import './app/user/user.css';
import './app/user/user.js';
import './app/user/addUser/addUser.controller.js';
import './app/user/addUser/addUser.css';
import './app/user/addUser/addUser.js';
import './app/user/editUser/editUser.controller.js';
import './app/user/editUser/editUser.css';
import './app/user/editUser/editUser.js';
import './app/userGroup/userGroup.controller.js';
import './app/userGroup/userGroup.css';
import './app/userGroup/userGroup.js';
import './app/userGroup/addGroup/addGroup.controller.js';
import './app/userGroup/addGroup/addGroup.css';
import './app/userGroup/addGroup/addGroup.js';
import './app/userGroup/editGroup/editGroup.controller.js';
import './app/userGroup/editGroup/editGroup.css';
import './app/userGroup/editGroup/editGroup.js';

//
import './app/content/content.controller.js';
import './app/content/content.js';
import './app/content/content.css';
import './app/content/modalLayout.js';
import './app/content/modalPreview.js';
import './app/content/modalProperty.js';
import './app/content/contentPopup.controller.js';


import './app/content/dlg/setting.controller.js';

import './app/content/list/content.controller.js';
import './app/content/list/content.js';


import './app/content/edit/edit.controller.js';
import './app/content/edit/edit.js';
import './app/content/edit/edit.css';
import './app/content/edit/seekbar.js';
import './app/content/edit/TimecodeUtil.js';

import './app/content/transcode/preset/preset.controller.js';
import './app/content/transcode/preset/preset.js';
import './app/content/transcode/preset/preset.css';

import './app/content/transcode/list/translist.controller.js';
import './app/content/transcode/list/translist.js';
import './app/content/transcode/list/translist.css';


import './app/content/local.controller.js';
import './app/content/local.js';

import './app/content/selectExternalContent.controller.js';
import './app/content/dlg/selectContent.controller.js';


import './app/option/option.controller.js';
import './app/option/option.js';

import './app/playlist/playlist.controller.js';
import './app/playlist/playlist.js';

import './app/playlist/newName.controller.js';
import './app/playlist/newName.js';

import './app/playlist/dialog/shareURL.controller.js';
import './app/playlist/dialog/confirmCheck.controller.js';

import './app/playlist/setting/setting.controller.js';
import './app/playlist/setting/setting.js';

import './app/device/device.controller.js';
import './app/device/device.js';
import './app/device/edit/editDevice.controller.js';
import './app/device/edit/editDevice.css';
import './app/device/edit/editDevice.js';

import './app/device/setting/deviceSetting.controller.js';
import './app/device/setting/deviceSetting.css';
import './app/device/setting/deviceSetting.js';
import './app/device/setting/img-upload-dialog.js';

import './app/device/info/deviceInfo.controller.js';


import './app/deviceGroup/deviceGroup.controller.js';
import './app/deviceGroup/deviceGroup.css';
import './app/deviceGroup/deviceGroup.js';
import './app/deviceGroup/addGroup/addGroup.controller.js';
import './app/deviceGroup/addGroup/addGroup.css';
import './app/deviceGroup/addGroup/addGroup.js';
import './app/deviceGroup/editGroup/editGroup.controller.js';
import './app/deviceGroup/editGroup/editGroup.css';
import './app/deviceGroup/editGroup/editGroup.js';
import './app/deviceGroup/dialog/selectDevice.controller.js';
import './app/deviceGroup/dialog/selectUser.controller.js';

// import './app/content/registSNS.controller.js';
// import './app/content/registSNS.js';

import './app/channel/channel.controller.js';
import './app/channel/channel.js';

import './app/channel/add/addChannel.controller.js';
import './app/channel/add/addChannel.js';

import './app/schedule/schedule.controller.js';
import './app/schedule/schedule.js';
import './app/schedule/scheduleItem.controller.js';
import './app/schedule/scheduleItem.js';
import './app/schedule/schedule-edit.controller.js';
import './app/schedule/schedule-edit.js';
import './app/schedule/schedule-edit.css';


import './app/schedule/scheduleItemTable.controller.js';


import './app/layout/layout.controller.js';
import './app/layout/layout.js';
import './app/layout/layout.css';
import './app/layout/dialog/editLayoutDlg.controller.js';

import './app/layout/newlayout.controller.js';
import './app/layout/newlayout.js';
import './app/layout/newlayout.css';

import './app/company/register.controller.js';
import './app/company/register.js';
import './app/company/register.css';
import './app/company/registOption.controller.js';

import './app/company/settings/settings.controller.js';
import './app/company/list/list.controller.js';


//
// import './app/instantsignage/layout/layout.controller.js';
// import './app/instantsignage/search/search.controller.js';
// import './app/instantsignage/select/select.controller.js';
// import './app/instantsignage/setting/setting.controller.js';
// import './app/instantsignage/upload/upload.controller.js';

// import './app/instantsignage/layout/layout.js';
// import './app/instantsignage/search/search.js';
// import './app/instantsignage/search/search.css';
// import './app/instantsignage/select/select.js';
// import './app/instantsignage/select/select.css';
// import './app/instantsignage/setting/setting.js';
// import './app/instantsignage/select/modalLayout.js';
// import './app/instantsignage/select/modalPreview.js';
// import './app/instantsignage/upload/upload.js';
//
import './app/system/device/system-device.controller.js';
import './app/system/device/system-device.css';
import './app/system/device/system-device.js';

import './app/system/manual/manual.controller.js';
import './app/system/manual/manual.css';
import './app/system/manual/manual.js';

import './app/system/mail/mail.controller.js';
import './app/system/mail/mail.css';
import './app/system/mail/mail.js';

import './app/system/manual/preview/preview.controller.js';
import './app/system/manual/preview/preview.css';
import './app/system/manual/preview/preview.js';

import './app/system/status/esys/esysLineSetting.controller.js';
import './app/system/status/esys/esysLineInfo.controller.js';

import './components/utils.js';
import './components/auth/auth.service.js';
import './components/auth/user.service.js';
import './components/modal/modal.css';
import './components/modal/modal.service.js';
import './components/navbar/navbar.controller.js';
import './components/navbar/navbar.css';
import './components/socket/socket.service.js';

import './components/dialog/dlgSelectItem.controller.js';
