'use strict';

var app = angular.module('limaApp');
const Encoding = require('encoding-japanese');
var async =require('async');

// app.service('S3UploadService', ['$q', function ($q) {
//   // Us standard region
//   AWS.config.region = 'us-northeast-1';
//   AWS.config.update({ accessKeyId: 'AKIAJM3ZVI5HXDZQFMGA', secretAccessKey: '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' });

//   var bucket = new AWS.S3({ params: { Bucket: 'lima-connect01', maxRetries: 10 }, httpOptions: { timeout: 360000 } });

//   this.Progress = 0;
//   this.Upload = function (file) {
//       var deferred = $q.defer();
//       var params = { Bucket: 'lima-connect01', Key: file.name, ContentType: file.type, Body: file };
//       var options = {
//           // Part Size of 10mb
//           partSize: 10 * 1024 * 1024,
//           queueSize: 1,
//           // Give the owner of the bucket full control
//           ACL: 'bucket-owner-full-control'
//       };
//       var uploader = bucket.upload(params, options, function (err, data) {
//           if (err) {
//               deferred.reject(err);
//           }
//           deferred.resolve();
//       });
//       uploader.on('httpUploadProgress', function (event) {
//           deferred.notify(event);
//       });

//       return deferred.promise;
//   };
// }]);


app.directive('fileReaderModules', ['$http','$translate', '$filter', 'Modal',
function fileReaderDirective($http, $translate,$filter,  Modal) {

  return {
    scope: {
      fileReader:"="
    },
    link: function(scope, element) {
      $(element).on('click', function(changeEvent) {
        //inputタグのchangeイベントをキャッチする時に、「同じファイルを選択する」時changeイベントが発火しない
        changeEvent.target.value = '';
      });
      $(element).on('change', function(changeEvent) {
        var files = changeEvent.target.files;
        if (files.length) {
          var filename = files[0].name;

          var reader = new FileReader();
          reader.onload = function (e) {
              // 8ビット符号なし整数値配列と見なす
              var array = new Uint8Array(e.target.result);

              // 文字コードを取得
              switch (Encoding.detect(array)) {
              case 'UTF16':
                  // 16ビット符号なし整数値配列と見なす
                  array = new Uint16Array(e.target.result);
                  break;
              case 'UTF32':
                  // 32ビット符号なし整数値配列と見なす
                  array = new Uint32Array(e.target.result);
                  break;
              }

              // Unicodeの数値配列に変換
              var unicodeArray = Encoding.convert(array, 'UNICODE');
              // Unicodeの数値配列を文字列に変換
              var contents = Encoding.codeToString(unicodeArray);

              contents = contents.replace(/[\r\n\t\v\f]/g, "");


              var nowText = document.getElementById("inputtext").value;
              var nowTitle = document.getElementById("inputtitle").value;


              function baseName(str)
              {
                 var base = new String(str).substring(str.lastIndexOf('/') + 1);
                  if(base.lastIndexOf(".") != -1)
                      base = base.substring(0, base.lastIndexOf("."));
                 return base;
              }

              var file_basename = baseName(filename);

              if (!nowText.length) {

                document.getElementById("inputtext").value = contents;
                document.getElementById("inputtitle").value = file_basename;

                let event_title = new Event('change_by_import');
                let target_title = document.getElementById('inputtitle');
                target_title.dispatchEvent(event_title);

                let event = new Event('change_by_import');
                let target = document.getElementById('inputtext');
                target.dispatchEvent(event);

              } else {
                Modal.confirm.yesno({
                  title: $translate.instant('COMMON.CONFIRM'),
                  html: "現在のテキストに追加してインポートしますか？<br>追加しない場合は現在のテキストは破棄され、インポートしたテキストで置き換わります。",
                  btnClassYes: 'btn-default',
                  btnCaptionYes: $translate.instant('COMMON.YES'),
                  btnClassNo: 'btn-danger',
                  btnCaptionNo: $translate.instant('COMMON.NO'),
                  modalClass: 'modal-danger'
                }, function (yes) {
                  if (yes){
                    document.getElementById("inputtext").value = nowText + contents;
                    if (nowTitle.length) {
                      document.getElementById("inputtitle").value = nowTitle + "_" + file_basename;
                    } else {
                      document.getElementById("inputtitle").value = file_basename;
                    }
                  } else {
                    document.getElementById("inputtext").value = contents;
                    document.getElementById("inputtitle").value = file_basename;
                  }

                  let event_title = new Event('change_by_import');
                  let target_title = document.getElementById('inputtitle');
                  target_title.dispatchEvent(event_title);

                  let event = new Event('change_by_import');
                  let target = document.getElementById('inputtext');
                  target.dispatchEvent(event);

                });

              }




          }
          reader.readAsArrayBuffer(files[0]);


        }
      });
    }
  };
}
]);

app.directive('ngThumb', ['$window', function($window) {
  var helper = {
      support: !!($window.FileReader && $window.CanvasRenderingContext2D),
      isFile: function(item) {
          return angular.isObject(item) && item instanceof $window.File;
      },
      isImage: function(file) {
          var type =  '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
          return '|jpg|png|jpeg|bmp|gif|mp4|quicktime|pdf|'.indexOf(type) !== -1;
      }
  };

  return {
      restrict: 'A',
      template: '<canvas/>',
      link: function(scope, element, attributes) {
          if (!helper.support) return;

          var params = scope.$eval(attributes.ngThumb);

          if (!helper.isFile(params.file)) return;
          if (!helper.isImage(params.file)) return;

          var canvas = element.find('canvas');
          var reader = new FileReader();

          reader.onload = onLoadFile;
          reader.readAsDataURL(params.file);

          var rotate = 0;
          EXIF.getData(params.file, function() {
              var result = '';

              if (this && this.exifdata && this.exifdata.Orientation) {
                switch (this.exifdata.Orientation) {
                  case 3:
                    rotate = 180;
                    break;
                  case 6:
                    rotate = 90;
                    break;
                  case 8:
                    rotate = -90;
                    break;
                }
              }


          });

          function onLoadFile(event) {
              var img = new Image();
              img.onload = onLoadImage;
              img.src = event.target.result;
          }

          function onLoadImage() {
              var width = params.width || this.width / this.height * params.height;
              var height = params.height || this.height / this.width * params.width;
              canvas.attr({ width: width, height: height });

              var context = canvas[0].getContext('2d');
              context.save();
              // 回転の中心に原点を移動する
              context.translate((width/2), (height/2));
              // canvasを回転する
              if (rotate){
                  context.rotate(rotate * Math.PI / 180);
              }
              // 画像サイズの半分だけずらして画像を描画する
              context.drawImage(this, -(width/2), -(height/2),width,height);
              // コンテキストを元に戻す
              context.restore();

          }
      }
  };
}]);


app.controller('LocalCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate', '$filter','$cookies','$q','$uibModal','Modal','FileUploader','Upload','limaOptionService', 'limaDiskService',
function($scope, socket, Auth, $http, $timeout, $translate, $filter, $cookieStore,$q,$modal,Modal, FileUploader,ngFileUpload, limaOptionService, limaDiskService) {

  $scope.isModal = false;
  $scope.isFile = true;
  $scope.isText = false;
  $scope.isHTML = false;
  $scope.isAutoUpdated = false;

  $scope.isDirectUploadS3 = false;

  $scope.isRegistering = false; //登録中

  //AWSの設定読み込んでおく
  $scope.AWSS3DirectUploadConfig = null;
  $http.get('/api/v1/configurations/AWSS3DirectUpload').then(function (res) {
    if (res.data) {
      $scope.AWSS3DirectUploadConfig = res.data;
      $scope.isDirectUploadS3 = $scope.AWSS3DirectUploadConfig.enabled;
    }
  });

  $scope.uploadMax = {size:500};
  $scope.settings ={playlistContentCount : 30};
  $scope.settings_compny = [];
  var m_sysInfo = {}
  function getInfo(infos, name){
    if (_.isUndefined(infos[name])) {
      return '';
    }
    return infos[name];
  }
  function getSettingValue(datas){
    //初期化
    $scope.settings_compny['setting.server'] = {};
    $scope.settings_compny['setting.ui'] = {};

    _.find(datas, function(dt) {
      if (dt.name === "setting.server") {
        var obj = dt.value;
        $scope.settings_compny['setting.server'] = obj;
      };
      if (dt.name === "setting.ui") {
        var obj = dt.value;
        $scope.settings_compny['setting.ui'] = obj;
      };
    });

    //設定がなかったらシステムデフォルトから持ってくる
    Auth.systemProperties().then(function (data) {
      m_sysInfo = {};
      m_sysInfo.movieMaxSize = Number(getInfo(data , 'setting.system.movieMaxSize') || "500");
      m_sysInfo.playlistContentCount = Number(getInfo(data , 'setting.system.playlistContentCount') || "30");
      var temp = getInfo(data , 'setting.system.contentEncrypt') || "0";
      m_sysInfo.contentEncryptEnable = temp !== "0" ? true : false;

      if (_.isUndefined($scope.settings_compny['setting.server'].contentEncryptEnable)){
        //$scope.settings_compny['setting.server'].contentEncryptEnable = m_sysInfo.contentEncryptEnable
      }
      if (_.isUndefined($scope.settings_compny['setting.ui'].playlistContentCount)){
        $scope.settings.playlistContentCount = m_sysInfo.playlistContentCount;
      } else {
        $scope.settings.playlistContentCount = Number($scope.settings_compny['setting.ui'].playlistContentCount);
      }
      if (_.isUndefined($scope.settings_compny['setting.ui'].movieMaxSize)){
        $scope.uploadMax.size = m_sysInfo.movieMaxSize
      } else {
        $scope.uploadMax.size = Number($scope.settings_compny['setting.ui'].movieMaxSize);
      }
    });
  }

  function getSetting() {
    var companyId = Auth.getCurrentUser().companyId;
    $http.get('/api/v1/setting' + '?companyId=' + companyId)
    .success(function (res) {
      if (res) {
        getSettingValue(res);
      }
    });
  }
  getSetting();

  $scope.getFileSize = function(size) {
    return $filter('limaDisplayBytes')(size);
  }

  var token = $cookieStore.get('token');
  //keepOriginalName=0
  //linuxなどのサーバーに上げると日本語を含むファイル名が正しく読めないことがあるので、
  //サーバー側で元ファイル名を使わずランダムな英数字文字列のファイル名に変換して登録する（4ee47b6355a522268c32ff165c70104b.pngとか）
  var uploadUrl =  '/api/v1/content/uploads' + '?keepOriginalName=0&access_token=' + encodeURIComponent(token);
  var uploader = $scope.uploader = new FileUploader({
    url:  uploadUrl,//'/api/v1/content/uploads' + '?keeporiginal=0&access_token=' + encodeURIComponent(token),
    method: 'post',

//    disableMultipart: true
  });
//  uploader.filters.push({
//    name: 'imageFilter',
//    fn: function(item /*{File|FileLikeObject}*/, options) {
//        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
//        return '|jpg|png|jpeg|bmp|gif|mp4|quicktime|'.indexOf(type) !== -1;
//    }
//  });

  $scope.progressStatus = function(item){
    if (item.isSuccess) {
      return $translate.instant('UPLOAD.COMPLETE');
    }
    if (item.isCancel) {
      return $translate.instant('COMMON.CANCEL');
    }
    if (item.isError) {
      if(item._xhr.response.includes('text is long')){
        return $translate.instant('UPLOAD.MESSAGE_TEXT_IS_TOO_LONG');
      }else{
        return $translate.instant('UPLOAD.ERROR');
      }
    }
    if (item.progress === 100) {
      if (item.isUploading) {
        return $translate.instant('UPLOAD.ENDING');
      }
      if (item.isReady) {
        return '';
      }
      if (item.isUploaded) {
        return $translate.instant('UPLOAD.ENDING');
      }
    }
    return item.progress + '%' ;
  }

  $scope.upload = function(item) {
   item.formData.push({text:item.text!==undefined?item.text:""});
   item.upload();


  }

  $scope.FilesS3 = {};
  $scope.onFileSelect = function(files) {
    $scope.FilesS3 = files;
    _.forEach($scope.FilesS3, function(file , index){
        // var filename = files[0].name;
        // var type = files[0].type;
        // var query = {
        //     filename: filename,
        //     type: type
        // };
        $scope.uploadFileToS3(file,index);
    });
  };
  $scope.uploadFileToS3=function(file , index){
    //amazon aws credentials
    AWS.config.update({
        accessKeyId : $scope.AWSS3DirectUploadConfig.AWSSetting.AccessKeyId,
        secretAccessKey : $scope.AWSS3DirectUploadConfig.AWSSetting.SecretAccessKey
    });
    //amazon s3 region
    AWS.config.region = $scope.AWSS3DirectUploadConfig.AWSSetting.Region;
    //amazon s3 bucket name
//    var bucket = new AWS.S3({params: {Bucket: 'lima-connect02'}});
    var bucket = new AWS.S3({ params: { Bucket: $scope.AWSS3DirectUploadConfig.AWSSetting.S3BacketName, maxRetries: 10 }, httpOptions: { timeout: 360000 } });
    var params = {Key:file.name , ContentType: file.type, Body: file};

    var options = {
        // Part Size of 10mb
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        // Give the owner of the bucket full control
        ACL: 'bucket-owner-full-control'
    };

    bucket.upload(params,options).on('httpUploadProgress', function(evt) {
        //logs the image uploading progress
        //console.log("Uploaded :: " + parseInt((evt.loaded * 100) / evt.total)+'%');
        var progress=parseInt((evt.loaded * 100) / evt.total);
        var find = _.find($scope.FilesS3 , function(f){
          if (f.name === evt.key) {
            return true;
          }
        });

        $timeout( function() {find.progress = progress;} , 0 );
        if(progress === 100){
          find.isSuccess = true;
          //alert("file uploaded successfully");
        }
    }).send(function(err, data) {
        if (data) {
          //displays the image location on amazon s3 bucket
          $scope.FilesS3[parseInt(data.key)].isError = true;
          console.log(data.Location);
        }
    });
}

  var formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };


  $scope.materials = [];
  $scope.uploadedItems = [];

  var allowVideoExt = ['mpg', 'mpeg', 'm2p', 'm2t', 'mts', 'm2ts'];
  var allowAudioExt = ['mp3', 'wav'];
  var allowImageExt = ['jpeg', 'jpg','bmp','png','gif'];
  var allowQuickTimeExt = ['mp4'];
  var allowOtherExt = ['html', 'txt','telop','swf'];
  var allowTelopExt = ['txt','telop'];
  var allowFlashExt = ['swf'];
  var allowHTMLExt = ['html'];


  $scope.closePopup = function() {
  }




  var optionService = null;
  limaOptionService.getOptionService(function(result) {
    optionService = result;
  });

  function isPDF() {
    var find = _.find(optionService, function(op){
      return op.name === 'PDF' ? true : false;
    });
    return find ? true : false;
  }
  function isMovie() {
    var find = _.find(optionService, function(op){
      return op.name === 'Movie' ? true : false;
    });
    return find ? true : false;
  }

  var MINE_PDF  = 'application/pdf';
  var MINE_VIDEO_MP4  = 'video/mp4';
  var MINE_VIDEO_QT  = 'video/quicktime';
  var MINE_IMAGE_JPG  = 'image/jpeg';
  var MINE_IMAGE_PNG  = 'image/png';
  var MINE_TXT  = 'text/plain';

  var diskInfo = null;
  limaDiskService.getDiskInfo(function(result){
    diskInfo = result;
  });


  $scope.uploaditemCanceled = function(item) {

    if (item.progress < 100 ) {
      console.log("cancel OK");
      item.cancel();
    } else {
      console.log("cancel NG");
    }
    return;

  }

  $scope.uploaditemDeleted = function(item) {

    //アップロードされてしまってるのでAPIでちゃんと消さないといけない
    if (item.id) {
      $http.delete('/api/v1/content/contents/' + item.id)
      .finally(function () {
        console.log('delete ==> ' + item.id);
      });
    }

    $scope.contentCount -= 1;
    if ($scope.contentCount < 0 ) {
      $scope.contentCount = 0;
    }
    _.find($scope.uploader.queue, function(queue){
      if (queue.enableLimaUpload.enable === false) {
        if  (queue.enableLimaUpload.type === 'count' ) {
          queue.enableLimaUpload.enable = true;
          queue.upload();
          return true;
        }
      }
    });

    var deleteItem = _.find($scope.uploadedItems, function(dt){
      if (dt.$$hashKey === item.$$hashKey) {
        return true;
      }
    });

    if (deleteItem) {
      $scope.materials = _.filter($scope.materials, function(mat){
        if (deleteItem.id !== mat.id) {
          return true;
        }
      });

    } else {
      console.log("not deleteItem");
    }

  }

  $scope.uploader.onAfterAddingAll = function (addedItems) {
    var enablePDF = isPDF();
    var enableMovie = isMovie();
    var totalUploadSize = 0;

    diskInfo = null;
    limaDiskService.getDiskInfo(function(result){
      diskInfo = result;

      if (diskInfo.awsDiskUsageInMB.use) {
        totalUploadSize = diskInfo.awsDiskUsageInMB.use.sizeByte;
      }
      var TransferMaxSize = limaOptionService.getTransferMaxSize(optionService);
      _.forEach(addedItems,function(item){
        var bUpload = false;
        var type = 'unsupported';
        if (item.file.type.indexOf(MINE_IMAGE_JPG) !== -1 || item.file.type.indexOf(MINE_IMAGE_PNG) !== -1 ) {
          bUpload = true;
        }
        else if (item.file.type.indexOf(MINE_TXT) !== -1 ) {
          bUpload = true;
        }
        else if (item.file.type.indexOf(MINE_PDF) !== -1 ) {
          if (enablePDF) {
            bUpload = true;
          } else {
            type = 'type';
          }
        }
  //      else if (item.file.type.indexOf(MINE_VIDEO_MP4) !== -1 || item.file.type.indexOf(MINE_VIDEO_QT) !== -1 ) {
        else if (item.file.type.indexOf(MINE_VIDEO_MP4) !== -1 ) {
          if (enableMovie) {
            if (item.file.size <= $scope.uploadMax.size * 1024 * 1024) {
              bUpload = true;
            } else {
              type = 'contentSize';
            }
          } else {
            type = 'type';
          }
        }

        if (bUpload) {
          if ((totalUploadSize + item.file.size) > TransferMaxSize) {
            item.enableLimaUpload = {enable : false,type:'size' };
          } else {
            $scope.contentCount += 1;
            if ($scope.contentCount > $scope.settings.playlistContentCount) {
              item.enableLimaUpload = {enable : false,type:'count' };
            } else {
              totalUploadSize += item.file.size;
              item.enableLimaUpload = {enable : true };
            }
          }
        } else {
          item.enableLimaUpload = {enable : false,type:type };
        }
      });
      /***/
      console.log("onAfterAddingAll");
      $timeout(function(){
        var filterItems = _.filter(addedItems, function(item){
          return item.enableLimaUpload.enable;
        });
        $scope.bUploadStarted = true;
        _.forEach(filterItems, function(item){
          item.upload();
        });
      },100);


    });

/***/




  }

  var arrParam = [];

//   $scope.modalInstance = null;
//   $scope.hideWait = function(){
//     if ($scope.modalInstance) {
//       $scope.modalInstance.close();
//       $scope.modalInstance = null;
//     }
//   }

//   $scope.showWait = function(title){

// ////////////////
//     var scope = {modal: {
//       dismissable: true,
//       title: title,
//       html: '<div><span class="glyphicon glyphicon-refresh glyphicon-refresh-animate")></span>' + '  ' + title + '...' + '</div>'
//     }};

//     var modalScope = $scope.$new();
//     scope = scope || {};
//     scope.trustAsHtml = scope.trustAsHtml || function (s) { return $sce.trustAsHtml(s); };
//     var modalClass = 'modal-warning';

//     angular.extend(modalScope, scope);

//     $scope.modalInstance = $modal.open({
//       templateUrl: 'components/modal/modal.html',
//       windowClass: modalClass,
//       backdrop: 'static', keyboard: false,
//       size: 'sm',
//       scope:modalScope
//     });
//     $scope.modalInstance.result.then(function(results) {
//     }, function() {
//       //閉じられた
// //        console.log("aaa");
//     });


//   }

$scope.bUploadStarted = false;
$scope.bCompleteAll = false;
  $scope.isCompleteAll = function(){


    return $scope.bCompleteAll;
  }


  $scope.uploader.onCompleteAll = function () {
    //なにもしない
    $scope.bCompleteAll = true;
  }

  $scope.uploader.onCompleteItem = function (item, response, status, headers) {
    console.log("onCompleteItem");

    if (response.code === 500) {
      if (response.message === "unsupported file type") {
        item.enableLimaUpload = {enable : false,type:'unsupported' };
        return;
      }
      if (response.message === "text is long") {
        item.enableLimaUpload = {enable : false,type:'text is long' };
        return;
      }
    } else {
      if (item.file.type.indexOf("/pdf") !== -1) {
        if (response) {
          var fileNum = response.length;
          var temp_count  =  $scope.contentCount + (fileNum-1);
          if (temp_count > $scope.settings.playlistContentCount) {
            item.enableLimaUpload = {enable : false,type:'page over' };
            $scope.contentCount -= 1;

            //アップロードされてしまってるのでAPIでちゃんと消さないといけない：response分全部
            var arrRes =_.cloneDeep(response);
            async.forEachSeries(arrRes, function (rr, cb) {
              $http.delete('/api/v1/content/contents/' + rr.id).
              success(function(resDel) {
                console.log('delete ==> ' + rr.id);
                cb(null);
              });
            }, function (err) {
            });

            response = [];  //なかったことにする
          }
        }
      }
    }
    if (item.isCancel) {
      if (response) {
        console.log(response);
      }
    }

    if (response !== '') {  //キャンセルされたときは''になる
      $scope.materials = _.concat( $scope.materials , response );

      _.forEach(response, function(r){
        var addItem = item;
        addItem.id = r.id;
        $scope.uploadedItems = _.concat( $scope.uploadedItems , addItem );

      });

    }


  };

}]);


app.controller('LocalModalFileCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate', '$location','$cookies','$uibModalInstance','FileUploader','limaShowWaitDialog', 'LimaModalUploadData', '$controller',
function ($scope, socket, Auth, $http, $timeout, $translate, $location, $cookieStore,$modalInstance,FileUploader, limaShowWaitDialog, LimaModalUploadData, $controller) {
  $controller('LocalCtrl', {$scope:$scope,Auth:Auth, $http:$http,$timeout:$timeout,$translate:$translate, $location:$location,$cookieStore:$cookieStore,$modalInstance:$modalInstance,FileUploader:FileUploader});
// app.controller('LocalModalFileCtrl',function($scope, $controller, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,FileUploader) {
//   var ctrl = $controller('LocalCtrl', {$scope:$scope,Auth:Auth, $http:$http,$timeout:$timeout,$location:$location,$cookieStore:$cookieStore,$modalInstance:$modalInstance,FileUploader:FileUploader});
//   angular.extend(this, ctrl);

    $scope.contentCount = 0;
    if (LimaModalUploadData) {
      $scope.contentCount = LimaModalUploadData.contentCount;
    }
    $scope.isModal = true;
    $scope.isFile = true;
    $scope.isText =false;
    $scope.isHTML = false;

    $scope.isDirectUploadS3 = false;
    //AWSの設定読み込んでおく
    $scope.AWSS3DirectUploadConfig = null;
    $http.get('/api/v1/configurations/AWSS3DirectUpload').then(function (res) {
      if (res.data) {
        $scope.AWSS3DirectUploadConfig = res.data;
        $scope.isDirectUploadS3 = $scope.AWSS3DirectUploadConfig.enabled;
      }
    });

  //}


  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');


    $scope.closePopup($scope.materials);
  }

  var waitTimer = null;
  $scope.waitClose = function() {
    //$modalInstance.dismiss('cancel');
    if (waitTimer){
      $timeout.cancel(waitTimer);
    }
    waitTimer = null;

    if ($scope.isCompleteAll()) {
      limaShowWaitDialog.hideWait();
      $modalInstance.close({materials:$scope.materials});

    } else {
      var find = _.find($scope.uploader.queue, function(que){
        return que.enableLimaUpload.enable
      });
      if (!find) {
        //アップロード対象がない：おわり
        limaShowWaitDialog.hideWait();
        $modalInstance.close({materials:$scope.materials});
        return;
      }
      waitTimer = $timeout(function () { $scope.waitClose(); }, 1000);
    }

  }



  $scope.closePopup = function(materials) {
    if ($scope.bUploadStarted) {
      //アップロード始まってるので、必ずCompleteAllは呼ばれる
    } else {
      if (!materials) {
        $modalInstance.dismiss('cancel');
        return;
      }
      if (!materials.length) {
        $modalInstance.dismiss('cancel');
        return;
      }
    }
    limaShowWaitDialog.showWait($scope,$translate.instant('CONTENT.PLAYLIST.CONTENT'),$translate.instant('UPLOAD.REGISTING'));
    waitTimer = $timeout(function () { $scope.waitClose(); }, 1000);

  }


}]);
app.controller('LocalModalTextCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate','$location','$cookies','$uibModalInstance','Modal','FileUploader','$controller',
function ($scope, socket, Auth, $http, $timeout, $translate,$location, $cookieStore,$modalInstance,Modal,FileUploader, $controller) {
  $controller('LocalCtrl', {$scope:$scope,Auth:Auth, $http:$http,$timeout:$timeout,$translate:$translate,$location:$location,$cookieStore:$cookieStore,$modalInstance:$modalInstance,Modal:Modal, FileUploader:FileUploader});

// app.controller('LocalModalTextCtrl',function($scope, $controller, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,FileUploader) {
//   var ctrl = $controller('LocalCtrl', {$scope:$scope,Auth:Auth, $http:$http,$timeout:$timeout,$location:$location,$cookieStore:$cookieStore,$modalInstance:$modalInstance,FileUploader:FileUploader});
//   angular.extend(this, ctrl);

  //if (LimaModalUploadData) {
    $scope.isModal = true;
    $scope.isFile = false;
    $scope.isText =true;
    $scope.isHTML = false;

  //}
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.formInput={};
  $scope.formInput.inputtext="";
  $scope.formInput.maxtext = 1000;
  $scope.createText = function() {
    if (!$scope.formInput.inputname) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URLTITLE'), modalClass:'modal-warning'});
      return;
    }
    if (!$scope.formInput.inputname.length) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URLTITLE'), modalClass:'modal-warning'});
      return;
    }

    if ($scope.formInput.inputname.length > 255) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:$translate.instant('UPLOAD.MESSAG_OVER_MAXLENGTH',{length:255}),
      modalClass:'modal-warning'});
      return;
    }
    if (!$scope.formInput.inputtext) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_TEXT'), modalClass:'modal-warning'});
      return;
    }
    if (!$scope.formInput.inputtext.length) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_TEXT'), modalClass:'modal-warning'});
      return;
    }
    if ($scope.formInput.inputtext.length > $scope.formInput.maxtext) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAG_OVER_TEXT_MAXLENGTH',{length:$scope.formInput.maxtext}), modalClass:'modal-warning'});
      return;
    }



    //inputtextをテキストファイルにする
    $http.post('/api/v1/content/extra/text',{text:$scope.formInput.inputtext , title:$scope.formInput.inputname} ).
    success(function(res) {
      $scope.isRegistering = false;
      console.log(res);
      $scope.materials.push(res[0]);
      $modalInstance.close({materials:$scope.materials});
   })
  }

  $timeout(function(){
    document.getElementById("inputtext").addEventListener("change_by_import", (e) => {
      $timeout(function() {
        $scope.formInput.inputtext = e.target.value;
      })
    });
    document.getElementById("inputtitle").addEventListener("change_by_import", (e) => {
      $timeout(function() {
        $scope.formInput.inputname = e.target.value;
      })
    });
  })

}]);

app.controller('LocalModalHTMLCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate', '$location','$cookies','$uibModal','$uibModalInstance', 'Modal','FileUploader','ContentUtil', '$controller',
function ($scope, socket, Auth, $http, $timeout, $translate, $location, $cookieStore,$modal,$modalInstance, Modal,FileUploader, ContentUtil, $controller) {
  $controller('LocalCtrl', {$scope:$scope,Auth:Auth, $http:$http,$timeout:$timeout, $translate:$translate,$location:$location,$cookieStore:$cookieStore,$modal:$modal,$modalInstance:$modalInstance,Modal:Modal,FileUploader:FileUploader});

  //if (LimaModalUploadData) {
    $scope.isModal = true;
    $scope.isFile = false;
    $scope.isText =false;
    $scope.isHTML = true;
  //}
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }

  function isEnableInIframe(url, callback) {
    if (url.length) {
      $http.post('/api/v1/content/extra/url/check', {url:url} , {timeout:10*1000})
      .success(function(res) {
        callback(res);
      })
      .error(function(res) {
        callback({});
      })
      ;
    } else {
      callback({});
    }
  }

  //URLの正規表現チェック
  function isValidUrl(string) {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  }

  function isEnableUrl(str) {
    // var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    //   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    //   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    //   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'); // query string
      //'(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    if(!pattern.test(str)) {
      return false;
    } else {
      return true;
    }
  }



  $scope.urlType = "url";

  $scope.formInput={};
  $scope.formInput.enableName=false;
  $scope.formInput.inputurl="";
  $scope.formInput.inputname="";
  $scope.inputurl_checking = false;
  $scope.createHTML = function() {
    if (!$scope.formInput.inputurl) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }
    if (!$scope.formInput.inputurl.length) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }
    if ($scope.formInput.enableName) {
      if (!$scope.formInput.inputname.length) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URLTITLE'), modalClass:'modal-warning'});
        return;
      }
      if ($scope.formInput.inputname.length > 255) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('UPLOAD.MESSAG_OVER_MAXLENGTH',{length:255}),
        modalClass:'modal-warning'});
        return;
      }
    }
    if (!isValidUrl($scope.formInput.inputurl)) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_CORRECT_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }
    if (!isEnableUrl($scope.formInput.inputurl)) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_CORRECT_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }

    // if ($scope.formInput.inputurl.indexOf("http://") !== -1) {
    //   Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_DONOT_USE_HTTP_SITE'), modalClass:'modal-warning'});
    //   return;
    // }
    $timeout(function() {
      $scope.inputurl_checking = true;
    })
    isEnableInIframe(ContentUtil.getValidYoutTubeUrl($scope.formInput.inputurl), function(res){

      $timeout(function() {
        $scope.inputurl_checking = false;
      })
      if (!res.enable) {
        $timeout(function() {
          $scope.inputurl_checking = false;
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_DISABLE_URL', {message:'(' + res.reason + ')'}), modalClass:'modal-warning'});
        })
        return;
      }

      var content= {
        type: ContentUtil.CONTENT_URL,
        title: $scope.formInput.enableName ? $scope.formInput.inputname : "url",//temp
        description: '',
        remarks: '',
        url: {}
      };
      content.url.sourceURL = $scope.formInput.inputurl;
      content.url.title = content.title;
      $scope.isRegistering = true; //登録中
      $http.post('/api/v1/content/contents', content).success(function(res) {
        $http.get('/api/v1/content/contents/' + res.id, content).success(function(res_get) {

          $scope.isRegistering = false;
          console.log(res_get);
          $scope.materials.push(res_get);
          $modalInstance.close({materials:$scope.materials});
        })
      })
      .error(function(res) {
        $scope.isRegistering = false;
        console.log(res);
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_YOUTUBE_FORBIDDEN'), modalClass:'modal-warning'});
      });
    });
  }
  $scope.preview = function() {
    if (!$scope.formInput.inputurl) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }
    if (!$scope.formInput.inputurl.length) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }

    if (!isValidUrl($scope.formInput.inputurl)) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_CORRECT_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }
    if (!isEnableUrl($scope.formInput.inputurl)) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_CORRECT_INPUT_URL'), modalClass:'modal-warning'});
      return;
    }
    if ($scope.formInput.inputurl.indexOf("http://") !== -1) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_DONOT_PREVIEW_HTTP_SITE'), modalClass:'modal-warning'});
      return;
    }
    $timeout(function() {
      $scope.inputurl_checking = true;
    })
    isEnableInIframe(ContentUtil.getValidYoutTubeUrl($scope.formInput.inputurl), function(res){

      $timeout(function() {
        $scope.inputurl_checking = false;
      })
      if (!res.enable) {
        $timeout(function() {
          $scope.inputurl_checking = false;
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_DISABLE_URL', {message:'(' + res.reason + ')'}), modalClass:'modal-warning'});
        })
        return;
      }


      //dummyでコンテンツObjectを作ってプレビューする
      var content = {};
      content.urlContents = [];
      content.urlContents.push({sourceURL:ContentUtil.getValidYoutTubeUrl($scope.formInput.inputurl)});
      var modalInstance = $modal.open({
      templateUrl: 'app/content/modalPreview.html',
      controller: 'ModalMaterialPreviewCtrl',
      windowClass: 'modal-preview-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaModalPreviewData: function() {
          return {
            selectTweet : content
          };
        }
      }
      });
      modalInstance.result.then(function(results) {
      }, function() {
        //
      });
    });
  }

}]);

app.controller('LocalModalYoutubeCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate', '$location','$cookies','$uibModal','$uibModalInstance', 'Modal','FileUploader','$controller',
function ($scope, socket, Auth, $http, $timeout, $translate, $location, $cookieStore,$modal,$modalInstance, Modal,FileUploader, $controller) {
  $controller('LocalModalHTMLCtrl', {$scope:$scope,socket:socket,Auth:Auth, $http:$http,$timeout:$timeout, $translate:$translate,$location:$location,$cookieStore:$cookieStore,$modal:$modal,$uibModalInstance:$modalInstance,Modal:Modal,FileUploader:FileUploader});

  $scope.urlType = "youtube";

}]);

app.controller('LocalModalLiveCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate', '$location','$cookies','$uibModal','$uibModalInstance', 'Modal','FileUploader','$controller',
function ($scope, socket, Auth, $http, $timeout, $translate, $location, $cookieStore,$modal,$modalInstance, Modal,FileUploader, $controller) {
  $controller('LocalModalHTMLCtrl', {$scope:$scope,socket:socket,Auth:Auth, $http:$http,$timeout:$timeout, $translate:$translate,$location:$location,$cookieStore:$cookieStore,$modal:$modal,$uibModalInstance:$modalInstance,Modal:Modal,FileUploader:FileUploader});

  $scope.urlType = "live";
  $scope.formInput={};
  $scope.formInput.enableName=true;
  $scope.formInput.inputurl="";
  $scope.formInput.inputname="";

}]);


app.controller('LocalModalAutoUpdatedCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$translate', '$location','$cookies','$uibModal','$uibModalInstance', 'Modal','FileUploader','$controller',
function ($scope, socket, Auth, $http, $timeout, $translate, $location, $cookieStore,$modal,$modalInstance, Modal,FileUploader, $controller) {
  $controller('LocalCtrl', {$scope:$scope,Auth:Auth, $http:$http,$timeout:$timeout, $translate:$translate,$location:$location,$cookieStore:$cookieStore,$modal:$modal,$modalInstance:$modalInstance,Modal:Modal,FileUploader:FileUploader});

// app.controller('LocalModalHTMLCtrl',function($scope, $controller, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,FileUploader) {
//   var ctrl = $controller('LocalCtrl', {$scope:$scope,Auth:Auth, $http:$http,$timeout:$timeout,$location:$location,$cookieStore:$cookieStore,$modalInstance:$modalInstance,FileUploader:FileUploader});
//   angular.extend(this, ctrl);

  //if (LimaModalUploadData) {
    $scope.isModal = true;
    $scope.isFile = false;
    $scope.isText =false;
    $scope.isHTML = false;
    $scope.isAutoUpdated = true;

  //}
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }


  $scope.autoUpdatedTypes = {};
  $scope.autoUpdatedTypes.types = [
    {name:'マーカス', id:'nds'},
    {name:'ついっとサイン', id:'twitter'},

    {name:'共同通信ニュース（オリジナル）', id:'kyodo_originalnews'},
    {name:'共同通信スポーツニュース（オリジナル）', id:'kyodo_originalsports'},
    {name:'共同通信天気（オリジナル）', id:'kyodo_originalweather'},

    {name:'共同通信防災（オリジナル）', id:'kyodo_originalbousai'},
    {name:'共同通信多言語マルチニュース（オリジナル）', id:'kyodo_originalmultinews'},
    {name:'共同通信多言語ニュース（オリジナル）', id:'kyodo_originalmultilang'},


    {name:'共同通信ニュース', id:'kyodo_generalnews'},
    {name:'共同通信スポーツ', id:'kyodo_sportsnews'},
    {name:'共同通信週間天気', id:'kyodo_one_week_weather'},
    {name:'共同通信３日間天気', id:'kyodo_today_tomorrow_weather'},

    {name:'LINE', id:'line'},

    {name:'熱中症情報', id:'env_heatstroke_curwbgt'},
    {name:'メニュー', id:'poster'},

    {name:'［ME］今日の天気', id:'meteolo_weatherforecast_today'},
    {name:'［ME］3日間の天気', id:'meteolo_weatherforecast_fewdays'},
    {name:'［ME］週間天気', id:'meteolo_weatherforecast_week'},



    {name:'googleドライブ', id:'googledrive'},

  ];

  $scope.formInput={};
  $scope.formInput.inputurl="";
  $scope.formInput.inputname="";
  $scope.registAutoUpdatedContent = function() {
    if (!$scope.formInput.inputID.length) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_NO_INPUT_URLTITLE'), modalClass:'modal-warning'});
      return;
    }


    var content= {
      type: 3,  //autoupdated
      title: $scope.autoUpdatedTypes.select.id,
      description: '',
      remarks: '',
      autoUpdated : {}
    };
    content.autoUpdated.autoUpdatedType = $scope.autoUpdatedTypes.select.id;//'https://ds.tweetsign.mediaedge.jp/tsunagaru/nds-hitachi-transportsystem/RN-TRN-146-HDH-B.jpg'
    content.autoUpdated.autoUpdatedId = $scope.formInput.inputID;//'https://ds.tweetsign.mediaedge.jp/tsunagaru/nds-hitachi-transportsystem/RN-TRN-146-HDH-B.jpg'
    content.autoUpdated.title = content.autoUpdated.autoUpdatedId;
    $http.post('/api/v1/content/uploads/autoUpdated', content).success(function(res) {
      console.log(res);
      $scope.materials.push(res);
      $modalInstance.close({materials:$scope.materials});
    });
  }
  $scope.preview = function() {
    //dummyでコンテンツObjectを作ってプレビューする
    var content = {};
    content.urlContents = [];
    content.urlContents.push({sourceURL:ContentUtil.getValidYoutTubeUrl($scope.formInput.inputurl)});
    var modalInstance = $modal.open({
    templateUrl: 'app/content/modalPreview.html',
    controller: 'ModalMaterialPreviewCtrl',
    windowClass: 'modal-preview-window',
    backdrop: 'static', keyboard: false,
    size: 'md',
    resolve: {
      LimaModalPreviewData: function() {
        return {
          selectTweet : content
        };
      }
    }
    });
    modalInstance.result.then(function(results) {
    }, function() {
      //
    });
  }


}]);

