'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('editGroup', {
    url: '/userGroup/edit',
    templateUrl: 'app/userGroup/editGroup/editGroup.html',
    controller: 'EditgroupCtrl',
    authenticate: true,
    functionName: 'UserGroupManagement',
    title: 'Edit User Group'
  });
}]);
