'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('devicesetting', {
    url: '/device/setting',
    templateUrl: 'app/device/setting/deviceSetting.html',
    controller: 'SettingDeviceCtrl',
    authenticate: true,
    title: '設定'
  });
}]);
