'use strict';

var app = angular.module('limaApp');

app.controller('CompanyListCtrl', ['$scope', '$translate', 'Auth', '$http', 'LimaRedirect',
function ($scope, $translate, Auth, $http, LimaRedirect) {
  $scope.errors = {};

  $scope.companyList = [];
  $http.get('/api/v1/company').
  success(function(res) {
    $scope.companyList = res;
  });




}]);
