'use strict';

import _ from "lodash";
import { RRule, RRuleSet, rrulestr } from 'rrule'
import moment, { utc } from "moment";

var app = angular.module('limaApp');

app.controller('ScheduleItemTableCtrl', ['$scope', 'socket', '$stateParams', 'Auth','$http', '$timeout', '$location','$cookies','$q','$uibModal','Modal','$uibModalInstance','$translate','limaOptionService', 'LimaScheduleItemData',
function($scope, socket, $stateParams,Auth, $http, $timeout, $location, $cookieStore, $q,$modal, Modal,$modalInstance,$translate,limaOptionService, LimaScheduleItemData) {


  var calendar  = null;

  var m_rruleSet = new RRuleSet()
  var m_rruleSet_control_mon = new RRuleSet()
  var m_rruleSet_control_reboot = new RRuleSet()

  function get_byweekday(dayOfWeek){
    var byweekday = [];
    _.forEach(dayOfWeek, function(dd) {
      if (dd === 0) {byweekday.push(RRule.SU);}
      if (dd === 1) {byweekday.push(RRule.MO);}
      if (dd === 2) {byweekday.push(RRule.TU);}
      if (dd === 3) {byweekday.push(RRule.WE);}
      if (dd === 4) {byweekday.push(RRule.TH);}
      if (dd === 5) {byweekday.push(RRule.FR);}
      if (dd === 6) {byweekday.push(RRule.SA);}
    });
    return byweekday;
  }

  function JSTtoUTC(date,){
    var utc_date = _.cloneDeep(date);
    utc_date.setMinutes(utc_date.getMinutes() + utc_date.getTimezoneOffset());
//    return new Date(Date.UTC(utc_date.getFullYear() , utc_date.getMonth() , utc_date.getDate() , utc_date.getHours() , utc_date.getMinutes() , utc_date.getSeconds()));
    return utc_date;
  }
  function UTCtoJST(date){
    var dd = new Date();
    var jst_date = _.cloneDeep(date);
    jst_date.setMinutes(jst_date.getMinutes() - dd.getTimezoneOffset());
    return jst_date;
  }

  function get_byweekday2(jst_date, dayOfWeek){
    var utc = JSTtoUTC(jst_date);
    var diff = (utc.getDay()%7) - (jst_date.getDay()%7);
    if (diff < 0) {
      //UTC 火曜日
      //JST　水曜日
      var dayOfWeek_new = [];
      _.forEach(dayOfWeek, function(ww){
        dayOfWeek_new.push(((ww+7)-1)%7);
      });
      return get_byweekday(dayOfWeek_new);
    } else if (diff > 0) {
      //UTC 水曜日
      //JST　火曜日
      _.forEach(dayOfWeek, function(ww){
        dayOfWeek_new.push(((ww+7)+1)%7);
      });
      return get_byweekday(dayOfWeek_new);
    }

    return get_byweekday(dayOfWeek);
  }

  function createCalendarData() {
    console.log("createCalendarData in");
    m_rruleSet = null;
    m_rruleSet_control_mon = null;
    m_rruleSet_control_reboot = null;
    m_rruleSet = new RRuleSet()
    m_rruleSet_control_mon = new RRuleSet()
    m_rruleSet_control_reboot = new RRuleSet()

    var dtToday = new Date();
    dtToday = calendar.getDate(); //先頭の日
    //0は日曜日、1は月曜日、2は火曜日、3は水曜日、4は木曜日、5は金曜日、6は土曜日
    var nDay = dtToday.getDay();

    //指定日のスケジュールが何個あるか：1個だと次がないのできちんと番組表にならない
    var filterDateSche = _.filter($scope.scheduleItems, function(ss){
      if (ss.date) {
        return true;
      }
    });
    //表示範囲の最後の位置に次の区切りイベントを入れておく
    {
      var ss = filterDateSche[0];
      var dtUntil = _.cloneDeep(dtToday);
      dtUntil.setDate(dtUntil.getDate() + (14-nDay));
      dtUntil.setHours(23);
      dtUntil.setMinutes(58);
      dtUntil.setSeconds(0);
      var rule = new RRule({
        freq: RRule.DAILY,
        interval: 1,  //一回
        count:1,
        dtstart:(dtUntil)
      })
      //if (filterDateSche.length === 1)
      {
        //ss.ruleString = rule.toString(); //ruleStringを覚えて比較で使う
        m_rruleSet.rrule(rule);
        m_rruleSet_control_reboot.rrule(rule);
      }
      m_rruleSet_control_mon.rrule(rule);

      var dtUntil2 = _.cloneDeep(dtUntil);
      dtUntil2.setMinutes(59);
      var rule2 = new RRule({
        freq: RRule.DAILY,
        interval: 1,  //一回
        count:1,
        dtstart:(dtUntil2)
      })
      m_rruleSet.rrule(rule);
      m_rruleSet_control_reboot.rrule(rule);
      m_rruleSet_control_mon.rrule(rule2);

    }

    _.forEach($scope.scheduleItems, function(ss){
      //if (ss.enable)
      {
        if (ss.controlListId) {
          if (ss.date) {
            //一回
            var dtStart = new Date(ss.date);
            var arTime = ss.startTime.split(':');
            dtStart.setHours(arTime[0]);
            dtStart.setMinutes(arTime[1]);
            dtStart.setSeconds(0);
            var rule = new RRule({
              freq: RRule.DAILY,
              interval: 1,  //一回
              count:1,
              dtstart:(dtStart)
            })
            ss.ruleString = rule.toString(); //ruleStringを覚えて比較で使う
            if (ss.controlListId===3) { //reboot
              m_rruleSet_control_reboot.rrule(rule);
            } else {
              m_rruleSet_control_mon.rrule(rule);
            }
          } else if (ss.dayOfWeek.length) {
            //毎週
            var dtStart = _.cloneDeep(dtToday);
            dtStart.setDate(dtStart.getDate() - (nDay+8));

            var arTime = ss.startTime.split(':');
            dtStart.setHours(arTime[0]);
            dtStart.setMinutes(arTime[1]);
            dtStart.setSeconds(0);
            var dtUntil = _.cloneDeep(dtToday);
            dtUntil.setDate(dtUntil.getDate() + (14-nDay));
            var rule = new RRule({
              freq: RRule.WEEKLY,
              interval: 1,  //毎週
              byweekday: get_byweekday2(dtStart, ss.dayOfWeek),
              dtstart: (dtStart),
              until: (dtUntil)
            })
            ss.ruleString = rule.toString();  //ruleStringを覚えて比較で使う
            if (ss.controlListId===3) { //reboot
              m_rruleSet_control_reboot.rrule(rule);
            } else {
              m_rruleSet_control_mon.rrule(rule);
            }
          }

        } else {
          if (ss.date) {
            //一回
            var dtStart = new Date(ss.date);
            var arTime = ss.startTime.split(':');
            dtStart.setHours(arTime[0]);
            dtStart.setMinutes(arTime[1]);
            dtStart.setSeconds(0);
            var rule = new RRule({
              freq: RRule.DAILY,
              interval: 1,  //一回
              count:1,
              dtstart:(dtStart)


            })
            ss.ruleString = rule.toString(); //ruleStringを覚えて比較で使う
            m_rruleSet.rrule(rule);
          } else if (ss.dayOfWeek.length) {
            //毎週
            var dtStart = _.cloneDeep(dtToday);
            dtStart.setDate(dtStart.getDate() - (nDay+8));
            var arTime = ss.startTime.split(':');
            dtStart.setHours(arTime[0]);
            dtStart.setMinutes(arTime[1]);
            dtStart.setSeconds(0);
            var dtUntil = _.cloneDeep(dtToday);
            dtUntil.setDate(dtUntil.getDate() + (14-nDay));
            var rule = new RRule({
              freq: RRule.WEEKLY,
              interval: 1,  //毎週
              byweekday: get_byweekday2(dtStart, ss.dayOfWeek),
              dtstart: (dtStart),
              until: (dtUntil)
            })
            ss.ruleString = rule.toString();  //ruleStringを覚えて比較で使う
            m_rruleSet.rrule(rule);
          }
        }
      }
    });
    console.log("createCalendarData2");

    //console.log(m_rruleSet.all());
    initCalendar(function() {
      console.log("createCalEvent in")
      createCalEvent();
      console.log("createCalEvent out")
    })
  }

  function updateZoomBtn() {
    if (m_slotDurSize >= 5) {
      $(".fc-zoomin-button").prop('disabled', true);
      $(".fc-zoomin-button").addClass('fc-state-disabled');

      $(".fc-zoomout-button").removeClass('fc-state-disabled'); ;
      $(".fc-zoomout-button").prop('disabled', false);

    } else if (m_slotDurSize <= 0) {
      $(".fc-zoomout-button").prop('disabled', true);
      $(".fc-zoomout-button").addClass('fc-state-disabled');

      $(".fc-zoomin-button").removeClass('fc-state-disabled'); ;
      $(".fc-zoomin-button").prop('disabled', false);

    } else {
      $(".fc-zoomin-button").removeClass('fc-state-disabled'); ;
      $(".fc-zoomin-button").prop('disabled', false);
      $(".fc-zoomout-button").removeClass('fc-state-disabled'); ;
      $(".fc-zoomout-button").prop('disabled', false);

    }
  }

  function onClickTodayButton() {
    console.log("####onClickTodayButton")
    createCalendarData();
  }

  function renderCalendar(callback) {
    //eventDidMountで覚えておいたcss変更を一気に行う
    $timeout(function() {
      _.forEach(m_arrDidMount , function(mt) {
        mt.item.css(mt.css);
      });
    });
    //透明にしてたので、元に戻す
    $timeout(function() {
      _.forEach(m_arrDidMount , function(mt) {
        mt.item.css({opacity:100})
      });
    });

    $timeout(function() {
      const elem = document.getElementById("my-calendar");

      console.log("render in");
      calendar.render();
      console.log("render out");

      document.querySelectorAll('.fc-icon-search-minus').forEach((e) => {
        e.className='fa fa-search-minus';
      });
      document.querySelectorAll('.fc-icon-search-plus').forEach((e) => {
        e.className='fa fa-search-plus';
      });

      updateZoomBtn();


      //addEventListenerをするときはremoveEventListenerしないといけない。
      //removeEventListenerするときは仮想関数ではダメで、addEventListenerと同じ関数でないと解除できない。
      //https://gray-code.com/javascript/unset-event-listener/
      elem.querySelector('.fc-today-button').removeEventListener('click', onClickTodayButton);
      elem.querySelector('.fc-today-button').addEventListener('click', onClickTodayButton);

      elem.querySelector('.fc-timeGridWeek-button').removeEventListener('click', onClickTodayButton);
      elem.querySelector('.fc-timeGridWeek-button').addEventListener('click', onClickTodayButton);


      elem.querySelector('.fc-timeGridDay-button').removeEventListener('click', onClickTodayButton);
      elem.querySelector('.fc-timeGridDay-button').addEventListener('click', onClickTodayButton);


      elem.querySelector('.fc-listWeek-button').removeEventListener('click', onClickTodayButton);
      elem.querySelector('.fc-listWeek-button').addEventListener('click', onClickTodayButton);

      if (document.querySelector('.fc-timegrid-slots')) {
        if (document.querySelector('.fc-timegrid-slots').parentNode) {
          if (document.querySelector('.fc-timegrid-slots').parentNode.parentNode) {

            document.querySelector('.fc-timegrid-slots').parentNode.parentNode.addEventListener("scroll", function(){
              //スクロール位置をチェック
              checkCalendarScroll();
            });
          }
        }
      }

      if ($scope.selectedRow) {
        $scope.rowHighilitedByCalendar($scope.selectedRow.itemuuid);
      }


      if (callback) {
        callback();
      }
    })

  }
  function initCalendar(callback){
    console.log("initCalendar in");
    if (calendar) {
      calendar.removeAllEvents()
      // var arrEvent = calendar.getEvents();
      // _.forEach(arrEvent, function(ev) {
      //   ev.remove();
      // });


      if ($scope.activeTabIndex == 2) {
        //renderCalendar(callback);

      }
      console.log("initCalendar out");
      m_arrElement = [];

      callback();
    }
  }

  //createCalEvent⇒eventDidMount発生なので
  //eventDidMountで変えたいCSSは覚えておいて、createCalEvent終わったタイミング（render）で一気に変える
  var m_arrDidMount = [];
  function createCalEvent(){
    m_arrDidMount = [];

    var arrRules = m_rruleSet.all();  //start時刻の一覧
    var arrRules_control_mon = m_rruleSet_control_mon.all();  //start時刻の一覧
    var arrRules_control_reboot = m_rruleSet_control_reboot.all();  //start時刻の一覧
    var color = undefined;

    var arrScheCont = [];

    var arrEvents= [];

    if (arrRules.length === 1 ) {

    }

    _.forEach(arrRules, function(ruleString,index) {
      if ((arrRules.length-2) >= index) {
        var dtBefore=(new Date(arrRules[index]));
        dtBefore.setSeconds(dtBefore.getSeconds()-1);
        var dtAfter=(new Date(arrRules[index+1]));
        dtAfter.setSeconds(dtAfter.getSeconds());
        var bet = m_rruleSet.between(dtBefore,dtAfter);
        var betRule = _.find(m_rruleSet._rrule, function(rule){
          var resRule = rule.between(dtBefore,dtAfter);
          if (resRule && resRule.length) {
            if (resRule[0].getTime() === bet[0].getTime()) {
              return true;
            }
          }
        });
        if (betRule) {
          var findSch = _.find($scope.scheduleItems, function(sch){
            return sch.ruleString === betRule.toString();
          });
          if (findSch) {
            var event = {};
            if (findSch.playlistId) {
              color = undefined;
              var findPL =  _.find($scope.playlists, function(pl) {
                return pl.playlistId === findSch.playlistId;
              });
              if (findPL) {
                color = findPL.calendarColor;

              }

              event = {
                id: findSch.itemuuid,
                start: (bet[0]),
                end:(arrRules[index+1]),
                title: (findSch.enable ? '' : '<無効>') + findSch.playlistName,
                description: "",
                backgroundColor: (findSch.enable ? color : 'dimgray'),
                borderColor: color,
                //display:findSch.enable ? 'auto' : 'block',
                textColor : findSch.enable ? 'white' : 'black',
                editable: false
              };
              arrEvents.push(event);
            }
          }
        }
      }
    });

    calendar.addEventSource(arrEvents);
    arrEvents = [];

    _.forEach(arrRules_control_mon, function(ruleString,index) {
      if ((arrRules_control_mon.length-2) >= index) {
        var dtBefore=(new Date(arrRules_control_mon[index]));
        dtBefore.setSeconds(dtBefore.getSeconds()-1);
        var dtAfter=(new Date(arrRules_control_mon[index+1]));
        dtAfter.setSeconds(dtAfter.getSeconds());
        var bet = m_rruleSet_control_mon.between(dtBefore,dtAfter);
        var betRule = _.find(m_rruleSet_control_mon._rrule, function(rule){
          var resRule = rule.between(dtBefore,dtAfter);
          if (resRule && resRule.length) {
            if (resRule[0].getTime() === bet[0].getTime()) {
              return true;
            }
          }
        });


        if (betRule) {
          var findSch = _.find($scope.scheduleItems, function(sch){
            return sch.ruleString === betRule.toString();
          });
          if (findSch) {
            arrScheCont.push(findSch);
          } else {
            if (arrScheCont.length) {
              var sc = arrScheCont[arrScheCont.length-1];
              if (sc.controlListId === 1) {
                var clone_sc = _.cloneDeep(sc);
                clone_sc.controlListId = 2;
                arrScheCont.push(clone_sc);
              }
              else if (sc.controlListId === 2) {
                var clone_sc = _.cloneDeep(sc);
                clone_sc.controlListId = 1;
                arrScheCont.push(clone_sc);
              }
            }
//            arrScheCont.push(null);
          }
        } else {
//          arrScheCont.push(null);
        }
      }
    });
    //モニタONがない
    var arr_on = _.filter(arrScheCont, function(sc1) {
      return sc1.controlListId !== 1;
    })
    if (!arr_on.length) {
      color = "#38383e";
      var index = 0;
      for (index = 0; index < (arrScheCont.length); index++) {
        //off-
        var event = {
          id: arrScheCont[index].itemuuid,
          start: (arrRules_control_mon[index]),
          end:(arrRules_control_mon[index+1]), ///On
          title: (arrScheCont[index].enable ? '' : '<無効>') + "モニタがオフされています",
          description: "",
          backgroundColor: color,
          borderColor: color,
          editable: false
        };
        arrEvents.push(event);

      }
    } else {

    }

    if (arrRules_control_mon.length >= arrScheCont.length) {

      var initState = null;
      var initDt = null;
      var nextState = null;
      color = "#38383e";
      var index = 0;
      for (index = 0; index <= (arrScheCont.length-2); index++) {
        if (!initState) {
          initState = arrScheCont[index].controlListId;
          initDt = arrRules_control_mon[index];
        }
        nextState = arrScheCont[index+1].controlListId;
        if (initState !== nextState) {
          if (initState === 1) {  //off
            //off->on
            var event = {
              id: arrScheCont[index].itemuuid,
              start: (initDt),
              end:(arrRules_control_mon[index+1]), ///On
              title: (arrScheCont[index].enable ? '' : '<無効>') + "モニタがオフされています",
              description: "",
              backgroundColor: color,
              borderColor: color,
              editable: false
            };
            arrEvents.push(event);
            // calendar.addEvent(event);
          }
          initState = nextState;
          initDt = arrRules_control_mon[index+1];
        } else if (initState === 1) { //off => off
          var event = {
            id: arrScheCont[index].itemuuid,
            start: (initDt),
            end:(arrRules_control_mon[index+1]), ///On
            title: (arrScheCont[index].enable ? '' : '<無効>') + "モニタがオフされています",
            description: "",
            backgroundColor: color,
            borderColor: color,
            editable: false
          };
          arrEvents.push(event);
          // calendar.addEvent(event);
          initState = nextState;
          initDt = arrRules_control_mon[index+1];

        }

      }
    }
    calendar.addEventSource(arrEvents);
    arrEvents = [];


    _.forEach(arrRules_control_reboot, function(ruleString,index) {
      if ((arrRules_control_reboot.length-2) >= index) {
        var dtBefore=(new Date(arrRules_control_reboot[index]));
        dtBefore.setSeconds(dtBefore.getSeconds()-1);
        var dtAfter=(new Date(arrRules_control_reboot[index+1]));
        dtAfter.setSeconds(dtAfter.getSeconds());
        var bet = m_rruleSet_control_reboot.between(dtBefore,dtAfter);
        var betRule = _.find(m_rruleSet_control_reboot._rrule, function(rule){
          var resRule = rule.between(dtBefore,dtAfter);
          if (resRule && resRule.length) {
            if (resRule[0].getTime() === bet[0].getTime()) {
              return true;
            }
          }
        });
        if (betRule) {
          var findSch = _.find($scope.scheduleItems, function(sch){
            return sch.ruleString === betRule.toString();
          });
          if (findSch) {
            var event = {};
            if (findSch.controlListId) {
              var dtEnd = (new Date(arrRules_control_reboot[index]));
              dtEnd.setMinutes(dtEnd.getMinutes()+1);
              color = "#FFD100";
              //デバイスコントロール1:off 2:on 3 reboot
              if (findSch.controlListId === 3) { //reboot
                event = {
                  id: findSch.itemuuid,
                  start: (bet[0]),
                  end:(dtEnd),

                  title: (findSch.enable ? '' : '<無効>') + "再起動",
                  description: "",
                  backgroundColor: color,
                  borderColor: color,
                  editable: false
                };
              }
              arrEvents.push(event);
              // calendar.addEvent(event);
            }
          }

        }

      }
    });
    calendar.addEventSource(arrEvents);
    arrEvents = [];


    renderCalendar();

  }

  $scope.activeTabIndex = 1;
  $scope.tabSelected = function(activeTabIndex){

    $scope.activeTabIndex = activeTabIndex;
    //カレンダーテーブル作成:ちょっと待ってから作成開始
    $timeout(function(){
      createCalendarData();
    },100)

  }
  var m_color = [
    '#4072B3',
    '#6088C6',
    '#AEC4E5',
    '#77AF9C',
    '#8CD790',
    '#C5E99B',
    '#37ab9d',
    '#4dc0b2',
    '#EB8686',
    '#ffc042',

    '#517D99',
    '#6AC1B7',
    '#BFE9DB',
    '#B4CCE3',
    '#5DA797',
    '#FC9D9D',

    '#EE817B',
    '#AAD6EC',
    '#F6AFAA',
    '#ffb2ae',
    '#79A1D4',
    '#9fbcdf',
    '#abcae8',
    '#ffeed5',
    '#6495ED',
    '#67C7D4',
    '#B2E2EC',

    '#64BCFC',
    '#68c5f3'

    // "antiquewhite",
    // "lavenderblush",
    // "gainsboro",
    // "lightsteelblue",
    // "lightskyblue",
    // "paleturquoise",
    // "mediumaquamarine",
    // "palegreen",
    // "khaki",
    // "moccasin",
    // "peachpuff",
    // "pink",
    // "thistle",
    // "silver",
    // "lightslategray",
    // "slateblue",
    // "mediumslateblue",
    // "blue",
    // "cornflowerblue",
    // "cyan",
    // "turquoise",
    // "darkturquoise"
  ];


  var arrDur = [30,20,15,10,5,1];;
  var m_scrollTop = 0;
  var m_slotDurSize = 2;  //初期値15
  var m_slotDurSize_b = 2;
  $scope.changeSlotDuration = function(val) {

    m_slotDurSize_b = m_slotDurSize;

    var arrLabelInterval = ["01:00","01:00","00:30","00:20","00:10","00:05"];;
    m_slotDurSize = m_slotDurSize + val;
    if (m_slotDurSize > 5) {
      m_slotDurSize = 4;
      updateZoomBtn();
      return;
    } else if (m_slotDurSize < 0) {
      m_slotDurSize = 0;
      updateZoomBtn();
      return;
    }
    if (calendar.view) {
      m_calendarOption.initialView = calendar.view.type;
    }
    m_calendarOption.slotDuration = "00:" + ('00' + arrDur[m_slotDurSize]).slice(-2);
    m_calendarOption.slotLabelInterval = arrLabelInterval[m_slotDurSize];
    m_calendarOption.customButtons.zoomText.text = arrDur[m_slotDurSize] + "分";
    m_calendarOption.initialDate = calendar.getDate();

    calendar.destroy();
    const elem = document.getElementById("my-calendar");
    calendar = new FullCalendar.Calendar(elem,m_calendarOption);

    renderCalendar();
    //カレンダーテーブル作成:ちょっと待ってから作成開始
    $timeout(function(){
      createCalendarData();
    },100)
  }

  var m_scrollTop = 0;
  var m_scrollHeight = 0;
  function checkCalendarScroll(){
    if (document.querySelector('.fc-timegrid-slots')) {
      if (document.querySelector('.fc-timegrid-slots').parentNode) {
        if (document.querySelector('.fc-timegrid-slots').parentNode.parentNode) {
          var scrollTop = document.querySelector('.fc-timegrid-slots').parentNode.parentNode.scrollTop;
          var scrollHeight = document.querySelector('.fc-timegrid-slots').parentNode.parentNode.scrollHeight;
          m_scrollTop = scrollTop;
          m_scrollHeight = scrollHeight;

        }
      }
    }
  }


  var m_arrElement = [];
  $('body').on('click', '.fc-prev-button', function() {
    //連打防止
    //ボタンクリック無効化
    var elem = this;
    elem.disabled=true;
    //ボタンクリック有効化
    setTimeout(function () {
      elem.disabled=false;
    }, 500);
    createCalendarData();

  });

  $('body').on('click', '.fc-next-button', function() {
    //連打防止
    //ボタンクリック無効化
    var elem = this;
    elem.disabled=true;
    //ボタンクリック有効化
    setTimeout(function () {
      elem.disabled=false;
    }, 500);
    createCalendarData();

  });


  var m_calendarOption = {
    themeSystem: 'standard',
    locale:'ja',
    buttonText: {
      today:    '今日',
      month:    '月',
      week:     '週',
      day:      '日',
      list:     '一覧'
  },
    initialView: "timeGridWeek",
    //slotEventOverlap:false,
    height : "100%",
    slotDuration:'00:15',
    // 1時間ごとにラベルを表示
    slotLabelInterval:'01:00',
    allDaySlot: false,
    headerToolbar: {
      left: 'prev,next zoomout,zoomText,zoomin today',
      center: 'title',
      right: 'timeGridWeek,timeGridDay,listWeek'
    },
    customButtons: {
      zoomout: {
          icon: 'search-minus',
          click: function() {
            $scope.changeSlotDuration(-1);
          }
      },
      zoomin: {
        icon: 'search-plus',
        click: function() {
          $scope.changeSlotDuration(1);
        }
      }
      ,
      zoomText: {
        text: '15分' //slotDuration
      }
    },
    nowIndicator:true,
    initialDate: null,
    slotLabelFormat: {  //"00:00"表記
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short'
    },
    events: [],//events,
    dateClick: (e)=>{
      //console.log("dateClick:", e);
    },
    eventClick: (e)=>{
      console.log("eventClick:", e.event.id);

      //
      $scope.rowHighilitedByCalendar(e.event.id);


      // $timeout(function() {
      //   console.log("render");
      //   calendar.render();
      // });
      },
    datesSet: function (){
      //カレンダーが作成された
      if (document.querySelector('.fc-timegrid-slots')) {
        if (document.querySelector('.fc-timegrid-slots').parentNode) {
          if (document.querySelector('.fc-timegrid-slots').parentNode.parentNode) {
            var ScrollHeight_now = document.querySelector('.fc-timegrid-slots').parentNode.parentNode.scrollHeight;
            //m_scrollTop : m_scrollHeight = xx : ScrollHeight_now
            var ss = m_scrollTop * ScrollHeight_now / m_scrollHeight;
            // スクロール実行
            setTimeout(function(){
              document.querySelector('.fc-timegrid-slots').parentNode.parentNode.scrollTop = ss;
            });
            console.log("datesSet");
            return ;
          }
        }
      }

    },
    viewClassNames: function(view) {  //Viewが作られるときに挿入できる
      // $('.fc-today-button').before("\
      //     <div class='label' style='line-height: 30px;margin-top:5px'>\
      //     <i class='fa fa-square text-default' style2='color:#0aa858;font-size:14px' id='labelDuration'>" + m_calendarOption.slotDuration + "</i>\
      //     </div>\
      // ");
  } ,
    eventDidMount: function (args) {
      if(args.event.icon){
        element.find(".fc-title").prepend("<i class='fa fa-"+event.icon+"'></i>");
      }
      m_arrElement.push(args);
      //重なっても必ずイベントの幅は幅いっぱいにする。
      let parent = $(args.el).parent()
      if (parent) {
        // console.log("eventDidMount " + parent.attr('style'));
        if (parent.css('z-index')!=='auto'){ // allDayイベントでなければz-indexはautoとなる
          //console.log("z-index= " + parent.css('z-index') + " " + parent.attr('style'));
          let insets = parent.attr('style').split('inset: ').slice(-1)[0].replace(';', '').split(' ');

          //このイベントがデバイスコントロールかどうか
          var item = _.find($scope.scheduleItems, function(ss){
            if (ss.itemuuid === args.event._def.publicId){
              return ss.controlListId ? true : false;
            }
          });

          //イベントの幅は必ず横幅いっぱいにする。
          //重なっていたらデフォルトでは50％とかで設定されてるので0%にして横幅いっぱいにする
          insets[1] = '0%'
          insets[3] = '0%'
          m_arrDidMount.push({item:parent,css:{top: insets[0], right: insets[1], bottom: insets[2], left: insets[3]} })

          //デバイスコントロールは常にzIndexで上になる
            if (item) {
              m_arrDidMount.push({item:parent,css:{zIndex: 2} })
            } else {
              m_arrDidMount.push({item:parent,css:{zIndex: 1} })
            }
            //あとで一気に変えるので、一旦透明にしておく
            parent.css({opacity: 0});
        }
      }

      //イベント文字の座布団を設定する
      // var elemE = args.el.getElementsByClassName("fc-event-time");
      // if (elemE) {
      //   if (elemE.length) {
      //     elemE[0].classList.add("label-default");
      //     elemE[0].style.backgroundColor = "#ababab99"
      //   }
      // }
      // elemE = args.el.getElementsByClassName("fc-event-title");
      // if (elemE) {
      //   if (elemE.length) {
      //     elemE[0].classList.add("label-default");
      //     elemE[0].style.backgroundColor = "#ababab99"
      //   }
      // }
    }
  }

  $scope.calload = function(){
    // Calendar
    const elem = document.getElementById("my-calendar");
    var startDate = new Date();
    m_calendarOption.initialDate =  $scope.formatDate(startDate , "YYYY-MM-DD");

    calendar = new FullCalendar.Calendar(elem,m_calendarOption);
    renderCalendar();
  }



  $scope.schedule = [];
  $scope.scheduleItems = [];
  $scope.scheduleItems_initial = null;
  $scope.scheduleItems_delete = [];


  var getScheduleItemsOnly = function(callback) {
    if (LimaScheduleItemData.data && LimaScheduleItemData.data.scheduleListId) {
      $scope.schedule = LimaScheduleItemData.data;
      $http.get('/api/v1/schedules/list/' + LimaScheduleItemData.data.scheduleListId).
      success(function(datas) {
        callback(datas);
      });
    } else {
      callback(null);
    }
  }

  function sortSchedule(datas){
    //初期ソート：日付け、毎週の順にソート（開始時刻でも）
    datas.sort(function(a,b){
      var dt_a = Number(a.startTime.replace(/:/g, ''));
      var dt_b = Number(b.startTime.replace(/:/g, ''));
      if (a.date && b.date) {
        if(new Date(a.date).getTime() > new Date(b.date).getTime()) {
          return 1;
        }
        if(new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1;
        }
      }
      if (a.dayOfWeek && b.dayOfWeek) {
        if (a.dayOfWeek.length && b.dayOfWeek.length) {
          if(a.dayOfWeek[0] > b.dayOfWeek[0]) {
            return 1;
          }
          if(a.dayOfWeek[0] < b.dayOfWeek[0]) {
            return -1;
          }
        }
        if (a.dayOfWeek.length > b.dayOfWeek.length) {
            return 1;
        }
        if (a.dayOfWeek.length < b.dayOfWeek.length) {
          return -1;
        }
      }
      if(dt_a > dt_b) {
        return 1;
      }
      if(dt_b > dt_a) {
        return -1;
      }


    });
    return datas;
  }

  var getScheduleItems = function() {
    getScheduleItemsOnly(function(datas){
      if (datas) {
        $scope.schedule = LimaScheduleItemData.data;
        $http.get('/api/v1/schedules/list/' + LimaScheduleItemData.data.scheduleListId).
        success(function(datas) {

          var datas_u = sortSchedule(datas);

          if (!$scope.scheduleItems_initial) {
            $scope.scheduleItems_initial = [];
            $scope.scheduleItems = datas_u;
            $scope.scheduleItems_initial = datas_u;
          }
          getPlaylits();
        });
      } else {
        getPlaylits();
      }
    });
  };
  getScheduleItems();

  $scope.playlists = [];
  function getPlaylits() {

    $scope.playlists = [];
    $http.get('/api/v1/playlists').
    success(function(datas) {
      //プレイリストだけを抽出
      $scope.playlists = datas.playlists;

      _.forEach($scope.playlists, function(pl,index) {
        var ii = pl.playlistId % m_color.length;
        pl.calendarColor = m_color[ii];
      })

      updateScheduleItem(true,-1);
    });
  }

  $scope.getDisplayName = function(controlName) {
    if (controlName === 'monitor-off') {
      return $translate.instant('DEVICE.TYPE_MONITOR_OFF');
    }
    else if (controlName === 'monitor-on') {
      return $translate.instant('DEVICE.TYPE_MONITOR_ON');
    }
    else if (controlName === 'reboot') {
      return $translate.instant('DEVICE.TYPE_REBOOT');
    }
    return '';
  }

  $scope.controlLists = [];
  function getControlLists() {
    $scope.controlLists = [];
    $http.get('/api/v1/controls').
    success(function(datas) {
      $scope.controlLists = datas.controls;
    });
  }
  getControlLists();

  //リストが選択されたときの処理
  $scope.selectedRow = null;
  $scope.rowHighilited = function (list) {
    _.forEach($scope.scheduleItems, function(item){
      item.checked = false;
    });
    list.checked = true;
    $scope.selectedRow = list;

  };

function getColor(itemId){
  var color = null;
  _.forEach($scope.scheduleItems, function(item){
    if (item.itemuuid === Number(itemId)) {
      _.find($scope.playlists, function(pl) {
        if (item.playlistId) {
          if (pl.playlistId === item.playlistId) {
            color = pl.calendarColor;
            if (!item.enable) {
              color = "dimgray";
            }
            return true;
          }
        } else {
          if (item.controlListId === 1) {
            color = "#38383e";
            return true;
          }
          if (item.controlListId === 3) {
            color = "#F43E43";
            return true;
          }
        }
      });

    }
  });
  return color;
}

function hex2rgb ( hex ) {
  if ( hex.slice(0, 1) == "#" ) hex = hex.slice(1) ;
  if ( hex.length == 3 ) hex = hex.slice(0,1) + hex.slice(0,1) + hex.slice(1,2) + hex.slice(1,2) + hex.slice(2,3) + hex.slice(2,3) ;

  return [ hex.slice( 0, 2 ), hex.slice( 2, 4 ), hex.slice( 4, 6 ) ].map( function ( str ) {
    return parseInt( str, 16 ) ;
  } ) ;
}
function rgb2hex ( rgb ) {
  //rgb(246, 175, 170)
  var rgb_a = rgb.replace('rgb(' , '');
  rgb_a = rgb_a.replace(')' , '');
  rgb_a = rgb_a.replace(/ /g , '');
  rgb_a = rgb_a.split(',');

	return "#" + rgb_a.map( function ( value ) {
		return ( "0" + Number(value).toString( 16 ) ).slice( -2 ) ;
	} ).join( "" ) ;
}


  $scope.rowHighilitedByCalendar = function (itemId , ev) {
    $timeout(function() {
      _.forEach($scope.scheduleItems, function(item){
        if (item.itemuuid === (itemId)) {
          $scope.selectedRow = item;
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    })

      // info.el.style.borderRadius = '0px';
      // info.el.style.borderWidth = '4px';


    var arrEv = calendar.getEvents();
    //まず非選択状態に戻す
    _.filter(m_arrElement, function(elem) {
      if (rgb2hex(elem.el.style.borderColor).toLowerCase() === '#e53935' ) {
        // console.log(elem.event._def.publicId + " " + elem.event._def.sourceId + "" +  + elem.event._def.defId)
        var color = getColor(elem.event._def.publicId);
        elem.el.style.borderColor = color;
        elem.el.style.borderWidth = '1px';
        elem.el.style.color = 'white';

        _.find(arrEv, function(ev) {
           if ( (elem.event._def.publicId === ev._def.publicId) &&
           (elem.event._def.sourceId === ev._def.sourceId) &&
           (elem.event._def.defId === ev._def.defId))  {
            ev.setProp( 'textColor', 'White' )
            return true;
          }
        })

        return true;
      }
    })



    //選択状態に
    _.filter(m_arrElement, function(elem) {
      if (itemId === elem.event._def.publicId ) {
        elem.el.style.borderColor = '#e53935';
        elem.el.style.borderWidth = '2px';
        elem.el.style.color = '#e53935';
        return true;
      }
    })
    _.filter(arrEv, function(ev) {
      if (ev.id === itemId) {
        //ev.setProp( 'backgroundColor', '#F99F48' );
        ev.setProp( 'textColor', '#e53935' )
        return true;
      }
    })



  };

  $scope.getTimeString = function(time){
    var arTime = time.split(':');
    return ('00' + arTime[0]).slice(-2) + ':' + ('00' + arTime[1]).slice(-2);
  }


  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }

  $scope.getDayOfWeekString = function(val){
    var str = '';
    var array = val;

    var arString = [
      $translate.instant('DAY_OF_WEEK.SHORT_SUNDAY'), $translate.instant('DAY_OF_WEEK.SHORT_MONDAY'),
      $translate.instant('DAY_OF_WEEK.SHORT_TUESDAY'), $translate.instant('DAY_OF_WEEK.SHORT_WEDNESDAY'),
      $translate.instant('DAY_OF_WEEK.SHORT_THURSDAY'), $translate.instant('DAY_OF_WEEK.SHORT_FRIDAY'),
      $translate.instant('DAY_OF_WEEK.SHORT_SATURDAY')
    ];
    _.forEach(array,function(item){
      str += arString[item] + ' ';
    });
    return str;
  }


  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  function createScheduleItem(item , callback) {

    var httpParam = {
      url: '/api/v1/schedules/list/' + item.scheduleListId,
      method: "",
      data: item
    };

    if (item.scheduleListItemId) {
      httpParam.method = 'PUT';
      httpParam.url = '/api/v1/schedules/list/' + item.scheduleListId + '/'  + item.scheduleListItemId;
    } else {
      httpParam.method = 'POST';
    }
    $http(httpParam)
    .success(function(resItem) {
      // console.log(resItem);
      // $timeout(function(){
      //   if (!LimaScheduleItemData.data) {
      //     LimaScheduleItemData.data = {};
      //   }
      //   if (!LimaScheduleItemData.data.scheduleListId) {
      //     LimaScheduleItemData.data.scheduleListId = item.scheduleListId;
      //   }
      //   getScheduleItems();
      // });
      if (!item.scheduleListItemId) {
        item.scheduleListItemId = resItem.scheduleListItemId;
      }
      callback();
    });
  }

//   function openScheduleItem(list) {
//     var modalInstance = $modal.open({
//       templateUrl: 'app/schedule/schedule-edit.html',
//       controller: 'ScheduleEditCtrl',
// //      windowClass: 'modal-fit',
//       backdrop: 'static', keyboard: false,
//       size: 'md',
//       resolve: {
//         RafaleScheduleData: function() {
//           return {
//             data : list,
//             list: $scope.scheduleItems
//           };
//         }
//       }
//     });
//     modalInstance.result.then(function(results) {
//       console.log(results);

//       if (results.scheduleListId) {
//         //update
//         createScheduleItem(results, function(){
//           console.log("createScheduleItem1");
//         });
//       } else {
//         //new
//         var modalInstance = $modal.open({
//           templateUrl: 'app/playlist/newName.html',
//           controller: 'NewNameCtrl',
//           windowClass: 'modal-rename-window',
//           backdrop: 'static', keyboard: false,
//           size: 'md',
//           resolve: {
//             LimaNewNameData: function() {
//               return {
//                 name : $scope.formatDate(new Date() , 'YYYYMMDD_hhmmss')
//               };
//             }
//           }
//         });
//         modalInstance.result.then(function(resNewName) {
//           console.log(resNewName);

//           var postCreate = {
//             name : resNewName.name
//           }
//           $http.post('/api/v1/schedules/list', postCreate).
//           success(function(datas) {
//             results.scheduleListId = datas;
//             createScheduleItem(results, function(){
//               console.log("createScheduleItem1");

//             });
//           });
//         }, function() {
//           //
//         });
//       }


//     }, function() {
//       //
//       $timeout(function(){
//         // $scope.playlists = [];
//         // $scope.getPlaylist({playlistId:$scope.selectedRow ? $scope.selectedRow.playlistId : null});
//       });
//     });
//   }

  function getUniqueStr(myStrong){
    var strong = 1000;
    if (myStrong) strong = myStrong;
    return new Date().getTime().toString(16)  + Math.floor(strong*Math.random()).toString(16)
  }

  $scope.m_bupdatingScheduleItem = true;  //起動直後はまだ設定されていないからupdating中にしておく

  function updateScheduleItem(bInitial , itemuuid){
    $timeout(function() {
      $scope.m_bupdatingScheduleItem = true;  //playlistNameが設定されている間は！マークがついて画面作成されないように
    });
    var find_noPlaylist = null;
    _.forEach($scope.scheduleItems, function(ss){
      var find = _.find($scope.playlists, function(pl){
        if (ss.playlistId) {
          if (pl.playlistId === ss.playlistId) {
            ss.playlistName = pl.playlistName;
            return true;
          }
        } else {
          return true;
        }
      });
      if (!find) {
        find_noPlaylist = true;
      }
      if (bInitial !== true) {
        if (ss.itemuuid === itemuuid) {
          $scope.rowHighilited(ss);
        }
      }
      if (!ss.itemuuid )  {
        ss.itemuuid = getUniqueStr();
        console.log(ss.itemuuid);
      }
    });

    $timeout(function() {
      $scope.m_bupdatingScheduleItem = false;
    });

    if (bInitial === true) {
      if (find_noPlaylist) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('PLAYLIST.SCHEDULE.MSG.MESSAGE_CONFIRM_ADD_NOTINCLUDE_PLAYLISTITEM'), modalClass:'modal-success'},
        function () {
        });
      }
    }
      //カレンダーテーブル作成
      createCalendarData();
  }

  var m_bEditedItem = false;
  function openScheduleItem(list) {
    var modalInstance = $modal.open({
      templateUrl: 'app/schedule/schedule-edit.html',
      controller: 'ScheduleEditCtrl',
//      windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        RafaleScheduleData: function() {
          return {
            data : list,
            list: $scope.scheduleItems
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
      m_bEditedItem = true;

      if (results.itemuuid) {
        //update
        var index = _.findIndex($scope.scheduleItems, function(item) {
          if (item.itemuuid === results.itemuuid) {
            return true;
          }
        });
        if (index !== -1) {
          $scope.scheduleItems[index] = results;
        }
        // createScheduleItem(results, function(){
        //   console.log("createScheduleItem1");
        // });
      } else {
        //new
        $scope.scheduleItems.push(results);
      }
      $timeout(function(){
        $scope.scheduleItems = sortSchedule($scope.scheduleItems);
        updateScheduleItem(false , results.itemuuid);
      });

    }, function() {
      //
      $timeout(function(){
        // $scope.playlists = [];
        // $scope.getPlaylist({playlistId:$scope.selectedRow ? $scope.selectedRow.playlistId : null});
      });
    });
  }



  $scope.createScheduleListItem = function() {
    //新規作成
    var id = null;
    if (LimaScheduleItemData.data) {
      id = LimaScheduleItemData.data.scheduleListId;
    }
    openScheduleItem({scheduleListId:id});

  }

  $scope.editScheduleListItem = function(list) {
    //編集
    openScheduleItem($scope.selectedRow);
  }

  $scope.deleteScheduleListItem = function(list) {
    //削除
    if ($scope.selectedRow) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.TRANSCODE_LIST.CONFIRM_DELETE_TEXT')}),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        //削除
        if ($scope.selectedRow.scheduleListItemId) {
          m_bEditedItem = true;
          $scope.scheduleItems_delete.push($scope.selectedRow);
        }
        $timeout( function() {
          $scope.scheduleItems = _.filter($scope.scheduleItems, function(item) {
            if (!angular.equals(item,$scope.selectedRow)) {
              return true;
            }
          });
          $scope.selectedRow = null;
          createCalendarData();
        });
      });
    }
  }

  $scope.deleteScheduleListItemFromDlg = function() {
    var datas = [];
    var titleArray = [
      "",
      $translate.instant("CONTENT.SCHEDULE.HEADER_DATE"),
      $translate.instant("CONTENT.SCHEDULE.HEADER_STARTTIME"),
      $translate.instant("PLAYLIST.SCHEDULE.PLAYLIST"),
      $translate.instant("PLAYLIST.SCHEDULE.DEVICE_CONTROL"),
    ];

    _.forEach($scope.scheduleItems , function(list) {
      var titleArray = [];
      titleArray.push(list.enable ? $translate.instant('COMMON.ENABLED') : $translate.instant('COMMON.DISABLED'))

      var temp = "";

      if (!list.dayOfWeek.length) {
        temp = list.date;
      } else {
        temp = $scope.getDayOfWeekString(list.dayOfWeek);
      }
      titleArray.push(temp);
      titleArray.push($scope.getTimeString(list.startTime));
      if (list.playlistId) {
        titleArray.push(list.playlistName);
      } else {
        titleArray.push("");
      }
      if (list.controlListId) {
        titleArray.push($scope.getDisplayName(list.controlName));
      } else {
        titleArray.push("");
      }

      datas.push({
        //title : dt.playlistName ,
        titleArray : titleArray,
        id : list.scheduleListItemId
      })
    });


    var modalInstance = $modal.open({
      templateUrl: 'components/dialog/dlgSelectItem.html',
      controller: 'DlgSelectItemCtrl',
      backdrop: 'static', keyboard: false,
      size: 'lg',
      resolve: {
        selectItem: function() {
          return {
            titleArray : titleArray,
            dlgTitle : $translate.instant('NAVIGATION.AUTO_PL_CHANE'),
            btnName : $translate.instant('COMMON.DELETE'),
            datas : datas
          };
        }
      }
    });
    modalInstance.result.then(function(results) {

      var  selected = _.filter($scope.scheduleItems, function(tw){
        return _.find(results.selected , function(dt){
          return dt.id === tw.scheduleListItemId;
        })
      });
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.TRANSCODE_LIST.CONFIRM_DELETE_TEXT')}),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        //削除
        _.forEach(selected, function(row){
          m_bEditedItem = true;
          $scope.scheduleItems_delete.push(row);
        });

        $timeout( function() {
          $scope.scheduleItems = _.filter($scope.scheduleItems, function(item) {
            var find =  _.find(selected , function(row) {
              if (angular.equals(item,row)) {
                return true;
              }
            })
            return !find;
          });
          $scope.selectedRow = null;
          createCalendarData();
        });

      });
    }, function() {
      //
    });

  }

  $scope.renameSchecule = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/newName.html',
      controller: 'NewNameCtrl',
      windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaNewNameData: function() {
          return {
            name : $scope.schedule.name
          };
        }
      }
    });
    modalInstance.result.then(function(resNewName) {
      console.log(resNewName);

      var postCreate = {
        name : resNewName.name,
        scheduleListId: $scope.schedule.scheduleListId
      }
      $http.put('/api/v1/schedules/list', postCreate).
      success(function(err) {
        $timeout(function(){
          $scope.schedule.name = resNewName.name;
        });
      });
    }, function() {
      //
    });

  };

  function getOldSchedule() {
    return _.filter($scope.scheduleItems, function(item) {
//      if (item.date && item.enable) {
      if (item.date) {
          var today = new Date();
        var arrTime1 = item.startTime.split(':');
        var tempday = new Date(item.date);
        var setday1 = new Date(tempday.getFullYear(), tempday.getMonth(), tempday.getDate(), arrTime1[0], arrTime1[1]);
        if (setday1 < today) {
          return true;
        }
      }
    })
  }

  function deleteSchedules(items, callback){
    var requests = items.map(function (item) {
      var deferred = $q.defer();

      $http.delete('/api/v1/schedules/list/' + item.scheduleListId + '/' + item.scheduleListItemId).
      success(function(datas) {
      })
      .finally(function () {
        console.log('end');
        deferred.resolve();
      }) ;

      return deferred.promise;
    });

    $q.all(requests).finally(function (results) {
      getScheduleItemsOnly(function(datas){
        callback(datas);
      });
    });
  }

  function confirmOldSchedule(items){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('PLAYLIST.SCHEDULE.MSG.MESSAGE_CONFIRM_DELETE_OLD_SCHEDULE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) {
        //削除せずに終わる
        $modalInstance.close({scheduleItems : $scope.scheduleItems});
        $modalInstance.dismiss({scheduleItems : $scope.scheduleItems});
        return;
      }
      //削除
      deleteSchedules(items, function(datas){
        $modalInstance.close({scheduleItems : datas});
        $modalInstance.dismiss({scheduleItems : datas});
      });
    });
  }

  $scope.openNewNameDialog = function(callback){
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/newName.html',
      controller: 'NewNameCtrl',
      windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaNewNameData: function() {
          return {
            name : $scope.formatDate(new Date() , 'YYYYMMDD_hhmmss')
          };
        }
      }
    });
    modalInstance.result.then(function(resNewName) {
      console.log(resNewName);

      var postCreate = {
        name : resNewName.name
      }
      $http.post('/api/v1/schedules/list', postCreate).
      success(function(datas) {
        if (datas) {
          callback({scheduleListId:datas, name: resNewName.name});
        } else {
          callback(null);
        }
      });
    }, function() {
      //
      callback(null);
    });
  }

  $scope.checkNewScheduleItemName = function(list) {
    var defer = $q.defer();

    if (!list) {
      //New => 名前つけてから渡す
       //new
      $scope.openNewNameDialog(function(result){
        if (!result) {
          defer.reject();
        } else {
          defer.resolve(result);
        }
       });
    } else {
      defer.resolve(list);
    }
    return defer.promise;
  }

  $scope.saveAll = function(items, callback) {
    var requests = items.map(function (item) {
      var deferred = $q.defer();

      createScheduleItem(item, function(){
        deferred.resolve();
      }) ;

      return deferred.promise;
    });

    $q.all(requests).finally(function (results) {
        callback();
    });

  }
  $scope.deleteAll = function(items, callback){
    var requests = items.map(function (item) {
      var deferred = $q.defer();

      $http.delete('/api/v1/schedules/list/' + item.scheduleListId + '/' + item.scheduleListItemId).
      success(function(datas) {
        //削除
        deferred.resolve();
      }) ;

      return deferred.promise;
    });

    $q.all(requests).finally(function (results) {
        callback();
    });

  }

  $scope.saveAndClose = function(){
    //再起動が入っているかどうか
    var findReboot =_.find($scope.scheduleItems, function(item){
      if (item.controlListId) {
        if (item.controlName === 'reboot') {
          if (item.enable) {
            if (item.dayOfWeek){
              if (item.dayOfWeek.length){
                if (item.dayOfWeek.length === 7){ //毎日
                  return true;
                }
              }
            }
          }
        }
      }
    });
    if (!findReboot) {
      //再起動ない
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: "毎日の再起動スケジュールが登録されていません。<br>このまま保存しますか?",
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;

        $scope.do_saveAndClose();
      });
    } else {
      $scope.do_saveAndClose();
    }

  }

  $scope.do_saveAndClose = function(){
    //save
    var list = LimaScheduleItemData.data;
    var promise = $scope.checkNewScheduleItemName(list);
    promise.then(
      function(resultItemName) { //成功
        if (!list) {
          list = resultItemName;
        }
        _.forEach($scope.scheduleItems, function(item){
          if (item.scheduleListId) {
            if (item.scheduleListId !== list.scheduleListId) {
              console.warn("scheduleListId diff!!");
            }
          }
          item.scheduleListId = list.scheduleListId;
        });

        $scope.deleteAll($scope.scheduleItems_delete , function() {
          $scope.saveAll($scope.scheduleItems, function(){
            var items = getOldSchedule();
            if (items && items.length) {
              confirmOldSchedule(items);
            } else {
              $modalInstance.close({scheduleItems : $scope.scheduleItems , scheduleListId : list.scheduleListId});
              $modalInstance.dismiss({scheduleItems : $scope.scheduleItems, scheduleListId : list.scheduleListId});
            }

          });
        })

      },
      function() { //失敗
        //なにもしない
      }
    );
  };

  $scope.close = function(){
    if (m_bEditedItem) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('PLAYLIST.MESSAGE_NO_SAVE_CLOSE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;

        $modalInstance.close({scheduleItems : $scope.scheduleItems_initial});
        $modalInstance.dismiss({scheduleItems : $scope.scheduleItems_initial});
      });
    } else {
      $modalInstance.close({scheduleItems : $scope.scheduleItems_initial});
      $modalInstance.dismiss({scheduleItems : $scope.scheduleItems_initial});

    }
  };
}]);
