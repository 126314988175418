'use strict';

var app = angular.module('limaApp');

app.controller('LogoutCtrl', ['$scope', '$translate', 'Auth', '$window',
function ($scope, $translate, Auth, $window ) {
  $scope.user = {};
  $scope.errors = {};

  Auth.logout();
  $window.location.href='/';

}]);

