'use strict';

var app = angular.module('limaApp');

app.controller('SystemManualPreviewCtrl', ['$scope', '$http', '$filter', '$stateParams','$translate', '$location', '$cookies', 'Auth', '$window', 'limaDiskService','limaOptionService',
function ($scope, $http, $filter, $stateParams, $translate, $location, $cookies, Auth,$window, limaDiskService,limaOptionService) {

  $scope.preview_type = "";
  if (!_.isUndefined($stateParams.type)) {
    $scope.preview_type = $stateParams.type;
  }


  $window.document.title  = $translate.instant('HELP.MANUAL.PREVIEW');

  $scope.nMaxPages = 1;
  $http.get('/api/v1/system-info/manual').
  success(function(datas) {
    console.log(datas);
    if ($scope.preview_type === "line" ) {
      $scope.nMaxPages = datas.line.length;
    } else {
      $scope.nMaxPages = datas.preview.length;
    }
    console.log(datas);
  });

  var nPage = 1;
  $scope.nCurrentPage = 1;
  $scope.sCurrent = "/info/doc/preview/manual-" +  ('00' + $scope.nCurrentPage).slice(-2) + ".jpg";
  if ($scope.preview_type === "line" ) {
    $scope.sCurrent = "/info/doc/preview/line/manual-line-" +  ('00' + $scope.nCurrentPage).slice(-2) + ".jpg";
  }

  $scope.next = function() {
    $scope.nCurrentPage += 1;
    if ($scope.nCurrentPage > $scope.nMaxPages) {
      $scope.nCurrentPage = $scope.nMaxPages;
    }
    $scope.sCurrent = "./info/doc/preview/manual-" + ('00' + $scope.nCurrentPage).slice(-2) + ".jpg";
    if ($scope.preview_type === "line" ) {
      $scope.sCurrent = "/info/doc/preview/line/manual-line-" +  ('00' + $scope.nCurrentPage).slice(-2) + ".jpg";
    }
    }
  $scope.back = function() {
    $scope.nCurrentPage -= 1;
    if ($scope.nCurrentPage < 1) {
      $scope.nCurrentPage = 1;
    }
    $scope.sCurrent = "./info/doc/preview/manual-" + ('00' + $scope.nCurrentPage).slice(-2) + ".jpg";
    if ($scope.preview_type === "line" ) {
      $scope.sCurrent = "/info/doc/preview/line/manual-line-" +  ('00' + $scope.nCurrentPage).slice(-2) + ".jpg";
    }
    }

  var bodyHeight = window.innerHeight;
  $scope.imageHeight = bodyHeight - 100;

  $scope.zoomout = function(){
    $scope.imageHeight -= 100;
    if ($scope.imageHeight < 100) {
      $scope.imageHeight = 100;
    }


  }
  $scope.zoomin = function(){
    $scope.imageHeight += 100;
    if ($scope.imageHeight > 3000) {
      $scope.imageHeight = 3000;
    }
  }

}]);
