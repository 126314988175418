'use strict';


var app = angular.module('limaApp');

import { Sortable } from 'sortablejs'


//ellipse状態（...）のテキスト上でツールチップを出す
const isEllipsized = element =>  element.offsetWidth < element.scrollWidth;
app.directive('ellipsisTooltip', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      const el = element[0];
      const ttDisableAttr = 'tooltipEnable';
      const ttEventTrigger = 'mouseenter';

      const ttShowEventBind = (event, isTriggeredByItself) => {

        if (isEllipsized(el)) {
          attrs.$set(ttDisableAttr, 'true');

          if (!isTriggeredByItself) {
            element.triggerHandler(ttEventTrigger, [true]);
          }

          scope.$applyAsync(() => {
            attrs.$set(ttDisableAttr, 'false');
          });
        }
      };

      if (angular.isObject(el)) {
        attrs.$set(ttDisableAttr, 'false');

        element.on(ttEventTrigger, ttShowEventBind);

        element.on('$destroy', () => {
            element.off(ttEventTrigger, ttShowEventBind);
        });
    }
    }
  };
});
////

app.controller('ContentCtrl', ['$scope', 'socket', 'Auth', '$http', '$localStorage', '$timeout','$filter', '$location','$sce','$uibModal', 'Modal','$q', '$translate','$window', 'ContentUtil', 'limaSocketService','limaOptionService', 'limaShowWaitDialog',
function ($scope, socket, Auth,$http, $localStorage, $timeout,$filter,$location,$sce,$modal, Modal, $q, $translate, $window, ContentUtil, limaSocketService,limaOptionService, limaShowWaitDialog) {
  //'$uibModalInstance',$modalInstance,'LimaModalContentData',,  LimaModalContentData
    //$scope.query=$routeParams.q;
    socket.emit('lima:call-request:v1:contents:init',null);

    $scope.socketId = null;
    socket.on('lima:call-response:v1:contents:init', function (res) {
      console.log("content socket : " + res.data.socketId);
      $scope.socketId = res.data.socketId;
    });

    socket.on('lima:call-response:v1:contents:thumbnail:updated', function (res) {
      console.log("######thumbnail:updated : " , res.contentId);

      $scope.m_arrUpdatingThumbContents = _.filter($scope.m_arrUpdatingThumbContents , function(contId) {
        return contId !== res.contentId
      });

      //リージョンに登録される同じコンテンツもサムネイル変更する
      $timeout(function(){
        $scope.setThumURL(res.contentId , false);
      });
    });

    $scope.storage = (function () {
      var key = 'limaApp:ContentCtrl@' + Auth.getCurrentUser().name;
      var storage = $localStorage[key] || {};

      if (_.isUndefined(storage.thumsize)) storage.thumsize = 0;
      if (_.isUndefined(storage.bContentListEnable)) storage.bContentListEnable = 0;

      var store = function () {
        $localStorage[key] = _.cloneDeep(storage);
      };
      var promise = null;
      $scope.$watch('storage', function () {
        if (promise) $timeout.cancel(promise);
        promise = $timeout(function () { store(); }, 10);
      }, true);
      $scope.$on('$destroy', function () {
        store();
      });
      return storage;
    })();

    ///
    $scope.isAutoUpdated = function(item) {
      return ContentUtil.isAutoupdated(item);
    }
    $scope.isYoutubeLive = function(item){
      return ContentUtil.isYoutubeLive(item);
    }
    $scope.isYoutube = function(content){
      return ContentUtil.isYoutube(content);
    }
    $scope.isHTML = function(item) {
      return ContentUtil.isHTML(item);
    }
    $scope.isURL = function(content) {
      return $scope.isHTML(content) && !$scope.isYoutube(content) && !$scope.isLive(content);
    }

    $scope.isVideo = function(content){
      return ContentUtil.isVideo(content);
    }
    $scope.isImage = function(content){
      return ContentUtil.isImage(content);
    }
    $scope.isImage4K = function(content){
      return ContentUtil.isImage4K(content);
    }

    $scope.isText = function(item) {
      var bb = ContentUtil.isText(item);
      return bb;
    }

    $scope.isExinput = function(item) {
      return ContentUtil.isExInput(item);
    }

    $scope.isLive = function(item) {
      return ContentUtil.isLive(item);
    }
    ///
    $scope.isEnableDeleteContent = function(cont) {
      return !cont.findPlaylistId;
    }

    $scope.showContTypeInfo = {
      ////////paging
      itemsPerPage : 30,
      currentPage : 1,
      enable : true,
      contents :[],
      sorting : false,  //ドラッグした要素が追加されてしまうので、枠内を作り直すためのフラグ
      sorting_a : false,  //ドラッグした要素が追加されてしまうので、枠内を作り直すためのフラグ
      datas : [
        {
          id : ContentUtil.CONTENT_SHOWTYPE_STILL,
          title : $translate.instant('CONTENT.PROPERTY.STILL'),
          checked : false,
          class : "glyphicon glyphicon-picture",
          checkFunc: $scope.isImage
        },
        {
          id : ContentUtil.CONTENT_SHOWTYPE_VIDEO,
          title : $translate.instant('CONTENT.PROPERTY.VIDEO'),
          checked : false,
          class : "glyphicon glyphicon-film",
          checkFunc: $scope.isVideo
        },
        {
          id : ContentUtil.CONTENT_SHOWTYPE_URL,
          title : $translate.instant('CONTENT.PROPERTY.URL'),
          checked : false,
          //class : "fa fa-tv",
          img : "./resources/images/webicon_negate.png",
          checkFunc: $scope.isURL
        },
        {
          id : ContentUtil.CONTENT_SHOWTYPE_YOUTUBE,
          title : $translate.instant('CONTENT.PROPERTY.YOUTUBE'),
          checked : false,
          class : "fa fa-youtube-play",
          checkFunc: $scope.isYoutube
        },
        {
          id : ContentUtil.CONTENT_SHOWTYPE_TELOP,
          title : $translate.instant('CONTENT.PROPERTY.TELOP'),
          checked : false,
          //class : "",
          img : "./resources/images/baseline_title_black_48dp_negate.png",
          checkFunc: $scope.isText
        },
        {
          id : ContentUtil.CONTENT_SHOWTYPE_LIVE,
          title : $translate.instant('CONTENT.PROPERTY.LIVE'),
          checked : false,
          class : "fa fa-video-camera",
          checkFunc: $scope.isLive
        }
        // ,
        // {
        //   type:ContentUtil.CONTENT_SHOWTYPE_NAME_AUTOUPDATED,
        //   id : ContentUtil.CONTENT_SHOWTYPE_AUTOUPDATED,
        //   title : "",
        //   checked : false,
        //   class : "glyphicon glyphicon-picture",
        //   checkFunc: $scope.isAutoUpdated,
        // }
      ]
    }


    $scope.settings ={playlistContentCount : 30 , leaveContent : true}; //leaveContent:コンテンツ残す
    $scope.settings_compny = [];
    var m_sysInfo = {}
    function getInfo(infos, name){
      if (_.isUndefined(infos[name])) {
        return '';
      }
      return infos[name];
    }
    function getSettingValue(datas){
      //初期化
      $scope.settings_compny['setting.server'] = {};
      $scope.settings_compny['setting.ui'] = {};

      _.find(datas, function(dt) {
        if (dt.name === "setting.server") {
          var obj = dt.value;
          $scope.settings_compny['setting.server'] = obj;
        };
        if (dt.name === "setting.ui") {
          var obj = dt.value;
          $scope.settings_compny['setting.ui'] = obj;
        };
      });

      //設定がなかったらシステムデフォルトから持ってくる
      Auth.systemProperties().then(function (data) {
        m_sysInfo = {};
        m_sysInfo.movieMaxSize = Number(getInfo(data , 'setting.system.movieMaxSize') || "500");
        m_sysInfo.playlistContentCount = Number(getInfo(data , 'setting.system.playlistContentCount') || "30");
        var temp = getInfo(data , 'setting.system.contentEncrypt') || "0";
        m_sysInfo.contentEncryptEnable = temp !== "0" ? true : false;

        if (_.isUndefined($scope.settings_compny['setting.server'].contentEncryptEnable)){
          //$scope.settings_compny['setting.server'].contentEncryptEnable = m_sysInfo.contentEncryptEnable
        }
        if (_.isUndefined($scope.settings_compny['setting.ui'].playlistContentCount)){
          $scope.settings.playlistContentCount = m_sysInfo.playlistContentCount;
        } else {
          $scope.settings.playlistContentCount = Number($scope.settings_compny['setting.ui'].playlistContentCount);
        }
        if (_.isUndefined($scope.settings_compny['setting.ui'].leaveContent)){
        } else {
          $scope.settings.leaveContent = Number($scope.settings_compny['setting.ui'].leaveContent);
        }
      });
    }

    function getSetting() {
      var companyId = Auth.getCurrentUser().companyId;
      $http.get('/api/v1/setting' + '?companyId=' + companyId)
      .success(function (res) {
        if (res) {
          getSettingValue(res);
        }
      });
    }
    getSetting();
    $scope.query=$location.search()["q"];
    $scope.query = $location.search()['q'];
    $scope.type = $location.search()['type'];
    if ($scope.type === undefined) {
      $scope.type = 0;
    }
    $scope.isModal = false;
    $scope.isPlaylistModal = false;


    $scope.onThumbError = function (id,elem) {
      console.log("######onThumbError ");
      var index = 0;
      var bCont = false;
      var bRegion = false;
      var cont = null;
      if (id.indexOf("cont_") !== -1 ) {
        index = Number(id.replace("cont_" , ""));
        cont = $scope.showContTypeInfo.contents[index];
      }
      else if (id.indexOf("region_") !== -1 ) {
        index = Number(id.replace("region_" , ""));
        if ($scope.getActiveRegion()) {
          cont = $scope.getActiveRegion().contentsInfo[index];
        } else {
          return;
        }
      } else{
        return;
      }
      if (cont.thumb_intializing) {
        return;  //何もしない
      }
      if ($scope.isHTML(cont)) {
        console.log("######onThumbError " , cont.id);
        $scope.updateThumbnail(cont);
      } else {
        $timeout(function(){
          $http.get('/api/v1/content/contents/' + cont.id)
          .success(function(resMat) {
            $timeout(function(){
              cont.thumbnailUrl = resMat.thumbnailUrl;
            });
          });
        } , 1000 );
      }

      // $http.get('/api/v1/content/contents/' + $scope.contentsInfo[index].id)
      // .success(function(resMat) {
      //   if (resMat.thumbnailUrl !== $scope.contentsInfo[index].thumbnailUrl ) {
      //     $timeout( function(){
      //       $scope.contentsInfo[index].thumbnailUrl = resMat.thumbnailUrl;
      //     });
      //   } else {
      //     console.log("onThumbError next");
      //     $timeout(function() {
      //       $scope.onThumbError(index)
      //     }, 2000);
      //   }
      // });

    }

    var getDeviceInfo = (function(){
      var ua = navigator.userAgent;
      if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
          return 'sp';
      }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
          return 'tab';
      }else{
          return 'other';
      }
    })();

    $scope.device = getDeviceInfo;

    $scope.setListDisplayType = function(enableList){
      $scope.storage.bContentListEnable = enableList;
      $scope.setItemPerPage();

      if (_.isFunction($scope.deleteSortable_LR)) {
        //D&D用のクラスを再作成
        $scope.deleteSortable_LR(true , true);
        $scope.Do_createSortable_LR();
      }
    }

    $scope.updateTypeCheck = function(index) {
      $scope.showContTypeInfo.datas[index].checked = $scope.showContTypeInfo.datas[index].checked ? false : true;
      $timeout(function(){
        if (!$scope.doingSearch) {
          $scope.doSearch();
        }
      });
    }

    $scope.setShowContent = function(bEnable){

      $timeout(function(){
        $scope.storage.bShowContent = bEnable;
        $scope.setItemPerPage();
        $scope.Do_createSortable_LR();

      })
    }
    $scope.uploadContetnList = [];

    $scope.m_arrExType = [
      {type:'url',      name:'URL', resource:'./resources/images/url.png', enable: true},
      {type:'youtube',  name:'YouTube', resource:'./resources/images/youtube.png', enable: true},
      {type:'marcus',   name:'マーカス', resource:'./resources/images/marcas.png', enable: false},
      {type:'twit',     name:'ついっとサイン', resource:'./resources/images/twit.png', enable: false}
    ];


    var arrUploadedMaterials_new = [];
    var arrUploadedMaterials = [];
    $scope.modalUploadFile = function(type) {

      var controllerName = "";
      if (type==='file') {
        controllerName = "LocalModalFileCtrl";

        if ($scope.getActiveRegion()) {
          if ($scope.getActiveRegion().contentsInfo) {
            if ($scope.getActiveRegion().contentsInfo.length >= $scope.settings.playlistContentCount) {
              Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'), modalClass:'modal-warning'});
              return;
            }
          }
        }
      }
      else if (type==='text') {
        controllerName = "LocalModalTextCtrl";
      }
      else if (type==='url') {
        controllerName = "LocalModalHTMLCtrl";
      }
      else if (type==='youtube') {
        controllerName = "LocalModalYoutubeCtrl";
      }
      else if (type==='autoupdated') {
        controllerName = "LocalModalAutoUpdatedCtrl";
      }

      $scope.bPreviewPaused = true;

      var modalInstance = $modal.open({
        templateUrl: 'app/content/local.html',
        controller: controllerName,
        //windowClass: 'modal-upload-window-ex',
        windowClass: 'modal-fit',
        backdrop: 'static', keyboard: false,
        size: 'lg',
        resolve: {
          LimaModalUploadData: function() {
            return {
              contentCount : $scope.getActiveRegion().contentsInfo ? $scope.getActiveRegion().contentsInfo.length : 0
            };
          }
        }
      });
      modalInstance.result.then(function(results) {

        $scope.bPreviewPaused = false;


        $scope.doSearch_AllUploaded(function() {
          $scope.doSearch_AllUploaded_done();
          $scope.sortContent('updatedTime', true);  //登録日時でソート
        });


        //contentsInfoと同じ並びにする
        var arrUploadedMaterials_b = _.cloneDeep(arrUploadedMaterials);
        arrUploadedMaterials = [];
        _.forEach($scope.contentsInfo, function(info){
          var find = _.find(arrUploadedMaterials_b, function(mat){
            if (mat.id === info.id) {
              return true;
            }
          });
          if (find) {
            arrUploadedMaterials.push(find);
          } else {
            console.log("not find");
          }
        });

        arrUploadedMaterials_new = [];
        arrUploadedMaterials_new = results.materials;

        arrUploadedMaterials = arrUploadedMaterials.concat(results.materials);
        $scope.uploadContetnList = $scope.uploadContetnList.concat(results.materials);
        $timeout(function(){
          $scope.doSearch(null , function(){
            if ($scope.searchType.type === 3) {
              $scope.createTemporaryPlaylistHTML();
            }
          });
        });
      }, function() {
        $scope.bPreviewPaused = false;
        //閉じられた
//        console.log("aaa");
      });

    }

    $scope.selectContents = function() {
      if ($scope.getActiveRegion()) {
        if ($scope.getActiveRegion().contentsInfo) {
          if ($scope.getActiveRegion().contentsInfo.length >= $scope.settings.playlistContentCount) {
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'), modalClass:'modal-warning'});
            return;
          }
        }
      }
      var info = {type:'uploaded'};


      $scope.modalContentFile(info);


    }


    function contentInfosFromDatas(datas, callback) {
      {
        var dataMaterials = [];

        var deferred = $q.defer();
        var promise = deferred.promise;

        var maxLen = 50;
        var dataIds = [];
        _.forEach(datas, function(item){
          dataIds.push(item.id);
        });
        var arrForAPI = ContentUtil.sliceByNumber(dataIds , maxLen)

        var requests = _.forEach(arrForAPI, function(item){
          promise = promise.finally(function () {
            return $http
              .post('/api/v1/content/contents/ids' , {ids:item})
              .success(function(resArrMat) {
                _.forEach(resArrMat , function(resMat){
                  if (!_.isUndefined(item.checked)) {
                    resMat.checked = item.checked;
                  } else if (!_.isUndefined(item.enabled)) {
                    resMat.checked = item.enabled;
                  }
                  if (!_.isUndefined(item.fixedRegionId)) {
                    resMat.fixedRegionId = item.fixedRegionId;
                  }
                  _.find(datas, function(dt){
                    if (dt.id === resMat.id) {
                      if (dt.thumb_intializing) {
                        resMat.thumb_intializing = dt.thumb_intializing;
                      }
                      return true;
                    }
                  })
                  dataMaterials.push(resMat);
                });
              })
              .error(function() {
                if (item.id === "-exinput") {
                  dataMaterials.push(item);
                }
              })
              .finally(function () {
              });
          });
        });

        promise = promise.finally(function () {
          if (callback) {
              callback(dataMaterials);
          }
        });

        deferred.resolve();

      }
    }



    $scope.selectExContent = function() {
      if ($scope.getActiveRegion()) {
        if ($scope.getActiveRegion().contentsInfo) {
          if ($scope.getActiveRegion().contentsInfo.length >= $scope.settings.playlistContentCount) {
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'), modalClass:'modal-warning'});
            return;
          }
        }
      }
      $scope.bPreviewPaused = true;

      var modalInstance = $modal.open({
        templateUrl: 'app/content/selectExternalContent.html',
        controller: 'SelectExContentCtrl',
        windowClass: 'modal-upload-window',
//        windowClass: 'modal-fit',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          LimaModalUploadData: function() {
            return {
              contentCount : $scope.contentsInfo ? $scope.contentsInfo.length : 0
            };
          }
        }
      });
      modalInstance.result.then(function(results) {


        if (results.type === 'autoupdated') {

          $scope.modalContentFile(results);
          return ;
        }
        if (results.type === 'exinput') {

          //return ;
        }

        $scope.bPreviewPaused = false;

        $scope.doSearch_AllUploaded(function() {
          $scope.doSearch_AllUploaded_done();
        });

        //contentsInfoと同じ並びにする
        var arrUploadedMaterials_b = _.cloneDeep(arrUploadedMaterials);
        arrUploadedMaterials = [];
        _.forEach($scope.contentsInfo, function(info){
          var find = _.find(arrUploadedMaterials_b, function(mat){
            if (mat.id === info.id) {
              return true;
            }
          });
          if (find) {
            arrUploadedMaterials.push(find);
          } else {
            console.log("not find");
          }
        });

        var arrURLs = [];
        _.filter(results.materials , function(mat) {
          if ($scope.isURL(mat)){
            mat.thumb_intializing = true;
            arrURLs.push(mat);
          }
        })

        arrUploadedMaterials_new = [];
        arrUploadedMaterials_new = results.materials;

        arrUploadedMaterials = arrUploadedMaterials.concat(results.materials);
        $scope.uploadContetnList = $scope.uploadContetnList.concat(results.materials);
        $timeout(function(){
          $scope.doSearch(null , function(){
            if ($scope.searchType.type === 3) {
              $scope.createTemporaryPlaylistHTML();
            }
          });
        });

        $timeout(function() {
          contentInfosFromDatas(arrURLs , function(checkedDatas) {
            _.forEach(checkedDatas , function(dt){
              $scope.setThumURL(dt.id , false , dt.thumbnailUrl);
            })

          })
        },10*1000)

      }, function() {
        $scope.bPreviewPaused = false;

        //閉じられた
//        console.log("aaa");
      });

    }


    $scope.modalContentFile = function(typeInfo) {
      var modalInstance = $modal.open({
        templateUrl: 'app/content/content.html',
        controller: 'ContentModalCtrl',
        windowClass: 'modal-content-window',
        backdrop: 'static', keyboard: false,
        windowClass: 'modal-fit',
        resolve: {
          LimaModalContentData: function() {
            return {
              type:typeInfo.type,
              type2: typeInfo.type2
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        console.log(results);
        var nowLength = 0;

        if ($scope.getActiveRegion()) {
          if ($scope.getActiveRegion().contentsInfo) {
            nowLength = $scope.getActiveRegion().contentsInfo.length;
          }
        }
        if (nowLength + results.length >= $scope.settings.playlistContentCount) {
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT3'), modalClass:'modal-warning'});
          results = results.slice(0, ($scope.settings.playlistContentCount-nowLength));
        }

        //contentsInfoと同じ並びにする
        var arrUploadedMaterials_b = _.cloneDeep(arrUploadedMaterials);
        arrUploadedMaterials = [];
        _.forEach($scope.contentsInfo, function(info){
          var find = _.find(arrUploadedMaterials_b, function(mat){
            if (mat.id === info.id) {
              return true;
            }
          });
          if (find) {
            arrUploadedMaterials.push(find);
          } else {
            console.log("not find");
          }
        });

        arrUploadedMaterials_new = [];
        arrUploadedMaterials_new = results;


        arrUploadedMaterials = arrUploadedMaterials.concat(results);
        $scope.uploadContetnList = $scope.uploadContetnList.concat(results);
        $timeout(function(){
          $scope.doSearch(null , function(){
            if ($scope.searchType.type === 3) {
              $scope.createTemporaryPlaylistHTML();
            }
          });
        });

        //trueのことがあるのでfalse
        $scope.bPreviewPaused = false;
      }, function() {
        //
        //trueのことがあるのでfalse
        $scope.bPreviewPaused = false;
      });
    }

  $scope.apply = function () {
    var selected = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
    if (selected.length === 1) {
      //$modalInstance.close(selected[0]);
    }

  };
  $scope.cancel = function () {
    //$modalInstance.dismiss('cancel');
  };



    // $http.get('/api/v1/device/setting').
    // success(function(datas) {
    //   $scope.setting=datas;
    // });

    $scope.searchTypeDef=[
      {name:'Instagram', icon:'../img/glyph-logo_May2016.png', type:0},
      {name:'Twitter', icon:'../img/Twitter_Logo_WhiteOnBlue.png', type:1},
      {name:'Local', icon:'../img/Twitter_Logo_WhiteOnBlue.png', type:2},
      {name:'Playlist', icon:'../img/Twitter_Logo_WhiteOnBlue.png', type:3}
    ];
    ////////////////////////////////////////////
    $scope.searchType=$scope.searchTypeDef[$scope.type];
    ////////////////////////////////////////////
    $scope.searchType.type = 2;// local

    // $scope.arrLayout = [
    //   {name:"ステーキ縦" , file:"1" , width:1080},
    //   {name:"トラベル" , file:"2" , width:1920},
    //   {name:"ファッション" , file:"3" , width:1920},
    //   {name:"ステーキ横" , file:"4" , width:1920}
    // ];
    // $scope.selectLayout = $scope.arrLayout[0];

    $scope.initVideo = function(index) {
      $timeout(function(){
      var vv = document.getElementById(""+index);
      vv.pause();
      // vv.addEventListener('loadedmetadata', function(){
      //   vv.currentTime=0;
      // }, false);

      },1000);
    }


    $scope.onResize = (function(previesize) {
      var elem_canvas = document.getElementById('canvasLayout');


      var elem_iframe = document.getElementById('canvas_par');
      var scale = 1;
      var scale_h = 1;
      if (elem_iframe) {
        scale = elem_iframe.clientWidth / 1920;
        scale_h = elem_iframe.clientHeight / 1920;

      }
      //console.log("scale_w : " + scale + "  h: " + scale_h)
      if (scale < scale_h) {
      }

      if (!angular.element('#iframePreview')) {
        return;
      }
      if (!angular.element('#iframePreview').length) {
        return;
      }

      angular.element('#iframePreview')[0].style.transform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.MozTransform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.WebkitTransform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.OTransform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.MsTransform = 'scale(' + scale + ')';


      // 'transform':'scale(' + scale + ')',
      // '-moz-transform':'scale(' + scale + ')',
      // '-webkit-transform':'scale(' + scale + ')',
      // '-o-transform':'scale(' + scale + ')',
      // '-ms-transform':'scale(' + scale + ')',

      if ($scope.playlistId) {
        //#14797 コンテンツの再生部分で右メニューを非表示；iframeは中まで入って禁止しないと効かない
        $timeout(function() {
          const frames = window.frames;
          // サブフレームを一つずつループする
          for (let i = 0; i < frames.length; i++) {
            // iframe内での右クリックを禁止する
            frames[i].document.oncontextmenu = function(){
              return false;
            };
          }
        },100)
      }


    })

    function onResizeA() {
      $scope.onResize();
      $scope.$apply();
    }

    angular.element($window).on('resize', onResizeA);
    $timeout(function(){  //timeoutの中でapplyが起こる
      $scope.onResize();
    });

    $scope.$on('$destroy', function () {
      //store();
       angular.element($window).off('resize', onResizeA);
    });

    $scope.iframestyle_canvas = function() {
    //   var elem_iframe = document.getElementById('canvas_par');
    //   if (!elem_iframe) {
    //     return;
    //   }
    //   var scale = 1;
    //   if (elem_iframe) {
    //     scale = elem_iframe.clientWidth / elem_iframe.clientHeight
    //   }
    //   if (scale > (16.0/9.0)) {
    //     //横長→
    //     var w= Math.floor(elem_iframe.clientHeight/9*16);
    //     var val ={
    //       'width':w + 'px'
    //     }
    //     return val;

    //   } else {
    //     //縦長
    //     var val ={
    //       'width':elem_iframe.clientWidth + 'px'
    //     }
    //     return val;
    //   }
        const canvasPar = document.getElementById('canvas_par');
        const canvas = document.getElementById('canvasLayout');
        const rotate = canvas.height > canvas.width;

        const aspect = 16 / 9;
        if(!rotate){
            //横画面
            const width = canvasPar.clientWidth;
            const height = width / aspect;

            return{
                'width': width + 'px',
                'height': height + 'px',
            }
        }else{
            //縦画面
            const scale = canvasPar.clientWidth / 1920
            return{
                'width': 1080 * scale,
                'height': 1920 * scale,
            }
        }
    }

    $scope.iframestyle3 = () => {
        const canvasPar = document.getElementById('canvas_par');
        var scale = 1;
        if (canvasPar) {
          scale = canvasPar.clientWidth / 1920;
        }

        const rotate = (() => {
          if($scope.selectLayout.model){
            return $scope.selectLayout.model.rotate
          }else{
            return 0
          }
        })();

        return{
            'width': 1920 * scale,
            'height': 1080 * scale,
            'rotate': rotate + 'deg',
        }
    }

    $scope.iframestyle2 = function() {
      var elem_iframe = document.getElementById('canvas_par');
      var scale = 1;
      if (elem_iframe) {
        scale = elem_iframe.clientWidth / 1920;
      }

      var val ={
        'width':'1920px',
        'height':'1080px',
        'transform':'scale(' + scale + ')',
        '-moz-transform':'scale(' + scale + ')',
        '-webkit-transform':'scale(' + scale + ')',
        '-o-transform':'scale(' + scale + ')',
        '-ms-transform':'scale(' + scale + ')',
        'transform-origin':'0 0',
        '-moz-transform-origin':'0 0',
        '-webkit-transform-origin':'0 0',
        '-o-transform-origin':'0 0',
        '-ms-transform-origin':'0 0',
      } ;

      return val;
    }
    $scope.iframestyle = function() {

      var val_w1080 ={
        'transform':'scale(0.2)  translate(1080px,0px)',
        'transform-origin':'0px 0px',
        'width': '1080px',
        'height': '1920px',
    };

      var val_w1920 ={
        'transform':'scale(0.23) translate(200px,0px)',
        'transform-origin':'0px 0px',
        'width': '1920px',
        'height': '1080px',
      };

      return $scope.selectLayout.model.width === 1920 ? val_w1920 : val_w1080;
    }

    $scope.isModal = false;
    // if (LimaModalContentData) {
    //   $scope.isModal = true;

    // }

    function GetFolderName(FileTypeID){
      var sDCMFolder=[
        {
          "FileTypeID": 4,
          "FolderName":"Telop"
        },
        {
          "FileTypeID": 16,
          "FolderName":"HTML"
        },
        {
          "FileTypeID": 128,
          "FolderName":"QuickTime"
        },
        {
          "FileTypeID": 2,
          "FolderName":"Still"
        },
        {
          "FileTypeID": 32,
          "FolderName":"Audio"
        }
      ];


      var res = _.find(sDCMFolder, function(folder) {
        if (folder.FileTypeID === FileTypeID) {
          return true;
        }
      });
      if (res) {
        return res.FolderName;
      }
      return "";
    }

    $scope.deleteContentFromUI = function(selectCont) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        deletePlaylistContent(selectCont);
      });
    }


    $scope.deleteContetnList = [];

    function do_deletePlaylistContent(content){
      var filterCont = _.filter($scope.contentsInfo, function(cont){
        if ( cont.id === content.id ) {
          return true;
        }
      });
      if (filterCont.length > 1) {
        //Copyがあるのでコンテンツ自体は消してはいけない
      } else {
        if (content.id !== "-exinput") {
          //実体を消す：プレイリストを保存するときに消すので一旦削除リストに入れる
          $scope.deleteContetnList.push(content);
          // $http.delete('/api/v1/content/contents/' + content.id)
          // .finally(function () {
          // })
        }
      }
      //列挙から消す
      $scope.contentsInfo = _.filter($scope.contentsInfo, function(cont){
        return _.isEqual(cont,content) ? false : true;  //hashKeyまで比較してobject的に完全一致したものだけ排除
      });

    }

    $scope.thumbInfo = {
      selected : 0,
      datas : [
        {
          size :0,
          list_image_box : {
            width : 200,
            height : 240,
          },
          inner : {
            width: 200,
            height : 200
          },
          inner_img : {
            max_width: 200,
            max_height : 200
          },
          outer : {
            width: 200,
            height : 200,
            margin_right : 20,
            margin_bottom : 20
          },
          div_inner :{
            width: 200,
          },
          div_title : {
            width: 180,
          },
          image_box_checkbox:{
            top: 12,
            right: 12,
            transform_scale : 2
          },
          content_icon:{
            top: 12,
            right: 12,
          }
        },
        {
          size :1,
          list_image_box : {
            width : 100,
            height : 120,
          },
          inner : {
            width: 100,
            height : 100
          },
          inner_img : {
            max_width: 100,
            max_height : 100
          },
          outer : {
            width: 100,
            height : 100,
            margin_right : 20,
            margin_bottom : 20
          },
          div_inner :{
            width: 100,
          },
          div_title : {
            width: 80,
          },
          image_box_checkbox:{
            top: 0,
            right: 8,
            transform_scale : 1.5
          },
          content_icon:{
            top: 0,
            right: 8
          }
        }

      ]
    };

    //何個表示できるか
    $scope.calcContentDiv = function() {
      var elem_par = document.getElementById("parentContent");

      if (!elem_par) {
        return -1;
      }
      var parW = elem_par.clientWidth - 30;  //30:padding左右分
      var parH = elem_par.clientHeight;;


      //var elems = document.getElementsByClassName("image_box");
      var elemW = $scope.thumbInfo.datas[$scope.storage.thumsize].list_image_box.width;
      var elemH = $scope.thumbInfo.datas[$scope.storage.thumsize].list_image_box.height;
      var num_x = Math.floor(parW / elemW);
      var num_y = parH / elemH;

      return num_x;

    }

    $scope.setItemPerPage = function() {
      if ($scope.storage.bContentListEnable) {
        $scope.showContTypeInfo.itemsPerPage = itemsPerPage_list;

      } else {
        if ($scope.storage.thumsize === 0) {
          $scope.showContTypeInfo.itemsPerPage = itemsPerPage_thumb_0;
          var x = $scope.calcContentDiv();
          if (x < 0) {
            x = itemsPerPage_thumb_0;
          }
          var amari = itemsPerPage_thumb_0 % x;
          var retu = Math.floor(itemsPerPage_thumb_0 / x);
          $scope.showContTypeInfo.itemsPerPage = amari ? (retu+1) * x  : retu * x;
        }
        else if ($scope.storage.thumsize === 1) {
          $scope.showContTypeInfo.itemsPerPage = itemsPerPage_thumb_1;
          var x = $scope.calcContentDiv();
          if (x < 0) {
            x = itemsPerPage_thumb_1;
          }
          var amari = itemsPerPage_thumb_1 % x;
          var retu = Math.floor(itemsPerPage_thumb_1 / x);
          $scope.showContTypeInfo.itemsPerPage = amari ? (retu+1) * x  : retu * x;
        }
      }

      $scope.showContTypeInfo.currentPage=1;
      $scope.showContTypeInfo.pages = Math.ceil($scope.showContTypeInfo.contents.length / $scope.showContTypeInfo.itemsPerPage);

    }


    $scope.setthumSize = function(size){

      $timeout(function() {
        $scope.storage.thumsize = size;
        $scope.setItemPerPage();
      })
    }
    $scope.getthumStyle = function(name , content){
      var obj = {};
      if ($scope.storage) {
        if (_.isUndefined($scope.storage.thumsize)) {
          return;
        }
      }
      var info = $scope.thumbInfo.datas[$scope.storage.thumsize];
      switch(name){
        case 'content_icon':
          obj.top = info.content_icon.top + "px";
          obj.right = info.content_icon.right + "px";
          break;
        case 'image_box_checkbox':
          obj.top = info.image_box_checkbox.top + "px";
          obj.right = info.image_box_checkbox.right + "px";
          obj.transform = "scale(" + info.image_box_checkbox.transform_scale + ")";
          break;
        case 'div_title':
          obj.width = info.div_title.width + "px";
          break;
        case 'div_inner':
          obj.width = info.div_inner.width + "px";
          break;
        case 'list_image_box':
          obj.width = info.list_image_box.width + "px";
          obj.height = info.list_image_box.height + "px";
          break;
        case 'outer':
          obj.width = info.outer.width + "px";
          obj.height = info.outer.height + "px";
          obj.marginRight = info.outer.margin_right + "px";
          obj.marginBottom = info.outer.margin_bottom + "px";
          break;
        case 'inner':
          obj.width = info.inner.width + "px";
          obj.height = info.inner.height + "px";
          break;
        case 'inner_img':
            if (content) {
              if (content.thumbnailUrl.indexOf('resources/images/') !== -1) {
                obj.maxWidth = info.inner_img.max_width * 2 / 3 + "px";
                //obj.maxHeight = info.inner_img.max_height * 2 / 3  + "px";
                break;
              }
            }
          obj.maxWidth = info.inner_img.max_width + "px";
          obj.maxHeight = info.inner_img.max_height + "px";
          break;
      }
      return obj;
    }

    function deletePlaylistContents(contents){

      //ほんとうに消すコンテンツ：コピーされたものは除く：実体が同じなため
      var arrForDel = [];
      _.forEach(contents, function(cont) {
        var find = _.find(arrForDel , function(delCont) {
          return delCont.id === cont.id;
        });
        if (!find) {
          var cont_clone = _.cloneDeep(cont);
          cont_clone.contentCount = 1;
          arrForDel.push(cont_clone);
        } else {
          find.contentCount = find.contentCount + 1;
        }
      })

      //完全に消すかどうか
      _.forEach(arrForDel, function(contDel) {
        var arrMatchCont = _.filter($scope.contentsInfo , function(cont) {
          return cont.id === contDel.id;
        });
        if (arrMatchCont.length === contDel.contentCount) {
          contDel.delForRegion = true;  //リージョンからも消す
        }
      })

      var arrfindCont_rg = [];
      _.forEach(arrForDel, function(cont) {
        if (cont.delForRegion && $scope.selectLayout.model.regions.length >= 1) {
          _.forEach($scope.selectLayout.model.regions, function(rg) {
            if (rg.contentsInfo) {
              return _.find(rg.contentsInfo , function(cont_rg) {
                if ( cont.id === cont_rg.id ) {
                  arrfindCont_rg.push({region:rg , content:cont_rg});
                }
              })
            }
          });
        }
      });

      if (arrfindCont_rg.length) {
        Modal.confirm.yesno({
          title: $translate.instant('COMMON.CONFIRM'),
          html: "リージョンに登録されているコンテンツがあります。削除しますか？",
          btnClassYes: 'btn-danger',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-default',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;

          //リージョンから登録解除
          _.forEach(arrfindCont_rg , function(rgCont) {

            rgCont.region.contentsInfo = _.filter(rgCont.region.contentsInfo , function(cont) {
              if (_.isEqual(cont, rgCont.content) !== true) {
                return true;
              }
            })
          })

          //削除
          _.forEach(contents, function(cont) {
            do_deletePlaylistContent(cont);
          })
          //プレビュー更新
          $scope.createTemporaryPlaylistHTML();
          calcPlaylistDuration();

        });
      } else {
        // //リージョンから登録解除:編集中に別のレイアウトのリージョンに登録されてしまってるので
        // arrfindCont_rg = [];
        // _.forEach(arrForDel, function(cont) {
        //   _.forEach($scope.regions, function(rg) {
        //       if (rg.contentsInfo) {
        //         return _.find(rg.contentsInfo , function(cont_rg) {
        //           if ( cont.id === cont_rg.id ) {
        //             arrfindCont_rg.push({region:rg , content:cont_rg});
        //           }
        //         })
        //       }
        //   });
        // });
        // //リージョンから登録解除
        // _.forEach(arrfindCont_rg , function(rgCont) {
        //   rgCont.region.contentsInfo = _.filter(rgCont.region.contentsInfo , function(cont) {
        //     if (_.isEqual(cont, rgCont.content) !== true) {
        //       return true;
        //     }
        //   })
        // })

        //削除
        _.forEach(contents, function(cont) {
          do_deletePlaylistContent(cont);
        })
        // //プレビュー更新
        // $scope.createTemporaryPlaylistHTML();
        // calcPlaylistDuration();

      }
    }

    function deletePlaylistContent(content){
      deletePlaylistContents([content]);

      //プレビュー更新
      $scope.createTemporaryPlaylistHTML();
      calcPlaylistDuration();

    }
    $scope.deleteContents = function(selected, callback){
      var deferred = $q.defer();
      var promise = deferred.promise;

      if (!selected.length) {
        callback();
      } else {
        var requests = _.forEach(selected, function(item){
          promise = promise.finally(function () {
            return $http
              .delete('/api/v1/content/contents/' + item.id , {data: {playlistId:$scope.playlistId}, headers: {'Content-Type': 'application/json;charset=utf-8'}})
              .finally(function () {
                console.log(item.id);
              })
            ;
          });
        });

        promise = promise.finally(function () {
          console.log('end delete');
          callback();
        });
        deferred.resolve();
      }

    }

    $scope.deleteContent = function() {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.SELECTED_CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        //削除

        var selected = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
        var promise = $scope.deleteContents(selected , function(){
          $timeout(function(){
            $scope.doSearch();
          });
        });
      });
    };

    function contentInfosForUIFromDatas(datas, callback) {
      contentInfosFromDatas(datas , function(dataMaterials) {
        datasToContentsInfo(dataMaterials);
        $timeout(function(){$scope.$apply()});
        if (callback) {
            callback();
        }
      });
    }

    function checkUploadMaterials() {
      arrUploadedMaterials = _.filter(arrUploadedMaterials, function(mt){
        var find = _.find($scope.deleteContetnList, function(cont){
          if (cont.id === mt.id) {
            if (!ContentUtil.isAutoupdated(cont)) {
              return true;
            }
          }
        });
        return find ? false : true;
      });
    }

    $scope.doSearch_type3 = function(playlistId, callback) {
      if (playlistId) {
        $http.get('/api/v1/playlists/' + playlistId + '/contents').
        success(function(datas) {
          _.forEach(datas, function(dt){
            dt.id = dt.contentId;
          })
          arrUploadedMaterials = arrUploadedMaterials.concat(datas);
          contentInfosForUIFromDatas(datas, callback);
        })
        .error(function(data) {
          if (callback) {
            callback();
          }
        });

      } else {
        checkUploadMaterials();
        contentInfosForUIFromDatas(arrUploadedMaterials, callback);
    }


    }

    $scope.noCacheParam = '?dum=' + new Date().getTime();

    $scope.getAutoUpdatedTypeName = function(item) {
      return ContentUtil.getAutoUpdatedTypeName(item);
    }

    function datasToContentsInfo(datas){
      $scope.contentsInfo = [];

      var startTime = new Date();
      startTime = startTime.getTime();

      var endTime = new Date();
      endTime = endTime.getTime();

      console.log("######doingSearch 1-1 " + (endTime - startTime));

      var checkedMaterial = false;
      if ($scope.searchType.type===3) {
        checkedMaterial = true;
      }

      var materials = datas;
      _.forEach(materials , function(item){
          if (_.isUndefined(item.checked)) {
            item.checked = checkedMaterial;
          } else {
            item.checked = item.checked ? true : false;
          }

          if (item.urlContents) {
            var bThumb = false;
            if (item.urlContents.length) {
              if (item.urlContents[0].additionalInfo) {
//                if (item.urlContents[0].additionalInfo.type==='youtube') {
                  bThumb = true;
//                }

              }
            }
            // if (!bThumb) {
            //   item.thumbnailUrl = "./resources/images/webicon.png";
            // }
          }
          $scope.contentsInfo.push(item);
      });
      return;
    }

    //テロップの向きを現在のリージョンから決める
    $scope.checkTelopVerticalByRegion = function(cont){
      if ($scope.isText(cont)) {
        if (cont.additionalInfo) {
          if ($scope.getActiveRegion().width < $scope.getActiveRegion().height) {
            if (!$scope.selectLayout.model.rotate) {
              //横：縦長
              cont.additionalInfo.vertical = true;
            }
          } else if($scope.getActiveRegion().width > $scope.getActiveRegion().height) {
            if ($scope.selectLayout.model.rotate !== 0) {
              //縦：横長
              cont.additionalInfo.vertical = true;
            }
          }
        }
      }
    }

    $scope.doingSearch = false;
    $scope.doSearch = function(playlistId, callback) {
      $scope.contentsInfo = [];
      if ($scope.searchType.type===2) {
        limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('PLAYLIST.MESSAGE_LOADING_PLAYLIST'));

        console.log("######doingSearch 1");
        $scope.doingSearch = true;

        //最新のでソート
        var params= {
          order: {'registeredDate':'desc'}
        };

        var enableAutoupdated = _.find($scope.showContTypeInfo.datas, function(st) {
          if (st.id === ContentUtil.CONTENT_SHOWTYPE_AUTOUPDATED) {
            return st.checked;
          }
          return false;
        });

        if (enableAutoupdated) {
          params.type = ContentUtil.CONTENT_AUTOUPDATED;  //autoupdated
          //params.type2 = enableAutoupdated.type2
        }

        $http.get('/api/v1/content/contents' , {params:params}).
        success(function(datas) {
          if (enableAutoupdated) {
            datas = _.filter(datas, function(dt) {
              return _.find(enableAutoupdated.type2, function(tt) {
                return dt.imageUrl.indexOf(tt) !== -1;
              })
            });
            var arrNames = [];
            _.forEach(datas , function(dt) {
              var aa = dt.title.replace('(' , '?');  //LINE(1)=>LINE?1?=>split:LINE 1 space
              aa = aa.replace(')' , '?');
              var arrA = aa.split("?");
              if (arrA.length) {
                var find = _.find(arrNames , function(name) {
                  return name.indexOf(arrA[0]) !== -1;
                });
                if (!find) {
                  arrNames.push(arrA[0]);
                }
              }
            })

            var datas_all = [];
            _.forEach(arrNames , function(name) {
              var datas_name = _.filter(datas , function(dt) {
                return dt.title.indexOf(name) !== -1;
              })

              //名前でソートする
              datas_name = datas_name.sort(function(a, b) {
                var aa = a.title.replace('(' , '?');  //LINE(1)=>LINE?1?=>split:LINE 1 space
                aa = aa.replace(')' , '?');
                var arrA = aa.split("?");
                var bb = b.title.replace('(' , '?');
                bb = bb.replace(')' , '?');
                var arrB = bb.split("?");
                if (arrA.length===3 && arrB.length===3){
                  return Number(arrA[1]) -  Number(arrB[1]);
                } else {
                  return (a.title < b.title) ? -1 : 1;  //オブジェクトの昇順ソート
                }
              });
              datas_all = datas_all.concat(datas_name);

            })
            datas = datas_all;
          }
          contentInfosForUIFromDatas(datas , function() {
            //#15683 LINE連携：「別の場所」からLINEを選択した時、LINEのコンテンツはすべて選択状態にする
            if (enableAutoupdated) {
              $scope.showContTypeInfo.contents_all = $scope.contentsInfo;
              if (enableAutoupdated.type2.indexOf("/line/") !== -1) {
                _.forEach($scope.contentsInfo , function(info) {
                  info.checked = true;
                })
              }
              $scope.showContTypeInfo.contents = $scope.showContTypeInfo.contents_all;
            } else {
              $scope.contentsInfo = _.filter($scope.contentsInfo , function(cont){
                return cont.type !== 3; //auto_update以外
              });
              $scope.showContTypeInfo.contents_all = $scope.contentsInfo;
              //初回の表示設定
              $scope.setShowContType();
            }
            //
            $timeout(function(){$scope.$apply()});
            limaShowWaitDialog.hideWait();

            if (callback) {
              callback();
            }

          })
        });

      }
      else if ($scope.searchType.type===3) {
        //playlistのコンテンツ
        $scope.doSearch_type3(playlistId, function(){

          calcRegion(playlistId, true);

          if ($scope.contentsInfo) {
            var res_isEnableInsert = {};
            var arrNotPushCont = [];
            _.forEach(arrUploadedMaterials_new , function(mat) {
              var findCont = _.find($scope.contentsInfo , function(cont) {
                if (mat.id === cont.id) {
                  if (mat.primaryKey === cont.primaryKey) {
                    return true;
                  }
                }
              })
              if (findCont) {
                res_isEnableInsert = $scope.isEnableInsertToRegion(findCont , $scope.getActiveRegion());
                if (res_isEnableInsert.enable) {
                  $scope.checkTelopVerticalByRegion(findCont);
                  $scope.getActiveRegion().contentsInfo.push(findCont);
                } else {
                  arrNotPushCont.push(findCont);
                }
              }
            })

            if (arrNotPushCont.length) {
              var message = "複数リージョンに登録できないコンテンツがアップロードされました。<br><br>";
              var arrName = [];
              _.forEach(arrNotPushCont , function(cont){
                arrName.push("<li>" + cont.title + "</li>");
              })
              message += arrName.join("")

              if (arrNotPushCont.length === 1) {
                if (arrNotPushCont[0].id === "-exinput"){
                  message = "複数リージョンには登録できません"
                  message += "<br>※「" + res_isEnableInsert.reasonContType+ "」が別のリージョンに登録されています。"
                }
                else if ($scope.isYoutube(arrNotPushCont[0])){
                  message = "複数リージョンには登録できません"
                  message += "<br>※「" + res_isEnableInsert.reasonContType+ "」が別のリージョンに登録されています。"
                }
                else if ($scope.isLive(arrNotPushCont[0])){
                  message = "複数リージョンには登録できません"
                  message += "<br>※「" + res_isEnableInsert.reasonContType+ "」が別のリージョンに登録されています。"
                }
                else if ($scope.isVideo(arrNotPushCont[0])){
                  message = "複数リージョンには登録できません"
                  message += "<br>※「" + res_isEnableInsert.reasonContType+ "」が別のリージョンに登録されています。"
                }
                else if ($scope.isImage4K(arrNotPushCont[0])){
                  message = "複数リージョンには登録できません"
                  message += "<br>※「" + res_isEnableInsert.reasonContType+ "」が別のリージョンに登録されています。"
                }
              }

              Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
              html:message,
              modalClass:'modal-success'});
            }
          }

         //長さ計算
         calcPlaylistDuration();
          $timeout(function(){$scope.$apply()});
          if (callback) {
            callback();
          }
        });
      }
    }


    $scope.showContTypeInfo_all = {
        checked : true,
    }

    const itemsPerPage_list = 20;
    const itemsPerPage_thumb_0 = 10;  //大
    const itemsPerPage_thumb_1 = 30;  //小


    $scope.prevPage = function() {
      $scope.showContTypeInfo.checked_all = false;
      if ($scope.showContTypeInfo.currentPage > 1) {
        $scope.showContTypeInfo.currentPage--;
      } else {
        //最後のページへ
        $scope.showContTypeInfo.currentPage = $scope.pages;
      }
    };
    $scope.nextPage = function() {
      $scope.showContTypeInfo.checked_all = false;
      if ($scope.showContTypeInfo.currentPage < $scope.showContTypeInfo.pages) {
        $scope.showContTypeInfo.currentPage++;
      } else {
        //先頭に戻る
        $scope.showContTypeInfo.currentPage=1;
      }
    };

    $scope.setShowContType_all = function(enable , conttype_index){
      $scope.showContTypeInfo.checked_all = false;

      $scope.showContTypeInfo_all.checked = enable;

      //$timeout(function() {

        if ($scope.showContTypeInfo_all.checked) {
          _.forEach($scope.showContTypeInfo.datas , function(dt, index) {
            dt.checked = false;
          })
          $scope.showContTypeInfo.contents = $scope.showContTypeInfo.contents_all;

          if (!$scope.storage.bContentUsed) {
            $scope.showContTypeInfo.contents =  _.filter($scope.showContTypeInfo.contents , function(cont){
              if (_.isUndefined(cont.playlistName_all)) {
                return true;
              } else {
                return !cont.playlistName_all.length;
              }
            })
          }

          $scope.sortContent($scope.storage.sortId);
          $scope.setItemPerPage();

        } else {
          //
        }
      //});
    }

    $scope.setShowContType = function(index){
      $scope.setShowContType_all(false);

      //$timeout(function() {
        if (!_.isUndefined(index)) {
          $scope.showContTypeInfo.datas[index].checked = !$scope.showContTypeInfo.datas[index].checked;
        }

        _.forEach($scope.showContTypeInfo.datas , function(dt, index) {
          dt.checked = false;
        })
        $scope.showContTypeInfo.datas[index].checked = true;


        $scope.showContTypeInfo.contents = [];
        $scope.showContTypeInfo.contents = _.filter($scope.showContTypeInfo.contents_all , function(cont) {
          return _.find($scope.showContTypeInfo.datas , function(info) {
            if (info.checked) {
//                console.log(cont.id , ContentUtil.getFileformatName(cont));
              return info.checkFunc(cont);
            }
          })
        })
        if (!$scope.storage.bContentUsed) {
          $scope.showContTypeInfo.contents =  _.filter($scope.showContTypeInfo.contents , function(cont){
            if (_.isUndefined(cont.playlistName_all)) {
              return true;
            } else {
              return !cont.playlistName_all.length;
            }
          })
        }
        $scope.sortContent($scope.storage.sortId);
        $scope.setItemPerPage();

      //})
    }

    //コンテンツ種類の詳細: ex 1_JPEG
    $scope.getContentTypeDetail = function(cont) {
      if ($scope.isImage(cont)) {
        return "" + cont.contentType + "_" + ContentUtil.getImageCodecName(cont);
      }
      return "" + cont.contentType;
    }
    //コンテンツ種類の詳細: ex 静止画（JPEG）
    $scope.getContentTypeDetailString = function(cont) {
      if ($scope.isImage(cont)) {
        return $translate.instant('CONTENT.PROPERTY.STILL') + " (" + ContentUtil.getImageCodecName(cont) + ")";
      }
      return "";
    }

    //ソート
    $scope.sortContent = function(id, sortDesc) {
      //id : name , type , filesize , playlistName , updatedtime
      if (_.isUndefined(id)) {
        id = 'updatedTime';
      }
      if (_.isUndefined(sortDesc)) {
        if (_.isUndefined($scope.storage.sortDesc)) {
          sortDesc = true;
        } else {
          sortDesc = $scope.storage.sortDesc;
        }
      }

      var sortName ="";
      var sortName2 ="primaryKey";
      if (id==='name') {
        sortName ="title";
      }
      if (id==='type') {
        if (!sortDesc) {
          $scope.showContTypeInfo.contents = _.orderBy($scope.showContTypeInfo.contents , [ function(dt){return $scope.getContentTypeDetail(dt)} , 'title'],['asc', 'asc']);
        } else {
          $scope.showContTypeInfo.contents = _.orderBy($scope.showContTypeInfo.contents , [ function(dt){return $scope.getContentTypeDetail(dt)}, 'title'],['desc', 'desc']);
        }

      }

      if (id==='updatedTime') {
        sortName ="registeredDate";
      }
      if (id==='size') {
        if (!sortDesc) {
          $scope.showContTypeInfo.contents = _.orderBy($scope.showContTypeInfo.contents , [ function(dt){return $scope.getFileSizeNumber(dt)} , 'title'],['asc', 'asc']);
        } else {
          $scope.showContTypeInfo.contents = _.orderBy($scope.showContTypeInfo.contents , [ function(dt){return $scope.getFileSizeNumber(dt)}, 'title'],['desc', 'desc']);
        }
      }
      if (id==='playlistName') {
        sortName ="playlistName_all";
      }

      if (sortName.length) {
        if (!sortDesc) {
          //指定されたものと名前で並び替える
          $scope.showContTypeInfo.contents = _.orderBy($scope.showContTypeInfo.contents , [ sortName , sortName2],['asc', 'asc']);
        } else {
          $scope.showContTypeInfo.contents = _.orderBy($scope.showContTypeInfo.contents , [ sortName , sortName2],['desc', 'desc']);
        }
      }

    }

    //コンテンツ削除ボタンからの選択削除
    $scope.selectDeleteContent = function(){
      var modalInstance = $modal.open({
        templateUrl: 'app/content/dlg/selectContent.html',
        controller: 'DlgSelectContentCtrl',
        //windowClass: 'modal-registSNS-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          selectContent: function() {
            return {
              btnName : $translate.instant('COMMON.DELETE'),
              contentsInfo : $scope.contentsInfo
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        Modal.confirm.yesno({
          title: $translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
          btnClassYes: 'btn-default',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-danger',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;
          deletePlaylistContents(results.selected);
        });

      }, function() {
        //
      });


    }
    //コンテンツ登録解除ボタンからの解除
    $scope.selectReleaseContentFromRegion = function(){
      var modalInstance = $modal.open({
        templateUrl: 'app/content/dlg/selectContent.html',
        controller: 'DlgSelectContentCtrl',
        //windowClass: 'modal-registSNS-window',
        backdrop: 'static', keyboard: false,
        size: 'lg',
        resolve: {
          selectContent: function() {
            return {
              btnName : "登録解除",
              contentsInfo : $scope.getActiveRegion().contentsInfo
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        Modal.confirm.yesno({
          title: $translate.instant('COMMON.CONFIRM'),
          html: '登録を解除してよろしいですか',
          btnClassYes: 'btn-default',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-danger',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;

          var contents = results.selected;

          var arrfindCont_rg = [];
          _.forEach(contents, function(cont) {
            var find = _.find($scope.selectLayout.model.regions, function(rg) {
              if (rg.contentsInfo) {
                return _.find(rg.contentsInfo , function(cont_rg) {
                  if ( _.isEqual(cont,cont_rg) ) {  //hashまで完全一致
                    arrfindCont_rg.push({region:rg , content:cont_rg});
                    return true;
                  }
                })
              }
            });
          });

          //リージョンから登録解除
          _.forEach(arrfindCont_rg , function(rgCont) {

            rgCont.region.contentsInfo = _.filter(rgCont.region.contentsInfo , function(cont) {
              if (_.isEqual(cont, rgCont.content) !== true) {
                return true;
              }
            })
          })

          $scope.createTemporaryPlaylistHTML();
          //長さ計算
          calcPlaylistDuration();

        });

      }, function() {
        //
      });


    }

    var m_initCalcRegion = false;
    function calcRegion(playlistId , init , beforeLayout){
      if (_.isUndefined($scope.selectLayout)) {
//        $timeout(function(){calcRegion(playlistId , init);} , 500)
        return;
      }
      if (_.isUndefined($scope.selectLayout.model)) {
//        $timeout(function(){calcRegion(playlistId, init);} , 500)
        return;
      }

      var initialRegion = true;
      var findContentInRegion = false;  //旧バージョン（リージョンの概念無しで作られたもの）かどうか判断させる
      _.forEach($scope.regions , function(rg) {
        var findRg = _.find($scope.selectLayout.model.regions, function(sel_rg) {
          return sel_rg.regionId === rg.regionId
        })
        if (findRg) {
          if (rg.contentsInfo) {
            if (rg.contentsInfo.length) {
              init = false;
            }
          }
          if (init) {
            rg.contentsInfo = [];
          if (rg.contentsRegionInfo){
            _.forEach(rg.contentsRegionInfo , function(rginfo) {
              var find = _.find($scope.contentsInfo , function(cont) {
                return (rginfo.contentId === cont.id) && (playlistId === rginfo.playlistId);
              });
              if (find) {
                var cont_copy = angular.copy(find);
                cont_copy.playIndex = rginfo.playIndex;
                cont_copy.additionalInfo = JSON.parse(rginfo.additionalInfo);
                cont_copy.checked = rginfo.enabled ? true : false;
                rg.contentsInfo.push(cont_copy);
                findContentInRegion = true;
              }
            });
            rg.contentsInfo = _.sortBy(rg.contentsInfo, function(ly) {
              return ly.playIndex;
            });

            if (rg.contentsInfo.length) {
              initialRegion = false;  //前に設定したことあるリージョン
            }
          }
          } else {
            if (rg.contentsInfo.length) {
              initialRegion = false;  //前に設定したことあるリージョン
            }
          }

        }
      })

      if (init && !arrUploadedMaterials_new.length/*アップロードからきたとき*/) {
        if (!findContentInRegion) {
          if ($scope.selectLayout.model.regions.length > 0) {
            if ($scope.contentsInfo) {
              if ($scope.contentsInfo.length) {
                //プレイリストにコンテンツ登録情報はあるが
                //リージョンに登録がない＝＞旧バージョンでの保存
                ///一つ目のリージョンに全部入れておく
                $scope.selectLayout.model.regions[0].contentsInfo = [];
                $scope.selectLayout.model.regions[0].contentsInfo = $scope.selectLayout.model.regions[0].contentsInfo.concat(_.cloneDeep($scope.contentsInfo));
              }
            }
          }
        }
      }

      if (initialRegion && beforeLayout) {
        //前のを適用する
        var count_b = beforeLayout.regions.length;
        var count_a = $scope.selectLayout.model.regions.length;
        var countRg = Math.min(count_b , count_a );
        for(var i = 0 ; i < countRg; i++){
          if (beforeLayout.regions[i].contentsInfo) {
            $scope.selectLayout.model.regions[i].contentsInfo = beforeLayout.regions[i].contentsInfo;
          }
        }
      }

      //contentInfoにAddtionalInfoを適用しておく
      if ($scope.selectLayout.model.regions) {
        if ($scope.selectLayout.model.regions.length === 1) {
          _.find($scope.selectLayout.model.regions[0].contentsInfo , function(rgCont , index) {
            if (rgCont.additionalInfo) {
              $scope.contentsInfo[index].additionalInfo = rgCont.additionalInfo;
            }
          })
        }
      }

      m_initCalcRegion = true;

    }
    function do_calcPlaylistDuration(contentsInfo) {
      var duration = 0;
      var file_length = 0;
      var findVideo = _.find($scope.selectLayout.model.regions, function(rg) {
        if (rg.name.toLowerCase().indexOf("video") !== -1) {
          return true;
        }
      });
      var findStill = _.find($scope.selectLayout.model.regions, function(rg) {
        if (rg.name.toLowerCase().indexOf("still") !== -1) {
          return true;
        }
      });
      var findAll = _.find($scope.selectLayout.model.regions, function(rg) {
        if ((rg.name.toLowerCase().indexOf("all") !== -1) ||
            (rg.name.toLowerCase().indexOf("v2_custom") !== -1)) {
          return true;
        }
      });

      var checkedNum = 0;
      var contentsNum = contentsInfo ? contentsInfo.length : 0;
      _.forEach(contentsInfo , function(item){
        if (item.checked) {
          checkedNum++;
          if ( ContentUtil.isVideo(item) && (findVideo||findAll) ) {
            if (item.files.length) {
                var ContentSec = ContentUtil.getDurationSec(item.files[0].duration);
                var repeatCount = Math.ceil( $scope.playlistSetting.changeContentDuration / ContentSec ); //切り上げ
                if ($scope.playlistSetting.repeatVideoType === 'once') {
                  repeatCount = 1;
                }
                duration += repeatCount * ContentSec;
                file_length += item.files[0].length;
            }
          } else if ( ContentUtil.isExInput(item) && (findAll)) {
//            if (contentsInfo.length !== 1) {
              duration += Number($scope.playlistSetting.changeContentDuration);
//            }
          } else if ( ContentUtil.isText(item) && (findAll)) {
            if (item.additionalInfo.flow) {
              if (item.additionalInfo.flowSpeed) {
                duration += Number(item.additionalInfo.flowSpeed);
              } else {
                duration += Number($scope.playlistSetting.changeContentDuration);
              }
            } else {
              duration += Number($scope.playlistSetting.changeContentDuration);
            }
            file_length += item.telopInfo ? item.telopInfo.text.length : item.files[0].length
          } else if ( ContentUtil.isImage(item) && (findStill||findAll)) {
              duration += Number($scope.playlistSetting.changeContentDuration);
              file_length += item.files.length ? item.files[0].length : 0;
          } else if ( ContentUtil.isHTML(item) && findAll) {
            if (item.urlContents[0].additionalInfo.info) {
              if (item.urlContents[0].additionalInfo.info.duration) {
                var ContentSec = ContentUtil.getDurationSec(item.urlContents[0].additionalInfo.info.duration);
                var repeatCount = Math.ceil( $scope.playlistSetting.changeContentDuration / ContentSec ); //切り上げ
                if ($scope.playlistSetting.repeatVideoType === 'once') {
                  repeatCount = 1;
                }
                duration += repeatCount * ContentSec;
              } else {
                //
                //if (contentsInfo.length !== 1) {
                  duration += Number($scope.playlistSetting.changeContentDuration);
                //}
              }
            }
          }
        }
      });

      return {duration:duration, file_length:file_length , checkedNum : checkedNum , contentsNum : contentsNum};
    }

    $scope.getInfomation = function() {
      var arrStr = [];
      _.forEach($scope.playlistUIInfo.regions , function(rg){
        var str = "<b>[リージョン" + rg.num + "] </b>";
        //str += "　";
        str += "コンテンツ  : " + rg.contentsNum + " / " + $scope.playlistUIInfo.playlistContentCount;
        str += "<br>";
        str += "長さ : " + rg.durationAllText;
        str += "　";
        str += "サイズ  : " + $filter('limaDisplayBytes')(rg.file_length);
        arrStr.push(str);
      })
        return (arrStr.join("<br>"));
    }


    //プレイリストのサイズと長さ、リージョンごとのサイズと長さ
    $scope.playlistUIInfo = {};
    function calcPlaylistDuration() {
      if (_.isUndefined($scope.selectLayout)) {
        $timeout(function(){calcPlaylistDuration();} , 500)
        return;
      }
      if (_.isUndefined($scope.selectLayout.model)) {
        $timeout(function(){calcPlaylistDuration();} , 500)
        return;
      }

      //初期化
      $scope.playlistUIInfo.playlistLengthAllText = '';
      $scope.playlistUIInfo.playlistDurationAllText = '';
      $scope.playlistUIInfo.popoverText = "";
      $scope.playlistUIInfo.playlistContentCount =  $scope.settings.playlistContentCount;
      $scope.playlistUIInfo.regions = [];
      var duration = 0;  //全体
      var file_length = 0;

      if ($scope.selectLayout.model) {
        if ($scope.selectLayout.model.regions.length) {
          if ($scope.selectLayout.model.regions.length >= 1) {
            //最大の長さを取得する
            _.forEach($scope.selectLayout.model.regions , function(rg) {
              var duration_calc = do_calcPlaylistDuration(rg.contentsInfo);
              if (duration < duration_calc.duration) {
                duration = duration_calc.duration;
              }
              file_length += duration_calc.file_length;
              $scope.playlistUIInfo.regions.push({
                regionId : rg.regionId,
                num : rg.num,
                contentsNum : duration_calc.contentsNum ? duration_calc.contentsNum  : 0,
                duration : duration_calc.duration,
                durationAllText : ContentUtil.getDurationText(duration_calc.duration,1),
                file_length : duration_calc.file_length
                })
            })
          } else {
            var duration_calc = do_calcPlaylistDuration($scope.contentsInfo);
            duration = duration_calc.duration;
            $scope.playlistUIInfo.regions.push({
              regionId : rg.regionId,
              num : rg.num,
              contentsNum : duration_calc.contentsNum ? duration_calc.contentsNum  : 0,
              duration : duration_calc.duration,
              durationAllText : ContentUtil.getDurationText(duration_calc.duration,1),
              file_length : duration_calc.file_length
            })
          }
        }
      }


      $timeout(function(){
        $scope.playlistUIInfo.popoverText = $scope.getInfomation();
        $scope.playlistUIInfo.playlistDurationAllText = ContentUtil.getDurationText(duration,1);
        if (!file_length) {
          file_length = 0;
        }
        $scope.playlistUIInfo.playlistLengthAllText = "" + $filter('limaDisplayBytes')(file_length);
      });

    }
    $scope.calcPlaylistDurationFunc = function() {
      calcPlaylistDuration()
    }

    $scope.getFileSize = function(item){
      if (item.files && item.files.length ) {
        if (item.files[0].videoStreams) {
          if (item.files[0].videoStreams.length) {
            return $filter('limaDisplayBytes')(item.files[0].length);
          }
        }
      }
      return '';
    }
    $scope.getFileSizeNumber = function(item){
      if (item.files && item.files.length ) {
        if (item.files[0].videoStreams) {
          if (item.files[0].videoStreams.length) {
            return item.files[0].length;
          }
        }
      }
      return 0;
    }

  //サイズ取得
  function do_calcFileSize(contentsInfo) {
    var checkedNum = 0;
    var contentsNum = contentsInfo ? contentsInfo.length : 0;
    var file_length = 0;
    _.forEach(contentsInfo , function(item){
      if (item.checked) {
        checkedNum++;
        if ( ContentUtil.isVideo(item) /*&& (findVideo||findAll)*/ ) {
          if (item.files.length) {
              var ContentSec = ContentUtil.getDurationSec(item.files[0].duration);
              // var repeatCount = Math.ceil( $scope.playlistSetting.changeContentDuration / ContentSec ); //切り上げ
              // if ($scope.playlistSetting.repeatVideoType === 'once') {
              //   repeatCount = 1;
              // }
              // duration += repeatCount * ContentSec;
              file_length += item.files[0].length;
          }
        } else if ( ContentUtil.isExInput(item)) {
//            if (contentsInfo.length !== 1) {
            //duration += Number($scope.playlistSetting.changeContentDuration);
//            }
        } else if ( ContentUtil.isText(item)) {
          file_length += item.telopInfo ? item.telopInfo.text.length : item.files[0].length
        } else if ( ContentUtil.isImage(item)) {
            file_length += item.files.length ? item.files[0].length : 0;
        } else if ( ContentUtil.isHTML(item) ) {
        }
      }
    });

    return {file_length:file_length , checkedNum : checkedNum , contentsNum : contentsNum};
  }

  $scope.getCheckContents = function() {
    return  _.filter($scope.showContTypeInfo.contents , function(cont){
      return $scope.isEnableDeleteContent(cont) && cont.checked;
    })

  }

  $scope.updateFileSize = function(){
    var allContents = _.cloneDeep($scope.showContTypeInfo.contents);
    _.forEach(allContents , function(cc){
      cc.checked = true;
    })
    var obj_all = do_calcFileSize(allContents);

    var obj = do_calcFileSize($scope.showContTypeInfo.contents);
    $scope.showContTypeInfo.checkedFileLen = obj.file_length;
    if (!obj.file_length && $scope.getCheckContents().length > 0) {
      obj.file_length = 0;  //0の時は強制的に１にしておく
    }
    if (!obj_all.file_length && allContents.length > 0) {
      obj_all.file_length = 0;  //0の時は強制的に１にしておく
    }
    allContents = [];
    var text1 =  $filter('limaDisplayBytes')(obj.file_length);
    var text2 =  $filter('limaDisplayBytes')(obj_all.file_length);

    $scope.showContTypeInfo.checkedLengthAllText = text1 + " / " + text2 + "";

  }

    //リージョンにそのコンテンツを登録できるかどうか
    $scope.isEnableInsertToRegion = function(cont, selectRg) {
      if (cont && selectRg) {
        var video = false;
        var image4K = false;
        if ($scope.isVideo(cont)) {
          video = true;
        }
        else if ($scope.isYoutube(cont)) {
          video = true;
        }
        else if ($scope.isLive(cont)) {
          video = true;
        }
        else if ($scope.isExinput(cont)) {
          video = true;
        }
        if (ContentUtil.isImage4K(cont)) {
          image4K = true;
        }
        if (!video && !image4K) {
          return {enable: true , isVideo : video , isImage4K:image4K };
        }
        var arrfindType = [];
        if (image4K) {  //4Kをいれようとした
          var find = _.find($scope.selectLayout.model.regions, function(rg) {
            if (rg.contentsInfo && rg.regionId !== selectRg.regionId) {
              var image4KInRg = false;
              var arr = _.filter(rg.contentsInfo , function(contRg) {
                var push_type = "";
                if ($scope.isImage4K(contRg)) {
                  push_type = '4K静止画';
                  image4KInRg = true;
                }
                else if ($scope.isVideo(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.VIDEO');
                  image4KInRg = true;  //4KとVideo/Live/外部入力/YouTubeは別リージョンに置けない
                }
                else if ($scope.isLive(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.LIVE');
                  image4KInRg = true;
                }
                else if ($scope.isExinput(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.EXINPUT');
                  image4KInRg = true;
                }
                else if ($scope.isYoutube(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.YOUTUBE');
                  image4KInRg = true;
                }
                if (push_type.length) {
                  var findType = _.find(arrfindType , function(type) {
                    return type === push_type;
                  });
                  if (!findType) {
                    arrfindType.push(push_type);
                  }
                }
                return image4KInRg;
              })
              return image4KInRg;
            }
          });
          if (arrfindType.length) {
            return {enable: false , isVideo : video , isImage4K:image4K , reasonContType:arrfindType.join("/") };
          }
        }
        if (video) {  //動画系をいれようとした
          _.filter($scope.selectLayout.model.regions, function(rg) {
            if (rg.contentsInfo && rg.regionId !== selectRg.regionId) {
              var videoInRg = false;
              return _.filter(rg.contentsInfo , function(contRg) {
                var push_type = "";
                if ($scope.isVideo(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.VIDEO');
                  videoInRg = true;
                }
                else if ($scope.isYoutube(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.YOUTUBE');
                  videoInRg = true;
                }
                else if ($scope.isLive(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.LIVE');
                  videoInRg = true;
                }
                else if ($scope.isExinput(contRg)) {
                  push_type = $translate.instant('CONTENT.PROPERTY.EXINPUT');
                  videoInRg = true;
                }
                else if ($scope.isImage4K(contRg)) {
                  push_type = '4K静止画';
                  videoInRg = true;
                }
                if (push_type.length) {
                  var findType = _.find(arrfindType , function(type) {
                    return type === push_type;
                  });
                  if (!findType) {
                    arrfindType.push(push_type);
                  }
                }
                return videoInRg;
              })
            }
          });
          if (arrfindType.length) {
            return {enable: false , isVideo : video , isImage4K:image4K , reasonContType:arrfindType.join("/") };
          }
        }
      }
      return {enable: true , isVideo : video , isImage4K:image4K };
    }

  //SNS
  $scope.RegistSNS = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/content/registSNS.html',
      controller: 'RegistSNSCtrl',
      windowClass: 'modal-registSNS-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaModalRegistSNSData: function() {
          return {
            //selectTweet : $scope.selectTweet
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
    }, function() {
      //
    });

  }


    $scope.doChecked = function(tw) {
      //アップロードしたものもcheck状態を適用
      _.find(arrUploadedMaterials, function(mt){
        if (mt.id === tw.id) {
          mt.checked = tw.checked;
          return true;
        }
      });

      $scope.createTemporaryPlaylistHTML();
      //長さ計算
      calcPlaylistDuration();
    }

    function checkTelop(tw) {
      var defer = $q.defer();
      if (tw.type ===4 ) {

        var filename = $location.protocol() + '://'+ $location.host() +':'+  $location.port();
        filename += tw.url;
        var httpObj = new XMLHttpRequest();
        httpObj.open('GET',filename +"?"+(new Date()).getTime(),true);
        // ?以降はキャッシュされたファイルではなく、毎回読み込むためのもの
        httpObj.send(null);
        httpObj.onreadystatechange = function(){
            if ( (httpObj.readyState == 4) && (httpObj.status == 200) ){
  //            var value = _.escape(httpObj.responseText.replace(/\r\n/g, '<br/>').replace(/[\r\n]/g, '<br/>'));
              var value = httpObj.responseText.replace(/\r\n/g, '<br/>').replace(/[\r\n]/g, '<br/>');
              value =httpObj.responseText;
              _.find($scope.contentsInfo,function(info){
                if (info.id === tw.id) {
                  info.scrollText = value;
                  //info.scrollText = value;
                  return true;
                }
              });
              defer.resolve();
            }
        }
      } else {
        defer.resolve();
      }
      return defer.promise;
    }


    $scope.selectTweet = {};
    $scope.selectPreview = function(tw) {
      $scope.bPreviewPaused = true;

      $scope.selectTweet = tw;

      var promise = checkTelop($scope.selectTweet);
      promise.then(
        function() { //成功
          $http.get('/api/v1/content/contents/' + tw.id)
          .success(function(resMat) {
            if (resMat.files ) {
              if (resMat.files.length ) {
                tw.files = resMat.files;
              }
            }

            var modalInstance = $modal.open({
            templateUrl: 'app/content/modalPreview.html',
            controller: 'ModalMaterialPreviewCtrl',
            windowClass: 'modal-preview-window',
            backdrop: 'static', keyboard: false,
            size: 'md',
            resolve: {
              LimaModalPreviewData: function() {
                return {
                  selectTweet : $scope.selectTweet
                };
              }
            }
            });
            modalInstance.result.then(function(results) {
            }, function() {
              //
              $scope.bPreviewPaused = false;

            });
          });
        },
        function() { //失敗
          //なにもしない
          $scope.bPreviewPaused = false;
        }
      );
    }

    //リージョンに登録される同じコンテンツもサムネイル変更する
    $scope.setThumURL = function(contentId , enableLoading , thumbUrl){
      _.filter($scope.showContTypeInfo.contents , function(cont) {
        if (cont.id === contentId) {
          cont.thumb_intializing = false;
          if (thumbUrl) {
            if (cont.thumbnailUrl !== thumbUrl) {
              console.log("##change==>" , cont.thumbnailUrl);
              cont.thumbnailUrl = thumbUrl;
            }
          }
          console.log("##change2==>" , cont.id);
          if (enableLoading) {
            cont.thumb_loading = true;
          } else {
            cont.thumb_loadedTime = new Date().getTime();
            cont.thumb_loading = false;
          }
          return true;
        }
      })

      if ($scope.selectLayout) {
        if ($scope.selectLayout.model) {
          _.forEach($scope.selectLayout.model.regions , function(rg) {
            _.forEach(rg.contentsInfo , function(cont) {
              cont.thumb_intializing = false;
              if (cont.id === contentId) {
                if (thumbUrl) {
                  if (cont.thumbnailUrl !== thumbUrl) {
                    console.log("##change1-2==>" , cont.thumbnailUrl);
                    cont.thumbnailUrl = thumbUrl;
                  }
                }
                console.log("##change3==>" , cont.id);
                if (enableLoading) {
                  cont.thumb_loading = true;
                } else {
                  cont.thumb_loadedTime = new Date().getTime();
                  cont.thumb_loading = false;
                }
                return true;
              }
            })
          })
        }
      }
    }

    $scope.m_arrUpdatingThumbContents = [];
    $scope.updateThumbnail = function(tw) {

      //リージョンに登録される同じコンテンツもサムネイル変更する
      $timeout(function(){
        $scope.setThumURL(tw.id , true);
      });
      console.log("emit thumbnail:update");

      var find = _.find($scope.m_arrUpdatingThumbContents, function(contId){
        return contId === tw.id;
      });
      if (find) {
        //処理中
        console.log("not emit thumbnail:update : same id");
        return;
      }
      console.log("########emit thumbnail:update : " , tw.id);

      $scope.m_arrUpdatingThumbContents.push(tw.id);
      socket.emit('lima:call-request:v1:contents:thumbnail:update',{contentId:tw.id,time:2000,companyId:tw.companyId,wait:true});

    }

    $scope.copyContent = function(tw) {
      var indexContent = -1;
      _.forEach($scope.contentsInfo, function(cont, index){
        if (_.isEqual(cont, tw) === true) {
            indexContent = index;
        }
      });
      $scope.contentsInfo.splice( indexContent+1, 0, angular.copy(tw) ) ; //$$hashkey削除するためのコピー方法

      calcPlaylistDuration();
      $timeout(function(){$scope.$apply()});

    }

    //親関数
    $scope.getActiveRegion = function() {
      return null;
    }

    //レイアウト情報に紐づけてあるコンテンツの情報をObjectから外したものを返す
    $scope.getLayoutBaseInfo = function(layout) {
      var obj = _.cloneDeep(layout);
      _.forEach(obj.regions , function(rg) {
        delete rg.contentsInfo;
        delete rg.contentsRegionInfo;
      })
      return obj;
    }


    $scope.getHTMLThumbUrl = function(cont) {
      if (cont.thumb_loading || cont.thumb_intializing) {
        return './resources/images/loading_200.gif';
      }
      if (!cont.thumb_loadedTime) {
        cont.thumb_loadedTime = new Date().getTime();;
      }
      return cont.thumbnailUrl + "?dum=" + cont.thumb_loadedTime;

    }

    function updatedThumbnail(cont_thumb) {
      var thumbUrl = cont_thumb.thumbnailUrl;
      var arrSplit = thumbUrl.split("?");
      if (arrSplit.length > 1) {
        thumbUrl= arrSplit[0];
      }

      var arrCont = [];

      _.filter($scope.showContTypeInfo.contents , function(cont) {
        if (cont.id === cont_thumb.id) {
          arrCont.push(cont);
          return true;
        }
      })

      if ($scope.selectLayout) {
        if ($scope.selectLayout.model) {
          _.forEach($scope.selectLayout.model.regions , function(rg) {
            _.forEach(rg.contentsInfo , function(cont) {
              if (cont.id === cont_thumb.id) {
                arrCont.push(cont);
                return true;
              }
            })
          })
        }
      }

      $timeout(function() {
        _.forEach(arrCont , function(cont) {
          cont.thumb_loading = true;
        });
      });
      $timeout(function() {
        _.forEach(arrCont , function(cont) {
          cont.thumb_loadedTime = new Date().getTime();
          cont.thumb_loading = false;
        });
      },1000)
    }


    $scope.selectProperty = function(tw,isRegion) {
      $scope.selectTweet = tw;

      var bModalSetting = false;
      var activeRegion = null;
      var layoutInfo = {};
      if ($scope.isPlaylistModal) {
        var activeRgId = $scope.getActiveRegion().regionId;
        layoutInfo = $scope.getLayoutBaseInfo($scope.selectLayout.model);
        activeRegion = _.find(layoutInfo.regions , function(rg) {
          return rg.regionId === activeRgId;
        })
      }
      if (!isRegion) {
        activeRegion = null;
      }
//       if ( !_.isUndefined(isRegion)) {
// //        if (!isRegion && $scope.isText(tw)) {
//         if (!isRegion) {
//             $scope.selectPreview(tw);
//           return;
//         }
//       }
      if (bModalSetting){
        if ($scope.isText(tw)){
          $scope.modalContentSetting(tw);
          return;
        }
      }

      $scope.bPreviewPaused = true;


      /**/
      $http.get('/api/v1/content/contents/' + tw.id)
      .success(function(resMat) {
        if (resMat.files ) {
          if (resMat.files.length ) {
            tw.files = resMat.files;
          }
        }
        //登録済みのコンテンツ欄
        if (!activeRegion) {
          if ($scope.isText(tw)) {
            tw.additionalInfo = resMat.additionalInfo;  //DBから取ってきたもの
          }
        }

        var modalInstance = $modal.open({
          templateUrl: 'app/content/modalProperty.html',
          controller: 'ModalMaterialPropertyCtrl',
          windowClass: 'modal-property-window',
          backdrop: 'static', keyboard: false,
          size: ($scope.isLive($scope.selectTweet) || ($scope.isExinput($scope.selectTweet)) ) ? 'md' : 'lg',
          resolve: {
            LimaModalPropertyData: function() {
              return {
                selectTweet : $scope.selectTweet,
                region:activeRegion,
                playlistSetting: _.cloneDeep($scope.playlistSetting),
                layout:layoutInfo,
                checkFunc : $scope.isEnableInsertToRegion
              };
            }
          }
        });
        modalInstance.result.then(function(results) {
          $scope.bPreviewPaused = false;

          if (results.event === 'updated') {
            //中身が変わった。更新
            $scope.selectTweet.additionalInfo = results.content.additionalInfo;
            //長さ計算
            $scope.calcPlaylistDurationFunc();
            $scope.createTemporaryPlaylistHTML();
          } else if (results.event === 'thumnailUpdated') {
            updatedThumbnail($scope.selectTweet);
          } else {
            if ($scope.isPlaylistModal) {
              $scope.createTemporaryPlaylistHTML();
            }
          }


        }, function(results) {
          //
          $scope.bPreviewPaused = false;
          if (results.event === 'updated') {
            //中身が変わった。更新
            $scope.selectTweet.additionalInfo = results.content.additionalInfo;
            //長さ計算
            $scope.calcPlaylistDurationFunc();
            $scope.createTemporaryPlaylistHTML();
          } else if (results.event === 'thumnailUpdated') {
            updatedThumbnail($scope.selectTweet);
          } else {
            if ($scope.isPlaylistModal) {
              $scope.createTemporaryPlaylistHTML();
            }
          }
        });

      })
      /**/
    }

    function getFixedRegion() {
      if ($scope.selectLayout.model) {
        return _.find($scope.selectLayout.model.regions, function(rg){
          return rg.fixed;
        });
      }
      return null;
    }
    //固定コンテンツとする
    $scope.setFixedRegion = function(content) {
      var findRg = getFixedRegion();

      //これまで設定されてたものを設定削除
      _.find($scope.contentsInfo,function(cont) {
        if (cont.fixedRegionId === findRg.regionId) {
          cont.fixedRegionId = null;
          return true;
        }
      });

      //設定
      content.fixedRegionId = findRg ? findRg.regionId : null;

      //プレビュー更新
      $scope.createTemporaryPlaylistHTML();
    }
    $scope.isIncludeFixedLayout =function(){
      var findRg = getFixedRegion();
      return findRg;
    }


  //////////////////////////
  $scope.selectLayout = {};
  $scope.selectLayout.model = undefined;

  $scope.channels = [];
  $http.get('/api/v1/channels').
  success(function(datas) {
    $scope.channels = datas.channels;
  });

  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = [];
  function getChannels() {
    $scope.channelsInfo.channels = [];
    $http.get('/api/v1/channels').
    success(function(datas) {
      $scope.channelsInfo.channels = datas.channels;
    });

  }
  getChannels();

  $scope.applyOption={};
  $scope.applyOption.options=[];
  $scope.applyOption.options.push({name:$translate.instant('PLAYLIST.TABLE.DEVICE') , type:'device'});
  $scope.applyOption.options.push({name:$translate.instant('DEVICE.GROUP') , type:'group'});
  $scope.applyOption.select = $scope.applyOption.options[0];

  function getDevice() {
    $scope.devices = [];
    $http.get('/api/v1/device/device').
    success(function(datas) {
      $scope.devices.items = _.filter(datas, function(dt){
        return dt.allowed;
      });
      getDeviceGroups(function() {
        if ($scope.devices.items.length) {
          _.find($scope.deviceGroups.groups, function(gr){
            if (gr.typeDefault) {
              var groupInfo = angular.copy(gr);
              groupInfo.enableGroup = false;
              var all = {
                name : $translate.instant('PLAYLIST.DEVICE_ALL'),
                groupIds: [groupInfo],
                deviceId: -1
              };
              $scope.devices.items.unshift(all);
              gr.name = $translate.instant('PLAYLIST.DEVICE_ALL');
              return true;
            }
          });

        }
      });
    });
  }
  getDevice();

  $scope.deviceGroups = [];
  function getDeviceGroups(callback) {
    $scope.deviceGroups = [];
    $http.get('/api/v1/device/groups').
    success(function(datas) {
      $scope.deviceGroups.groups = datas;
      $scope.deviceGroups.showgroups = _.filter(datas, function(dt){
        if (!dt.typeDefault) {
          return true;
        }
      });
      if ($scope.deviceGroups.showgroups.length){
        $scope.deviceGroups.select = $scope.deviceGroups.showgroups[0];
      }
      callback();
    });

  }

  function getLayout(optionService) {
    $scope.layout = [];

    $http.get('/api/v1/layouts').
    success(function(datas) {
  //    $scope.layout = datas.layouts;

      datas.layouts = _.filter(datas.layouts, function(ly) {
        var packageType = ly.packageType ? ly.packageType : 0;
        return $scope.packageType === packageType;
      });


      var arrOrigin = _.filter(datas.layouts , function(ly){
        return !ly.custom;
      })
      arrOrigin = _.sortBy(arrOrigin, function(ly) {
        return new Date(ly.updatedTime);
      });

      var arrCustom = _.filter(datas.layouts , function(ly){
        return ly.custom;
      })
      arrCustom = _.sortBy(arrCustom, function(ly) {
        return new Date(ly.updatedTime);
      }).reverse();


      datas.layouts = [];
      datas.layouts = datas.layouts.concat(arrOrigin);
      datas.layouts = datas.layouts.concat(arrCustom);


      $scope.layout = _.filter(datas.layouts,function(ly){
        var find = _.find( optionService, function(info){
          //return ly.optionId == info.optionId;
          if (ly.optionId == info.optionId) {
            return true;
          }
          var companyId_option = ('00000000' + info.companyId).slice(-8);  //8桁で0埋め
          var companyId_layout = ('00000000' +   ly.companyId).slice(-8);  //8桁で0埋め
          if (companyId_option === companyId_layout) {
            return true;
          }
        });
        return find ? true : false;
      });

      var find = _.find($scope.layout, function(ly){
        if ($scope.playlistInfo) {
          if (ly.layoutId === $scope.playlistInfo.layoutId) {return true;}
        }
      })
      if (find) {
        $scope.selectLayout.model = find;
      } else {
        $scope.selectLayout.model = $scope.layout[0];
      }
      $scope.selectLayoutIndex = $scope.selectLayout.model.layoutId;
      $scope.selectChange();
    });
  }

  $scope.isImportUrlEnable = false;
  $scope.isDeviceGroupEnable = false;
  $scope.isMovieEnable = false;
  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.isDeviceGroupEnable = true;
    }
    find = _.find(result, function(op){
      return op.name === 'Url' ? true : false;
    });
    if (find) {
      $scope.isImportUrlEnable = true;
    }

    find = _.find(result, function(op){
      return op.name === 'Movie' ? true : false;
    });
    if (find) {
      $scope.isMovieEnable = true;
    }

    find = _.find(result, function(op){
      return op.name === 'Still_and_Video' ? true : false;
    });
    if (find) {
      $scope.isMovieEnable = true;
    }


    getLayout(result);

  });

  $scope.packageType = 0;
  limaOptionService.getpackageType(function(result){
    if (result) {
      $scope.packageType = result.packageType;
    }
  });


  $scope.regions = [];
  $http.get('/api/v1/regions').
  success(function(datas) {
    $scope.regions = datas.regions;
    $scope.selectChange();
  });

  function getRegion(layoutID) {
    $scope.selectLayout.model.regions = _.filter($scope.regions, function(region){
      if (region.layoutId === layoutID) {
        return true;
      }
    });

    _.forEach($scope.selectLayout.model.regions , function(rg) {
      var name = rg.name;
      if (name.indexOf("all") !== -1) {
        rg.num = Number(name.replace("all" , ""));
      }
      else if (name.indexOf("v2_custom") !== -1) {
        rg.num = Number(name.replace("v2_custom" , ""));
      }
    });

    $scope.selectLayout.model.regions = _.sortBy($scope.selectLayout.model.regions , function(rg) {
      return rg.num;
    });
   }

  $scope.SelectedRegion = undefined;
  $scope.selectRegion = function(region){
    $scope.SelectedRegion = region;
    $scope.SelectedRegion.rect.fill = "#0F0";
    _.find($scope.selectLayout.model.regions, function(rg,index){
      if (rg.regionId === region.regionId) {
        canvas.bringToFront(canvas.item(index));
        //canvas.setActiveObject(canvas.item(index));
        return true;
      }
    });

  }
  $scope.isActiveList = function(regionId){
    if ($scope.SelectedRegion) {
      return $scope.SelectedRegion.regionId === regionId;
    }
    return false;
  }

  $scope.setRegionTab = () => {
    var rgCnt = $scope.selectLayout.model.regions.length;
    _.forEach($scope.selectLayout.model.regions, function(region,index){
      var sName = "";
      if ((region.name.toLowerCase().indexOf('all') !== -1) || (region.name.toLowerCase().indexOf('v2_custom') !== -1) ) {
        if ($scope.packageType !== 0) {
          sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO') + '/' + $translate.instant('CONTENT.PROPERTY.URL');
        } else if ($scope.isMovieEnable){
          sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
        }
        if (rgCnt > 1) {
          sName = region.name;
          sName = sName.replace("all" , "リージョン");
          sName = sName.replace("v2_custom" , "リージョン");
        }
      }
      else if (region.name.toLowerCase().indexOf('still_video') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
      }
      else if (region.name.toLowerCase().indexOf('still4k') !== -1) {
        sName = '4K' + $translate.instant('CONTENT.PROPERTY.STILL');
      }
      else if (region.name.toLowerCase().indexOf('still') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.STILL');
      }
      else if (region.name.toLowerCase().indexOf('video') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.VIDEO');
      }
      else if (region.name.toLowerCase().indexOf('input') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.EXINPUT');
      }
      else if (region.name.toLowerCase().indexOf('html') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.URL');
      }
      if (region.fixed) {
        sName = '固定';// + sName;　
      }
    });
  }

  $scope.RegionRect = [];
  $scope.canvasDraw = () => {
    //ディープコピーを作成(zIndexでソートするため、リージョン番号が入れ替わってしまう。順番通りにならなくなる) リージョンタブの順番がバラバラになる
    const copyRegions = Object.assign([], $scope.selectLayout.model.regions)
    //zIndex順に並び替える
    const sortRegions = copyRegions.sort((a, b) => a.zIndex - b.zIndex);

    const rgCnt = $scope.selectLayout.model.regions.length;

    //必要な情報を変数に入れる
    const rotate = $scope.selectLayout.model.rotate;


    //layoutWidth = 1920, layoutHeight = 1080固定だが、念のため取っておく
    const layoutWidth = $scope.selectLayout.model.width;
    const layoutHeight = $scope.selectLayout.model.height;

    const canvas = document.getElementById("canvasLayout");

    if (!canvas) {
      return;
    }
    if (canvas.style) {
      canvas.style.border = '1px solid #2e2e2e'
    }
    const ctx = canvas.getContext("2d");

    if(rotate === 0){
        canvas.width = layoutWidth;
        canvas.height = layoutHeight;
        ctx.clearRect(0, 0, layoutWidth, layoutHeight);
    }else{
        canvas.width = layoutHeight;
        canvas.height = layoutWidth;
        ctx.clearRect(0, 0, layoutHeight, layoutWidth);
    }


    const adjustRotateRegion = (rotate, left, top, width, height) => {
        if(rotate === 0){
            return{
                left: left,
                top: top,
                width: width,
                height: height
            }
        }else if(rotate === 90){
            return{
                left: canvas.width - (top + height),
                top: left,
                width: height,
                height: width
            }
        }else if(rotate === 270){
            return{
                left: top,
                top: canvas.height - (left + width),
                width: height,
                height: width
            }
        }
    }

    for(const region of sortRegions){

        let sName = "";
        if ((region.name.toLowerCase().indexOf('all') !== -1) || (region.name.toLowerCase().indexOf('v2_custom') !== -1) ) {
        if ($scope.packageType !== 0) {
            sName = 'すべてのコンテンツ';
        } else if ($scope.isMovieEnable){
            sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
        }
        if (rgCnt > 1) {
            sName = region.name;
            sName = sName.replace("all" , "リージョン");
            sName = sName.replace("v2_custom" , "リージョン");
        }
        }
        else if (region.name.toLowerCase().indexOf('still_video') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
        }
        else if (region.name.toLowerCase().indexOf('still4k') !== -1) {
        sName = '4K' + $translate.instant('CONTENT.PROPERTY.STILL') + ' (JPEG)';
        }
        else if (region.name.toLowerCase().indexOf('still') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.STILL');
        }
        else if (region.name.toLowerCase().indexOf('video') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.VIDEO');
        }
        else if (region.name.toLowerCase().indexOf('input') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.EXINPUT');
        }
        else if (region.name.toLowerCase().indexOf('html') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.URL');
        }
        if (region.fixed) {
        sName = '固定';// + sName;　
        }

        //adjustRegion
        const ar = adjustRotateRegion(rotate, region.leftIndex, region.topIndex, region.width, region.height);
        console.log(sName)

        if(rgCnt === 1 || $scope.activeRegionTabIndex === region.regionId){
            ctx.fillStyle = "rgba(255, 130, 80, 0.8)"
            ctx.fillRect(ar.left, ar.top, ar.width, ar.height);

            ctx.strokeStyle = "#e95420"
            ctx.lineWidth = 15;
            ctx.strokeRect(ar.left, ar.top, ar.width, ar.height);
        }else{
            ctx.fillStyle = "#e9e9e9"
            ctx.fillRect(ar.left, ar.top, ar.width, ar.height);

            ctx.strokeStyle = "#000000"
            ctx.lineWidth = 15;
            ctx.strokeRect(ar.left, ar.top, ar.width, ar.height);
        }

        const fontsize = 200;
        ctx.font = fontsize + 'px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center'
        ctx.fillStyle = "#787878"
        if((ar.width === 1920 || ar.width === 1080) && (ar.height === 1920 || ar.height === 1080) && rgCnt === 1){
            ctx.fillText(sName, ar.width / 2, ar.top + (ar.height / 2), ar.width)
        }
    }
  }

  // $scope.canvasDraw =function(){

  //   var c=document.getElementById("canvasLayout");
  //   if (!c) {
  //     return;
  //   }
  //   var ctx=c.getContext("2d");

  //   var arrRg = [];
  //   var w = $scope.selectLayout.model.width;
  //   var h = $scope.selectLayout.model.height;
  //   var canvas_w = 1920;
  //   var canvas_h = 1080;
  //   var diff_w = 0;
  //   var diff_h = 0;
  //   var scale = 1;
  //   if (!($scope.selectLayout.model.rotate % 180) /* || $scope.selectLayout.model.regions.length === 1 */) {
  //     //w : h = 1920 : yy
  //     canvas_h = canvas_w * h / w;
  //     diff_h = (canvas_h - canvas_h) / 2;
  //   } else {
  //     canvas_w = canvas_h * w / h;
  //     diff_w = (canvas_w - canvas_w) / 2;

  //     canvas_w = 1080;
  //     var h2 =  Math.floor(canvas_w / 16 * 9);
  //     scale = h2 / canvas_h;
  //     canvas_h = h2;
  //   }
  //   ctx.clearRect(0,0,1920,1080);
  //   var rgCnt = $scope.selectLayout.model.regions.length;

  //   //ディープコピーを作成(zIndexでソートするため、リージョン番号が入れ替わってしまう。順番通りにならなくなる)
  //   const copyRegions = Object.assign([], $scope.selectLayout.model.regions)
  //   //zIndex順に並び替える
  //   const sortRegions = copyRegions.sort((a, b) => a.zIndex - b.zIndex);

  //   //////////////////////////////////////リージョンメニューの配置//////////////////////////////////////
  //   _.forEach($scope.selectLayout.model.regions, function(region,index){
  //     var sName = "";
  //     if ((region.name.toLowerCase().indexOf('all') !== -1) || (region.name.toLowerCase().indexOf('v2_custom') !== -1) ) {
  //       if ($scope.packageType !== 0) {
  //         sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO') + '/' + $translate.instant('CONTENT.PROPERTY.URL');
  //       } else if ($scope.isMovieEnable){
  //         sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
  //       }
  //       if (rgCnt > 1) {
  //         sName = region.name;
  //         sName = sName.replace("all" , "リージョン");
  //         sName = sName.replace("v2_custom" , "リージョン");
  //       }
  //     }
  //     else if (region.name.toLowerCase().indexOf('still_video') !== -1) {
  //       sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
  //     }
  //     else if (region.name.toLowerCase().indexOf('still4k') !== -1) {
  //       sName = '4K' + $translate.instant('CONTENT.PROPERTY.STILL');
  //     }
  //     else if (region.name.toLowerCase().indexOf('still') !== -1) {
  //       sName = $translate.instant('CONTENT.PROPERTY.STILL');
  //     }
  //     else if (region.name.toLowerCase().indexOf('video') !== -1) {
  //       sName = $translate.instant('CONTENT.PROPERTY.VIDEO');
  //     }
  //     else if (region.name.toLowerCase().indexOf('input') !== -1) {
  //       sName = $translate.instant('CONTENT.PROPERTY.EXINPUT');
  //     }
  //     else if (region.name.toLowerCase().indexOf('html') !== -1) {
  //       sName = $translate.instant('CONTENT.PROPERTY.URL');
  //     }
  //     if (region.fixed) {
  //       sName = '固定';// + sName;　
  //     }
  //   });
  //   ////////////////////////////////////////////////////////////////////////////

  //   //////////////////////////////////////レイアウトCanvasの描画//////////////////////////////////////
  //   _.forEach(sortRegions, function(region,index){
  //     var rect = {
  //       left: region.leftIndex * scale,
  //       top: region.topIndex * scale,
  //       width: region.width * scale,
  //       height: region.height * scale
  //     };

  //     var sName = "";

  //     if (rgCnt > 1 && $scope.activeRegionTabIndex === region.regionId || rgCnt === 1) {
  //       //ctx.fillStyle = '#bbbbff';
  //       ctx.fillStyle = "rgba(255, 130, 80, 0.8)"
  //       ctx.fillRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

  //       // ctx.fillStyle = "#bbbbff"
  //       // ctx.strokeStyle = "#bbbbff"
  //       ctx.fillStyle = "#e95420"
  //       ctx.strokeStyle = "#e95420"
  //       ctx.lineWidth = 8;
  //       ctx.strokeRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);


  //     } else {
  //       ctx.fillStyle = '#e9e9e9';
  //       ctx.fillRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

  //       ctx.fillStyle = "#F9F9F9"
  //       ctx.strokeStyle = "#000000"
  //       ctx.lineWidth = 8;
  //       ctx.strokeRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

  //     }

  //     var fontsize = Math.floor(100 * scale);

  //     ctx.font=fontsize + 'px "Helvetica Neue", Helvetica, Arial, sans-serif';
  //     var textWidth = ctx.measureText( sName ).width ;
  //     var textHeight =  ctx.measureText( sName ).actualBoundingBoxAscent + ctx.measureText( sName ).actualBoundingBoxDescent;;
  //     ctx.fillStyle = "#787878"
  //     if (rgCnt === 1) {
  //       if (rect.width === 1920) {
  //         ctx.fillText( sName,rect.left + (rect.width - textWidth) / 2,  diff_h + rect.top + (rect.height - textHeight ) / 2  ) ;
  //       }

  //     } else {
  //       if (rect.width === 1920) {
  //         ctx.fillText( sName,rect.left + 100,  diff_h + rect.top + (rect.height - textHeight )  ) ;
  //       } else {
  //         ctx.fillText( sName,rect.left + (rect.width - textWidth) / 2 ,  diff_h+ rect.top + (rect.height + textHeight ) / 2  ) ;
  //       }
  //     }
  //   });
  //   ////////////////////////////////////////////////////////////////////////////
  // }

  $scope.applyContent=[];

  $scope.bNotIncludeContentLayout = false;

  $scope.selectChange = function() {
    if ($scope.isModal) {
      return;
    }
    if (_.isUndefined($scope.selectLayout)) {
      return;
    }
    if (_.isUndefined($scope.selectLayout.model)) {
      return;
    }
    var beforeLayout = _.find($scope.layout , function(ly) {
      return ly.layoutId === $scope.selectLayoutIndex;
    })

    $scope.applyContent=[];
    $scope.selectArea = {};
    //$scope.selectLayout = layout;
    $scope.selectLayoutIndex = $scope.selectLayout.model.layoutId;
    getRegion($scope.selectLayout.model.layoutId);
    calcRegion($scope.playlistId , true , beforeLayout);


    if ($scope.selectLayout.model.regions) {
      if ($scope.selectLayout.model.regions.length) {
        $scope.activeRegionTabIndex = $scope.selectLayout.model.regions[0].regionId;
      }
    }


    $scope.titleAlertMessage = '';
    //外部入力のみのレイアウトか
    $scope.bNotIncludeContentLayout = false;
    if ($scope.selectLayout.model.regions.length == 1) {
      if ($scope.selectLayout.model.regions[0].name.toLowerCase().indexOf("input") !== -1) {
        $scope.bNotIncludeContentLayout = true;
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_CONTENT');
        //return true;
      }
    }
    if ($scope.selectLayout.model.rotate % 180  !== 0) {
      //縦
      $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_CORRECT_VIDEO');
    }

    $timeout(function(){
      if (_.isFunction($scope.deleteSortable_LR)) {
      //D&D用のクラスを再作成
      $scope.deleteSortable_LR(true , true);
      $scope.Do_createSortable_LR();
      }


      $scope.canvasDraw();
      $scope.setRegionTab();
      $scope.createTemporaryPlaylistHTML();
      //長さ計算
      calcPlaylistDuration();
    });

  }

  $scope.applyToRegion = function() {
    var selectedTw = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
    if (selectedTw.length === 1) {
      var val = {
        region:$scope.SelectedRegion,
        content:selectedTw
      }
      $scope.applyContent.push(val);

      _.forEach($scope.contentsInfo, function(tw){tw.checked=false;});

    }
  }

  var formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  $scope.setRegionContentInfoForCreatePlaylist = function() {
    var resObj = {contentInfo:[]};

    if ($scope.selectLayout.model.regions) {
      resObj.regions = _.cloneDeep($scope.selectLayout.model.regions);

      //いらない情報消す
      _.forEach(resObj.regions , function(rg) {
        if (rg.contentsRegionInfo) {
          delete rg.contentsRegionInfo;
        }
        if (rg.contentsInfo) {
          delete rg.contentsInfo;
        }
        rg.contentsInfo = [];
      })

      if ($scope.selectLayout.model.regions.length > 1) {
        var cont_all = [];
        _.forEach(resObj.regions , function(rg , rg_index) {
          _.forEach($scope.selectLayout.model.regions[rg_index].contentsInfo , function(cont,index ) {
            var cont_rg = {contentId:cont.id , index: index, enabled:(findCC? true : false)};
            cont_rg.id = cont.id
            cont_rg.enabled = cont.checked;
            cont_rg.additionalInfo = cont.additionalInfo;
            rg.contentsInfo.push(cont_rg);

            if (cont_rg.enabled) {
              var findCC = _.find(cont_all , function(cc) {
                return cc.id === cont.id;
              });
              if (!findCC) {
                cont_all.push(cont);
              }
            }
          });
        })

        var index = 0;
        _.forEach($scope.contentsInfo, function(tw){
          var findCC = _.find(cont_all , function(cc) {
            return cc.id === tw.id;
          });
          var obj = {contentId:tw.id , index: index, enabled:tw.checked, fixedRegionId:null};
          resObj.contentInfo.push(obj);
          index++;
        });


      } else if ($scope.selectLayout.model.regions.length === 1) {

        var index = 0;
        _.forEach($scope.selectLayout.model.regions[0].contentsInfo, function(tw){
          var obj = {contentId:tw.id , index: index, enabled:tw.checked, fixedRegionId:null};
          if (tw.additionalInfo) {
            obj.additionalInfo = tw.additionalInfo;
          }
          resObj.contentInfo.push(obj);
          index++;
        });

        resObj.regions[0].contentsInfo = _.cloneDeep(resObj.contentInfo);

        _.forEach(resObj.regions[0].contentsInfo , function(cont) {
          cont.id = cont.contentId;
        });

      }
    } else {

    }
    return resObj;
  }

  $scope.createPlaylist = function(option , callback) {
    var name = option.name;


    var postdata = {
      playlistName:name ? name : $scope.selectLayout.model.name + "_" + formatDate(new Date() , 'YYYYMMDD_hhmmss'),
      playlistLength: 30000,  //ミリ秒
      layoutId:$scope.selectLayoutIndex,
      playlistSetting: $scope.playlistSetting,
      thumbUrl : $scope.ifarame_url
    };
    postdata.contentInfo = [];

    ////toDo layout!!!!
    var obj = $scope.setRegionContentInfoForCreatePlaylist();
    postdata.contentInfo = obj.contentInfo;
    postdata.regions = obj.regions;

    var httpParam = {
    url: '/api/v1/playlists',
    method: "",
    data: postdata
    };

    if (option.playlistId) {
      httpParam.url += '/' + option.playlistId;
      httpParam.method = 'PUT';
    } else {
      httpParam.method = 'POST';
    }
    $http(httpParam)
    .success(function(res) {
      //固定コンテンツ登録があればRegionに登録する
      var findRg = getFixedRegion();
      var playIndex = -1;
      var findFixedCnt = _.find($scope.contentsInfo, function(cnt,index){
        playIndex = index
        return cnt.fixedRegionId;
      });
      if (findRg && findFixedCnt) {
        $http.put('/api/v1/regions/' + findRg.regionId + '/' + res.id + '/' + findFixedCnt.id + '/' + playIndex , {})
        .success(function(res2) {
          callback(res.id);
        })
      } else {
        callback(res.id);
      }

    });
  }

  $scope.modalClose = function(){};

  function getFileformatName(content) {
    if (content.files) {
      if (content.files.length) {
        return content.files[0].formatName;
      }
    }
  }
  $scope.isText = function(content) {
    if (getFileformatName(content)) {
      if (getFileformatName(content).indexOf("tty") !== -1) {
        return true;
      }
    }
    return false;
  }



  $scope.updatePlaylist = function(option , callback) {

    var option1 = {
      playlistId:option.id,
      name:option.name,
      playlistAndSchedule:option.playlistAndSchedule
    };
    $scope.createPlaylist(option1,callback);
  }

  $scope.createPlaylistHTML = function(option, callback) {
    var playlistId = option.id;
    var playlistAndSchedule = option.playlistAndSchedule ? option.playlistAndSchedule: null;
    var selectedTw = _.filter($scope.contentsInfo, function(tw){return tw.checked;});

    var params = [];
    _.forEach(selectedTw, function(item){
      var data = {
        contentId: item.id
      };
      params.push(data);
    })
    var channelName = new Date();

    if (_.isUndefined( $scope.selectLayoutIndex)) {
      console.log( "$scope.selectLayoutIndex undefined" );
      return;
    }

    var postdata = {
      channel: formatDate(new Date() , 'YYYYMMDD_hhmmss'),
      layoutId: $scope.selectLayoutIndex,
      Materials: params,
      temporary: false,
      playlist: playlistId,
      name: option.name
    }
    $http.post('/api/v1/playlists/html', postdata)
    .success(function(res) {
      //チェック外す
      //_.forEach($scope.contentsInfo, function(tw){tw.checked=false;});
      //$scope.ifarame_url = $location.protocol() + '://'+ $location.host() +':'+  $location.port() + res.html;
      console.log(res.html);

      if (callback) {
        callback(playlistId);
      }

      }, function(res) {
      });

  }

  function getChannelIdFromDeviceGroupId(deviceGroupId,deviceId){
    var itemFind = _.find($scope.channelsInfo.channels, function(ch){
      if (ch.groupId === deviceGroupId) {
        return true;
      }
      // if (ch.deviceId === deviceId) {
      //   return true;
      // }
      return false;
    });
    if (itemFind) {
      return itemFind.channelId;
    }
    return null;
  }

  $scope.addToNoSchedule = function(playlistId , callback) {
    //
    var selectedLists = _.filter($scope.playlists, function(p){return p.checked;});

    if (playlistId) {
      selectedLists.push({id:playlistId});
    }
    var deviceId,deviceGroupsId;
    if ($scope.applyOption.select.type==='device') {
      var find = _.find($scope.devices.select.groupIds, function(gr){
        return !gr.enableGroup ? true : false;
      });
      if (find) {
        deviceGroupsId = find.groupId;
      }

    } else {
      deviceGroupsId = $scope.deviceGroups.select.groupId;
    }
    var channelId = getChannelIdFromDeviceGroupId(deviceGroupsId, deviceId);
    if (channelId === null) {
      console.log("getChannelIdFromDeviceGroupId == > null" + " groupId = " + $scope.deviceGroups.select.groupId);
    }

    var postdata = {};
    _.forEach(selectedLists, function(list , index) {
      postdata = {
        //ScheduleID : 0,
        playlistId : list.id,
        channelId : channelId,
        PlayIndex : index
      };

      $http.post('/api/v1/schedules/repeat' , postdata)
      .success(function(res2) {
        console.log(res2.scheduleID);
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('PLAYLIST.MESSAGE_REGIST_OK'),
        modalClass:'modal-success'});

        limaSocketService.emitChannel({channelId:channelId,groupId:deviceGroupsId}, function(err){

          if (callback) {
            callback();
          }

        });

      });
    });

  };

  //リージョンに登録されてないコンテンツはcontentsInfoから除去
  $scope.checkPlaylistBeforeSave = function(callback) {
    var arrAllCont = [];
    if ($scope.selectLayout) {
      if ($scope.selectLayout.model) {
        _.forEach($scope.selectLayout.model.regions , function(rg) {
          arrAllCont = arrAllCont.concat(rg.contentsInfo);
        });
      }
    }

    $scope.deleteContetnList = []; //削除対象
    _.forEach($scope.uploadContetnList , function(cont) {
      var find = _.find(arrAllCont , function(rgCont){
        return rgCont.id === cont.id;
      })
      if (!find) {
        if ($scope.isAutoUpdated(cont)) {

        }
        else if ($scope.isExinput(cont)) {

        } else {
          $scope.deleteContetnList.push(cont);
        }
      }
    })

    if (!$scope.settings.leaveContent) { //コンテンツ残さないモード：すべて消す
      callback();
      return;
    }

    if ($scope.deleteContetnList.length) {
      //アップロードされたけど使われなかったコンテンツがある
      var message = "使われていないコンテンツが存在します。システムから削除しますか？<br><br>";
      var arrName = [];
      _.forEach($scope.deleteContetnList , function(cont){
        arrName.push("<li style='word-break: break-all;'>" + cont.title + "</li>");
      })
      message += arrName.join("")
      message += "<br><b>削除しない場合は、プレイリスト画面の「登録済みコンテンツ」にて管理を行ってください。</b>";


      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: message,//$translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger',
        trustHtml : true
      }, function (yes) {
        if (!yes) {
          $scope.deleteContetnList = []; //削除しない
        }
        callback()
      });

    } else {
      callback()
    }


  }

  //使われていないコンテンツをチェック＆削除するを選択されたら削除する＆削除中メッセージあり
  $scope.do_deleteNotUsesContent = function(callback){
    //使われていないコンテンツチェック
    $scope.checkPlaylistBeforeSave(function() {
      //削除対象があれば削除
      if ($scope.deleteContetnList.length){
        var mes1 = $scope.settings.leaveContent ? $translate.instant('CONTENT.PLAYLIST.CONTENT') : $translate.instant('NAVIGATION.PLAYLIST');
        var mes2 = $scope.settings.leaveContent ? $translate.instant('PLAYLIST.MESSAGE_DELETING') : $translate.instant('PLAYLIST.MESSAGE_CANCELING');
        limaShowWaitDialog.showWait($scope,mes1,mes2);
      }
      var promise = $scope.deleteContents($scope.deleteContetnList, function(){
        if ($scope.deleteContetnList.length){
          limaShowWaitDialog.hideWait();
        }
        callback(null);
      });
    });
  }

  $scope.m_bSaving = false;
  $scope.savePlaylist = function(option,callback) {
    $scope.m_bSaving = true;

    if (!$scope.playlistId) {
      //new
      var modalInstance = $modal.open({
        templateUrl: 'app/playlist/newName.html',
        controller: 'NewNameCtrl',
        windowClass: 'modal-rename-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          LimaNewNameData: function() {
            return {
              name : $scope.selectLayout.model.name + "_" + formatDate(new Date() , 'YYYYMMDD_hhmmss')
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        //使われていないコンテンツをチェック＆削除するを選択されたら削除する＆削除中メッセージあり
        $scope.do_deleteNotUsesContent(function() {
          var option1 = {
            name:results.name,
            playlistAndSchedule:option.playlistAndSchedule
          };
          $scope.createPlaylist(option1, function(id){
            if (callback) {
              callback(id);
            }
          });
        });
      }, function() {
        //
      });

    } else {
      //使われていないコンテンツをチェック＆削除するを選択されたら削除する＆削除中メッセージあり
      $scope.do_deleteNotUsesContent(function() {
        //update
        var option1 = {
          id:$scope.playlistId,
          name:$scope.playlistName,
          playlistAndSchedule:option.playlistAndSchedule
        };
        $scope.updatePlaylist(option1, callback);
      });


    };
  }

  //流れる速さがプレイリスト設定と同じにしているときは同じにセットする
  function checkTelopAddtionalInfo() {
    var updated = false;

    if (_.isUndefined($scope.selectLayout)) {
      return updated;
    }
    if (_.isUndefined($scope.selectLayout.model)) {
      return updated;
    }

    _.forEach($scope.selectLayout.model.regions , function(rg) {
      _.forEach(rg.contentsInfo , function(cont) {
        if ($scope.isText(cont)) {
          if (cont.additionalInfo) {
            if (cont.additionalInfo.flow) {
              if (cont.additionalInfo.flowSpeedByPlaylistSetting) {
                cont.additionalInfo.flowSpeed = $scope.playlistSetting.changeContentDuration;
                updated = true;;
              }
            }
          }
        }
      });
    });
    _.forEach($scope.contentsInfo, function(cont){
      if ($scope.isText(cont)) {
        if (cont.additionalInfo) {
          if (cont.additionalInfo.flow) {
            if (cont.additionalInfo.flowSpeedByPlaylistSetting) {
              cont.additionalInfo.flowSpeed = $scope.playlistSetting.changeContentDuration;
              updated = true;;
            }
          }
        }
      }
    });
    return updated;
  }

  $scope.DoPlaylistSetting = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/setting/setting.html',
      controller: 'PlaylistSettingCtrl',
      windowClass: 'modal-upload-window',
//      windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaPlaylistSettingData: function() {
          return {
            playlist: $scope.playlistId,
            playlistSetting: _.cloneDeep($scope.playlistSetting)
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      $scope.playlistSetting = results;

      //テロップの設定をチェックする
      var updated = checkTelopAddtionalInfo();

      //長さ計算
      calcPlaylistDuration();
      //プレビュー更新
      $scope.createTemporaryPlaylistHTML();

    }, function() {
      //閉じられた
      //  console.log("aaa");
    });
  }

  $scope.savePlaylistAndUpdate = function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('PLAYLIST.MESSAGE_SAVE_AND_APPLY_PLAYLIST'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;

      var option = {
        playlistAndSchedule:true
      };
      $scope.savePlaylist(option, function(id) {
        $scope.addToNoSchedule(id, function(){
          $scope.modalClose();
        });
      });
    });
  }

  function checkPlaylist() {
    if (_.isUndefined($scope.selectLayout)) {
      return;
    }
    if (_.isUndefined($scope.selectLayout.model)) {
      return;
    }

    //regioncontentとcontentinfoのチェック
    $scope.contentsInfo = []
    _.forEach($scope.selectLayout.model.regions, function(rg){
      $scope.contentsInfo = $scope.contentsInfo.concat(rg.contentsInfo);
    });



    var findStill = _.find($scope.selectLayout.model.regions, function(rg){
      if (rg.name.toLowerCase().indexOf('still') !== -1 ){
        if (rg.name.toLowerCase().indexOf('4k') === -1 ){
          return true;
        }
      }
    });
    var findVideo = _.find($scope.selectLayout.model.regions, function(rg){
      if (rg.name.toLowerCase().indexOf('video') !== -1 ){
        return true;
      }
    });
    var findHTML = _.find($scope.selectLayout.model.regions, function(rg){
      if (rg.name.toLowerCase().indexOf('html') !== -1 ){
        return true;
      }
    });
    if (findStill && findVideo) {
      var contHTML = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isHTML(cont);
      });
      if (contHTML) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_URL');
        return false;
      }
    }
    else if (!findStill && !findVideo) {
      if (findHTML) {
        //HTML only layout
        var contVideo = _.find($scope.contentsInfo , function(cont){
          return ContentUtil.isVideo(cont);
        });
        var contImage = _.find($scope.contentsInfo , function(cont){
          return ContentUtil.isImage(cont);
        });
        if (contVideo && contImage) {
          $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_STILL_AND_VIDEO');
          return false;
        }
        else if (contVideo) {
          $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_VIDEO');
          return false;
        }
        else if (contImage) {
          $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_STILL');
          return false;
        }
      }
    }
    else if (!findVideo) {
      //still only
      var contVideo = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isVideo(cont);
      });
      if (contVideo) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_VIDEO');
        return false;
      }
      var contHTML = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isHTML(cont);
      });
      if (contHTML) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_URL');
        return false;
      }
    }
    else if (!findStill) {
      //video only
      var contImage = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isImage(cont);
      });
      if (contImage) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_STILL');
        return false;
      }
      var contHTML = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isHTML(cont);
      });
      if (contHTML) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_URL');
        return false;
      }
    }

    return true;

  }
  $scope.bPreviewPaused = false;  //プレビューを停止させてるかどうか

  //プレビュー表示されるときに右メニューOFFにする
  $scope.$watch('bPreviewPaused', function(newValue, oldValue, scope) {
    if(!angular.equals(newValue, oldValue)) {
      if (!newValue) {
        //#14797 コンテンツの再生部分で右メニューを非表示；iframeは中まで入って禁止しないと効かない
        $timeout(function() {
          const frames = window.frames;
          // サブフレームを一つずつループする
          for (let i = 0; i < frames.length; i++) {
            // iframe内での右クリックを禁止する
            frames[i].document.oncontextmenu = function(){
              return false;
            };
          }
        },1000)
      }
    }
});

  $scope.bPreviewLoading = false;
  $scope.ifarame_url = "";
  $scope.createTemporaryPlaylistHTML = function() {
    checkPlaylist();
    var selectedTw = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
    if (_.isUndefined( $scope.selectLayoutIndex)) {
      console.log( "$scope.selectLayoutIndex undefined" );
      return;
    }
    $scope.bPreviewLoading = true;

    var postdata = {
      channel: formatDate(new Date() , 'YYYYMMDD_hhmmss'),
      layoutId: $scope.selectLayoutIndex,
      playlistSetting: $scope.playlistSetting,
      temporary: true,
      playlist: $scope.socketId ? "temp_" + $scope.socketId : "temp_" + formatDate(new Date() , 'YYYYMMDD_hhmmss')
    }

    postdata.contentInfo = [];

    ////toDo layout!!!!
    var obj = $scope.setRegionContentInfoForCreatePlaylist();
    postdata.contentInfo = obj.contentInfo;
    postdata.regions = obj.regions;



    $http.post('/api/v1/playlists/html', postdata)
    .success(function(res) {
      $timeout(function() {
        $scope.ifarame_url = res.server + res.html + "?time=" + new Date().getTime();  //URLは同じことが多いので、？で確実に別のURLにしてやる
      });
      //#14797 コンテンツの再生部分で右メニューを非表示；iframeは中まで入って禁止しないと効かない
      $timeout(function() {
        const frames = window.frames;
        // サブフレームを一つずつループする
        for (let i = 0; i < frames.length; i++) {
          // iframe内での右クリックを禁止する
          frames[i].document.oncontextmenu = function(){
            return false;
          };
        }
      },1000)
          $scope.bPreviewLoading = false;

      }, function(res) {
        //ゴミ消す
        // $http.delete('/api/v1/lima/layout/dcm/' + postdata.channel )
        // .success(function(res) {
        //   console.log("delete tempLayout");
        // });
        $scope.bPreviewLoading = false;
      });

  }

  $scope.optiontest = function() {
    $http.post('/api/v1/content/extra/option').
    success(function(datas) {
      //$scope.regions = datas.regions;
    });

  }




}]);


//ContentCtrlの子
app.controller('ContentModalCtrl', ['$scope', 'socket', 'Auth', '$http', '$localStorage', '$timeout','$location','$sce','$uibModal', 'Modal','$q', '$translate', '$uibModalInstance', 'LimaModalContentData', 'ContentUtil', '$controller',
function ($scope, socket, Auth,$http, $localStorage, $timeout,$location,$sce,$modal, Modal, $q, $translate, $modalInstance, LimaModalContentData, ContentUtil , $controller) {
  $controller('ContentCtrl', {$scope:$scope, socket:socket, Auth:Auth,$http:$http, $timeout:$timeout,$location:$location,$sce:$sce,$modal:$modal, Modal:Modal, $q:$q, $translate:$translate}); //This works

  $scope.isModal = true;
  ////////paging
  $scope.itemsPerPage = 20;
  $scope.currentPage = 1;
  //$scope.storage.currentPage = 1;

  $scope.storage = (function () {
    var key = 'limaApp:ContentModalCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};

    if (_.isUndefined(storage.thumsize)) storage.thumsize = 0;
    if (_.isUndefined(storage.bContentListEnable)) storage.bContentListEnable = 0;


    var store = function () {
      $localStorage[key] = _.cloneDeep(storage);
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();


  function checkShowContTypeInfo(){
    if (LimaModalContentData.type === "uploaded"){
      $scope.showContTypeInfo.enable = true;

      $scope.showContTypeInfo.datas = _.filter($scope.showContTypeInfo.datas, function(info){
        return info.title.length;
      });
    }
    else if (LimaModalContentData.type === "autoupdated"){
      $scope.showContTypeInfo.enable = false;

      $scope.showContTypeInfo.datas = [
        {
          type:ContentUtil.CONTENT_SHOWTYPE_NAME_AUTOUPDATED,
          id : ContentUtil.CONTENT_SHOWTYPE_AUTOUPDATED,
          title : "",
          checked : false,
          class : "glyphicon glyphicon-picture",
          checkFunc: $scope.isAutoUpdated,
        }

      ];
    }

    _.find($scope.showContTypeInfo.datas, function(info){
      if (info.type) {
        if (info.type.toLowerCase().indexOf(LimaModalContentData.type.toLowerCase()) !== -1) {
          info.checked = true;
          info.type2 = LimaModalContentData.type2;
          return true;
        }
      }
    });




  }
    //LimaModalContentDataからチェック
    checkShowContTypeInfo();

  $scope.getMaxPages = function() {
    if ($scope.contentsInfo) {
      $scope.maxPage = Math.ceil($scope.contentsInfo.length/$scope.itemsPerPage);
    } else {
      $scope.maxPage = 1;
    }
  }

  $scope.range = function() {
    var ret = [];
    for (var i=1; i<=$scope.maxPage; i++) {
      ret.push(i);
    }
    return ret;
  };
  $scope.setPage = function(n) {
    $scope.currentPage = n;
  };
  $scope.prevPageDisabled = function() {
    return $scope.currentPage === 1 ? "disabled" : "";
  };

  $scope.nextPageDisabled = function() {
    return $scope.currentPage === $scope.maxPage ? "disabled" : "";
  };
  ////////paging



  $scope.apply = function () {
    var selected = _.filter($scope.showContTypeInfo.contents, function(tw){return tw.checked;});
    $modalInstance.close(selected);
    $modalInstance.dismiss('done');
    //$dismiss();
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  }

  $scope.showContTypeInfo.loaded = false;
  ContentUtil.doSearchContent(LimaModalContentData , function(datas) {
    if (LimaModalContentData.type2) {
      if (LimaModalContentData.type2.length) {
        if (LimaModalContentData.type2[0].indexOf("line") !== -1){
          _.forEach(datas, function(dt) {
            dt.checked = true;
          })

        }
      }
    }
    $scope.showContTypeInfo.contents_all = datas;
    $scope.showContTypeInfo.contents = datas;
    $scope.setItemPerPage();
    $scope.showContTypeInfo.loaded = true;
    console.log("4241")
  });
}]);

app.controller('ContentForUserCtrl', ['$scope', 'socket', 'Auth', '$http', '$localStorage','$timeout','$location','$sce','$uibModal', 'Modal','$q', '$translate', '$filter', 'limaShowWaitDialog', 'ContentUtil','$controller',
function ($scope, socket, Auth,$http, $localStorage, $timeout,$location,$sce,$modal, Modal, $q, $translate,$filter, limaShowWaitDialog, ContentUtil, $controller) {
  $controller('ContentCtrl', {$scope:$scope, socket:socket, Auth:Auth,$http:$http, $timeout:$timeout,$location:$location,$sce:$sce,$modal:$modal, Modal:Modal, $q:$q,$translate:$translate}); //This works

  $scope.isModal = false;


  $scope.storage = (function () {
    var key = 'limaApp:ContentForUserCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};

    if (_.isUndefined(storage.thumsize)) storage.thumsize = 0;
    if (_.isUndefined(storage.bContentListEnable)) storage.bContentListEnable = 0;
    if (_.isUndefined(storage.bContentUsed)) storage.bContentUsed = false;

    if (_.isUndefined(storage.sortId)) storage.sortId = "updatedTime";
    if (_.isUndefined(storage.sortDesc)) storage.sortDesc = true;


    var store = function () {
      $localStorage[key] = _.cloneDeep(storage);
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();

  ///sort
  $scope.doSortClick = function(id) {
    $timeout(function() {
      if (id === $scope.storage.sortId) {
        //自分がソート状態＝＞ソートを昇順降順に切り替える
        $scope.storage.sortDesc = !$scope.storage.sortDesc;
      } else {
        $scope.storage.sortId = id;
        $scope.storage.sortDesc = true;
      }
      //ソート
      $scope.sortContent($scope.storage.sortId);
    })

  }
  $scope.getsortADClass = function(id) {
    if (id === $scope.storage.sortId) {
      return $scope.storage.sortDesc ?  'fa fa-sort-desc' : 'fa fa-sort-asc'
    }
    return "";
  }

  ///sort

  $scope.ui = {showPlaylistType : 2}; //0:all , 1:used  2:notUsed
  $scope.playlists = [];
  var arrContents = [];
  function getPlaylistContent(callback) {
    var async = require('async');
    var arrContents = [];
    $http.get('/api/v1/playlists').
    success(function(datas_pl) {
      async.forEachOfSeries(datas_pl.playlists , function(pl,index,next){
        $http.get('/api/v1/playlists/' + pl.playlistId + '/contents').
        success(function(datas_con) {
          pl.contents = datas_con;
          arrContents = arrContents.concat(datas_con);
          next(null);
        });
      },function(err){
        $scope.playlists = datas_pl.playlists;
        var arrContentsUsed = arrContents.filter(function (x, i, array) {
          return array.findIndex(function (y) {
            return y.contentId === x.contentId;
          }) === i
        });
        callback(arrContentsUsed);
      })
    });
  }



  $scope.doSearch_AllUploaded_done = function() {
    console.log("4333")
    $scope.showContTypeInfo.loaded = false;
    $timeout(function(){
      $scope.showContTypeInfo.itemsPerPage = 20;
      $scope.showContTypeInfo.pages = Math.round($scope.showContTypeInfo.contents.length / $scope.showContTypeInfo.itemsPerPage);

      getPlaylistContent(function(arrContents) {
        var datas = _.filter($scope.showContTypeInfo.contents_all, function(dt) {
          var findCon = _.find(arrContents, function(con) {
            return con.contentId === dt.id;
          })
          if (findCon) {
            dt.playlistName = findCon.playlistName;
            dt.findPlaylistId = findCon.playlistId;
          }

          var arrplaylistName = [];
          _.filter(arrContents, function(con) {
            if (con.contentId === dt.id) {
              _.find($scope.playlists ,function(pl){
                if (pl.playlistId === con.playlistId) {
                  arrplaylistName.push(pl.playlistName);
                  return true;
                }
              })
              return true;
            }
          })

          _.find($scope.showContTypeInfo.datas , function(show_dt) {
            if (show_dt.checkFunc(dt)) {
              dt.contentType = show_dt.id;
              return true;
            }
          })


          dt.playlistName_all = arrplaylistName.join(" / ");
          dt.updatedTime_string = $filter('limaFormatDate')(new Date(dt.registeredDate),'YYYY-MM-DD hh:mm:ss');
        })


        $scope.setShowContType_all($scope.showContTypeInfo_all.checked );
        if (!$scope.showContTypeInfo_all.checked ) {
          _.find($scope.showContTypeInfo.datas , function(dt, index) {
            if (dt.checked) {
              $scope.setShowContType(index);
              return true;
            }
          })

        }

        $scope.updateFileSize();
        $scope.sortContent($scope.storage.sortId);

        console.log("4389")
        $scope.showContTypeInfo.loaded = true;

      })
    })
  }

  $scope.doSearch_AllUploaded = function(callback) {
    console.log("4397")
    $scope.showContTypeInfo.loaded = false;
    ContentUtil.doSearchContent({type:"uploaded"} , function(datas) {
      $scope.showContTypeInfo.contents_all = datas;
      $scope.showContTypeInfo.contents = datas;

      $scope.setShowContType_all($scope.showContTypeInfo_all.checked );
      if (!$scope.showContTypeInfo_all.checked ) {
        _.find($scope.showContTypeInfo.datas , function(dt, index) {
          if (dt.checked) {
            $scope.setShowContType(index);
            return true;
          }
        })
      }
      $scope.updateFileSize();

      console.log("4414")
      $scope.showContTypeInfo.loaded = true;

     callback();
    });


  }

  $scope.deleteContentsFromChecked = function() {
    var arrSelected = _.filter($scope.showContTypeInfo.contents, function(cont){
      return cont.checked;
    });

    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.SELECTED_CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      //削除
      limaShowWaitDialog.showWait($scope,$translate.instant('CONTENT.PLAYLIST.CONTENT'),$translate.instant('PLAYLIST.MESSAGE_DELETING'));
      $scope.deleteContents(arrSelected , function() {
        $scope.doSearch_AllUploaded(function() {
          $scope.doSearch_AllUploaded_done();

          $scope.showContTypeInfo.checked_all = false;

          limaShowWaitDialog.hideWait();

          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
          html:$translate.instant('COMMON.MSG.DONE_DELETE' , {name:$translate.instant('CONTENT.PLAYLIST.CONTENT')}),
          modalClass:'modal-success'} , function() {
          });
      })

      })

    });
  }


  //未使用かどうか設定
  $scope.setContentUsed = function(val) {
    $scope.storage.bContentUsed = val;

    if (!$scope.showContTypeInfo_all.checked ) {
      _.find($scope.showContTypeInfo.datas , function(dt, index) {
        if (dt.checked) {
          $scope.setShowContType(index);
          return true;
        }
      })

    } else {
      $scope.setShowContType_all($scope.showContTypeInfo_all.checked );
    }

    $scope.updateFileSize();
  }



  function getMultiDatas() {
    if ($scope.showContTypeInfo.contents) {
      var start  = ($scope.showContTypeInfo.currentPage-1)*$scope.showContTypeInfo.itemsPerPage;
      var count   = $scope.showContTypeInfo.itemsPerPage;
      var datas = $scope.showContTypeInfo.contents.slice(start, start + count);
      return datas;
    } else {
      return [];
    }
  }

  //未使用＝削除できる物だけ
  $scope.checkContentsAll = function() {
    var arr = getMultiDatas();
    _.forEach(arr , function(cont){
    if ($scope.isEnableDeleteContent(cont)) {
        cont.checked = $scope.showContTypeInfo.checked_all;
      }
    })
    $scope.updateFileSize();

  }


  $scope.doSearch_AllUploaded(function() {
    $scope.doSearch_AllUploaded_done();
  })


}]);



//ContentCtrlの子
app.controller('ContentMainCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$location','$sce','$uibModal', 'Modal','$q', '$translate', '$window', 'ContentUtil', '$controller',
function ($scope, socket, Auth,$http, $timeout,$location,$sce,$modal, Modal, $q, $translate,$window, ContentUtil, $controller) {
  $controller('ContentCtrl', {$scope:$scope, socket:socket, Auth:Auth,$http:$http, $timeout:$timeout,$location:$location,$sce:$sce,$modal:$modal, Modal:Modal, $q:$q,$translate:$translate}); //This works

  $scope.isModal = false;
  $scope.isPlaylistModal = false;
  $scope.playlistId = undefined;

  $scope.searchType.type = 2;// local

  //$scope.doSearch();
  $scope.showContTypeInfo.datas = [
    {
      type:ContentUtil.CONTENT_SHOWTYPE_NAME_AUTOUPDATED,
      type2:"",
      id : ContentUtil.CONTENT_SHOWTYPE_AUTOUPDATED,
      title : "",
      checked : true,
      class : "glyphicon glyphicon-picture",
      checkFunc: $scope.isAutoUpdated,
    }

  ];

  $scope.modalUploadFile2 = function(type) {

    var arrUploadedMaterials_new = [];
    var arrUploadedMaterials = [];

    var controllerName = "";
    if (type==='autoupdated') {
      controllerName = "LocalModalAutoUpdatedCtrl";
    }

    var modalInstance = $modal.open({
      templateUrl: 'app/content/local.html',
      controller: controllerName,
      //windowClass: 'modal-upload-window-ex',
      windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
      size: 'lg',
      resolve: {
        LimaModalUploadData: function() {
          return {
            contentCount : 0
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      var string = "";
      var arrStr = [];
      if (results) {
        if (results.materials) {
          if (results.materials.length) {
            _.forEach(results.materials[0] , function(rr) {
              arrStr.push(rr.title);
            });
          }
        }
      }

      if (arrStr.length) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:"登録されました。<br><hr>" + arrStr.join("<br>"),
        modalClass:'modal-success'});
      }


//       if ($scope.doSearch_AllUploaded) {
//         $scope.doSearch_AllUploaded(function() {
//           $scope.doSearch_AllUploaded_done();
//         });
//       }


//       //contentsInfoと同じ並びにする
//       if (arrUploadedMaterials) {

//       }
//       var arrUploadedMaterials_b = _.cloneDeep(arrUploadedMaterials);
//       arrUploadedMaterials = [];
//       _.forEach($scope.contentsInfo, function(info){
//         var find = _.find(arrUploadedMaterials_b, function(mat){
//           if (mat.id === info.id) {
//             return true;
//           }
//         });
//         if (find) {
//           arrUploadedMaterials.push(find);
//         } else {
//           console.log("not find");
//         }
//       });

//       arrUploadedMaterials_new = [];
//       arrUploadedMaterials_new = results.materials;

//       arrUploadedMaterials = arrUploadedMaterials.concat(results.materials);
//       $scope.uploadContetnList = $scope.uploadContetnList.concat(results.materials);
//       $timeout(function(){
//         $scope.doSearch(null , function(){
//           if ($scope.searchType.type === 3) {
//             $scope.createTemporaryPlaylistHTML();
//             limaShowWaitDialog.hideWait();
//           }
//         });
//       });
     }, function() {
//       //閉じられた
// //        console.log("aaa");
     });

  }


  $scope.editclip = (function(content) {
    //第2引数にIDを渡して、同じURLのタブが複数開かないようにする。
    $window.open( 'http://' + location.host + '/edit?contentId=' + encodeURIComponent(content.id), encodeURIComponent(content.id));
    return false;
  });

}]);

//ContentCtrlの子
app.controller('ContentModalPlaylistCtrl', ['$scope', 'socket', 'Auth', '$http', '$localStorage', '$timeout','$location','$sce','$uibModal', 'Modal','$q', '$uibModalInstance','$translate', 'LimaPlaylistModalContentData','limaShowWaitDialog', '$controller',
function ($scope, socket, Auth,$http, $localStorage, $timeout,$location,$sce,$modal, Modal, $q, $modalInstance,$translate, LimaPlaylistModalContentData, limaShowWaitDialog, $controller) {
  $controller('ContentCtrl', {$scope:$scope, socket:socket, Auth:Auth,$http:$http, $timeout:$timeout,$location:$location,$sce:$sce,$modal:$modal, Modal:Modal, $q:$q,$modalInstance:$modalInstance, $translate:$translate}); //This works

  $scope.isModal = false;
  $scope.isPlaylistModal = true;

  $scope.playlistId =  LimaPlaylistModalContentData.playlistId;
  $scope.playlistName =  LimaPlaylistModalContentData.playlistName;
  $scope.searchType.type = 3;// playlist
  $scope.uploadEnable = LimaPlaylistModalContentData.upload;


  $scope.storage = (function () {
    var key = 'limaApp:ContentModalPlaylistCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};

    if (_.isUndefined(storage.thumsize)) storage.thumsize = 0;
    if (_.isUndefined(storage.bContentListEnable)) storage.bContentListEnable = 0;
    if (_.isUndefined(storage.bShowContent)) storage.bShowContent = false;

    var store = function () {
      $localStorage[key] = _.cloneDeep(storage);
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();



  $scope.sortableOptions = {
    'contentsInfo' : '> li',
    'update': function(e, ui) {
      console.log(e );
      console.log(ui );
      $timeout(function(){
        $scope.createTemporaryPlaylistHTML();
      });
    }
  };

  $scope.sortableOptions_clone = {
    'contentsInfo' : '> li',
    'update': function(e, ui) {
      console.log(e );
      console.log(ui );
      $timeout(function(){
        $scope.createTemporaryPlaylistHTML();
      });
    }
  };


  $scope.sortableOptions_table = {
    'update': function(e, ui) {
      console.log(e );
      console.log(ui );
      $timeout(function(){
        $scope.createTemporaryPlaylistHTML();
      });
    }
  };

  //$('#widget').draggable();

  // var blockMenuHeaderScroll = false;
  // $(window).on('touchstart', function(e)
  // {
  //       blockMenuHeaderScroll = true;
  // });
  // $(window).on('touchend', function()
  // {
  //     blockMenuHeaderScroll = false;
  // });
  // $(window).on('touchmove', function(e)
  // {
  //     if (blockMenuHeaderScroll)
  //     {
  //         e.preventDefault();
  //     }
  // });


  $scope.message = {};
  $scope.message.CreateSuccess = "";
  function TriggerAlertClose() {
    window.setTimeout(function () {
        $(".alert").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateSuccess = "";
        });
    }, 3000);
  }



  $scope.renamePlaylist = function() {
    //new
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/newName.html',
      controller: 'NewNameCtrl',
      windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaNewNameData: function() {
          return {
            name : $scope.playlistName
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      var option = {
        playlistAndSchedule: false
      };
      $scope.playlistName = results.name
      $scope.savePlaylist(option, function(id){
        // Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        // html:'名前を変更しました。',//$translate.instant('CONTENT.ATTACHMENT.CHECK_SHOW_DETAIL'),
        // modalClass:'modal-success'});
        $scope.message.CreateSuccess = $translate.instant('PLAYLIST.MESSAGE_CHANGE_NAME_OK');
        TriggerAlertClose();


      });
    }, function() {
      //
    });
  }

  $scope.playlistInfo = {};
  if ($scope.playlistId) {
    $http.get('/api/v1/Playlists/' + $scope.playlistId).then(function (res) {
      $scope.playlistInfo = res.data;
    });
  }

  $scope.playlistUIInfo = {
    playlistLengthAllText : '',
    playlistDurationAllText : '',
    regions : []
  };


  $scope.playlistSetting = null;
  $http.get('/api/v1/configurations/Playlist').then(function (res) {
    if (res.data) {
      $scope.playlistSetting  = res.data.DefaultSetting;

      if ($scope.playlistId) {
        $http.get('/api/v1/playlists/' + $scope.playlistId + '/setting').
        success(function(datas) {
          if (datas) {
            $scope.playlistSetting  = datas;
          }
        });
      }
    }
  });


  if ($scope.playlistId) {
    limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('PLAYLIST.MESSAGE_LOADING_PLAYLIST'));
    $scope.doSearch($scope.playlistId, function() {
      $scope.createTemporaryPlaylistHTML();
      limaShowWaitDialog.hideWait();
    });
  } else {
    if ($scope.uploadEnable) {
      $scope.modalUploadFile('file');
    }
  }


  $scope.modalClose = function() {
    $timeout(function() {
      $modalInstance.close();
      //$modalInstance.dismiss('cancel');
    } , 10);
  }

  $scope.saveAndClose = function() {
    var option = {
      playlistAndSchedule: false
    };
    $scope.savePlaylist(option, function(id){
        $scope.modalClose();
    });
  }

  $scope.cancel = function() {

    if (!$scope.playlistId) {
      //新規　かつ　アップロードされたものもない＝空　＝＞メッセージ出さない
      if (!$scope.bNotIncludeContentLayout) {
        //外部入力のみのレイアウトではない
        if (!$scope.contentsInfo) {
          $modalInstance.dismiss('cancel');
          return;
        }
        else if (!$scope.contentsInfo.length) {
          $modalInstance.dismiss('cancel');
          return;
        }
      }
    }

    if (!$scope.settings.leaveContent) { //コンテンツ残さない
      //アップロードしたコンテンツは消す
      limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('PLAYLIST.MESSAGE_CANCELING'));
      var promise = $scope.deleteContents($scope.uploadContetnList, function(){
        limaShowWaitDialog.hideWait();
        $modalInstance.dismiss('cancel');
      });
      return;
    }

    //コンテンツ残す
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('PLAYLIST.MESSAGE_NO_SAVE_CLOSE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;

      //アップロードしたコンテンツがあるのに保存せずに終了しようとした＝＞コンテンツは削除する
      if ($scope.uploadContetnList.length) {
        limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('PLAYLIST.MESSAGE_CANCELING'));
        var promise = $scope.deleteContents($scope.uploadContetnList, function(){
          limaShowWaitDialog.hideWait();
          $modalInstance.dismiss('cancel');
        });
      } else {
        $modalInstance.dismiss('cancel');
      }


    });
  }
}]);

app.controller('ContentModalPlaylistRegionCtrl', ['$scope', 'socket', 'Auth', '$http', '$localStorage','$timeout','$location','$sce','$uibModal', 'Modal','$q', '$uibModalInstance','$translate', 'LimaPlaylistModalContentData','limaShowWaitDialog', 'ContentUtil','$controller',
function ($scope, socket, Auth,$http, $localStorage, $timeout,$location,$sce,$modal, Modal, $q, $modalInstance,$translate, LimaPlaylistModalContentData, limaShowWaitDialog, ContentUtil, $controller) {
  $controller('ContentCtrl', {$scope:$scope, socket:socket, Auth:Auth,$http:$http, $timeout:$timeout,$location:$location,$sce:$sce,$modal:$modal, Modal:Modal, $q:$q,$modalInstance:$modalInstance, $translate:$translate}); //This works

  $scope.isModal = false;
  $scope.isPlaylistModal = true;
  $scope.isPlaylistRegionModal = true;/******/

  $scope.playlistId =  LimaPlaylistModalContentData.playlistId;
  $scope.playlistName =  LimaPlaylistModalContentData.playlistName;
  $scope.searchType.type = 3;// playlist
  $scope.uploadEnable = LimaPlaylistModalContentData.upload;


  $scope.storage = (function () {
    var key = 'limaApp:ContentModalPlaylistRegionCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};

    if (_.isUndefined(storage.thumsize)) storage.thumsize = 0;
    if (_.isUndefined(storage.bContentListEnable)) storage.bContentListEnable = 0;
    if (_.isUndefined(storage.bContentUsed)) storage.bContentUsed = false;

    var store = function () {
      $localStorage[key] = _.cloneDeep(storage);
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();


/**** */
  $scope.copyContent_region = function(tw) {

    if ($scope.getActiveRegion()) {
      if ($scope.getActiveRegion().contentsInfo) {
        if ($scope.getActiveRegion().contentsInfo.length >= $scope.settings.playlistContentCount) {
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'), modalClass:'modal-warning'});
          return;
        }
      }
    }


    var indexContent = -1;
    _.forEach($scope.getActiveRegion().contentsInfo, function(cont, index){
      if (_.isEqual(cont, tw) === true) {
          indexContent = index;
      }
    });
    $scope.getActiveRegion().contentsInfo.splice( indexContent+1, 0, angular.copy(tw) ) ; //$$hashkey削除するためのコピー方法

    $timeout(function(){$scope.$apply()});
  }

  $scope.deleteContentFromUI_region = function(selectCont) {
    $scope.getActiveRegion().contentsInfo = _.filter($scope.getActiveRegion().contentsInfo , function(cont) {
      if (_.isEqual(cont, selectCont) !== true) {
        return true;
      }
    })
    $timeout(function(){
      //長さ計算
      $scope.calcPlaylistDurationFunc();
      $scope.createTemporaryPlaylistHTML();
    });

  }



  $scope.setactiveRegionTabIndex = function(reginonId) {
    $timeout(function() {
      $scope.activeRegionTabIndex = reginonId;

      $scope.canvasDraw();
      $scope.setRegionTab();
    })
  }
  $scope.getRegionName = function(region) {
    var name = region.name.replace("v2_custom" , "リージョン");
    name = name.replace("all" , "リージョン");
    return name;
  }

  $scope.style_region = function() {
    if ($scope.getActiveRegion().contentsInfo) {
      if ($scope.getActiveRegion().contentsInfo.length===0) {
        var val ={
          'height':100 + 'px'
        }
        return val;
      }
    } else {
      var val ={
        'height':100 + 'px'
      }
      return val;

    }
    return {};
  }


  $scope.getActiveRegion = function() {
    var find = {};
    if ($scope.selectLayout) {
      if ($scope.selectLayout.model) {
        if ($scope.selectLayout.model.regions) {
          find = _.find($scope.selectLayout.model.regions , function(rg) {
            return rg.regionId === $scope.activeRegionTabIndex;
          })
        }
      }
    }
    if (!find) {
      find = {};
    }
    return find;
  }
  $scope.isMultiRegion = function() {
    if ($scope.selectLayout) {
      if ($scope.selectLayout.model) {
        if ($scope.selectLayout.model.regions) {
          return $scope.selectLayout.model.regions.length > 1 ? true : false;
        }
      }
    }
    return false;
  }

  $scope.registContToRegion = function(content , region){
    if (!region.contentsInfo ) {
      region.contentsInfo = [];
    }
    $timeout(function() {

      region.contentsInfo.push(angular.copy(content));

    })

  }

  $scope.modalContentSetting = function(content) {
    console.log('hogaaaaaaa')
    var modalInstance = $modal.open({
      templateUrl: 'app/content/dlg/setting.html',
      controller: 'ContentSettingCtrl',
      windowClass: 'modal-registSNS-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        ModalContentSetting: function() {
          return {
            content : content,
            playlistSetting: _.cloneDeep($scope.playlistSetting)
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      content.additionalInfo = results.additionalInfo;
      $timeout(function(){
        //長さ計算
        $scope.calcPlaylistDurationFunc();
        $scope.createTemporaryPlaylistHTML();
      });

    }, function() {
      //
    });

  }


  //=====================================================
  ///D&D用の準備　---->
  $scope.m_sortable_L = null;
  $scope.m_sortable_R = null;
  $scope.deleteSortable_LR = function(L,R) {
    if ($scope.m_sortable_L && L) {
      $scope.m_sortable_L = null;
    }
    if ($scope.m_sortable_R && R) {
      $scope.m_sortable_R = null;
    }
  }

  function createSortable_LR() {
    var el = document.getElementById($scope.storage.bContentListEnable ? 'sortableLeft_list' : 'sortableLeft');
    if (!el && $scope.m_sortable_L) {
      $scope.m_sortable_L = null;  //一旦開放
    }
    if (el && !$scope.m_sortable_L) {
      $scope.m_sortable_L = Sortable.create(el,
        {
          handle:".sortableItem",
          group: {
              name: 'shared',
              pull: 'clone',
              //revertClone : true,
              put: false, // Do not allow items to be put into this list
          },
          animation: 150,
          sort: false,
          // , // To disable sorting: set sort to false
          onStart:  onStartEvent_L,  // 2-1, ドラッグ開始時
           onEnd:    onEndEvent_L,    // 2-2, ドラッグ終了時
          onChange: onChangeEvent_L, // 2-3, ドラッグ変化時
          onSort:   onSortEvent_L    // 2-4, 並び替え終了時
        }
      );
    }

    var el2 = document.getElementById($scope.storage.bContentListEnable ? 'sortableRight_list' : 'sortableRight');
    if (el2 && !$scope.m_sortable_R) {
      $scope.m_sortable_R = Sortable.create(el2,{
        handle:".sortableItem",
        group: 'shared',
        animation: 150
        ,
        onStart:  onStartEvent_R,  // 2-1, ドラッグ開始時
        onEnd:    onEndEvent_R,    // 2-2, ドラッグ終了時
        onChange: onChangeEvent_R, // 2-3, ドラッグ変化時
        onSort:   onSortEvent_R    // 2-4, 並び替え終了時
      }
    );
    }
  }

  function onStartEvent_L(e){
    //console.log("onStart!!");
  }

  function onEndEvent_L(e){
    // console.log("onEnd start!!");

    // //ドラッグした要素が追加されてしまうので、枠内を作り直す
    // $scope.showContTypeInfo.sorting = true;
    // $timeout(function() {
    //   $scope.showContTypeInfo.sorting = false;

    //   //D&D用のクラスを再作成
    //   $scope.deleteSortable_LR(true , true);
    //   $scope.Do_createSortable_LR();
    //   console.log("onEnd end!!");

    // }, 0)

  }

  function onChangeEvent_L(e){
    //console.log("onChange!!");
  }

  function onSortEvent_L(e){
    // 3, 並び替え後のエレメントを確認
    //console.log("onSort!!");
  }

  function onStartEvent_R(e){
    //console.log("onStart!!");
  }

  function onEndEvent_R(e){
    //console.log("onEnd!!");
  }

  function onChangeEvent_R(e){
    //console.log("onChange!!");
  }

  function getId(elem){
    var cc = elem.children;
    if (cc.length >= 2) {
      var cc2 = elem.children[1].children;
      if (cc2.length >= 1) {
        var id = elem.children[1].children[0].getAttribute("id");
          if (id) {
            if (id.indexOf("left_") !== -1) {
              return id;
            }
          }
      }
    }
    return "";
  }

  function onSortEvent_R(e){
    console.log("onSortEvent_R start!!");


    //fromがleftだったら何もしない
    if (e.from.getAttribute("id").toLowerCase().indexOf("left") !== -1 ) {
      //$scope.showContTypeInfo.contentsにはcontentIdの重複はない

      //左欄からdropされたやつを探す。elemのIDが”left_”のもの
      var find_from_id = null;
      var findIndex_to = -1;
      const items = e.to.querySelectorAll(".sortableItem");
      _.find(items , function(item , index) {
        var id = item.getAttribute("id");
        if (!id) {
          id = getId(item);
        }
        if (id.indexOf("left_") !== -1) {
        // if (item.getAttribute("id").indexOf("left_") !== -1) {
          find_from_id = id.replace("left_" , "");  //ContentId
          findIndex_to = index;  //Drop位置
          item.remove();  //実際にドロップされたHTML上のelementは不要なので削除
          return true;
        }
      })

      //DropされたコンテンツのContentId
      var find_from = _.find($scope.showContTypeInfo.contents,function(cont) {
        return cont.id === find_from_id;
      })

      var res_isEnableInsert = $scope.isEnableInsertToRegion( find_from , $scope.getActiveRegion());
      if (!res_isEnableInsert.enable) {
        var mes = "複数リージョンには登録できません"
        mes += "<br>※「" + res_isEnableInsert.reasonContType+ "」が別のリージョンに登録されています。"

        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:mes,
        modalClass:'modal-success'});

        return;
      }

      if ($scope.getActiveRegion()) {
        if ($scope.getActiveRegion().contentsInfo) {
          if ($scope.getActiveRegion().contentsInfo.length >= $scope.settings.playlistContentCount) {
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'), modalClass:'modal-warning'});
            return;
          }
        }
      }

      if (find_from && findIndex_to >=0 ) {
        var contentsInfo_back = _.cloneDeep($scope.getActiveRegion().contentsInfo);
        $scope.getActiveRegion().contentsInfo = [];

        delete find_from.$$hashKey;
        _.forEach(contentsInfo_back , function(cont) {
          delete cont.$$hashKey;
        })

        //テロップの向きをリージョンから決める
        var copyCont = _.cloneDeep(find_from);
        $scope.checkTelopVerticalByRegion(copyCont);

        $scope.getActiveRegion().contentsInfo = contentsInfo_back;
        $timeout(function() {
          $scope.getActiveRegion().contentsInfo.splice(findIndex_to , 0 , copyCont);  //cloneDeepで新しいコンテンツとして登録
        });

        //ドラッグした要素が追加されてしまうので、枠内を作り直す
        $scope.showContTypeInfo.sorting_a = true;
        $scope.showContTypeInfo.sorting = true;
        $timeout(function() {
          $scope.showContTypeInfo.sorting_a = false;
          $scope.showContTypeInfo.sorting = false;

          //D&D用のクラスを再作成
          $scope.deleteSortable_LR(true , true);
          $scope.Do_createSortable_LR();

        }, 0)

      } else {
        console.log("###find_from=null")

       //ドラッグした要素が追加されてしまうので、枠内を作り直す
       $scope.showContTypeInfo.sorting_a = true;
       $timeout(function() {
         $scope.showContTypeInfo.sorting_a = false;

         //D&D用のクラスを再作成
         $scope.deleteSortable_LR(true , true);
         $scope.Do_createSortable_LR();

       }, 0)


        return;
      }

      $timeout(function(){
        $scope.calcPlaylistDurationFunc();
        $scope.createTemporaryPlaylistHTML();
      });

      return;

    }

    var arrHash = [];  //ソート後の並び
    const items = e.to.querySelectorAll(".sortableItem");
    _.forEach(items , function(item) {
      var hash = item.getAttribute("id").replace("right_", "");
      arrHash.push(hash);
    })

    var contentsInfo_back = _.cloneDeep($scope.getActiveRegion().contentsInfo);
    $scope.getActiveRegion().contentsInfo = [];
    _.forEach(arrHash , function(hash) {
      _.find(contentsInfo_back , function(cont) {
        if (cont.$$hashKey === hash) {
          $scope.getActiveRegion().contentsInfo.push(cont);
          return true;
        }
      })
    })


    $timeout(function(){
      $scope.calcPlaylistDurationFunc();
      $scope.createTemporaryPlaylistHTML();
    });
  }


  var m_timersortableCreate = null;
  $scope.Do_createSortable_LR = function() {
    if (m_timersortableCreate) {
      $timeout.cancel(m_timersortableCreate);
      m_timersortableCreate = null;
    }

    createSortable_LR();

    if (!$scope.m_sortable_L || !$scope.m_sortable_R) {
      m_timersortableCreate = $timeout(function() {
        $scope.Do_createSortable_LR();
      } , 500);
    }
  }
  $scope.Do_createSortable_LR();
  ///<---- D&D用の準備　ここまで
  //=====================================================


  //$('#widget').draggable();

  // var blockMenuHeaderScroll = false;
  // $(window).on('touchstart', function(e)
  // {
  //       blockMenuHeaderScroll = true;
  // });
  // $(window).on('touchend', function()
  // {
  //     blockMenuHeaderScroll = false;
  // });
  // $(window).on('touchmove', function(e)
  // {
  //     if (blockMenuHeaderScroll)
  //     {
  //         e.preventDefault();
  //     }
  // });


  $scope.message = {};
  $scope.message.CreateSuccess = "";
  function TriggerAlertClose() {
    window.setTimeout(function () {
        $(".alert").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateSuccess = "";
        });
    }, 3000);
  }


  $scope.renamePlaylist = function() {
    //new
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/newName.html',
      controller: 'NewNameCtrl',
      windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaNewNameData: function() {
          return {
            name : $scope.playlistName
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      var option = {
        playlistAndSchedule: false
      };
      $scope.playlistName = results.name
      $scope.savePlaylist(option, function(id){
        // Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        // html:'名前を変更しました。',//$translate.instant('CONTENT.ATTACHMENT.CHECK_SHOW_DETAIL'),
        // modalClass:'modal-success'});
        $scope.message.CreateSuccess = $translate.instant('PLAYLIST.MESSAGE_CHANGE_NAME_OK');
        TriggerAlertClose();


      });
    }, function() {
      //
    });
  }

  $scope.playlistInfo = {};
  if ($scope.playlistId) {
    $http.get('/api/v1/Playlists/' + $scope.playlistId).then(function (res) {
      $scope.playlistInfo = res.data;
    });
  }

  $scope.playlistSetting = null;
  $http.get('/api/v1/configurations/Playlist').then(function (res) {
    if (res.data) {
      $scope.playlistSetting  = res.data.DefaultSetting;

      if ($scope.playlistId) {
        $http.get('/api/v1/playlists/' + $scope.playlistId + '/setting').
        success(function(datas) {
          if (datas) {
            $scope.playlistSetting  = datas;
          }
        });
      }
    }
  });



  if ($scope.playlistId) {
    limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('PLAYLIST.MESSAGE_LOADING_PLAYLIST'));
    $scope.doSearch($scope.playlistId, function() {
      $scope.createTemporaryPlaylistHTML();
      limaShowWaitDialog.hideWait();
    });
  } else {
    if ($scope.uploadEnable) {
      $scope.modalUploadFile('file');
    }
  }

  $scope.modalClose = function() {
    $timeout(function() {
      $modalInstance.close();
      //$modalInstance.dismiss('cancel');
    } , 10);
  }

  $scope.saveAndClose = function() {
    var option = {
      playlistAndSchedule: false
    };
    $scope.savePlaylist(option, function(id){
        $scope.modalClose();
    });
  }

  $scope.cancel = function() {

    if (!$scope.playlistId) {
      //新規　かつ　アップロードされたものもない＝空　＝＞メッセージ出さない
      var isExistContent = false;
      if ($scope.selectLayout) {
        if ($scope.selectLayout.model) {
          var find = _.find($scope.selectLayout.model.regions , function(rg) {
            if (rg.contentsInfo) {
              return rg.contentsInfo.length;
            }
          });
          if (find) {
            isExistContent = true;
          }
        }
      }

      if (isExistContent) {
        Modal.confirm.yesno({
          title: $translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('PLAYLIST.MESSAGE_NO_SAVE_CLOSE'),
          btnClassYes: 'btn-default',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-danger',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;

          //保存しないので
          //リージョンへの登録解除
          if ($scope.selectLayout) {
            if ($scope.selectLayout.model) {
              _.forEach($scope.selectLayout.model.regions , function(rg) {
                rg.contentsInfo = [];
              });
            }
          }
          //使われていないコンテンツをチェック＆削除するを選択されたら削除する＆削除中メッセージあり
          $scope.do_deleteNotUsesContent(function() {
            $modalInstance.dismiss('cancel');
            return; //閉じて終了
          });
        });
      } else {
        //コンテンツはない
        $modalInstance.dismiss('cancel');
        return; //閉じて終了
      }
    }else {



    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('PLAYLIST.MESSAGE_NO_SAVE_CLOSE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;



      //アップロードしたコンテンツがあるのに保存せずに終了しようとした
      if ($scope.uploadContetnList.length) {
        //保存しないので
        //リージョンへの登録解除
        if ($scope.selectLayout) {
          if ($scope.selectLayout.model) {
            _.forEach($scope.selectLayout.model.regions , function(rg) {
              rg.contentsInfo = [];
            });
          }
        }


        //使われていないコンテンツをチェック＆削除するを選択されたら削除する＆削除中メッセージあり
        $scope.do_deleteNotUsesContent(function() {
          $modalInstance.dismiss('cancel');
          return;
        });
      } else {
        $modalInstance.dismiss('cancel');
      }


    });
    }
  }



  $scope.doSearch_AllUploaded_done = function() {
    console.log("5475")
    $scope.showContTypeInfo.loaded = false;
    $timeout(function(){
      if ($scope.showContTypeInfo) {
        $scope.showContTypeInfo.itemsPerPage = 30;
        $scope.showContTypeInfo.pages = Math.round($scope.showContTypeInfo.contents.length / $scope.showContTypeInfo.itemsPerPage);

      }
      console.log("5481")
      $scope.showContTypeInfo.loaded = true;

        $scope.setShowContType_all($scope.showContTypeInfo_all.checked );
        if (!$scope.showContTypeInfo_all.checked ) {
          _.find($scope.showContTypeInfo.datas , function(dt, index) {
            if (dt.checked) {
              $scope.setShowContType(index);
              return true;
            }
          })

        }
        if (_.isFunction($scope.deleteSortable_LR)) {
        //D&D用のクラスを再作成
        $scope.deleteSortable_LR(true , true);
        $scope.Do_createSortable_LR();
        }

        $scope.updateFileSize();
        $scope.sortContent('updatedTime', true);  //登録日時でソート:コンテンツ枠はこのソートで固定

      //})
    })
  }

  $scope.doSearch_AllUploaded = function(callback) {
    console.log("5508")
    $scope.showContTypeInfo.loaded = false;
    ContentUtil.doSearchContent({type:"uploaded"} , function(datas) {
      _.forEach(datas, function(dt) {
        dt.checked = true;
      })

      $scope.showContTypeInfo.contents_all = datas;
      $scope.showContTypeInfo.contents = datas;

      $scope.setShowContType_all($scope.showContTypeInfo_all.checked );
      if (!$scope.showContTypeInfo_all.checked ) {
        _.find($scope.showContTypeInfo.datas , function(dt, index) {
          if (dt.checked) {
            $scope.setShowContType(index);
            return true;
          }
        })
      }
      $scope.updateFileSize();

      console.log("5529")
      $scope.showContTypeInfo.loaded = true;

     callback();
    });


  }
  $scope.doSearch_AllUploaded(function() {
    $scope.doSearch_AllUploaded_done();
  })



  $timeout(function() {
    if (LimaPlaylistModalContentData.isOldLayout) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:'コンテンツをリージョンごとに設定してください。',
      modalClass:'modal-information'});

    }
  })



}]);
