'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('modalMaterialLayout', {
    url: '/content/modalLayout',
    templateUrl: 'app/content/modalLayout.html',
    controller: 'ModalMaterialLayoutCtrl',
    title: 'Top',
    authenticate: true
  });
}]);
