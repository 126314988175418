'use strict';
var app = angular.module('limaApp');

app.controller('DlgSelectContentCtrl', ['$scope', '$http', '$translate', '$timeout', 'Modal', 'Auth', '$uibModalInstance', 'socket', 'ContentUtil', 'selectContent',
function ($scope, $http, $translate, $timeout, Modal, Auth, $modalInstance, socket, ContentUtil,selectContent) {


  $timeout(function(){
    $scope.selectContent = _.cloneDeep(selectContent);

    $scope.selectContent.contentsInfo = [];
    _.forEach(selectContent.contentsInfo , function(cont) {
      $scope.selectContent.contentsInfo.push(angular.copy(cont)); //渡された列挙と実体は違うものにしておく
    });

   _.forEach($scope.selectContent.contentsInfo , function(cm) {
    //   var find = _.find($scope.selectContent.encoderIds , function(id) {
    //     return cm.EncoderID === id;
    //   });
    //   //長い名前はチェック
    //   cm.EncoderTitle = limaAppUtil.charcount(cm.EncoderTitle , 17*2 , 14/*fontsize*/);

       cm.checked = false;
     });
    $scope.selectContent.checkedlist = _.filter($scope.selectContent.contentsInfo, function(list){return list.checked;});

  });

  ////
  $scope.isText = function(item) {
    var bb = ContentUtil.isText(item);
    return bb;
  }

  $scope.isExinput = function(item) {
    return ContentUtil.isExInput(item);
  }

  $scope.isLive = function(item) {
    return ContentUtil.isLive(item);
  }


  $scope.isVideo = function(item) {
    var bVideo = ContentUtil.isVideo(item);
    return bVideo;
  }
  $scope.isImage = function(item) {
    return ContentUtil.isImage(item);
  }

  $scope.isYoutube = function(item){
    return ContentUtil.isYoutube(item);
  }
  $scope.isYoutubeLive = function(item){
    return ContentUtil.isYoutubeLive(item);
  }


  $scope.isHTML = function(item) {
    return ContentUtil.isHTML(item);
  }
  $scope.isAutoUpdated = function(item) {
    return ContentUtil.isAutoupdated(item);
  }

  $scope.getAutoUpdatedTypeName = function(item) {
    return ContentUtil.getAutoUpdatedTypeName(item);
  }

  //コンテンツ種類の詳細: ex 静止画（JPEG）
  $scope.getContentTypeDetailString = function(cont) {
    if ($scope.isImage(cont)) {
      return $translate.instant('CONTENT.PROPERTY.STILL') + " (" + ContentUtil.getImageCodecName(cont) + ")";
    }
    return "";
  }


  $scope.UpdateCheckedList_All = function(){
    console.log($scope.svsDatas);
    $timeout(function() {
      _.forEach($scope.svsDatas, function(list){list.checked = $scope.svsDatas.checkedAll;});
      $scope.selectContent.checkedlist = _.filter($scope.selectContent.contentsInfo, function(list){return list.checked;});
    });
  }

  $scope.UpdateCheckedList = function(){
    $timeout(function() {
      $scope.selectContent.checkedlist = _.filter($scope.selectContent.contentsInfo, function(list){return list.checked;});
    });
  }
////

  $scope.updateDevice = function() {
    // _.forEach($scope.lists, function(list){
    //   list.groupId = $scope.selectedChannel[0].groupId;
    // });

    var selected = [];  //modalで返すのは渡された列挙。
    selected = _.filter(selectContent.contentsInfo , function(enc,index) {
      if ($scope.selectContent.contentsInfo[index].checked) {  //DLGで設定したチェック状態を見る
        return true;
      };
    });

    $modalInstance.close({selected:selected});
    $modalInstance.dismiss({selected:selected});
  }


  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
