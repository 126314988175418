'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('schedule', {
    url: '/schedule',
    templateUrl: 'app/schedule/schedule.html',
    controller: 'ScheduleCtrl',
    title: 'プレイリスト切り替え',
    authenticate: true
  });
}]);
