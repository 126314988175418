'use strict';

var app = angular.module('limaApp');

app.controller('DlgSelectUserCtrl', ['$scope', '$location', '$translate', '$timeout', 'Modal', 'Auth', '$uibModalInstance', 'socket', 'selectUsers',
function ($scope, $location, $translate, $timeout, Modal, Auth, $modalInstance, socket, selectUsers) {



  $timeout(function(){
    $scope.selectUsers = selectUsers;
    _.forEach($scope.selectUsers.users , function(cm) {
      var find = _.find($scope.selectUsers.userAll , function(dv) {
        return cm.id === dv.id;
      });
      cm.checked = find ? true : false;
    });
  });

  function getUserName(name) {
    if (name === 'administrator') {
      return $translate.instant('USER.USER_ADMINISTRATOR');
    }
    else if (name === 'manager') {
      return $translate.instant('USER.USER_MANAGER');
    }
    else if (name === 'user') {
      return $translate.instant('USER.USER_USER');
    }
    else if (name === 'viewer') {
      return $translate.instant('USER.USER_VIEWER');
    }
    return name;
  }

  $scope.getGroupName = function(member){
    var arrName = [];
    var findAdmin = _.find(member.groups, function (group) {
      arrName.push(getUserName(group.name));
    });
    return arrName.join(' / ');
  }

  $scope.UpdateCheckedList_All = function(){
    //console.log($scope.svsDatas);
    $timeout(function() {
      _.forEach($scope.decDatas, function(list){list.checked = $scope.decDatas.checkedAll;});
      $scope.selectUsers.checkedlist = _.filter($scope.selectUsers.users, function(list){return list.checked;});
    });
  }


  $scope.updateDevice = function() {
    // _.forEach($scope.lists, function(list){
    //   list.groupId = $scope.selectedChannel[0].groupId;
    // });

    var selected = [];
    _.filter($scope.selectUsers.userAll , function(dv) {
      if (dv.checked) {
        selected.push(dv.id);
      };
    });


    $modalInstance.close({selected:selected});
    $modalInstance.dismiss({selected:selected});
  }


  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
