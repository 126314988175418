'use strict';

var app = angular.module('limaApp');


app.controller('ContentSettingCtrl', ['$scope', '$http','socket', 'Auth', 'Modal','$window', '$timeout', '$location','$cookies','$uibModalInstance', '$translate', 'limaAppUtil', 'ModalContentSetting',
function($scope, $http, socket, Auth, Modal,$window, $timeout, $location, $cookieStore,$modalInstance,$translate, limaAppUtil, ModalContentSetting) {

  $scope.edit_starting = false;
  $scope.edit_starting_string = ""; //編集前の文字列
  $scope.editTextStart = function() {
    $scope.edit_starting = true;
    //編集前の文字列覚える
    $scope.edit_starting_string = _.cloneDeep($scope.content.additionalInfo.text);
  }
  $scope.editTextEnd = function(bSave) {
    if (bSave){
      if ($scope.edit_starting_string !== $scope.content.additionalInfo.text) {
        Modal.confirm.message({
          title:$translate.instant('COMMON.CONFIRM'),
          html:"テキストが変更されました。<br>リージョンからこのコンテンツの登録を解除すると変更内容は破棄されます。",//$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'),
          modalClass:'modal-success'});
      }
    } else {
      $scope.content.additionalInfo.text = $scope.edit_starting_string;
    }
    $scope.edit_starting_string = ""; //編集前の文字列初期化
    $scope.edit_starting = false;
  }


  $scope.urlTelopPreview = "";

  $scope.maxtext = 1000;  //最大文字数

  $scope.TEXTCOLOR = 0; //文字色
  $scope.TEXTBACKCOLOR = 1; //背景

  //透明度UI設定用
  $scope.textcolorInfo = [
    {
      index : $scope.TEXTCOLOR ,
      colorVal : 0,
      alpha : {
        sliderVal : 0,
        val_ui : 0,
        val : 0
      }
    },
    {
      index : $scope.TEXTBACKCOLOR ,
      colorVal : 0,
      alpha : {
        sliderVal : 0,
        val_ui : 0,
        val : 0
      }
    }
  ];

  //透明度スライダー変更
  $scope.textAlphaSliderChange = function(index) {

    var color_picker=document.querySelector("#color_picker_text");
    var color_wrapper=document.querySelector("#color_wrapper_text");
    if (index === $scope.TEXTBACKCOLOR ) {
      color_picker=document.querySelector("#color_picker_back");
      color_wrapper=document.querySelector("#color_wrapper_back");
    }

    var sliderVal = Number($scope.textcolorInfo[index].alpha.sliderVal);

    var a = 100 - sliderVal;
    color_wrapper.style.backgroundColor=$scope.textcolorInfo[index].val + (sliderVal == 0 ? "" : parseInt(a).toString(16).padStart(2, "0"));
    $scope.textcolorInfo[index].alpha.val_ui = sliderVal;
    if(color_wrapper.style.backgroundColor.split(',').length > 3) {
      color_wrapper.style.backgroundColor =  color_wrapper.style.backgroundColor.replace(/[\d.]+\)$/g, (100-sliderVal)/100)
    }
  }
  //透明度テキスト変更
  $scope.textAlphaInputChange = function(index) {
    if ($scope.textcolorInfo[index].alpha.val_ui === null) {
      //変な値の時はスライダーの値を使う（変化なしにする）
      //$scope.textcolorInfo[index].alpha.val_ui = $scope.textcolorInfo[index].alpha.sliderVal;
      return;
    }
    if (_.isUndefined($scope.textcolorInfo[index].alpha.val_ui)) {
      //変な値の時はスライダーの値を使う（変化なしにする）
//      $scope.textcolorInfo[index].alpha.val_ui = $scope.textcolorInfo[index].alpha.sliderVal;
      return;
    }

    $scope.textcolorInfo[index].alpha.sliderVal = Number($scope.textcolorInfo[index].alpha.val_ui);
    $scope.textAlphaSliderChange(index);
  }

  function convertHex(hexCode, opacity = 1){
    var hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    var r = parseInt(hex.substring(0,2), 16),
        g = parseInt(hex.substring(2,4), 16),
        b = parseInt(hex.substring(4,6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
    }

    return 'rgba('+r+','+g+','+b+','+opacity+')';
  }

  //色表示DIVクリック時
  function onIinput_color_picker(index , wrapperElem , val){
    $scope.textcolorInfo[index].val = val ;

    var a = 100 - $scope.textcolorInfo[index].alpha.val_ui;
    wrapperElem.style.backgroundColor=$scope.textcolorInfo[index].val + ($scope.textcolorInfo[index].alpha.val_ui == 0 ? "" : parseInt(a).toString(16).padStart(2, "0"));

//    wrapperElem.style.backgroundColor=$scope.textcolorInfo[index].val + ($scope.textcolorInfo[index].alpha.val == 255 ? "" : parseInt($scope.textcolorInfo[index].alpha.val).toString(16).padStart(2, "0"));
    wrapperElem.style.backgroundColor.replace(/[\d.]+\)$/g, $scope.textcolorInfo[index].alpha.val_ui /100)
    if(wrapperElem.style.backgroundColor.split(',').length > 3) {
      wrapperElem.style.backgroundColor = wrapperElem.style.backgroundColor.replace(/[\d.]+\)$/g, (100 - $scope.textcolorInfo[index].alpha.val_ui) /100)
    }


  }


  function onResizeA(){

    $timeout(function() {
      {

      //RGBA
      var rgb1 = limaAppUtil.rgbaToHex($scope.content.additionalInfo.fontColor)
      var rgb2 = limaAppUtil.rgbToHex($scope.content.additionalInfo.fontColor)
      if (!rgb2 && !rgb1) {
        rgb2 = {hex:"#000000" , alpha:1};
      }
      var hex = rgb1 ? rgb1.hex : rgb2.hex;
      var alpha = rgb1 ? rgb1.alpha : rgb2.alpha;

      //
      $scope.textcolorInfo[$scope.TEXTCOLOR].val = hex;
      $scope.textcolorInfo[$scope.TEXTCOLOR].alpha.val = parseInt(255 * alpha);;
      $scope.textcolorInfo[$scope.TEXTCOLOR].alpha.sliderVal = 100 - parseInt(alpha*100);
      $scope.textcolorInfo[$scope.TEXTCOLOR].alpha.val_ui = 100 - parseInt(alpha*100);
      //

      $scope.textAlphaSliderChange($scope.TEXTCOLOR);

      var color_picker=document.querySelector("#color_picker_text");
      color_picker.value = $scope.textcolorInfo[$scope.TEXTCOLOR].val;
      color_picker.oninput = function() {
        let wrapper=document.querySelector("#color_wrapper_text");
        onIinput_color_picker($scope.TEXTCOLOR , wrapper , color_picker.value);
      }
      }
      {
      let color_picker_back=document.querySelector("#color_picker_back");

      //RGBA
      var rgb1 = limaAppUtil.rgbaToHex($scope.content.additionalInfo.backgroudColor)
      var rgb2 = limaAppUtil.rgbToHex($scope.content.additionalInfo.backgroudColor)
      if (!rgb2 && !rgb1) {
        rgb2 = {hex:"#000000" , alpha:1};
      }
      var hex = rgb1 ? rgb1.hex : rgb2.hex;
      var alpha = rgb1 ? rgb1.alpha : rgb2.alpha;

      //
      $scope.textcolorInfo[$scope.TEXTBACKCOLOR].val = hex;
      $scope.textcolorInfo[$scope.TEXTBACKCOLOR].alpha.val = parseInt(255 * alpha);;
      $scope.textcolorInfo[$scope.TEXTBACKCOLOR].alpha.sliderVal = 100 - parseInt(alpha*100);
      $scope.textcolorInfo[$scope.TEXTBACKCOLOR].alpha.val_ui = 100 - parseInt(alpha*100);
      //

      $scope.textAlphaSliderChange($scope.TEXTBACKCOLOR);
      color_picker_back.value = $scope.textcolorInfo[$scope.TEXTBACKCOLOR].val;

      color_picker_back.oninput = function() {
        let wrapper=document.querySelector("#color_wrapper_back");

        onIinput_color_picker($scope.TEXTBACKCOLOR, wrapper , color_picker_back.value);
      }

      }
    },100)
  };
  $timeout(function(){  //timeoutの中でapplyが起こる
    onResizeA();
  });
  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }

  //流れる速さテキスト変更
  $scope.constantFlowSpeedTextChange = function() {
    //Inputの変化
    if ($scope.content.additionalInfo.constantFlowSpeed_text === null) {
      //変な値の時はスライダーの値を使う（変化なしにする）
      return;
    }
    if (_.isUndefined($scope.content.additionalInfo.constantFlowSpeed_text)) {
      //変な値の時はスライダーの値を使う（変化なしにする）
      return;
    }

    $scope.content.additionalInfo.constantFlowSpeed = $scope.content.additionalInfo.constantFlowSpeed_text;

  }

  //流れる速さスライダー変更
  $scope.constantFlowSpeedChange = function() {
    //スライダの変化
    $scope.content.additionalInfo.constantFlowSpeed_text = Number($scope.content.additionalInfo.constantFlowSpeed);
  }

  var m_additionalInfo_back={};
  function init() {
    $scope.content=_.cloneDeep(ModalContentSetting.content);
    if (!$scope.content.additionalInfo) {
      $scope.content.additionalInfo = {
        vertical : false ,
        align:"center",
        autofontSize:true,
        fontSize:80,
        flow : false,
        flowSpeed : ModalContentSetting.playlistSetting.changeContentDuration,  //デフォルト
        flowSpeedByPlaylistSetting : true,
        fontColor : "rgba(0,0,0,1)",
        backgroudColor : "rgba(255,255,255,1)"
      };
    } else {
      if (!_.isUndefined($scope.content.additionalInfo.constantFlowSpeed)) {
        $scope.content.additionalInfo.flowType = "speed";
      } else {
        $scope.content.additionalInfo.flowType = "time";
      }
      if (_.isUndefined($scope.content.additionalInfo.flowSpeed)) {
        $scope.content.additionalInfo.flowSpeed = ModalContentSetting.playlistSetting.changeContentDuration;  //デフォルト
        $scope.content.additionalInfo.flowSpeedByPlaylistSetting = true;
      }

      if (_.isUndefined($scope.content.additionalInfo.text)) {
        $scope.content.additionalInfo.text =  $scope.content.telopInfo.text;
      }
      if (_.isUndefined($scope.content.additionalInfo.constantFlowSpeed)) {
        $scope.content.additionalInfo.constantFlowSpeed = '5';
      }
    }

    //初期設定値から必要な設定を更新
    $scope.constantFlowSpeedChange();

    //バックアップ
    m_additionalInfo_back = _.cloneDeep($scope.content.additionalInfo);


    $scope.fontInfo = { };
    $http.get('/api/v1/system/fonts/info').
    success(function(datas) {
      $scope.fontInfo.datas = datas;
      if ($scope.content.additionalInfo.font) {
        $scope.fontInfo.selected = _.find(datas , function(dt) {
          return dt.fontFamily === $scope.content.additionalInfo.font
        })

      } else {
        if (datas.length) {
          $scope.fontInfo.selected =datas[0];
        }
      }
    });

  }

  //UI初期設定
  init() ;


  $scope.apply = function () {

    if (!$scope.content.additionalInfo.text.length) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:"テキストを入力してください。",//$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
      modalClass:'modal-danger'});
      return;
    }
    if ($scope.content.additionalInfo.text.length > 1000) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:"テキストは1000文字以下で入力してください。",//$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
      modalClass:'modal-danger'});
      return;
    }
    if ($scope.content.additionalInfo.flowType ===  'speed'){
      if ((_.isUndefined($scope.content.additionalInfo.constantFlowSpeed_text)) ||
          ($scope.content.additionalInfo.constantFlowSpeed_text <= 0) ||
          ($scope.content.additionalInfo.constantFlowSpeed_text > 20)) {
            var mes = "流れる速さは1から20の値で入力してください。";
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
            html:mes,//$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
            modalClass:'modal-danger'});
            return;
      }


      if (!_.isUndefined($scope.content.additionalInfo.flowSpeed)){
        delete $scope.content.additionalInfo.flowSpeed;
        delete $scope.content.additionalInfo.flowSpeedByPlaylistSetting
        delete $scope.content.additionalInfo.constantFlowSpeed_text;  //UI用の設定なので
      }
      $scope.content.additionalInfo.constantFlowSpeed = Number($scope.content.additionalInfo.constantFlowSpeed);
    }
    else if ($scope.content.additionalInfo.flowType ===  'time'){
      if (!_.isUndefined($scope.content.additionalInfo.constantFlowSpeed)){
        delete $scope.content.additionalInfo.constantFlowSpeed;
      }


      if ((_.isUndefined($scope.content.additionalInfo.flowSpeed)) || ($scope.content.additionalInfo.flowSpeed <= 0)) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:"流れる速さは１以上の値で入力してください。",//$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
        modalClass:'modal-danger'});
        return;
      }

      if ($scope.content.additionalInfo.flowSpeedByPlaylistSetting) {
        $scope.content.additionalInfo.flowSpeed =   ModalContentSetting.playlistSetting.changeContentDuration;
      }


    }

    //flowtypeは一時的なものなので消す
    if ($scope.content.additionalInfo.flowType) {
      delete $scope.content.additionalInfo.flowType;
    }

    if (!$scope.content.additionalInfo.autofontSize) {
      var enableFontSize = true;
      if (!$scope.content.additionalInfo.fontSize) {
        enableFontSize = false;
      }
      else if ($scope.content.additionalInfo.fontSize <= 0) {
        enableFontSize = false;
      }
      else if ($scope.content.additionalInfo.fontSize > 100) {
        enableFontSize = false;
      }
      if (!enableFontSize) {
        var mes = "文字の高さは1から100の値で入力してください。";
        if ($scope.content.additionalInfo.vertical) {
          mes = "文字の幅は1から100の値で入力してください。";
        }
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:mes,//$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
        modalClass:'modal-danger'});
        return;
      }
    }

    if ($scope.fontInfo.selected) {
      $scope.content.additionalInfo.font = $scope.fontInfo.selected.fontFamily;
    }

    var enableAlpha = true;
    if ($scope.textcolorInfo[$scope.TEXTCOLOR].alpha.val_ui === null ||
      $scope.textcolorInfo[$scope.TEXTBACKCOLOR].alpha.val_ui  === null ) {
        enableAlpha = false;
    }
    else if (_.isUndefined($scope.textcolorInfo[$scope.TEXTCOLOR].alpha.val_ui) ||
              _.isUndefined($scope.textcolorInfo[$scope.TEXTBACKCOLOR].alpha.val_ui) ) {
        enableAlpha = false;
    }
    else if ($scope.textcolorInfo[$scope.TEXTCOLOR].alpha.val_ui < 0 ||
      $scope.textcolorInfo[$scope.TEXTBACKCOLOR].alpha.val_ui < 0 ) {
        enableAlpha = false;
    }
    else if ($scope.textcolorInfo[$scope.TEXTCOLOR].alpha.val_ui >100 ||
      $scope.textcolorInfo[$scope.TEXTBACKCOLOR].alpha.val_ui >100 ) {
        enableAlpha = false;
    }
    if (!enableAlpha) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:"透明度は0から100の値で入力してください。",//$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
        modalClass:'modal-danger'});
        return;
    }


    let color_wrapper_back=document.querySelector("#color_wrapper_back");
    let color_wrapper=document.querySelector("#color_wrapper_text");
    $scope.content.additionalInfo.backgroudColor = color_wrapper_back.style.backgroundColor;
    $scope.content.additionalInfo.fontColor = color_wrapper.style.backgroundColor;
    //if (checkSetting()) {
      $modalInstance.close($scope.content);
      $modalInstance.dismiss('done');
    //}
  }
  $scope.cancel = function() {

    //変更されてないか確認
    {
      console.log(m_additionalInfo_back);

      var same = _.isEqual(m_additionalInfo_back,$scope.content.additionalInfo);
      if (same) {
        let color_wrapper_back=document.querySelector("#color_wrapper_back");
        let color_wrapper=document.querySelector("#color_wrapper_text");
        if ($scope.content.additionalInfo.backgroudColor !== color_wrapper_back.style.backgroundColor ) {
          same = false;
        }
        else if ($scope.content.additionalInfo.fontColor !== color_wrapper.style.backgroundColor ) {
          same = false;
        }

        if ($scope.fontInfo.selected) {
          if (m_additionalInfo_back.font !== $scope.fontInfo.selected.fontFamily) {
            same = false;
          }
        }
      }

    }
    if (!same) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: "編集内容を保存せずに終了しますか？",
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (yes) {
          $modalInstance.dismiss('cancel');
        }
      })
    } else {
      $modalInstance.dismiss('cancel');

    }


  }

  $scope.onTextchange = function() {
    $scope.content.additionalInfo.text = $scope.content.additionalInfo.text.replace(/[\r\n\t\v\f]/g, "");
  }


  $scope.handleKeydown = function(event) {
    //console.log("handleKeydown");

    var stopDefaultAction = false;
    if ((event.key === "Enter")) { //enter
      stopDefaultAction = true;
    }

    if(event.preventDefault && stopDefaultAction){
      // デフォルトの動作を無効化する
      event.preventDefault();
    }
  }
  //キーバインド
  angular.element($window).on('keydown', $scope.handleKeydown);

  $scope.$on('$destroy', function () {
    angular.element($window).off('keydown', $scope.handleKeydown);
  });

}]);
