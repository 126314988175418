'use strict';

angular.module('limaApp')
.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('transcodepreset', {
    url: '/content/transcode/preset?navbar',
    templateUrl: 'app/content/transcode/preset/preset.html',
    controller: 'TranscodepresetCtrl',
    title: 'TranscodePreset',
    authenticate: true,
    functionName: 'ContentManagement' //権限：コンテンツ管理ページと同じにしておく
  });
}]);
