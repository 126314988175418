'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('channel', {
    url: '/channel',
    templateUrl: 'app/channel/channel.html',
    controller: 'ChannelCtrl',
    title: 'チャンネル一覧',
    authenticate: true
  });
}]);
