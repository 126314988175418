'use strict';

var app = angular.module('limaApp');

app.controller('AdduserCtrl', ['$scope', '$location', '$translate', '$uibModalInstance', '$q', 'Modal', 'socket',
function ($scope, $location, $translate, $modalInstance, $q, Modal, socket) {

  $scope.multiSelectTranslations = {
    selectAll: $translate.instant('MULTI_SELECT.SELECT_ALL'),
    selectNone: $translate.instant('MULTI_SELECT.SELECT_NONE'),
    reset: $translate.instant('MULTI_SELECT.RESET'),
    search: $translate.instant('MULTI_SELECT.SEARCH'),
    // nothingSelected: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
    nothingSelected: '未選択'
  };

  function getUserName(name) {
    if (name === 'administrator') {
      return $translate.instant('USER.USER_ADMINISTRATOR');
    }
    else if (name === 'manager') {
      return $translate.instant('USER.USER_MANAGER');
    }
    else if (name === 'user') {
      return $translate.instant('USER.USER_USER');
    }
    else if (name === 'viewer') {
      return $translate.instant('USER.USER_VIEWER');
    }
    return name;
  }

  socket.connect().then(function () {
    return socket.call('v1:user:group:findAll', {functions:true});
  }).then(function (res) {
    _.forEach(res, function (group) {
      // group.escapedName = _.escape(group.name);
      group.escapedName = getUserName(group.name);
    });
    $scope.groups = res;
  });

  $scope.selectedGroups = [];
  $scope.newuser = {effective:true};
  $scope.createUser = function(form) {
    $scope.submitted = true;
    if(!form.$valid || $scope.newuser.password !== $scope.newuser.retypePassword) {
      return;
    }
    var user = _.cloneDeep($scope.newuser);
    user.effective = ($scope.newuser.effective) ? 1 : 0;
    user.groups = _.map(_.filter($scope.selectedGroups, {ticked:true}), function (group) {
      return {id:group.id};
    });
    socket.connect().then(function () {
      return socket.call('v1:user:account:create', {user:user});
    }).then(function (res) {
      $modalInstance.close();
    }, function (err) {
      var data = (_.isObject(err) && err.data) ? err.data : err;
      var showRawErr = true;
      if (_.has(data, 'errors')) {
        _.forEach(data.errors, function(error) {
          if (_.has(error, 'code')) {
            if (error.code === 'ER_DUP_ENTRY') {
              $scope.isUsernameDuplicate = true;
              showRawErr = false;
            }
          }
          if (error.message.substr(0, 22) === 'does not match pattern') {
            $scope.isUsernameInvalid = true;
            showRawErr = false;
          }
        });
      }
      if (showRawErr) {
        $scope.msg_error = data;
      }
    });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.$watch('newuser.name', function () {
    $scope.isUsernameDuplicate = false;
    $scope.isUsernameInvalid = false;
  });

}]);
