'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('playlistSetting', {
    url: '/playlist/setting',
    templateUrl: 'app/playlist/setting/setting.html',
    controller: 'PlaylistSettingCtrl',
    title: '設定',
    authenticate: true
  });
}]);
