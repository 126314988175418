'use strict';

var app = angular.module('limaApp');
var async = require('async');

app.directive('limaEmailValidationPattern', [
function () {
  return {
    restrict: 'A',
    priority: 1,
    require: 'ngModel',
    link: function (scope, element, attrs, ctrl) {
      if (!ctrl || !ctrl.$validators || !ctrl.$validators.email || !attrs.limaEmailValidationPattern) {
        return;
      }
      var regex = new RegExp(attrs.limaEmailValidationPattern);
      ctrl.$validators.email = function (value) {
        return ctrl.$isEmpty(value) || (typeof value === 'string' && regex.test(value));
      };
    }
  };
}]);

app.filter('limaDisplayBytes', ['$filter',
function($filter) {
  return function(bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
    if (bytes === 0) return '0 bytes';
    if (typeof precision === 'undefined') precision = 1;
    var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    var number = Math.floor(Math.log(bytes) / Math.log(1024));
    if (number === 0) precision = 0;
    return $filter('number')(bytes / Math.pow(1024, Math.floor(number)), precision) +  ' ' + units[number];
  }
}]);

app.filter('limaDurationText', ['$translate',
function ($translate) {
  return function (duration, scale, omit) {
    try {
      scale = scale || 1000000; // micro sec.
      omit = omit || false;
      if (typeof duration === 'string') duration = parseInt(duration);
      if (typeof duration !== 'number' || isNaN(duration)) return 'N/A';
      var sign = '';
      if (duration < 0) {sign = '-'; duration = -duration; }
      duration = Math.ceil(duration / scale);
      var hh = Math.floor(duration / 3600);
      var mm = Math.floor((duration -= hh * 3600) / 60);
      var ss = duration -= mm * 60;
      var text = '';
      if (hh) {
        text += hh + $translate.instant('UNIT.HOUR');
        if (!omit && !mm) mm = '0';
        if (!omit && !ss) ss = '0';
      }
      if (mm) {
        text += mm + $translate.instant('UNIT.MINUTE');
        if (!omit && !ss) ss = '0';
      }
      if (!text && !ss) {
        sign = '';
        ss = '0';
      }
      if (ss) {
        text += ss + $translate.instant('UNIT.SECOND');
      }
      return sign + text;
    } catch (ex) {
      return '';//'N/A';
    }
  };
}]);

app.filter('limaDurationHHMMSS', [
function () {
  return function (duration, scale) {
    try {
      scale = scale || 1000000; // micro sec.
      if (typeof duration === 'string') duration = parseInt(duration);
      if (typeof duration !== 'number' || isNaN(duration)) return 'N/A';
      var sign = '';
      if (duration < 0) {sign = '-'; duration = -duration; }
      duration = Math.ceil(duration / scale);
      var hh = Math.floor(duration / 3600);
      var mm = Math.floor((duration -= hh * 3600) / 60);
      var ss = duration -= mm * 60;
      if (hh > 0) return sign + hh + ':' + ('0' + mm).slice(-2) + ':' + ('0' + ss).slice(-2);
      return sign + mm + ':' + ('0' + ss).slice(-2);
    } catch (ex) {
      return '';//'N/A';
    }
  };
}]);

app.filter('limaDateTime', [
function () {
  return function (date, time, options) {
    var d = null;
    if (!date || !(date instanceof Date)) {
      return null;
    } else if (time === null) {
      d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } else if (time instanceof Date) {
      d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
    } else {
      d = new Date(date);
      if (typeof time === 'object') options = time;
    }
    if (!(d instanceof Date) || d.toString() === 'Invalid Date') {
      return null;
    }
    options = options || {};
    var add = options.add || {};
    if (add.year && typeof add.year === 'number') d.setFullYear(d.getFullYear() + add.year);
    if (add.month && typeof add.month === 'number') d.setMonth(d.getMonth() + add.month);
    if (add.date && typeof add.date === 'number') d.setDate(d.getDate() + add.date);
    if (add.hour && typeof add.hour === 'number') d.setHours(d.getHours() + add.hour);
    if (add.minute && typeof add.minute === 'number') d.setMinutes(d.getMinutes() + add.minute);
    if (add.second && typeof add.second === 'number') d.setSeconds(d.getSeconds() + add.second);
    if (add.millisecond && typeof add.millisecond === 'number') d.setMilliseconds(d.getMilliseconds() + add.millisecond);
    var truncate = options.truncate || {millisecond:true};
    if (truncate.month === true) d = new Date(d.getFullYear(), 0, 1);
    else if (truncate.date === true) d = new Date(d.getFullYear(), d.getMonth(), 1);
    else if (truncate.hour === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    else if (truncate.minute === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), 0, 0);
    else if (truncate.second === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0);
    else if (truncate.millisecond === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
    return d;
  };
}]);


app.filter('limaFormatDate', [
  function () {
    return function (date, format) {
      if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
      format = format.replace(/YYYY/g, date.getFullYear());
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
      format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
      if (format.match(/S/g)) {
        var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
        var length = format.match(/S/g).length;
        for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
      }
      return format;
    };
  }
]);


app.factory('limaSocketService', ['socket', 'limaDiskService', 'limaOptionService',
function LimaSocketService(socket, limaDiskService, limaOptionService) {
  return {
    emitChannel: function (param, callback) {
      limaOptionService.getOptionService(function(result) {
        var optionService = result;

        var transferMaxSize = limaOptionService.getTransferMaxSize(optionService)
        limaDiskService.getTransferInfo(function(result){

          param.enableDownload = true;
          if (result.size > transferMaxSize) {
            param.enableDownload = false;
          }

          socket.emit('lima:call-request:v1:device:channel',param);

          callback(null);
        });

      });

    }
  }
}]);


app.factory('limaOptionService', ['$http', 'Auth',
function LimaOptionService($http, Auth) {
  return {
    getTransferMaxSize: function (optionService) {
      var findBase = _.find(optionService, function(oc){
        return oc.name === 'base' ? true : false;
      });
      var find = _.find(optionService, function(op){
        return op.name === 'DataTransferAmount1G' ? true : false;
      });
      var size=1*1024*1024*1024 * findBase.amount;
      if (find) {
        size+=find.amount*1024*1024*1024;
      }
      return size;
    },
    modifyOption:function(options, packageType , callback){
      var options_modified = [];
      $http.get('/api/v1/option-service').
      success(function(datas) {
        var optionsAdd = [];
        if (packageType === 1) {
          optionsAdd = _.filter(datas,function(dt){
            if (dt.name === 'SampleLayout') {
              return false;
            }
            if (dt.perDevice === 1 ) {
              return true;
            }
            if (dt.name === 'Support_By_Mail') {
              return true;
            }
          });
        }
        options_modified =  options;
        if (optionsAdd.length) {
          _.forEach(optionsAdd, function(itemAdd){
            var find = _.find(options, function(op){
              return op.optionServiceId === itemAdd.optionServiceId;
            });
            if (!find){
              itemAdd.optionId = itemAdd.optionServiceId;
              options_modified.push(itemAdd);
            }
          });
        }
        callback(options_modified);
      });
    },
    getpackageType: function(callback){
      if (!Auth.isLoggedIn()){
        callback(null);
        return;
      }
      var scope = this;
      $http.get('/api/v1/users/me').
      success(function(datas) {
        var userinfo=datas;
        var user;
        $http.get('/api/v1/option-service/package/' + userinfo.companyId).
        success(function(resPackage) {
          var packageType = 0;
          if (resPackage && resPackage.length) {packageType = resPackage[0].packageType;}
          callback({packageType:packageType});
        });
      });
    },
    getOptionService: function (callback) {
      if (!Auth.isLoggedIn()){
        callback(null);
        return;
      }
      var scope = this;
      $http.get('/api/v1/users/me').
      success(function(datas) {
        var userinfo=datas;
        var user;
        $http.get('/api/v1/option-service/package/' + userinfo.companyId).
        success(function(resPackage) {
          var packageType = 0;
          if (resPackage && resPackage.length) {packageType = resPackage[0].packageType;}
          $http.get('/api/v1/option-service/' + userinfo.companyId).
          success(function(datas) {
            scope.modifyOption(datas,packageType , function(datas_modified){
              callback(datas_modified); //契約情報
            })
          });
        });
      });
    },
    isOverDeviceAllowed(callback){
      $http.get('/api/v1/device/device').
      success(function(datas) {
        var devicelists = datas;
        this.getOptionService(function(result) {
          var find = _.find(result, function(op){
            return op.name === 'base' ? true : false;
          });
          if (find) {
            var enableDeviceLimit = find.amount;
            var allowedDevices = _.find(devicelists, function(dv){
              return dv.allowed;
            });
            if (allowedDevices) {
              if (allowedDevices.length > enableDeviceLimit) {
                callback(true);
              } else {
                callback(false);
              }
            }
          } else {
            callback(false);
          }
        });
      }).
      error(function(result) {
        callback(false);
      });
    }
  };
}]);

app.factory('limaDeviceService', ['$http','limaOptionService',
function LimaOptionService($http, limaOptionService) {
  return {
    isOverDeviceAllowed(callback){
      $http.get('/api/v1/device/device').
      success(function(datas) {
        var devicelists = datas;
        limaOptionService.getOptionService(function(result) {
          var find = _.find(result, function(op){
            return op.name === 'base' ? true : false;
          });
          if (find) {
            var enableDeviceLimit = find.amount;
            var allowedDevices = _.filter(devicelists, function(dv){
              return dv.allowed;
            });
            if (allowedDevices) {
              if (allowedDevices.length > enableDeviceLimit) {
                callback(true);
              } else {
                callback(false);
              }
            }
          } else {
            callback(false);
          }
        });
      }).
      error(function(result) {
        callback(false);
      });
    }
  };
}]);


app.factory('limaPlaylistService1', ['$http', '$q',
function LimaPlaylistService($http, $q) {
  return {

    createPlaylistHTML: function (option,callback) {
      //selectLayoutIndex
      //contentsInfo
      //name
      var playlistId = option.playlistId;
      var selectedTw = _.filter(option.contentsInfo, function(tw){return tw.checked;});
      var params = option.contentsInfo;
      // var params = [];
      // _.forEach(selectedTw, function(item){
      //   var data = {
      //     contentId: item.id
      //   };
      //   .push(data);
      // })

      if (_.isUndefined( option.selectLayoutIndex)) {
        console.log( "option.selectLayoutIndex undefined" );
        callback(null);;
      } else {
        var postdata = {
          //channel: option.name,
          layoutId: option.selectLayoutIndex,
          contentInfo: params,
          temporary: false,
          playlist: playlistId,
          playlistSetting: option.playlistSetting,
          name: option.name,
          regions : option.regions
        }
        $http.post('/api/v1/playlists/html', postdata)
        .success(function(res) {
          console.log(res.html);
          if (callback) {
            callback(playlistId);
          }
        });

      }

    }
  };
}]);

app.factory('limaPlaylistService', ['$http', '$q', 'limaPlaylistService1',
function LimaPlaylistService($http, $q, limaPlaylistService1) {
  return {
    UpdatePlaylistHTML: function(playlistId, playlists) {
      var defer = $q.defer();

      var option = {};
      var find = _.find(playlists, function(p){return p.playlistId ===playlistId;});

      $http.get('/api/v1/playlists/' + playlistId + '/contents').
      success(function(cont) {
        option.contentsInfo = _.sortBy(cont, function(item) {
          return item.playIndex;
        });
        option.contentsInfo = _.filter(option.contentsInfo, function(c){
          if (c.enabled) {
            return true;
          }
        });

        $http.get('/api/v1/regions').
        success(function(rg_datas) {

          var regions = [];
          _.forEach(rg_datas.regions, function(rg) {
            //if (rg.contentsRegionInfo.length)
            {
              rg.contentsInfo = _.filter(rg.contentsRegionInfo , function(rg_con) {
                if (rg_con.playlistId === playlistId){
                  rg_con.id = rg_con.contentId;
                  if ( !_.isObject(rg_con.additionalInfo)) {
                    if (rg_con.additionalInfo === "null") {
                      delete rg_con.additionalInfo;
                    } else {
                      rg_con.additionalInfo = JSON.parse(rg_con.additionalInfo);

                    }
                  }
                  return true;
                };
              });
              if(rg.contentsInfo && rg.contentsInfo.length){
                  var rg_push = _.cloneDeep(rg);
                  rg_push.contentsRegionInfo = [];
                  regions.push(rg_push);
              } else if(find.layoutId === rg.layoutId){
                var rg_push = _.cloneDeep(rg);
                rg_push.contentsInfo = [];
                rg_push.contentsRegionInfo = [];
                regions.push(rg_push);
              }
            }
          })




          option.regions = regions;
          option.playlistId = playlistId;
          option.selectLayoutIndex = find.layoutId;
          option.name = find.playlistName;
          option.playlistSetting = find.setting;
          limaPlaylistService1.createPlaylistHTML(option,function(res){
            defer.resolve();
          });
        });
      }).
      error(function(cont) {
        option.contentsInfo = _.sortBy(cont, function(item) {
          return item.playIndex;
        });
        option.contentsInfo = _.filter(option.contentsInfo, function(c){
          if (c.enabled) {
            return true;
          }
        });

        option.playlistId = playlistId;
        option.selectLayoutIndex = find.layoutId;
        option.name = find.playlistName;
        option.playlistSetting = find.setting;
        limaPlaylistService1.createPlaylistHTML(option,function(res){
          defer.resolve();
        });

      });

      return defer.promise;


    }

  };
}]);


app.factory('limaDiskService', ['$http','$filter',
function LimaDiskService($http,$filter) {
  return {
    getTransferWarningRemainSize: function() {
      return 50*1024*1024;  //50M
    },
    getTransferInfo: function (callback) {
      $http.get('/api/v1/device/transfer').
      success(function(datas) {

        var size = 0;
        var size_autoUpdated = 0;
        _.forEach(datas, function(dv){
          size += dv.transferedSize;
          size_autoUpdated += dv.autoUpdatedTransferedSize
        });
        var size_calc = size - size_autoUpdated
        if (size_calc < 0) {
          size_calc = 0;
        }
        callback({size:size_calc, size_autoUpdated:size_autoUpdated});

      });

    },

    getDiskInfo: function (callback) {
      var result = {};
      result.awsDiskUsageInMB = {};
      result.diskUsageInMB = {};
      result.awsDiskUsageInMB.licensed = 0;
      $http.get('/api/v1/configurations/AWSS3UploadServer').success(function (resAws) {
        if (resAws.enabled) {
          $http.get('/api/v1/system-info/aws').then(function (res) {
            //res.data.totalSize : kByte
            result.awsDiskUsageInMB.use = {size: $filter('number')(Math.round(res.data.totalSize / 1024)), sizeByte:res.data.totalSize*1024};
            //$scope.awsdiskUsageInMB.available = {size: $filter('number')(Math.round(info.diskUsageInKB.available / 1024))};
            callback(result);
          });
        } else {
          $http.get('/api/v1/system-info').then(function (res) {
            var info = res.data;
            result.diskUsageInMB.total = {size: $filter('number')(Math.round(info.diskUsageInKB.total / 1024)), sizeByte:res.data.totalSize*1024};
            result.diskUsageInMB.use = {size: $filter('number')(Math.round(info.diskUsageInKB.usage / 1024)), sizeByte:res.data.totalSize*1024};
            callback(result);
          });
        }
      });
    }
  };
}]);

app.factory('limaShowWaitDialog', ['$uibModal','$sce',
function LimaShowWaitDialog($modal,$sce) {
  return {
    hideWait : function(){
      if (this.modalInstance) {
        this.modalInstance.close();
        this.modalInstance = null;
      }
    },
    showWait: function (scopeParent,headerTitle , title) {
      var scope = {modal: {
        dismissable: false,
        title: headerTitle,
        html: '<div><span class="glyphicon glyphicon-refresh glyphicon-refresh-animate")></span>' + '  ' + title + '...' + '</div>'
      }};

      var modalScope = scopeParent.$new();
      scope = scope || {};
      scope.trustAsHtml = scope.trustAsHtml || function (s) { return $sce.trustAsHtml(s); };
      var modalClass = 'modal-warning';

      angular.extend(modalScope, scope);

      this.modalInstance = $modal.open({
        templateUrl: 'components/modal/modal.html',
        windowClass: modalClass,
        backdrop: 'static', keyboard: false,
        size: 'sm',
        scope:modalScope
      });
      this.modalInstance.result.then(function(results) {
      }, function() {
        //閉じられた
  //        console.log("aaa");
      });


    }
  };
}]);

app.factory('limaAppUtil', ['$http','$q',
function limaAppUtil($http, $q) {
  return{
    rgbaToHex : function (fg_color, bg_color)
    {
      // HEXに変換したものを代入する変数
      var hex = '#';

      // 第1引数がHEXのとき変換処理は必要ないのでそのままreturn
      // IE8の場合はjQueryのcss()関数でHEXを返すので除外
      if (fg_color.match(/^#[a-f\d]{3}$|^#[a-f\d]{6}$/i))
      {
        return fg_color;
      }

      // 第1引数の正規表現
      var regex = fg_color.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*?(?:\.?\d+))?\)$/);

      // 正規表現でマッチしたとき
      if (regex)
      {
        // 前景色のRGB成分
        var rgb =
        [
          parseInt(regex[1]),
          parseInt(regex[2]),
          parseInt(regex[3])
        ];
        // 透明度
        var alpha = parseFloat(regex[4]);
        // 背景色のRGB成分（第2引数が省略されたときのデフォルト値）
        var rgb_bg = [255, 255, 255];


        for (var i = 0; i < rgb.length; ++i)
        {
          // 背景色を考慮した透明度による計算
          var tmp =rgb[i];

          // rgb値は0から255の範囲なので超えた場合は255にする
          if (tmp > 255)
          {
            tmp = 255;
          }
          // HEXに変換
          tmp = tmp.toString(16);

          // rgb(1,1,1)のようなときHEXに変換すると1桁になる
          // 1桁のときは前に0を足す
          if (tmp.length == 1)
          {
            tmp = '0' + tmp;
          }
          hex += tmp;
        }
        return {hex : hex , alpha : alpha };

        return hex;
      }


    },
    rgbToHex : function (color)
    {
      // HEXに変換したものを代入する変数
      var hex = '#';

      // 第1引数がHEXのとき変換処理は必要ないのでそのままreturn
      // IE8の場合はjQueryのcss()関数でHEXを返すので除外
      if (color.match(/^#[a-f\d]{3}$|^#[a-f\d]{6}$/i))
      {
        return color;
      }

      // 正規表現
      var regex = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

      // 正規表現でマッチしたとき
      if (regex)
      {
        var rgb =
        [
          // RGBからHEXへ変換
          parseInt(regex[1]).toString(16),
          parseInt(regex[2]).toString(16),
          parseInt(regex[3]).toString(16)
        ];

        for (var i = 0; i < rgb.length; ++i)
        {
          // rgb(1,1,1)のようなときHEXに変換すると1桁になる
          // 1桁のときは前に0を足す
          if (rgb[i].length == 1)
          {
            rgb[i] = '0' + rgb[i];
          }
          hex += rgb[i];
        }
        return {hex : hex , alpha : 1};

        return hex;
      }


    }

  };
}]);

app.factory('ContentUtil', ['$http','$q',
function ContentUtil($http, $q) {
  return{
    CONTENT_NORMAR : 0,
    CONTENT_LIVE : 1,
    CONTENT_URL : 2,
    CONTENT_AUTOUPDATED : 3,

    CONTENT_SHOWTYPE_ALL: 0,
    CONTENT_SHOWTYPE_STILL : 1,
    CONTENT_SHOWTYPE_VIDEO : 2,
    CONTENT_SHOWTYPE_URL : 3,
    CONTENT_SHOWTYPE_YOUTUBE : 4,
    CONTENT_SHOWTYPE_TELOP : 5,
    CONTENT_SHOWTYPE_LIVE : 6,
    CONTENT_SHOWTYPE_AUTOUPDATED  : 7,


    CONTENT_SHOWTYPE_NAME_STILL : "",
    CONTENT_SHOWTYPE_NAME_VIDEO : "",
    CONTENT_SHOWTYPE_NAME_URL : "url",
    CONTENT_SHOWTYPE_NAME_YOUTUBE : "youtube",
    CONTENT_SHOWTYPE_NAME_TELOP : "",
    CONTENT_SHOWTYPE_NAME_LIVE : "live",
    CONTENT_SHOWTYPE_NAME_AUTOUPDATED: "autoupdated",

    sliceByNumber: function(array, number){
      // 元の配列(今回で言うと変数arrayを指します)を基に、分割して生成する配列の個数を取得する処理です。
      // 今回は元の配列の要素数が10個、分割して生成する配列は2つの要素を持つことを期待しています。
      // 上記のことから今回は、元の配列から5つの配列に分割されることになります。
      const length = Math.ceil(array.length / number);

      // new Arrayの引数に上記で取得した配列の個数を渡します。これで配列の中に5つの配列が生成されます。
      // 5つの配列分だけループ処理(mapメソッド)をします。map処理の中でsliceメソッドを使用して、元の配列から新しい配列を作成して返却します。
      // sliceメソッドの中では、要素数2つの配列を生成します。
      // fillメソッドはインデックスのキーを生成するために使用しています。もし使用しない場合はmapメソッドはindexがないため、mapメソッドが機能しません。
      return new Array(length)
        .fill()
        .map((_, i) => array.slice(i * number, (i + 1) * number));
    },

    getdbTimezone: function(callback) {
      $http.get('/api/v1/system-info/db').
      success(function(datas) {
        //console.log(datas);
        _.find(datas,function(dt){
          if (dt.Variable_name === 'system_time_zone') {
            callback(dt.Value);
            return true;
          }
        });
      });
    },

    //contentIdの列挙から詳細なContent情報を取る
    doSerachFromMaterialFromId_uploaded:function(datas, callback) {
      {
        var dataMaterials = [];

        var deferred = $q.defer();
        var promise = deferred.promise;

        var maxLen = 50;
        var dataIds = [];
        _.forEach(datas, function(item){
          dataIds.push(item.id);
        });
        var arrForAPI = this.sliceByNumber(dataIds , maxLen)

        var requests = _.forEach(arrForAPI, function(item){
          promise = promise.finally(function () {
            return $http
              .post('/api/v1/content/contents/ids' , {ids:item})
              .success(function(resArrMat) {
                _.forEach(resArrMat , function(resMat){
                  if (!_.isUndefined(item.checked)) {
                    resMat.checked = item.checked;
                  } else if (!_.isUndefined(item.enabled)) {
                    resMat.checked = item.enabled;
                  }
                  if (!_.isUndefined(item.fixedRegionId)) {
                    resMat.fixedRegionId = item.fixedRegionId;
                  }
                  dataMaterials.push(resMat);
                });
              })
              .error(function() {
                if (item.id === "-exinput") {
                  dataMaterials.push(item);
                }
              })
              .finally(function () {
              });
          });
        });
        promise = promise.finally(function () {
          console.log('end get Materials');

        // doSerachFromMaterial(dataMaterials);
        // $timeout(function(){$scope.$apply()});
        if (callback) {
            callback(dataMaterials);
        }

        });

        deferred.resolve();

      }
    },

    //指定された種類のコンテンツををAPIからとってくる
    /*
    type : uploaded , autoupdated
    type2 : /line/ とかautoupdated向けの絞り込み用
    */
    doSearchContent:function(option , callback) {

      var thisObj = this;

        var contents_all = [];

        //最新のでソート
        var params= {
          order: {'registeredDate':'desc'}
        };

        var enableAutoupdated = false;
        if (option.type === "autoupdated") {
          enableAutoupdated = true;
        }

        if (enableAutoupdated) {
          params.type = ContentUtil.CONTENT_AUTOUPDATED;  //autoupdated
          //params.type2 = enableAutoupdated.type2
        }

        $http.get('/api/v1/content/contents' , {params:params}).
        success(function(datas) {
          if (enableAutoupdated) {
            datas = _.filter(datas, function(dt) {
              return _.find(option.type2, function(tt) {
                return dt.imageUrl.indexOf(tt) !== -1;
              })
            });
            var arrNames = [];
            _.forEach(datas , function(dt) {
              var aa = dt.title.replace('(' , '?');  //LINE(1)=>LINE?1?=>split:LINE 1 space
              aa = aa.replace(')' , '?');
              var arrA = aa.split("?");
              if (arrA.length) {
                var find = _.find(arrNames , function(name) {
                  return name.indexOf(arrA[0]) !== -1;
                });
                if (!find) {
                  arrNames.push(arrA[0]);
                }
              }
            })

            var datas_all = [];
            _.forEach(arrNames , function(name) {
              var datas_name = _.filter(datas , function(dt) {
                return dt.title.indexOf(name) !== -1;
              })

              //名前でソートする
              datas_name = datas_name.sort(function(a, b) {
                var aa = a.title.replace('(' , '?');  //LINE(1)=>LINE?1?=>split:LINE 1 space
                aa = aa.replace(')' , '?');
                var arrA = aa.split("?");
                var bb = b.title.replace('(' , '?');
                bb = bb.replace(')' , '?');
                var arrB = bb.split("?");
                if (arrA.length===3 && arrB.length===3){
                  return Number(arrA[1]) -  Number(arrB[1]);
                } else {
                  return (a.title < b.title) ? -1 : 1;  //オブジェクトの昇順ソート
                }
              });
              datas_all = datas_all.concat(datas_name);

            })
            datas = datas_all;
          }
          thisObj.doSerachFromMaterialFromId_uploaded(datas , function(infos) {
            if (enableAutoupdated) {
              contents_all = infos;
            } else {
              contents_all = _.filter(infos , function(cont){
                return cont.type !== 3; //auto_update以外
              });
            }

            thisObj.getdbTimezone(function(dbtimezoneString){
              if (dbtimezoneString === 'UTC') {  //S3 AURORA
                _.forEach(contents_all, function(cont){
                  //UTC->SYSTEM
                  const defaultTimezoneOffset = new Date().getTimezoneOffset()*-1;
                  var dt = new Date(cont.updatedTime);
                  dt.setMinutes(dt.getMinutes() + defaultTimezoneOffset);
                  cont.updatedTime = dt;
                });
              }
              if (callback) {
                callback(contents_all);
              }
            });
          })
        });

    },

    isAutoupdated : function(content) {
      return content.type === 3
    },
    getFileformatName : function(content) {
      if (content.files) {
        if (content.files.length) {
          if (!_.isUndefined(content.files[0].formatName)) {
            return content.files[0].formatName;
          }
        }
      }
      return '';
    },

    getCodecName : function(content) {
      if (content.files) {
        if (content.files && content.files.length ) {
          if (content.files[0].videoStreams) {
            if (content.files[0].videoStreams.length) {
              return content.files[0].videoStreams[0].codecName;
            }
          }
        }
      }
      return '';
    },

    getImageCodecName : function(content) {
      if (this.isImage(content)) {
        var name = this.getCodecName(content);
        if (name.toLowerCase() === "mjpeg"){
          return "JPEG";
        }
        if (name.toLowerCase() === "png"){
          return "PNG";
        }
      }
      return "";
    },

    isLive : function(item) {
      if (this.isHTML(item) ) {
        if (item.urlContents.length) {
          if (item.urlContents[0].additionalInfo) {
            if (item.urlContents[0].additionalInfo.type==='live') {
              return true;
            }
          }
        }
      }
    },

    isExInput : function(item) {
      return item.id === "-exinput";
    },

    isText : function(content) {
      if (this.getFileformatName(content).indexOf("tty") !== -1) {
          return true;
      }
      return false;
    },


    isImage : function(content) {
      if (this.getFileformatName(content).indexOf("image") !== -1) {
          return true;
      }
      if (this.getFileformatName(content).indexOf("png") !== -1) {
        return true;
      }
      return false;
    },

    isImage4K : function(content) {
      if (this.getFileformatName(content).indexOf("image") !== -1) {
        if (content.additionalInfo) {
          if (content.additionalInfo.enable4KImage) {
            return true;
          }
        }
      }
      // if (this.getFileformatName(content).indexOf("image") !== -1) {
      //   if (content.files && content.files.length ) {
      //     if (content.files[0].videoStreams) {
      //       if (content.files[0].videoStreams.length) {
      //         if ((content.files[0].videoStreams[0].width >= 3840 ) &&
      //             (content.files[0].videoStreams[0].height >= 2160 ) ){
      //           return true;
      //         }
      //         else if ((content.files[0].videoStreams[0].width >= 2160 ) &&
      //             (content.files[0].videoStreams[0].height >= 3840 ) ){
      //           return true;  //縦4K
      //         }
      //       }
      //     }
      //   }
      //   return false;
      // }
      return false;
    },
    isImage4KSize : function(content) {
      if (this.getFileformatName(content).indexOf("image") !== -1) {
        if (content.files && content.files.length ) {
          if (content.files[0].videoStreams) {
            if (content.files[0].videoStreams.length) {
              if ((content.files[0].videoStreams[0].width >= 3840 ) &&
                  (content.files[0].videoStreams[0].height >= 2160 ) ){
                return true;
              }
              else if ((content.files[0].videoStreams[0].width >= 2160 ) &&
                  (content.files[0].videoStreams[0].height >= 3840 ) ){
                return true;  //縦4K
              }
            }
          }
        }
        return false;
      }
      return false;
    },


    isVideo : function(content) {
      if (this.getFileformatName(content).indexOf("mov") !== -1) {
          return true;
      }
      // if (this.isHTML(content)) {
      //   var youtubeUrl = this.getYouTubeVideoUrl(content.urlContents[0].sourceURL);
      //   if (youtubeUrl.length) {
      //     return true;
      //   }
      // }
      return false;
    },

    isHTML_HTTP : function(content) {
      if (this.isHTML(content)) {
        if (content.urlContents) {
          if (content.urlContents.length) {
            if (content.urlContents[0].sourceURL) {
              if (content.urlContents[0].sourceURL.indexOf("http://") !== -1 ) {
                return true;
              }
            }
          }
        }
        return false;
      }

    },

    isHTML : function(content) {
      if (!content) {
        return false;
      }
      if (content.urlContents) {
        if (content.urlContents.length) {
          if (content.urlContents[0].sourceURL) {
            return content.urlContents[0].sourceURL.length ? true : false;
          }
        }
      }
      return false;
    },
    isYoutube : function(content){
      if (this.isHTML(content) ) {
        if (content.urlContents.length) {
          if (content.urlContents[0].additionalInfo) {
            if (content.urlContents[0].additionalInfo.type==='youtube') {
              return true;
            }
          }
        }
      }
    },

    isYoutubeLive : function(content){
      if (this.isHTML(content) ) {
        if (content.urlContents.length) {
          if (content.urlContents[0].additionalInfo) {
            if (content.urlContents[0].additionalInfo.type==='youtube') {
              if (content.urlContents[0].additionalInfo.info) {
                if (content.urlContents[0].additionalInfo.info.isLive) {
                  return true;
                }
              }
            }
          }
        }
      }
    },
    //YouTubeのURLチェック
    getValidYoutTubeUrl : function(string) {
      const shortYouTubePattern = "https://www.youtube.com/shorts/";
      const embedYouTubePattern = "https://www.youtube.com/embed/";
      const watchYouTubePattern = "https://www.youtube.com/watch?v=";

      if (string.indexOf(shortYouTubePattern) !== -1 ) {
        return string.replace(shortYouTubePattern , watchYouTubePattern);
      }
      else if (string.indexOf(embedYouTubePattern) !== -1 ) {
        return string.replace(embedYouTubePattern , watchYouTubePattern);
      }
      return string;
    },

    getAutoUpdatedTypeName : function(item) {
      if (this.isAutoupdated(item)) {
        if (item.autoUpdated.length) {
          if (item.autoUpdated[0].type === 'nds') {
            return 'マーカス';
          }
          if (item.autoUpdated[0].type.indexOf('kyodo') !== -1) {
            return 'マーカス';
          }
          if (item.autoUpdated[0].type === 'twitter') {
            return 'ついっとサイン';
          }
          if (item.autoUpdated[0].type === 'line') {
            return 'LINE';
          }
      }
      }
      return '';
    },

    getYouTubeVideoEmbedUrl: function(url){
      //WebのURL
      //https://www.youtube.com/watch?v=coYw-eVU0Ks
      //動画のURL（動画のURL、右クリック）
      //https://youtu.be/coYw-eVU0Ks
      //動画の埋め込みコードから取れるURL
      //https://www.youtube.com/embed/coYw-eVU0Ks

      var videoId = "";
      var isYoutube = false;
      var searchString = "https://www.youtube.com/watch?v=";
      if (url.indexOf(searchString) != -1) {
        isYoutube = true;
        videoId = url.replace(searchString, "");
        if (videoId.length) {
          //return "https://youtu.be/" + videoId;
          return "https://www.youtube.com/embed/" + videoId + "?showinfo=0&controls=0&autoplay=1&loop=1&playlist=" + videoId;
        }
      }
      searchString = "https://youtu.be/";
      if (url.indexOf(searchString) != -1) {
        isYoutube = true;
        videoId = url.replace(searchString, "");
        if (videoId.length) {
          //return "https://youtu.be/" + videoId;
          return "https://www.youtube.com/embed/" + videoId + "?showinfo=0&controls=0&autoplay=1&loop=1&playlist=" + videoId;
        }
      }
      searchString = "https://www.youtube.com/embed/";
      if (url.indexOf(searchString) != -1) {
        isYoutube = true;
        videoId = url.replace(searchString, "");
        if (videoId.length) {
          //return "https://youtu.be/" + videoId;
          return "https://www.youtube.com/embed/" + videoId + "?showinfo=0&controls=0&autoplay=1&loop=1&playlist=" + videoId;
        }
      }
      searchString = "https://www.youtube.com/shorts/";
      if (url.indexOf(searchString) != -1) {
        isYoutube = true;
        videoId = url.replace(searchString, "");
        if (videoId.length) {
          //return "https://youtu.be/" + videoId;
          return "https://www.youtube.com/embed/" + videoId + "?showinfo=0&controls=0&autoplay=1&loop=1&playlist=" + videoId;
        }
      }
      return "";  //見つからない：Youtubeじゃない
    },

    getYouTubeVideoUrl: function(url){
      //WebのURL
      //https://www.youtube.com/watch?v=coYw-eVU0Ks
      //動画のURL（動画のURL、右クリック）
      //https://youtu.be/coYw-eVU0Ks
      //動画の埋め込みコードから取れるURL
      //https://www.youtube.com/embed/coYw-eVU0Ks

      var videoId = "";
      var isYoutube = false;
      var searchString = "https://www.youtube.com/watch?v=";
      if (url.indexOf(searchString) != -1) {
        isYoutube = true;
        videoId = url.replace(searchString, "");
        if (videoId.length) {
          return "https://youtu.be/" + videoId;
          return "https://www.youtube.com/embed/" + videoId + "?autoplay=1";
        }
      }
      searchString = "https://youtu.be/";
      if (url.indexOf(searchString) != -1) {
        isYoutube = true;
        videoId = url.replace(searchString, "");
        if (videoId.length) {
          return "https://youtu.be/" + videoId;
          return "https://www.youtube.com/embed/" + videoId + "?autoplay=1";
        }
      }
      searchString = "https://www.youtube.com/embed/";
      if (url.indexOf(searchString) != -1) {
        isYoutube = true;
        videoId = url.replace(searchString, "");
        if (videoId.length) {
          return "https://youtu.be/" + videoId;
          return "https://www.youtube.com/embed/" + videoId + "?autoplay=1";
        }
      }
      return "";  //見つからない：Youtubeじゃない
    },

    isText : function(content) {
      if (this.getFileformatName(content).indexOf("tty") !== -1) {
          return true;
      }
      return false;
    },

    getDurationSec : function(duration){
      var scale = 1000000; // micro sec.
      var sign = '';
      if (duration < 0) {sign = '-'; duration = -duration; }
      var sec = Math.ceil(duration / scale);

      return sec;
    },
    getDurationText : function(duration, scale) {
      try {
        scale = scale || 1000000; // micro sec.
        if (typeof duration === 'string') duration = parseInt(duration);
        if (typeof duration !== 'number' || isNaN(duration)) return 'N/A';
        var sign = '';
        if (duration < 0) {sign = '-'; duration = -duration; }
        duration = Math.ceil(duration / scale);
        var hh = Math.floor(duration / 3600);
        var mm = Math.floor((duration -= hh * 3600) / 60);
        var ss = duration -= mm * 60;
        if (hh > 100) {
          return sign + ('0' + hh).slice(-3) + ':' + ('0' + mm).slice(-2) + ':' + ('0' + ss).slice(-2);
        }
          return sign + ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ':' + ('0' + ss).slice(-2);
      } catch (ex) {
        return '';//'N/A';
      }
    }
  };
}]);

app.filter('MultiOffset', function() {
  return function(input, start) {
    start = parseInt(start);
    if (input) {
      return input.slice(start);
    }
    return [];
  };
})

/**
 * クレジットカード決済に関する情報
 */
app.factory('LimaCreditCardInfo', ['$http', '$translate', '$window', 'Auth',
function LimaCreditCardInfo($http,$translate,$window,Auth) {
  return {
    //
    // credit cardの決済状況の情報から、メッセージを生成する
    getInfoString : function(creditCardInfo) {
      var retInfo = {message: '', errorcode: '', expired:false, disabled:false }
      if ( creditCardInfo === null || creditCardInfo.creditcard === false) {
        return retInfo;
      }
      if ( creditCardInfo.disabled === 1 ) {
        const diff = creditCardInfo.remain_days;
        if ( diff > 0 ) {
           retInfo.expired = false;
           retInfo.message = $translate.instant('COMPANY.SUSPENDED_CD', {day:diff})  // "この契約は" + diff + "後に停止します。";
          }
        else {
          retInfo.expired = true;
          retInfo.message = $translate.instant('COMPANY.SUSPENDED')  // "この契約は現在は無効です";
        }
        retInfo.disabled = true;
      }
      else if ( creditCardInfo.current_status === 0 ) {//未決済
        if ( creditCardInfo.errorcode.length ) {
          retInfo.message = $translate.instant('COMPANY.MONTHRY_PAYMENT_ERROR') + "( error code: " +  creditCardInfo.errorcode + " )"; //自動課金時にエラー
          retInfo.expired = true;
          retInfo.disabled = false;
        }
        else {
          const diff = creditCardInfo.remain_days;
          if ( diff > 0 ) {
            retInfo.message =  $translate.instant('COMPANY.TRIAL_PERIOD' , {day:diff}); // "デモ期間は、あと" + diff + "日です"
            retInfo.expired = false;
            retInfo.disabled = false;
          }
          else {
            retInfo.message = $translate.instant('COMPANY.TRIAL_EXPIRED') // "デモ期間は終了しました";
            retInfo.expired = true;
            retInfo.disabled = false;
          }
        }
      }
      else if (creditCardInfo.errorcode.length ) { // "契約期間中だが、自動決済エラー"
        const diff = creditCardInfo.remain_days;
        if ( diff > 0 ) {
          retInfo.message =  $translate.instant('COMPANY.MONTHRY_PAYMENT_ERROR_CD' , {day:diff}); // "カード変更期間は、あと" + diff + "日です"
          retInfo.expired = false;
          retInfo.disabled = false;
        }
        else {
          retInfo.message = $translate.instant('COMPANY.MONTHRY_PAYMENT_ERROR') // "カード変更期間は終了しました";
          retInfo.expired = true;
          retInfo.disabled = false;
        }
      }
      else {
        retInfo.message = $translate.instant('COMPANY.CONTRACTED') // "契約期間中です";
        retInfo.expired = false;
        retInfo.disabled = false;
      }
      retInfo.errorcode = creditCardInfo.errorcode;
      return retInfo;
    },
    //
    // credit cardの決済状況の情報を取得する
    /*
    *　登録の管理用サーバについて設定の読み込み
    * -------- 項目 ---------
    *　 "RegistManageServer": {      // 企業登録管理サーバ
    *     "Setting": {
    *       "domain": "localhost",//"regist.tsunagaru.dev1.mediaedge.jp",
    *       "protocol": "http",//"https",
    *       "port": "80"//"443"
    *     }
    *   },
    */

    getRegistToken: function (_callback) {
      async.waterfall([
        function (callback) {
          $http.get('/api/v1/configurations/RegistManageServer').then(function (res) {
            if (res.data) {

              callback(null, res.data);
            } else {
              callback("result:ng", null);
            }
          });
        },
        function (config, callback) {
          if (config) {
            //regist系のAPIを呼ぶ際はregist用のtokenをとる（Admin権限）
            const regsitServerUrl = config.setting.protocol
              + "://"
              + config.setting.domain
              + ":"
              + config.setting.port
              + '/auth/local';
            $.ajax({
              type: 'POST',
              url: regsitServerUrl,
              dataType: 'json',
              processData: false,
              data: $.param({
                loginAdmin: true,
                email: 'admin',
                password: 'Mediaedge4'
              }),
              success: function (data) {
                callback(null, data.token);
              },
              error: function (data) {
                callback("result:ng", null);
              }
            });
          } else {
            callback("result:ng", null);
          }
        }
      ], function (err, token) {
        if (err) {
          _callback(err, null);
        }
        else {
          _callback(null, token);
        }
      });
    },
    getCreditCardInfo: function (_callback) {
      var access_token = '';
      var thisFunc = this;
      async.waterfall([
        function (callback) {
          thisFunc.getRegistToken(function (err, token) {
            access_token = token;
            callback(err);
          });
        },
        function (callback) {  // companyIdを取得する
          if (Auth.isLoggedIn()) {
            $http.get('/api/v1/users/me').
              success(function (datas) {
                callback(null, datas.companyId);
              }).
              error(function (err) {
                callback(err, null, null, null);
              })
          } else {
            callback("Not Login", null, null, null);
          }
        },
        function (companyId, callback) {     // レジスト用のサーバURLを取得する
          $http.get('/api/v1/configurations/RegistManageServer').then(function (res) {
            if (res.data) {
              /* RegistManageServerに関する設定情報の取得 */
              const config = res.data;
              const regsitServerUrl = config.setting.protocol
                + "://"
                + config.setting.domain
                + ":"
                + config.setting.port;

              /* クレジットカード情報取得 */
              callback(null, regsitServerUrl, companyId);
            }
            else {
              callback("result:ng", null, regsitServerUrl, companyId);
            }
          })
        },
        function (regsitServerUrl, companyId, callback) {  // クレジット決済関連情報の取得
          const url = regsitServerUrl + '/api/v1/creditcard/' + companyId
            + '?access_token=' + access_token;
          var xmlHttpRequest = new XMLHttpRequest();
          xmlHttpRequest.onreadystatechange = function () {
            if (this.readyState === 4) {
              var creditCardInfo = null;
              if (this.status === 200 && this.response) {
                var info = this.response;
                if (JSON && navigator.userAgent.indexOf('Trident') !== -1) {
                  //IEの場合、レスポンスを置き換える
                  info = JSON.parse(this.response);
                }
                if (info.creditcard) {
                  creditCardInfo = info;
                }
              }
              callback(null, creditCardInfo, regsitServerUrl, companyId);
            }
          }
          xmlHttpRequest.open('GET', url, true);
          xmlHttpRequest.responseType = 'json';
          xmlHttpRequest.send(null);
        }
      ], function (err, creditCardInfo, registServerUrl, companyId) {
        if (err) {
          _callback(err, creditCardInfo, registServerUrl, companyId);
        }
        else {
          _callback(null, creditCardInfo, registServerUrl, companyId);
        }
      });
    },
    // registサイトに接続する
    // json = {
    //    token: ""
    // }
    jumpToRegistSite: function jumpToRegistSite(registServerUrl, companyId, mode) {

      var access_token = '';
      this.getRegistToken(function (err, token) {
        access_token = token;

        const url = registServerUrl + '/api/v1/creditcard/enc/' + companyId
          + '?access_token=' + access_token;

        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200 && this.response) {
            console.log(this.response);
            var info = this.response;
            if (JSON && navigator.userAgent.indexOf('Trident') !== -1) {
              //IEの場合、レスポンスを置き換える
              info = JSON.parse(this.response);
            }
            const url = registServerUrl + '/ccpayment'
              + '?access_token=' + access_token
              + '&cid=' + encodeURIComponent(info.cidtoken)
              + '&mode=' + mode;
            $window.location.href = url;
          }
        }
        xmlHttpRequest.open('GET', url, true);
        xmlHttpRequest.responseType = 'json';
        xmlHttpRequest.send(null);
      });
    },
    isIE : function() {
      var userAgent = $window.navigator.userAgent.toLowerCase();
      if(userAgent.indexOf('msie') != -1 ||
        userAgent.indexOf('trident') != -1) {
        return true;   // 'Internet Explore'
      }
      else {
        return false;
      }
    }
  }
}]);

