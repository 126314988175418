'use strict';

var async =require('async');
var app = angular.module('limaApp');

app.controller('ESysLineSettingCtrl', ['$scope', '$http', '$filter', '$translate', '$timeout','$window', '$cookies','Modal', 'Auth', 'ContentUtil', 'limaShowWaitDialog', 'esysInfo',
function ($scope, $http, $filter, $translate, $timeout, $window, $cookies, Modal, Auth, ContentUtil, limaShowWaitDialog ,esysInfo) {

  $scope.esysInfo = _.cloneDeep(esysInfo.info);
  $scope.esysInfo.contractInfo.line_contract_id_str = ( '00000000' + $scope.esysInfo.contractInfo.line_contract_id ).slice( -8 );
  $scope.enablePassword = esysInfo.enablePassword;
  $scope.enableSetting = esysInfo.enableSetting;
  $scope.esysInfo.m_updated = false;

  $scope.esysInfo_saved = _.cloneDeep($scope.esysInfo);

  $scope.showPwd = false;

  $scope.changeShowPwd = function() {
    if( input_pass.type === 'password' ) {
      input_pass.type = 'text';
      $scope.showPwd = true;
    } else {
      input_pass.type = 'password';
      $scope.showPwd = false;
    }
  }

  function deleteLineContent(arrAutoContent , callback) {
    //arrAutoContent:この企業の自動更新コンテンツ
    if (esysInfo.info.contractInfo.generation_num > $scope.esysInfo.contractInfo.generation_num) {
      //減る
    } else {
      //増える
      callback(null);
      return;
    }

    //減るときだけここからの処理をする
    async.forEachSeries(arrAutoContent, function (dt, cb) {
      if (dt.url.indexOf("line_content_") !== -1) {
        var title = dt.title.toLowerCase().replace("line" , "");
        title = title.replace("(" , "");
        title = title.replace(")" , "");
        var titleNum = Number(title);
        if (titleNum > $scope.esysInfo.contractInfo.generation_num) {
          //削除するやつ
          $http.delete('/api/v1/content/extra/autoupdated/' + dt.id)
          .finally(function () {
            cb(null);
          })
        } else {
          cb(null);
        }
      } else {
        cb(null);
      }
    }, function (err) {
      callback(null);
    });


  }

  function check_generation_num(callback){
    //世代数が増減するときチェック。ラインをプレイリストで使ってないかどうか
    if (esysInfo.info.contractInfo.generation_num !== $scope.esysInfo.contractInfo.generation_num) {
      var arr = [];

      //arrAutoContent:この企業の自動更新コンテンツ
      var arrAutoContent = [];
      var param = {};
      param.type = ContentUtil.CONTENT_AUTOUPDATED;  //autoupdated
      $http.get('/api/v1/content/contents' , {params:param}).
      success(function(datas_auto) {
        arrAutoContent = datas_auto;

        $http.get('/api/v1/playlists').
        success(function(datas) {
          async.forEachSeries(datas.playlists, function (dt, cb) {
            $http.get('/api/v1/playlists/' + dt.playlistId + '/contents').
            success(function(cont_datas) {
              arr = arr.concat(cont_datas);
              cb(null);
            });
          }, function (err) {
            //自動更新コンテンツだけ
            arr = _.filter(arr, function(cont) {
              if (ContentUtil.isAutoupdated(cont)) {
                return true;
              }
            });

            //lineを探す
            var find = _.find(arr, function(cont) {
              return _.find(datas_auto, function(cont_auto) {
                if (cont_auto.id === cont.contentId) {
                  if (cont_auto.url.toLowerCase().indexOf("line_content_") !== -1) {
                    return true;
                  }
                }
              });
            });
            if (!find) {
              //使ってない⇒DBから消す
              deleteLineContent(arrAutoContent , function() {
                callback(find);
              })
            } else {
              callback(find);
            }
          });
        });
      });
    } else {
      callback(null);
    }

  }

  function checkPassword(str){
    if(str.match(/^[A-Za-z0-9]*$/)){
      return true;
    }else{
      return false;
    }
  }

  //保存
  $scope.update = function() {
    if ($scope.esysInfo.contractInfo.line_contract_pass.length !== 11) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"パスワードは11桁で設定して下さい。", modalClass:'modal-warning'});
      return;
    }
    if (!checkPassword($scope.esysInfo.contractInfo.line_contract_pass)) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"パスワードには半角英数字を入力してください。", modalClass:'modal-warning'});
      return;
    }

    if ($scope.esysInfo.contractInfo.generation_num <= 0) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"世代数は1以上を設定してください。", modalClass:'modal-warning'});
      return;
    }
    if ($scope.esysInfo.contractInfo.generation_num > 10) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"世代数は10以下を設定してください。", modalClass:'modal-warning'});
      return;
    }

    var addGenNum = true;
    if (esysInfo.info.contractInfo.generation_num > $scope.esysInfo.contractInfo.generation_num) {
      //減る
      addGenNum = false;
    }

    limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.SYSTEM_SETTINGS'),'設定中');

    check_generation_num(function(result) {
      if (result) {
        limaShowWaitDialog.hideWait();
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"プレイリストにLINEコンテンツが登録されています。プレイリストから登録を外してから再度世代数を設定してください。", modalClass:'modal-warning'});
        return;
      } else {
        $scope.esysInfo.contractInfo.new_passWd = $scope.esysInfo.contractInfo.line_contract_pass;
        $http.put('/api/v1/content/extra/esys/line' , $scope.esysInfo.contractInfo).
        success(function(data) {
          $scope.esysInfo.contractInfo.line_contract_pass = $scope.esysInfo.contractInfo.new_passWd;  //入れ替える

          //増えるときはコンテンツの追加なのでUI上で少し待たせておく。減るときはここで既に減っているので待たなくてOK
          $timeout(function() {
            limaShowWaitDialog.hideWait();
            Modal.confirm.message({title:"", html:$translate.instant('COMMON.MSG.DONE_SAVE_CHANGES'), modalClass:'modal-primary'});
          },addGenNum ? 10*1000 : 0);

          $scope.esysInfo.m_updated = true; //変更した
          $scope.esysInfo_saved = _.cloneDeep($scope.esysInfo);
        });
      }
    })
  }

}]);
