'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('addDeviceGroup', {
    url: '/deviceGroup/add',
    templateUrl: 'app/deviceGroup/addGroup/addGroup.html',
    controller: 'AddDevicegroupCtrl',
    authenticate: true,
    functionName: 'UserGroupManagement',
    title: 'Add device Group'
  });
}]);
