'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('EditDeviceCtrl', {
    url: '/device/edit',
    templateUrl: 'app/device/edit/editDevice.html',
    controller: 'EditDeviceCtrl',
    authenticate: true,
    functionName: 'UserGroupManagement',
    title: 'Edit Device'
  });
}]);
