'use strict';

var app = angular.module('limaApp');

app.controller('DeviceGroupCtrl', ['$scope', '$http', '$location', '$translate','$q', 'Modal', 'Auth', '$uibModal', 'LimaRedirect', 'socket','$timeout','$window',
function ($scope, $http, $location, $translate,$q, Modal, Auth, $modal, LimaRedirect, socket, $timeout,$window) {

  $window.document.title  = $translate.instant('NAVIGATION.DEVICEGROUP');

  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = [];
  $scope.channelsInfo.selects = [];

  function getChannels() {
    $scope.channelsInfo.channels = [];
    // $http.get('/api/v1/device/groups').
    // success(function(datas) {
      $scope.channelsInfo.channels = _.filter($scope.deviceGroups.groups, function(item){
        if (!item.typeDefault) {
          item.deviceIds = JSON.parse(item.deviceIds);
          item.devices = [];
          _.forEach(item.deviceIds, function(dvId) {
            _.find($scope.devicelists , function(dv){
              if (dv.deviceId === dvId) {
                item.devices.push(dv);
                return true;
              }
            });
          });
          item.userIds = JSON.parse(item.userIds);
          item.users = [];
          _.forEach(item.userIds, function(userId) {
            _.find($scope.members , function(mem){
              if (mem.id === userId) {
                item.users.push(mem);
                return true;
              }
            });
          });

          return true;
        }
      });
    // });

  }

  $scope.deviceGroups = [];
  function getDeviceGroups(callback) {
    $scope.deviceGroups = [];
    $http.get('/api/v1/device/groups').
    success(function(datas) {
      //自分が閲覧できるグループを確認
      if (Auth.isAdmin()) {
        $scope.deviceGroups.groups = datas;
      } else {
        var userId = Auth.getCurrentUser().id;
        $scope.deviceGroups.groups = _.filter(datas, function(gr){
          if (gr.typeDefault) {
            //All
            return true;
          } else {
            var userIds = JSON.parse(gr.userIds);
            var findUser = _.find(userIds, function(id) {
              return id === userId;
            });
            return findUser;
          }
        });
      }
      callback();
    });

  }

  $scope.devicelists = [];
  var getDevicelist = function() {
    $http.get('/api/v1/device/device').
    success(function(datas) {
      getDeviceGroups(function() {
        if (Auth.isAdmin()) {
          $scope.devicelists = datas;
        } else {
          $scope.devicelists = _.filter(datas, function(dt){
            //グループに含まれるデバイスだけにする
            var findGrp = _.find($scope.deviceGroups.groups, function(gp) {
              var deviceIds = JSON.parse(gp.deviceIds);
              var findDevice = _.find(deviceIds,function(dvId) {
                return dvId === dt.deviceId;
              });
              return findDevice;
            });
            return findGrp;
          });
        }
        getChannels();
      });
    });

  };

  $scope.getDeviceNames = function(group) {
    var arrNames = [];
    _.forEach(group.devices, function(dv) {
        arrNames.push(dv.name);
        return true;
    });
    return  arrNames.join(' / ');
  }

  $scope.getUserNames = function(group) {
    var arrNames = [];
    _.forEach(group.users, function(dv) {
        arrNames.push(dv.displayName ? dv.displayName : dv.name);
        return true;
    });
    return  arrNames.join(' / ');
  }


  socket.connect().then(function () {
    return socket.call('v1:user:account:findAll');
  }).then(function (res) {
    // $scope.members = res || [];
    $scope.members = _.filter(res, function(member) {
      var findAdmin = _.find(member.groups, function (group) {
        if (group.id.indexOf('-Administrator') !== -1 ) {
          return true;
        }
      });
      return !findAdmin ? true : false;
    })
    getDevicelist();
  }, function (err) {
    $scope.members = [{name:'error'}];
  });


  $scope.checkedlist = [];
  $scope.UpdateCheckedList = (function() {
    $scope.checkedlist = _.filter($scope.channelsInfo.channels, function(list){return list.checked;});
  });


  $scope.deleteGroup = function(group) {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('DEVICEGROUP.SELECTED_GROUP')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      //削除

      var  selected = _.filter($scope.channelsInfo.channels, function(list){return list.checked;});
      {
        var deferred = $q.defer();
        var promise = deferred.promise;

          var requests = _.forEach(selected, function(item){
          promise = promise.finally(function () {
            return $http.delete('/api/v1/device/groups/' + item.groupId).finally(function () {
              console.log(item.groupId);
            });
          });
        });

        promise = promise.finally(function () {
          console.log('end delete');
          $timeout(function(){
            //
            getDevicelist();
            $scope.checkedlist = [];
          });
        });

        deferred.resolve();

      }
    });
  };

  $scope.addGroup = function (event) {
    $scope.editGroup({});
    return;
    var modalInstance = $modal.open({
      templateUrl: 'app/deviceGroup/addGroup/addGroup.html',
      controller: 'AddDevicegroupCtrl',
      backdrop: 'static', keyboard: false,
      resolve: { }
    });
    modalInstance.result.then(function (results) { // OK

      var postdata = {
        groupId : "",
        name    : results.group.name   ,
        description   : results.group.description   ,
        target  : "ME-DEC" , //ME-DEC
      };
      $http.post('/api/v1/device/groups' , postdata)
      .success(function(res) {
        $timeout(function(){
          getChannels();
        });

      });


    }, function () { // cancel
    });
  };

  $scope.editGroup = function (group) {
    //$event.stopPropagation();
    var modalInstance = $modal.open({
      templateUrl: 'app/deviceGroup/editGroup/editGroup.html',
      controller: 'EditDevicegroupCtrl',
      resolve: {
        deviceGroupInfo: function() {
          return {
            groupInfo : group,
            devices : $scope.devicelists,
            users : $scope.members,
            groups: $scope.deviceGroups.groups
          }
        }
      }
    });
    modalInstance.result.then(function (result) { // OK

      var postdata = {
        groupId : result.group.groupId,
        name    : result.group.name   ,
        description   : result.group.description   ,
        deviceIds : result.group.deviceIds ? result.group.deviceIds : [],
        userIds : result.group.userIds ? result.group.userIds : []
      };
      $http.post('/api/v1/device/groups' , postdata)
      .success(function(res) {
        $timeout(function(){
          getDevicelist();
        });

      });


    }, function () { // cancel
    });

  };

}]);
