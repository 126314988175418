'use strict';

angular.module('limaApp')
.controller('ScheduleEditCtrl', ['$scope','$uibModalInstance', '$stateParams', '$http', '$timeout', '$translate', '$localStorage', 'Auth', 'Modal', '$window', '$uibModal', '$q', '$filter', 'RafaleScheduleData',
function($scope, $modalInstance, $stateParams, $http, $timeout, $translate, $localStorage, Auth, Modal, $window, $modal, $q, $filter, RafaleScheduleData) {

  function getSameLiveIdSchedules() {

    var scheduleId;
    if (RafaleScheduleData && RafaleScheduleData.data && RafaleScheduleData.data.scheduleListId){
      scheduleId = RafaleScheduleData.data.scheduleListId;
    }

    //上位から渡されたスケジュール一覧と重複するところがないかチェック。
    //まずliveIdが同じものを探す
    //RafaleScheduleData.scheduleItems
    var res = _.filter(RafaleScheduleData.scheduleItems, function(item){
      if (item.param.liveId === $scope.selectContentLive.id) {
        if (item.id !== scheduleId) {	//自分自身は省く
          return true;
        }
      }
    });
    return res;
  }

  function overlap2(date1,startTime1,endTime1,date2,startTime2,endTime2) {
    var bTimeOverlap = false;
    var day1_s = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), getHour(startTime1), getMinute(startTime1));
    var day1_e = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), getHour(endTime1), getMinute(endTime1));
    var day2_s = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), getHour(startTime2), getMinute(startTime2));
    var day2_e = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), getHour(endTime2), getMinute(endTime2));

    return day1_s < day2_e && day1_e > day2_s;
  }

  function IsOverlap(item1, item2){
    var date1 = new Date(item1.date);
    var date2 = new Date(item2.date);
    var arr1 = item1.dayOfWeek.split(',');	//文字列の配列
    var arr2 = item2.dayOfWeek.split(',');

    var checked = false;
    if (item1.dayOfWeek.length) {
      if (item2.dayOfWeek.length) {
        ///毎週　と　毎週
        var tempday = new Date();
        checked = _.find(arr1, function(dd1) {
          var checked2 = _.find(arr2, function(dd2) {
            var numday = tempday.getDay();
            var testday = _.cloneDeep(tempday);
            testday.setDate(tempday.getDate() - numday + Number(dd1)); //同じ週のその曜日

            var testday2 = _.cloneDeep(tempday);
            testday2.setDate(tempday.getDate() - numday + Number(dd2)); //同じ週のその曜日

            return overlap2(testday, item1.startTime, item1.endTime,testday2, item2.startTime, item2.endTime);
          });
          if (checked2) {
            return true;
          }
        });
      } else {
        ///毎週　と　日付
        checked = _.find(arr1, function(dd) {
          var numday = date2.getDay();
          var testday = _.cloneDeep(date2);
          testday.setDate(date2.getDate() - numday + Number(dd)); //同じ週のその曜日
          return overlap2(testday, item1.startTime, item1.endTime, date2, item2.startTime, item2.endTime);
        });
      }
    } else {
      if (item2.dayOfWeek.length) {
        //日付と毎週
        checked = _.find(arr2, function(dd) {
          var numday = date1.getDay();
          var testday = _.cloneDeep(date1);
          testday.setDate(date1.getDate() - numday + Number(dd)); //同じ週のその曜日
          return overlap2(date1, item1.startTime, item1.endTime, testday, item2.startTime, item2.endTime);
        });

      } else {
        //日付と日付
        checked = overlap2(date1, item1.startTime, item1.endTime, date2, item2.startTime, item2.endTime);
      }
    }
    return checked ? true : false;
  }

  function overlap2ByStartTime(date1,startTime1,endTime1,date2,startTime2,endTime2) {
    var bTimeOverlap = false;
    var day1_s = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), getHour(startTime1), getMinute(startTime1));
    //var day1_e = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), getHour(endTime1), getMinute(endTime1));
    var day2_s = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), getHour(startTime2), getMinute(startTime2));
    //var day2_e = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), getHour(endTime2), getMinute(endTime2));

    return day1_s.getTime() === day2_s.getTime();
  }

  function IsOverlapByStartTime(item1, item2){
    if (!item1.scheduleListItemId || !item2.scheduleListItemId) {
      if (item1.itemuuid === item2.itemuuid) {
        return false;//比較対象が同じ。。。
      }
    } else {
      if (item1.scheduleListItemId === item2.scheduleListItemId) {
        return false;//比較対象が同じ。。。
      }
    }
    var date1 = new Date(item1.date);
    var date2 = new Date(item2.date);
    var arr1 = _.isArray(item1.dayOfWeek) ? item1.dayOfWeek : item1.dayOfWeek.split(',');	//文字列の配列
    var arr2 = _.isArray(item2.dayOfWeek) ? item2.dayOfWeek : item2.dayOfWeek.split(',');

    var checked = false;
    if (item1.dayOfWeek.length) {
      if (item2.dayOfWeek.length) {
        ///毎週　と　毎週
        var tempday = new Date();
        checked = _.find(arr1, function(dd1) {
          var checked2 = _.find(arr2, function(dd2) {
            var numday = tempday.getDay();
            var testday = _.cloneDeep(tempday);
            testday.setDate(tempday.getDate() - numday + Number(dd1)); //同じ週のその曜日

            var testday2 = _.cloneDeep(tempday);
            testday2.setDate(tempday.getDate() - numday + Number(dd2)); //同じ週のその曜日

            return overlap2ByStartTime(testday, item1.startTime, item1.endTime,testday2, item2.startTime, item2.endTime);
          });
          if (checked2) {
            return true;
          }
        });
      } else {
        ///毎週　と　日付
        checked = _.find(arr1, function(dd) {
          var numday = date2.getDay();
          var testday = _.cloneDeep(date2);
          testday.setDate(date2.getDate() - numday + Number(dd)); //同じ週のその曜日
          return overlap2ByStartTime(testday, item1.startTime, item1.endTime, date2, item2.startTime, item2.endTime);
        });
      }
    } else {
      if (item2.dayOfWeek.length) {
        //日付と毎週
        checked = _.find(arr2, function(dd) {
          var numday = date1.getDay();
          var testday = _.cloneDeep(date1);
          testday.setDate(date1.getDate() - numday + Number(dd)); //同じ週のその曜日
          return overlap2ByStartTime(date1, item1.startTime, item1.endTime, testday, item2.startTime, item2.endTime);
        });

      } else {
        //日付と日付
        checked = overlap2ByStartTime(date1, item1.startTime, item1.endTime, date2, item2.startTime, item2.endTime);
      }
    }
    return checked ? true : false;
  }


  function checkSchedule_Schedule(data) {
    //スケジュール設定自体がおかしくないかチェック
    // //入力チェック：時分（時分が空白の時は0になる）
     var arrTime1 = data.startTime.split(':');
    // var arrTime2 = data.endTime.split(':');
    // if ((arrTime1.length < 2) || (arrTime2.length < 2)){
    //   return $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_TIME');
    // }
    // //数字チェック
    // var i;
    // for (i=0; i < 2; i++) {
    //   arrTime1[i] = Number(arrTime1[i]);
    //   arrTime2[i] = Number(arrTime2[i]);
    // }
    // for (i=0; i < 2; i++) {
    //   if (!_.isNumber(arrTime1[i]) || _.isNaN(arrTime1[i])) {
    //     return $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_TIME');
    //   }
    //   if (!_.isNumber(arrTime2[i]) || _.isNaN(arrTime2[i])) {
    //     return $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_TIME');
    //   }
    // }
    // //24時間越えチェック
    // while(true) {
    //   if (arrTime1[0] > arrTime2[0]) {
    //     arrTime2[0] += 24;
    //     data.endTime = arrTime2[0] + ':' + arrTime2[1];	//更新する
    //   } else {
    //     break;
    //   }
    // }

    //入力チェック：日付
    if (($scope.selectSchedule.type === '-DayOfWeek') || ($scope.selectSchedule.type === '-Everyday')) {
      if (!data.dayOfWeek.length) {
        return $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_DAYOFWEEK');
      }
    } else {
      if (!data.date) {
        return $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_DATE');
      }
    }

//     //Durationは５分以上必要とする
//     //日付
    var now = new Date();
    var tempday = new Date(data.date);
    if (data.dayOfWeek.length) {
      //毎週の時は今日の日付でチェック
      tempday = new Date();
    }
     var setday1 = new Date(tempday.getFullYear(), tempday.getMonth(), tempday.getDate(), arrTime1[0], arrTime1[1]);
//     var setday2 = new Date(tempday.getFullYear(), tempday.getMonth(), tempday.getDate(), arrTime2[0], arrTime2[1]);
// //		var nMinMinutes = 5;	//5min
//     var nMinMinutes = 2;	//5min
//     if ((setday2 - setday1) < nMinMinutes*60*1000) {
//       return $translate.instant('CONTENT.SCHEDULE.MSG.CHECK_DURATION', {minute: nMinMinutes});
//     }
//     //日付のチェック：古くないか
    if (data.enable) {
      if (!data.dayOfWeek.length) {
        if (setday1 - now < 0) {
          return $translate.instant('CONTENT.SCHEDULE.MSG.OLD_DATE');
        }
      }
    }

    //重複チェック
    var scItemlist = RafaleScheduleData.list;
    if (scItemlist) {
      if ( _.find(scItemlist, function(item) {return IsOverlapByStartTime(item, data);}) ) {
        return $translate.instant('PLAYLIST.SCHEDULE.MSG.DUPLICATE_SETTING');
      }
    }
    // var arrSameLiveId = getSameLiveIdSchedules();
    // if (_.find(arrSameLiveId, function(item){return IsOverlap(item, data)})) {
    //   return $translate.instant('CONTENT.SCHEDULE.MSG.DUPLICATE_SETTING');
    // }
    return '';
  }


  function checkEditItem(scheduleListId) {
    //編集元のスケジュール録画が動作中と思われるものの設定はできないように
    // var editItem = _.find(RafaleScheduleData.scheduleItems, function(item){
    //   if (item.id === id) {
    //     return true;
    //   }
    // });
    // if (!editItem) {
    //   return '';
    // }

    // var arrTime1 = editItem.startTime.split(':');
    // var arrTime2 = editItem.endTime.split(':');

    // var now = new Date();
    // var setday1 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), arrTime1[0], arrTime1[1]);
    // var setday2 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), arrTime2[0], arrTime2[1]);

    // var nTodaysRec = false;
    // //設定された曜日が今日かどうか
    // if (editItem.dayOfWeek.length) {
    //   if (editItem.dayOfWeek.indexOf(now.getDay()) !== -1 ) {
    //     nTodaysRec=true;
    //   }
    // } else {
    //   //設定された日が今日かどうか
    //   if ((setday1.getFullYear() === now.getFullYear()) && (setday1.getMonth() === now.getMonth()) && (setday1.getDate() === now.getDate())) {
    //     nTodaysRec=true;
    //   }
    // }
    // //録画時間の範囲内:実際に録画しているかは置いておく
    // if (nTodaysRec) {
    //   if ((setday1 <= now) && (now <= setday2)) {
    //     return $translate.instant('CONTENT.SCHEDULE.MSG.DISABLE_SETTING_WITHIN_TIME');
    //   }
    // }
    return '';

  }

  function checkMinuteString(val) {
    return ('00' + val).slice(-2);
  }

  $scope.ok = function () {

    var resCheck;
    // //見た目のチェック
    // var resCheck = checkGuiValue();
    // if (resCheck.length) {
    //   Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:resCheck, modalClass:'modal-warning'});
    //   return;
    // }

    //登録データ作成
    var scheduleListId='';
    var scheduleListItemId='';
    var itemuuid = '';
    if (RafaleScheduleData && RafaleScheduleData.data && RafaleScheduleData.data.scheduleListId){
      scheduleListId = RafaleScheduleData.data.scheduleListId;

      resCheck = checkEditItem(scheduleListId);
      if (resCheck.length) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:resCheck, modalClass:'modal-warning'});
        return;
      }

    }
    if (RafaleScheduleData && RafaleScheduleData.data && RafaleScheduleData.data.scheduleListItemId){
      scheduleListItemId = RafaleScheduleData.data.scheduleListItemId;
    }
    if (RafaleScheduleData && RafaleScheduleData.data && RafaleScheduleData.data.itemuuid){
      itemuuid = RafaleScheduleData.data.itemuuid;
    }
    var scheduleval = {
      itemuuid: itemuuid,
      scheduleListItemId: scheduleListItemId,
      scheduleListId:scheduleListId,
      enable: $scope.scheduleEnable,

      date: null,
      dayOfWeek:[],	//Sunday=0、"4,5,6"
      startTime:$scope.selecthours.title+ ':' + $scope.selectminutes.title + ':00',
      endTime:null,
      playlistId: $scope.schedulePlaylistEnable ?  $scope.selectPlaylist.select.playlistId : null,
      controlListId: !$scope.schedulePlaylistEnable ?  $scope.selectControlList.select.controlListId : null,
      controlName: !$scope.schedulePlaylistEnable ?  $scope.selectControlList.select.controlName : null,
    };
    if ($scope.selectSchedule.type === '-Date') {
      //dateとstarttimeを同じにしておく
      scheduleval.date = $filter('date')(new Date($scope.selectSchedule.datecalender.getValue().dateValue), 'yyyy-MM-dd');
    }
    else if ($scope.selectSchedule.type === '-DayOfWeek') {
      scheduleval.dayOfWeek = [];
      _.forEach($scope.selectSchedule.dayOfWeek.dayOfWeekValue, function(v,index) {
        if (v.enable) {
          // if (scheduleval.dayOfWeek.length) {
          //   scheduleval.dayOfWeek += ',';
          // }
//          scheduleval.dayOfWeek += index.toString();
          scheduleval.dayOfWeek.push(index);
        }
      });
    }
    else if ($scope.selectSchedule.type === '-Everyday') {
      scheduleval.dayOfWeek = [];
      _.forEach($scope.selectSchedule.dayOfWeek.dayOfWeekValue, function(v,index) {
        scheduleval.dayOfWeek.push(index);  //全部
    });
    }

    //入力値の整合性チェック
    resCheck = checkSchedule_Schedule(scheduleval);
    if (resCheck.length) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:resCheck, modalClass:'modal-warning'});
      return;
    }

    $modalInstance.close(scheduleval);
  };
  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  /////時間と時刻
  function initHM() {
    $scope.hours = [];
    $scope.minutes = [];
    $scope.selecthours = [];
    $scope.selectminutes = [];

    for (var i=0; i < 24; i++){
      $scope.hours.push({value:i , title:('00' + i).slice(-2)});
    }
    for (i=0; i < 60; i++){
      //if (i % 10 === 0) {
        $scope.minutes.push({value:i , title:('00' + i).slice(-2)});
      //}
    }
  }

  function updateSelectHM() {
    //現在時刻
    var nowTime = new Date();
    $scope.selecthours = _.find($scope.hours, function(hh){
      var check_h = nowTime.getHours() + 1;
      if (check_h === 24) {
        check_h = 0;
      }
      return hh.value === check_h ? true : false;
    });
    $scope.selectminutes = $scope.minutes[0];
  }


  /////

  $scope.error = '';

  $scope.playlists = [];
  $scope.selectPlaylist = {};

  $scope.dbtimezoneString = "";
  $scope.getdbTimezone = function() {
    $http.get('/api/v1/system-info/db').
    success(function(datas) {
      console.log(datas);
      _.find(datas,function(dt){
        if (dt.Variable_name === 'system_time_zone') {
          $scope.dbtimezoneString = dt.Value;
          return true;
        }
      });

      getPlaylits();

    });
  }



  function getPlaylits() {

    $scope.playlists = [];
    $http.get('/api/v1/playlists').
    success(function(datas) {
      //プレイリストだけを抽出
      $scope.playlists = datas.playlists;
      const defaultTimezoneOffset = new Date().getTimezoneOffset()*-1;  //min
      if ($scope.dbtimezoneString === 'UTC') {  //S3 AURORA
        $scope.playlists = _.forEach(datas.playlists, function(list){
          //UTC->SYSTEM
          const defaultTimezoneOffset = new Date().getTimezoneOffset()*-1;
          var dt = new Date(list.updatedTime);
          dt.setMinutes(dt.getMinutes() + defaultTimezoneOffset);
          list.updatedTime = dt;
        });
      }
      //新しいもの順にソート
      $scope.playlists = _.sortBy($scope.playlists, function(list) {
        return new Date(list.updatedTime);
      }).reverse();

      if ($scope.playlists.length) {
        $scope.selectPlaylist.select = $scope.playlists[0];
        if (RafaleScheduleData && RafaleScheduleData.data && RafaleScheduleData.data.playlistId){
          $scope.selectPlaylist.select = _.find($scope.playlists, function(ll){
            return ll.playlistId === RafaleScheduleData.data.playlistId;
          });
        }
      }
    });
  }

  $scope.getDisplayName = function(controlName) {
    if (controlName === 'monitor-off') {
      return $translate.instant('DEVICE.TYPE_MONITOR_OFF');
    }
    else if (controlName === 'monitor-on') {
      return $translate.instant('DEVICE.TYPE_MONITOR_ON');
    }
    else if (controlName === 'reboot') {
      return $translate.instant('DEVICE.TYPE_REBOOT');
    }
    return '';
  }

  $scope.controlLists = [];
  $scope.selectControlList = {};
  function getControlLists() {
    $scope.controlLists = [];
    $http.get('/api/v1/controls').
    success(function(datas) {
      $scope.controlLists = datas.controls;
      if ($scope.controlLists.length) {
        $scope.selectControlList.select = $scope.controlLists[0];
        if (RafaleScheduleData && RafaleScheduleData.data && RafaleScheduleData.data.controlListId){
          $scope.selectControlList.select = _.find($scope.controlLists, function(ll){
            return ll.controlListId === RafaleScheduleData.data.controlListId;
          });
          $scope.schedulePlaylistEnable = 0;
        }
      }
    });
  }

  getControlLists();


  var NewValue = (function () {
    var stringIsNumber = function (s) {
       if (_.isString(s)) s = Number(s);
       return _.isNumber(s) && !_.isNaN(s);
    };
    var NewValue = function (type, value, value2) {
      var self = this;
      self.type = type;
      var paramDate1 = value ? value : new Date();
      paramDate1.setSeconds(0);
      var paramDate2 = value2 ? value2 : _.cloneDeep(paramDate1);
      if (!value2) {
        paramDate2.setHours(paramDate1.getHours()+1);
      }
      paramDate2.setSeconds(0);
      self.numberValue = null;
      self.stringValue = null;
      self.dayOfWeekValue = [];
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_SUNDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_MONDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_TUESDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_WEDNESDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_THURSDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_FRIDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_SATURDAY')});
      self.dateValue = {
        date: paramDate1,
        isOpen: false,
        openCalender: function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          self.dateValue.isOpen = !self.dateValue.isOpen;
        }
      };
      self.timeValue = {
        time: $filter('limaDateTime')(paramDate1, paramDate1)
      };
      self.endDateValue = {
        date: paramDate2,
        isOpen: false,
        openCalender: function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          self.endDateValue.isOpen = !self.endDateValue.isOpen;
        }
      };
      self.endTimeValue = {
        time: $filter('limaDateTime')(paramDate2, paramDate2)
      };
      function onChange() {
        self.changed = true; self.valid = self.isValid();
      }
      $scope.$watch(function () { return self.numberValue; }, onChange);
      $scope.$watch(function () { return self.stringValue; }, onChange);
      $scope.$watch(function () { return self.dayOfWeekValue; }, onChange);
      $scope.$watch(function () { return self.dateValue.date; }, onChange);
      $scope.$watch(function () { return self.timeValue.time; }, onChange);
      $scope.$watch(function () { return self.endDateValue.date; }, onChange);
      $scope.$watch(function () { return self.endTimeValue.time; }, onChange);
      // if (!_.isUndefined(value)) {
      // 	self.setValue(value);
      // }
    };
    NewValue.prototype.isValid = function () {
      switch (this.type) {
     case '-Number':
       return stringIsNumber(this.numberValue);
      case '-String':
         return this.stringValue ? true : false;
      case '-DayOfWeek':
        return this.dayOfWeekValue.length === 7 ? true : false;
      case '-Everyday':
        return this.dayOfWeekValue.length === 7 ? true : false;
      case '-Date':
        return angular.isDate(this.dateValue.date) ? true : false;
      case '-Period':
        if (!angular.isDate(this.dateValue.date) || !angular.isDate(this.endDateValue.date))
          return false;
        var dateValue = $filter('limaDateTime')(this.dateValue.date, this.timeValue.time);
        var endDateValue = $filter('limaDateTime')(this.endDateValue.date, this.endTimeValue.time);
        return dateValue < endDateValue ? true : false;
      }
      return false;
    };
    NewValue.prototype.updateDisplayValue = function () {
      switch (this.type) {
        case '-Number':
          var val = this.getValue();
          this.displayValue = val.displayValue;
          break;
      }
    }
    NewValue.prototype.updateValue = function () {
      switch (this.type) {
        case '-Number':
          this.numberValue = Number(this.displayValue);
        break;
      }
    }

    NewValue.prototype.setValue = function (value) {
      if (!_.isObject(value)) {
        return;
      }
      switch (this.type) {
      case '-Number':
         this.numberValue = Number(value.displayValue);
         break;
      case '-String':
         this.stringValue = value.displayValue;
         break;
      case '-DayOfWeek':
        this.dayOfWeekValue = value;
        break;
      case '-Everyday':
        this.dayOfWeekValue = value;
        break;
      case '-Date':
        this.dateValue.date = this.timeValue.time = new Date(value.displayValue);
        break;
      case '-Period':
        var splited = value.displayValue.split(' - ');
        this.dateValue.date = this.timeValue.time = new Date(splited[0]);
        this.endDateValue.date = this.endTimeValue.time = new Date(splited[1]);
        break;
      }
      var self = this;
      $timeout(function () { self.changed = false; }, 0);
    };
    NewValue.prototype.getValue = function () {
      var value = {};
      switch (this.type) {
      case '-Number':
         if (!stringIsNumber(this.numberValue))
           return $translate.instant('CONTENT.ATTRIBUTE.INVAID_INPUT_VALUE');
         value.numberValue = Number(this.numberValue);
         value.displayValue =  ('0' + this.numberValue).slice(-2);
         break;
      case '-String':
         value.stringValue = value.displayValue = this.stringValue || '';
         break;
      case '-DayOfWeek':
        value.dayOfWeekValue = this.dayOfWeekValue;
        break;
      case '-Date':
        if (!angular.isDate(this.dateValue.date))
          return $translate.instant('CONTENT.ATTRIBUTE.INVAID_INPUT_VALUE');
        value.dateValue = $filter('limaDateTime')(this.dateValue.date, this.timeValue.time);
        value.displayValue = $filter('date')(value.dateValue);
        break;
      case '-Period':
        if (!angular.isDate(this.dateValue.date) || !angular.isDate(this.endDateValue.date))
          return $translate.instant('CONTENT.ATTRIBUTE.INVAID_INPUT_VALUE');
        value.dateValue = $filter('limaDateTime')(this.dateValue.date, this.timeValue.time);
        value.endDateValue = $filter('limaDateTime')(this.endDateValue.date, this.endTimeValue.time);
        if (value.dateValue >= value.endDateValue)
          return $translate.instant('CONTENT.ATTRIBUTE.START_DATE_MUST_BE_BEFORE_END_DATE');
        value.displayValue = $filter('date')(value.dateValue, 'shortTime') + ' - ' + $filter('date')(value.endDateValue, 'shortTime');
        break;
      }
      return value;
    };
    return NewValue;
  })();

  $scope.selectSchedule = {
    type: '-DayOfWeek',
    dayOfWeek:new NewValue('-DayOfWeek'),
    datecalender: new NewValue('-Date'),
    //dateperiod: new NewValue('-Period'),
    periodDateTimeHour: [ new NewValue('-Number') ,new NewValue('-Number') ],
    periodDateTimeMin: [ new NewValue('-Number') ,new NewValue('-Number') ],
    duration:0
  };
  $scope.scheduleEnable = 1;
  $scope.schedulePlaylistEnable = 1;

  $scope.selectRecordsetting = {};

  function msToTimeString(ms, enebleSec) {
    var h = String(Math.floor(ms / 3600000) + 100).substring(1);
    var m = String(Math.floor((ms - h * 3600000)/60000)+ 100).substring(1);
    var s = String(Math.round((ms - h * 3600000 - m * 60000)/1000)+ 100).substring(1);
    if (enebleSec) {
      return h+':'+m+':'+s;
    }
    return h+':'+m;
  }


  $scope.getsegmentDuration = function(param){
    if (_.isUndefined(param.segmentationType)) {
      return $translate.instant('CONTENT.SCHEDULE.SEGMENTATIONTYPE_NONE');
    } else if (param.segmentationType!=='duration') {
      return $translate.instant('CONTENT.SCHEDULE.SEGMENTATIONTYPE_NONE');
    } else {
      return msToTimeString(param.segmentDuration, true);
    }
  };


  $scope.incrementHours = function(index) {
    if ($scope.selectSchedule.periodDateTimeHour[index].numberValue >= 47) {
      return;
    }
    $scope.selectSchedule.periodDateTimeHour[index].numberValue++;
    $scope.selectSchedule.periodDateTimeHour[index].updateDisplayValue();
    $scope.checkHours(index);
  }
  $scope.decrementHours = function(index) {
    if ($scope.selectSchedule.periodDateTimeHour[index].numberValue <= 0) {
      return;
    }
    $scope.selectSchedule.periodDateTimeHour[index].numberValue--;
    $scope.selectSchedule.periodDateTimeHour[index].updateDisplayValue();
    $scope.checkHours(index);
  }
  $scope.incrementMinutes = function(index) {
    if ($scope.selectSchedule.periodDateTimeMin[index].numberValue >= 59) {
      $scope.selectSchedule.periodDateTimeMin[index].numberValue=0;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
      $scope.incrementHours(index);
    } else {
      $scope.selectSchedule.periodDateTimeMin[index].numberValue++;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
    }
    $scope.updateHours();
  }
  $scope.decrementMinutes = function(index) {
    if (!$scope.selectSchedule.periodDateTimeMin[index].numberValue){
      $scope.selectSchedule.periodDateTimeMin[index].numberValue = 59;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
      $scope.decrementHours(index);
    } else {
      $scope.selectSchedule.periodDateTimeMin[index].numberValue--;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
    }
    $scope.updateHours();
  }

  $scope.checkMinutes = function(index) {
    $scope.selectSchedule.periodDateTimeMin[index].updateValue();
    $scope.updateHours();
  }
  $scope.checkHours = function(index) {
    $scope.error='';
    if ($scope.selectSchedule.periodDateTimeHour[0].numberValue >= 48) {
      $scope.error = $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_STARTTIME', {hour: 48});
    }
    if ($scope.selectSchedule.type === '-DayOfWeek') {
      if ($scope.selectSchedule.periodDateTimeHour[1].numberValue >= 48) {
        $scope.error = $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_DAY_OF_WEEK_ENDTIME', {hour: 48});
      }
    }
    if ($scope.selectSchedule.periodDateTimeHour[index].numberValue < 0) {
      $scope.selectSchedule.periodDateTimeHour[index].numberValue = 0;
      $scope.selectSchedule.periodDateTimeHour[index].updateDisplayValue();
      $scope.error = $translate.instant('CONTENT.SCHEDULE.MSG.INVALID_VALUE');
    }
    $scope.updateHours();
  }


  $scope.updateMinutes = function() {
    $scope.updateHours();
  }

  $scope.updateHours = function() {

    var startDate = _.cloneDeep($scope.selectSchedule.datecalender.dateValue.date);
    var endDate = _.cloneDeep($scope.selectSchedule.datecalender.dateValue.date);

    var hour0 = $scope.selectSchedule.periodDateTimeHour[0].numberValue;
    var hour1 = $scope.selectSchedule.periodDateTimeHour[1].numberValue;
    while(1) {
      if (hour0 > hour1) {
        //翌日
        hour1 += 24;
      } else {
        break;
      }
    }
    startDate.setHours(hour0);
    startDate.setMinutes($scope.selectSchedule.periodDateTimeMin[0].displayValue);
    endDate.setHours(hour1);
    endDate.setMinutes($scope.selectSchedule.periodDateTimeMin[1].displayValue);

    var hour0Text = ('0' + hour0 % 24).slice(-2);
    var hour1Text = ('0' + hour1 % 24).slice(-2);

    $scope.time1_str = $filter('date')(startDate, 'mediumDate') + ' ' + hour0Text + ':' + checkMinuteString($scope.selectSchedule.periodDateTimeMin[0].displayValue);
    $scope.time2_str = $filter('date')(endDate, 'mediumDate')   + ' ' + hour1Text + ':' + checkMinuteString($scope.selectSchedule.periodDateTimeMin[1].displayValue);
    $scope.selectSchedule.duration = msToTimeString(endDate-startDate, false);

    //見た目を直す
    if ($scope.selectSchedule.type === '-Date') {
      //
    } else if ($scope.selectSchedule.type === '-DayOfWeek' || $scope.selectSchedule.type === '-Everyday') {
      $scope.time1_str = '';
      $scope.time2_str = '';
      if (hour0 >= 24 ) {
        $scope.time1_str = $translate.instant('CONTENT.SCHEDULE.NEXTDAY');
      }
      $scope.time1_str += hour0Text + ":" + $scope.selectSchedule.periodDateTimeMin[0].displayValue;
      $scope.time2_str = '';
      if ($scope.selectSchedule.periodDateTimeHour[1].numberValue >= 24 ) {
        $scope.time2_str = $translate.instant('CONTENT.SCHEDULE.NEXTDAY');
      }
      $scope.time2_str += hour1Text + ":" + $scope.selectSchedule.periodDateTimeMin[1].displayValue;
    }

    //値が不正なら表示はしない
    if (!$scope.selectSchedule.periodDateTimeHour[0].displayValue.length || !$scope.selectSchedule.periodDateTimeMin[0].displayValue.length) {
      $scope.time1_str = '';
      $scope.selectSchedule.duration = '';
    }
    if (!$scope.selectSchedule.periodDateTimeHour[1].displayValue.length || !$scope.selectSchedule.periodDateTimeMin[1].displayValue.length) {
      $scope.time2_str = '';
      $scope.selectSchedule.duration = '';
    }


  }
  Auth.systemProperties().then(function (data) {
    $scope.setting = {
      allContentsShow: data['setting.all-contents.show'] === true,
      restrictionViewGroup: data['setting.restriction.view.group'] === true,
      restrictionEditGroup: data['setting.restriction.edit.group'] === true,
      restrictionCategoryGroup: data['setting.restriction.category.group'] === true,
      embedCodeHostname: data['setting.embed-code.hostname'],
      textColors: data['setting.text-colors']
    };
  });


  function getHour(str) {
    var arTime = str.split(':');
    return parseInt(arTime[0]);
  }
  function getMinute(str) {
    var arTime = str.split(':');
    return parseInt(arTime[1]);
  }

  function initSelectItem() {
    var now = new Date();
    // $scope.selectSchedule.periodDateTimeHour[0].numberValue = now.getHours();
    // $scope.selectSchedule.periodDateTimeHour[0].updateDisplayValue();
    // $scope.selectSchedule.periodDateTimeMin[0].numberValue = now.getMinutes();
    // $scope.selectSchedule.periodDateTimeMin[0].updateDisplayValue();
    // $scope.selectSchedule.periodDateTimeHour[1].numberValue = now.getHours() + 1;
    // $scope.selectSchedule.periodDateTimeHour[1].updateDisplayValue();
    // $scope.selectSchedule.periodDateTimeMin[1].numberValue = now.getMinutes();
    // $scope.selectSchedule.periodDateTimeMin[1].updateDisplayValue();
    // $timeout(function () { $scope.updateHours() }, 0);
  }

  //呼び出し元から渡された内容を展開
  function updateSeletItem() {
    if (!_.isUndefined(RafaleScheduleData.data.enable)) {
      $scope.scheduleEnable = RafaleScheduleData.data.enable;
    }
    //スケジュール
    if (RafaleScheduleData.data.startTime) {
      $scope.selecthours = _.find($scope.hours , function(hh){
        return hh.value === getHour(RafaleScheduleData.data.startTime);
      });
      $scope.selectminutes = _.find($scope.minutes , function(mm){
        return mm.value === getMinute(RafaleScheduleData.data.startTime);
      });
    }
    if (RafaleScheduleData.data.dayOfWeek && RafaleScheduleData.data.dayOfWeek.length ) {
      $scope.selectSchedule.type = '-DayOfWeek';
      var array = RafaleScheduleData.data.dayOfWeek;
      if (array.length >= 7) {  //毎日
        $scope.selectSchedule.type = '-Everyday';
      }
      //設定
      _.forEach(array, function(item) {
        $scope.selectSchedule.dayOfWeek.dayOfWeekValue[item].enable = true;
      });
    } else if (RafaleScheduleData.data.date ) {
      $scope.selectSchedule.type = '-Date';
      if (RafaleScheduleData.data.date) {
        $scope.selectSchedule.datecalender = new NewValue('-Date', new Date(RafaleScheduleData.data.date));
      } else {
        $scope.selectSchedule.datecalender = new NewValue('-Date', new Date());
      }
    } else {
      // $scope.selectSchedule.type = '-Date';
      // if (RafaleScheduleData.data.date) {
      //   $scope.selectSchedule.datecalender = new NewValue('-Date', new Date(RafaleScheduleData.data.date));
      // } else {
      //   $scope.selectSchedule.datecalender = new NewValue('-Date', new Date());
      // }
      $scope.selectSchedule.type = '-DayOfWeek';
      // var array = [0,1,2,3,4,5,6];
      // if (array.length >= 7) {  //毎日
         $scope.selectSchedule.type = '-Everyday';
      // }
      //設定
      _.forEach(array, function(item) {
        $scope.selectSchedule.dayOfWeek.dayOfWeekValue[item].enable = true;
      });

    }
  }

  initHM();
  updateSelectHM();

  //編集の時
  if (RafaleScheduleData && RafaleScheduleData.data) {
    updateSeletItem();
  } else {
    initSelectItem();
  }

  $scope.getdbTimezone();


}]);

