'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('device', {
    url: '/device?debug',
    templateUrl: 'app/device/device.html',
    controller: 'DeviceCtrl',
    title: '端末',
    authenticate: true
  });
}]);
