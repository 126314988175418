'use strict';

var app = angular.module('limaApp');

app.controller('NewLayoutCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$window','$uibModal',
function($scope,socket, Auth, $http,$timeout,$window,$modal) {
  $scope.selectLayoutIndex = -1;
  $scope.selectLayout={};

  $scope.regions = [];
  $scope.plusText = function(index) {
    $scope.regions.push({name:'', x:0,y:0,w:1920,h:1080});
  }

  $scope.regions.push({name:'', x:0,y:0,w:1920,h:1080});

  $scope.selectOptionServiceList = {};
  function getOptionService() {
    $http.get('/api/v1/option-service').
    success(function(datas) {
      $scope.optionServiceList = datas;
      $scope.selectOptionServiceList = datas[0];
    });
  }
  getOptionService();
  ///////////////////////////////////////////////////////////////////////

  // $scope.regions = [];
  // $http.get('/api/v1/regions').
  // success(function(datas) {
  //   $scope.regions = datas.regions;
  //   _.forEach($scope.regions, function(rg){
  //     if (!_.isUndefined(rg.contentId)) {
  //       $http.get('/api/v1/content/contents/' + rg.contentId).
  //       success(function(datas) {
  //         if (isImage(datas)) {
  //           rg.fixImage = {id:rg.contentId, name: datas.title};
  //         }
  //         if (isVideo(datas)) {
  //           rg.fixVideo = {id:rg.contentId, name: datas.title};

  //         }
  //         if (isText(datas)) {
  //           rg.fixText = {id:rg.contentId, name: datas.title};
  //         }

  //       });

  //     }
  //   });
  //   $scope.selectChange();

  // });

  function getRegion(layoutID) {
    $scope.selectLayout.model.regions = _.filter($scope.regions, function(region){
      if (region.LayoutID === layoutID) {
        return true;
      }
    });
    $scope.selectLayout.model.regions = _.sortBy($scope.selectLayout.model.regions, function(item) {
      return item.ZIndex;
    });//.reverse()

  }

  function getMaterialInfo(MaterialID){
    //idと名前
  }

  var color = [
    "#f39700",
    "#e60012",
    "#9caeb7",
    "#00a7db",
    "#009944",
    "#d7c447",
    "#9b7cb6",
    "#00ada9",
    "#bb641d",
    "#e85298",
    "#0079c2",
    "#6cbb5a",
    "#b6007a",
    "#e5171f",
    "#522886",
    "#0078ba",
    "#019a66",
    "#e44d93",
    "#814721",
    "#a9cc51",
    "#ee7b1a",
    "#00a0de"
    ];
    //var canvas = new fabric.Canvas('canvas');

  $scope.SelectedRegion = undefined;
  $scope.selectRegion = function(region){
    // $scope.SelectedRegion = region;
    // $scope.SelectedRegion.rect.selected = true;
    // //canvas.setActiveObject(canvas.item(0));

    $scope.SelectedRegion = region;
    //$scope.SelectedRegion.rect.fill = "#0F0";
    _.filter($scope.selectLayout.model.regions, function(rg,index){
      // canvas.item(index).dirty = true;
      // canvas.item(index).fill = '#000';//color[index];
      if (rg.regionId === region.regionId) {
        // canvas.bringToFront(canvas.item(index));
        // canvas.setActiveObject(canvas.item(index));
        // canvas.item(index).fill = '#0F0';
        return true;
      } else {
        //canvas.item(index).renderAll();

      }
    });
    canvasDraw();
    //canvas.renderAll();

  }
  $scope.isActiveList = function(regionId){
    if (_.isUndefined($scope.SelectedRegion)) {
      return false;

    }
    return $scope.SelectedRegion.regionId === regionId;
  }

  $scope.RegionRect = [];

//   function fabricDraw() {
//     canvas.clear();
//     canvas.isDrawingMode = false;
//     $scope.RegionRect = [];
//     var scale =  1;
//     _.forEach($scope.selectLayout.model.regions, function(region,index){
//       var rect = new fabric.Rect({
//         left: region.LeftIndex / scale,
//         top: region.TopIndex / scale,
//         fill:  '#FFF' ,// color[index],
//         width: region.Width / scale,
//         height: region.Height / scale,
//         backgroundColor: 'transparent',
//       });
//       rect.on('selected', function() {
//         console.log('selected a rectangle');
//       });
// //      $scope.RegionRect.push(rect);
//       region.rect = rect;
//       canvas.add(rect);

//     });

//     document.getElementById('select').addEventListener("click", function(){
//       canvas.isDrawingMode = !canvas.isDrawingMode }, false);

//       var canvas2 = document.getElementById('canvas');
//       if (canvas2.getContext) {
//         var context = canvas2.getContext('2d');
//        // context.scale(0.5,0.5);

//       }
//       canvas.setViewportTransform([0.25, 0, 0, 0.25, 0, 0]);
//   }


  //保存
  function getContentId(region)
  {
    if (region.fixImage) {
      return region.fixImage.id;
    }
    if (region.fixText) {
      return region.fixText.id;
    }
    if (region.fixVideo) {
      return region.fixVideo.id;
    }
    return undefined;
  }

  $scope.saveLayout = function() {


    var postdata = {
      name    : $scope.selectLayout.name  ,
      width   : $scope.selectLayout.width  ,
      height   : $scope.selectLayout.height  ,
      rotate   : 0,
      optionId: $scope.selectOptionServiceList.optionServiceId
    };
    $http.post('/api/v1/layouts' , postdata)
    .success(function(data) {


      _.forEach($scope.regions, function(rg){
        var postdataRg = {
          name    : rg.name  ,
          leftIndex : rg.x,
          topIndex:rg.y,
          width   :rg.w  ,
          height   : rg.h  ,
          zIndex   : 1,
          layoutId : data.id
        };
        $http.post('/api/v1/regions/', postdataRg).
        success(function(dataRg) {
            console.log("region " + dataRg.id);
        });
      });

    });




  }

  $scope.editRegion = function () {

  }

  $scope.updateCanvas = function() {
    canvasDraw() ;
  }

  function canvasDraw() {

    var c=document.getElementById("canvasLayout");
    var ctx=c.getContext("2d");

    var arrRg = [];
    var w = Number($scope.selectLayout.width);
    var h = Number($scope.selectLayout.height);
    var canvas_w = 1920;
    var canvas_h = 1920;
    var diff_w = 0;
    var diff_h = 0;
    if (w > h) {
      //w : h = 1920 : yy
      canvas_h = canvas_w * h / w;
      diff_h = (1920 - canvas_h) / 2;
    } else {
      canvas_w = canvas_h * w / h;
      diff_w = (1920 - canvas_w) / 2;
    }
    ctx.clearRect(0,0,1920,1920);

    _.forEach($scope.regions, function(region,index){
      var rect = {
        left: Number(region.x),
        top: Number(region.y),
        width: Number(region.w),
        height: Number(region.h)
      };
      var bSelect = false;
      if (!_.isUndefined($scope.SelectedRegion)) {
        if ($scope.SelectedRegion.regionId === region.regionId) {
          bSelect = true;
        }
      }
      if (bSelect) {
        ctx.fillStyle = '#fff59d';
      } else {
        ctx.fillStyle = '#e9e9e9';
      }
      ctx.fillRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

      ctx.fillStyle = "#e9e9e9"
      ctx.lineWidth = 4;
      ctx.strokeRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

    });



  }


  $scope.selectChange = function() {
    $scope.selectArea = {};
    $scope.selectLayoutIndex = $scope.selectLayout.model.id;
    getRegion($scope.selectLayout.model.id);
    $timeout(function(){canvasDraw();});
//    $timeout(function(){fabricDraw();});

  }


  $scope.iframestyle = function() {
    if ($scope.selectLayoutIndex === -1) {
      return;
    }
    var val_w1080 ={
      'transform':'scale(0.2)  translate(1080px,0px)',
      'transform-origin':'0px 0px',
      'width': '1080px',
      'height': '1920px',
  };

    var val_w1920 ={
      'transform':'scale(0.23) translate(200px,0px)',
      'transform-origin':'0px 0px',
      'width': '1920px',
      'height': '1080px',
    };

    return $scope.selectLayout.model.width % 180 === 0 ? val_w1920 : val_w1080;
  }



  $scope.font = [
    {name:"", font:"Noto Sans CJK JP Black"},
    {name:"", font:"Noto Sans CJK JP Light"},
    {name:"", font:"Noto Sans CJK JP Bold"},
    {name:"", font:"Noto Sans CJK JP Medium"},
    {name:"", font:"Noto Sans CJK JP DemiLight"},
    {name:"", font:"Noto Sans CJK JP Regular"},
    {name:"", font:"Noto Sans Mono CJK JP Bold"},
    {name:"", font:"Noto Sans Mono CJK JP Regular"}
  ];



  function getPxValue(strVal) {
    var val = strVal.replace("px",""); val = Number(val);
    return val;
  }

  function drawArea(context, val, index, type){
    var font = "50px 'ＭＳ Ｐゴシック'";

    var x = getPxValue(val.left);
    var y = getPxValue(val.top);

    var w = getPxValue(val.width);
    var h = getPxValue(val.height);

    context.strokeStyle="gray";//$scope.layout.body.backgroundColor;
    context.lineWidth=10;//$scope.layout.body.backgroundColor;
    context.fillStyle = "#00F";
    if ($scope.selectArea) {
      if (val.id===$scope.selectArea.id) {
        if (val.type===$scope.selectArea.type) {
          context.fillStyle = "#0FF";
        }
      }
    }
    context.fillRect(x,y,w,h);
    context.strokeRect(x,y,w,h);


    context.textAlign = 'center';
    context.font = font;
    context.fillStyle="gray";//$scope.layout.body.backgroundColor;

    var title = "";
    if (type==="image") {
      title = "画像";
    }
    if (type==="text") {
      title = "テキスト";
    }
    if (type==="marquee") {
      title = "スクロールテキスト";
    }
    if (type==="imagefix") {
      title = "固定";
    }
    context.fillText(title+index, x+w/2,y+50);

  }


  $scope.selectArea = undefined;

  function updataLayout(){
    if ($scope.selectArea) {
      $scope.selectLayout.model.image.find(function(im){
        if ($scope.selectArea.id === im.id) {
          if ($scope.selectArea.type === im.type) {
            im = $scope.selectArea;
            return true;
          }
        }
      });
      if ($scope.selectArea.text) {
        if ($scope.selectArea.text.length) {
          $scope.selectArea.text.find(function(im){
            if ($scope.selectArea.id === im.id) {
              if ($scope.selectArea.type === im.type) {
                im = $scope.selectArea;
                im.fontFamily = $scope.selectArea.selectfont.font;
                return true;
              }
            }
          });
        }
      }
      $scope.selectLayout.model.marquee.find(function(im){
        if ($scope.selectArea.id === im.id) {
          if ($scope.selectArea.type === im.type) {
            im = $scope.selectArea;
            im.fontFamily = $scope.selectArea.selectfont.font;
            return true;
          }
        }
      });
      $scope.selectLayout.model.imagefix.find(function(im){
        if ($scope.selectArea.id === im.id) {
          if ($scope.selectArea.type === im.type) {
            im = $scope.selectArea;
            if (im.text) {
              im.text.forEach(function(tx,index){
                tx.fontFamily = $scope.selectArea.text[index].selectfont.font;
              });
            }
            else if (im.marquee) {
              im.marquee.forEach(function(tx,index){
                tx.fontFamily = $scope.selectArea.marquee[index].selectfont.font;
              });
            }
            return true;
          }
        }
      });
    }

  }

  $scope.canvasmousedown = function(e) {
    updataLayout();

    var rect = e.target.getBoundingClientRect();

    var body_w = getPxValue($scope.selectLayout.model.width);

    var scale = body_w / rect.width;

    var mx = e.clientX - rect.left; mx *= scale;
    var my = e.clientY - rect.top;  my *= scale;

    var bFind = false;
    $scope.selectLayout.model.image.find(function(im){
      var x = getPxValue(im.left);
      var y = getPxValue(im.top);

      var w = getPxValue(im.width);
      var h = getPxValue(im.height);

      if ((x <= mx) && (mx <= (x+w))) {
        if (y <= my && (my <= (y+h))) {
          $scope.selectArea = im;
          bFind = true;
          return true;
        }
      }
    });

    if (!bFind) {
      $scope.selectLayout.model.imagefix.forEach(function(im){
        var x = getPxValue(im.left);
        var y = getPxValue(im.top);

        var w = getPxValue(im.width);
        var h = getPxValue(im.height);

        if ((x <= mx) && (mx <= (x+w))) {
          if (y <= my && (my <= (y+h))) {
              $scope.selectArea = im;
              if (im.text) {
                im.text.forEach(function(tx){
                  tx.selectfont = $scope.font.find(function(f){
                    if (f.font === tx.fontFamily) {
                      return true;
                    }
                  });
                });
              }
              if (im.marquee) {
                im.marquee.forEach(function(tx){
                  tx.selectfont = $scope.font.find(function(f){
                    if (f.font === tx.fontFamily) {
                      return true;
                    }
                  });
                });
              }

            bFind = true;
            return true;
          }
        }
      });
    }

    if (!bFind) {
      $scope.selectLayout.model.marquee.forEach(function(im){
        var x = getPxValue(im.left);
        var y = getPxValue(im.top);

        var w = getPxValue(im.width);
        var h = getPxValue(im.height);

        if ((x <= mx) && (mx <= (x+w))) {
          if (y <= my && (my <= (y+h))) {
              $scope.selectArea = im;
              im.selectfont = $scope.font.find(function(f){
                if (f.font === im.fontFamily) {
                  return true;
                }
              });

            bFind = true;
            return true;
          }
        }
      });
    }

    if (!bFind) {
      $scope.selectLayout.model.text.forEach(function(im){
        var x = getPxValue(im.left);
        var y = getPxValue(im.top);

        var w = getPxValue(im.width);
        var h = getPxValue(im.height);

        if ((x <= mx) && (mx <= (x+w))) {
          if (y <= my && (my <= (y+h))) {
              $scope.selectArea = im;
              im.selectfont = $scope.font.find(function(f){
                if (f.font === im.fontFamily) {
                  return true;
                }
              });
            bFind = true;
            return true;
          }
        }
      });
    }
    console.log($scope.selectArea);
    canvasDraw();
  }

}]);

