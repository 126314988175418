'use strict';

import {TimecodeUtil, Timecode_CdrTime, Timecode_CdrTimeCode} from './TimecodeUtil.js';
import {TLSeekBar} from './seekbar.js';
import {ProfileFunc} from '../transcode/preset/preset.controller.js';

angular.module('limaApp')
.directive('rafaleTouchmove', ['$timeout', function($timeout) {
  return function(scope, element, attr) {

    element.on('touchmove', function(event) {
      $timeout(function() {
        if (attr.rafaleTouchmove === 'timeline') {
          scope.mouseoperation.onTouchMove(event);
        }
      });
    });
  };
}]).directive('rafaleTouchstart', ['$timeout', function($timeout) {
  return function(scope, element, attr) {

    element.on('touchstart', function(event) {
      $timeout(function() {
        if (attr.rafaleTouchstart === 'timeline') {
          scope.mouseoperation.onTouchStart(event);
        } else if (attr.rafaleTouchstart === 'btnSkipRew1') {
          scope.videooperation.startSkip_rew(-1);
        } else if (attr.rafaleTouchend === 'btnSkipRew10') {
          scope.videooperation.startSkip_rew(-10);
        } else if (attr.rafaleTouchend === 'btnSkipFF') {
          scope.videooperation.startSkip_ff();
        }
      });
    });
  };
}]).directive('rafaleTouchend', ['$timeout', function($timeout) {
  return function(scope, element, attr) {

    element.on('touchend', function(event) {
      $timeout(function() {
        if (attr.rafaleTouchend === 'timeline') {
          scope.mouseoperation.onTouchEnd(event);
        } else if (attr.rafaleTouchend === 'btnSkipRew1') {
          scope.videooperation.stopSkip_rew();
        } else if (attr.rafaleTouchend === 'btnSkipRew10') {
          scope.videooperation.stopSkip_rew();
        } else if (attr.rafaleTouchend === 'btnSkipFF') {
          scope.videooperation.stopSkip_ff();
        }
      });
    });
  };
}])
.controller('EditCtrl', ['$scope', '$cookieStore', '$stateParams', '$http', '$location', '$timeout', '$interval' , '$translate', '$localStorage', '$window', '$uibModal', '$q', '$filter', 'Auth', 'Modal', 'socket' ,
function($scope, $cookieStore, $stateParams, $http, $location, $timeout, $interval , $translate, $localStorage, $window, $modal, $q, $filter, Auth, Modal, socket ) {
  $scope.storage = (function () {
    var key = 'limaApp:EditCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};

    var store = function () {
      if (!$scope.contentInfo.disableplayer) {  //画面が有効になってから保存は開始する
        storage[$scope.contentInfo.contentId] = {};
        if ($scope.arrInOut.length) {
          storage[$scope.contentInfo.contentId].inout = [];
          _.forEach($scope.arrInOut, function(elem){
            storage[$scope.contentInfo.contentId].inout.push({in: elem.frame_in, out: elem.frame_out});
          });
        }
        if ($scope.arrStillInfo.length) {
          storage[$scope.contentInfo.contentId].still = [];
          _.forEach($scope.arrStillInfo, function(elem){
            storage[$scope.contentInfo.contentId].still.push({position:elem.position});
          });
        }
        $localStorage[key] = storage;
      }
    };
    //常に保存する場合は以下を有効にする
    //var promise = null;
    // $scope.$watch(function() {
    //   return angular.toJson([$scope.arrInOut, $scope.arrStillInfo]);
    // },
    // function() {
    //   if (promise) $timeout.cancel(promise);
    //   promise = $timeout(function () { store(); }, 10);
    // });
    $scope.$on('$destroy', function () {
      //store();
      if ($scope.watchtab) {$scope.watchtab();}
      $scope.videooperation.vidpause(); //念のため

      angular.element($window).off('resize', onResizeA);
      angular.element($window).off('keydown', $scope.handleKeydown);
    });
    return {
      storage:storage,
      store:store
    };

  })();



  $scope.basename = (function(path) {
    return path.replace(/\/+$/, '').replace( /.*\//, '' );
  });

  //コンテンツの情報
  var ContentInfo = function(id) {
    this.contentId = id;
    //src
    this.proxy = '';      //プロキシ：MP4
    this.original = '';   //オリジナルの素材URL：MP4ではないかもしれないURL
    //v
    this.framerate = [];
    this.framerate.src =[];       //APIから取得したフレームレート
    this.framerate.checked =[];   //チェックして正しい値にしたフレームレート
    this.framesize = [];

    this.framesize.width = 1920;  //初期化
    this.framesize.height = 1080;

    this.aspect = [];

    this.aspect.width = 16;  //初期化
    this.aspect.height = 9;


    this.framerate.src.numerator = 30000;
    this.framerate.src.denominator = 1001;
    this.framerate.checked.numerator = 30000;
    this.framerate.checked.denominator = 1001;
    this.df = false;

    this.disableplayer = true;  //画面の有効状態

    this.mp4Files=[];
    this.activemp4File = undefined;
  };

  ContentInfo.prototype.getStillSize = function(){
    //w:h=xxx:72
    var stillHeight = 72;
    var w = this.framesize.height / this.aspect.height * this.aspect.width;
    return {width: parseInt(stillHeight * w / this.framesize.height), height: stillHeight };
  }

  ContentInfo.prototype.setFramesize = function( w , h ){
    this.framesize.width = parseInt(w);
    this.framesize.height = parseInt(h);
  }

  ContentInfo.prototype.setAspect = function( w , h ){
    this.aspect.width = parseInt(w);
    this.aspect.height = parseInt(h);
  }

  ContentInfo.prototype.setFramerate = function( num , den ){
    this.framerate.src.numerator = parseInt(num);
    this.framerate.src.denominator = parseInt(den);
    this.checkFramerate();
  };

  ContentInfo.prototype.checkFramerate = function(){
    var fr100 = (this.framerate.src.numerator / this.framerate.src.denominator ) * 100;
    var FrameRate = [];

      if (fr100 === 6000) {                  // 60
        FrameRate.Numerator = 60;
        FrameRate.Denominator = 1;
      }
      else if (5900 < fr100 && fr100 < 6000) {        // 59.94
        FrameRate.Numerator = 60000;
        FrameRate.Denominator = 1001;
        this.df = true;
      }
      else if (4900 < fr100 && fr100 < 5100) {        // 50
        FrameRate.Numerator = 50;
        FrameRate.Denominator = 1;
      }
      else if (fr100 === 3000) {                // 30
        FrameRate.Numerator = 30;
        FrameRate.Denominator = 1;
      }
      else if (2900 < fr100 && fr100 < 3000) {        // 29.97
        FrameRate.Numerator = 30000;
        FrameRate.Denominator = 1001;
        this.df = true;
      }
      else if (2400 < fr100 && fr100 < 2600) {        // 25
        FrameRate.Numerator = 25;
        FrameRate.Denominator = 1;
      }
      else if (fr100 === 2400) {                // 24
        FrameRate.Numerator = 24;
        FrameRate.Denominator = 1;
      }
      else if (2300 < fr100 && fr100 < 2400) {        // 23.97
        FrameRate.Numerator = 24000;
        FrameRate.Denominator = 1001;
      }
      else {
        FrameRate.Numerator = this.numerator;
        FrameRate.Denominator = this.denominator;
      }

      this.framerate.checked.numerator = FrameRate.Numerator;
      this.framerate.checked.denominator = FrameRate.Denominator;
    };

  $scope.contentInfo = new ContentInfo($stateParams.contentId);
  //
  $scope.launchApp = false;
  if (!_.isUndefined($stateParams.launchapp)) {
    $scope.launchApp = $stateParams.launchapp === 'false' ? false : true;
  }
  $scope.showPresetConfig = true;
  if (!_.isUndefined($stateParams.presetconfig)) {
    $scope.showPresetConfig = $stateParams.presetconfig === 'false' ? false : true;
  } else {
    if (!Auth.hasFunction('SystemManagement')){
      $scope.showPresetConfig = false;
    } else {
      $http.get('/api/v1/configurations/features/transcode').then(function (res) {
        if (!res.data) {
          $scope.showPresetConfig = false;
        }
      });
    }
  }


  function setSrcToPreview(){
    if ($scope.contentInfo.mp4Files.length) {
      if (_.isUndefined($scope.contentInfo.activemp4File)) {
        $scope.contentInfo.activemp4File = 0;
      } else {
        $scope.contentInfo.activemp4File++;
        if ($scope.contentInfo.mp4Files.length <= $scope.contentInfo.activemp4File) {
          //err
          return false;
        }
      }
      $scope.contentInfo.proxy = $scope.contentInfo.mp4Files[$scope.contentInfo.activemp4File].filename;
      $scope.contentInfo.setFramerate($scope.contentInfo.mp4Files[$scope.contentInfo.activemp4File].framerate.num, $scope.contentInfo.mp4Files[$scope.contentInfo.activemp4File].framerate.den);
      $scope.contentInfo.setAspect($scope.contentInfo.mp4Files[$scope.contentInfo.activemp4File].aspect.width,$scope.contentInfo.mp4Files[$scope.contentInfo.activemp4File].aspect.height);
      $scope.contentInfo.setFramesize($scope.contentInfo.mp4Files[$scope.contentInfo.activemp4File].frameSize.width,$scope.contentInfo.mp4Files[$scope.contentInfo.activemp4File].frameSize.height);
      angular.element('#VideoPreview')[0].src = $scope.contentInfo.proxy;
      return true;

    }
    return false;
  }

  function GetFolderName(FileTypeID){
    var sDCMFolder=[
      {
        "FileTypeID": 4,
        "FolderName":"Telop"
      },
      {
        "FileTypeID": 16,
        "FolderName":"HTML"
      },
      {
        "FileTypeID": 128,
        "FolderName":"QuickTime"
      },
      {
        "FileTypeID": 2,
        "FolderName":"Still"
      },
      {
        "FileTypeID": 32,
        "FolderName":"Audio"
      }
    ];


    var res = _.find(sDCMFolder, function(folder) {
      if (folder.FileTypeID === FileTypeID) {
        return true;
      }
    });
    if (res) {
      return res.FolderName;
    }
    return "";
  }


  //APIの戻りを解析する
  function analyzeContentsRes(res)
  {
    var err = true;
    if (res && res.data && res.data.files && res.data.files.length) {
      $scope.contentInfo.title = res.data.title;
      //mp4探す
       $scope.contentInfo.activemp4File = undefined; //初期化

      var requests = res.data.files.map(function(file){
        var deferred = $q.defer();

        if (file.formatName.indexOf('mp4') !== -1) {
          var videoInfo = {};
          if (file.videoStreams && file.videoStreams.length) {
            if (file.videoStreams[0].width && file.videoStreams[0].height){
              videoInfo.frameSize = {width:file.videoStreams[0].width, height:file.videoStreams[0].height};
            }
            if (file.videoStreams[0].aspectRatio) {
              var arrAspect = file.videoStreams[0].aspectRatio.split(':');
              if (arrAspect && arrAspect.length === 2) {
                videoInfo.aspect = {width:arrAspect[0], height:arrAspect[1]};
              }
            }
            if (!videoInfo.aspect) {
              videoInfo.aspect = {width:1, height:1};
            }
            var arrFR = file.videoStreams[0].frameRate.split('/');
            if (arrFR && arrFR.length === 2) {
              videoInfo.framerate = {num:arrFR[0], den:arrFR[1]};

              // var req3 = {method:'get', url:'/api/version_2_0/content/contents/' + encodeURIComponent($scope.contentInfo.contentId) + '/url?original&&fileId=' + file.id};
              // $http(req3).then(function (res3) {
              videoInfo.filename = file.url;
              $scope.contentInfo.mp4Files.push(videoInfo);
              // })
              // .finally(function () {
                deferred.resolve();
              // });
            }
          }
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      });
      $q.all(requests).finally(function (results) {
        if (!$scope.contentInfo.mp4Files.length) {
          //プレビューできるURLがない
          angular.element('#rafale-edit')[0].disable = true;
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('CONTENT.NO_PLAYABLE_FILES'), modalClass:'modal-warning'});
        } else {
          setSrcToPreview();
        }
      });
    }
  }

  //コンテンツの情報をコンテンツIDから取得
  $scope.initializeVideo = (function() {
    var req = {method:'get', url:'/api/v1/content/contents/' + encodeURIComponent($scope.contentInfo.contentId)};
    $http(req).then(function (res) {
      analyzeContentsRes(res);
    });
  });

  $scope.inTimeText = '--:--:--;--';
  $scope.outTimeText = '--:--:--;--';
  $scope.setting = { concatInOut: false };
  $scope.transcodelist = [];
  $scope.arrInOut=[];
  $scope.arrStillInfo=[];
  $scope.selectProfile=[];
  $scope.selectProfileInfo=[];
  $scope.selectProfileInfo.format = '';
  $scope.selectProfileInfo.video = [];
  $scope.selectProfileInfo.audio = [];

  //プリセット取得
  function UpdateProfiles() {
    Auth.systemProperties().then(function (data) {
      $scope.transcode.profiles = [];
      _.forEach(data, function (val , key) {
        if (key.indexOf('transcode.profile.') === 0) {  //transcode.profile.で始まるもの
          $scope.transcode.profiles.push(val);
        }
      });
      //edit可能のものだけさらに列挙
      $scope.transcode.profiles = _.filter($scope.transcode.profiles, function (profile) {
        if ( profile.parameters ) {
          var elems = _.filter(profile.parameters, function (param) {
            if ( param['type'] === 'edit-inout') {
              return true;
            }
          });
          if (elems && elems.length) {
            return true;
          }
        }
      });
      //copy指定のものは結果が正しくなくなるので省く
      $scope.transcode.profiles = _.filter($scope.transcode.profiles, function (profile) {
        if ( profile.options ) {
          var elems = _.filter(profile.options, function (param) {
            if ( param === 'copy') {
              return true;
            }
          });
          return (elems && elems.length) ? false : true;
        }
      });

      $scope.transcode.enabled = !_.isEmpty($scope.transcode.profiles);
      if ($scope.transcode.enabled) {
        $http.get('/api/v1/configurations/features/transcode').then(function (res) {
          $scope.transcode.enabled = res.data;
        });
      }
      if ( $scope.transcode.profiles.length ) {
        $scope.selectProfile = $scope.transcode.profiles[0];
        $scope.CreateProfileInfo();
      }
    });
  }

  UpdateProfiles();

  //プリセットの情報
  $scope.CreateProfileInfo = (function() {
    //ng-modelのselectProfileがよくわからないので、いったんこの方法でIndexをとる
    if (!angular.element('.presetSelect')[0]) {
      $timeout(function (){
        $scope.CreateProfileInfo();
      },1000);
      return;
    }
    var index = angular.element('.presetSelect')[0].selectedIndex;
    var test = $scope.transcode.profiles[index];

    var res = ProfileFunc($translate).analyze($scope.transcode.profiles[index].options, $scope.transcode.profiles[index].parameters ) ;
    //console.log(res);
    $scope.selectProfileInfo.format = '';
    $scope.selectProfileInfo.video = [];
    $scope.selectProfileInfo.audio = [];

    if (!res) return;



    $scope.selectProfileInfo.format = $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.FORMAT') + ': ' + res.val.container.codec.name;
    $scope.selectProfileInfo.video = [];
    $scope.selectProfileInfo.video.push( '[' + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.VIDEO')  + ']' );
    $scope.selectProfileInfo.video.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.CODEC') + ': ' + res.val.video.codec.name);
    if (res.check.video.framesize) {
      $scope.selectProfileInfo.video.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.FRAMESIZE') + ': ' + res.val.video.framesize.w + 'x' + res.val.video.framesize.h);
    } else {
      $scope.selectProfileInfo.video.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.FRAMESIZE') + ': ' + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.MESSAGE_SRC_SAME') );
    }
    if (res.check.video.bitrate) {
      $scope.selectProfileInfo.video.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.BITRATE') + ': ' + res.val.video.bitrate + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.KBPS'));
    } else {
      $scope.selectProfileInfo.video.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.BITRATE') + ': ' + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.MESSAGE_SRC_SAME') );
    }

    $scope.selectProfileInfo.audio = [];
    $scope.selectProfileInfo.audio.push('[' + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.AUDIO')  + ']');
    $scope.selectProfileInfo.audio.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.CODEC') + ': ' + res.val.audio.codec.name);
    if (res.check.audio.bitrate) {
      $scope.selectProfileInfo.audio.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.BITRATE') + ': ' + res.val.audio.bitrate + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.KBPS'));
    } else {
      $scope.selectProfileInfo.audio.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.BITRATE') + ': ' + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.MESSAGE_SRC_SAME') );
    }
    if (res.check.audio.sample) {
      $scope.selectProfileInfo.audio.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.SAMPLINGRATE') + ': ' + res.val.audio.sample.name + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.HZ'));
    } else {
      $scope.selectProfileInfo.audio.push($translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.SAMPLINGRATE') + ': ' + $translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.MESSAGE_SRC_SAME') );
    }
    $scope.selectProfileInfo.etc = !res.val.analyzeRes ? $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.PARAM_ANLYZE_OTHER_PARAM') : '';

  });

  //////関数
  $scope.SecondToTimecodeString = (function(second) {
    return TimecodeUtil.SecondToTimecodeString(second , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
  });

  var formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  $scope.FormatTime = (function(time) {
    return formatDate(new Date(time) , 'YYYY/MM/DD hh:mm:ss');
  });


  //描画関数
  var DrawOperation = (function() {

      var canvas = document.getElementById('cvSeekBar');

      function UpdateTL()
      {
        canvas= window.parent.document.getElementById('cvSeekBar');

        var rect = canvas.getBoundingClientRect();
        var dur = $scope.videooperation.GetDuration();
        var pos =  $scope.videooperation.GetCurrentTime();
        //rect.width : duration = xxx : pos
        var x = pos * rect.width / dur;;

        draw(x,0);

      }

      /*
      //描画処理
      */
      function initdraw() {

        var SeekbarOption = {
          durationSeconds: $scope.videooperation.GetDuration(),
          textTop: 35,
          textBottom: 55,
          numerator:$scope.contentInfo.framerate.checked.numerator,
          denominator: $scope.contentInfo.framerate.checked.denominator,
          elementCanvasId: 'cvSeekBar',
          df: $scope.contentInfo.df,
          canvasFont: 'bold 16px' + ' ' + "'Helvetica Neue', Helvetica, Arial, sans-serif",
          colorScale: '#fff',
          colorText:'#515151'
        };

        TLSeekBar.initialize(SeekbarOption);
      }

      function draw(x_cur,y)
      {
          var ctx = canvas.getContext('2d');
          var rect = canvas.getBoundingClientRect();
          {

            ctx.fillStyle = '#484848';
            ctx.fillRect(0,0,rect.width,canvas.height);
            ctx.fillStyle = '#c3c3c3';
            ctx.fillRect(0,0,rect.width,55);
            ctx.fillStyle = '#fff';
            ctx.fillRect(0,35,rect.width,20);

          }
          var fontfamily = "'Helvetica Neue', Helvetica, Arial, sans-serif";

          var nInFrame=-1 , nOutFrame=-1;
          var x1_b=-1 , x2_b=-1;
          $scope.arrInOut.forEach(function (inout , index) {
            nInFrame = inout.frame_in;
            nOutFrame = inout.frame_out;
          // if ( ( nInFrame == -1 ) ||  ( nOutFrame == -1 ) ) {
            //     //何もしない
            // } else {
              if (canvas.getContext){
                var dur = $scope.videooperation.GetDuration();
                var dur_frame = TimecodeUtil.SecondToFrame( dur , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df );

                //rect.width : duration = xxx : seekpos
                var x1 = nInFrame * rect.width / dur_frame;;
                var x2 = nOutFrame  * rect.width / dur_frame;;
                if ( nOutFrame + 1  == dur_frame ) {
                  x2 = rect.width;
                }

                //console.log( '%d:%d-%d x1:%d x2:%d' , index,nInFrame , nOutFrame , x1 , x2 );
                ctx.fillStyle = '#000';
                ctx.globalAlpha = 0.4;

                if ( !index ) {
                  ctx.fillRect(0,0,x1,35);
                }
                if (index) {
                  ctx.fillRect(x2_b,0,x1-x2_b,35);
                }
                if ( index == $scope.arrInOut.length - 1 ) {
                  ctx.fillRect(x2,0,rect.width-x2,35);
                }

                ctx.globalAlpha = 1;


                ctx.fillStyle = '#668cf8';
                ctx.fillRect(x1,0,x2-x1,35);

                ctx.fillStyle = '#fff';
                ctx.fillRect(0,35,rect.width,20);


                ctx.strokeStyle = '#ff5e5e';
                ctx.beginPath();
                ctx.moveTo(x1,0);
                ctx.lineTo(x1,35);
                ctx.closePath();
                ctx.stroke();
                ctx.beginPath();
                ctx.moveTo(x2,0);
                ctx.lineTo(x2,35);
                ctx.closePath();
                ctx.stroke();

                ctx.font = 'bold 14px' + ' ' + fontfamily;
                ctx.fillStyle = '#5ED9FF';
                ctx.textAlign = 'center';
                //ctx.fillText('IN' ,x1,70);
                //ctx.fillText('OUT' ,x2,70);
                ctx.textAlign = 'left';

                x1_b = x1;
                x2_b = x2;
            }
          });


          {
            //タイムライン
            TLSeekBar.DrawTimeScale();

            //TLカーソル
            //context.fillStyle = 'red';
            ctx.strokeStyle = '#0000ff';
            ctx.beginPath();
            ctx.moveTo(x_cur,0);
            ctx.lineTo(x_cur,35);
            ctx.closePath();
            ctx.stroke();

            ctx.strokeStyle = '#fff';
            //ctx.strokeRect(0,0,rect.width,50);
            //ctx.strokeRect(0,0,rect.width,35);

            ctx.beginPath();
            ctx.moveTo(0,0);
            ctx.lineTo(0,35);
            ctx.moveTo(rect.width,0);
            ctx.lineTo(rect.width,35);
            ctx.closePath();
            ctx.stroke();


          }
      }
      return {
        UpdateTL: UpdateTL,
        initdraw: initdraw,
        draw:draw
      };
    })();

    if (typeof KeyEvent == "undefined") {
      var KeyEvent = {
          DOM_VK_CANCEL: 3,
          DOM_VK_HELP: 6,
          DOM_VK_BACK_SPACE: 8,
          DOM_VK_TAB: 9,
          DOM_VK_CLEAR: 12,
          DOM_VK_RETURN: 13,
          DOM_VK_ENTER: 14,
          DOM_VK_SHIFT: 16,
          DOM_VK_CONTROL: 17,
          DOM_VK_ALT: 18,
          DOM_VK_PAUSE: 19,
          DOM_VK_CAPS_LOCK: 20,
          DOM_VK_ESCAPE: 27,
          DOM_VK_SPACE: 32,
          DOM_VK_PAGE_UP: 33,
          DOM_VK_PAGE_DOWN: 34,
          DOM_VK_END: 35,
          DOM_VK_HOME: 36,
          DOM_VK_LEFT: 37,
          DOM_VK_UP: 38,
          DOM_VK_RIGHT: 39,
          DOM_VK_DOWN: 40,
          DOM_VK_PRINTSCREEN: 44,
          DOM_VK_INSERT: 45,
          DOM_VK_DELETE: 46,
          DOM_VK_0: 48,
          DOM_VK_1: 49,
          DOM_VK_2: 50,
          DOM_VK_3: 51,
          DOM_VK_4: 52,
          DOM_VK_5: 53,
          DOM_VK_6: 54,
          DOM_VK_7: 55,
          DOM_VK_8: 56,
          DOM_VK_9: 57,
          DOM_VK_SEMICOLON: 59,
          DOM_VK_EQUALS: 61,
          DOM_VK_A: 65,
          DOM_VK_B: 66,
          DOM_VK_C: 67,
          DOM_VK_D: 68,
          DOM_VK_E: 69,
          DOM_VK_F: 70,
          DOM_VK_G: 71,
          DOM_VK_H: 72,
          DOM_VK_I: 73,
          DOM_VK_J: 74,
          DOM_VK_K: 75,
          DOM_VK_L: 76,
          DOM_VK_M: 77,
          DOM_VK_N: 78,
          DOM_VK_O: 79,
          DOM_VK_P: 80,
          DOM_VK_Q: 81,
          DOM_VK_R: 82,
          DOM_VK_S: 83,
          DOM_VK_T: 84,
          DOM_VK_U: 85,
          DOM_VK_V: 86,
          DOM_VK_W: 87,
          DOM_VK_X: 88,
          DOM_VK_Y: 89,
          DOM_VK_Z: 90,
          DOM_VK_CONTEXT_MENU: 93,
          DOM_VK_NUMPAD0: 96,
          DOM_VK_NUMPAD1: 97,
          DOM_VK_NUMPAD2: 98,
          DOM_VK_NUMPAD3: 99,
          DOM_VK_NUMPAD4: 100,
          DOM_VK_NUMPAD5: 101,
          DOM_VK_NUMPAD6: 102,
          DOM_VK_NUMPAD7: 103,
          DOM_VK_NUMPAD8: 104,
          DOM_VK_NUMPAD9: 105,
          DOM_VK_MULTIPLY: 106,
          DOM_VK_ADD: 107,
          DOM_VK_SEPARATOR: 108,
          DOM_VK_SUBTRACT: 109,
          DOM_VK_DECIMAL: 110,
          DOM_VK_DIVIDE: 111,
          DOM_VK_F1: 112,
          DOM_VK_F2: 113,
          DOM_VK_F3: 114,
          DOM_VK_F4: 115,
          DOM_VK_F5: 116,
          DOM_VK_F6: 117,
          DOM_VK_F7: 118,
          DOM_VK_F8: 119,
          DOM_VK_F9: 120,
          DOM_VK_F10: 121,
          DOM_VK_F11: 122,
          DOM_VK_F12: 123,
          DOM_VK_F13: 124,
          DOM_VK_F14: 125,
          DOM_VK_F15: 126,
          DOM_VK_F16: 127,
          DOM_VK_F17: 128,
          DOM_VK_F18: 129,
          DOM_VK_F19: 130,
          DOM_VK_F20: 131,
          DOM_VK_F21: 132,
          DOM_VK_F22: 133,
          DOM_VK_F23: 134,
          DOM_VK_F24: 135,
          DOM_VK_NUM_LOCK: 144,
          DOM_VK_SCROLL_LOCK: 145,
          DOM_VK_COMMA: 188,
          DOM_VK_PERIOD: 190,
          DOM_VK_SLASH: 191,
          DOM_VK_BACK_QUOTE: 192,
          DOM_VK_OPEN_BRACKET: 219,
          DOM_VK_BACK_SLASH: 220,
          DOM_VK_CLOSE_BRACKET: 221,
          DOM_VK_QUOTE: 222,
          DOM_VK_META: 224
      };
  }

  $scope.handleKeydown = function(event) {
    //console.log("handleKeydown");
    if (modalOpening) {
      //modalオープン中のときはキーボードショートカット処理はしない
      return;
    }

    var stopDefaultAction = true;
    if (event.keyCode === KeyEvent.DOM_VK_I && !event.shiftKey ){ //in
      $scope.inoutoperation.video_in();
    } else if (event.keyCode === KeyEvent.DOM_VK_O && !event.shiftKey ){ //out
      $scope.inoutoperation.video_out();
    } else if ((event.keyCode === KeyEvent.DOM_VK_ENTER) || (event.keyCode === KeyEvent.DOM_VK_SPACE) || (event.keyCode === KeyEvent.DOM_VK_RETURN)) { //play , preview
        if ($scope.videooperation.isPaused()) {
        $scope.videooperation.vidplay(event.shiftKey);
      } else {
        $scope.videooperation.vidpause();
      }
    } else if (event.keyCode === KeyEvent.DOM_VK_D && !event.shiftKey ){ //divide
      $scope.inoutoperation.divide();
    } else if (event.keyCode === KeyEvent.DOM_VK_J && !event.shiftKey ){ //<<
      $scope.videooperation.skip_throttle(-10); //間引きでskip
    } else if (event.keyCode === KeyEvent.DOM_VK_K && !event.shiftKey ){ //play
      if ($scope.videooperation.isPaused()) {
        $scope.videooperation.vidplay(event.shiftKey);
      } else {
        $scope.videooperation.setPlaybackRate(1);//1倍速にして再生
        $scope.videooperation.vidplay(event.shiftKey);
      }
    } else if (event.keyCode === KeyEvent.DOM_VK_L ){ //>>
      $scope.videooperation.VideoForward()
    } else if (event.keyCode === KeyEvent.DOM_VK_RIGHT && !event.shiftKey ){ //>
      $scope.videooperation.skip_throttle(1); //間引きでskip
    } else if (event.keyCode === KeyEvent.DOM_VK_LEFT && !event.shiftKey ){ //<
      $scope.videooperation.skip_throttle(-1); //間引きでskip
    } else if (event.keyCode === KeyEvent.DOM_VK_UP && !event.shiftKey ){ // 前のポイント
      $scope.inoutoperation.seek_backpoint();
    } else if (event.keyCode === KeyEvent.DOM_VK_DOWN && !event.shiftKey ){ //次のポイント
      $scope.inoutoperation.seek_nextpoint();
    } else if (event.keyCode === KeyEvent.DOM_VK_T ){ //still
      $scope.stilloperation.save();
    } else if (event.keyCode === KeyEvent.DOM_VK_HOME && event.shiftKey ){ //to 0
      $scope.videooperation.seek(0);
    } else if (event.keyCode === KeyEvent.DOM_VK_END && event.shiftKey  ){ //to end
      $scope.videooperation.seek($scope.videooperation.GetDuration());
    } else if (event.keyCode === KeyEvent.DOM_VK_Z && event.ctrlKey ){ //undo
      $scope.actionoperation.undo();
    } else if (event.keyCode === KeyEvent.DOM_VK_Y && event.ctrlKey ){ //redo
      $scope.actionoperation.redo();
    } else {
      stopDefaultAction = false;
    }
    if(event.preventDefault && stopDefaultAction){
      // デフォルトの動作を無効化する
      event.preventDefault();
    }
  }

  //マウス操作
  $scope.mouseoperation = (function() {

    var dragFlag = false;//対象画像上でマウスボタンを押すと、true
    var prevX,prevY;//ドラック中における前のイベントのマウス座標
    var dragObj = null;
    var offsetLeft = 0;
    var offsetTop = 0;

    var mousepos = -1;
    function mouse_seek(x , rect, moveflag)
    {
      if ( !moveflag ) {
        mousepos = x;
      } else {
        if (mousepos === x) {
            //前回と同じところが指定された
            // console.log('mouse_seek same pos ');
            return;
        }
        mousepos = x;
      }

      var dur = $scope.videooperation.GetDuration();
      var durFrame = TimecodeUtil.SecondToFrame(dur , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
      var seekFrame = durFrame * x / rect.width;

      //rect.width : duration = x : seekpos
      var seeksec_b = dur * x / rect.width;
      var seeksec = TimecodeUtil.FrameToSecond(seekFrame , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);

      $scope.videooperation.seek(seeksec);
      // console.log('mouse_seek ' + seeksec);

    }
    //mousemove時のシーク間引き処理
    var mouse_seek_throttle = _.throttle(function(x , rect, moveflag) {
      mouse_seek(x , rect, moveflag);
    },300);

    function onClick(e) {
      /*
       * rectでcanvasの絶対座標位置を取得し、
       * クリック座標であるe.clientX,e.clientYからその分を引く
       * ※クリック座標はdocumentからの位置を返すため
       * ※rectはスクロール量によって値が変わるので、onClick()内でつど定義
       */
      if ($scope.contentInfo.disableplayer) return;

      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top;

      DrawOperation.draw(x,y);

      mouse_seek_throttle(x, rect, false);
    }

    var mousemoveTimer = 0;
    var mousemove_seeksec = -1;
    function onMouseMove(itEvent) {

      if ($scope.contentInfo.disableplayer) return;


      var itX = offsetLeft;//divB要素の左からの位置を得る。
      var mx = itEvent.clientX-itX;//divB内の相対座標を得る
      //（上記では、offsetXに相当する値であるがFireFoxが使えないので相当する情報を算術している）
      var itY = offsetTop;//ページの上からのdivB要素位置を得る。
      var my = itEvent.clientY-itY;
      my += document.documentElement.scrollTop + document.body.scrollTop;//ページがスクロールされている場合にも対応させる。

      var rect = itEvent.target.getBoundingClientRect();
      mx = itEvent.clientX - rect.left;
      my = itEvent.clientY - rect.top;

      if(dragFlag){//ドラック中か？
        var dx = mx - prevX;//現在もマウス位置と 前の位置で、移動量を求める
        //var objX = dragObj.offsetLeft +  dx;
        //dragObj.style.left = objX + 'px';//対象画像の移動
        prevX =mx;//ドラックでの移動量を求めるため、現在の位置を移動前の情報として記憶

        //var dy = my - prevY;//現在もマウス位置と 前の位置で、移動量を求める
        //var objY = dragObj.offsetTop +  dy;
        //dragObj.style.top = objY + 'px';//対象画像の移動
        prevY =my;//ドラックでの移動量を求めるため、現在の位置を移動前の情報として記憶


        DrawOperation.draw(mx,0);

        mouse_seek_throttle(mx , rect, true);
      }
    }

    function onMouseDown(itEvent) {
      document.body.style.cursor = 'default';

      if ($scope.contentInfo.disableplayer) return;


      var itX = offsetLeft;//divB要素の左からの位置を得る。
      var mx = itEvent.clientX-itX;//divB内の相対座標を得る
      //（上記では、offsetXに相当する値であるがFireFoxが使えないので相当する情報を算術している）
      var itY = offsetTop;//ページの上からのdivB要素位置を得る。
      var my = itEvent.clientY-itY;
      my += document.documentElement.scrollTop + document.body.scrollTop;//ページがスクロールされている場合にも対応させる。
      // (Safariではscroll系が未対応で、0の加算になることを期待して作っている。)

      var rect = itEvent.target.getBoundingClientRect();

      var imgX = 0;//document.getElementById('imgB').offsetLeft;//画像imgBの位置、幅、高さを取得/
      var imgW = rect.width;//document.getElementById('imgB').offsetWidth;
      var imgY = 0;//document.getElementById('imgB').offsetTop;
      var imgH = rect.height;;//document.getElementById('imgB').offsetHeight;

      mx = itEvent.clientX - rect.left;
      my = itEvent.clientY - rect.top;

      //if(mx > imgX && mx < imgX+imgW && my > imgY && my < imgY+imgH)
      {//画像の範囲にマウス位置があるかどうかを調べる。
        dragFlag = true;//ドラック開始
        ///dragObj = document.getElementById('imgB');
        prevX = mx;//ドラックでの移動量を求めるため、現在位置を移動前の情報として記憶
        prevY = my;

        DrawOperation.draw(mx,0);

        mouse_seek_throttle(mx , rect, false);
      }
    }

    function onMouseUp(itEvent) {
      dragFlag = false;//ドラック終了
      document.body.style.cursor = 'auto';

      $interval.cancel(mousemoveTimer);
      mousemoveTimer = 0;

      //ちゃんと最後のseek位置をseekしておく
      //$scope.videooperation.seek(mousemove_seeksec);

    }
    function checkTouchEvent(event) {
      var touches = null;
      if (event.changedTouches && event.changedTouches.length) {
          //AC: this is original library case
          touches = event.changedTouches;
      } else if (event.originalEvent && event.originalEvent.changedTouches && event.originalEvent.changedTouches.length) {
          //AC: this is fix actually working
          touches = event.originalEvent.changedTouches;
      } else {
          touches = [event];
      }
      return _.isArrayLike(touches) ? touches[0] : touches;
    }
    function onTouchStart(event){
      onMouseDown(checkTouchEvent(event));
    }
    function onTouchMove(event){
      onMouseMove(checkTouchEvent(event));
    }
    function onTouchEnd(event){
      onMouseUp(checkTouchEvent(event));
    }
    return {
      onClick: onClick,
      onMouseMove: onMouseMove,
      onMouseDown: onMouseDown,
      onMouseUp:onMouseUp,
      onTouchStart: onTouchStart,
      onTouchMove: onTouchMove,
      onTouchEnd: onTouchEnd
    };

  })();

  $scope.checkedInoutlist = [];
  $scope.UpdateCheckedInout = (function() {
    $scope.checkedInoutlist = _.filter($scope.arrInOut, {checked:true} );
  });

  $scope.checkedstill = [];
  $scope.UpdateCheckedStill = (function() {
    $scope.checkedstill = _.filter($scope.arrStillInfo, {checked:true} );
  });

  $scope.checkedtranslist = [];
  $scope.checkedVerifylist = [];
  $scope.UpdateCheckedTranslist = (function() {
    $scope.checkedtranslist = _.filter($scope.transcodelist, {checked:true, status:{status:'start'}} );
    $scope.checkedVerifylist = _.filter($scope.transcodelist, function(elem){
      if (elem.checked) {
        if (elem.status.status !== 'start') {
          return true;
        }
      }
    });
  });

  $scope.getStatusString = function(status) {
    if (status.status === 'start') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.START');
    }
    else if (status.status === 'finished') {
      return $translate.instant('COMMON.DONE');
    }
    else if (status.status === 'finishing') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.FINISHING');
    }
    else if (status.status === 'abort') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.ABORT');
    }
    else if (status.status === 'aborting') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.STATUS.ABORTING');
    }
    else if (status.status === 'error') {
      return $translate.instant('CONTENT.TRANSCODE_LIST.ERR') + '(' + status.err + ')';
    }
    return status.status;
  };

  $scope.activeTabIndex = 0;

  //トランスコード
  $scope.transcode = (function() {

    var statusUpdateTimer = null;
    var statusUpdateDelay = 1000;
    var lastTranscodesLength = 0;
    var resultMessage = '';

    function verify(id) {
      var defer = $q.defer();
      $http.put('/api/v1/content/transcodes/list/' + id, {appinfo:{verified:true}})
      .success(function (res) {
        defer.resolve(res);
      }).error(function (data) {
        defer.reject(data);
      });
      return defer.promise;
    }

    function confirmVerify() {
      var defer = $q.defer();

      Modal.confirm['delete'](function (yesno) {
        return !yesno ? defer.reject() : defer.resolve();
      })($translate.instant('CONTENT.TRANSCODE_LIST.CONFIRM_DELETE_TEXT'), 'no');
      return defer.promise;
    }


    function verifylist() {
      var promise = confirmVerify();
      promise.then(
        function() { //成功
          var arrTran = _.filter($scope.transcodelist, {checked:true});
          arrTran = _.filter(arrTran, function(elem){
            if (elem.status.status !== 'start') {
              return true;
            }
          });

          var requests = arrTran.map(function (elem) {
            var deferred = $q.defer();

            $http.put('/api/v1/content/transcodes/list/' + elem._id, {appinfo:{verified:true}})
            .finally(function () {
                //console.log(elem._id);
                deferred.resolve();
              }) ;

            return deferred.promise;
          });

          $q.all(requests).finally(function (results) {
            // results配列の各要素で結果が取れる
            status();
          });
        },
        function() { //失敗
          //なにもしない
        }
      );
    }

    function status() {
      if (statusUpdateTimer) $timeout.cancel(statusUpdateTimer);
      statusUpdateTimer = null;
      var contentId = $scope.contentInfo.contentId;
      if (contentId) {
        $http.get('/api/v1/content/transcodes/list?contentId=' + contentId).success(function (arr) {
          var arrlist = arr || [];

          //verifiedになっていない　かつ　編集したものだけ列挙
          arrlist = _.filter(arrlist,function(elem){
            if (elem.appinfo) {
              if (elem.appinfo.verified) {
                return false;
              }
            }
            if (elem.task.parameters) {
              var findEditParam = _.find(elem.task.parameters, function(param){
                if (param.type === 'edit-inout') {
                  if (param.value) {
                    if (param.value.length) {
                      return true;
                    }
                  }
                }
              });
              if (findEditParam) {
                return true;
              }
            }
            return false;
          });
          var startElem = _.find(arrlist, {status : { status: 'start' }} ); //進行中のものがあるかどうか
          if (!startElem) {
            startElem = _.find(arrlist, {status : { status: 'finishing' }} ); //終了処理中のものがあるかどうか
          }
          if (!startElem) {
            startElem = _.find(arrlist, {status : { status: 'aborting' }} ); //キャンセル処理中のものがあるかどうか
          }

          if (startElem) {
            statusUpdateTimer = $timeout(function () { $scope.transcode.status(); }, statusUpdateDelay);
          } else {
            $scope.selectcolumn_info = '';
          }
           _.forEach(arrlist, function(obj) {
              var obj_trans = _.find($scope.transcodelist, {status : { starttime: obj.status.starttime }} );
              if (obj_trans) {
                obj.checked = !obj_trans.checked ? false : obj_trans.checked;
              }
          });

          if ($scope.transcodelist.length !== arrlist.length) {
            $scope.transcodelist = arrlist;
          } else {
            //変更のあったものだけ更新する
            _.forEach(arrlist, function(elem){
              _.find($scope.transcodelist,function(list){
                if (elem._id === list._id) {
                  list.status = elem.status;
                  if (elem.posttask) {
                    list.posttask = elem.posttask;
                  }
                  return true;
                }
              })
            });
          }

          //出力タブ表示中の時はテーブルに幅が初期化されてしまうので、カラム幅を設定する
          if ($scope.activeTabIndex === 2 ) {
            $timeout(function () { checkTableColumnWidth(); }, 0);
          }

        }).error(function (err) {
          //console.log('failed to get transodings: ' + err);
          lastTranscodesLength = 0;
          $scope.transcodings = null;
          $scope.transcoded = null;
        });
      } else {
        lastTranscodesLength = 0;
        $scope.transcodings = null;
        $scope.transcoded = null;
      }

    }

    function download(content, file) {
      var req = {method:'get', url:'/api/version_2_0/content/contents/' + encodeURIComponent($scope.contentInfo.contentId) + '/url?download&fileId=' + encodeURIComponent(file.id)};
      $http(req).then(function (res) { $window.open(res.data.url, '_self'); });
      return false;
    }
    function abort() {
      var arrTran = _.filter($scope.transcodelist, {checked:true} );

      if ( arrTran.length ) {
        Modal.confirm.yesno({
          title: $translate.instant('CONTENT.TRANSCODE.ABORT.TITLE'),
          html: $translate.instant('CONTENT.TRANSCODE.ABORT.MESSAGE'),
          btnClassYes: 'btn-danger',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-default',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {

          if (yes) {
            arrTran.forEach(function(element) {
              $http({method: 'delete', url: '/api/v1/content/transcodes/' + element.task.unique});
            });
          }
        });
      }
    }

    function deletestill(index) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.DELETE') ,
        html: $translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_DELETE_STILL'),
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.DELETE'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.CANCEL'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;

        $scope.arrStillInfo = _.filter($scope.arrStillInfo , function (v) {
          if (v.checked){
            return false;
          }
          return true;  //チェックついてないのだけ残す
        });
        $scope.UpdateCheckedStill();
      });
    }

    function savestill(position , type, tabactive) {
      var uniqueid = parseInt(position * 1000);
      var stillSize = $scope.contentInfo.getStillSize();
      if ( type == 'still' ) {
        if (!_.find($scope.arrStillInfo, function(info){ return (info.uniqueid === uniqueid) ? true : false; })) {
          //配列内にない
          $http.put('/api/v1/content/contents/' + $scope.contentInfo.contentId + '/stillimage',
                    {time: position < 0 ? 0 : position, uniqueid:uniqueid, type: 'base64', width: stillSize.width, height: stillSize.height})
          .success(function (res) {

            var index = _.findIndex($scope.arrStillInfo, function(info){ return (info.position === position) ? true : false; });
            if (index === -1) {
              $scope.arrStillInfo.push({position:position, outurl:res.base64, checked: false, uniqueid:uniqueid});
            } else {
              $scope.arrStillInfo[index] = {position:position, outurl:res.base64, checked: false, uniqueid:uniqueid};
            }

            $scope.arrStillInfo = _.orderBy($scope.arrStillInfo, ['position'],['asc']);
            if (tabactive) {  //tabをアクティブにする設定なら
              $scope.activeTabIndex = 1;
            }

          });
        } else {
          //取得済み：選択状態にだけする
          $scope.activeTabIndex = 1;
        }
        $scope.onResize();

      } else {
        //静止画の配列内にあるかチェック
        var findStill = _.find($scope.arrStillInfo, function(info){ return info.uniqueid === uniqueid ? true : false; })

        if (!findStill) {
          //配列内にない
        } else {
          //あり
          _.find($scope.arrInOut, function(obj_io) {
            if ( uniqueid == parseInt(obj_io.sec_in * 1000) ) {
              obj_io.image_in = findStill.outurl;
              obj_io.image_in_uniqueid = uniqueid;
            }
            if ( uniqueid == parseInt(obj_io.sec_out * 1000) ) {
              obj_io.image_out = findStill.outurl;
              obj_io.image_out_uniqueid = uniqueid;
            }
          });
          return;
        }

        $http.put('/api/v1/content/contents/' + $scope.contentInfo.contentId + '/stillimage',
                  {time: position < 0 ? 0 : position, uniqueid:uniqueid, type: 'base64', width: stillSize.width, height: stillSize.height})
        .success(function (res) {
          //サムネイルを探してセット
          //まだサムネイルがないものを探す
          _.forEach($scope.arrInOut, function(obj_io) {
            if ( position == obj_io.sec_in ) {
              obj_io.image_in = res.base64;
              obj_io.image_in_uniqueid = uniqueid;
            }
            if ( position == obj_io.sec_out ) {
              obj_io.image_out = res.base64;
              obj_io.image_out_uniqueid = uniqueid;
            }
          });
        });
      }
    }

    function transcode() {

      if ( $scope.arrInOut && $scope.arrInOut.length ) {
        var arrIO_Enc = [];
        if ($scope.setting.concatInOut) {
          var arrIO = [];
          $scope.arrInOut.forEach( function(elem){
            if (elem.checked) {
              var io = {in:0,out:0};
              io.in = elem.sec_in;
              io.out = elem.sec_out;
              arrIO.push(io);
            }
          });
          arrIO_Enc.push(arrIO);
        } else {
          $scope.arrInOut.forEach( function(elem){
            if (elem.checked) {
              var arrIO = [];
              var io = {in:0,out:0};
              io.in = elem.sec_in;
              io.out = elem.sec_out;
              arrIO.push(io);
              arrIO_Enc.push(arrIO);
            }
          });
        }

        _.forEach( arrIO_Enc , function(elem){

          var index = angular.element('.presetSelect')[0].selectedIndex;
          var req = {method:'post', url:'/api/v1/content/transcodes', data:{transcode:$scope.transcode.profiles[index].id, contentId:$scope.contentInfo.contentId , register:'new'}};

          //INOUT情報をパラメータに追記
          var nCount = 0;
          var opt = [];
          elem.forEach(function(element) {
            if (element.out - element.in > 0) {
              opt.push('-ss');
              opt.push('' + element.in);

              opt.push('-t');
              opt.push('' + (element.out - element.in));

              opt.push('-i');
              opt.push("'$(infile)'");

              nCount++;
            }
          });
          if (nCount && $scope.setting.concatInOut){
            opt.push('-filter_complex');
            opt.push("'concat=n=" + nCount + "':v=1:a=1'");
          }
          if (nCount){
            req.data['$(input)'] = opt;

            //console.log('encode' + elem);

            $http(req).then(function (res) {
              $scope.transcode.resultMessage = $translate.instant('CONTENT.VIDEO_EDIT.MSG.START_TRANSCODE');
              $timeout(function (){
                $scope.transcode.resultMessage = '';
              },5000);

              $scope.transcode.status();
            });
          }
        });

        return false;
      }
    }
    return {
      resultMessage: resultMessage,
      download: download,
      abort: abort,
      transcode:transcode,
      deletestill: deletestill,
      savestill:savestill,
      status: status,
      verifylist: verifylist
    };
  })();

  //ビデオ操作
  $scope.videooperation = (function() {

    var timeSkip = 500;
    var timerplaying = 0;
    var arrplaybackRate = {
                            rew:[-1.0,-2.0,-4.0] ,
                            slow_rew:[-0.5 , -0.25] ,
                            slow_ff:[0.5 , 0.25] ,
                            ff:[1.0,2.0,4.0]
                          };
    var video = document.getElementById('VideoPreview');

    //iPhoneでvideoをinlineで再生する
    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0) {
      video.setAttribute('webkit-playsinline', 'webkit-playsinline');
      video.setAttribute('playsinline', 'playsinline');
    }

    var nVideoOffset= 0;
    var seekpos=0;
    var ntimeupdate = 0;
    //var playbackRate = 1;

    function video_timeupdate(){
      var video = angular.element('#VideoPreview')[0];
      var diff =0;
      if (!video) return;

      if ( ntimeupdate != video.currentTime ) {
        diff = video.currentTime - ntimeupdate;
        ntimeupdate = video.currentTime;
      }
      // if (diff) {
      //   console.log('##video_timeupdate:diff ' + diff + ' video ' + video.currentTime + ' ' + seekpos  + ' ' + TimecodeUtil.SecondToTimecodeString($scope.videooperation.GetCurrentTime() , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df));
      // }  else {
      //   console.log('  video_timeupdate:diff ' + diff + ' video ' + video.currentTime + ' ' + seekpos  + ' ' + TimecodeUtil.SecondToTimecodeString($scope.videooperation.GetCurrentTime() , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df));
      // }
      //UpdateTime();
    }

    function getDevice() {
      var ua = navigator.userAgent;
      if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
          return 'sp';
      }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
          return 'tab';
      }else{
          return 'other';
      }
    };

    function video_seeking() {
      // console.log('video_seeking');
    }
    function video_seeked() {
      var video = angular.element('#VideoPreview')[0];
      if ( nVideoOffset==0 && seekpos != video.currentTime ) {
        nVideoOffset = video.currentTime - seekpos;
        //console.log('video_seeked:nVideoOffset = ' +  nVideoOffset);
        UpdateTime();
      }
      // console.log("video_seeked");
    }

    function video_loadeddata() {
      var video = angular.element('#VideoPreview')[0];

      //Loadできたのでシークバー初期化
      DrawOperation.initdraw();

      //先頭がずれる対策：
      $timeout(function() {
        $scope.videooperation.seek(0);
      }, 100);
      var loop = $interval(function() {
        $scope.videooperation.skip(-1);
      } , 100 , 3 );    //3回くらいトライ

      //intervalの処理が終わったら
      loop.then(function(){
        if ($scope.contentInfo.proxy.length) {
          //前の状態を復元
          importFromLocalStorage();
          //INOUT変更による再描画など
          UpdateTimeByInOut();
          //GUI利用可能とする
          $scope.contentInfo.disableplayer = false;
        }
      });

      //ここまで
    }
    function video_ended() {
      clearInterval(timerplaying);
      timerplaying = 0;
    }

    function video_error()
    {
      clearInterval(timerplaying);
      timerplaying = 0;

      if (!video || !video.error) {
        return;
      }
      var errMes;
      switch (video.error.code) {
        case MediaError.MEDIA_ERR_ABORTED:
          errMes = $translate.instant('CONTENT.VIDEO_EDIT.MSG.ERR_MEDIA_ERR_ABORTED');
          break;
        case MediaError.MEDIA_ERR_NETWORK:
          errMes = $translate.instant('CONTENT.VIDEO_EDIT.MSG.ERR_MEDIA_ERR_NETWORK');
          break;
        case MediaError.MEDIA_ERR_DECODE:
          errMes = $translate.instant('CONTENT.VIDEO_EDIT.MSG.ERR_MEDIA_ERR_DECODE');
          break;
        case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
          if (!setSrcToPreview()) {
            errMes = $translate.instant('CONTENT.VIDEO_EDIT.MSG.ERR_MEDIA_ERR_SRC_NOT_SUPPORTED');
            break;
          } else {
            return;
          }
        default:
          errMes = $translate.instant('CONTENT.VIDEO_EDIT.MSG.ERR_MEDIA_ERR_DEFAULT');
          break;
      }
      Modal.confirm.message({title:$translate.instant('CONTENT.TRANSCODE_LIST.ERR'), html:errMes, modalClass:'modal-warning'});

    }

    function setPlaybackRate(rate) {
      $timeout(function (){
        video.playbackRate = rate;
        // console.log("playrate:" + rate);
      });
    }

    function IsPlaybakRewind(){
      // ユーザーエージェント情報を取得
      var user_agent = navigator.userAgent;

      // Lunascape を除外したい場合
      //if(user_agent.indexOf('Lunascape') != -1)  return false;

      // Chromium 派生ブラウザ
      if(user_agent.indexOf('Chrome') != -1)  return false;

      return true;;
    }

    function GetDuration()
    {
        return       video.duration;
    }

    function GetCurrentTime()
    {
      return video.currentTime -  nVideoOffset;
    }
    function isPaused()
    {
      return video.paused;
    }

    var previewplay = false;
    var previewplay_index = 0;
    var previewtimer = 0;

    function preview()
    {
      if (!$scope.arrInOut.length) {
        return;
      }

      //プレビュー再生(+shift)
      previewplay = true;
      previewplay_index = 0;

      seek($scope.arrInOut[previewplay_index].sec_in);
      video.play();

      var sec = TimecodeUtil.FrameToSecond(1 , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
      previewtimer = $interval(function() {
        if ( $scope.videooperation.GetCurrentTime() >= $scope.arrInOut[previewplay_index].sec_out ) {
          previewplay_index++;
          if (previewplay_index >= $scope.arrInOut.length) {
              //終了
              $interval.cancel(previewtimer);
              previewtimer = 0;
              $scope.videooperation.vidpause();
          } else {
            seek($scope.arrInOut[previewplay_index].sec_in);
          }
        }
      }, sec * 1000);


    }

    function vidplay(previewflag) {
      if (video.paused) {
        if (previewflag){
          $timeout(function() {
            preview();
          });

        } else {
          $timeout(function() {
            video.play();
          });
        }
        timerplaying = setInterval(UpdateTime, 100);
      } else {
         //video.pause();
      }
    }

    function getPlaybackString() {
      return video.paused ? '' : 'x ' + video.playbackRate;
    }

    function vidpause() {
      if (video.paused) {
          //video.play();
      } else {
          video.pause();
      }
      clearInterval(timerplaying);
      timerplaying = 0;

      setPlaybackRate(1);
      previewplay = false;
      previewplay_index = 0;

    }

    function VideoForward(slow) {
      //速度の指定は、1.0が標準で、2なら2倍速、0.5なら半分の速度となります。
      // if ($event.altKey){}
      // if ($event.ctrlKey){}
      // if ($event.shiftKey){}

      var i;
      var bSetRate = false;
      if (slow){
        //スロー
        for (i=0; i < arrplaybackRate.slow_ff.length; i++) {
          if (arrplaybackRate.slow_ff[i] == video.playbackRate) {
            if (i!=arrplaybackRate.slow_ff.length-1) {
              setPlaybackRate(arrplaybackRate.slow_ff[i+1]);
              bSetRate = true;
              break;
            }
          }
        }
        if (!bSetRate) {
          setPlaybackRate(arrplaybackRate.slow_ff[0]);
        }
      } else {
        //通常
        for (i=0; i < arrplaybackRate.ff.length; i++) {
          if (arrplaybackRate.ff[i] == video.playbackRate) {
            if (i!=arrplaybackRate.ff.length-1) {
              setPlaybackRate(arrplaybackRate.ff[i+1]);
            } else {
              setPlaybackRate(arrplaybackRate.ff[0]);
            }
            bSetRate = true;
            break;
          }
        }
        if (!bSetRate) {
          setPlaybackRate(1);
        }
      }
     vidplay();
    }

    function VideoRewind($event) {
      var i;
      var bSetRate = false;
      if ($event.shiftKey){
        //スロー
        for (i=0; i < arrplaybackRate.slow_rew.length; i++) {
          if (arrplaybackRate.slow_rew[i] == video.playbackRate) {
            if (i!=arrplaybackRate.slow_rew.length-1) {
              setPlaybackRate(arrplaybackRate.slow_rew[i+1]);
              bSetRate = true;
              break;
            }
          }
        }
        if (!bSetRate) {
          setPlaybackRate(arrplaybackRate.slow_rew[0]);
          $timeout(function() {
            setPlaybackRate(arrplaybackRate.slow_rew[0]);
          }, 100);

        }
      } else {
        //通常
        for (i=0; i < arrplaybackRate.rew.length; i++) {
          if (arrplaybackRate.rew[i] == video.playbackRate) {
            if (i!=arrplaybackRate.rew.length-1) {
              setPlaybackRate(arrplaybackRate.slow_rew[i+1]);
            } else {
              setPlaybackRate(arrplaybackRate.slow_rew[0]);
            }
            bSetRate = true;
            break;
          }
        }
        if (!bSetRate) {
          setPlaybackRate(-1.0);
          $timeout(function() {
            setPlaybackRate(-1.0);
          }, 100);
        }
      }
     vidplay();
    }

    var skiptimer = 0;
    function startSkip_ff() {
      //console.log('startSkip_ff');
      stopSkip_ff();
      // timeSkipミリ秒単位に処理を実行
      skiptimer = $interval(function() {
        skip(1);
      }, timeSkip);
    }

    function stopSkip_ff() {
      //console.log('stopSkip_ff');
      $interval.cancel(skiptimer);
      skiptimer = 0;
    }

    function startSkip_rew_sec(sec) {
      // timeSkipミリ秒単位に処理を実行
      stopSkip_ff();
      skiptimer = $interval(function() {
        skip(-1 * TimecodeUtil.SecondToFrame(sec , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df));
      }, timeSkip*2);
    }

    function startSkip_rew(frame) {
      // timeSkipミリ秒単位に処理を実行
      stopSkip_ff();
      skiptimer = $interval(function() {
        skip(frame);
      }, timeSkip);
    }

    function stopSkip_rew() {
      $interval.cancel(skiptimer);
      skiptimer = 0;
    }

    function skip_sec(sec) {
      //1frame
      vidpause();
      seek( GetCurrentTime() + TimecodeUtil.SecondToFrame(sec,$scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df) );
    }

    function skip(frame) {
      //1frame
      vidpause();
      var sec = GetCurrentTime() + TimecodeUtil.FrameToSecond(frame,$scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
      var frame_2 = TimecodeUtil.SecondToFrame(GetCurrentTime(),$scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
      frame_2 += frame;
      var sec_2 = TimecodeUtil.FrameToSecond(frame_2,$scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);

      //console.log('skip  sec = ' + sec + ' frame_calc = ' + sec_2 + ' frame: ' + frame_2);

      seek( sec_2 );
    }

    //skip間引き処理
    var skip_throttle = _.throttle(function(frame) {
      skip(frame);
    },300);


    function seek(sec) {
      if (!video.seeking) {
        // console.log('seek ' + sec);
      } else if (video.seeking) {
        //console.log('seeking ' + sec);
        video.pause();  //念のため
        return;
      }
      var sec6 = parseFloat(sec.toFixed(6));
      // console.log("seek before " + video.currentTime + " now " + (sec6 + nVideoOffset));
      //下三桁チェック：ミリ秒
      var now = parseFloat(video.currentTime.toFixed(3));
      var after = parseFloat((sec6 + nVideoOffset).toFixed(3));
      if (now === after) {
        // 同じ位置を指定しようとしているのでシークしない
        // console.log("seek before now same ==> retun");
        return;
      }
      video.currentTime = sec6 + nVideoOffset;
      seekpos = sec6 + nVideoOffset;

      //画面更新:applyも
      $timeout(function() {UpdateTime()});
    }

    function seekByFrame(frame,num , den) {
      video.currentTime =  TimecodeUtil.FrameToSecond(frame,num , den, $scope.contentInfo.df);

      UpdateTime();
    }

    return {
      //event
      video_timeupdate:video_timeupdate,
      video_seeking:video_seeking,
      video_seeked:video_seeked,
      video_loadeddata: video_loadeddata ,
      video_ended: video_ended,
      video_error: video_error,
      //function
      startSkip_rew_sec:startSkip_rew_sec,
      skip_sec:skip_sec,
      startSkip_ff: startSkip_ff,
      stopSkip_ff: stopSkip_ff,
      startSkip_rew: startSkip_rew,
      stopSkip_rew: stopSkip_rew,
      getPlaybackString: getPlaybackString,
      IsPlaybakRewind: IsPlaybakRewind,
      GetDuration: GetDuration,
      GetCurrentTime: GetCurrentTime,
      vidplay: vidplay,
      vidpause: vidpause,
      VideoForward: VideoForward,
      VideoRewind: VideoRewind,
      skip: skip,
      seek: seek,
      seekByFrame: seekByFrame,
      setPlaybackRate: setPlaybackRate,
      isPaused: isPaused,
      //value
      playbackRate: video.playbackRate,
      timerplaying: timerplaying,
      skip_throttle: skip_throttle
    };

  })();

  $scope.actionoperation = (function() {
    var actionList = [];
    var currentActionIndex = -1;
    var maxActionSize = 11; //10回戻すためには10+1=11
    var modifiedData = false;


    function addAction(type, data) {
      //current位置から後ろは削除する
      if (currentActionIndex != actionList.length -1) { //最後尾でなければ削除
        while(1){
          if (actionList.length -1 !== currentActionIndex) {
            actionList.pop();
          } else {
            break;
          }
        }
      }
      actionList.push({type: type, data: data});
      if (actionList.length > maxActionSize) {
        // 先頭を削除
        actionList.shift() ;
      }
      currentActionIndex = actionList.length -1; //current位置をpushしたところにする
      $scope.actionoperation.modifiedData = true;
    }

    function doAction() {
      //データ入れ替え。
      $scope.arrInOut = _.cloneDeep(actionList[currentActionIndex].data);

      //サムネイルチェック
      _.forEach($scope.arrInOut, function(elem){
        if (!elem.image_in) {
          $scope.transcode.savestill(elem.sec_in , 'in');
        }
        if (!elem.image_out) {
          $scope.transcode.savestill(elem.sec_out , 'out');
        }
      });
      //INOUT変更による再描画など
      UpdateTimeByInOut();
    }

    //undo , redo
    function undo() {
      if (IsEnableUndo()) {
        currentActionIndex -= 1;
        doAction();
      }
    }

    function redo() {
      if (IsEnableRedo()) {
        currentActionIndex += 1;
        doAction();
      }
    }

    function IsEnableUndo() {
      if (currentActionIndex) {
        return true;
      }
      return false;
    }
    function IsEnableRedo() {
      if ((currentActionIndex+1) < actionList.length ) {
        return true;
      }
      return false;
    }

    return {
      addAction: addAction,
      undo: undo,
      redo: redo,
      IsEnableUndo: IsEnableUndo,
      IsEnableRedo: IsEnableRedo,
      modifiedData: modifiedData
    }
  })();

  //INOUT
  $scope.inoutoperation = (function() {
    var checkedInOutAll = false;
    var enableIn = false;
    var enableOut = false;
    var enableDivide = false;

    function checkInOutAll()
    {
      $scope.arrInOut = _.forEach($scope.arrInOut , function (v) {
        v.checked = $scope.inoutoperation.checkedInOutAll;
      });
      $scope.UpdateCheckedInout();
    }

    function deleteInOut(index)
    {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.DELETE'),
        html: $translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_DELETE_INOUT'),
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.DELETE'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.CANCEL'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;

        $scope.arrInOut = _.filter($scope.arrInOut , function (v) {
          return !v.checked;
        });

        $scope.actionoperation.addAction('inout' , _.cloneDeep($scope.arrInOut));
        //すべてチェック用のチェックはOFFにする
        if ($scope.inoutoperation.checkedInOutAll) {
          $scope.inoutoperation.checkedInOutAll = false;
        }
        $scope.UpdateCheckedInout();

        //ボタンの有効無効
        $scope.inoutoperation.UpdateInOutEnable();

        DrawOperation.UpdateTL();
      });
    }

    function InOutPos(_i,_o)
    {
      this.frame_in = _i;
      this.frame_out = _o;
      this.frame_dur = 0;

      this.sec_in = 0;
      this.sec_out = 0
      this.sec_dur = 0;


      this.tc_text_in = '';
      this.tc_text_out = '';
      this.tc_text_dur = 0;

      this.image_in = '';
      this.image_out = '';

      this.checked = false;
    };

    function UpdateInOut( sec_in , sec_out )
    {
      var index = _.findIndex($scope.arrInOut, function(obj) {
        if ( (obj.sec_in === sec_in) || ((obj.sec_out === sec_out)) ) {
          return true;
        }
      });

      if (index !== -1 ){
        $scope.arrInOut[index].tc_text_in = TimecodeUtil.FrameToTimecodeString($scope.arrInOut[index].frame_in , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
        $scope.arrInOut[index].tc_text_out = TimecodeUtil.FrameToTimecodeString($scope.arrInOut[index].frame_out , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
        $scope.arrInOut[index].frame_dur = $scope.arrInOut[index].frame_out - $scope.arrInOut[index].frame_in + 1;  //out含む
        $scope.arrInOut[index].tc_text_dur = TimecodeUtil.FrameToTimecodeString($scope.arrInOut[index].frame_dur , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
      }
    }
    function SortInOut()
    {
      $scope.arrInOut.sort(function(a,b){
        if(a.frame_in < b.frame_in) return -1;
        if(a.frame_in > b.frame_in) return 1;
        return 0;
      });
    }

    //指定されたIn,Outで追加
    function AddInOut(_i , _o , num , den) {  //秒で設定
      //in,outを追加する
      //念のためにIN/OUTの範囲に入ってないかどうかを確認しておく
      var isIncludeIO = $scope.arrInOut.forEach(function (inout) {
        if ( ( inout.sec_in <= _i  ) && ( _i <= inout.sec_out ) ) {
          //既存のIN/OUTの中
          return true;
        }
        else if ( ( inout.sec_in <= _o  ) && ( _o <= inout.sec_out ) ) {
          //既存のIN/OUTの中
          return true;
        }
        return false;
      });
      if (isIncludeIO) {
        return; //含まれているので追加しない
      }

      var newIO = new InOutPos;
      newIO.sec_in = _i;
      newIO.sec_out = _o;
      newIO.frame_in = TimecodeUtil.SecondToFrame(_i , num , den, $scope.contentInfo.df);
      newIO.frame_out = TimecodeUtil.SecondToFrame(_o , num , den, $scope.contentInfo.df);
      $scope.arrInOut.push(newIO);

      //ソートする
      SortInOut();
    }

    function SetIn_multi(_i , num , den) {  //秒で設定
      //window.console.log('SetIn = ' + String(_i));
      var res_io=[];
      var i;
      var frame = TimecodeUtil.SecondToFrame(_i, num, den, $scope.contentInfo.df);
      var second = _i;
      var bSet = false;
      if ( !$scope.arrInOut.length ) {
        //新規にIN/OUT作る
        var newIO = new InOutPos;
        newIO.sec_in = second;
        newIO.frame_in = frame;
        newIO.frame_out = TimecodeUtil.SecondToFrame($scope.videooperation.GetDuration() , num , den, $scope.contentInfo.df) - 1;
        newIO.sec_out = TimecodeUtil.FrameToSecond(newIO.frame_out, num , den, $scope.contentInfo.df);
        $scope.arrInOut.push(newIO);
        res_io = newIO; //結果
      } else {
        $scope.arrInOut.forEach(function (inout) {
          if ( ( inout.frame_in <= frame  ) && ( frame <= inout.frame_out ) ) {
            //既存のIN/OUTの中
            inout.frame_in = frame;
            inout.sec_in = second;
            inout.image_in = '';
            bSet = true;
            res_io = inout; //結果
            return true;
          }
        });

        if ( !bSet ) {  //IN/OUT範囲外
          for ( i = 0; i < $scope.arrInOut.length; i++ ) {
            if ( frame < $scope.arrInOut[i].frame_in ) {
              //このINOUTのINを変更する
              $scope.arrInOut[i].frame_in = frame;
              $scope.arrInOut[i].sec_in = second;
              $scope.arrInOut[i].image_in = '';
              res_io = $scope.arrInOut[i]; //結果
              bSet = true;
              break;
            }
            else if ( $scope.arrInOut[i].frame_out < frame ) {
              if ( i == $scope.arrInOut.length-1 ) {  //最後尾のINOUT
                //新規にIN/OUT作る
                var newIO = new InOutPos;
                newIO.frame_in = frame;
                newIO.sec_in = second;
                newIO.frame_out = TimecodeUtil.SecondToFrame($scope.videooperation.GetDuration() , num , den, $scope.contentInfo.df) - 1;
                newIO.sec_out = TimecodeUtil.FrameToSecond(newIO.frame_out, num , den, $scope.contentInfo.df);
                bSet = true;
                $scope.arrInOut.push(newIO);
                //ソート
                SortInOut();
                res_io = newIO; //結果
                break;

              } else if ($scope.arrInOut[i+1].frame_in > frame ) {  //次のINOUTよりは前にある
                //次のINOUTのINをここにする
                $scope.arrInOut[i+1].frame_in = frame;
                $scope.arrInOut[i+1].sec_in = second;
                $scope.arrInOut[i+1].image_in = '';
                res_io = $scope.arrInOut[i+1];
                bSet = true;
                break;
              }
            }
          }
        }
        if ( !bSet ) {
          alert(false); //ありえない
        }
      }
      return res_io;
    };

    function SetOut_multi(_o , num , den) {  //秒で設定
      //window.console.log('SetOut = ' + String(_o));


      var res_io=[];
      var i;
      var frame = TimecodeUtil.SecondToFrame(_o , num , den, $scope.contentInfo.df);
      var bSet = false;
      if ( !$scope.arrInOut.length ) {
        //新規にIN/OUT作る
        var newIO = new InOutPos;
        newIO.frame_in = 0;
        newIO.frame_out = TimecodeUtil.SecondToFrame(_o , num , den, $scope.contentInfo.df);
        newIO.sec_in = 0;
        newIO.sec_out = _o;
        $scope.arrInOut.push(newIO);
        res_io = newIO; //結果

      } else {
        $scope.arrInOut.forEach(function (inout) {
          if ( ( inout.frame_in <= frame  ) && ( frame <= inout.frame_out ) ) {
            //既存のIN/OUTの中
            inout.frame_out = frame;
            inout.sec_out = _o;
            inout.image_out = '';
            res_io = inout; //結果
            bSet = true;
            return true;
          }
        });

        if ( !bSet ) {  //IN/OUT範囲外
          for ( i = 0; i < $scope.arrInOut.length; i++ ) {
            if ( frame > $scope.arrInOut[i].frame_out ) {
              if ( i == $scope.arrInOut.length-1 ) {
                //このINOUTのOUTを変更する
                $scope.arrInOut[i].frame_out = frame;
                $scope.arrInOut[i].sec_out = _o;
                $scope.arrInOut[i].image_out = '';
                res_io = $scope.arrInOut[i]; //結果
                bSet = true;
              } else if ( frame < $scope.arrInOut[i+1].frame_in ) { //次のINOUTのINよりは小さい
                //このINOUTのOUTを変更する
                $scope.arrInOut[i].frame_out = frame;
                $scope.arrInOut[i].sec_out = _o;
                $scope.arrInOut[i].image_out = '';
                res_io = $scope.arrInOut[i]; //結果
                bSet = true;
              }
              if (bSet) {
                break;
              }
            }
            else if ( $scope.arrInOut[i].frame_in > frame ) {
              if (!i) {
                //先頭のINOUTよりも前にOUTを設定する場合
                //新規にIN/OUT作る
                var newIO = new InOutPos;
                newIO.frame_out = frame;
                newIO.sec_out = _o;
                //先頭のINOUTになるのでINは０
                newIO.frame_in = 0;
                newIO.sec_in = 0;
                $scope.arrInOut.push(newIO);
                //ソート
                SortInOut();
                bSet = true;
                res_io = newIO; //結果
              }
              if (bSet) {
                break;
              }
            }
          }
        }
        if ( !bSet ) {
          alert(false); //ありえない
        }
      }
      return res_io;
    };

    function seek_nextpoint() {
      //小数点3桁：ミリ秒で比較する
      var pos = Math.floor($scope.videooperation.GetCurrentTime()*1000)/1000;
      _.forEach($scope.arrInOut, function(io) {
        if (pos < Math.floor(io.sec_in*1000)/1000) {
          $scope.videooperation.seek(io.sec_in);
          return false;
        } else if (pos < Math.floor(io.sec_out*1000)/1000) {
          $scope.videooperation.seek(io.sec_out);
          return false;
        }
      });
    }
    function seek_backpoint() {
      var pos = Math.floor($scope.videooperation.GetCurrentTime()*1000)/1000;
      var arr_reverse = _.cloneDeep($scope.arrInOut).reverse();  //後ろから見つけていくために逆順にする
      _.forEach(arr_reverse, function(io) {
        if (pos > Math.floor(io.sec_out*1000)/1000) {
          $scope.videooperation.seek(io.sec_out);
          return false;
        } else if (pos > Math.floor(io.sec_in*1000)/1000) {
          $scope.videooperation.seek(io.sec_in);
          return false;
        }
      });
    }
    function seek_video_in(index) {
      $scope.videooperation.seek($scope.arrInOut[index].sec_in);
    }

    function seek_video_out(index) {
      $scope.videooperation.seek($scope.arrInOut[index].sec_out);
    }

    function divide() {
      //現在含まれるINOUTを取得
      var currentTime = $scope.videooperation.GetCurrentTime();
      var arr_io = GetIncludeInOut();
      if ( arr_io.length ) {
        //新たにIN/OUT作る
        var new_in = currentTime + TimecodeUtil.FrameToSecond(1,$scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df)
        var new_out = arr_io[0].sec_out,num;

        //まずcurrentTimeをOUTにする=>既存のINOUTのOUTが今のカーソル位置になる
        var res = SetOut_multi(currentTime, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator);
        res.image_out = '';
        $scope.transcode.savestill(res.sec_out , 'out');
        UpdateInOut(res.sec_in , res.sec_out);

        //SetXX_multiではGUI上でのI、O処理が行われてしまうので、普通にarrInOutに追加すればよい
        AddInOut(new_in , new_out,$scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator);

        $scope.transcode.savestill(new_in , 'in');
        $scope.transcode.savestill(new_out , 'out');
        UpdateInOut(new_in , new_out);

        $scope.actionoperation.addAction('inout' , _.cloneDeep($scope.arrInOut));

        $scope.activeTabIndex = 0;

        //INOUT変更による再描画など
        UpdateTimeByInOut();
        $scope.onResize();

      }
    }

    function video_in()
    {
      if (!$scope.inoutoperation.enableIn){
        return;
      }
      var currentTime = $scope.videooperation.GetCurrentTime();
      var res = SetIn_multi(currentTime, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator );
      $scope.transcode.savestill(res.sec_in , 'in');
      $scope.transcode.savestill(res.sec_out , 'out');
      UpdateInOut(res.sec_in , res.sec_out);

      $scope.actionoperation.addAction('inout' , _.cloneDeep($scope.arrInOut));

      $scope.activeTabIndex = 0;

      //INOUT変更による再描画など
      UpdateTimeByInOut();
      $scope.onResize();

    }

    function video_out()
    {
      if (!$scope.inoutoperation.enableOut){
        return;
      }

      var currentTime = $scope.videooperation.GetCurrentTime();

      var res = SetOut_multi(currentTime, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator);

      $scope.transcode.savestill(res.sec_in , 'in');
      $scope.transcode.savestill(res.sec_out , 'out');
      UpdateInOut(res.sec_in , res.sec_out);

      $scope.actionoperation.addAction('inout' , _.cloneDeep($scope.arrInOut));

      $scope.activeTabIndex = 0;

      //INOUT変更による再描画など
      UpdateTimeByInOut();

      $scope.onResize();

    }

    function GetSameInOut()
    {
      //既存のIN/OUTと同じところはNG
      var currentTime = $scope.videooperation.GetCurrentTime();
      var frame = TimecodeUtil.SecondToFrame(currentTime, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df);
      var arr_io = _.filter($scope.arrInOut , function (inout) {
        if ( ( inout.frame_in === frame ) || (frame ===  inout.frame_out )) {
          return true;
        }
      });
      return arr_io.length;
    }

    function IsEnableIn()
    {
      var currentTime = $scope.videooperation.GetCurrentTime();
      if ( GetSameInOut() ) {
        return false;
      }
      //終点はNG
      if ( currentTime == $scope.videooperation.GetDuration() ) {
        return false;
      }
      return true;
    }

    function IsEnableOut()
    {
      var currentTime = $scope.videooperation.GetCurrentTime();
      if ( GetSameInOut() ) {
        return false;
      }
      //終点はNG
      if ( currentTime == $scope.videooperation.GetDuration() ) {
        return false;
      }
      //始点はNG
      if ( currentTime == 0) {
        return false;
      }
      return true;
    }

    function IsEnableDivide()
    {
      var currentTime = $scope.videooperation.GetCurrentTime();
      var frame = TimecodeUtil.SecondToFrame(currentTime, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df);
      var arr_io = _.filter($scope.arrInOut , function (inout) {
        if ( ( inout.frame_in < frame ) && (frame < inout.frame_out )) {
          return true;
        }
      });
      return arr_io ?  arr_io.length : null;
    }

    function GetIncludeInOut()
    {
      var currentTime = $scope.videooperation.GetCurrentTime();
      var frame = TimecodeUtil.SecondToFrame(currentTime, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df);
      var arr_io = _.filter($scope.arrInOut , function (inout) {
        if ( ( inout.frame_in <= frame ) && (frame <= inout.frame_out )) {
          return true;
        }
      });
      return arr_io;
    }

    function UpdateInOutEnable()
    {
      $scope.inoutoperation.enableIn = IsEnableIn();
      $scope.inoutoperation.enableOut = IsEnableOut();
      $scope.inoutoperation.enableDivide = IsEnableDivide();
    }

    return {
      enableIn:enableIn,
      enableOut:enableOut,
      enableDivide:enableDivide,
      checkedInOutAll: checkedInOutAll ,

      checkInOutAll: checkInOutAll ,
      deleteInOut: deleteInOut ,
      InOutPos: InOutPos,
      UpdateInOut: UpdateInOut,
      SortInOut: SortInOut,
      SetIn_multi: SetIn_multi,
      SetOut_multi: SetOut_multi,
      AddInOut: AddInOut,
      seek_backpoint: seek_backpoint,
      seek_nextpoint: seek_nextpoint,
      seek_video_in: seek_video_in,
      seek_video_out: seek_video_out,
      video_in: video_in,
      video_out: video_out,
      divide: divide,
      UpdateInOutEnable: UpdateInOutEnable,
      GetIncludeInOut: GetIncludeInOut
    };
  })();

  $scope.stilloperation = (function() {
    var modifiedData = false;

    function save() {
      $scope.stilloperation.modifiedData = true;
      $scope.transcode.savestill($scope.videooperation.GetCurrentTime(),'still')
    }
    return {
      modifiedData: modifiedData,
      save: save
    }
  })();

  //起動時の処理
  $scope.load = function() {
  //    alert('Window is loaded');
    var video = angular.element('#VideoPreview')[0];
    video.addEventListener('ended', $scope.videooperation.video_ended, true);
    video.addEventListener('error', $scope.videooperation.video_error , true );
    video.addEventListener('loadedmetadata', $scope.videooperation.video_loadeddata , true );// loadeddata
    video.addEventListener('timeupdate', $scope.videooperation.video_timeupdate, true );
    video.addEventListener('seeked', $scope.videooperation.video_seeked, true );
    video.addEventListener('seeking', $scope.videooperation.video_seeking, true );

    $scope.transcode.status();
  }

  /////////////テストコード//////////////////////////////////////////
  $scope.second=0;
  $scope.timecode=0;
  var timer = 0;
  $scope.skiptimer = 80;
  $scope.test3 = function() {
    $scope.second=parseFloat($scope.second) + 0.001;
    $scope.videooperation.seek(parseFloat($scope.second));
    $scope.timecode = TimecodeUtil.SecondToTimecodeString($scope.videooperation.GetCurrentTime() , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
  }
  $scope.test2 = function() {
    $scope.videooperation.seek(parseFloat($scope.second));
    $scope.timecode = TimecodeUtil.SecondToTimecodeString($scope.videooperation.GetCurrentTime() , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
  }
  $scope.test = function() {
    if (timer) {
      $interval.cancel(timer);
      timer = 0;
      return;
    }
    var video = angular.element('#VideoPreview')[0];
    timer = $interval(function() {
      $scope.second=parseFloat($scope.second) + 0.001;
      $scope.videooperation.seek($scope.second);
      $scope.timecode = TimecodeUtil.SecondToTimecodeString($scope.videooperation.GetCurrentTime() , $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator , $scope.contentInfo.df);
    }, $scope.skiptimer);
  };

  ///////////////////////////////////////////////////////


  function UpdateTime()
  {
    var textTime = document.getElementById('curTime');
    var textTime_dur = document.getElementById('curTime_dur');


    var currentTime = $scope.videooperation.GetCurrentTime();
    if (currentTime<0) {  //補正の加減でマイナスになることがあるので見た目を0に
      currentTime = 0;
    }
    var dutartion = $scope.videooperation.GetDuration();

    textTime.innerHTML = TimecodeUtil.SecondToTimecodeString(currentTime , $scope.contentInfo.framerate.checked.numerator , $scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df);
    textTime_dur.innerHTML = TimecodeUtil.SecondToTimecodeString(dutartion, $scope.contentInfo.framerate.checked.numerator , $scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df);


    var arrIO = $scope.inoutoperation.GetIncludeInOut();
    if (arrIO.length) {
      $scope.inTimeText = arrIO[0].tc_text_in;
      $scope.outTimeText = arrIO[0].tc_text_out;
    } else {
      if ( $scope.contentInfo.df ) {
        $scope.inTimeText = '--:--:--;--';
        $scope.outTimeText = '--:--:--;--';
      } else {
        $scope.inTimeText = '--:--:--:--';
        $scope.outTimeText = '--:--:--:--';
      }
    }

    //ボタンの有効無効
    $scope.inoutoperation.UpdateInOutEnable();

    //TL描画
    DrawOperation.UpdateTL();


  }

  function UpdateTimeByInOut() {
    UpdateTime();
    //タブのカラム幅調整
    $timeout(function() {checkTableColumnWidth()} , 50);
  }


  //////////////////////////////////////////////////////////////
  function checkTableColumnWidth() {
    //IN/OUTリストのtd
    if (angular.element('#barInOutTop') && angular.element('#barInOutTop')[0]) {
      var inouttableW = angular.element('#barInOutTop')[0].clientWidth;
      if (inouttableW) {
        if (angular.element('.td_elem').length - 1 !== $scope.arrInOut.length * 3) {
          $timeout(function() {checkTableColumnWidth()} , 50);
          return;
        }


        var tdTextW = (inouttableW - 30 - $scope.iconSize.w * 2)/3;  //30:check , 64:image
        _.forEach(angular.element('.td_elem_img') , function(elem) {
          elem.width = tdTextW + $scope.iconSize.w + 'px';
          elem.style.maxWidth= elem.width;
        });
        _.forEach(angular.element('.td_elem') , function(elem) {
          elem.width = parseInt(tdTextW) + 'px';
          elem.style.maxWidth= elem.width;
        });
      }
    }

    //Encリストのtd
    if (angular.element('#barEncTop') && angular.element('#barEncTop')[0]) {
      var enctableW = angular.element('#barEncTop')[0].clientWidth;
      if (enctableW){
        _.forEach(angular.element('.td_enc') , function(elem) {
          if (elem.width !== (enctableW-30)/3 + 'px') {
            elem.width = (enctableW-30)/3 + 'px';
            elem.style.maxWidth= elem.width;
          }
        });
      }
    }
  }

  function resizeTab() {
    //Tabの高さなど
    var tabH = angular.element('#divPlayer')[0].clientHeight;
    var nav = angular.element('#rafale-navbar');
    var tabHeadH = 40;

    angular.element('#tab_area').css('height', tabH + 'px');

    var contentH = tabH-tabHeadH;

    var scrollHeight = 0;
    var elemTopHeight = 0;

    var elemInOutBottomH = 0;
    var elemInOutBottom = angular.element('#barInOutBottom');
    if (elemInOutBottom && elemInOutBottom.length) {
      elemInOutBottomH = angular.element('#barInOutBottom')[0].clientHeight + 30; //30:hr分
      elemTopHeight = contentH - elemInOutBottomH;

      var headHeight = angular.element('.scrollHead')[0].clientHeight + angular.element('#inoutBtnHead')[0].clientHeight;
      if (headHeight) {
        scrollHeight = elemTopHeight - headHeight; //headerまでの高さ

        if (contentH - elemInOutBottomH < 100) {
          //差が充分に（100px）なかったらそんなに小さくしない
          scrollHeight = 50;
          elemTopHeight = headHeight + scrollHeight;
        }
        angular.element('#barInOutTop').attr( 'style', 'height:' + elemTopHeight + 'px;' );
        angular.element('.scrollBody').attr( 'style', 'height:' + scrollHeight + 'px;' );
      }

    } else {
      $timeout(function() {
        resizeTab();
      }, 100);
      return;
    }
    var elemEncBottomH = 0;
    var elemEncBottom = angular.element('#barEncBottom');
    if (elemEncBottom && elemEncBottom.length) {
      elemEncBottomH = angular.element('#barEncBottom')[0].clientHeight;
      angular.element('#barEncTop').attr( 'style', 'height:' + (contentH - elemEncBottomH) + 'px;' );

      elemTopHeight = contentH - elemInOutBottomH;

      var headHeight = angular.element('.scrollHeadEnc')[0].clientHeight + angular.element('#encBtnHead')[0].clientHeight;
      if (headHeight) {
        scrollHeight = elemTopHeight - headHeight; //headerまでの高さ

        if (contentH - elemInOutBottomH < 100) {
          //差が充分に（100px）なかったらそんなに小さくしない
          scrollHeight = 50;
          elemTopHeight = headHeight + scrollHeight;
        }
        angular.element('.scrollBodyEnc').attr( 'style', 'height:' + scrollHeight + 'px;' );
      }
    }

    checkTableColumnWidth();

    var elemStillBottomH = 0;
    var elemStillBottom = angular.element('#barStillBottom');
    if (elemStillBottom && elemStillBottom.length) {
      elemStillBottomH = angular.element('#barStillBottom')[0].clientHeight;
      angular.element('#barStillTop').attr( 'style', 'height:' + (contentH - elemStillBottomH) + 'px;' );
      angular.element('#scrollStill').attr( 'style', 'height:' + (contentH - elemInOutBottomH - 50) + 'px;' );
    }
  }

  var setStyle = function( ele, styles ) {
    Object.keys( styles ).forEach( function ( key ) {
      ele.style[key] = styles[key];
    });
  }
  var calcStrWidth = function( str, sz, fmly, className ) {
    var ele = document.createElement('span');
    ele.className = className;
    ele.id = 'calc';
    ele.innerHTML = str;
    var styles = {
      'white-space': 'nowrap',
      'visibility': 'hidden'
    };
    if( sz != undefined ) {
      styles['font-size'] = sz;
    }
    if( fmly != undefined ) {
      styles['font-family'] = fmly;
    }
    setStyle( ele, styles );
    document.body.appendChild( ele );
    var ele_w = ele.offsetWidth;
    document.body.removeChild( ele );
    return ele_w;
  };

  $scope.windowScreen = {};
  $scope.iconSize = {w:64};

  $scope.onResize = (function(previesize) {

    //Previewのサイズ
    var videoPreviewDiv_W = angular.element('#VideoPreviewDiv')[0].clientWidth;
    var videoPreviewDiv_H = angular.element('#VideoPreviewDiv')[0].clientHeight;
    var videoW = videoPreviewDiv_W;
    var videoH = parseInt(videoPreviewDiv_W / 16 * 9);  //Videoの領域は16：9固定

    if (videoPreviewDiv_W === document.body.clientWidth) {
      //まだcol8がまだ動いてない？
      //console.log("scope.onResize retry!!!");
      $timeout(function() {$scope.onResize()} , 10); //少し待ってから再リサイズ
      return;
    }

    angular.element('#VideoPreview')[0].style.width = videoW + 'px';
    angular.element('#VideoPreview')[0].style.height = videoH + 'px';

    angular.element('#cvSeekBar')[0].width = videoPreviewDiv_W;

    // 解像度を取得
    var sh = window.screen.height;
    var sw = window.screen.width;
    // 実際に有効な画面サイズ
    var ash = window.screen.availHeight;
    var asw = window.screen.availWidth;

    $scope.windowScreen = window.screen;

    var screenWidth = $scope.windowScreen.width;
    var screenHeight = $scope.windowScreen.height;
    var orientationAngle = ($scope.windowScreen && $scope.windowScreen.orientation && $scope.windowScreen.orientation.angle) || window.orientation || 0;

    if (orientationAngle % 180) {
      //横向き
      screenWidth = $scope.windowScreen.height;
      screenHeight = $scope.windowScreen.width;
    }


    $scope.iconSize = {w:64};
    if (screenWidth > screenHeight ) {
      //横長
      if (screenWidth <= 1024) {
        if (screenWidth > 768) {
          $scope.iconSize = {w:32};
        }
      }
    } else {
      //縦長
      if (screenWidth <= 1024) {
        if (screenWidth > 768) {
          //$scope.iconSize = {w:32};
        } else if (screenWidth < 600) {
          $scope.iconSize = {w:32};
        }
      }
    }


    // if ($scope.windowScreen.width > $scope.windowScreen.height && $scope.windowScreen.width <= 1024) {
    //   $scope.iconSize = {w:32};
    //   if ($scope.windowScreen.width > $scope.windowScreen.height && $scope.windowScreen.width < 768) {
    //     $scope.iconSize = {w:64};
    //   }
    // } else {
    //   if ( $scope.windowScreen.width > $scope.windowScreen.height && $scope.windowScreen.width <= 1024 ) {
    //     $scope.iconSize = {w:32};
    //   } else {
    //     $scope.iconSize = {w:64};
    //   }
    // }

    //FullHDよりも小さい解像度に対応する。1366×768まで。（ノートパソコン）
    if (sh >= 768 && sw >= 1280) {
      var SIZE_TB = 992;  //bootstrap md 幅
      if (document.body.clientWidth > SIZE_TB) {
        if (document.body.clientHeight > (ash-90)) {  //90:マージン分
          var diffPlayer = document.body.clientHeight - (ash-90);

          var videoPreviewDiv_W = angular.element('#VideoPreviewDiv')[0].clientWidth;
          var videoPreviewDiv_H = angular.element('#VideoPreviewDiv')[0].clientHeight - diffPlayer;
          var videoH = videoPreviewDiv_H;
          var videoW = parseInt(videoPreviewDiv_H / 9 * 16);  //Videoの領域は16：9固定

          angular.element('#VideoPreview')[0].style.width = videoW + 'px';
          angular.element('#VideoPreview')[0].style.height = videoH + 'px';
        }
      }
    }

    //tab
    resizeTab();

    //タイムコード表示文字幅設定
    var strWidth = calcStrWidth("00:00:00:00", undefined, undefined, 'h4');
    strWidth += 5;  //すこし余裕を持たせる
    var strWidth2 = calcStrWidth(" / ", undefined, undefined, 'h4');
    strWidth2 += 5; //すこし余裕を持たせる

    angular.element('#curTimeTd')[0].style.width = strWidth + 'px';
    angular.element('#curTime_durTd')[0].style.width = strWidth + 'px';
    angular.element('#curTime_dumTd')[0].style.width = strWidth2 + 'px';
    //

    DrawOperation.UpdateTL();
  });

  $scope.watchtab = $scope.$watch('activeTabIndex', function(newValue, oldValue, scope) {  //再度watch
    if(angular.equals(newValue, oldValue)) {
    } else {
      //変更があった
      $timeout(function(){  //timeoutの中でapplyが起こる
        resizeTab(); //tabのみ
      });
    }
  },true);

  function onResizeA() {
    $scope.onResize();
    $scope.$apply();
  }

  angular.element($window).on('resize', onResizeA);
  $timeout(function(){  //timeoutの中でapplyが起こる
    $scope.onResize();
  });

  $scope.IEver = (function () { // copied from ../upload/upload.controller.js
    var rv = -1;
    if ($window.navigator.appName == 'Microsoft Internet Explorer') {
      var ua = $window.navigator.userAgent;
      var mr = ua.match(/MSIE ([0-9]{1,}[\.0-9]{0,})/i);
      if (mr && mr.length == 2)
        rv = parseFloat(mr[1]);
    } else if ($window.navigator.appName == 'Netscape') {
      var ua = $window.navigator.userAgent;
      var mr = ua.match(/Trident\/.*rv:([0-9]{1,}[\.0-9]{0,})/i);
      if (mr && mr.length == 2)
        rv = parseFloat(mr[1]);
    }
    return rv;
  })();

  $scope.selectFile = function () {
    $timeout(function () {
      angular.element('#file-select').click();
    }, 0);
  };

  function importFromLocalStorage() {
    //Localstorageから情報をとってきて展開する
    if ($scope.storage.storage[$scope.contentInfo.contentId]) {
      _.forEach($scope.storage.storage[$scope.contentInfo.contentId].still, function(pos){
        $scope.arrStillInfo.push({position:pos.position});  //とりあえずarrayに入れておかないとstorageが空で更新されてしまう
        $scope.transcode.savestill(pos.position,'still', false);
      });
      _.forEach($scope.storage.storage[$scope.contentInfo.contentId].inout, function(io){
        var newIO = new $scope.inoutoperation.InOutPos;
        if (!_.isUndefined(io.in) && !_.isUndefined(io.out)) {
          newIO.frame_in = io.in;
          newIO.frame_out = io.out;
          newIO.sec_in = TimecodeUtil.FrameToSecond(newIO.frame_in, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df);
          newIO.sec_out = TimecodeUtil.FrameToSecond(newIO.frame_out, $scope.contentInfo.framerate.checked.numerator ,$scope.contentInfo.framerate.checked.denominator, $scope.contentInfo.df);
          $scope.arrInOut.push(newIO);
          $scope.transcode.savestill(newIO.sec_in, 'in', false);
          $scope.transcode.savestill(newIO.sec_out, 'out', false);
          $scope.inoutoperation.UpdateInOut(newIO.sec_in,newIO.sec_out);
        }
      });
    }
    //最初の状態を覚える
    $scope.actionoperation.addAction('inout' , _.cloneDeep($scope.arrInOut));
    $scope.actionoperation.modifiedData = false;
  }

  $scope.canPopupContent = function(transobj) {
    var item = _.find(transobj.posttask, function(val){ return val.id==='new' ? true : false; });
    if (item) {
      if (item.contentId) {  //コンテンツ内でのトランスコード
        if (transobj.status.status === 'finished'){
          return true;
        }
      }
    }
    return false;

  };

  var modalOpening = false; //ポップアップオープン中か

  //トランスコードコンテンツ一覧画面をポップアップで開く
  $scope.popupContent = function(transobj) {
    var item = _.find(transobj.posttask, function(val){ return val.id==='new' ? true : false; });
    var contentId;
    var fileId;
    if (item) {
      if (item.contentId) {
        contentId = item.contentId;
      }
    } else if (!transobj.posttas) {  //コンテンツ内でのトランスコード
      if (transobj.status.status === 'finished'){
        contentId = transobj.task.contentId;
        fileId = transobj.task.fileId;
      }
    }
    if (contentId) {
      var contents = [{contentId:contentId, fileId:fileId}];

      modalOpening = true;
      var modalInstance = $modal.open({
        templateUrl: 'app/content/schedule/schedule-content.html',
        controller: 'ScheduleContentCtrl',
        windowClass: 'schedule-content-modal-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          RafaleScheduleContentData: function() {
            return {
              data: contents
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
      }, function() {
        modalOpening = false;
      });
    }
  }



  //プリセット管理をポップアップで開く
  $scope.popupConfigPreset = function() {
    modalOpening = true;
    var modalInstance = $modal.open({
      templateUrl: 'app/content/transcode/preset/preset.html',
      controller: 'TranscodepresetCtrl',
      windowClass: 'transcode-preset-modal-window',
      backdrop: 'static', keyboard: false,
      resolve: {
      }
    });
    modalInstance.result.then(function() {
    }, function() {
      modalOpening = false;
      //再読み込み
      UpdateProfiles();
    });
  }

  $scope.confirmClose = function() {
    var defer = $q.defer();
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_CLOSE_EDIT'),
      btnClassYes: 'btn-primary',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-primary'
    }, function (yes) {
      if (yes) {
        $scope.storage.store();
        //modifyフラグクリア
        $scope.actionoperation.modifiedData = false;
        $scope.stilloperation.modifiedData = false;
      }
      defer.resolve();
    });
    return defer.promise;

  }

  $scope.closeEdit = function() {
    if ($scope.actionoperation.modifiedData || $scope.stilloperation.modifiedData) {
      var promise = $scope.confirmClose();
      promise.then(
        function() { //成功
          $timeout(function(){window.close();},100);
        }
      );
    } else {
      $timeout(function(){window.close();},100);
    }
  }

  //ページ遷移しようとしたときの対策
  $scope.$on('$locationChangeStart', function (event, next, current) {
    if ($scope.actionoperation.modifiedData || $scope.stilloperation.modifiedData) {
      var answer = confirm($translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_CLOSE_EDIT'));
      if (!answer) {
        event.preventDefault();
      }
    }
  });

  //リロード対策
  window.onbeforeunload = function (e) {
    if ($scope.actionoperation.modifiedData || $scope.stilloperation.modifiedData) {
      if (!_.isUndefined(e.returnValue)) {
        //returnValueに何か入れておけば確認ダイアログが出る。メッセージはブラウザ固定のもの
        e.returnValue = $translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_CLOSE_EDIT');
        return $translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_CLOSE_EDIT');
      } else {
        e.returnValue = $translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_CLOSE_EDIT');
        return $translate.instant('CONTENT.VIDEO_EDIT.MSG.CONFIRM_CLOSE_EDIT');
      }
    } else{
      return undefined;
    }
   };

  //キーバインド
  angular.element($window).on('keydown', $scope.handleKeydown);

}]);

/////////////////////////////////////////////////////////////////


var EditInfo = (function() {

  function importInfo()
  {
    //情報のインポート


  }

  function exportInfo()
  {
    //情報のエクスポート
    var blob = new Blob([JSON.stringify($scope.arrInOut, null, 2)], {type : 'application/json'});

    // For Internet Expolorer
    if ($window.navigator.msSaveBlob) {
      $window.navigator.msSaveBlob(blob, "export.json");
    // For other browsers
    } else {
      var link = $window.document.getElementById("json_exporter");

      if (link == null) {
          link = $window.document.createElement("a");
          link.setAttribute("id", "json_exporter");
          link.setAttribute("style", "display:none;");
          link.setAttribute("download", "export.json");
      }

      // $window.location = $window.URL.createObjectURL(blob);
      link.setAttribute("href", $window.URL.createObjectURL(blob));
      link.click();
    }

  }

  return {
    importInfo: importInfo,
    exportInfo: exportInfo
  };
})();