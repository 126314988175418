'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('addGroup', {
    url: '/userGroup/add',
    templateUrl: 'app/userGroup/addGroup/addGroup.html',
    controller: 'AddgroupCtrl',
    authenticate: true,
    functionName: 'UserGroupManagement',
    title: 'Add User Group'
  });
}]);
