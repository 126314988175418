'use strict';

var app = angular.module('limaApp');

app.controller('NavbarCtrl', ['$scope', '$location', '$translate', 'Auth','$http', 'Modal', '$timeout', '$cookies', '$localStorage', '$q', 'LimaRedirect','limaOptionService','LimaCreditCardInfo',
function ($scope, $location, $translate, Auth, $http, Modal, $timeout, $cookies, $localStorage, $q, LimaRedirect, limaOptionService,LimaCreditCardInfo) {

  $scope.isCollapsed = true;
  $scope.isLoggedIn = Auth.isLoggedIn;
  $scope.isAdmin = Auth.isAdmin;
  $scope.getCurrentUser = Auth.getCurrentUser;
  $scope.hasFunction = Auth.hasFunction;


  $scope.storage = (function () {
    var key = 'limaApp:NavbarCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};
    var store = function () {
      $localStorage[key] = storage;
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();

  //$scope.storage.modeDetail = false;


  $scope.changeMode = function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "モードを切り替えます。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      $scope.storage.modeDetail = !$scope.storage.modeDetail;

      });

    //$timeout(function(){$scope.$apply()});
  }

  function isLoadbalance() {
    var defer = $q.defer();

    $http.get('/api/v1/users/me').
    success(function(datas) {
      if (datas.companyId) {
        $http.get('/api/v1/system/loadbalance/' + datas.companyId ).
        success(function(datasLB) {
          if (datasLB) {
            if (datasLB.length) {
              //if (datasLB[0].server.length){
                defer.resolve(datasLB[0]);
              //} else {
              //  defer.reject();
             // }
            } else {
              defer.reject();
            }
          } else {
            defer.reject();
          }
        });
      }
    });
    return defer.promise;
  }

  $scope.logout2 = function() {

    var promise = isLoadbalance();
    promise.then(
      function(res) { //成功
        Auth.logout();
        //LimaRedirect.returnPath
        var url = res.loginServer;
        if (!_.isUndefined(LimaRedirect.returnPath)) {
          url += LimaRedirect.returnPath;
        }
        location.replace(url + '/login');
        return;
      },
      function() { //失敗
        Auth.logout();
        LimaRedirect.go({path:'/login', returnPath:'/', clearCache:true});
      }
    );
  };


  $scope.logout = function() {
    Auth.logout();
    LimaRedirect.go({path:'/login', returnPath:'/', clearCache:true});
  };

  $scope.getDisplayName = function () {
    var u = $scope.getCurrentUser();
    return {name: u.displayName || u.name};
  };

  $scope.greetings = function () {
    return $translate.instant('NAVIGATION.GREETINGS') + $scope.getDisplayName().name + $translate.instant('NAVIGATION.SAN');
  };

  $scope.isActive = function(route) {
    if (!_.isArray(route)) route = [ route ];
    return _.includes(route, $location.path());
  };


  $scope.enableBackgroundSetting = false;
  $scope.enablePlaylistStartTime = false;
  $scope.enableMailSupport = false;
  $scope.enableDeviceGroup = false;

  $scope.m_arrInfoMessages = [];
  $scope.packageType = 0;

  $scope.brand = {text:'　　　　　　　　　　　　　'};

  limaOptionService.getpackageType(function(result){
    if (result) {
      $scope.packageType = result.packageType;
      $timeout(function() {
        $scope.brand.text = $translate.instant('PACKAGE_SERVICE.package_' + $scope.packageType + '.DESCRIPTION');
      });
    }
    //packageTypeでタイトルを表示し分ける
    Auth.systemProperties().then(function (data) {
      // $scope.brand.text = _.get(data, 'setting.brand.text') || '';
      // $scope.brand.text = $translate.instant('PACKAGE_SERVICE.package_' + $scope.packageType + '.DESCRIPTION');

      //メッセージの情報取得
      for (let key in data) {
        //alert('key:' + key + ' value:' + data[key]);
        if (key.indexOf("infomation.message") !== -1) {
          if (data[key].message.length) {
            $scope.m_arrInfoMessages.push(data[key]);
          }
        }

      };
      checkInfoMenu();
    });
  });

  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.enableDeviceGroup = true;
    }
    find = _.find(result, function(op){
      return ((op.name === 'Support_By_Mail') || (op.name === 'Support_By_Tel_and_Mail')) ? true : false;
    });
    if (find) {
      $scope.enableMailSupport = true;
    }
    find = _.find(result, function(op){
      return op.name === 'PlaylistStartTime' ? true : false;
    });
    if (find) {
      $scope.enablePlaylistStartTime = true;
    }
    find = _.find(result, function(op){
      return op.name === 'BackgroundSetting' ? true : false;
    });
    if (find) {
      $scope.enableBackgroundSetting = true;
    }
  });



  $scope.enableInfoMenu = false;

  $scope.trialExpired = false;
  $scope.companyMessage = '';
  $scope.errorcode = '';  //自動課金のエラー時にエラーコードが設定される
  $scope.registServerUrl;
  $scope.companyId = '';
  $scope.companyDisabled = false;
  function getCompanyInfo() {
    /* クレジットカード情報取得 */
    const access_token=encodeURIComponent($cookies.get('token'));
    if ( _.isUndefined(access_token ) ) {
      return;
    }
    LimaCreditCardInfo.getCreditCardInfo(function(err, creditCardInfo, registServerUrl, companyId ) {
      if ( err ) {

      }
      else {
        $scope.registServerUrl = registServerUrl;
        $scope.companyId = companyId;
        $timeout(function(){
          const info = LimaCreditCardInfo.getInfoString(creditCardInfo)
          $scope.companyMessage = info.message;
          $scope.errorcode = info.errorcode;
          $scope.trialExpired = info.expired;
          $scope.companyDisabled = info.disabled;
          checkInfoMenu();
        });
      }
    });
  }
  function checkInfoMenu() {
      $scope.enableInfoMenu = $scope.companyMessage.length ? true : false;
      if ($scope.m_arrInfoMessages.length) {
        $scope.enableInfoMenu = true;
      }
  }
  getCompanyInfo();
  checkInfoMenu();

  $scope.infoMenu = function() {
    if( LimaCreditCardInfo.isIE() ) {
      Modal.confirm.message({
        title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('COMPANY.CREDITCARD.WARN_FOR_IE'),
        modalClass:'modal-warning'
      });
      return;
    }
    else if ( $scope.companyDisabled ) {
      //アカウントが無効になっている
      return;
    }
    var message = $translate.instant('COMPANY.CREDITCARD.CAPTURE_CONFIRM');
    var mode = 'payment';
    if ( $scope.errorcode.length ) {
      message = $translate.instant('COMPANY.CREDITCARD.CARDCHANGE_CONFIRM');
      mode = 'cardchange';
    }
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: message,
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (yes) {
        if (Auth.isAdmin() && !$scope.companyDisabled ) {
          LimaCreditCardInfo.jumpToRegistSite($scope.registServerUrl, $scope.companyId, mode);
        }
        }
    });
  }

}]);
