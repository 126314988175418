'use strict';

var app = angular.module('limaApp');
var async =require('async');

app.controller('LayoutCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$window','$translate','$uibModal', 'Modal', '$q','limaShowWaitDialog','limaPlaylistService','limaOptionService',
function($scope,socket, Auth, $http,$timeout,$window,$translate,$modal, Modal, $q, limaShowWaitDialog, limaPlaylistService, limaOptionService) {

  Auth.systemProperties().then(function (data) {
    $scope.systemDebug = data['system.debug'] || 0;
  });

  $scope.maxPlaylistCount = 10;
  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'AddPlaylist' ? true : false;
    });
    if (find) {
      $scope.maxPlaylistCount += find.amount * 10;
    }

  });
  //playlistControlと同じ↑↑↑↑↑

  function modalEditLayout(layout) {
    var playlistUsed = 0;
    async.waterfall([
      function (cb) {
        if (!layout) {
          cb(null);
        } else {
          isIncludeLayoutInPL([layout], function(resultPL) {
            if ($scope.selectedRow.arrIncludeLayoutInPL.length) {
              playlistUsed = 1;
            }
            cb(null);
          });
        }
      }, function(cb) {
        var modalInstance = $modal.open({
          templateUrl: 'app/layout/dialog/editLayoutDlg.html',
          controller: 'EditLayoutDlgCtrl',
          //windowClass: 'modal-upload-window-ex',
          windowClass: 'modal-fit',
          backdrop: 'static', keyboard: false,
          size: 'lg',
          resolve: {
            EditLayoutInfo: function() {
              return {
                layout : layout ? layout : null,
                playlistUsed : playlistUsed
              };
            }
          }
        });
        modalInstance.result.then(function(results) {
          //保存
          $timeout(function() {
            getLayouts(function() {
              if (!layout) {
                if ($scope.layouts.length) {
                  $scope.rowHighilited($scope.layouts[0]);
                }
              } else {
                var updatedTime_b = new Date(layout.updatedTime);
                var find = _.find($scope.layouts , function(lay){
                  return lay.layoutId === layout.layoutId;
                });
                $scope.rowHighilited(find);

                if (playlistUsed) {
                  //使用中のレイアウト
                  if (find) {
                    var updatedTime_a = new Date(find.updatedTime);
                    if (updatedTime_b.getTime() < updatedTime_a.getTime()) {  //新しい⇒更新された
                      //プレイリスト作り直し：もう一度チェック
                      isIncludeLayoutInPL([find], function(resultPL) {
                        async.forEachOfSeries(find.arrIncludeLayoutInPL , function(pl,index,next) {
                          var promise = limaPlaylistService.UpdatePlaylistHTML(pl.playlistId ,find.arrIncludeLayoutInPL );
                          promise.then(
                            function() { //成功
                              next(null);
                           },
                            function() { //失敗
                              next(null);
                            }
                          );
                        }, function (err) {
                          ///おわり
                        }) ;
                      });
                    }
                  }
                }


              }
            });
          })
        }, function() {
          $timeout(function() {
            getLayouts(function() {
              if (!layout) {
                if ($scope.layouts.length) {
                  $scope.rowHighilited($scope.layouts[0]);
                }
              } else {
                var find = _.find($scope.layouts , function(lay){
                  return lay.layoutId === layout.layoutId;
                });
                $scope.rowHighilited(find);
              }
            });
          })
        });;
      }
    ], function (err) {
      //何もしない
    });
  }

  $scope.newLayout = function() {
    if ($scope.layouts.length >= $scope.maxPlaylistCount) {

      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:$translate.instant('LAYOUT.MSG.MESSSAGE_COUNT_MAX'),
      modalClass:'modal-danger'});
      return;
    }
    modalEditLayout();
  }
  $scope.editLayout = function(layout) {
    if (layout) {
      modalEditLayout(layout);
    } else if($scope.selectedRow){
      modalEditLayout($scope.selectedRow);
    }
  }

  function getCopyName(baseName) {
    var newName = baseName;
    var find = _.find($scope.layouts, function(item) {
      return item.name === newName;
    });
    if (find) {
      var index = 1;
      while(1){
        newName = baseName + "(" + index + ')';
        var find = _.find($scope.layouts, function(item) {
          return item.name === newName;
        });
        if (find) {
          index++;
        } else {
          break;  //この名前で被りなし
        }
      }
    }
    return newName;
  }




  function getPlaylist(callback) {
    $http.get('/api/v1/playlists').
    success(function(datas) {
      callback(datas ? datas.playlists : []);
    });
  }
  function isIncludeLayoutInPL(layoutId , callback) {
    getPlaylist(function(datas) {
      var find = _.find(datas , function(dt) {
        return dt.layoutId === layoutId;
      });
      callback(find);
    })
  }

  function isIncludeLayoutInPL(arrLayout , callback) {
    getPlaylist(function(datas) {
      _.find(arrLayout , function(ly) {
        ly.arrIncludeLayoutInPL = _.filter(datas , function(dt) {
          return dt.layoutId === ly.layoutId;
        });
      })
      callback();
    })
  }


  var doDeleteLayout = function(item , callback){
    $http.delete('/api/v1/layouts/' + item.layoutId)
    .success(function(data) {
      async.forEachSeries(item.regions, function (rg, cb) {
        $http.delete('/api/v1/regions/' + rg.regionId).
        success(function(dataRg) {
          cb(null);
        });
      }, function (err) {
        callback(err);
      });
    });

  }

  var deleteLayoutFunc = function(selected) {

    //削除
    limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.LAYOUT'),$translate.instant('PLAYLIST.MESSAGE_DELETING'));
    {
      async.forEachSeries(selected, function (item, cb) {
        doDeleteLayout(item, function() {
          cb(null);
        });
      }, function (err) {
        $timeout(function() {
          getLayouts();
          limaShowWaitDialog.hideWait($translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('NAVIGATION.MESSAGE_DELETED'));
        })
      });
    }
  }



  $scope.deleteLayout = function() {
    isIncludeLayoutInPL([$scope.selectedRow], function(resultPL) {
      if ($scope.selectedRow.arrIncludeLayoutInPL.length) {
        var arrName = [];
        _.forEach($scope.selectedRow.arrIncludeLayoutInPL , function(val){
          arrName.push("<li>" + val.playlistName + "</li>");
        });
        var mes = arrName.join("<br>");

        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:"プレイリストで使用されているため削除できません。" + "<br><br>" + mes,//$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
        modalClass:'modal-danger'});
        return;
      }


      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('LAYOUT.SELECTED_LAYOUT')}),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;

          //削除
          $http.delete('/api/v1/layouts/' + $scope.selectedRow.layoutId)
          .success(function(data) {
            async.forEachSeries($scope.selectedRow.regions, function (rg, cb) {
              $http.delete('/api/v1/regions/' + rg.regionId).
              success(function(dataRg) {
                cb(null);
              });
            }, function (err) {
              $timeout(function() {
                getLayouts();
                //何も選択していない状態にする
                $scope.selectedRow = null;
              })
              //callback(err);
            });
          });

      })
    })
  }

  $scope.deleteLayoutFromDlg = function() {
    isIncludeLayoutInPL($scope.layouts , function() {

      var datas = [];
      _.forEach($scope.layouts , function(dt) {
        datas.push({
          titleArray : [dt.name],
          id : dt.layoutId,
          canSelect : dt.arrIncludeLayoutInPL.length ? false : true
        })
      });


      var modalInstance = $modal.open({
        templateUrl: 'components/dialog/dlgSelectItem.html',
        controller: 'DlgSelectItemCtrl',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          selectItem: function() {
            return {
              titleArray:[$translate.instant("PLAYLIST.TABLE.NAME")],
              dlgTitle : $translate.instant('NAVIGATION.LAYOUT'),
              btnName : $translate.instant('COMMON.DELETE'),
              datas : datas
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        //選択されたレイアウトで、プレイリストで使用されていないレイアウトを抽出
        const canDeleteLayout = results.selected.filter((layout) => layout.canSelect === true)

        //$scope.layoutの中から、選択した＆プレイリストに使用されていないレイアウトを抽出
        const selected = $scope.layouts.filter((lay1) => {
          return canDeleteLayout.find((lay2) => {
            return lay1.layoutId === lay2.id;
          });
        });

        // var  selected = _.filter($scope.layouts, function(tw){
        //   return _.find(results.selected , function(dt){
        //     return dt.id === tw.layoutId;
        //   })
        // });

        Modal.confirm.yesno({
          title: $translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('LAYOUT.SELECTED_LAYOUT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
          btnClassYes: 'btn-default',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-danger',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;
          //削除
          deleteLayoutFunc(selected);
          //何も選択していない状態にする
          $scope.selectedRow = null;
        });
      }, function() {
        //
      });


    })
  }


  $scope.copyLayout = function(layout) {

    if (layout) {
      if ($scope.layouts.length >= $scope.maxPlaylistCount) {

        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('LAYOUT.MSG.MESSSAGE_COUNT_MAX'),
        modalClass:'modal-danger'});
        return;
      }
    }

    var maxStringLength = 127;
    var copyName = getCopyName(layout.name + "_コピー");
    if (copyName.length > maxStringLength) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:"コピー元のレイアウト名が長すぎるため、コピーできません。<br>レイアウト名の長さを短くしてから再度コピーしてください。",
      modalClass:'modal-danger'});
      return;
    }

    var postCreate = _.cloneDeep(layout);
    _.forEach(postCreate.regions, function (rg) {
      if (rg.contentsRegionInfo) {
        delete rg.contentsRegionInfo;
      }
    });

    postCreate.name = copyName;
    $http.post('/api/v1/layouts', postCreate).
    success(function(data) {

      async.forEachSeries(postCreate.regions, function (rg, cb) {
        var postdataRg = _.cloneDeep(rg);
        postdataRg.layoutId = data.id;
        $http.post('/api/v1/regions/', postdataRg).
        success(function(dataRg) {
            console.log("region " + dataRg.id);
            cb(null);
        });
      }, function (err) {
        $timeout(function() {
          getLayouts(function() {
            var find = _.find($scope.layouts , function(lay){
              return lay.layoutId === data.id;
            });
            $scope.rowHighilited(find);
          })

        })
      });

    });

 }

  //リストが選択されたときの処理
  $scope.selectedRow = null;
  $scope.rowHighilited = function (list) {
    _.forEach($scope.layouts, function(item){
      item.checked = false;
    });
    list.checked = true;
    $scope.selectedRow = list;

  };
  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }

  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  function getLayouts(callback) {
    $scope.layouts = [];
    $http.get('/api/v1/layouts').
    success(function(datas) {
  //    $scope.layout = datas.layouts;
      if ($scope.dbtimezoneString === 'UTC') {  //S3 AURORA
        _.forEach(datas.layouts, function(list){
          //UTC->SYSTEM
          const defaultTimezoneOffset = new Date().getTimezoneOffset()*-1;
          var dt = new Date(list.updatedTime);
          dt.setMinutes(dt.getMinutes() + defaultTimezoneOffset);
          list.updatedTime = dt;
        });
      }
      datas.layouts = _.sortBy(datas.layouts, function(ly) {
        return new Date(ly.updatedTime);
      }).reverse();

      var companyId = Auth.getCurrentUser().companyId;
      datas.layouts = _.filter(datas.layouts,function(ly){
        if (ly.custom) {
          var companyId_layout = ('00000000' +   ly.companyId).slice(-8);  //8桁で0埋め
          if (companyId === companyId_layout) {
            return true;
          }
        }
      });
      $http.get('/api/v1/regions').
      success(function(datas_rg) {
        _.forEach(datas.layouts, function(ly) {
          var arr = _.filter(datas_rg.regions, function(rg){
            return rg.layoutId === ly.layoutId;
          });
          ly.regions = arr;
        });

        $scope.layouts = _.filter(datas.layouts , function(ly) {
          var findRg = _.find(ly.regions , function(rg) {
            if (rg.name.indexOf("v2_custom") !== -1 ) {
              return true;
            }
          });
          return findRg;   //"v2_custom"のみ列挙
        })

        if (callback) {
          callback();
        }
      });
    });
  }

  $scope.dbtimezoneString = "";
  $scope.getdbTimezone = function() {
    $http.get('/api/v1/system-info/db').
    success(function(datas) {
      console.log(datas);
      _.find(datas,function(dt){
        if (dt.Variable_name === 'system_time_zone') {
          $scope.dbtimezoneString = dt.Value;
          return true;
        }
      });

      getLayouts();

    });
  }
  $scope.getdbTimezone();

  //////////////////////////////////////////////////////////////////
  $scope.selectLayoutIndex = -1;
  $scope.selectLayout={};



  $scope.regions = [];
  $scope.plusText = function(index) {
    $scope.regions.push({name:'', x:0,y:0,w:1920,h:1080});
  }

  $scope.regions.push({name:'', x:0,y:0,w:1920,h:1080});

  $scope.selectOptionServiceList = {};
  function getOptionService() {
    $http.get('/api/v1/option-service').
    success(function(datas) {
      $scope.optionServiceList = datas;
      $scope.selectOptionServiceList = datas[0];
    });
  }
  getOptionService();
  ///////////////////////////////////////////////////////////////////////

  // $scope.regions = [];
  // $http.get('/api/v1/regions').
  // success(function(datas) {
  //   $scope.regions = datas.regions;
  //   _.forEach($scope.regions, function(rg){
  //     if (!_.isUndefined(rg.contentId)) {
  //       $http.get('/api/v1/content/contents/' + rg.contentId).
  //       success(function(datas) {
  //         if (isImage(datas)) {
  //           rg.fixImage = {id:rg.contentId, name: datas.title};
  //         }
  //         if (isVideo(datas)) {
  //           rg.fixVideo = {id:rg.contentId, name: datas.title};

  //         }
  //         if (isText(datas)) {
  //           rg.fixText = {id:rg.contentId, name: datas.title};
  //         }

  //       });

  //     }
  //   });
  //   $scope.selectChange();

  // });

  function getRegion(layoutID) {
    $scope.selectLayout.model.regions = _.filter($scope.regions, function(region){
      if (region.LayoutID === layoutID) {
        return true;
      }
    });
    $scope.selectLayout.model.regions = _.sortBy($scope.selectLayout.model.regions, function(item) {
      return item.ZIndex;
    });//.reverse()

  }

  function getMaterialInfo(MaterialID){
    //idと名前
  }

  var color = [
    "#f39700",
    "#e60012",
    "#9caeb7",
    "#00a7db",
    "#009944",
    "#d7c447",
    "#9b7cb6",
    "#00ada9",
    "#bb641d",
    "#e85298",
    "#0079c2",
    "#6cbb5a",
    "#b6007a",
    "#e5171f",
    "#522886",
    "#0078ba",
    "#019a66",
    "#e44d93",
    "#814721",
    "#a9cc51",
    "#ee7b1a",
    "#00a0de"
    ];
    //var canvas = new fabric.Canvas('canvas');

  $scope.SelectedRegion = undefined;
  $scope.selectRegion = function(region){
    // $scope.SelectedRegion = region;
    // $scope.SelectedRegion.rect.selected = true;
    // //canvas.setActiveObject(canvas.item(0));

    $scope.SelectedRegion = region;
    //$scope.SelectedRegion.rect.fill = "#0F0";
    _.filter($scope.selectLayout.model.regions, function(rg,index){
      // canvas.item(index).dirty = true;
      // canvas.item(index).fill = '#000';//color[index];
      if (rg.regionId === region.regionId) {
        // canvas.bringToFront(canvas.item(index));
        // canvas.setActiveObject(canvas.item(index));
        // canvas.item(index).fill = '#0F0';
        return true;
      } else {
        //canvas.item(index).renderAll();

      }
    });
    canvasDraw();
    //canvas.renderAll();

  }
  $scope.isActiveList = function(regionId){
    if (_.isUndefined($scope.SelectedRegion)) {
      return false;

    }
    return $scope.SelectedRegion.regionId === regionId;
  }

  $scope.RegionRect = [];

//   function fabricDraw() {
//     canvas.clear();
//     canvas.isDrawingMode = false;
//     $scope.RegionRect = [];
//     var scale =  1;
//     _.forEach($scope.selectLayout.model.regions, function(region,index){
//       var rect = new fabric.Rect({
//         left: region.LeftIndex / scale,
//         top: region.TopIndex / scale,
//         fill:  '#FFF' ,// color[index],
//         width: region.Width / scale,
//         height: region.Height / scale,
//         backgroundColor: 'transparent',
//       });
//       rect.on('selected', function() {
//         console.log('selected a rectangle');
//       });
// //      $scope.RegionRect.push(rect);
//       region.rect = rect;
//       canvas.add(rect);

//     });

//     document.getElementById('select').addEventListener("click", function(){
//       canvas.isDrawingMode = !canvas.isDrawingMode }, false);

//       var canvas2 = document.getElementById('canvas');
//       if (canvas2.getContext) {
//         var context = canvas2.getContext('2d');
//        // context.scale(0.5,0.5);

//       }
//       canvas.setViewportTransform([0.25, 0, 0, 0.25, 0, 0]);
//   }


  //保存
  function getContentId(region)
  {
    if (region.fixImage) {
      return region.fixImage.id;
    }
    if (region.fixText) {
      return region.fixText.id;
    }
    if (region.fixVideo) {
      return region.fixVideo.id;
    }
    return undefined;
  }


  $scope.DoSaveLayout = function() {
    $scope.layoutInfo.name = new Date().getTime().toString();

    $scope.saveLayout(function() {
      $modalInstance.dismiss('cancel');

    })
  }

  $scope.saveLayout = function(callback) {


    var postdata = {
      name    : $scope.layoutInfo.name  ,
      width   : $scope.layoutInfo.size.width  ,
      height   : $scope.layoutInfo.size.height  ,
      rotate   : $scope.layoutInfo.selectDirection,
      companyId : Auth.getCurrentUser().companyId,
      optionId: 99
    };
    $http.post('/api/v1/layouts' , postdata)
    .success(function(data) {

      async.forEachSeries($scope.layoutInfo.regions, function (rg, cb) {
        var postdataRg = {
          name    : rg.name  ,
          leftIndex : rg.x,
          topIndex:rg.y,
          width   :rg.w  ,
          height   : rg.h  ,
          zIndex   : 1,
          layoutId : data.id
        };
        $http.post('/api/v1/regions/', postdataRg).
        success(function(dataRg) {
            cb(null , "region " + dataRg.id);
        });
      }, function (err) {
        callback(err);
      });
    });




  }

  $scope.editRegion = function () {

  }

  $scope.updateCanvas = function() {
    canvasDraw() ;
  }

  function canvasDraw() {

    var c=document.getElementById("canvasLayout");
    var ctx=c.getContext("2d");

    var arrRg = [];
    var w = Number($scope.selectLayout.width);
    var h = Number($scope.selectLayout.height);
    var canvas_w = 1920;
    var canvas_h = 1920;
    var diff_w = 0;
    var diff_h = 0;
    if (w > h) {
      //w : h = 1920 : yy
      canvas_h = canvas_w * h / w;
      diff_h = (1920 - canvas_h) / 2;
    } else {
      canvas_w = canvas_h * w / h;
      diff_w = (1920 - canvas_w) / 2;
    }
    ctx.clearRect(0,0,1920,1920);

    _.forEach($scope.regions, function(region,index){
      var rect = {
        left: Number(region.x),
        top: Number(region.y),
        width: Number(region.w),
        height: Number(region.h)
      };
      var bSelect = false;
      if (!_.isUndefined($scope.SelectedRegion)) {
        if ($scope.SelectedRegion.regionId === region.regionId) {
          bSelect = true;
        }
      }
      if (bSelect) {
        ctx.fillStyle = '#fff59d';
      } else {
        ctx.fillStyle = '#e9e9e9';
      }
      ctx.fillRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

      ctx.fillStyle = "#e9e9e9"
      ctx.lineWidth = 4;
      ctx.strokeRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

    });



  }


  $scope.selectChange = function() {
    $scope.selectArea = {};
    $scope.selectLayoutIndex = $scope.selectLayout.model.id;
    getRegion($scope.selectLayout.model.id);
    $timeout(function(){canvasDraw();});
//    $timeout(function(){fabricDraw();});

  }


  $scope.iframestyle = function() {
    if ($scope.selectLayoutIndex === -1) {
      return;
    }
    var val_w1080 ={
      'transform':'scale(0.2)  translate(1080px,0px)',
      'transform-origin':'0px 0px',
      'width': '1080px',
      'height': '1920px',
  };

    var val_w1920 ={
      'transform':'scale(0.23) translate(200px,0px)',
      'transform-origin':'0px 0px',
      'width': '1920px',
      'height': '1080px',
    };

    return $scope.selectLayout.model.width % 180 === 0 ? val_w1920 : val_w1080;
  }



  $scope.font = [
    {name:"", font:"Noto Sans CJK JP Black"},
    {name:"", font:"Noto Sans CJK JP Light"},
    {name:"", font:"Noto Sans CJK JP Bold"},
    {name:"", font:"Noto Sans CJK JP Medium"},
    {name:"", font:"Noto Sans CJK JP DemiLight"},
    {name:"", font:"Noto Sans CJK JP Regular"},
    {name:"", font:"Noto Sans Mono CJK JP Bold"},
    {name:"", font:"Noto Sans Mono CJK JP Regular"}
  ];



  function getPxValue(strVal) {
    var val = strVal.replace("px",""); val = Number(val);
    return val;
  }

  function drawArea(context, val, index, type){
    var font = "50px 'ＭＳ Ｐゴシック'";

    var x = getPxValue(val.left);
    var y = getPxValue(val.top);

    var w = getPxValue(val.width);
    var h = getPxValue(val.height);

    context.strokeStyle="gray";//$scope.layout.body.backgroundColor;
    context.lineWidth=10;//$scope.layout.body.backgroundColor;
    context.fillStyle = "#00F";
    if ($scope.selectArea) {
      if (val.id===$scope.selectArea.id) {
        if (val.type===$scope.selectArea.type) {
          context.fillStyle = "#0FF";
        }
      }
    }
    context.fillRect(x,y,w,h);
    context.strokeRect(x,y,w,h);


    context.textAlign = 'center';
    context.font = font;
    context.fillStyle="gray";//$scope.layout.body.backgroundColor;

    var title = "";
    if (type==="image") {
      title = "画像";
    }
    if (type==="text") {
      title = "テキスト";
    }
    if (type==="marquee") {
      title = "スクロールテキスト";
    }
    if (type==="imagefix") {
      title = "固定";
    }
    context.fillText(title+index, x+w/2,y+50);

  }


  $scope.selectArea = undefined;

  function updataLayout(){
    if ($scope.selectArea) {
      $scope.selectLayout.model.image.find(function(im){
        if ($scope.selectArea.id === im.id) {
          if ($scope.selectArea.type === im.type) {
            im = $scope.selectArea;
            return true;
          }
        }
      });
      if ($scope.selectArea.text) {
        if ($scope.selectArea.text.length) {
          $scope.selectArea.text.find(function(im){
            if ($scope.selectArea.id === im.id) {
              if ($scope.selectArea.type === im.type) {
                im = $scope.selectArea;
                im.fontFamily = $scope.selectArea.selectfont.font;
                return true;
              }
            }
          });
        }
      }
      $scope.selectLayout.model.marquee.find(function(im){
        if ($scope.selectArea.id === im.id) {
          if ($scope.selectArea.type === im.type) {
            im = $scope.selectArea;
            im.fontFamily = $scope.selectArea.selectfont.font;
            return true;
          }
        }
      });
      $scope.selectLayout.model.imagefix.find(function(im){
        if ($scope.selectArea.id === im.id) {
          if ($scope.selectArea.type === im.type) {
            im = $scope.selectArea;
            if (im.text) {
              im.text.forEach(function(tx,index){
                tx.fontFamily = $scope.selectArea.text[index].selectfont.font;
              });
            }
            else if (im.marquee) {
              im.marquee.forEach(function(tx,index){
                tx.fontFamily = $scope.selectArea.marquee[index].selectfont.font;
              });
            }
            return true;
          }
        }
      });
    }

  }

  $scope.canvasmousedown = function(e) {
    updataLayout();

    var rect = e.target.getBoundingClientRect();

    var body_w = getPxValue($scope.selectLayout.model.width);

    var scale = body_w / rect.width;

    var mx = e.clientX - rect.left; mx *= scale;
    var my = e.clientY - rect.top;  my *= scale;

    var bFind = false;
    $scope.selectLayout.model.image.find(function(im){
      var x = getPxValue(im.left);
      var y = getPxValue(im.top);

      var w = getPxValue(im.width);
      var h = getPxValue(im.height);

      if ((x <= mx) && (mx <= (x+w))) {
        if (y <= my && (my <= (y+h))) {
          $scope.selectArea = im;
          bFind = true;
          return true;
        }
      }
    });

    if (!bFind) {
      $scope.selectLayout.model.imagefix.forEach(function(im){
        var x = getPxValue(im.left);
        var y = getPxValue(im.top);

        var w = getPxValue(im.width);
        var h = getPxValue(im.height);

        if ((x <= mx) && (mx <= (x+w))) {
          if (y <= my && (my <= (y+h))) {
              $scope.selectArea = im;
              if (im.text) {
                im.text.forEach(function(tx){
                  tx.selectfont = $scope.font.find(function(f){
                    if (f.font === tx.fontFamily) {
                      return true;
                    }
                  });
                });
              }
              if (im.marquee) {
                im.marquee.forEach(function(tx){
                  tx.selectfont = $scope.font.find(function(f){
                    if (f.font === tx.fontFamily) {
                      return true;
                    }
                  });
                });
              }

            bFind = true;
            return true;
          }
        }
      });
    }

    if (!bFind) {
      $scope.selectLayout.model.marquee.forEach(function(im){
        var x = getPxValue(im.left);
        var y = getPxValue(im.top);

        var w = getPxValue(im.width);
        var h = getPxValue(im.height);

        if ((x <= mx) && (mx <= (x+w))) {
          if (y <= my && (my <= (y+h))) {
              $scope.selectArea = im;
              im.selectfont = $scope.font.find(function(f){
                if (f.font === im.fontFamily) {
                  return true;
                }
              });

            bFind = true;
            return true;
          }
        }
      });
    }

    if (!bFind) {
      $scope.selectLayout.model.text.forEach(function(im){
        var x = getPxValue(im.left);
        var y = getPxValue(im.top);

        var w = getPxValue(im.width);
        var h = getPxValue(im.height);

        if ((x <= mx) && (mx <= (x+w))) {
          if (y <= my && (my <= (y+h))) {
              $scope.selectArea = im;
              im.selectfont = $scope.font.find(function(f){
                if (f.font === im.fontFamily) {
                  return true;
                }
              });
            bFind = true;
            return true;
          }
        }
      });
    }
    console.log($scope.selectArea);
    canvasDraw();
  }

  $scope.onResize = (function(previesize) {

    //Previewのサイズ
    // var footerBar = angular.element('#footerBar')[0].clientHeight;
    // var footerMesBar = angular.element('#footerMesBar')[0].clientHeight;
    var title = angular.element('#title')[0].clientHeight + 40;
    var buttonbar = angular.element('#buttonbar')[0].clientHeight;
    if (_.isUndefined(angular.element('#lima-navbar')[0])) {
      //console.log("navbar1");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    if (_.isUndefined(angular.element('#lima-navbar')[0].clientHeight)) {
      //console.log("navbar2");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    var limanavbar = angular.element('#lima-navbar')[0].clientHeight;
    if (limanavbar > 150) { //変に高さがある＝＝まだ描画され切ってない
      //console.log("navbar3");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    var bodyHeight = window.innerHeight;

    var tableHeight = bodyHeight - (title + buttonbar + limanavbar + 20 );
    //console.log("#bodyHeight  " + bodyHeight);
    //console.log("#tableList  " + tableHeight);
    angular.element('#tableList')[0].style.height = tableHeight + 'px';


  });


  function onResizeA() {
    $scope.onResize();
    $scope.$apply();
  }

  angular.element($window).on('resize', onResizeA);
  $timeout(function(){  //timeoutの中でapplyが起こる
//    console.log('resize');
    $scope.onResize();
  });


  $scope.$on('$destroy', function () {
    angular.element($window).off('resize', onResizeA);
  });


}]);

