'use strict';

var app = angular.module('limaApp');

app.controller('LoginCtrl', ['$scope', '$translate', 'Auth', '$http', 'LimaRedirect', '$q',
function ($scope, $translate, Auth, $http, LimaRedirect, $q) {
  $scope.user = {};
  $scope.errors = {};
  $scope.loginAdmin = false;

  (function (help) {
    switch (help) {
    case 'Password has expired': break;
    case 'Please login': break;
    case 'Anonymous login failed':
    case 'Failed to get anonymous login params': $translate('LOGIN.HELP.ANONYMOUS_LOGIN_FAILED').then(function (res) { $scope.help = res; }); break;
    case 'Login session has expired': $translate('LOGIN.HELP.SESSION_EXPIRED').then(function (res) { $scope.help = res; }); break;
    case 'Forbidden': $translate('LOGIN.HELP.FORBIDDEN').then(function (res) { $scope.help = res; }); break;
    default: $scope.help = help; break;
    }
  })(LimaRedirect.help);

  $scope.loginDemo = function(){
    $scope.submitted = true;
//    if(!form.$valid) return;

    $scope.performing = true;
    $http.get('/api/v1/configurations/demo').success(function (demo) {
      Auth.login({
        email: demo.user.email,
        password: demo.user.password
      })
      .then(function() {
        // Logged in, redirect to home
        LimaRedirect.back({returnPath: '/'});
      }, function(err) {
        $scope.errors.other = (function () {
          switch (err.message) {
          case 'Missing credentials': return $translate.instant('LOGIN.WARN_MISSING_CREDENTIALS');
          case 'This username is not registered.': return $translate.instant('LOGIN.WARN_USERNAME_NOT_REGISTERED');
          case 'Invalid username/password':
          case 'This password is not correct.': return $translate.instant('LOGIN.WARN_PASSWORD_INCORRECT');
          case 'Account disabled':
          case 'This username is currently not effective.': return $translate.instant('LOGIN.WARN_USERNAME_NOT_EFFECTIVE');
          case 'This username is not allowed.':
          case 'This username is not supported.':
          case 'Not Permitted to login at this time':
          case 'Not permited to logon at this workstation':
          case 'Password expired':
          case 'Account expired':
          case 'User must reset password':
          case 'The user is not allowed to login from the address.': return $translate.instant('LOGIN.WARN_ADDRESS_NOT_ALLOWED');
          case 'User account locked':
          case 'The user has been locked-out.': return $translate.instant('LOGIN.WARN_USER_LOCKED');
          default: return err.message
          }
        })();
      })['finally'](function () {
        $scope.performing = false;
      });
    }).error(function (err) {
    });


  }

  function isLoadbalance() {
    var defer = $q.defer();

    if ($scope.user.companyLoginId) {
      $http.post('/auth/local', {
        loginAdmin:true,
        email: 'admin',
        password: 'Mediaedge4'
      }).then(function (res) {
        //var id = '00000002';
        $http.get('/api/v1/company' + '?access_token=' + res.data.token , {params:{loginId:$scope.user.companyLoginId}} ).
        then(function(resC) {
          if(resC && resC.data && resC.data.length){
            $http.get('/api/v1/system/loadbalance/' + resC.data[0].companyId + '?access_token=' + res.data.token ).
            success(function(datasLB) {
              if (datasLB) {
                if (datasLB.length) {
                  if (datasLB[0].server.length){
                    defer.resolve({server:datasLB[0].server});
                  } else {
                    defer.reject();
                  }
                } else {
                  defer.reject();
                }
              } else {
                defer.reject();
              }
            });
          } else {
            defer.reject();
          }
        });
      });
    }
    return defer.promise;
  }

  $scope.login = function(form) {
    $scope.submitted = true;
    if(!form.$valid) return;

    var promise = isLoadbalance();
    promise.then(
      function(res) { //成功
        //LimaRedirect.returnPath
        var url = res.server;
        if (!_.isUndefined(LimaRedirect.returnPath)) {
          url += LimaRedirect.returnPath;
        }
        var param1 = btoa($scope.user.companyLoginId);
        var param2 = btoa($scope.user.email);
        var param3 = btoa($scope.user.password);
        location.replace(url + '?companyId=' + param1 + '&email=' + param2 + '&password=' + param3);
        return;
      },
      function() { //失敗
        $scope.performing = true;
        Auth.login({
          loginAdmin: $scope.loginAdmin,
          companyLoginId: !$scope.loginAdmin ? $scope.user.companyLoginId : null,
          email: $scope.user.email,
          password: $scope.user.password
        })
        .then(function() {
          //S3アップロードしてるときはSignedCookieを取得する
          $http.get('/api/v1/configurations/AWSS3UploadServer').success(function (resAws) {
            if (resAws.enabled) {
              if (!resAws.AWSSetting.cookieDebug) {
                $http.get('/api/v1/playlists/aws/signed').success(function (resAws2) {
                  // Logged in, redirect to home
                  LimaRedirect.back({returnPath: '/'});
                });
              } else {
                var data = {redirectUrl:location.protocol + '//' + location.host};
                $http.get('/api/v1/playlists/aws/signed/debug?redirectUrl=' + decodeURIComponent(data.redirectUrl)).success(function (resAws2) {
                  //LimaRedirect.goではうまくcookieが設定されなかったのでlocation.replaceでリダイレクトする
    //              LimaRedirect.go({path:resAws2.signedUrl, returnPath:false, clearCache:false});
                  location.replace(resAws2.signedUrl);
                });
              }
            } else {
              // Logged in, redirect to home
              LimaRedirect.back({returnPath: '/'});
            }
          });

        }, function(err) {
          $scope.errors.other = (function () {
            switch (err.message) {
            case 'This companyID is not registered.' : return '企業IDが見つかりません。';
            case 'Missing credentials': return $translate.instant('LOGIN.WARN_MISSING_CREDENTIALS');
            case 'This username is not registered.': return $translate.instant('LOGIN.WARN_USERNAME_NOT_REGISTERED');
            case 'Invalid username/password':
            case 'This password is not correct.': return $translate.instant('LOGIN.WARN_PASSWORD_INCORRECT');
            case 'Account disabled':
            case 'This username is currently not effective.': return $translate.instant('LOGIN.WARN_USERNAME_NOT_EFFECTIVE');
            case 'This username is not allowed.':
            case 'This username is not supported.':
            case 'Not Permitted to login at this time':
            case 'Not permited to logon at this workstation':
            case 'Password expired':
            case 'Account expired':
            case 'User must reset password':
            case 'The user is not allowed to login from the address.': return $translate.instant('LOGIN.WARN_ADDRESS_NOT_ALLOWED');
            case 'User account locked':
            case 'The user has been locked-out.': return $translate.instant('LOGIN.WARN_USER_LOCKED');
            default: return err.message
            }
          })();
        })['finally'](function () {
          $scope.performing = false;
        });

      }
    );



  };
  $scope.login2 = function(form) {
    $scope.submitted = true;
//    if(!form.$valid) return;

    var data = {
      loginAdmin: $scope.loginAdmin,
      companyLoginId: !$scope.loginAdmin ? $scope.user.companyLoginId : null,
      email: $scope.user.email,
      password: $scope.user.password
    };


    $scope.performing = true;
    Auth.login({
      loginAdmin: $scope.loginAdmin,
      companyLoginId: !$scope.loginAdmin ? $scope.user.companyLoginId : null,
      email: $scope.user.email,
      password: $scope.user.password
    })
    .then(function() {
      //S3アップロードしてるときはSignedCookieを取得する
      $http.get('/api/v1/configurations/AWSS3UploadServer').success(function (resAws) {
        if (resAws.enabled) {
          if (!resAws.AWSSetting.cookieDebug) {
            $http.get('/api/v1/playlists/aws/signed').success(function (resAws2) {
              // Logged in, redirect to home
              LimaRedirect.back({returnPath: '/'});
            });
          } else {
            var data = {redirectUrl:location.protocol + '//' + location.host};
            $http.get('/api/v1/playlists/aws/signed/debug?redirectUrl=' + decodeURIComponent(data.redirectUrl)).success(function (resAws2) {
              //LimaRedirect.goではうまくcookieが設定されなかったのでlocation.replaceでリダイレクトする
//              LimaRedirect.go({path:resAws2.signedUrl, returnPath:false, clearCache:false});
              location.replace(resAws2.signedUrl);
            });
          }
        } else {
          // Logged in, redirect to home
          LimaRedirect.back({returnPath: '/'});
        }
      });

    }, function(err) {
      $scope.errors.other = (function () {
        switch (err.message) {
        case 'This companyID is not registered.' : return '企業IDが見つかりません。';
        case 'Missing credentials': return $translate.instant('LOGIN.WARN_MISSING_CREDENTIALS');
        case 'This username is not registered.': return $translate.instant('LOGIN.WARN_USERNAME_NOT_REGISTERED');
        case 'Invalid username/password':
        case 'This password is not correct.': return $translate.instant('LOGIN.WARN_PASSWORD_INCORRECT');
        case 'Account disabled':
        case 'This username is currently not effective.': return $translate.instant('LOGIN.WARN_USERNAME_NOT_EFFECTIVE');
        case 'This username is not allowed.':
        case 'This username is not supported.':
        case 'Not Permitted to login at this time':
        case 'Not permited to logon at this workstation':
        case 'Password expired':
        case 'Account expired':
        case 'User must reset password':
        case 'The user is not allowed to login from the address.': return $translate.instant('LOGIN.WARN_ADDRESS_NOT_ALLOWED');
        case 'User account locked':
        case 'The user has been locked-out.': return $translate.instant('LOGIN.WARN_USER_LOCKED');
        default: return err.message
        }
      })();
    })['finally'](function () {
      $scope.performing = false;
    });

  };
  function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

$scope.load = function() {
  var param1 = getParam('companyId');
  var param2 = getParam('email');
  var param3 = getParam('password');
  if (param1 && param2 && param3) {
    $scope.user.companyLoginId=atob(getParam('companyId'));
    $scope.user.email = atob(getParam('email'));
    $scope.user.password = atob(getParam('password'));
    if ($scope.user.companyLoginId) {
      $scope.login2();
    }
  }
}

}]);

//LoginCtrlの子
app.controller('LoginAdminCtrl', ['$scope', '$translate', 'Auth', '$http', 'LimaRedirect', '$q','$controller',
function ($scope, $translate, Auth, $http, LimaRedirect, $q, $controller) {
  $controller('LoginCtrl', {$scope:$scope, $translate:$translate, Auth:Auth,$http:$http, LimaRedirect:LimaRedirect,$q:$q}); //This works

  $scope.loginAdmin = true;

}]);

