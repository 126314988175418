'use strict';

var app = angular.module('limaApp');

app.controller('RegisterOptionCtrl', ['$scope', '$stateParams','$translate', '$timeout', 'Auth', '$http', 'Modal','LimaRedirect',
function ($scope, $stateParams, $translate, $timeout, Auth, $http, Modal, LimaRedirect) {
  $scope.user = {};
  $scope.errors = {};

  $scope.sendmail = false;
  if (!_.isUndefined($stateParams.mail)) {
    $scope.sendmail = $stateParams.mail === 'false' ? false : true;
  }

  $scope.optionServiceList= undefined;

  /**
   * Get the URL parameter value
   *
   * @param  name {string} パラメータのキー文字列
   * @return  url {url} 対象のURL文字列（任意）
   */
  function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  $scope.companyId=undefined;
  $scope.companyId = getParam('id');
  if ($scope.companyId) {
    $http.get('/api/v1/company/' + $scope.companyId).
    success(function(datas) {
      $scope.companyInfo = datas; //
    });
  }

  function getOptionService() {
    $http.get('/api/v1/option-service').
    success(function(datas) {
      $scope.optionServiceList = datas;
      _.forEach($scope.optionServiceList, function(list){
        list.checked = false;
        if (list.name === 'base') {
          list.checked = true;
        }
      });
      $timeout(function() {
        updateOptionServiceInfo();
      });
    });
  }
  getOptionService();

  function updateOptionServiceInfo() {
    //ログイン情報
    $scope.userinfo = null;
    $http.get('/api/v1/users/me').
    success(function(datas) {
      $scope.userinfo=datas;

      //登録済みか
      $scope.optionContractionInfo = null; //契約情報
      if ($scope.userinfo.companyId) {
        $http.get('/api/v1/option-service/' + $scope.userinfo.companyId).
        success(function(datas) {
          $scope.optionContractionInfo = datas; //契約情報

          _.forEach($scope.optionServiceList, function(list){
            _.find($scope.optionContractionInfo, function(info){
              if (info.optionId === list.optionServiceId) {
                if (list.name === 'base') {
                  $scope.selectedChoise.value = info.amount;
                }
                else if (list.name === 'DataTransferAmount1G') {
                  $scope.selectedChoiseTransfer.value = info.amount;
                }
                else if (list.name === 'AddPlaylist') {
                  $scope.selectedChoisePlaylist.value = info.amount;
                }
                return true;
              }
            });

          });

          $scope.UpdateCheckedList();

        });
        if (!$scope.companyId) {
          $http.get('/api/v1/company/' + $scope.userinfo.companyId).
          success(function(datas) {
            $scope.companyInfo = datas; //
          });
        }

      }

    });
  }




  $scope.checkedlist = [];
  $scope.UpdateCheckedList = (function() {
    _.forEach($scope.optionServiceList, function(list){
      var find = _.find($scope.optionContractionInfo, function(contra){
        return contra.optionId === list.optionServiceId ? true : false;
      });
      list.checked = find ? true : false;
    });
    $scope.changeDeviceChoise();
  });

  $scope.transferChoises = [{value:1},{value:2},{value:3},{value:4},{value:5}];
  $scope.selectedChoiseTransfer = _.cloneDeep($scope.transferChoises[0]);

  $scope.playlistChoises = [{value:1},{value:2},{value:3},{value:4},{value:5}];
  $scope.selectedChoisePlaylist = _.cloneDeep($scope.playlistChoises[0]);


  $scope.deviceChoises = [];
  for (var i =1; i <=100; i++ ){
    $scope.deviceChoises.push({value:i});
  }


  $scope.selectedChoise = _.cloneDeep($scope.deviceChoises[0]);
  //$scope.selectedChoise.value = $scope.deviceChoises[0].value;
  $scope.totalPrices = 0;

  $scope.changeDeviceChoise = function() {
    $scope.totalPrices = 0;
    _.forEach($scope.optionServiceList, function(list){
      if (list.checked){
        if (list.perDevice) {
          if (list.perDevice === 1) {
            list.priceTotal = $scope.selectedChoise.value * list.price;
            list.amount = $scope.selectedChoise.value;
          } else {
            var perDevice2 = parseInt($scope.selectedChoise.value / list.perDevice) + 1;  //小数切り捨て
            list.priceTotal = perDevice2 * list.price;
            list.amount = perDevice2;
          }

        } else {
          list.priceTotal = list.price;
          if (list.name === 'DataTransferAmount1G') {
            list.amount = $scope.selectedChoiseTransfer.value;
          }
          else if (list.name === 'AddPlaylist') {
            list.amount = $scope.selectedChoisePlaylist.value;
          }
          else {
            list.amount = 1;
          }
        }
        $scope.totalPrices += list.priceTotal;

      } else {
        list.priceTotal = undefined;
        list.amount = 0;

      }
    });
  }

  function reLogin() {
      //初期登録の時は再ログインしてもらう
      if (!$scope.optionContractionInfo) {
        Auth.logout();
        LimaRedirect.go({path:'/login', returnPath:'/', clearCache:true});
      }
  }


  $scope.doRegister = function(){
    var postdata = {
      companyId:$scope.userinfo.companyId ? $scope.userinfo.companyId : $scope.companyId,
      option : $scope.optionServiceList
    };

    var httpParam = {
      url: '/api/v1/option-service',
      method: '',
      data: postdata
    };

    if ($scope.optionContractionInfo) {
      httpParam.method = 'PUT';
    } else {
      httpParam.method = 'POST';
    }
    $http(httpParam).
    success(function(data) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"登録が完了しました。", modalClass:'modal-success'},
      function () {
        if ($scope.sendmail) {
          var textOption = {
            sendmail: $scope.companyInfo.email,
            companyName : $scope.companyInfo.name ,
            loginId : $scope.companyInfo.loginId ,
            userName : $scope.userinfo.name,
            optionServiceList : $scope.optionServiceList
            // ,
            //downloadurl : req.protocol + '://' + req.get('host') + path.dirname(req.baseUrl) + '/system/device'

          }
          $http.post('/api/v1/company/mail' ,textOption ).
          success(function(datas) {
            reLogin();
          });
        } else {
          reLogin();
        }
      });
    });




  }

  $scope.cancel = function() {
    window.history.back();
  }
}]);
