'use strict';


var app = angular.module('limaApp');

app.directive('iframeSetDimensionsOnload', function($window) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {

      var contentMinWidth = 100;

      element.on('load', setSizeWithZoom());

      angular.element($window).bind('resize', function() {
        setSizeWithZoom();
        scope.$digest();
      });

      function setSize() {
        var iFrameWidth = element[0].contentWindow.parent.document.body.offsetWidth;
        var iFrameHeight = element[0].contentWindow.parent.document.body.offsetHeight;
        element.css('width', iFrameWidth + 'px');
        element.css('height', iFrameHeight + 'px');
      }

      function setSizeWithZoom() {
        var iFrameWidth = element[0].contentWindow.parent.document.body.offsetWidth;
        var iFrameHeight = element[0].contentWindow.parent.document.body.offsetHeight;

        var scale = iFrameWidth / contentMinWidth;
        scale = scale > 1 ? 1 : scale;
        var zoom = 1 / scale;

        element.css('width', iFrameWidth + 'px');
        element.css('height', iFrameHeight + 'px');
        element.css('-webkit-transform', 'scale(' + scale + ')');
        element.css('-ms-transform', 'scale(' + scale + ')');
        element.css('transform', 'scale(' + scale + ')');
        element.css('zoom', zoom);
      }

    }
  }
});
app.directive('iframeOnload', function(){
    var directive = {
      scope: {
        callBack: '&iframeOnload'
      },
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.on('load', function() {
          console.log('in directive iframe loaded',element);
          // bad bad bad!
          // var elemWrapper = {theElem:element};
          // return scope.callBack({element:elemWrapper});
          return scope.callBack({element:element[0].src});
        });
      }
    };

    return directive;

});

app.directive("onImageError", ["$timeout", function($timeout) {

  function timeOut(id, scope) {
          //scope.imageLoaded = value;
          scope.onThumbError(id);
  }

  return {
      restrict: 'A',
      link: function(scope, element, attrs) {
          element.bind('load', function() {
              //timeOut(true, scope);
          }).bind('error', function() {
            //element.attr('src','./resources/images/webicon.png');
            element.attr('src','./resources/images/loading_200.gif');
              timeOut(element[0].id, scope);
          });
      }
  };

}]);

app.controller('ModalMaterialLayoutCtrl', ['$scope', 'socket', 'Auth', '$http', '$translate', '$timeout',/*'$routeParams',*/'$location','$sce','$uibModalInstance', 'LimaModalLayoutData',
function ($scope, socket, Auth,$http, $translate, $timeout,/*$routeParams,*/$location,$sce,$modalInstance, LimaModalLayoutData) {

  //trustAsResourceUrlを使わないとiFrameに設定できない
  $scope.url = $sce.trustAsResourceUrl(LimaModalLayoutData.src);
  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = LimaModalLayoutData.channels;
  if ($scope.channelsInfo.channels.length) {
    $scope.channelsInfo.select = $scope.channelsInfo.channels[0];
  }

  $scope.iframestyle = function() {

    var val_w1080 ={
      // 'transform':'scale(0.2)  translate(1080px,0px)',
      // 'transform-origin':'0px 0px',
      'width': '1080px',
      'height': '1920px',
  };

    var val_w1920 ={
      // 'transform':'scale(0.23) translate(200px,0px)',
      // 'transform-origin':'0px 0px',
      'width': '1920px',
      'height': '1080px',
    };

    return val_w1920;
  }



/*
//<div style="transform: scale(0.2) translate(-0px, 0px); transform-origin: 0px 0px 0px; max-height: 384px">
<div ng-style="iframestyle_div()" style="
transform: rotate(90deg) translate(-1080px, -960px);
transform-origin: bottom left;
"><iframe id="iframePreview" frameborder="0" ng-style="iframestyle()" ng-src="http://localhost:10080/layout/channel_20181108_015321/layout_18/layout.html" src="http://localhost:10080/layout/channel_20181108_015321/layout_18/layout.html" style="width: 1920px; height: 1080px;"></iframe></div>
</div>
*/

var getDeviceInfo = (function(){
  var ua = navigator.userAgent;
  if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
      return 'sp';
  }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
      return 'tab';
  }else{
      return 'other';
  }
})();

var isIphone = (function(){
  var ua = navigator.userAgent;
  if(ua.indexOf('iPhone') > 0 ){
      return true;
  }
  return false;
})();



$scope.iframestyle_div2 = function() {

  var val_270deg ={
    'transform': 'rotate(90deg) translate(-1080px, -960px)',
    'transform-origin': 'bottom left',
    '-ms-transform': 'rotate(90deg) translate(-1080px, -960px)',
    '-ms-transform-origin': 'bottom left',
    '-webkit-transform': 'rotate(90deg) translate(-1080px, -960px)',
    '-webkit-transform-origin': 'bottom left',
    '-moz-transform': 'rotate(90deg) translate(-1080px, -960px)',
    '-moz-transform-origin': 'bottom left',
    '-o-transform': 'rotate(90deg) translate(-1080px, -960px)',
    '-o-transform-origin': 'bottom left'

  };
  if (getDeviceInfo === 'sp' && isIphone) {
    var bSP = true;
    if (screen.width < screen.height) {
      if (screen.width >= 375) {
        bSP = false;
      }
    } else {
      if (screen.height >= 375) {
        bSP = false;
      }

    }
    if (bSP) {
      val_270deg ={
        'transform': 'rotate(90deg) translate(-1080px, -0px)',
        'transform-origin': 'bottom left',
        '-ms-transform': 'rotate(90deg) translate(-1080px, -960px)',
        '-ms-transform-origin': 'bottom left',
        '-webkit-transform': 'rotate(90deg) translate(-1080px, -0px)',
        '-webkit-transform-origin': 'bottom left',
        '-moz-transform': 'rotate(90deg) translate(-1080px, -960px)',
        '-moz-transform-origin': 'bottom left',
        '-o-transform': 'rotate(90deg) translate(-1080px, -960px)',
        '-o-transform-origin': 'bottom left'

        };

    } else {
      val_270deg ={
        'transform': 'rotate(90deg) translate(-1920px, 500px)',
        'transform-origin': 'bottom left',
        '-ms-transform': 'rotate(90deg) translate(-1920px, 500px)',
        '-ms-transform-origin': 'bottom left',
        '-webkit-transform': 'rotate(90deg) translate(-1920px, 500px)',
        '-webkit-transform-origin': 'bottom left',
        '-moz-transform': 'rotate(90deg) translate(-1920px, 500px)',
        '-moz-transform-origin': 'bottom left',
        '-o-transform': 'rotate(90deg) translate(-1920px, 500px)',
        '-o-transform-origin': 'bottom left'

        };

    }
  } else {
    val_270deg ={
      'transform': 'rotate(90deg) translate(-1080px, -0px)',
      'transform-origin': 'bottom left',
      '-ms-transform': 'rotate(90deg) translate(-1080px, -960px)',
      '-ms-transform-origin': 'bottom left',
      '-webkit-transform': 'rotate(90deg) translate(-1080px, -0px)',
      '-webkit-transform-origin': 'bottom left',
      '-moz-transform': 'rotate(90deg) translate(-1080px, -960px)',
      '-moz-transform-origin': 'bottom left',
      '-o-transform': 'rotate(90deg) translate(-1080px, -960px)',
      '-o-transform-origin': 'bottom left'

      };
  }
  var val_90deg ={
    'transform': 'rotate(270deg) translate(-1920px, 960px)',
    'transform-origin': 'left top'
  };
  var val_0deg ={
  };

  if (!LimaModalLayoutData.rotate) {
    return val_0deg;

  } else if (LimaModalLayoutData.rotate===90) {
    return val_90deg;
  } else if (LimaModalLayoutData.rotate===270) {
    return val_270deg;
  }
}

$scope.iframestyle_div = function() {

  var val_270deg ={
    'transform':'scale(0.2)  translate(-0px, -0px)',
    'transform-origin':'0px 0px',
    '-ms-transform': 'scale(0.2)  translate(-0px, -0px)',
    '-ms-transform-origin':'0px 0px',
    '-webkit-transform': 'scale(0.2)  translate(-0px, -0px)',
    '-webkit-transform-origin':'0px 0px',
    '-moz-transform': 'scale(0.2)  translate(-0px, -0px)',
    '-moz-transform-origin': '0px 0px',
    '-o-transform': 'scale(0.2)  translate(-0px, -0px)',
    '-o-transform-origin': '0px 0px',

    // 'max-width': '1080px',
    'max-height': LimaModalLayoutData.width * 0.2 + 'px',
  };
  var val_0deg ={
    'transform':'scale(0.23) translate(200px,0px)',
    'transform-origin':'0px 0px',
    '-ms-transform': 'scale(0.23) translate(200px,0px)',
    '-ms-transform-origin':'0px 0px',
    '-webkit-transform': 'scale(0.23) translate(200px,0px)',
    '-webkit-transform-origin':'0px 0px',
    '-moz-transform': 'scale(0.23) translate(200px,0px)',
    '-moz-transform-origin': '0px 0px',
    '-o-transform': 'scale(0.23) translate(200px,0px)',
    '-o-transform-origin': '0px 0px',

    'max-width': 1920*0.2 + 'px',
    'max-height': 1080 * 0.2 + 'px',
  };

  return LimaModalLayoutData.rotate % 180 === 0 ? val_0deg : val_270deg;
}

$scope.applyDone = function() {
    $modalInstance.close({selectChannel:$scope.channelsInfo.select});
    //$modalInstance.dismiss('done');

  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.enableShowURL = false;

  $scope.showLayoutURL = function() {
    $scope.enableShowURL = true;

    // $modalInstance.dismiss('cancel');

  }


}]);

app.controller('ModalMaterialPreviewCtrl', ['$scope', 'socket', 'Auth', '$http', '$translate', '$timeout', '$window','$uibModal', 'Modal',/*'$routeParams',*/'$location','$sce','$uibModalInstance','ContentUtil', 'LimaModalPreviewData','limaOptionService',
function ($scope, socket, Auth,$http, $translate, $timeout, $window, $modal, Modal,/*$routeParams,*/$location,$sce,$modalInstance,ContentUtil, LimaModalPreviewData,limaOptionService) {
  $scope.selectTweet = LimaModalPreviewData.selectTweet;
  $scope.playlistId = LimaModalPreviewData.playlistId;
  $scope.playlistName = LimaModalPreviewData.playlistName;
  $scope.channelsInfo = LimaModalPreviewData.channelsInfo;
  $scope.deviceGroups = LimaModalPreviewData.deviceGroups;
  $scope.devices = LimaModalPreviewData.devices;
  $scope.enableApplyMessage = LimaModalPreviewData.enableApplyMessage;
  $scope.useLayout = LimaModalPreviewData.useLayout;
  $scope.rotateLayout = $scope.useLayout ? ($scope.useLayout.rotate !== 0) : 0;

  $scope.applyOption={};
  $scope.applyOption.options=[];
  $scope.applyOption.options.push({name:$translate.instant('PLAYLIST.TABLE.DEVICE') , type:'device'});
  $scope.applyOption.options.push({name:$translate.instant('DEVICE.GROUP') , type:'group'});
  $scope.applyOption.select = $scope.applyOption.options[0];

  $scope.hasFunction = Auth.hasFunction;

  $scope.iframe_loaded = false;
  $scope.onloaded = function() {
    $timeout(function() {
      $scope.iframe_loaded = true;
    })
  }

  $scope.apply = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.goEdit = function() {
    $modalInstance.dismiss({event:'edit'});

  }
  $scope.updateChannel = function() {
    var deviceId;
    var deviceGroupsId;
    if ($scope.applyOption.select.type==='device') {
      deviceId = $scope.devices.select.deviceId;
    } else {
      deviceGroupsId = $scope.deviceGroups.select.groupId;
    }


    if ($scope.enableApplyMessage) {
      var applyName = "";
      if ($scope.applyOption.select.type==='device') {
        applyName = $scope.devices.select.name;
      } else {
        applyName = $scope.deviceGroups.select.name;
      }
      var message =  "<strong>" + $scope.playlistName + "</strong>を <br>" +
                      "<strong>" + applyName + "</strong>" + " に反映しますか?";

      var modalInstance = $modal.open({
        templateUrl: 'app/playlist/dialog/confirmCheck.html',
        controller: 'ConfirmCheckCtrl',
        windowClass: 'modal-preview-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          ConfirmCheck: function() {
            return {
              checked: false,
              messageHTML : message
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        // console.log("");
      }, function(results) {
        //
        if (results === "done") {
          $modalInstance.dismiss({event:'updateChannel', deviceGroupsId: deviceGroupsId, deviceId:deviceId});
        }
      });
      return;
    }

    $modalInstance.dismiss({event:'updateChannel', deviceGroupsId: deviceGroupsId, deviceId:deviceId});
  }

  $scope.isText = function(item) {
    var bb = ContentUtil.isText(item);
    return bb;
  }

  $scope.isExinput = function(item) {
    return ContentUtil.isExInput(item);
  }

  $scope.isLive = function(item) {
    return ContentUtil.isLive(item);
  }

  $scope.isVideo = function(item) {
    var bVideo = ContentUtil.isVideo(item);
    return bVideo;
  }
  $scope.isImage = function(item) {
    return ContentUtil.isImage(item);
  }
  $scope.isHTML = function(item) {
    return ContentUtil.isHTML(item);
  }
  $scope.isYoutube = function(content) {
    //ContentUtil.isYoutube　では判定できない。URLしか入ってないので
    if ($scope.isHTML(content)) {
      if (content.urlContents) {
        if (content.urlContents.length) {
          if (content.urlContents[0].sourceURL) {
            if (content.urlContents[0].sourceURL.length ) {
              if (content.urlContents[0].sourceURL.indexOf("https://www.youtube.com/watch?v=") !== -1 ) {
                return true;
              }
            }
          }
        }
      }

    }
    return false;
  }

  $scope.getUrl = function(item) {
    if (ContentUtil.isAutoupdated(item)) {
      if (item.autoUpdated) {
        if (item.autoUpdated.length) {
          return item.autoUpdated[0].url;
        }
      }
    }
    if (item.files) {
      if (item.files.length ) {
      return item.files[0].url;
      }
    }
    if ($scope.isLive(item)) {
      if (item.urlContents) {
        if (item.urlContents.length) {
          if (item.urlContents[0].files) {
            if (item.urlContents[0].files.length) {
              return  $sce.trustAsResourceUrl(item.urlContents[0].files[0].url);
            }
          }
        }
      }
    }
    if ($scope.isHTML(item)) {
        var youtubeUrl = ContentUtil.getYouTubeVideoEmbedUrl(item.urlContents[0].sourceURL);
      if (youtubeUrl.length) {
        return  $sce.trustAsResourceUrl(youtubeUrl);
      }
      return $sce.trustAsResourceUrl(item.urlContents[0].sourceURL);
    }
    return "";
  }

  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.$on('$viewContentLoaded', function(){
    //Here fyour view content is fully loaded !!
  });
  $scope.marqueDuration = $scope.selectTweet.speed + "s";
  $scope.marqueStyle = //{marginTop:'10px', color:content.titleColor || $scope.colors[0]};
  {
    '-webkit-animation-duration':$scope.marqueDuration,
    '-moz-animation-duration':$scope.marqueDuration,
    '-ms-animation-duration':$scope.marqueDuration,
    '-o-animation-duration':$scope.marqueDuration,
    'animation-duration':$scope.marqueDuration
  };


  $scope.FunCall = function() {
  };

  $scope.isDeviceGroupEnable = false;
  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.isDeviceGroupEnable = true;
    }
  });

  angular.element(function () {
    console.log('page loading completed');
    $timeout(function(){$scope.FunCall();},500);
  });

  $scope.onResize = function() {
    if (_.isUndefined(angular.element('#htmlDiv')[0])) {
      $timeout(function(){  //timeoutの中でapplyが起こる
//        $scope.onResize();
      },100);
      return;
    }
    var htmlDiv_w = angular.element('#htmlDiv')[0].clientWidth;
    var htmlDiv_h = angular.element('#htmlDiv')[0].clientHeight;
    var rate_h = htmlDiv_h / 1080.0;
    var rate_w = htmlDiv_w / 1920.0;

    var rate = rate_w;
    if (rate_h < rate_w ) {
      rate = rate_h;
    }
    angular.element('#htmlDivImage')[0].style.transform = 'scale(' + rate + ')';
    angular.element('#htmlDivImage')[0].style.MozTransform = 'scale(' + rate + ')';
    angular.element('#htmlDivImage')[0].style.WebkitTransform = 'scale(' + rate + ')';
    angular.element('#htmlDivImage')[0].style.OTransform = 'scale(' + rate + ')';
    angular.element('#htmlDivImage')[0].style.MsTransform = 'scale(' + rate + ')';

    if ($scope.playlistId) {
      //#14797 https://teratail.com/questions/207199 DIVを重ねてクリックできないようにする
      $timeout(function() {
        var v = document.querySelector('.wrapframe');
        var overlay = document.querySelector('.overlayframe')
        if (v && overlay) {
          overlay.style.width = v.clientWidth + 'px'
          overlay.style.height = v.clientHeight + 'px'
        }
      },100)


    }

  }
  $scope.onResize();

  function onResizeA() {
    $scope.onResize();
    $scope.$apply();
  }

  angular.element($window).on('resize', onResizeA);
  $timeout(function(){  //timeoutの中でapplyが起こる
    $scope.onResize();
  });

  $scope.$on('$destroy', function () {
    angular.element($window).off('resize', onResizeA);
  });

  $scope.previewframe = () => {
    const htmlDiv = document.getElementById('htmlDiv')
    let scale = 1;
    if(htmlDiv){
      scale = htmlDiv.clientWidth / 1920;
    }

    const rotate = $scope.useLayout.rotate;

    return{
      'width': 1920 * scale,
      'height': 1080 * scale,
      'rotate': rotate + 'deg'
    }
  }



}]);

app.controller('ModalMaterialPropertyCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$filter', '$uibModal','Modal','$translate',/*'$routeParams',*/'$location','$sce','$uibModalInstance','$window','ContentUtil','limaAppUtil', 'LimaModalPropertyData',
function ($scope, socket, Auth,$http, $timeout,$filter,$modal, Modal,$translate,/*$routeParams,*/$location,$sce,$modalInstance,$window,ContentUtil, limaAppUtil, LimaModalPropertyData) {
  $scope.selectTweet = _.cloneDeep(LimaModalPropertyData.selectTweet);
  $scope.playlistSetting= _.cloneDeep(LimaModalPropertyData.playlistSetting);
  $scope.layoutInfo = LimaModalPropertyData.layout;
  $scope.rotateLayout = $scope.layoutInfo.rotate !== 0;
  $scope.regionInfo = LimaModalPropertyData.region;

  $scope.plusText = function(index) {
    $scope.selectTweet.properties.push({text:""});
  }
  $scope.isText = function(item) {
    var bb = ContentUtil.isText(item);
    return bb;
  }

  //この設定をテロップの初期値にする
  $scope.saveInitSet = function(content) {

    var settingData = _.cloneDeep(content.additionalInfo);
    if (settingData.text) {
      delete settingData.text;
    }
    var data = [
      {
        name : 'setting.content.telop',
        value : {defaultSetting : settingData}
      }
    ];

    $http.post('/api/v1/setting' , {companyId :content.companyId,datas : data}).
    success(function(datas) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:"現在の設定をテロップの初期値に設定しました。",
      modalClass:'modal-information'});

    });
  }

  $scope.doChecked4K = function(content) {
    if (content.checked4K) {
      var check_cont = _.cloneDeep(content);  //チェック用に一時的に作る
      check_cont.additionalInfo = {enable4KImage:true}; //チェック用に一時的にtrue

      var res_isEnableInsert =LimaModalPropertyData.checkFunc(check_cont , $scope.regionInfo);
      if (!res_isEnableInsert.enable) {
        var mes = "複数リージョンには登録できません"
        mes += "<br>※「" + res_isEnableInsert.reasonContType+ "」が別のリージョンに登録されています。"

        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:mes,
        modalClass:'modal-success'});

        content.checked4K = false;
        return;
      } else {
        content.additionalInfo = {enable4KImage:true};
        $scope.edit_updated = true;

      }
    } else {
      if (content.additionalInfo) {
        if (content.additionalInfo.enable4KImage) {
          delete content.additionalInfo;
          $scope.edit_updated = true;
        }
      }
    }
  }

  function getFontInfo(){
    $scope.fontInfo = { };
    if (!$scope.isText($scope.selectTweet)) {return;}

    $http.get('/api/v1/system/fonts/info').
    success(function(datas) {
      $scope.fontInfo.datas = datas;
      if ($scope.selectTweet.additionalInfo) {
        if ($scope.selectTweet.additionalInfo.font) {
          $scope.fontInfo.selected = _.find(datas , function(dt) {
            return dt.fontFamily === $scope.selectTweet.additionalInfo.font
          })
        } else {
          if (datas.length) {
            $scope.fontInfo.selected =datas[0];
          }
        }
      }
    });
  }
  getFontInfo();

  $scope.colorAlphaInfo = {};
  function getColorInfo(){
    if (!$scope.isText($scope.selectTweet)) {return;}
    if ($scope.selectTweet.additionalInfo) {
      if ($scope.selectTweet.additionalInfo.fontColor) {
        var rgb1 = limaAppUtil.rgbaToHex($scope.selectTweet.additionalInfo.fontColor)
        var rgb2 = limaAppUtil.rgbToHex($scope.selectTweet.additionalInfo.fontColor)
        if (!rgb2 && !rgb1) {
          $scope.colorAlphaInfo.fontColorAlpha = 100;
        } else {
          var alpha = rgb1 ? rgb1.alpha : rgb2.alpha;
          $scope.colorAlphaInfo.fontColorAlpha = 100 - parseInt(alpha*100);
        }

        rgb1 = limaAppUtil.rgbaToHex($scope.selectTweet.additionalInfo.backgroudColor)
        rgb2 = limaAppUtil.rgbToHex($scope.selectTweet.additionalInfo.backgroudColor)
        if (!rgb2 && !rgb1) {
          $scope.colorAlphaInfo.backgroudColorAlpha = 100;
        } else {
          var alpha = rgb1 ? rgb1.alpha : rgb2.alpha;
          $scope.colorAlphaInfo.backgroudColorAlpha = 100 - parseInt(alpha*100);
        }
      }
    }
  }
  getColorInfo();

  function windowOen(str_htm) {
            // ウィンドウを作成する
            let w = window.open('','_blank', 'popup=1');

            // 初期状態としてHTMLを直接挿入
            var anchor = document.createElement('a');
            anchor.href = str_htm;
            //if (newTab)
            {
                //anchor.target = '_blank';
            }
            anchor.click();
//            w.document.body.innerHTML = str_htm


            // ページがリロードされた時のハンドラー
            const resetHandler = () => {
              w.addEventListener('beforeunload', () => {

                // もしリロードされたら、0.1秒待ってHTMLを挿入しハンドラーをリセットする
                setTimeout(() => {
                  try{
                    w.document.body.innerHTML = restr_htms;
                  } catch(err){
                    // クロスオリジンでエラー(URLバーが直接編集されたような場合)は、windowを閉じる
                    w.close();
                  }
                  resetHandler();
                }, 100);

              });
            }

            resetHandler();
  }

  $scope.live_preview = function(selectTweet) {
    var jsPath = location.protocol + "//" + location.host + "/preview/includes/js/lib/hls.js";
    var winHtml = "<!DOCTYPE html><html><head>  <meta charset='UTF-8'>  <title>" + selectTweet.title + "</title> <style>    html, body    {        overflow: hidden;    }  </style> <script src='" + jsPath +"'></script></head><body id=''  onLoad='' onclick='' bgcolor='#000' style='margin: 0px;'><div>  <video id='video_m3u8' style='background-color: white;width:100%;' oncontextmenu='return false;' src=''></video></div><script>  var video = document.getElementById('video_m3u8');  var videoSrc = '" + selectTweet.urlContents[0].localPath + "';  if (Hls.isSupported()) {    var hls = new Hls();    hls.loadSource(videoSrc);    hls.attachMedia(video);setTimeout(function(){video.play();},100);  }  else if (video.canPlayType('application/vnd.apple.mpegurl')) {    video.src = videoSrc;setTimeout(function(){video.play();},100);  }</script></body></html>";
      const winUrl = URL.createObjectURL(
        new Blob([winHtml], { type: "text/html" })
    );
//    windowOen(winUrl);
    const win = window.open(
        winUrl,
        "win",
        `width=640,height=360,screenX=200,screenY=200`
    );

    }

  function updateTelopPreview(){
    if (!$scope.isText($scope.selectTweet)) {
      return;
    }

    if (!$scope.selectTweet.additionalInfo) {
      $scope.selectTweet.additionalInfo = {
        vertical : false ,
        align:"left",
        autofontSize:true,
        fontSize:80,
        flow : false,
        flowSpeed : $scope.playlistSetting.changeContentDuration,  //デフォルト
        flowSpeedByPlaylistSetting : true,
        fontColor : "rgba(0,0,0,1)",
        backgroudColor : "rgba(255,255,255,1)"
      };
    } else {
      if (!_.isUndefined($scope.selectTweet.additionalInfo.constantFlowSpeed)) {
        $scope.selectTweet.additionalInfo.flowType = "speed";
      } else {
        $scope.selectTweet.additionalInfo.flowType = "time";
      }
      if ($scope.selectTweet.additionalInfo.flowType==="time") {
        if (_.isUndefined($scope.selectTweet.additionalInfo.flowSpeed)) {
          $scope.selectTweet.additionalInfo.flowSpeed = $scope.playlistSetting.changeContentDuration;  //デフォルト
          $scope.selectTweet.additionalInfo.flowSpeedByPlaylistSetting = true;
        }
      } else {
        if (_.isUndefined($scope.selectTweet.additionalInfo.constantFlowSpeed)) {
          $scope.selectTweet.additionalInfo.constantFlowSpeed = '5';
        }
      }

      if (_.isUndefined($scope.selectTweet.additionalInfo.text)) {
        $scope.selectTweet.additionalInfo.text =  $scope.selectTweet.telopInfo.text;
      }
    }


      var postData = {
        region : LimaModalPropertyData.region ,
        additionalInfo : $scope.selectTweet.additionalInfo,
        layoutInfo: $scope.layoutInfo
      }

      $http.post('/api/v1/content/extra/text/' + $scope.selectTweet.id + '/preview', postData).
      success(function(data) {
        console.log("success set preview");
        $timeout(function() {
          $scope.urlTelopPreview = data.url;
          $scope.urlTelopPreview_data = data;
        })
      });
    }
    if (LimaModalPropertyData.playlistSetting) {
      if (LimaModalPropertyData.region) {
        updateTelopPreview();
      }
    }



  $scope.edit_updated = false;
  $scope.edit_starting = false;
  $scope.edit_start = function(index) {

    const ta_span = document.getElementById('textarea_span');
    const ta = document.getElementById('textarea');
    const ch = ta.clientHeight;
    ta.style.height = ta_span.clientHeight + 30 + 'px';;
    // textarea.addEventListener('input', ()=>{
    //   ta.style.height = ch + 'px';
    //   const sh = ta.scrollHeight;
    //   ta.style.height = sh + 'px';
    // });

    $timeout(function() {

      $scope.edit_starting = true;
    })
    $scope.selectTweet.properties.push({text:""});
  }

  function updateTelop(callback) {
    $timeout(function() {
      $scope.edit_starting = false;
      var ta = document.getElementById('textarea');
      $http.put('/api/v1/content/extra/text/' + $scope.selectTweet .id ,{telop:ta.value} ).
      success(function(data) {
        $scope.selectTweet.telopInfo.text = ta.value;
        $scope.edit_updated = true;
        callback(null);
      });
    })
  }

  $scope.edit_end = function(index) {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "テキストを更新してよろしいですか？",
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;

      updateTelop();
    });
  }
  $scope.thumnailUpdating = false;
  $scope.thumnailUpdated = false;

  $scope.updateThumnail = function(content) {
//    socket.emit('lima:call-request:v1:contents:thumbnail:update',{contentId:content.id,time:2000,companyId:content.companyId,wait:true});
    var postData = {
      contentId : content.id,
      renderDelay : 60*1000,
      time : new Date().getTime(),
      wait : true
    }
    $timeout(function() {
      $scope.thumnailUpdating = true;
    });
    $http.put('/api/v1/content/contents/' + content.id + '/thumbnails', postData).
    success(function(data) {
      $timeout(function() {
        $scope.thumnailUpdating = false;
        $scope.thumnailUpdated = true;
      },5000);  //S3へのアップロードに少しかかる
    });


  }

  $scope.modalContentSetting = function(content) {
    var modalInstance = $modal.open({
      templateUrl: 'app/content/dlg/setting.html',
      controller: 'ContentSettingCtrl',
      windowClass: 'modal-registSNS-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        ModalContentSetting: function() {
          return {
            content : content,
            region : _.cloneDeep(LimaModalPropertyData.region),
            playlistSetting: _.cloneDeep($scope.playlistSetting)
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      $timeout(function(){
        $scope.edit_updated = true;

        $scope.selectTweet.additionalInfo = results.additionalInfo;

        getFontInfo();
        getColorInfo();
        updateTelopPreview();
      });

    }, function() {
      //
    });

  }


  $scope.isExinput = function(item) {
    return ContentUtil.isExInput(item);
  }

  $scope.isLive = function(item) {
    return ContentUtil.isLive(item);
  }


  $scope.isVideo = function(item) {
    var bVideo = ContentUtil.isVideo(item);
    return bVideo;
  }
  $scope.isImage = function(item) {
    return ContentUtil.isImage(item);
  }
  $scope.isImage4KSize = function(content){
    return ContentUtil.isImage4KSize(content);
  }
  $scope.isImage4K = function(content){
    return ContentUtil.isImage4K(content);
  }

  $scope.isYoutube = function(item){
    return ContentUtil.isYoutube(item);
  }
  $scope.isYoutubeLive = function(item){
    return ContentUtil.isYoutubeLive(item);
  }


  $scope.isHTML = function(item) {
    return ContentUtil.isHTML(item);
  }
  $scope.isURL = function(content) {
    return $scope.isHTML(content) && !$scope.isYoutube(content) && !$scope.isLive(content);
  }
  $scope.isURL_http = function(content) {
    return ContentUtil.isHTML_HTTP(content);
  }

  $scope.getImageCodecName = function(item) {
    return ContentUtil.getImageCodecName(item);
  }


  $scope.getAutoUpdatedTypeName = function(item) {
    return ContentUtil.getAutoUpdatedTypeName(item);
  }

  $scope.isAutoUpdated = function(item) {
    return ContentUtil.isAutoupdated(item);
  }

  $scope.selectTweet.checked4K = $scope.isImage4K($scope.selectTweet);


  $scope.iframestyleProp = function() {
    var elem_iframe = document.getElementById('htmlDivImage');
    //var iFrameWidth = elem_iframe.contentWindow.parent.document.body.offsetWidth;
    //var iFrameHeight = elem_iframe.contentWindow.parent.document.body.offsetHeight;
    var scale = elem_iframe.clientWidth / 1920;

    var val ={
      'width': '1920px',
      'height': '1080px',
      'transform':'scale(' + scale + ')',
      '-moz-transform':'scale(' + scale + ')',
      '-webkit-transform':'scale(' + scale + ')',
      '-o-transform':'scale(' + scale + ')',
      '-ms-transform':'scale(' + scale + ')',
      'transform-origin':'0 0',
      '-moz-transform-origin':'0 0',
      '-webkit-transform-origin':'0 0',
      '-o-transform-origin':'0 0',
      '-ms-transform-origin':'0 0'

    } ;

    var val_w1080 ={
//        'transform':'scale(0.2)  translate(1080px,0px)',
      'transform':'scale(' + scale + ')  translate(1080px,0px)',
      'transform-origin':'0px 0px',
      'width': '1080px',
      'height': '1920px',
  };

    var val_w1920 ={
      'transform':'scale(0.23) translate(200px,0px)',
      'transform-origin':'0px 0px',
      'width': '1920px',
      'height': '1080px',
    };
    return val;
    return $scope.selectLayout.model.width === 1920 ? val_w1920 : val_w1080;
  }

  $scope.iframeRotate = () => {
    const elem_iframe = document.getElementById('htmlDivImage');
    const scale = elem_iframe.clientWidth / 1920;

    return{
        'width': 1920 * scale,
        'height': 1080 * scale,
        'rotate': $scope.layoutInfo.rotate + 'deg',
    }
  }


  $scope.getDurationText = function(duration, scale) {
    try {
      scale = scale || 1000000; // micro sec.
      if (typeof duration === 'string') duration = parseInt(duration);
      if (typeof duration !== 'number' || isNaN(duration)) return 'N/A';
      var sign = '';
      if (duration < 0) {sign = '-'; duration = -duration; }
      duration = Math.ceil(duration / scale);
      var hh = Math.floor(duration / 3600);
      var mm = Math.floor((duration -= hh * 3600) / 60);
      var ss = duration -= mm * 60;
      return sign + ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ':' + ('0' + ss).slice(-2);
    } catch (ex) {
      return '';//'N/A';
    }
  };
  function displayBytes(bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
    if (bytes === 0) return '0 bytes';
    if (typeof precision === 'undefined') precision = 1;
    var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    var number = Math.floor(Math.log(bytes) / Math.log(1024));
    //while (number > 0 && precision >= 3) {
    //  --number;
    //  precision -= 3;
    //}
    if (number === 0) precision = 0;
    return $filter('number')(bytes / Math.pow(1024, Math.floor(number)), precision) +  ' ' + units[number];
  }

  $scope.getCodec = function(item){
    var str = '';
    if (item.files && item.files.length ) {
      if ($scope.isVideo(item)) {
        if (item.files[0].videoStreams.length) {
          str = $translate.instant('CONTENT.PROPERTY.TITLE_VIDEO')  + item.files[0].videoStreams[0].codecName;
        }
        if (item.files[0].audioStreams.length) {
          str += $translate.instant('CONTENT.PROPERTY.TITLE_AUDIO') + item.files[0].audioStreams[0].codecName;
        }
      } else {
        str = item.files[0].videoStreams[0].codecName;
      }
    }
    return str;
  }

  $scope.isEnableAspect = function(item){
    if (item.files && item.files.length ) {
      if (item.files[0].videoStreams.length) {
        var arr = item.files[0].videoStreams[0].aspectRatio.split(':');
        if (arr.length === 2) {
          if (parseInt(arr[0]) && parseInt(arr[1])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  $scope.getAspect = function(item){
    if (item.files && item.files.length ) {
      if (item.files[0].videoStreams.length) {
        return item.files[0].videoStreams[0].aspectRatio;
      }
    }
    return '';
  }

  function calcFramerate(fr100) {
    var FrameRate = {};
    if (fr100 === 6000) {                  // 60
      FrameRate.Numerator = 60;
      FrameRate.Denominator = 1;
      FrameRate.display = '60';
    }
    else if (5900 < fr100 && fr100 < 6000) {        // 59.94
      FrameRate.Numerator = 60000;
      FrameRate.Denominator = 1001;
     // this.df = true;
     FrameRate.display = '59.94';
    }
    else if (4900 < fr100 && fr100 < 5100) {        // 50
      FrameRate.Numerator = 50;
      FrameRate.Denominator = 1;
      FrameRate.display = '50';
    }
    else if (fr100 === 3000) {                // 30
      FrameRate.Numerator = 30;
      FrameRate.Denominator = 1;
      FrameRate.display = '30';
    }
    else if (2900 < fr100 && fr100 < 3000) {        // 29.97
      FrameRate.Numerator = 30000;
      FrameRate.Denominator = 1001;
      FrameRate.display = '29.97';
     // this.df = true;
    }
    else if (2400 < fr100 && fr100 < 2600) {        // 25
      FrameRate.Numerator = 25;
      FrameRate.Denominator = 1;
      FrameRate.display = '25';
    }
    else if (fr100 === 2400) {                // 24
      FrameRate.Numerator = 24;
      FrameRate.Denominator = 1;
      FrameRate.display = '24';
    }
    else if (2300 < fr100 && fr100 < 2400) {        // 23.97
      FrameRate.Numerator = 24000;
      FrameRate.Denominator = 1001;
      FrameRate.display = '23.97';
    }
    else {
      FrameRate = undefined;
    }
    return FrameRate;
  }

  $scope.getFramerate = function(item){
    if (item.files && item.files.length ) {
      if (item.files[0].videoStreams.length) {
        if ($scope.isVideo(item)) {
          var arr = item.files[0].videoStreams[0].frameRate.split('/');
          var fr = undefined;
          if (arr.length === 2) {
            var fr100 = (parseInt(arr[0]) / parseInt(arr[1])) * 100;
            fr = calcFramerate(fr100);
          }
          if (_.isUndefined(fr)) {
            return item.files[0].videoStreams[0].frameRate;
          } else {
            return fr.display;
          }
        }
      }
    }
    return '';
  }
  $scope.getbitRate = function(item){
    if (item.files && item.files.length ) {
      if (item.files[0].videoStreams.length) {
        if ($scope.isVideo(item)) {
          var bitrate = Math.round(item.files[0].videoStreams[0].bitRate / 100) / 10
          return bitrate + 'kbps';
        }
      }
    }
    return '';
  }

  $scope.getFileSize = function(item){
    if (item.files && item.files.length ) {
      if (item.files[0].videoStreams.length) {
        return displayBytes(item.files[0].length);
      }
    }
    return '';
  }

  $scope.getSize = function(item){
    if (item.files && item.files.length ) {
      if (item.files[0].videoStreams) {
        if (item.files[0].videoStreams.length) {
          return item.files[0].videoStreams[0].width + ' x ' + item.files[0].videoStreams[0].height;
        }
      }
    }
    return '';
  }
  $scope.getDuration = function(item){
    if (item.files && item.files.length ) {
      if (item.files[0].videoStreams.length) {
        if ($scope.isVideo(item)) {
          return $scope.getDurationText(item.files[0].videoStreams[0].duration);
        }
      }
    }
    if ($scope.isHTML(item)) {
      if (item.urlContents[0].additionalInfo.info) {
        if (item.urlContents[0].additionalInfo.info.duration) {
          return $scope.getDurationText(item.urlContents[0].additionalInfo.info.duration);
        }
      }
    }
    return '';
  }

  $scope.getUrl = function(item) {
    if (item.files) {
      if (item.files.length ) {
      return item.files[0].url;
      }
    }
    if ($scope.isLive(item)) {
      if (item.urlContents) {
        if (item.urlContents.length) {
          if (item.urlContents[0].files) {
            if (item.urlContents[0].files.length) {
              return  $sce.trustAsResourceUrl(item.urlContents[0].files[0].url);
            }
          }
        }
      }
    }

    if ($scope.isHTML(item)) {
      var youtubeUrl = ContentUtil.getYouTubeVideoEmbedUrl(item.urlContents[0].sourceURL);
      if (youtubeUrl.length) {
        return  $sce.trustAsResourceUrl(youtubeUrl);
      }
      return $sce.trustAsResourceUrl(item.urlContents[0].sourceURL);
    }
    return "";
  }

  $scope.getUrlForUI = function(item) {
    if (item.files) {
      if (item.files.length ) {
      return item.files[0].url;
      }
    }
    if ($scope.isHTML(item)) {
      return $sce.trustAsResourceUrl(item.urlContents[0].sourceURL);
    }
    return "";
  }


  if (_.isUndefined($scope.selectTweet.properties)) {
    $scope.selectTweet.properties = [];
    $scope.plusText(0);
  }

  $scope.apply = function() {
    $modalInstance.close($scope.selectTweet);
    $modalInstance.dismiss('done');

  }
  $scope.cancel = function() {
    if ($scope.edit_starting) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: "テキストの編集内容を保存せずに終了しますか？",
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (yes) {
          $scope.edit_starting = false;
          //updateTelop(function() {
            if ($scope.edit_updated) {
              $modalInstance.dismiss({event:'updated' , content:$scope.selectTweet});
            } else {
              $modalInstance.dismiss({event:'cancel'});
            }

          //});
        }

      });

    } else {

      if ($scope.urlTelopPreview_data && $scope.urlTelopPreview_data.uuid) {
        //プレビュー用に作ったファイル削除
        $http.delete('/api/v1/content/extra/text/' + $scope.selectTweet.id + '/preview/' + $scope.urlTelopPreview_data.uuid ).
        success(function(data) {

          if ($scope.edit_updated) {
            $modalInstance.dismiss({event:'updated' , content:$scope.selectTweet});
          } else {
            $modalInstance.dismiss({event:'cancel'});
          }

        });

      } else {
        if ($scope.edit_updated) {
          $modalInstance.dismiss({event:'updated' , content:$scope.selectTweet});
        } else if ($scope.thumnailUpdated) {
          $modalInstance.dismiss({event:'thumnailUpdated'});
        } else {
          $modalInstance.dismiss({event:'cancel'});
        }
      }

    }


  }

  $scope.edit = function() {
    // $modalInstance.close($scope.selectTweet);
    // $modalInstance.dismiss('cancel');
    var url = $location.protocol() + '://'+ $location.host() +':'+  $location.port();
    $window.open(url + '/edit?contentId=' + $scope.selectTweet.id, '_blank');

  }
  $scope.delete = function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
//    削除することを通知
      if ($scope.selectTweet.id) {
        //$modalInstance.close($scope.selectTweet);
        $modalInstance.dismiss({event:'delete'});
      }
    });
  }


  $scope.$on('$viewContentLoaded', function(){
    //Here your view content is fully loaded !!
  });
  $scope.marqueDuration = $scope.selectTweet.speed + "s";
  $scope.marqueStyle = //{marginTop:'10px', color:content.titleColor || $scope.colors[0]};
  {
    '-webkit-animation-duration':$scope.marqueDuration,
    '-moz-animation-duration':$scope.marqueDuration,
    '-ms-animation-duration':$scope.marqueDuration,
    '-o-animation-duration':$scope.marqueDuration,
    'animation-duration':$scope.marqueDuration
  };


  $scope.FunCall = function() {
  };

  $scope.loadYouTube = function() {
    var firstScriptTag = document.getElementById("yt-video-iframe");
    if (firstScriptTag) {
      firstScriptTag.src=$scope.getUrl($scope.selectTweet);
    }
  }


  angular.element(function () {
    console.log('page loading completed');
    $timeout(function(){
      $scope.loadYouTube();
    },10);
    $timeout(function(){
      $scope.FunCall();
    },500);
  });




}]);


