'use strict';

var app = angular.module('limaApp');



app.controller('ContentListCtrl', ['$scope', 'socket', 'Auth', '$http', '$filter', '$timeout','$location','$sce','$uibModal', 'Modal','$q', '$translate','$window', 'ContentUtil', 'limaSocketService','limaOptionService','limaShowWaitDialog',
function ($scope, socket, Auth,$http, $filter, $timeout,$location,$sce,$modal, Modal, $q, $translate, $window, ContentUtil, limaSocketService,limaOptionService, limaShowWaitDialog) {

    //////////2022.12.30 -add
    $scope.bContentListEnable = false;
    $scope.setListDisplayType = function(enableList){
      $scope.bContentListEnable = enableList;
    }

    $scope.ui = {showPlaylistType : 2}; //0:all , 1:used  2:notUsed
    $scope.playlists = [];
    function getPlaylistContent(callback) {
      var async = require('async');
      var arrContents = [];
      $http.get('/api/v1/playlists').
      success(function(datas_pl) {
        async.forEachOfSeries(datas_pl.playlists , function(pl,index,next){
          $http.get('/api/v1/playlists/' + pl.playlistId + '/contents').
          success(function(datas_con) {
            pl.contents = datas_con;
            arrContents = arrContents.concat(datas_con);
            next(null);
          });
        },function(err){
          $scope.playlists = datas_pl.playlists;
          var arrContentsUsed = arrContents.filter(function (x, i, array) {
            return array.findIndex(function (y) {
              return y.contentId === x.contentId;
            }) === i
          });
          callback(arrContentsUsed);
        })
      });
    }
    //////////2022.12.30 -add

    $scope.onThumbError = function (index) {
      console.log("onThumbError ");
      if ($scope.isHTML($scope.contentsInfo[index])) {
        $scope.updateThumbnail($scope.contentsInfo[index]);
      } else {
        $timeout(function(){
          $http.get('/api/v1/content/contents/' + $scope.contentsInfo[index].id)
          .success(function(resMat) {
            $timeout(function(){
              $scope.contentsInfo[index].thumbnailUrl = resMat.thumbnailUrl;
            });
          });
        } , 1000 );
      }

      // $http.get('/api/v1/content/contents/' + $scope.contentsInfo[index].id)
      // .success(function(resMat) {
      //   if (resMat.thumbnailUrl !== $scope.contentsInfo[index].thumbnailUrl ) {
      //     $timeout( function(){
      //       $scope.contentsInfo[index].thumbnailUrl = resMat.thumbnailUrl;
      //     });
      //   } else {
      //     console.log("onThumbError next");
      //     $timeout(function() {
      //       $scope.onThumbError(index)
      //     }, 2000);
      //   }
      // });

    }

    var getDeviceInfo = (function(){
      var ua = navigator.userAgent;
      if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
          return 'sp';
      }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
          return 'tab';
      }else{
          return 'other';
      }
    })();

    $scope.device = getDeviceInfo;

    var path = require('path');

    $scope.showTypes = [
      {type:'quicktime', checked: true, FileTypeID:128},
      {type:'image', checked: true, FileTypeID:2},
      {type:'telop', checked: false, FileTypeID:4},
      {type:'html', checked: false, FileTypeID:16},
      {type:'audio', checked: false, FileTypeID:32},
    ];

    $scope.updateTypeCheck = function(type) {
      $scope.showTypes[type].checked = $scope.showTypes[type].checked ? false : true;
      $timeout(function(){
        if (!$scope.doingSearch) {
          $scope.doSearch();
        }
      });
    }


    $scope.uploadContetnList = [];

    $scope.m_arrExType = [
      {type:'url',      name:'URL', resource:'./resources/images/url.png', enable: true},
      {type:'youtube',  name:'YouTube', resource:'./resources/images/youtube.png', enable: true},
      {type:'marcus',   name:'マーカス', resource:'./resources/images/marcas.png', enable: false},
      {type:'twit',     name:'ついっとサイン', resource:'./resources/images/twit.png', enable: false}
    ];


    var arrUploadedMaterials = [];
    $scope.modalUploadFile = function(type) {

      var controllerName = "";
      if (type==='file') {
        controllerName = "LocalModalFileCtrl";
        if ($scope.contentsInfo) {
          if ($scope.contentsInfo.length >= 30) {
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'), modalClass:'modal-warning'});
            return;
          }
        }
      }
      else if (type==='text') {
        controllerName = "LocalModalTextCtrl";
      }
      else if (type==='url') {
        controllerName = "LocalModalHTMLCtrl";
      }
      else if (type==='youtube') {
        controllerName = "LocalModalYoutubeCtrl";
      }
      else if (type==='autoupdated') {
        controllerName = "LocalModalAutoUpdatedCtrl";
      }

      var modalInstance = $modal.open({
        templateUrl: 'app/content/local.html',
        controller: controllerName,
        //windowClass: 'modal-upload-window-ex',
        windowClass: 'modal-fit',
        backdrop: 'static', keyboard: false,
        size: 'lg',
        resolve: {
          LimaModalUploadData: function() {
            return {
              contentCount : $scope.contentsInfo ? $scope.contentsInfo.length : 0
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        //contentsInfoと同じ並びにする
        var arrUploadedMaterials_b = _.cloneDeep(arrUploadedMaterials);
        arrUploadedMaterials = [];
        _.forEach($scope.contentsInfo, function(info){
          var find = _.find(arrUploadedMaterials_b, function(mat){
            if (mat.id === info.id) {
              return true;
            }
          });
          if (find) {
            arrUploadedMaterials.push(find);
          } else {
            console.log("not find");
          }
        });


        arrUploadedMaterials = arrUploadedMaterials.concat(results.materials);
        $scope.uploadContetnList = $scope.uploadContetnList.concat(results.materials);
        $timeout(function(){
          $scope.doSearch(null , function(){
            if ($scope.searchType.type === 3) {
              $scope.createTemporaryPlaylistHTML();
            }
          });
        });
      }, function() {
        //閉じられた
//        console.log("aaa");
      });

    }

    $scope.selectExContent = function() {
      if ($scope.contentsInfo) {
        if ($scope.contentsInfo.length >= 30) {
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT2'), modalClass:'modal-warning'});
          return;
        }
      }

      var modalInstance = $modal.open({
        templateUrl: 'app/content/selectExternalContent.html',
        controller: 'SelectExContentCtrl',
        windowClass: 'modal-upload-window',
//        windowClass: 'modal-fit',
        backdrop: 'static', keyboard: false,
        size: 'lg',
        resolve: {
          LimaModalUploadData: function() {
            return {
              contentCount : $scope.contentsInfo ? $scope.contentsInfo.length : 0
            };
          }
        }
      });
      modalInstance.result.then(function(results) {

        if (results.type === 'autoupdated') {

          $scope.modalContentFile(results);
          return ;
        }

        //contentsInfoと同じ並びにする
        var arrUploadedMaterials_b = _.cloneDeep(arrUploadedMaterials);
        arrUploadedMaterials = [];
        _.forEach($scope.contentsInfo, function(info){
          var find = _.find(arrUploadedMaterials_b, function(mat){
            if (mat.id === info.id) {
              return true;
            }
          });
          if (find) {
            arrUploadedMaterials.push(find);
          } else {
            console.log("not find");
          }
        });


        arrUploadedMaterials = arrUploadedMaterials.concat(results.materials);
        $scope.uploadContetnList = $scope.uploadContetnList.concat(results.materials);
        $timeout(function(){
          $scope.doSearch(null , function(){
            if ($scope.searchType.type === 3) {
              $scope.createTemporaryPlaylistHTML();
            }
          });
        });
      }, function() {
        //閉じられた
//        console.log("aaa");
      });

    }


    $scope.modalContentFile = function(typeInfo) {
      var modalInstance = $modal.open({
        templateUrl: 'app/content/content.html',
        controller: 'ContentModalCtrl',
        windowClass: 'modal-content-window',
        backdrop: 'static', keyboard: false,
        windowClass: 'modal-fit',
        resolve: {
          LimaModalContentData: function() {
            return {
              type:typeInfo.type,
              type2: typeInfo.type2
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        console.log(results);
        var nowLength = 0;
        if ($scope.contentsInfo) {
          nowLength = $scope.contentsInfo.length;
        }
        if (nowLength + results.length >= 30) {
          Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('UPLOAD.MESSAGE_OVER_COUNT3'), modalClass:'modal-warning'});
          results = results.slice(0, (30-nowLength));
        }

        //contentsInfoと同じ並びにする
        var arrUploadedMaterials_b = _.cloneDeep(arrUploadedMaterials);
        arrUploadedMaterials = [];
        _.forEach($scope.contentsInfo, function(info){
          var find = _.find(arrUploadedMaterials_b, function(mat){
            if (mat.id === info.id) {
              return true;
            }
          });
          if (find) {
            arrUploadedMaterials.push(find);
          } else {
            console.log("not find");
          }
        });



        arrUploadedMaterials = arrUploadedMaterials.concat(results);
        $scope.uploadContetnList = $scope.uploadContetnList.concat(results);
        $timeout(function(){
          $scope.doSearch(null , function(){
            if ($scope.searchType.type === 3) {
              $scope.createTemporaryPlaylistHTML();
            }
          });
        });
        //選択
        // if (type === 'telop') {
        //   $scope.SelectedRegion.fixText = results;
        // }
        // else if (type === 'image') {
        //   $scope.SelectedRegion.fixImage = results;
        // }
        // else if (type === 'QuickTime') {
        //   $scope.SelectedRegion.fixVideo = results;
        // }
      }, function() {
        //
      });
    }

  $scope.apply = function () {
    var selected = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
    if (selected.length === 1) {
      //$modalInstance.close(selected[0]);
    }

  };
  $scope.cancel = function () {
    //$modalInstance.dismiss('cancel');
  };



    // $http.get('/api/v1/device/setting').
    // success(function(datas) {
    //   $scope.setting=datas;
    // });

    $scope.searchTypeDef=[
      {name:'Instagram', icon:'../img/glyph-logo_May2016.png', type:0},
      {name:'Twitter', icon:'../img/Twitter_Logo_WhiteOnBlue.png', type:1},
      {name:'Local', icon:'../img/Twitter_Logo_WhiteOnBlue.png', type:2},
      {name:'Playlist', icon:'../img/Twitter_Logo_WhiteOnBlue.png', type:3}
    ];
    $scope.type = 0;
    ////////////////////////////////////////////
    $scope.searchType=$scope.searchTypeDef[$scope.type];
    ////////////////////////////////////////////
    $scope.searchType.type = 2;// local

    // $scope.arrLayout = [
    //   {name:"ステーキ縦" , file:"1" , width:1080},
    //   {name:"トラベル" , file:"2" , width:1920},
    //   {name:"ファッション" , file:"3" , width:1920},
    //   {name:"ステーキ横" , file:"4" , width:1920}
    // ];
    // $scope.selectLayout = $scope.arrLayout[0];

    $scope.initVideo = function(index) {
      $timeout(function(){
      var vv = document.getElementById(""+index);
      vv.pause();
      // vv.addEventListener('loadedmetadata', function(){
      //   vv.currentTime=0;
      // }, false);

      },1000);
    }


    $scope.onResize = (function(previesize) {
      var elem_iframe = document.getElementById('canvasLayout');
      if (!elem_iframe) {
        return;
      }
      var scale = elem_iframe.clientWidth / 1920;

      angular.element('#iframePreview')[0].style.transform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.MozTransform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.WebkitTransform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.OTransform = 'scale(' + scale + ')';
      angular.element('#iframePreview')[0].style.MsTransform = 'scale(' + scale + ')';
      // 'transform':'scale(' + scale + ')',
      // '-moz-transform':'scale(' + scale + ')',
      // '-webkit-transform':'scale(' + scale + ')',
      // '-o-transform':'scale(' + scale + ')',
      // '-ms-transform':'scale(' + scale + ')',
    })

    function onResizeA() {
      $scope.onResize();
      $scope.$apply();
    }

    angular.element($window).on('resize', onResizeA);
    $timeout(function(){  //timeoutの中でapplyが起こる
      $scope.onResize();
    });

    $scope.$on('$destroy', function () {
      //store();
       angular.element($window).off('resize', onResizeA);
    });

    $scope.iframestyle2 = function() {
      var elem_iframe = document.getElementById('canvasLayout');
      //var iFrameWidth = elem_iframe.contentWindow.parent.document.body.offsetWidth;
      //var iFrameHeight = elem_iframe.contentWindow.parent.document.body.offsetHeight;
      var scale = elem_iframe.clientWidth / 1920;


      var val ={
        'width':'1920px',
        'height':'1080px',
        'transform':'scale(' + scale + ')',
        '-moz-transform':'scale(' + scale + ')',
        '-webkit-transform':'scale(' + scale + ')',
        '-o-transform':'scale(' + scale + ')',
        '-ms-transform':'scale(' + scale + ')',
        'transform-origin':'0 0',
        '-moz-transform-origin':'0 0',
        '-webkit-transform-origin':'0 0',
        '-o-transform-origin':'0 0',
        '-ms-transform-origin':'0 0'

      } ;

      var val_w1080 ={
//        'transform':'scale(0.2)  translate(1080px,0px)',
        'transform':'scale(' + scale + ')  translate(1080px,0px)',
        'transform-origin':'0px 0px',
        'width': '1080px',
        'height': '1920px',
    };

      var val_w1920 ={
        'transform':'scale(0.23) translate(200px,0px)',
        'transform-origin':'0px 0px',
        'width': '1920px',
        'height': '1080px',
      };
      return val;
      return $scope.selectLayout.model.width === 1920 ? val_w1920 : val_w1080;
    }
    $scope.iframestyle = function() {

      var val_w1080 ={
        'transform':'scale(0.2)  translate(1080px,0px)',
        'transform-origin':'0px 0px',
        'width': '1080px',
        'height': '1920px',
    };

      var val_w1920 ={
        'transform':'scale(0.23) translate(200px,0px)',
        'transform-origin':'0px 0px',
        'width': '1920px',
        'height': '1080px',
      };

      return $scope.selectLayout.model.width === 1920 ? val_w1920 : val_w1080;
    }

    $scope.isModal = false;
    // if (LimaModalContentData) {
    //   $scope.isModal = true;

    // }

    function GetFolderName(FileTypeID){
      var sDCMFolder=[
        {
          "FileTypeID": 4,
          "FolderName":"Telop"
        },
        {
          "FileTypeID": 16,
          "FolderName":"HTML"
        },
        {
          "FileTypeID": 128,
          "FolderName":"QuickTime"
        },
        {
          "FileTypeID": 2,
          "FolderName":"Still"
        },
        {
          "FileTypeID": 32,
          "FolderName":"Audio"
        }
      ];


      var res = _.find(sDCMFolder, function(folder) {
        if (folder.FileTypeID === FileTypeID) {
          return true;
        }
      });
      if (res) {
        return res.FolderName;
      }
      return "";
    }

    $scope.deleteContentFromUI = function(selectCont) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        deletePlaylistContent(selectCont);
      });
    }


    var deleteContetnList = [];

    function deletePlaylistContent(content){
      var filterCont = _.filter($scope.contentsInfo, function(cont){
        if ( cont.id === content.id ) {
          return true;
        }
      });
      if (filterCont.length > 1) {
        //Copyがあるのでコンテンツ自体は消してはいけない
      } else {
        //実体を消す：プレイリストを保存するときに消すので一旦削除リストに入れる
        deleteContetnList.push(content);
        // $http.delete('/api/v1/content/contents/' + content.id)
        // .finally(function () {
        // })
      }
      //列挙から消す
      $scope.contentsInfo = _.filter($scope.contentsInfo, function(cont){
        return _.isEqual(cont,content) ? false : true;  //hashKeyまで比較してobject的に完全一致したものだけ排除
      });
      //プレビュー更新
      $scope.createTemporaryPlaylistHTML();
      calcPlaylistDuration();

    }

    $scope.deleteContents = function(selected, callback){
      var deferred = $q.defer();
      var promise = deferred.promise;

      if (!selected.length) {
        callback();
      } else {
        var requests = _.forEach(selected, function(item){
          promise = promise.finally(function () {
            return $http
              .delete('/api/v1/content/contents/' + item.id)
              .finally(function () {
                console.log(item.id);
              })
            ;
          });
        });

        promise = promise.finally(function () {
          console.log('end delete');
          callback();
        });
        deferred.resolve();
      }

    }

    $scope.deleteContent = function() {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.PLAYLIST.SELECTED_CONTENT')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        //削除

        var selected = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
        var promise = $scope.deleteContents(selected , function(){
          $timeout(function(){
            $scope.doSearch();
          });
        });

        // {
        //   var deferred = $q.defer();
        //   var promise = deferred.promise;

        //     var selected = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
        //     var requests = _.forEach(selected, function(item){
        //     promise = promise.finally(function () {
        //       return $http
        //         .delete('/api/v1/content/contents/' + item.id)
        //         .finally(function () {
        //           console.log(item.id);
        //         })
        //       ;
        //     });
        //   });

        //   promise = promise.finally(function () {
        //     console.log('end delete');
        //     $timeout(function(){
        //       $scope.doSearch();
        //     });
        //   });

        //   deferred.resolve();

        // }
      });

    };

    function doSerachFromMaterialFromId(datas, callback) {
      {
        var dataMaterials = [];

        var deferred = $q.defer();
        var promise = deferred.promise;

          var requests = _.forEach(datas, function(item){
          promise = promise.finally(function () {
            return $http
              .get('/api/v1/content/contents/' + item.id)
              .success(function(resMat) {
                if (!_.isUndefined(item.checked)) {
                  resMat.checked = item.checked;
                } else if (!_.isUndefined(item.enabled)) {
                  resMat.checked = item.enabled;
                }
                if (!_.isUndefined(item.fixedRegionId)) {
                  resMat.fixedRegionId = item.fixedRegionId;
                }
                dataMaterials.push(resMat);
              })
              .finally(function () {
              });
          });
        });

        promise = promise.finally(function () {
          console.log('end get Materials');

        doSerachFromMaterial(dataMaterials);
        $timeout(function(){$scope.$apply()});
        if (callback) {
            callback();
        }

        });

        deferred.resolve();

      }
    }

    function checkUploadMaterials() {
      arrUploadedMaterials = _.filter(arrUploadedMaterials, function(mt){
        var find = _.find(deleteContetnList, function(cont){
          if (cont.id === mt.id) {
            if (!ContentUtil.isAutoupdated(cont)) {
              return true;
            }
          }
        });
        return find ? false : true;
      });
    }


    $scope.noCacheParam = '?dum=' + new Date().getTime();

    $scope.getAutoUpdatedTypeName = function(item) {
      if (ContentUtil.isAutoupdated(item)) {
        if (item.autoUpdated.length) {
          if (item.autoUpdated[0].type === 'nds') {
            return 'マーカス';
          }
          if (item.autoUpdated[0].type.indexOf('kyodo') !== -1) {
            return 'マーカス';
          }
          if (item.autoUpdated[0].type === 'twitter') {
            return 'ついっとサイン';
          }
        }
      }
      return '';
    }

    $scope.isAutoUpdated = function(item) {
      return ContentUtil.isAutoupdated(item);
    }
    $scope.isYoutube = function(content){
      if (ContentUtil.isHTML(content) ) {
        if (content.urlContents.length) {
          if (content.urlContents[0].additionalInfo) {
            if (content.urlContents[0].additionalInfo.type==='youtube') {
              return true;
            }
          }
        }
      }
    }
    $scope.isHTML = function(item) {
      if (item.id==='20220901171511_25925461') {
        console.log("aaaa");

      }
      return ContentUtil.isHTML(item);
    }

    $scope.isVideo = function(content){
      if (content.files) {
        if (content.files.length) {
          if (content.files[0].videoStreams) {
            return ContentUtil.isVideo(content);
          }
        }
      }
      return false;
    }
    $scope.isImage = function(content){
      if (content.files) {
        if (content.files.length) {
          if (content.files[0].videoStreams) {
            return ContentUtil.isImage(content);
          }
        }
      }
      return false;
    }
    function doSerachFromMaterial(datas){
      $scope.contentsInfo = [];

      var startTime = new Date();
      startTime = startTime.getTime();

      var endTime = new Date();
      endTime = endTime.getTime();

      console.log("######doingSearch 1-1 " + (endTime - startTime));

      var checkedMaterial = false;
      if ($scope.searchType.type===3) {
        checkedMaterial = true;
      }

      var materials = datas;
      _.forEach(materials , function(item){
          if (_.isUndefined(item.checked)) {
            item.checked = checkedMaterial;
          } else {
            item.checked = item.checked ? true : false;
          }
          if (item.urlContents) {
            var bThumb = false;
            if (item.urlContents.length) {
              if (item.urlContents[0].additionalInfo) {
//                if (item.urlContents[0].additionalInfo.type==='youtube') {
                  bThumb = true;
//                }

              }
            }
            // if (!bThumb) {
            //   item.thumbnailUrl = "./resources/images/webicon.png";
            // }
          }
          $scope.contentsInfo.push(item);
      });
      return;
    }

    $scope.canDelete = function() {
      var selected = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
      return selected.length;
    }

    function getContentDetail(datas,callback){
      var async = require('async');
      async.forEachOfSeries(datas , function(dt,index,next){
        $http.get('/api/v1/content/contents/' + dt.id)
        .success(function(resMat) {
          if (resMat.files ) {
            if (resMat.files.length ) {
              dt.files = resMat.files;
            }
          }
          if (resMat.urlContents) {
            if (resMat.urlContents.length) {
              dt.urlContents = resMat.urlContents;
            }
          }

          next(null);
        });
      },function(err){
        callback()
      });
    }

    function displayBytes(bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
      if (bytes === 0) return '0 bytes';
      if (typeof precision === 'undefined') precision = 1;
      var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      var number = Math.floor(Math.log(bytes) / Math.log(1024));
      //while (number > 0 && precision >= 3) {
      //  --number;
      //  precision -= 3;
      //}
      if (number === 0) precision = 0;
      return $filter('number')(bytes / Math.pow(1024, Math.floor(number)), precision) +  ' ' + units[number];
    }

    $scope.getFileSize = function(item){
      if (item.files && item.files.length ) {
        if (item.files[0].videoStreams) {
          if (item.files[0].videoStreams.length) {
            return displayBytes(item.files[0].length);
          }
        }
      }
      return '';
    }

    $scope.changeShowPlaylistType = function(){
      limaShowWaitDialog.showWait($scope,'',"表示中...");
      $scope.doSearch(null , function() {
        limaShowWaitDialog.hideWait();

      })
    }

    $scope.doingSearch = false;
    $scope.doSearch = function(playlistId, callback) {
      $scope.contentsInfo = [];
      {

        console.log("######doingSearch 1");
        $scope.doingSearch = true;

        //最新のでソート
        var params= {
          order: {'updatedTime':'desc'}
        };

        var enableAutoupdated = _.find($scope.showTypes, function(st) {
          if (st.type === 'autoupdated') {
            return st.checked;
          }
          return false;
        });

        if (enableAutoupdated) {
          params.type = ContentUtil.CONTENT_AUTOUPDATED;  //autoupdated
          //params.type2 = enableAutoupdated.type2
        }

        $http.get('/api/v1/content/contents' , {params:params}).
        success(function(datas) {
          if (enableAutoupdated) {
            datas = _.filter(datas, function(dt) {
              return _.find(enableAutoupdated.type2, function(tt) {
                return dt.imageUrl.indexOf(tt) !== -1;
              })
            });
          }
          //showPlaylistType  0:all , 1:used  2:notUsed
          if ($scope.ui.showPlaylistType ) {
            getPlaylistContent(function(arrContents){
              //not used
              if ($scope.ui.showPlaylistType === 2) {
                datas = _.filter(datas, function(dt) {
                  var findCon = _.find(arrContents, function(con) {
                    return con.contentId === dt.id;
                  })
                  return !findCon;
                })
              } else {  //used
                datas = _.filter(datas, function(dt) {
                  var findCon = _.find(arrContents, function(con) {
                    return con.contentId === dt.id;
                  })
                  //playlist
                  if (findCon) {
                    var arrPlaylistsNames = [];
                    _.filter($scope.playlists, function(pl) {
                      var findPl = _.find(pl.contents, function(pl_con) {
                        return pl_con.contentId === findCon.contentId;
                      });
                      if (findPl) {
                        arrPlaylistsNames.push(pl.playlistName);
                      }
                    });
                    dt.playlistNames = arrPlaylistsNames.join(" / ");
                  }
                  return findCon;
                })
              }
                getContentDetail(datas,function() {
                doSerachFromMaterial(datas);
                $timeout(function(){$scope.$apply()});
                if (callback) {
                  callback();
                }
              })

            })
          } else {
            getPlaylistContent(function(arrContents){
              _.forEach(datas, function(dt) {
                var findCon = _.find(arrContents, function(con) {
                  return con.contentId === dt.id;
                })
                //playlist
                if (findCon) {
                  var arrPlaylistsNames = [];
                  _.filter($scope.playlists, function(pl) {
                    var findPl = _.find(pl.contents, function(pl_con) {
                      return pl_con.contentId === findCon.contentId;
                    });
                    if (findPl) {
                      arrPlaylistsNames.push(pl.playlistName);
                    }
                  });
                  dt.playlistNames = arrPlaylistsNames.join(" / ");
                }
                return findCon;
              })
              doSerachFromMaterial(datas);
              getContentDetail(datas,function() {
                $timeout(function(){$scope.$apply()});
                if (callback) {
                  callback();
                }
              })
            })
          }
        });

      }
     }

    $scope.changeShowPlaylistType();

    function calcPlaylistDuration() {
      if (_.isUndefined($scope.selectLayout)) {
        $timeout(function(){calcPlaylistDuration();} , 500)
        return;
      }
      if (_.isUndefined($scope.selectLayout.model)) {
        $timeout(function(){calcPlaylistDuration();} , 500)
        return;
      }
      var findVideo = _.find($scope.selectLayout.model.regions, function(rg) {
        if (rg.name.toLowerCase().indexOf("video") !== -1) {
          return true;
        }
      });
      var findStill = _.find($scope.selectLayout.model.regions, function(rg) {
        if (rg.name.toLowerCase().indexOf("still") !== -1) {
          return true;
        }
      });
      var findAll = _.find($scope.selectLayout.model.regions, function(rg) {
        if (rg.name.toLowerCase().indexOf("all") !== -1) {
          return true;
        }
      });

      $scope.playlistLengthAllText = '';
      $scope.playlistDurationAllText = '';
      var duration = 0;
      var file_length = 0;
      _.forEach($scope.contentsInfo , function(item){
        if (item.checked) {
          if ( ContentUtil.isVideo(item) && (findVideo||findAll) ) {
            if (item.files.length) {
                var ContentSec = ContentUtil.getDurationSec(item.files[0].duration);
                var repeatCount = Math.ceil( $scope.playlistSetting.changeContentDuration / ContentSec ); //切り上げ
                if ($scope.playlistSetting.repeatVideoType === 'once') {
                  repeatCount = 1;
                }
                duration += repeatCount * ContentSec;
                file_length += item.files[0].length;
            }
          } else if ( ContentUtil.isImage(item) && (findStill||findAll)) {
              duration += Number($scope.playlistSetting.changeContentDuration);
              file_length += item.files.length ? item.files[0].length : 0;
          } else if ( ContentUtil.isHTML(item) && findAll) {
            if (item.urlContents[0].additionalInfo.info) {
              if (item.urlContents[0].additionalInfo.info.duration) {
                var ContentSec = ContentUtil.getDurationSec(item.urlContents[0].additionalInfo.info.duration);
                var repeatCount = Math.ceil( $scope.playlistSetting.changeContentDuration / ContentSec ); //切り上げ
                if ($scope.playlistSetting.repeatVideoType === 'once') {
                  repeatCount = 1;
                }
                duration += repeatCount * ContentSec;
              } else {
                //
                if ($scope.contentsInfo.length !== 1) {
                  duration += Number($scope.playlistSetting.changeContentDuration);
                }
              }
            }
          }
        }
      });
      $timeout(function(){
        $scope.playlistDurationAllText = ContentUtil.getDurationText(duration,1);
        if (!file_length) {
          file_length = 1;  //0の時は強制的に１にしておく
        }
        $scope.playlistLengthAllText = "" + Math.ceil(file_length / 1024 / 1024 );  //切り上げ：安全策
      });

    }


  //SNS
  $scope.RegistSNS = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/content/registSNS.html',
      controller: 'RegistSNSCtrl',
      windowClass: 'modal-registSNS-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaModalRegistSNSData: function() {
          return {
            //selectTweet : $scope.selectTweet
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
    }, function() {
      //
    });

  }


    $scope.doChecked = function(tw) {
      //アップロードしたものもcheck状態を適用
      _.find(arrUploadedMaterials, function(mt){
        if (mt.id === tw.id) {
          mt.checked = tw.checked;
          return true;
        }
      });

      $scope.createTemporaryPlaylistHTML();
      //長さ計算
      calcPlaylistDuration();
    }

    function checkTelop(tw) {
      var defer = $q.defer();
      if (tw.type ===4 ) {

        var filename = $location.protocol() + '://'+ $location.host() +':'+  $location.port();
        filename += tw.url;
        var httpObj = new XMLHttpRequest();
        httpObj.open('GET',filename +"?"+(new Date()).getTime(),true);
        // ?以降はキャッシュされたファイルではなく、毎回読み込むためのもの
        httpObj.send(null);
        httpObj.onreadystatechange = function(){
            if ( (httpObj.readyState == 4) && (httpObj.status == 200) ){
  //            var value = _.escape(httpObj.responseText.replace(/\r\n/g, '<br/>').replace(/[\r\n]/g, '<br/>'));
              var value = httpObj.responseText.replace(/\r\n/g, '<br/>').replace(/[\r\n]/g, '<br/>');
              value =httpObj.responseText;
              _.find($scope.contentsInfo,function(info){
                if (info.id === tw.id) {
                  info.scrollText = value;
                  //info.scrollText = value;
                  return true;
                }
              });
              defer.resolve();
            }
        }
      } else {
        defer.resolve();
      }
      return defer.promise;
    }


    $scope.selectTweet = {};
    $scope.selectPreview = function(tw) {
      $scope.bPreviewPaused = true;

      $scope.selectTweet = tw;

      var promise = checkTelop($scope.selectTweet);
      promise.then(
        function() { //成功
          $http.get('/api/v1/content/contents/' + tw.id)
          .success(function(resMat) {
            if (resMat.files ) {
              if (resMat.files.length ) {
                tw.files = resMat.files;
              }
            }

            var modalInstance = $modal.open({
//            templateUrl: 'app/instantsignage/select/modalPreview.html',
            templateUrl: 'app/content/modalPreview.html',
            controller: 'ModalMaterialPreviewCtrl',
            windowClass: 'modal-preview-window',
            backdrop: 'static', keyboard: false,
            size: 'md',
            resolve: {
              LimaModalPreviewData: function() {
                return {
                  selectTweet : $scope.selectTweet
                };
              }
            }
            });
            modalInstance.result.then(function(results) {
            }, function() {
              //
              $scope.bPreviewPaused = false;

            });
          });
        },
        function() { //失敗
          //なにもしない
          $scope.bPreviewPaused = false;
        }
      );
    }

    $scope.updateThumbnail = function(tw) {
      socket.emit('lima:call-request:v1:contents:thumbnail:update',{contentId:tw.id,time:2000,companyId:tw.companyId,wait:true});
      $timeout(function(){
        tw.thumbnailUrl_ = tw.thumbnailUrl;
        tw.thumbnailUrl = './resources/images/loading_200.gif';
      });
      console.log("emit thumbnail:update");
    }

    $scope.copyContent = function(tw) {
      var indexContent = -1;
      _.forEach($scope.contentsInfo, function(cont, index){
        if (_.isEqual(cont, tw) === true) {
            indexContent = index;
        }
      });
      $scope.contentsInfo.splice( indexContent+1, 0, angular.copy(tw) ) ; //$$hashkey削除するためのコピー方法

      calcPlaylistDuration();
      $timeout(function(){$scope.$apply()});

    }

    $scope.selectProperty = function(tw) {
      $scope.selectTweet = tw;
      $scope.bPreviewPaused = true;

      /**/
      $http.get('/api/v1/content/contents/' + tw.id)
      .success(function(resMat) {
        if (resMat.files ) {
          if (resMat.files.length ) {
            tw.files = resMat.files;
          }
        }
        //tw.properties = resMat;
        var modalInstance = $modal.open({
          templateUrl: 'app/content/modalProperty.html',
          controller: 'ModalMaterialPropertyCtrl',
          windowClass: 'modal-property-window',
          backdrop: 'static', keyboard: false,
          size: 'lg',
          resolve: {
            LimaModalPropertyData: function() {
              return {
                selectTweet : $scope.selectTweet
              };
            }
          }
        });
        modalInstance.result.then(function(results) {
          $scope.bPreviewPaused = false;
          console.log(results);
          var arrProp = [];
          _.forEach(results.properties, function(prop){
            arrProp.push(prop.Value);
          });
          $http.post('/api/v1/content/extra/' + tw.id + '/property',arrProp ).
          success(function(data) {
            console.log("success set property");
            $scope.createTemporaryPlaylistHTML();
          });

        }, function(results) {
          //
          $scope.bPreviewPaused = false;
          if (results && results.event) {
            if (results.event === 'delete') {
              //削除
              deletePlaylistContent(tw);
            }
          }
        });

      })
      /**/
    }

    function getFixedRegion() {
      if ($scope.selectLayout.model) {
        return _.find($scope.selectLayout.model.regions, function(rg){
          return rg.fixed;
        });
      }
      return null;
    }
    //固定コンテンツとする
    $scope.setFixedRegion = function(content) {
      var findRg = getFixedRegion();

      //これまで設定されてたものを設定削除
      _.find($scope.contentsInfo,function(cont) {
        if (cont.fixedRegionId === findRg.regionId) {
          cont.fixedRegionId = null;
          return true;
        }
      });

      //設定
      content.fixedRegionId = findRg ? findRg.regionId : null;

      //プレビュー更新
      $scope.createTemporaryPlaylistHTML();
    }
    $scope.isIncludeFixedLayout =function(){
      var findRg = getFixedRegion();
      return findRg;
    }


  //////////////////////////
  $scope.selectLayout = {};
  $scope.selectLayout.model = undefined;

  $scope.channels = [];
  $http.get('/api/v1/channels').
  success(function(datas) {
    $scope.channels = datas.channels;
  });

  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = [];
  function getChannels() {
    $scope.channelsInfo.channels = [];
    $http.get('/api/v1/channels').
    success(function(datas) {
      $scope.channelsInfo.channels = datas.channels;
      // _.forEach($scope.channelsInfo.channels, function(ch) {
      //   $http.get('/api/v1/lima/layout/dcm/' + ch.id + '/layout').
      //   success(function(chInfo) {
      //     ch.chInfo = chInfo;
      //   });
      // });
    });

  }
  getChannels();

  $scope.applyOption={};
  $scope.applyOption.options=[];
  $scope.applyOption.options.push({name:$translate.instant('PLAYLIST.TABLE.DEVICE') , type:'device'});
  $scope.applyOption.options.push({name:$translate.instant('DEVICE.GROUP') , type:'group'});
  $scope.applyOption.select = $scope.applyOption.options[0];

  function getDevice() {
    $scope.devices = [];
    $http.get('/api/v1/device/device').
    success(function(datas) {
      $scope.devices.items = _.filter(datas, function(dt){
        return dt.allowed;
      });
      getDeviceGroups(function() {
        if ($scope.devices.items.length) {
          _.find($scope.deviceGroups.groups, function(gr){
            if (gr.typeDefault) {
              var groupInfo = angular.copy(gr);
              groupInfo.enableGroup = false;
              var all = {
                name : $translate.instant('PLAYLIST.DEVICE_ALL'),
                groupIds: [groupInfo],
                deviceId: -1
              };
              $scope.devices.items.unshift(all);
              gr.name = $translate.instant('PLAYLIST.DEVICE_ALL');
              return true;
            }
          });

        }
      });
    });
  }
  getDevice();

  $scope.deviceGroups = [];
  function getDeviceGroups(callback) {
    $scope.deviceGroups = [];
    $http.get('/api/v1/device/groups').
    success(function(datas) {
      $scope.deviceGroups.groups = datas;
      $scope.deviceGroups.showgroups = _.filter(datas, function(dt){
        if (!dt.typeDefault) {
          return true;
        }
      });
      if ($scope.deviceGroups.showgroups.length){
        $scope.deviceGroups.select = $scope.deviceGroups.showgroups[0];
      }
      callback();
    });

  }

  function getLayout(optionService) {
    $scope.layout = [];

    $http.get('/api/v1/layouts').
    success(function(datas) {
  //    $scope.layout = datas.layouts;

      datas.layouts = _.filter(datas.layouts, function(ly) {
        var packageType = ly.packageType ? ly.packageType : 0;
        return $scope.packageType === packageType;
      });

      datas.layouts = _.sortBy(datas.layouts, function(ly) {
        return new Date(ly.updatedTime);
      }).reverse();

      $scope.layout = _.filter(datas.layouts,function(ly){
        var find = _.find( optionService, function(info){
          //return ly.optionId == info.optionId;
          if (ly.optionId == info.optionId) {
            return true;
          }
          var companyId_option = ('00000000' + info.companyId).slice(-8);  //8桁で0埋め
          var companyId_layout = ('00000000' +   ly.companyId).slice(-8);  //8桁で0埋め
          if (companyId_option === companyId_layout) {
            return true;
          }
        });
        return find ? true : false;
      });

      var find = _.find($scope.layout, function(ly){
        if ($scope.playlistInfo) {
          if (ly.layoutId === $scope.playlistInfo.layoutId) {return true;}
        }
      })
      if (find) {
        $scope.selectLayout.model = find;
      } else {
        $scope.selectLayout.model = $scope.layout[0];
      }
      $scope.selectLayoutIndex = $scope.selectLayout.model.layoutId;
      $scope.selectChange();
    });
  }

  $scope.isImportUrlEnable = false;
  $scope.isDeviceGroupEnable = false;
  $scope.isMovieEnable = false;
  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.isDeviceGroupEnable = true;
    }
    find = _.find(result, function(op){
      return op.name === 'Url' ? true : false;
    });
    if (find) {
      $scope.isImportUrlEnable = true;
    }

    find = _.find(result, function(op){
      return op.name === 'Movie' ? true : false;
    });
    if (find) {
      $scope.isMovieEnable = true;
    }

    find = _.find(result, function(op){
      return op.name === 'Still_and_Video' ? true : false;
    });
    if (find) {
      $scope.isMovieEnable = true;
    }


    getLayout(result);

  });

  $scope.packageType = 0;
  limaOptionService.getpackageType(function(result){
    if (result) {
      $scope.packageType = result.packageType;
    }
  });


  $scope.regions = [];
  $http.get('/api/v1/regions').
  success(function(datas) {
    $scope.regions = datas.regions;
    $scope.selectChange();
  });

  function getRegion(layoutID) {
    $scope.selectLayout.model.regions = _.filter($scope.regions, function(region){
      if (region.layoutId === layoutID) {
        return true;
      }
    });
  }
  var color = [
    "#f39700",
    "#e60012",
    "#9caeb7",
    "#00a7db",
    "#009944",
    "#d7c447",
    "#9b7cb6",
    "#00ada9",
    "#bb641d",
    "#e85298",
    "#0079c2",
    "#6cbb5a",
    "#b6007a",
    "#e5171f",
    "#522886",
    "#0078ba",
    "#019a66",
    "#e44d93",
    "#814721",
    "#a9cc51",
    "#ee7b1a",
    "#00a0de"
    ];
    //var canvas = new fabric.Canvas('canvas');

  $scope.SelectedRegion = undefined;
  $scope.selectRegion = function(region){
    $scope.SelectedRegion = region;
    $scope.SelectedRegion.rect.fill = "#0F0";
    _.find($scope.selectLayout.model.regions, function(rg,index){
      if (rg.regionId === region.regionId) {
        canvas.bringToFront(canvas.item(index));
        //canvas.setActiveObject(canvas.item(index));
        return true;
      }
    });

  }
  $scope.isActiveList = function(regionId){
    if ($scope.SelectedRegion) {
      return $scope.SelectedRegion.regionId === regionId;
    }
    return false;
  }

  $scope.RegionRect = [];
  function canvasDraw() {

    var c=document.getElementById("canvasLayout");
    if (!c) {
      return;
    }
    var ctx=c.getContext("2d");

    var arrRg = [];
    var w = $scope.selectLayout.model.width;
    var h = $scope.selectLayout.model.height;
    var canvas_w = 1920;
    var canvas_h = 1920;
    var diff_w = 0;
    var diff_h = 0;
    if (w > h) {
      //w : h = 1920 : yy
      canvas_h = canvas_w * h / w;
      diff_h = (1920 - canvas_h) / 2;
    } else {
      canvas_w = canvas_h * w / h;
      diff_w = (1920 - canvas_w) / 2;
    }
    ctx.clearRect(0,0,1920,1920);

    var rgCnt = $scope.selectLayout.model.regions.length;

    _.forEach($scope.selectLayout.model.regions, function(region,index){
      var rect = {
        left: region.leftIndex,
        top: region.topIndex,
        width: region.width,
        height: region.height
      };

      var sName = "";
      if (region.name.toLowerCase().indexOf('all') !== -1) {
        if ($scope.packageType !== 0) {
          sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO') + '/' + $translate.instant('CONTENT.PROPERTY.URL');
        } else if ($scope.isMovieEnable){
          sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
        }
      }
      else if (region.name.toLowerCase().indexOf('still_video') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.STILL') + '/' + $translate.instant('CONTENT.PROPERTY.VIDEO');
      }
      else if (region.name.toLowerCase().indexOf('still4k') !== -1) {
        sName = '4K' + $translate.instant('CONTENT.PROPERTY.STILL');
      }
      else if (region.name.toLowerCase().indexOf('still') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.STILL');
      }
      else if (region.name.toLowerCase().indexOf('video') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.VIDEO');
      }
      else if (region.name.toLowerCase().indexOf('input') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.EXINPUT');
      }
      else if (region.name.toLowerCase().indexOf('html') !== -1) {
        sName = $translate.instant('CONTENT.PROPERTY.URL');
      }
      if (region.fixed) {
        sName = '固定';// + sName;　
      }

      ctx.fillStyle = '#e9e9e9';
      ctx.fillRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

      ctx.fillStyle = "#F9F9F9"
      ctx.lineWidth = 8;
      ctx.strokeRect(diff_w + rect.left, diff_h + rect.top, rect.width, rect.height);

      ctx.font='100px "Helvetica Neue", Helvetica, Arial, sans-serif';
      var textWidth = ctx.measureText( sName ).width ;
      var textHeight =  ctx.measureText( sName ).actualBoundingBoxAscent + ctx.measureText( sName ).actualBoundingBoxDescent;;
      ctx.fillStyle = "#787878"
      if (rgCnt === 1) {
        if (rect.width === 1920) {
          ctx.fillText( sName,rect.left + (rect.width - textWidth) / 2,  diff_h + rect.top + (rect.height - textHeight ) / 2  ) ;
        }

      } else {
        if (rect.width === 1920) {
          ctx.fillText( sName,rect.left + 100,  diff_h + rect.top + (rect.height - textHeight )  ) ;
        } else {
          ctx.fillText( sName,rect.left + (rect.width - textWidth) / 2 ,  diff_h+ rect.top + (rect.height + textHeight ) / 2  ) ;
        }
      }


    });



  }

  function fabricDraw() {
    canvas.clear();
    canvas.isDrawingMode = false;
    $scope.RegionRect = [];
    var scale =  1;
    _.forEach($scope.selectLayout.model.regions, function(region,index){
      var rect = new fabric.Rect({
        left: region.LeftIndex / scale,
        top: region.TopIndex / scale,
        fill: color[index],
        width: region.Width / scale,
        height: region.Height / scale
      });
      rect.on('selected', function() {
        console.log('selected a rectangle');
        $scope.SelectedRegion = region;
        $timeout(function(){$scope.$apply()});
      });
//      $scope.RegionRect.push(rect);
      region.rect = rect;
      canvas.add(rect);

      canvas.item(index).setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr 	: false
          });

    });


    canvas.setViewportTransform([0.25, 0, 0, 0.25, 0, 0]);
  }
  $scope.applyContent=[];

  $scope.bNotIncludeContentLayout = false;

  $scope.selectChange = function() {
    if ($scope.isModal) {
      return;
    }
    if (_.isUndefined($scope.selectLayout)) {
      return;
    }
    if (_.isUndefined($scope.selectLayout.model)) {
      return;
    }
    $scope.applyContent=[];
    $scope.selectArea = {};
    //$scope.selectLayout = layout;
    $scope.selectLayoutIndex = $scope.selectLayout.model.layoutId;
    getRegion($scope.selectLayout.model.layoutId);

    $scope.titleAlertMessage = '';
    //外部入力のみのレイアウトか
    $scope.bNotIncludeContentLayout = false;
    if ($scope.selectLayout.model.regions.length == 1) {
      if ($scope.selectLayout.model.regions[0].name.toLowerCase().indexOf("input") !== -1) {
        $scope.bNotIncludeContentLayout = true;
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_CONTENT');
        //return true;
      }
    }
    if ($scope.selectLayout.model.rotate % 180  !== 0) {
      //縦
      $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_CORRECT_VIDEO');
    }

    $timeout(function(){
      canvasDraw();
      $scope.createTemporaryPlaylistHTML();
      //長さ計算
      calcPlaylistDuration();
    });
//    $timeout(function(){fabricDraw();});

  }

  $scope.applyToRegion = function() {
    var selectedTw = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
    if (selectedTw.length === 1) {
      var val = {
        region:$scope.SelectedRegion,
        content:selectedTw
      }
      $scope.applyContent.push(val);

      _.forEach($scope.contentsInfo, function(tw){tw.checked=false;});

    }
  }

  var formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  $scope.createPlaylist = function(option , callback) {
    var name = option.name;


    var postdata = {
      playlistName:name ? name : $scope.selectLayout.model.name + "_" + formatDate(new Date() , 'YYYYMMDD_hhmmss'),
      playlistLength: 30000,  //ミリ秒
      layoutId:$scope.selectLayoutIndex,
      playlistSetting: $scope.playlistSetting,
      thumbUrl : $scope.ifarame_url
    };
    postdata.contentInfo = [];
    var index = 0;
    _.forEach($scope.contentsInfo, function(tw){
      postdata.contentInfo.push({contentId:tw.id , index: index, enabled:tw.checked, fixedRegionId:tw.fixedRegionId});
      index++;
    });

    var httpParam = {
    url: '/api/v1/playlists',
    method: "",
    data: postdata
    };

    if (option.playlistId) {
      httpParam.url += '/' + option.playlistId;
      httpParam.method = 'PUT';
    } else {
      httpParam.method = 'POST';
    }
    $http(httpParam)
    .success(function(res) {
      //固定コンテンツ登録があればRegionに登録する
      var findRg = getFixedRegion();
      var playIndex = -1;
      var findFixedCnt = _.find($scope.contentsInfo, function(cnt,index){
        playIndex = index
        return cnt.fixedRegionId;
      });
      if (findRg && findFixedCnt) {
        $http.put('/api/v1/regions/' + findRg.regionId + '/' + res.id + '/' + findFixedCnt.id + '/' + playIndex , {})
        .success(function(res2) {
          callback(res.id);
        })
      } else {
        callback(res.id);
      }

    });
  }

  $scope.modalClose = function(){};

  function getFileformatName(content) {
    if (content.files) {
      if (content.files.length) {
        return content.files[0].formatName;
      }
    }
  }
  function isImage(content) {
    if (getFileformatName(content).indexOf("image") !== -1) {
        return true;
    }
    if (getFileformatName(content).indexOf("png") !== -1) {
      return true;
    }
    return false;
  }
  function isVideo(content) {
    if (item.files) {
      if (item.files.length) {
        if (item.files[0].videoStreams) {
          if (getFileformatName(content).indexOf("mov") !== -1) {
            return true;
          }
        }
      }
    }

    return false;
  }
  function isText(content) {
    if (getFileformatName(content).indexOf("tty") !== -1) {
        return true;
    }
    return false;
  }




  $scope.updatePlaylist = function(option , callback) {

    var option1 = {
      playlistId:option.id,
      name:option.name,
      playlistAndSchedule:option.playlistAndSchedule
    };
    $scope.createPlaylist(option1,callback);
  }

  $scope.createPlaylistHTML = function(option, callback) {
    var playlistId = option.id;
    var playlistAndSchedule = option.playlistAndSchedule ? option.playlistAndSchedule: null;
    var selectedTw = _.filter($scope.contentsInfo, function(tw){return tw.checked;});

    var params = [];
    _.forEach(selectedTw, function(item){
      var data = {
        contentId: item.id
      };
      params.push(data);
    })
    var channelName = new Date();

    if (_.isUndefined( $scope.selectLayoutIndex)) {
      console.log( "$scope.selectLayoutIndex undefined" );
      return;
    }

    var postdata = {
      channel: formatDate(new Date() , 'YYYYMMDD_hhmmss'),
      layoutId: $scope.selectLayoutIndex,
      Materials: params,
      temporary: false,
      playlist: playlistId,
      name: option.name
    }
    $http.post('/api/v1/playlists/html', postdata)
    .success(function(res) {
      //チェック外す
      //_.forEach($scope.contentsInfo, function(tw){tw.checked=false;});
      //$scope.ifarame_url = $location.protocol() + '://'+ $location.host() +':'+  $location.port() + res.html;
      console.log(res.html);

      if (!playlistAndSchedule) {
        // Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        // html:'プレイリストを作成しました。',//$translate.instant('CONTENT.ATTACHMENT.CHECK_SHOW_DETAIL'),
        // modalClass:'modal-success'});

      }
      if (callback) {
        callback(playlistId);
      }

      // src: $location.protocol() + '://'+ $location.host() +':'+  $location.port() + res.html,
          // width:$scope.selectLayout.model.width,
          // height:$scope.selectLayout.model.Height,
          // rotate:$scope.selectLayout.model.rotate,
          // channels:$scope.channels
      }, function(res) {
        //ゴミ消す
        // $http.delete('/api/v1/lima/layout/dcm/' + postdata.channel )
        // .success(function(res) {
        //   console.log("delete tempLayout");
        // });
      });

  }

  function getChannelIdFromDeviceGroupId(deviceGroupId,deviceId){
    var itemFind = _.find($scope.channelsInfo.channels, function(ch){
      if (ch.groupId === deviceGroupId) {
        return true;
      }
      // if (ch.deviceId === deviceId) {
      //   return true;
      // }
      return false;
    });
    if (itemFind) {
      return itemFind.channelId;
    }
    return null;
  }

  $scope.addToNoSchedule = function(playlistId , callback) {
    //
    var selectedLists = _.filter($scope.playlists, function(p){return p.checked;});

    if (playlistId) {
      selectedLists.push({id:playlistId});
    }
    var deviceId,deviceGroupsId;
    if ($scope.applyOption.select.type==='device') {
      var find = _.find($scope.devices.select.groupIds, function(gr){
        return !gr.enableGroup ? true : false;
      });
      if (find) {
        deviceGroupsId = find.groupId;
      }

    } else {
      deviceGroupsId = $scope.deviceGroups.select.groupId;
    }
    var channelId = getChannelIdFromDeviceGroupId(deviceGroupsId, deviceId);
    if (channelId === null) {
      console.log("getChannelIdFromDeviceGroupId == > null" + " groupId = " + $scope.deviceGroups.select.groupId);
    }

    var postdata = {};
    _.forEach(selectedLists, function(list , index) {
      postdata = {
        //ScheduleID : 0,
        playlistId : list.id,
        channelId : channelId,
        PlayIndex : index
      };

      $http.post('/api/v1/schedules/repeat' , postdata)
      .success(function(res2) {
        console.log(res2.scheduleID);
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('PLAYLIST.MESSAGE_REGIST_OK'),
        modalClass:'modal-success'});

        limaSocketService.emitChannel({channelId:channelId,groupId:deviceGroupsId}, function(err){

          if (callback) {
            callback();
          }

        });

      });
    });

  };

  $scope.m_bSaving = false;
  $scope.savePlaylist = function(option,callback) {
    $scope.m_bSaving = true;
    //Content delete
    var promise = $scope.deleteContents(deleteContetnList, function(){
      if ($scope.playlistId) {
        //update
        var option1 = {
          id:$scope.playlistId,
          name:$scope.playlistName,
          playlistAndSchedule:option.playlistAndSchedule
        };
        $scope.updatePlaylist(option1, callback);
      } else {
        //new
        var modalInstance = $modal.open({
          templateUrl: 'app/playlist/newName.html',
          controller: 'NewNameCtrl',
          windowClass: 'modal-rename-window',
          backdrop: 'static', keyboard: false,
          size: 'md',
          resolve: {
            LimaNewNameData: function() {
              return {
                name : $scope.selectLayout.model.name + "_" + formatDate(new Date() , 'YYYYMMDD_hhmmss')
              };
            }
          }
        });
        modalInstance.result.then(function(results) {
          console.log(results);
          var option1 = {
            name:results.name,
            playlistAndSchedule:option.playlistAndSchedule
          };
          $scope.createPlaylist(option1, function(id){
            if (callback) {
              callback(id);
            }
          });
        }, function() {
          //
        });
      }
    });

  }

  $scope.DoPlaylistSetting = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/setting/setting.html',
      controller: 'PlaylistSettingCtrl',
      windowClass: 'modal-upload-window',
//      windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaPlaylistSettingData: function() {
          return {
            playlist: $scope.playlistId,
            playlistSetting: _.cloneDeep($scope.playlistSetting)
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      $scope.playlistSetting = results;
      //長さ計算
      calcPlaylistDuration();
      //プレビュー更新
      $scope.createTemporaryPlaylistHTML();

    }, function() {
      //閉じられた
      //  console.log("aaa");
    });
  }

  $scope.savePlaylistAndUpdate = function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('PLAYLIST.MESSAGE_SAVE_AND_APPLY_PLAYLIST'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;

      var option = {
        playlistAndSchedule:true
      };
      $scope.savePlaylist(option, function(id) {
        $scope.addToNoSchedule(id, function(){
          $scope.modalClose();
        });
      });
    });
  }

  function checkPlaylist() {
    if (_.isUndefined($scope.selectLayout)) {
      return;
    }
    if (_.isUndefined($scope.selectLayout.model)) {
      return;
    }
    var findStill = _.find($scope.selectLayout.model.regions, function(rg){
      if (rg.name.toLowerCase().indexOf('still') !== -1 ){
        return true;
      }
    });
    var findVideo = _.find($scope.selectLayout.model.regions, function(rg){
      if (rg.name.toLowerCase().indexOf('video') !== -1 ){
        return true;
      }
    });
    var findHTML = _.find($scope.selectLayout.model.regions, function(rg){
      if (rg.name.toLowerCase().indexOf('html') !== -1 ){
        return true;
      }
    });
    if (findStill && findVideo) {
      var contHTML = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isHTML(cont);
      });
      if (contHTML) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_URL');
        return false;
      }
    }
    else if (!findStill && !findVideo) {
      if (findHTML) {
        //HTML only layout
        var contVideo = _.find($scope.contentsInfo , function(cont){
          return ContentUtil.isVideo(cont);
        });
        var contImage = _.find($scope.contentsInfo , function(cont){
          return ContentUtil.isImage(cont);
        });
        if (contVideo && contImage) {
          $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_STILL_AND_VIDEO');
          return false;
        }
        else if (contVideo) {
          $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_VIDEO');
          return false;
        }
        else if (contImage) {
          $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_STILL');
          return false;
        }
      }
    }
    else if (!findVideo) {
      //still only
      var contVideo = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isVideo(cont);
      });
      if (contVideo) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_VIDEO');
        return false;
      }
      var contHTML = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isHTML(cont);
      });
      if (contHTML) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_URL');
        return false;
      }
    }
    else if (!findStill) {
      //video only
      var contImage = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isImage(cont);
      });
      if (contImage) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_STILL');
        return false;
      }
      var contHTML = _.find($scope.contentsInfo , function(cont){
        return ContentUtil.isHTML(cont);
      });
      if (contHTML) {
        $scope.titleAlertMessage = $translate.instant('PLAYLIST.ALERT_LAYOUT_NOT_TO_USE_URL');
        return false;
      }
    }

    return true;

  }
  $scope.bPreviewPaused = false;  //プレビューを停止させてるかどうか

  $scope.bPreviewLoading = false;
  $scope.ifarame_url = "";
  $scope.createTemporaryPlaylistHTML = function() {
    checkPlaylist();
    var selectedTw = _.filter($scope.contentsInfo, function(tw){return tw.checked;});
    if (_.isUndefined( $scope.selectLayoutIndex)) {
      console.log( "$scope.selectLayoutIndex undefined" );
      return;
    }
    // if (!selectedTw.length && !$scope.bNotIncludeContentLayout) {
    //   return;
    // }
    $scope.bPreviewLoading = true;
    var params = [];
    _.forEach(selectedTw, function(item,index){
      params.push({contentId:item.id , index: index, enabled:item.checked , fixedRegionId:item.fixedRegionId});
    })

    var postdata = {
      channel: formatDate(new Date() , 'YYYYMMDD_hhmmss'),
      layoutId: $scope.selectLayoutIndex,
      Materials: params,
      playlistSetting: $scope.playlistSetting,
      temporary: true,
      playlist: $scope.socketId ? "temp_" + $scope.socketId : "temp_" + formatDate(new Date() , 'YYYYMMDD_hhmmss')
    }
    $http.post('/api/v1/playlists/html', postdata)
    .success(function(res) {
      //チェック外す
      //_.forEach($scope.contentsInfo, function(tw){tw.checked=false;});
      $scope.ifarame_url = res.server + res.html;
        //iFrameResize({ log: true,enablePublicMethods     : true,  heightCalculationMethod : 'lowestElement' }, '#iframePreview')

      //$('#iframePreview').iFrameResize({ });
      // src: $location.protocol() + '://'+ $location.host() +':'+  $location.port() + res.html,
          // width:$scope.selectLayout.model.width,
          // height:$scope.selectLayout.model.Height,
          // rotate:$scope.selectLayout.model.rotate,
          // channels:$scope.channels
          $scope.bPreviewLoading = false;

      }, function(res) {
        //ゴミ消す
        // $http.delete('/api/v1/lima/layout/dcm/' + postdata.channel )
        // .success(function(res) {
        //   console.log("delete tempLayout");
        // });
        $scope.bPreviewLoading = false;
      });

  }


  $scope.optiontest = function() {
    $http.post('/api/v1/content/extra/option').
    success(function(datas) {
      //$scope.regions = datas.regions;
    });

  }




}]);


