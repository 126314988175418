'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('login', {
    url: '/login',
    templateUrl: 'app/account/login/login.html',
    controller: 'LoginCtrl',
    title: 'ログイン'
  })
  .state('loginAdmin', {
    url: '/admin/login',
    templateUrl: 'app/account/login/login.html',
    controller: 'LoginAdminCtrl',
    title: '管理者ログイン'
  })
  .state('logout', {
    url: '/logout',
    templateUrl: 'app/account/logout/logout.html',
    controller: 'LogoutCtrl',
    title: 'ログアウト'
  })
  .state('settings', {
    url: '/settings',
    templateUrl: 'app/account/settings/settings.html',
    controller: 'SettingsCtrl',
    authenticate: true,
    title: 'Settings'
  });
}]);
