'use strict';

var app = angular.module('limaApp');

app.controller('AddgroupCtrl', ['$scope', '$location', '$translate', '$uibModalInstance', 'socket',
function ($scope, $location, $translate, $modalInstance, socket) {

  socket.connect().then(function () {
    return socket.call('v1:user:role:findAll');
  }).then(function (res) {
    $scope.roles = res;
  });

  $scope.newgroup = new Object();
  $scope.newgroup.roles = [];
  $scope.createGroup = function(form) {
    $scope.submitted = true;
    if(!form.$valid) {
      //console.log('create() invalid');
      return;
    }

    $scope.newgroup.roles = [];
    $scope.newgroup.roles.push({id:$scope.selectedRole});

    socket.connect().then(function () {
      return socket.call('v1:user:group:create', {group:$scope.newgroup});
    }).then(function (res) {
      $scope.message = 'added group : ' + JSON.stringify($scope.newgroup);
      $modalInstance.close();
    }, function (err) {
      var msgs = [];
      if (_.has(err, 'errors')) {
        _.forEach(err.errors, function(error) {
          if (_.has(error, 'code')) {
            if (error.code === 'ER_DUP_ENTRY') {
              $scope.isGroupnameDuplicate = true;
            }
          }
          if (_.has(error, 'property')) {
            if (error.property === 'instance.group.roles[0]') {
              $scope.isUnSelectedRoles = true;
            }
          }
          if (error.stack.match(/instance.group.description does not meet maximum length of \d+/)) {
            msgs.push($translate.instant('USER.USERGROUP_DESCRIPTION_LENGTH_TOO_LONG'));
          }
        });
      }
      if (!$scope.isGroupnameDuplicate && !$scope.isUnSelectedRoles) {
        $scope.msg_error = (msgs.length) ? msgs.join(', ') : (err.message || err);
      }
    });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.$watch('newgroup.name', function () {
    $scope.isGroupnameDuplicate = false;
  });
  $scope.$watch('selectedRole', function () {
    $scope.isUnSelectedRoles = false;
  });

}]);
