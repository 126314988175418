'use strict';

var app = angular.module('limaApp');

app.controller('SystemManualCtrl', ['$scope', '$http', '$timeout', '$translate', '$location', '$cookies', 'Auth', '$window', 'limaDiskService','limaOptionService',
function ($scope, $http, $timeout, $translate, $location, $cookies, Auth,$window, limaDiskService,limaOptionService) {

  $window.document.title  = $translate.instant('NAVIGATION.MANUAL');

  $scope.packageName = "";
  limaOptionService.getpackageType(function(result){
    if (result) {
      $scope.packageType = result.packageType;
      $timeout(function() {
        $scope.packageName = $translate.instant('PACKAGE_SERVICE.package_' + $scope.packageType + '.DESCRIPTION');
      });
    }
  });


  var urlBase = $location.protocol() + '://'+ $location.host() + ":" + $location.port();
  $scope.download_manual = {
    url : 'manual.pdf'
  };
  $scope.download_manual_LINE = {
    url : 'manual_line.pdf'
  };
  $scope.download_manual.urlFull = urlBase + '/info/doc/pdf/' + $scope.download_manual.url;
  $scope.download_manual_LINE.urlFull = urlBase + '/info/doc/pdf/' + $scope.download_manual_LINE.url;

  $scope.preview_manual = {
    url : 'preview.html'
  };
  $scope.preview_manual.urlFull = urlBase + '/system/manual/preview';

  $scope.preview_manual_LINE = {
    url : 'preview.html?type=line'
  };
  $scope.preview_manual_LINE.urlFull = urlBase + '/system/manual/preview?type=line';

  function getEsysInfo(){
    //LINE情報
    $http.get('/api/v1/content/extra/esys/line').
    success(function(data) {
      $scope.esysInfo = data;
      getEsysLineStatus(function() {
        //
      })
    });
  }
  getEsysInfo();
  $scope.isEsysLlineEnable = function() {
    if ($scope.esysInfo) {
      if ($scope.esysInfo.contractInfo) {
        return $scope.esysInfo.contractInfo.contents_contract_id;
      }
    }
    return false;
  }


}]);
