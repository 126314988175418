'use strict';

var app = angular.module('limaApp');

app.controller('SettingsCtrl', ['$scope', '$translate', 'Auth', 'LimaRedirect', '$window',
function ($scope, $translate, Auth, LimaRedirect, $window) {
  $scope.errors = {};
  (function (help) {
    switch (help) {
    case 'Password has expired': $translate('SETTINGS.HELP.PASSWORD_EXPIRED').then(function (res) { $scope.help = res; }); break;
    case 'Please login':
    case 'Anonymous login failed':
    case 'Failed to get anonymous login params':
    case 'Login session has expired':
    case 'Forbidden': break;
    default: $scope.help = help; break;
    }
  })(LimaRedirect.help);

  $window.document.title  = $translate.instant('NAVIGATION.SETTINGS');

  $scope.changePassword = function(form) {
    $scope.submitted = true;
    form.password.$setValidity('mongoose', true); //#6827  保存を押されたときに前のエラーが残ってたら一旦解除
    if(form.$valid && $scope.user.newPassword === $scope.user.retypePassword) {
      Auth.changePassword($scope.user.oldPassword, $scope.user.newPassword).then(function (res) {
        if (!LimaRedirect.back()) {
          $scope.message = $translate.instant('SETTINGS.MSG_PASSWORD_SUCCESS');
        }
      }, function (err) {
        if (err.data && err.data.message === 'Old password is invalid') {
          form.password.$setValidity('mongoose', false);
          $scope.errors.other = $translate.instant('SETTINGS.WARN_PASSWORD_INCORRECT');
          $scope.message = '';
        } else {
          $scope.message = err.data.message || err.data;
        }
      });
    }
  };
}]);
