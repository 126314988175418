'use strict';

angular.module('limaApp')
.directive('rafaleLimitToMax', function() {
  return {
    link: function(scope, element, attributes) {
      element.on("keydown keyup", function(e) {
    if (Number(element.val()) > Number(attributes.max) &&
          e.keyCode != 46 // delete
          &&
          e.keyCode != 8 // backspace
        ) {
          e.preventDefault();
          element.val(Number(attributes.max)).change(); //更新
        }
      });
    }
  };
})
.directive('rafaleLimitToMin', function() {
  return {
    link: function(scope, element, attributes) {
      element.on("keydown keyup", function(e) {
    if (Number(element.val()) < Number(attributes.min) &&
          e.keyCode != 46 // delete
          &&
          e.keyCode != 8 // backspace
        ) {
          e.preventDefault();
          element.val(Number(attributes.min)).change(); //更新
        }
      });
    }
  };
})

.controller('TranscodepresetCtrl', ['$scope', '$stateParams', '$http', '$timeout', '$translate', '$localStorage', '$window', '$uibModal', '$q', '$filter', 'Auth', 'Modal', 'socket',
function($scope, $stateParams, $http, $timeout, $translate, $localStorage, $window, $modal, $q, $filter, Auth, Modal, socket) {

  //メッセージ用
  $scope.msg = {};

  //プリセット
  $scope.profiles = [];
  $scope.activeprofileIndex = -1;

  //config
  $scope.archivers = {enabled: false};
  $scope.archiveServer = {enabled: false};
  $http.get('/api/v1/configurations/archivers/enabled').success(function (enabled) {
    $scope.archivers.enabled = angular.fromJson(enabled);
  });
  $http.get('/api/v1/configurations/archiveServer/enabled').success(function (enabled) {
    $scope.archiveServer.enabled = angular.fromJson(enabled);
  });

  //ページネーション設定
  $scope.pager = {};
  $scope.setPage = function(page) {
    // if (page < 1 || page > $scope.pager.totalPages) {
    //     return;
    // }
    // pager object作成
    $scope.pager = PagerService().GetPager($scope.profiles.length, page, 15 , 6);
  };

  function initPageController() {
    // 今の表示Indexに合わせたページを表示する
    var nowPage = Math.ceil(($scope.activeprofileIndex+1) / $scope.pager.pageSize); //Indexは0始まりだから
    $scope.setPage(nowPage <=0 ? 0 : nowPage);
  }

  $scope.profileFunc = ProfileFunc($translate);

  $scope.showNavbar = true;
  if (!_.isUndefined($stateParams.navbar)) {
    $scope.showNavbar = $stateParams.navbar === 'false' ? false : true;
  }


  //プリセットの解析
  function analyzeProfile(index, options, parameters) {
    //初期化
    $scope.profileItems.select = _.cloneDeep($scope.profileItems.default.value);
    $scope.profileItems.checked = _.cloneDeep($scope.profileItems.default.checked);

    if ($scope.profiles.length <= index) {
      return;
    }

    var options2 = options;
    if (_.isArray(options)) {
      options2 = JSON.stringify(options, undefined, 2);
    }
    var parameters2 = parameters;
    if (_.isArray(parameters)) {
      parameters2 = JSON.stringify(parameters, undefined, 2);
    }

    var resFunc = $scope.profileFunc.analyze(options2, parameters2);
    if (resFunc) {
      $scope.profileItems.select = resFunc.val;
      $scope.profileItems.checked = resFunc.check;

      //デフォルト値がなくなってしまうので再度入れる
      if (!$scope.profileItems.checked.video.framesize) { $scope.profileItems.select.video.framesize={}; $scope.profileItems.select.video.framesize = $scope.profileItems.default.value.video.framesize; }
      if (!$scope.profileItems.checked.video.aspect) { $scope.profileItems.select.video.aspect={}; $scope.profileItems.select.video.aspect = $scope.profileItems.default.value.video.aspect; }
      if (!$scope.profileItems.checked.video.bitrate) { $scope.profileItems.select.video.bitrate = $scope.profileItems.default.value.video.bitrate; }

      if (!$scope.profileItems.checked.audio.bitrate) { $scope.profileItems.select.audio.bitrate = $scope.profileItems.default.value.video.bitrate; }
      if (!$scope.profileItems.checked.audio.sample) { $scope.profileItems.select.audio.sample = $scope.profileItems.default.value.audio.sample; }
    }


    $scope.profileItems.select.options =  options2;  //textarea表示用
    $scope.profileItems.select.parameters = parameters2;
    $scope.profileItems.select.etc = {};
    $scope.profileItems.checked.etc = {};

    if ( $scope.profiles[index].data.auto &&  $scope.profiles[index].data.auto.length ) {
      $scope.profileItems.select.etc.auto = _.find( $scope.transcodeAuto , function(item2){ if ( item2.id === $scope.profiles[index].data.auto ) {return true;}});
      $scope.profileItems.checked.etc.auto = true;
    } else {
      $scope.profileItems.checked.etc.auto = false;
    }
    if ( $scope.profiles[index].data.manual ) {
      $scope.profileItems.select.etc.manual = $scope.profiles[index].data.manual;
      $scope.profileItems.checked.etc.manual = true;
    } else {
      $scope.profileItems.checked.etc.manual = false;
    }
    if ( $scope.profiles[index].data.protected ) {
      $scope.profileItems.select.etc.protected = $scope.profiles[index].data.protected;
      $scope.profileItems.checked.etc.protected = true;
    } else {
      $scope.profileItems.checked.etc.protected = false;
    }
    if ( $scope.profiles[index].data.priority ) {
      $scope.profileItems.select.etc.priority = $scope.profiles[index].data.priority;
      $scope.profileItems.checked.etc.priority = true;
    } else {
      $scope.profileItems.checked.etc.priority = false;
    }
    $scope.profileItems.select.etc.title = $scope.profiles[index].data.title;

    if (!$scope.profileItems.checked.etc.auto) { $scope.profileItems.select.etc.auto = $scope.profileItems.default.value.etc.auto; }
    if (!$scope.profileItems.checked.etc.protected) { $scope.profileItems.select.etc.protected = $scope.profileItems.default.value.etc.protected; }
    if (!$scope.profileItems.checked.etc.priority) { $scope.profileItems.select.etc.priority = $scope.profileItems.default.value.etc.priority; }
  }

  //選択操作
  function doSetActivePreset(index) {
    //watchを解除
    if ($scope.watch) {$scope.watch();}

    if ($scope.profiles.length <= index) {
      return;
    }

    //表示していたものが新規プリセットだったら最後のものを削除
    if ($scope.activeprofileIndex !== -1) {
      if (!$scope.profiles[$scope.activeprofileIndex].data.id) {
        $scope.profiles.pop();
      }
    }

    $scope.activeprofileIndex = index;
    analyzeProfile(index, $scope.profiles[index].data.options , $scope.profiles[index].data.parameters);
    $scope.profileItems.select.change = false;
    if (!$scope.profiles[index].data.id) {
      //新規プリセットのときは変更された状態にしておく
      $scope.profileItems.select.change = true;
    }
    $scope.showTranscodeDetail = false; //詳細は閉じておく
    //ページの表示
    initPageController();

    $scope.watch = $scope.$watch('profileItems', function(newValue, oldValue, scope) {  //再度watch
      if(angular.equals(newValue, oldValue)) {
      } else {
        //変更があった
        $scope.profileItems.select.change = true;
      }
    },true);

  }

  function getAuthSystemProfile() {
    var defer = $q.defer();
    Auth.systemProperties().then(function (data) {
      _.forEach(data, function (val , key) {
        if (key.indexOf('transcode.profile.') === 0) {  //transcode.profile.で始まるもの
          if (val.options.length) {
            $scope.profiles.push({key:key , data: val});
          }
        }
      });
      defer.resolve();
    });
    return defer.promise;
  }

  //profileの初期化
  function initializeProfiles(select) { //{name:xxx, index:n} name優先
    $scope.profiles=[];
    var promise = getAuthSystemProfile();
    promise.then(
      function() { //成功
        var selectIndex = 0;
        if (select.name) {
          selectIndex = _.findIndex( $scope.profiles , function(val) {return val.data.title === select.name ? true: false;});
          if (selectIndex < 0) {
            selectIndex = 0;
          }
        } else if (select.index) {
          selectIndex = select.index;
        }
        $scope.activeprofileIndex = -1; //新規に選択しなおすので初期化
        doSetActivePreset(selectIndex);

      },
      function() { //失敗
        //なにもしない
      }
    );
  }

  $scope.initializeSystemDbProfile = function() {
    var promise2 = confirmInitializeDb();
    promise2.then(
      function() { //成功
        Auth.systemProperties({clear:true});
        $http.put('/api/v1/system/transcode.profile/init').success(function() {
          $scope.msg.message = $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.UPDATE_SUCCESS');
          $scope.msg.error = null;

          initializeProfiles({name:'', index:0});
          $timeout(function () {$scope.msg={}; }, 5000);
        }).error(function(data) {
          $scope.message = $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.UPDATE_ERR');
          $scope.msg.error = data;
          $timeout(function () {$scope.msg={}; }, 5000);
        });
      }
    );
  }

  //初期化確認
  function confirmInitializeDb() {
    var defer = $q.defer();
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.CONFIRM_INITIALIZE'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yesno) {
      return !yesno ? defer.reject() : defer.resolve();
    });
    return defer.promise;
  }


  //保存確認
  function confirmUpdate() {
    var defer = $q.defer();
    if (!$scope.profileItems.select.change) {
      defer.resolve();
    } else {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.CONFIRM_REVERT_UNSAVED'),
        btnClassYes: 'btn-primary',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-primary'
      }, function (yesno) {
        return !yesno ? defer.reject() : defer.resolve();
      });
    }
    return defer.promise;
  }

  //上書き確認
  function confirmSave(title) {
    var defer = $q.defer();
    if (!$scope.profileItems.select.change) {
      defer.resolve();
    } else {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.CONFIRM_SAVE'),
        btnClassYes: 'btn-primary',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-primary'
      }, function (yesno) {
        return !yesno ? defer.reject() : defer.resolve();
      });
    }
    return defer.promise;
  }


  //削除確認
  function confirmDelete(title) {
    var defer = $q.defer();

    Modal.confirm['delete'](function (yesno) {
      return !yesno ? defer.reject() : defer.resolve();
    })(title, 'no');
    return defer.promise;
  }

  $scope.createNewPreset = function() {
    var promise2 = confirmUpdate();
    promise2.then(
      function() { //成功
        var newTitle = $translate.instant('CONTENT.TRANSCODE_PROFILE.NEW_PRESET');
        var cnt = 0;
        while(1) {
          if (!_.find($scope.profiles, function(val) {return val.data.title === newTitle ? true: false;})) {
            break;
          }
          cnt++;
          newTitle = $translate.instant('CONTENT.TRANSCODE_PROFILE.NEW_PRESET') + cnt;
        }

        $scope.profiles.push({key:'' , data:{title:newTitle}});
        $scope.profileItems.select.change = false;
        $scope.setActivePreset( $scope.profiles.length-1);
        //変更があったことにする
        $scope.profileItems.select.change = true;
      },
      function() { //失敗
        //なにもしない
      }
    );
  };

  //optionsの整合性チェック
  function checkProfileOption(options, parameters) {
    //"-y"は必ず先頭に必要。まず-yを抜いたものを取得
    var optionsChecked = _.filter(options, function(elem){
      return (elem === '-y') ? false: true;
    });
    optionsChecked.unshift('-y');

    return optionsChecked;
  }

  function createProfileOption() {
    var options = [];
    options.push('-y');
    options.push("$(input)");

    options.push('-f');
    options.push($scope.profileItems.select.container.codec.format);

    //Video
    if ( $scope.profileItems.checked.video.framesize ) {
      if ( $scope.profileItems.select.video.framesize.w && $scope.profileItems.select.video.framesize.h ) {
        options.push('-s');
        options.push($scope.profileItems.select.video.framesize.w + 'x' + $scope.profileItems.select.video.framesize.h);
      }
    }

    if ( $scope.profileItems.checked.video.aspect ) {
      if ( $scope.profileItems.select.video.aspect.w && $scope.profileItems.select.video.aspect.h ) {
        options.push('-aspect');
        options.push($scope.profileItems.select.video.aspect.w + ':' + $scope.profileItems.select.video.aspect.h);
      }
      //-aspect 16:9
    }

    if ( $scope.profileItems.select.video.codec.format.length ) {
      options.push('-c:v');
      options.push($scope.profileItems.select.video.codec.format);
    } else {
      //無変換
      options.push('-vcodec copy');
    }

    if ( $scope.profileItems.checked.video.bitrate ) {
      if ( _.isNumber($scope.profileItems.select.video.bitrate) ) {
        options.push('-b:v');
        options.push($scope.profileItems.select.video.bitrate + 'k');
      }
    }

    //audio
    if ( $scope.profileItems.select.audio.codec.format.length ) {
      options.push('-c:a');
      options.push($scope.profileItems.select.audio.codec.format);
    } else {
      //無変換
      options.push('-acodec copy');
    }

    if ( $scope.profileItems.checked.audio.bitrate ) {
      if ( _.isNumber($scope.profileItems.select.audio.bitrate) ) {
        options.push('-ab');
        options.push($scope.profileItems.select.audio.bitrate + 'k');
      }
    }
    if ( $scope.profileItems.checked.audio.sample ) {
      options.push('-ar');
      options.push($scope.profileItems.select.audio.sample.value);
    }

    if ( $scope.profileItems.select.container.codec.format === 'mp4' ) {
      options.push('-movflags');
      options.push('faststart');
    }

    options.push('$(outfile)');

    return options;
  }

  function createProfile(index) {
    var profile = $scope.profiles[index];
    if ($scope.showTranscodeDetail) {
      try {
        profile.data.options = JSON.parse($scope.profileItems.select.options);
      } catch (ex) {
        Modal.confirm.message({title:$translate.instant('CONTENT.TRANSCODE_LIST.ERR'), html:$translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.SAVE_OPTION_ERR'), modalClass:'modal-warning'});
        return null;
      }
      //optionsのチェック
      profile.data.options = checkProfileOption(profile.data.options);
    } else {
      if ($scope.profileItems.select.analyzeRes) {
        profile.data.options = createProfileOption();
      } else {
        try {
          profile.data.options = JSON.parse($scope.profileItems.select.options);
        } catch (ex) {
          Modal.confirm.message({title:$translate.instant('CONTENT.TRANSCODE_LIST.ERR'), html:$translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.SAVE_OPTION_ERR'), modalClass:'modal-warning'});
          return null;
        }
      }
    }
    if (!_.isUndefined($scope.profileItems.select.container.codec) && !_.isUndefined($scope.profileItems.select.container.codec.extension)) {
      profile.data.extension = $scope.profileItems.select.container.codec.extension;
    }
    try {
      profile.data.parameters = $scope.profileItems.select.parameters ? angular.fromJson($scope.profileItems.select.parameters) : [];
    } catch (ex) {
      Modal.confirm.message({title:$translate.instant('CONTENT.TRANSCODE_LIST.ERR'), html:$translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.SAVE_PARAM_ERR'), modalClass:'modal-warning'});
      return null;
    }
    profile.data.title = $scope.profileItems.select.etc.title;
    profile.data.auto = $scope.profileItems.checked.etc.auto ? $scope.profileItems.select.etc.auto.id : '';
    profile.data.manual = $scope.profileItems.select.etc.manual;
    profile.data.protected = $scope.profileItems.checked.etc.protected ? $scope.profileItems.select.etc.protected : '';
    profile.data.priority = $scope.profileItems.checked.etc.priority ? $scope.profileItems.select.etc.priority : '';

    //編集用
    if ( _.isUndefined(profile.data.parameters)) {
      profile.data.parameters=[];
    }

    //$Inputがあるときだけparametersに追記
    if (_.find(profile.data.options, function(elem){return elem === '$(input)' ? true : false;})) {
      if(!_.find(profile.data.parameters, function(elem){return elem.type === 'edit-inout' ? true : false;})){
        profile.data.parameters.push(
          {
            'key': '$(input)',
            'type': 'edit-inout',
            'default': ["-i", "'$(infile)'"]
          }
        );
      }
    }

    return profile;
  }

  function checkSaveProfile(index) {
    if ( !$scope.profileItems.select.etc.title.length ) {
      return $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.UPDATE_ERR_NO_TITLE');
    }
    if (_.find($scope.profiles , function(elem){ return ((elem.data.title === $scope.profileItems.select.etc.title) && ($scope.profiles[index].data.id !== elem.data.id)) ? true : false; })) {
      return $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.UPDATE_ERR_SAME_TITLE');
    }
    return '';
  }

  function doSaveProfile(index) {
    //watchを解除
    if ($scope.watch) {$scope.watch();}
    $scope.profileItems.select.change = false;  //変更フラグを初期化

    var profile = [];
    profile= createProfile(index);
    if (!profile) {
      $scope.profileItems.select.change = true;  //変更フラグONのままにする
      return;
    }

    var name;
    if (!profile.data.id) {
      //新規
      profile.data.id = new Date().getTime().toString(16);  //桁数減らすために16進数にしてみる
      name = 'transcode.profile.user.' + profile.data.id;
    } else {
      name = profile.key;
    }

    //
    var data = [{name: name, value: angular.toJson(profile.data, true)}];
    Auth.systemProperties({clear:true});
    $http.put('/api/v1/system/', data).success(function() {
      $scope.msg.message = $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.UPDATE_SUCCESS');
      $scope.msg.error = null;

      initializeProfiles({name:profile.data.title});
      $timeout(function () {$scope.msg={}; }, 5000);
    }).error(function(data) {
      $scope.message = $translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.UPDATE_ERR');
      $scope.msg.error = data;
      $timeout(function () {$scope.msg={}; }, 5000);
    });
  }

  $scope.saveProfile = function(index) {
    var res = checkSaveProfile(index);
    if (res.length){
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:res, modalClass:'modal-warning'});
      return;
    }
    var promise = confirmSave();
    promise.then(
      function() { //成功
        doSaveProfile(index);
      },
      function() { //失敗
        //なにもしない
      }
    );
  };

  $scope.showTranscodeDetail = false;
  $scope.watch = null;

  $scope.showhideTranscodeDetail = function(show) {
    $scope.showTranscodeDetail = show;
    if (!show) {  //閉じるときに詳細じゃないほうを更新
      analyzeProfile($scope.activeprofileIndex , $scope.profileItems.select.options , $scope.profileItems.select.parameters);
    } else {
      //optisonsをアップデート
      if ($scope.profileItems.select.analyzeRes) {
        $scope.profileItems.select.options = createProfileOption();
        //表示用に文字列に変換しておく
        if ($scope.profileItems.select.options && _.isArray($scope.profileItems.select.options)) {
          $scope.profileItems.select.options = JSON.stringify($scope.profileItems.select.options, undefined, 2);
        }
      }
    }
  }

  function doDeletePreset(index) {
    var deletetitle = $scope.profiles[index].data.title;
    Auth.systemProperties({clear:true});
    $http.delete('/api/v1/system/' + $scope.profiles[index].key).success(function() {
      $scope.msg.message = $translate.instant('COMMON.MSG.DONE_DELETE' , {name:deletetitle});
      $scope.msg.error = null;
      $timeout(function () {$scope.msg={}; }, 5000);

      //全部読み直して選択しなおす:現在の選択の次のアイテム
      var selectindex = index;
      if (($scope.profiles.length-1) <= selectindex) {  //一つ減るから
        selectindex = $scope.profiles.length - 1 - 1;
      }
      initializeProfiles({index: selectindex});

    }).error(function(data) {
      $scope.message = $translate.instant('COMMON.MSG.FAIL_DELETE' , {name:deletetitle});
      $scope.msg.error = data;
      $timeout(function () {$scope.msg={}; }, 5000);
    });
  }

  $scope.deletePreset = function(index) {
    if ($scope.profiles[index].data.auto) {
      Modal.confirm.message({title:$translate.instant('CONTENT.TRANSCODE_LIST.ERR'), html:$translate.instant('CONTENT.TRANSCODE_PROFILE.MSG.COMMENT_FOR_AUTO_DELETE'), modalClass:'modal-warning'});
      return;
    }
    var promise = confirmDelete($scope.profiles[index].data.title);
    promise.then(
      function() { //成功
        //削除
        doDeletePreset(index);
      },
      function() { //失敗
        //なにもしない
      }
    );
  };

  $scope.isDeletePreset = function(index) {
    if ($scope.profiles.length <= index) {
      return false;
    }
    if (index !== -1) {
      if (!$scope.profiles[index].data.id) {
        return false;
      // } else if ($scope.profiles[index].data.auto) {
      //   return false;
      } else {
        return true;
      }
    }
    return false;
  }

  $scope.isNewPreset = function(index) {
    if ($scope.profiles.length <= index) {
      return false;
    }
    if (index !== -1) {
      if (!$scope.profiles[index].data.id) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  $scope.cancelNewProfile = function(index) {
    $scope.setActivePreset(index-1);
  };

  $scope.setActivePreset = function(index) {
    var promise = confirmUpdate();
    promise.then(
      function() { //成功
        doSetActivePreset(index);
      },
      function() { //失敗
        //なにもしない
      }
    );
  };

  $scope.transcodeAuto = [
    {name: $translate.instant('CONTENT.TRANSCODE_PROFILE.ETC.LIVEREC'), id: 'liveRec'},
    {name: $translate.instant('CONTENT.TRANSCODE_PROFILE.ETC.UPLOAD'), id: 'upload'},
    {name: $translate.instant('CONTENT.TRANSCODE_PROFILE.ETC.LIVEREC') + ' ' + $translate.instant('CONTENT.TRANSCODE_PROFILE.ETC.UPLOAD'), id: 'both'}
  ];

  $scope.profileItems = {};

  $scope.profileItems.default = {
    value: {
      analyzeRes: true,
      options:'',
      parameters:'',
      container:{
        codec:$scope.profileFunc.defineVal().containers[0]
      },
      video:{
        codec:$scope.profileFunc.defineVal().videocodecs[0],
        framesize:$scope.profileFunc.defineVal().framesize[0],
        aspect:$scope.profileFunc.defineVal().aspect[0],
        bitrate:1000
      },
      audio:{
        codec:$scope.profileFunc.defineVal().audiocodecs[0],
        sample:$scope.profileFunc.defineVal().audiosamplingrate[6],
        bitrate:128
      },
      etc:{
        title: '',
        auto: $scope.transcodeAuto[0],
        protected:365,
        priority:100,
      }
    },
    checked: {
      video:{
        codec:false,
        framesize:false,
        aspect:false,
        bitrate:false
      },
      audio:{
        codec:false,
        sample:false,
        bitrate:false
      },
      etc:{
        manual: false,
        auto: false,
        protected: false,
        priority: false,
      }
    }
  };

  $scope.profileItems.checked = {
    video:{
      codec:false,
      framesize:false,
      aspect:false,
      bitrate:false
    },
    audio:{
      codec:false,
      sample:false,
      bitrate:false
    },
    etc:{
      manual: false,
      auto: false,
      protected: false,
      priority: false,
    }
  };

  $scope.profileItems.select = {};
  $scope.profileItems.select.analyzeRes = true; //options,parametersの解析結果初期化

  initializeProfiles({name:'', index:0});

  function hideNavbar(){
    if ( angular.element('#rafale-transpreset') && angular.element('#rafale-transpreset').length ) {
      if ( angular.element('.modal-dialog') && angular.element('.modal-dialog').length ) {
        $scope.showNavbar = false;
      }
    } else {
      $timeout(function() {
        hideNavbar();
      }, 100);
      return;
    }
  }

  hideNavbar();

}]);


/////////////////////////////////////////////////////////////////

var PagerService = function() {
  // PagerService定義
  var service = {};

  service.GetPager = GetPager;

  return service;

  function GetPager(totalItems, currentPage, pageSize, maxShowPages) {
      // 現在のページ
      currentPage = currentPage || 1;

      // １ページに表示する数：デフォルト10
      pageSize = pageSize || 10;

      // 最大表示ページ数：デフォルト10
      maxShowPages = maxShowPages || 10;

      // トータルページ数を計算：ceilは切り上げ
      var totalPages = Math.ceil(totalItems / pageSize);

      var startPage, endPage;
      if (totalPages <= maxShowPages) {
          // less than maxShowPages total pages so show all
          startPage = 1;
          endPage = totalPages;
      } else {
          // more than maxShowPages total pages so calculate start and end pages
          if (currentPage <= (Math.ceil(maxShowPages/2))) {
              startPage = 1;
              endPage = maxShowPages;
          } else if (currentPage + (maxShowPages/2-1) >= totalPages) {
              startPage = totalPages - (maxShowPages-1);
              endPage = totalPages;
          } else {
              startPage = currentPage - (maxShowPages/2);
              endPage = currentPage + (maxShowPages/2 - 1);
          }
      }

      // calculate start and end item indexes
      var startIndex = (currentPage - 1) * pageSize;
      var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      var pages = _.range(startPage, endPage + 1);

      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages,
          maxShowPages: maxShowPages
      };
  }
}

function ProfileFunc(trans) {

  //var ProfileFunc = {};
  var translate = trans;
  var containers=[
    { name:'MP4',     extension:'.mp4', format:'mp4'},
    { name:'MPEG-2 Transport Stream', extension:'.m2ts' , format:'mpegts'},
    // { name:'HTTP Live Streaming',     extension:'.m3u8'}
  ];

  var framesize=[
    {name:'1920 x 1080', w:1920 , h:1080 , id:'1920'},
    {name:'1280 x 720', w:1280 , h:720, id:'1280'},
    {name:'720 x 480', w:720  , h:480, id:'720'},
    {name: translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.CUSTOM'), w:1920, h:1080, id:''}
  ];
  var aspect=[
    {name:'16:9', w:16 , h:9},
    {name:'4:3', w:4 , h:3}
  ];

  var videocodecs=[
    { name: translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.NOT_TRANSCODE'), format:'copy'},
    { name:'H.264',     format:'h264'}
  ];
  var audiocodecs=[
    { name:translate.instant('CONTENT.TRANSCODE_PROFILE.TRANSCODE.NOT_TRANSCODE'), format:'copy'},
    { name:'AAC',       format:'aac'}
  ];

  var audiosamplingrate=[
    { name:'8000',  value:'8000',     id:'8000'},
    { name:'11025', value:'11025',    id:'11025'},
    { name:'16000', value:'16000',    id:'16000'},
    { name:'22050', value:'22050',    id:'22050'},
    { name:'32000', value:'32000',    id:'32000'},
    { name:'44100', value:'44100',    id:'44100'},
    { name:'48000', value:'48000',    id:'48000'}
  ];

  function defineVal () {

    return {
      containers: containers,
      aspect: aspect,
      videocodecs: videocodecs,
      audiocodecs: audiocodecs,
      framesize: framesize,
      audiosamplingrate: audiosamplingrate
    };
  }


  function analyze(options , parameters) {
    var resVal = {
      container:{},
      video:{
      },
      audio:{
      }
    } ;
    var resCheck = {
      video:{
        codec:false,
        framesize:false,
        aspect:false,
        bitrate:false
      },
      audio:{
        codec:false,
        sample:false,
        bitrate:false
      }
    };
    var mmdsParam = [
      '$(infile)',
      '$(input)',
      '$(outfile)',
      '$(outdir)',
    ];

    var param = {
      param:{
        global: {
          y: '-y',  //overwrite outfile
          movflags: '-movflags',
          i: '-i',
        },
        container:{
          codec:'-f'
        },
        video:{
          codec:'-c:v',
          codec2:'-vcodec',
          framesize:'-s',
          aspect:'-aspect',
          bitrate:'-b:v'
        },
        audio:{
          codec: '-c:a',
          codec2: '-acodec',
          sample:'-ar' ,
          bitrate:'-ab'
        }
      }
    };


    var self = this;
    resVal.analyzeRes = true; //初期化
    var optionsJson = [];
    if (options && !_.isArray(options)) {
      optionsJson = JSON.parse(options);
    } else {
      optionsJson = options;
    }

    if (!_.isUndefined(optionsJson) && _.isArray(optionsJson) && optionsJson.length) {
      var index;
      for (index = 0; index < optionsJson.length; index++) {
        var item = optionsJson[index];
      //_.forEach( optionsJson , function(item,index) { //-f mp4
        // if (item.indexOf('-') !== 0) {  // -で始まるもののみ対象
        //   //console.log('#########NG profile');
        // } else
        var findInParam = _.find(parameters, function(para){
          return para.key === item ? true :false;
        });
        if (!findInParam) {
          findInParam = _.find(mmdsParam, function(para){
            return item.indexOf(para) !== -1 ? true :false;
          });
        }
        if (!findInParam) {
          var nextVal = (index !== (optionsJson.length-1)) ? optionsJson[index+1] :'';
          if ( param.param.container.codec === item) {
            resVal.container.codec = _.find( self.defineVal().containers , function(item2){ if ( item2.format === nextVal ) {return true;}});
            if (!resVal.container.codec) {
              resVal.analyzeRes = false;
            }
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.video.codec === item || param.param.video.codec2 === item) {
            resVal.video.codec = _.find( self.defineVal().videocodecs , function(item2){ if ( item2.format === nextVal ) {return true;}});
            if (!resVal.video.codec) {
              resVal.analyzeRes = false;
            } else {
              resCheck.video.codec = true;
            }
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.video.framesize === item ) {
            resVal.video.framesize = _.find( self.defineVal().framesize , function(item2){ if ( (item2.w + 'x' + item2.h) === nextVal ) {return true;}});
            if (!resVal.video.framesize){
              resVal.video.framesize = _.find( self.defineVal().framesize , function(item2){ if (!item2.id.length) {return true;}});  //custom
              var arrSize = nextVal.split( 'x' );
              if (arrSize.length === 2){
                resVal.video.framesize.w = parseInt(arrSize[0]);
                resVal.video.framesize.h = parseInt(arrSize[1]);
              } else {
                resVal.analyzeRes = false;
              }
            }
            resCheck.video.framesize = true;
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.video.aspect === item ) {
            resVal.video.aspect = _.find( self.defineVal().aspect , function(item2){ if ( (item2.name) === nextVal ) {return true;}});
            if (!resVal.video.aspect) {
              resVal.analyzeRes = false;
            } else {
              resCheck.video.aspect = true;
            }
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.video.bitrate === item ) {
            if ( nextVal.indexOf('k') !== -1 ) {
              resVal.video.bitrate = parseInt(nextVal.replace('k' , ''));
              resCheck.video.bitrate = true;
            } else {
              resVal.analyzeRes = false;
            }
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.audio.codec === item || param.param.audio.codec2 === item ) {
            resVal.audio.codec = _.find( self.defineVal().audiocodecs , function(item2){ if ( item2.format === nextVal ) {return true;}});
            if (!resVal.audio.codec) {
              resVal.analyzeRes = false;
            } else {
              resCheck.audio.codec = true;
            }
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.audio.bitrate === item ) {
            if ( nextVal.indexOf('k') !== -1 ) {
              resVal.audio.bitrate = parseInt(nextVal.replace('k' , ''));
              resCheck.audio.bitrate = true;
            } else {
              resVal.analyzeRes = false;
            }
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.audio.sample === item ) {
            resVal.audio.sample = _.find( self.defineVal().audiosamplingrate , function(item2){ if ( item2.value === nextVal ) {return true;}});
            if (!resVal.audio.sample) {
              resVal.analyzeRes = false;
            } else {
              resCheck.audio.sample = true;
            }
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.global.y === item ) {
          }
          else if ( param.param.global.movflags === item ) {
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else if ( param.param.global.i === item ) {
            if (nextVal.indexOf('-') !== 0) {  // -で始まるもののみ対象
              index++;  //次のアイテムもチェックしたので、一つ進める
            }
          }
          else {
            //当てはまらないものがある
            resVal.analyzeRes = false;
          }
        }
      }
      return {val:resVal,check:resCheck};
    } else {
      return false;
    }
  }

  return {
    analyze: analyze,
    defineVal: defineVal
  };

}

export {ProfileFunc};
