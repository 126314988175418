'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('scheduleItem', {
    url: '/scheduleItem',
    templateUrl: 'app/schedule/scheduleItem.html',
    controller: 'ScheduleItemCtrl',
    title: 'プレイリスト切り替えリスト',
    authenticate: true
  });
}]);
