'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('newName', {
    url: '/newName',
    templateUrl: 'app/playlist/newName.html',
    controller: 'NewNameCtrl',
    title: '名前',
    authenticate: true
  });
}]);
