'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('content', {
    url: '/content',
    templateUrl: 'app/content/content.html',
    controller: 'ContentMainCtrl',
    title: 'コンテンツ一覧',
    authenticate: true
  })
  .state('content_user', {
    //    url: '/playlist/detail',
    url: '/contents',
    templateUrl: 'app/content/content_user.html',
    controller: 'ContentForUserCtrl',
    title: 'コンテンツ一覧',
    authenticate: true
  });
}]);
