'use strict';

angular.module('limaApp')
.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('scheduleedit', {
    url: '/content/schedule/schedule-edit',
    templateUrl: 'app/content/schedule/schedule-edit.html',
    controller: 'ScheduleEditCtrl',
    title: 'ScheduleEdit',
    authenticate: true,
    functionName: 'ContentManagement' //権限：コンテンツ管理ページと同じにしておく
  });
}]);
