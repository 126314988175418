'use strict';

angular.module('limaApp')
.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('edit', {
    url: '/edit?contentId&launchapp&presetconfig',
    templateUrl: 'app/content/edit/edit.html',
    controller: 'EditCtrl',
    title: 'Edit',
    authenticate: true
    // ,
    // functionName: 'ContentManagement' //権限：コンテンツ管理ページと同じにしておく
  });
}]);
