'use strict';


var app = angular.module('limaApp');
var async = require('async');


app.controller('ModalSelectDeviceCtrl', ['$scope', 'socket', 'Auth', '$http', '$translate', '$timeout', '$window',/*'$routeParams',*/'$location','$sce','$uibModalInstance','Modal','ContentUtil', 'LimaModalSelectDevice','limaOptionService',
function ($scope, socket, Auth,$http, $translate, $timeout, $window, /*$routeParams,*/$location,$sce,$modalInstance,Modal,ContentUtil, LimaModalSelectDevice,limaOptionService) {
  // $scope.selectTweet = LimaModalSelectDevice.selectTweet;
  // $scope.playlistId = LimaModalSelectDevice.playlistId;
  // $scope.playlistName = LimaModalSelectDevice.playlistName;
  // $scope.channelsInfo = LimaModalSelectDevice.channelsInfo;
  $scope.deviceGroups = LimaModalSelectDevice.deviceGroups;
  $scope.devices = LimaModalSelectDevice.devices;

  $scope.applyOption={};
  $scope.applyOption.options=[];
  $scope.applyOption.options.push({name:$translate.instant('PLAYLIST.TABLE.DEVICE') , type:'device'});
  $scope.applyOption.options.push({name:$translate.instant('DEVICE.GROUP') , type:'group'});
  $scope.applyOption.select = $scope.applyOption.options[0];


  $scope.apply = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.doEvent = function() {
    var deviceId;
    var deviceGroupsId;
    if ($scope.applyOption.select.type==='device') {
      deviceId = $scope.devices.select.deviceId;
    } else {
      deviceGroupsId = $scope.deviceGroups.select.groupId;
    }

    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('PLAYLIST.SCHEDULE.MSG.MESSAGE_CONFIRM_CLEAR_SCHEDULE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) {
        //なにもしない
      } else {
        //削除
        LimaModalSelectDevice.function(-1 ,deviceGroupsId, deviceId);

        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('PLAYLIST.SCHEDULE.MSG.MESSAGE_CONFIRM_CLEARED_SCHEDULE'),
        modalClass:'modal-danger'});

      }
    });


    return;

    //$modalInstance.dismiss({event:'clearSchedule', deviceGroupsId: deviceGroupsId, deviceId:deviceId});

  }



  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }

  $scope.isDeviceGroupEnable = false;
  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.isDeviceGroupEnable = true;
    }
  });


}]);



app.controller('ScheduleCtrl', ['$scope', 'socket', '$stateParams', 'Auth','$http', '$timeout', '$location','$cookies','$q','$uibModal','Modal','$translate','$window','limaOptionService','limaShowWaitDialog',
function($scope, socket, $stateParams,Auth, $http, $timeout, $location, $cookieStore, $q,$modal, Modal,$translate,$window, limaOptionService, limaShowWaitDialog) {

  $window.document.title  = $translate.instant('PLAYLIST.SCHEDULE.TITLE');

  Auth.systemProperties().then(function (data) {
    $scope.systemDebug = data['system.debug'] || 0;
  });

  $scope.schedulelists = [];
  var getSchedulelist = function(selectInfo) {//scheduleListId or index
    $scope.schedulelists = [];
    $http.get('/api/v1/schedules/list').
    success(function(datas) {
      if ($scope.dbtimezoneString === 'UTC') {  //S3 AURORA
        datas = _.forEach(datas, function(list){
          //UTC->SYSTEM
          const defaultTimezoneOffset = new Date().getTimezoneOffset()*-1;
          var dt = new Date(list.updatedTime);
          dt.setMinutes(dt.getMinutes() + defaultTimezoneOffset);
          list.updatedTime = dt;
        });
      }

      $scope.schedulelists = _.sortBy(datas, function(list) {
        return new Date(list.updatedTime);
      }).reverse();

      //選択
      if (selectInfo) {
        if (selectInfo.scheduleListId) {
          var find = _.find($scope.schedulelists, function(list){
            return list.scheduleListId === selectInfo.scheduleListId;
          });
          $scope.rowHighilited(find);
        }
        if (selectInfo.index >= 0) {
          if ($scope.schedulelists.length > selectInfo.index ) {
            $scope.rowHighilited($scope.schedulelists[selectInfo.index]);
          }
        }
      }
      _.forEach($scope.schedulelists, function(list) {
        $http.get('/api/v1/schedules/list/' + list.scheduleListId).
        success(function(listItems) {
          list.items = listItems;
        });
      });

      //デバイス再生状況表示
      $scope.getDevicePlayInfoFromPlaylist();


    });

  };

  $scope.dbtimezoneString = "";
  $scope.getdbTimezone = function() {
    $http.get('/api/v1/system-info/db').
    success(function(datas) {
      console.log(datas);
      _.find(datas,function(dt){
        if (dt.Variable_name === 'system_time_zone') {
          $scope.dbtimezoneString = dt.Value;
          return true;
        }
      });

      getSchedulelist();

    });
  }
  $scope.getdbTimezone();

  //playlistControlと同じ↓↓↓↓
  $scope.setting = {};
  $scope.setting.enableSchedule = false;
  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = [];
  function getChannels() {
    $scope.channelsInfo.channels = [];
    $http.get('/api/v1/channels').
    success(function(datas) {
      if ($scope.setting.enableSchedule) {
        //スケジュール用のチャンネルだけにする
        datas.channels = _.filter(datas.channels, function(ch){return ch.groupId ? false : true;});
      }
      $scope.channelsInfo.channels = datas.channels;
      // _.forEach($scope.channelsInfo.channels, function(ch) {
      //   $http.get('/api/v1/lima/layout/dcm/' + ch.id + '/layout').
      //   success(function(chInfo) {
      //     ch.chInfo = chInfo;
      //   });
      // });

    });

  }


  $scope.applyOption={};
  $scope.applyOption.options=[];
  $scope.applyOption.options.push({name:$translate.instant('PLAYLIST.TABLE.DEVICE')  , type:'device'});
  $scope.applyOption.options.push({name:$translate.instant('DEVICE.GROUP') , type:'group'});
  $scope.applyOption.select = $scope.applyOption.options[0];

  $scope.deviceGroups = [];
  function getDeviceGroups(callback) {
    $scope.deviceGroups = [];
    $http.get('/api/v1/device/groups').
    success(function(datas) {
      //自分が閲覧できるグループを確認
      if (Auth.isAdmin()) {
        $scope.deviceGroups.groups = datas;
      } else {
        var userId = Auth.getCurrentUser().id;
        $scope.deviceGroups.groups = _.filter(datas, function(gr){
          if (gr.typeDefault) {
            //All
            return true;
          } else {
            var userIds = JSON.parse(gr.userIds);
            var findUser = _.find(userIds, function(id) {
              return id === userId;
            });
            return findUser;
          }
        });
      }
      // $scope.deviceGroups.groups = datas;
      $scope.deviceGroups.showgroups = _.filter($scope.deviceGroups.groups, function(dt){
        if (!dt.typeDefault) {
          return true;
        }
      });
      if ($scope.deviceGroups.showgroups.length){
        $scope.deviceGroups.select = $scope.deviceGroups.showgroups[0];
      }
      callback();
    });

  }
//  getDeviceGroups();

  $scope.deviceGroupPlay = [];
  function getDevicePlay() {
    $scope.deviceGroupPlay = [];
    var groupIds = [];
    _.forEach($scope.deviceGroups.groups , function(item){
      groupIds.push(item.groupId);
    });
    _.forEach($scope.devices.items , function(item){
      _.find(item.groupIds , function(gp){
        if (!gp.enableGroup) {
          var find = _.find(groupIds, function(id) {
            if (id === gp.groupId) {
              return true;
            }
          });
          if (!find) {
            groupIds.push(gp.groupId);
          }
          return true;
        }
      });
    });


    var arr = [];
    var requests = groupIds.map(function (item) {
      var deferred = $q.defer();

      $http.get('/api/v1/device/groups/' + item + '/play' ).
      success(function(datas) {
        if (datas) {
          arr.push(datas);
        }
      })
      .finally(function () {
        //console.log(elem._id);
        deferred.resolve();
      }) ;

      return deferred.promise;
    });

    $q.all(requests).finally(function (results) {
      $scope.deviceGroupPlay = arr;
    });
  }

  function getDevice() {
    $scope.devices = [];
    $http.get('/api/v1/device/device').
    success(function(datas) {
      // $scope.devices.items = _.filter(datas, function(dt){
      //   return dt.allowed;
      // });
      getDeviceGroups(function() {
        if (Auth.isAdmin()) {
          $scope.devices.items = datas;
        } else {
          $scope.devices.items = _.filter(datas, function(dt){
            if (dt.allowed) {
              //グループに含まれるデバイスだけにする
              var findGrp = _.find($scope.deviceGroups.groups, function(gp) {
                var deviceIds = JSON.parse(gp.deviceIds);
                var findDevice = _.find(deviceIds,function(dvId) {
                  return dvId === dt.deviceId;
                });
                return findDevice;
              });
              return findGrp;
            }
          });
        }

        if ($scope.devices.items.length) {
          _.find($scope.deviceGroups.groups, function(gr){
            if (gr.typeDefault) {
              var groupInfo = angular.copy(gr);
              groupInfo.enableGroup = false;
              var all = {
                name : $translate.instant('PLAYLIST.DEVICE_ALL'),
                groupIds: [groupInfo],
                deviceId: -1
              };
              $scope.devices.items.unshift(all);
              gr.name = $translate.instant('PLAYLIST.DEVICE_ALL');

              $scope.devices.select = all;
              return true;
            }
          });
        }

        getChannels();
        //デバイス再生状況表示
        $scope.getDevicePlayInfoFromPlaylist();

      });
    });

  }
  getDevice();


  //deviceplayinfo
  $scope.devicePlayInfo = [];
  $scope.getDevicePlayInfo = function(callback) {
    $scope.devicePlayInfo = [];
    $http.get('/api/v1/device/playInfo').
    success(function(datas) {
      $scope.devicePlayInfo  = datas;
      console.log(datas);

      if (callback) {
        callback();
      }
    });
  }
  $scope.getDevicePlayInfo();

  $scope.getDevicePlayInfoName = function(list){
    var findItems = _.filter($scope.devicePlayInfo , function(item){
      if ( item.scheduleListId === list.scheduleListId){
        return true;
      }
    });
    var arrName = [];
    _.forEach(findItems, function(findItem){
      _.find($scope.devices.items , function(dv){
        if (dv.deviceId === findItem.deviceId) {
          arrName.push( dv.name );
          return true;
        }
      });
    });

    var limit = 200;
    list.deviceInfoNames = '';
    _.find(arrName , function(name, index){
      list.deviceInfoNames += name;
      if (list.deviceInfoNames.length > limit) {
//        list.deviceInfoNames += ' ...';
        return true;
      } else {
        if (index !== (arrName.length-1)) {
          list.deviceInfoNames += ' / ';
        }
      }
    })
    // list.deviceInfoNames = arrName.join(' / ');
    //list.deviceInfoNames = list.deviceInfoNames.length > limit ? (list.deviceInfoNames).slice(0,limit)+"…" : list.deviceInfoNames;

//    list.deviceInfoNames_all = arrName.join("\r\n");
    list.deviceInfoNames_all = arrName.join(" / ");
  }

  $scope.getDevicePlayInfoFromPlaylist = function(){
    $timeout(function() {
      $scope.getDevicePlayInfo(function() {
        _.forEach($scope.schedulelists, function(list) {
            $scope.getDevicePlayInfoName(list);
          })
      });
    });
  }



  $scope.getDevicegroupName = function(list , bGroup){
    var findItems = _.filter($scope.deviceGroupPlay , function(item){
      if (!item.scheduleListId || !list.scheduleListId) {
        return false;
      } else {
        if ( item.scheduleListId === list.scheduleListId){
          return true;
        }
      }
    });
    var arrName = [];
    _.forEach(findItems, function(findItem){
      if (findItem) {
        var findItem3 = _.find($scope.deviceGroups.groups , function(item3){
          if ( item3.groupId === findItem.deviceGroupId){
            return true;
          }
        });
        if (findItem3) {
          if (bGroup) {
            arrName.push( findItem3.name );
          } else {
            if (findItem3.typeDefault) {  //すべてはデバイス・グループ関係ない
              arrName.push( findItem3.name );
            }
          }
        } else {
          if (!bGroup) {
            //デバイスの単独グループを探す
            _.find($scope.devices.items , function(dv){
              var find = _.find(dv.groupIds , function(gp){
                if ( gp.groupId === findItem.deviceGroupId){
                  return true;
                }
              });
              if (find) {
                arrName.push( dv.name );
                return true;
              }
            });
          }
        }
      }
    });

    return arrName.join(' / ');
  };


  var optionService = null;
  $scope.isDeviceGroupEnable = false;
  $scope.maxPlaylistCount = 10;
  limaOptionService.getOptionService(function(result) {
    optionService = result;
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.isDeviceGroupEnable = true;
    }
    find = _.find(result, function(op){
      return op.name === 'AddPlaylist' ? true : false;
    });
    if (find) {
      $scope.maxPlaylistCount += find.amount * 10;
    }

  });
  //playlistControlと同じ↑↑↑↑↑


  //リストが選択されたときの処理
  $scope.selectedRow = null;
  $scope.rowHighilited = function (list) {
    _.forEach($scope.schedulelists, function(item){
      item.checked = false;
    });
    list.checked = true;
    $scope.selectedRow = list;

  };

  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }

  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  function openNewNameDialog(callback){
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/newName.html',
      controller: 'NewNameCtrl',
      windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaNewNameData: function() {
          return {
            name : $scope.formatDate(new Date() , 'YYYYMMDD_hhmmss')
          };
        }
      }
    });
    modalInstance.result.then(function(resNewName) {
      console.log(resNewName);

      var postCreate = {
        name : resNewName.name
      }
      $http.post('/api/v1/schedules/list', postCreate).
      success(function(datas) {
        if (datas) {
          callback({scheduleListId:datas, name: resNewName.name});
        } else {
          callback(null);
        }
      });
    }, function() {
      //
      callback(null);
    });
  }

  function checkNewScheduleItemName(list) {
    var defer = $q.defer();

    if (!list) {
      // //New => 名前つけてから渡す
      //  //new
      //  openNewNameDialog(function(result){
      //   if (!result) {
      //     defer.reject();
      //   } else {
      //     defer.resolve(result);
      //   }
      //  });
       defer.resolve(list);
      } else {
      defer.resolve(list);
    }
    return defer.promise;
  }

  function openScheduleItem(list) {
    if (!list) {
      if ($scope.schedulelists.length >= $scope.maxPlaylistCount) {

        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('PLAYLIST.SCHEDULE.MSG.MESSSAGE_COUNT_MAX'),
        modalClass:'modal-danger'});
        return;
      }
    }

    var promise = checkNewScheduleItemName(list);
    promise.then(
      function(resultItemName) { //成功
        if (!list) {
          list = resultItemName;
        }
        var modalInstance = $modal.open({
          // templateUrl: 'app/schedule/scheduleItem.html',
          // controller: 'ScheduleItemCtrl',
          templateUrl: 'app/schedule/scheduleItemTable.html',
          controller: 'ScheduleItemTableCtrl',
          windowClass: 'modal-fit',
          backdrop: 'static', keyboard: false,
    //      size: 'lg',
          resolve: {
            LimaScheduleItemData: function() {
              return {
                data : resultItemName
              };
            }
          }
        });
        modalInstance.result.then(function(results) {
          console.log(results);
          if (!results.scheduleItems){
            //新規＝＞空のまま閉じた
            return ;
          }
          if (!results.scheduleItems.length) {
            var scheduleListId = results.scheduleListId;
            Modal.confirm.yesno({
              title: $translate.instant('COMMON.CONFIRM'),
              html: $translate.instant('PLAYLIST.SCHEDULE.MSG.MESSSAGE_NO_COUNT'),
              btnClassYes: 'btn-default',
              btnCaptionYes: $translate.instant('COMMON.YES'),
              btnClassNo: 'btn-danger',
              btnCaptionNo: $translate.instant('COMMON.NO'),
              modalClass: 'modal-danger'
            }, function (yes) {
              if (!yes) {
                $timeout(function(){
                  if (list) {
                    getSchedulelist({scheduleListId:scheduleListId});
                  } else {
                    getSchedulelist({index:0});
                  }
                });
              } else {
                //削除
                $http.delete('/api/v1/schedules/list/' + scheduleListId).
                success(function(resDel) {
                  //削除
                  $timeout(function(){
                    getSchedulelist();
                  });
                });
              }
            });
          } else {
            $timeout(function(){
              if (list) {
                getSchedulelist({scheduleListId:list.scheduleListId});
              } else {
                getSchedulelist({index:0});
              }
            });
          }
        }, function(result) {
          //
          $timeout(function(){
            $scope.schedulelists = [];
            if (list) {
              getSchedulelist({scheduleListId:list.scheduleListId});
            } else {
              getSchedulelist({index:0});
            }
          });
        });

      },
      function() { //失敗
        //なにもしない
      }
    );
  }

  $scope.createScheduleList = function() {
    //新規作成
    openScheduleItem(null);
  }

  $scope.editScheduleList = function(list) {
    if (!list) {
      list = $scope.selectedRow;
    }
    //編集
    if (list) {
      openScheduleItem(list);
    }
  }

  function createScheduleItem(scheduleListId , item , callback) {

    var httpParam = {
      url: '/api/v1/schedules/list/' + scheduleListId,
      method: "",
      data: item
    };

    httpParam.method = 'POST';
    $http(httpParam)
    .success(function(resItem) {
      callback();
    });
  }

  function getCopyName(baseName) {
    var newName = baseName;
    var find = _.find($scope.schedulelists, function(item) {
      return item.name === newName;
    });
    if (find) {
      var index = 1;
      while(1){
        newName = baseName + "(" + index + ')';
        var find = _.find($scope.schedulelists, function(item) {
          return item.name === newName;
        });
        if (find) {
          index++;
        } else {
          break;  //この名前で被りなし
        }
      }
    }
    return newName;
  }

  $scope.copyScheduleList = function() {
    //コピー
    if ($scope.selectedRow) {
      if ($scope.schedulelists.length >= $scope.maxPlaylistCount) {

        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('PLAYLIST.SCHEDULE.MSG.MESSSAGE_COUNT_MAX'),
        modalClass:'modal-danger'});
        return;
      }

      var maxStringLength = 127;
      var copyName = getCopyName($scope.selectedRow.name + "_コピー");
      if (copyName.length > maxStringLength) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:"コピー元のスケジュール名が長すぎるため、コピーできません。<br>スケジュール名の長さを短くしてから再度コピーしてください。",
        modalClass:'modal-danger'});
        return;
      }

      var postCreate = {
        name : copyName
      }
      $http.post('/api/v1/schedules/list', postCreate).
      success(function(datas) {
        var scheduleListId = datas;
        var arrItems  = _.cloneDeep($scope.selectedRow.items);
        async.forEachOfSeries(arrItems , function(item,index,next) {
          item.scheduleListId = scheduleListId;
          delete item.scheduleListItemId;
          createScheduleItem(scheduleListId, item, function(){
            next(null);
          })
        }, function (err) {
          //おわり
          $timeout(function(){
            $scope.selectedRow = null;
            getSchedulelist({scheduleListId:scheduleListId});
          });
        }) ;
      });
    }
  }

  var deleteScheduleFunc = function(selected) {

    //削除
    limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.SCHEDULE'),$translate.instant('PLAYLIST.MESSAGE_DELETING'));
    {
      async.forEachSeries(selected, function (item, cb) {

        //削除
        $http.delete('/api/v1/schedules/list/' + item.scheduleListId).
        success(function(datas) {
          cb(null);
        });
      }, function (err) {
        $timeout(function() {
          getSchedulelist();
          limaShowWaitDialog.hideWait($translate.instant('NAVIGATION.SCHEDULE'),$translate.instant('NAVIGATION.MESSAGE_DELETED'));
        })
      });
    }
  }

  $scope.deleteScheduleFromDlg = function() {

      var datas = [];
      _.forEach($scope.schedulelists , function(dt) {
        datas.push({
          titleArray : [dt.name],
          id : dt.scheduleListId,
          canSelect : true
        })
      });


      var modalInstance = $modal.open({
        templateUrl: 'components/dialog/dlgSelectItem.html',
        controller: 'DlgSelectItemCtrl',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          selectItem: function() {
            return {
              titleArray:[$translate.instant("PLAYLIST.TABLE.NAME")],
              dlgTitle : $translate.instant('NAVIGATION.SCHEDULE'),
              btnName : $translate.instant('COMMON.DELETE'),
              datas : datas
            };
          }
        }
      });
      modalInstance.result.then(function(results) {

        var  selected = _.filter($scope.schedulelists, function(tw){
          return _.find(results.selected , function(dt){
            return dt.id === tw.scheduleListId;
          })
        });
        Modal.confirm.yesno({
          title: $translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('PLAYLIST.SCHEDULE.SELECTED_SCHEDULE')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
          btnClassYes: 'btn-default',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-danger',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) return;
          //削除
          deleteScheduleFunc(selected);
        });
      }, function() {
        //
      });

  }


  $scope.deleteScheduleList = function() {
    //削除
    if ($scope.selectedRow) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('PLAYLIST.SCHEDULE.SELECTED_SCHEDULE')}),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        //削除
        $http.delete('/api/v1/schedules/list/' + $scope.selectedRow.scheduleListId).
        success(function(datas) {
          //削除
          $timeout(function(){
            $scope.selectedRow = null;
            getSchedulelist();
          });
        });
      });
    }

  }


  function getChannelIdFromDeviceGroupId(deviceGroupId,deviceId){
    var itemFind = _.find($scope.channelsInfo.channels, function(ch){
      if (ch.groupId === deviceGroupId) {
        return true;
      }
      // if (ch.deviceId === deviceId) {
      //   return true;
      // }
      return false;
    });
    if (itemFind) {
      return itemFind.channelId;
    }
    return null;
  }

  //プレイリストが割当たってないスケジュールかどうか
  $scope.hasNoPlaylistSchedule = function(scheduleListId, callback) {
    $http.get('/api/v1/schedules/list/' + scheduleListId).
    success(function(listItems) {
      $http.get('/api/v1/playlists').
      success(function(playlists) {
        var findNoPlaylist = _.find(listItems, function(item){
          var find = _.find(playlists.playlists, function(pl){
            if (item.playlistId) {
              return item.playlistId === pl.playlistId;
            } else {
              //デバイスコントロールはチェック対象外
              return true;
            }
          });
          if (!find) {
            return true;
          }
        });
        callback(findNoPlaylist ? true : false);
      });
    });

  }

  $scope.clearScheduleListToDevice = function() {

  }

  $scope.addToSchedule = function(scheduleListId, deviceGroupsId, deviceId) {
    //
    var selectedLists = [];
    var bDoClear = false; //クリアかどうか
    if (scheduleListId) {
      if (scheduleListId === -1) {
        //クリアなので何も登録しない
        bDoClear = true;
      } else {
        selectedLists.push({scheduleListId:scheduleListId});
      }
    } else {
      selectedLists = _.filter($scope.schedulelists, function(p){return p.checked;});
    }

    if (!selectedLists.length && !bDoClear) {
      return;
    }

    async.waterfall([function (cb) {
      if (!bDoClear) {
        $scope.hasNoPlaylistSchedule(selectedLists[0].scheduleListId, function(resNoPlaylist){
          cb(null ,resNoPlaylist) ;
        });
      } else {
        cb(null ,null) ;
      }
    }, function (resNoPlaylist, cb) {
      if (!resNoPlaylist) {
          //登録可能
          cb(null);
      } else {
        //登録するか
        Modal.confirm.yesno({
          title: $translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('PLAYLIST.SCHEDULE.MSG.MESSAGE_CONFIRM_ADD_NOTINCLUDE_PLAYLIST'),
          btnClassYes: 'btn-default',
          btnCaptionYes: $translate.instant('COMMON.YES'),
          btnClassNo: 'btn-danger',
          btnCaptionNo: $translate.instant('COMMON.NO'),
          modalClass: 'modal-danger'
        }, function (yes) {
          if (!yes) {
            //登録しない
            cb("no add");
          } else {
            //登録
            cb(null);
          }
        });
      }
    }, function (cb) {
      var findTypeDefaultGroup = _.find($scope.deviceGroups.groups, function(gr){
        if (gr.typeDefault) {
          return true;
        }
      });

      if (!deviceGroupsId && !deviceId) {
        if ($scope.applyOption.select.type==='device') {
          var find = _.find($scope.devices.select.groupIds, function(gr){
            return !gr.enableGroup ? true : false;
          });
          if (find) {
            deviceGroupsId = find.groupId;
          }
        } else {
          deviceGroupsId = $scope.deviceGroups.select.groupId;
        }
      }
      if (deviceId) {
        _.find($scope.devices.items,function(item){
          if (item.deviceId ===deviceId){
            var find = _.find(item.groupIds, function(gr){
              return !gr.enableGroup ? true : false;
            });
            if (find) {
              deviceGroupsId = find.groupId;
            }
            return;
          }
        });
      }

      var channelId = getChannelIdFromDeviceGroupId(deviceGroupsId, deviceId);
      if (channelId === null) {
        console.log("getChannelIdFromDeviceGroupId == > null" + " groupId = " + deviceGroupsId);
      }

      var typeDefault = false;
      if (findTypeDefaultGroup){
        if (findTypeDefaultGroup.groupId === deviceGroupsId) {
          typeDefault = true;
        }
      }

      $scope.message.CreateUpdateSuccess = "";
      $scope.message.CreateSuccess = $translate.instant('PLAYLIST.MESSAGE_NOTIFIED_REFLECTION');
      TriggerAlertClose();

      socket.emit('lima:call-request:v1:device:schedule',{scheduleListId:bDoClear ? -1 : selectedLists[0].scheduleListId,groupId:deviceGroupsId});

      $timeout(function(){
          // getDevicePlay();
        //デバイス再生状況表示
        $scope.getDevicePlayInfoFromPlaylist();
      },1000);  //すぐに呼ぶと画面に反映されないので少し待ってから情報を取る


    }], function (err) {
      if (err) {
        return ;
      } else {
        return ;
      }
    });

  };

  $scope.message = {};
  $scope.message.CreateSuccess = "";
  $scope.message.CreateUpdateSuccess = "";
  $scope.message.CreateUpdateError = "";

  function TriggerAlertClose() {
    window.setTimeout(function () {
        $("#message1").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateSuccess = "";
        });
    }, 3000);
  }


  function TriggerAlertClose2() {
    window.setTimeout(function () {
        $("#message2").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateUpdateSuccess = "";
        });
    }, 3000);
  }


  socket.on('lima:device:schedule:status:updated', function (res) {
    console.log("lima:device:schedule:status:updated");


    var find = _.find($scope.devices.items , function(dv){
      return dv.deviceId === res.deviceId ? true : false;
    });
    var findPl = _.find($scope.schedulelists, function(list){
      return list.scheduleListId === Number(res.scheduleListId) ? true : false;
    });

    if (find && findPl) {
      if ($scope.message.CreateUpdateSuccess.length) {
        $scope.message.CreateUpdateSuccess += "\r\n";
      }
      $scope.message.CreateUpdateSuccess += $translate.instant('PLAYLIST.MESSAGE_REFLECT_PLAYLIST',{name:find.name,playlistName:findPl.name})
      TriggerAlertClose2();
    } else if (res.scheduleListId === '-1') {
      //スケジュールのクリア
      if ($scope.message.CreateUpdateSuccess.length) {
        $scope.message.CreateUpdateSuccess += "\r\n";
      }
      var sche_name = $translate.instant('NAVIGATION.SCHEDULE') + $translate.instant('COMMON.CLEAR');
      $scope.message.CreateUpdateSuccess += $translate.instant('PLAYLIST.MESSAGE_REFLECT_PLAYLIST',{name:find.name,playlistName:sche_name})
      TriggerAlertClose2();
    }

    //デバイス再生状況表示
    $scope.getDevicePlayInfoFromPlaylist();

  });

  socket.on('lima:device:schedule:updated', function (res) {
    console.log("lima:device:schedule:updated");

    //デバイス再生状況表示
    $scope.getDevicePlayInfoFromPlaylist();

  });

  $scope.clearScheduleListToDevice = function(playlist) {
    var modalInstance = $modal.open({
      templateUrl: 'app/schedule/modalSelectDevice.html',
      controller: 'ModalSelectDeviceCtrl',
      windowClass: 'modal-preview-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaModalSelectDevice: function() {
          return {
            devices: $scope.devices,
            deviceGroups: $scope.deviceGroups,
            type : 'clearSchedule',
            function: $scope.addToSchedule
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log("");
    }, function(results) {

    });

  }

  $scope.onResize = (function(previesize) {
    var body_element = document.getElementsByTagName('body');
    if (body_element) {
      if (body_element.length) {
        body_element[0].style.overflowY = "hidden";
      }
    }

    //Previewのサイズ
    var footerBar = angular.element('#footerBar')[0].clientHeight;
    // var footerMesBar = angular.element('#footerMesBar')[0].clientHeight;
    var title = angular.element('#title')[0].clientHeight + 40;
    var buttonbar = angular.element('#buttonbar')[0].clientHeight;
    if (_.isUndefined(angular.element('#lima-navbar')[0])) {
      //console.log("navbar1");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    if (_.isUndefined(angular.element('#lima-navbar')[0].clientHeight)) {
      //console.log("navbar2");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    var limanavbar = angular.element('#lima-navbar')[0].clientHeight;
    if (limanavbar > 150) { //変に高さがある＝＝まだ描画され切ってない
      //console.log("navbar3");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    var bodyHeight = window.innerHeight;

    var tableHeight = bodyHeight - (footerBar + title + buttonbar + limanavbar + 20 );
    //console.log("#bodyHeight  " + bodyHeight);
    //console.log("#tableList  " + tableHeight);
    angular.element('#tableList')[0].style.height = tableHeight + 'px';


  });


  function onResizeA() {
    $scope.onResize();
    $scope.$apply();
  }

  angular.element($window).on('resize', onResizeA);
  $timeout(function(){  //timeoutの中でapplyが起こる
//    console.log('resize');
    $scope.onResize();
  });


  $scope.$on('$destroy', function () {
    angular.element($window).off('resize', onResizeA);
  });


}]);
