'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('EditUser', {
    url: '/user/edit',
    templateUrl: 'app/user/editUser/editUser.html',
    controller: 'EdituserCtrl',
    authenticate: true,
    functionName: 'UserManagement',
    title: 'Edit User'
  });
}]);
