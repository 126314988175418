'use strict';

var app = angular.module('limaApp');

app.controller('AddDevicegroupCtrl', ['$scope', '$http','$location', '$translate', '$uibModalInstance', 'socket',
function ($scope, $http, $location, $translate, $modalInstance, socket) {

  socket.connect().then(function () {
    return socket.call('v1:user:role:findAll');
  }).then(function (res) {
    $scope.roles = res;
  });

  $scope.isGroupnameDuplicate = false;

  $scope.newgroup = new Object();
  $scope.newgroup.roles = [];
  $scope.createGroup = function(form) {
    $scope.submitted = true;
    if(!form.$valid) {
      //console.log('create() invalid');
      return;
    }

    if ($scope.newgroup.name.length) {
      $scope.isGroupnameDuplicate = false;

      $http.get('/api/v1/device/groups')
      .success(function(res) {
        var find = _.find(res, function(rr) {
          if (rr.name === $scope.newgroup.name) {
            return true;
          }
        });
        if (find) {
          $scope.isGroupnameDuplicate = true;
          return;
        } else {
          $modalInstance.close({group:$scope.newgroup});
          $modalInstance.dismiss('done');
        }
      });
    }
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.$watch('newgroup.name', function () {
    $scope.isGroupnameDuplicate = false;
  });
  $scope.$watch('selectedRole', function () {
    $scope.isUnSelectedRoles = false;
  });

}]);
