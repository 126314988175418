'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('manualPreview', {
    url: '/system/manual/preview?type',
    templateUrl: 'app/system/manual/preview/preview.html',
    controller: 'SystemManualPreviewCtrl',
    authenticate: true,
    functionName: 'SystemMonitoring',
    title: 'ヘルプ'
  });
}]);
