'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('layout', {
    url: '/layout',
    templateUrl: 'app/layout/layout.html',
    controller: 'LayoutCtrl',
    title: 'レイアウト',
    authenticate: true
  });
}]);
