/**
 * MEDIAEDGE.RAFALE.TimecodeUtil
 *
 */

function TimecodeUtil() {
}

TimecodeUtil.max = function(a,  b) {
  var max = a;
  if (max < b) max = b;

  return max;
};

function Timecode_CdrTime(hour,min,sec,msec){
  this.hour = hour;
  this.min = min;
  this.sec = sec;
  this.msec = msec;
}

function Timecode_CdrTimeCode(hour,min,sec,frame){
  this.hour = hour;
  this.min = min;
  this.sec = sec;
  this.frame = frame;

}


// *****************************************************************************
/**
 * @brief    FrameToTimeCode関数
 *
 * @param[in]    var      dwFrame
 * @param[in]    var      dwNumerator
 * @param[in]    var      dwDenominator
 * @param[in]    var      fDropped
 * @param[in,out]  CdrTimeCode*  pTimeCode
 *
 * @return    var  1:成功 / FALSE:失敗
 */
//*****************************************************************************
TimecodeUtil.FrameToTimeCode = function( dwFrame,  dwNumerator,  dwDenominator,  fDropped, /*CdrTimeCode*/ pTimeCode)
{
  if (fDropped != 0 && (dwNumerator % dwDenominator) != 0) {
    return TimecodeUtil.FrameToTimeCodeDF(dwFrame, dwNumerator, dwDenominator, fDropped, pTimeCode);
  }
  else {
    return TimecodeUtil.FrameToTimeCodeNDF(dwFrame, dwNumerator, dwDenominator, fDropped, pTimeCode);
  }
}

// *****************************************************************************
/**
 * @brief    FrameToTimeCodeNDF関数
 *
 * @param[in]    var      dwFrame
 * @param[in]    var      dwNumerator
 * @param[in]    var      dwDenominator
 * @param[in]    var      fDropped
 * @param[in,out]  CdrTimeCode*  pTimeCode
 *
 * @return    var  1:成功 / FALSE:失敗
 */
//*****************************************************************************
TimecodeUtil.FrameToTimeCodeNDF = function( dwFrame,  dwNumerator,  dwDenominator,  fDropped, /*CdrTimeCode*/ pTimeCode)
{
//  if (!pTimeCode) {
//    return FALSE;
//  }
  var dwFramesPerSec = parseInt((dwNumerator + dwDenominator - 1) / dwDenominator);

  var dwRemain = dwFrame;
  pTimeCode.hour = parseInt(dwFrame / (dwFramesPerSec * 60 * 60));
  dwRemain -= dwFramesPerSec * pTimeCode.hour * 60 * 60;
  pTimeCode.min = parseInt(dwRemain / (dwFramesPerSec * 60));
  dwRemain -= (dwFramesPerSec * pTimeCode.min * 60);
  pTimeCode.sec = parseInt(dwRemain / dwFramesPerSec);
  pTimeCode.frame = (dwRemain % dwFramesPerSec);

  return 1;
}

// *****************************************************************************
/**
 * @brief    FrameToTimeCodeDF関数
 *
 * @param[in]    var      dwFrame
 * @param[in]    var      dwNumerator
 * @param[in]    var      dwDenominator
 * @param[in]    var      fDropped
 * @param[in,out]  CdrTimeCode*  pTimeCode
 *
 * @return    var  1:成功 / FALSE:失敗
 */
//*****************************************************************************
TimecodeUtil.FrameToTimeCodeDF = function( dwFrame,  dwNumerator,  dwDenominator,  fDropped, /*CdrTimeCode*/ pTimeCode)
{
//  if (!pTimeCode) {
//    return FALSE;
//  }
  if (dwNumerator == 30000 && dwDenominator == 1001) {
    var dwFramesPerSec = parseInt((dwNumerator + dwDenominator - 1) / dwDenominator);

    var dw10minFrame = 17982;
    var dw1st1minFrame = 1800;
    var dw1minFrame = 1798;

    // 10分で17982フレーム
    var dw10min = parseInt(dwFrame / dw10minFrame);

    // 時
    pTimeCode.hour = parseInt(dw10min / 6);
    // 10分台
    var min = (dw10min % 6) * 10;
    var sec = 0;
    var frame = 0;

    var dwRemain = dwFrame - dw10min * dw10minFrame;
    // 1分台
    if (dwRemain < dw1st1minFrame) {
      // 毎0分台は1800フレーム
      sec = parseInt(dwRemain / dwFramesPerSec);
      frame = parseInt(dwRemain % dwFramesPerSec);
    }
    else {
      // それ以外は1798フレーム
      var remain_min = parseInt((dwRemain - 2) / dw1minFrame);
      dwRemain -= (dw1minFrame * remain_min) + 2;
      min += remain_min;
    }
    if ((min % 10)==0) {
      sec = parseInt(dwRemain / dwFramesPerSec);
      frame = parseInt(dwRemain % dwFramesPerSec);
    }
    else {
      if (dwRemain < 28) {
        sec = 0;
        frame = parseInt(dwRemain + 2);    // 最初の2フレームをスキップする
      }
      else {
        sec = parseInt((dwRemain + 2) / dwFramesPerSec);
        frame =parseInt ((dwRemain + 2) % dwFramesPerSec);
      }
    }
    pTimeCode.min = min;
    pTimeCode.sec = sec;
    pTimeCode.frame = frame;
    return 1;
  }
  else if (dwNumerator == 60000 && dwDenominator == 1001) {
    var dwFramesPerSec = parseInt((dwNumerator + dwDenominator - 1) / dwDenominator);

    var dw10minFrame = 35964;
    var dw1st1minFrame = 3600;
    var dw1minFrame = 3596;

    // 10分で35964フレーム
    var dw10min = parseInt(dwFrame / dw10minFrame);

    // 時
    pTimeCode.hour = parseInt(dw10min / 6);
    // 10分台
    var min = (dw10min % 6) * 10;
    var sec = 0;
    var frame = 0;

    var dwRemain = dwFrame - dw10min * dw10minFrame;
    // 1分台
    if (dwRemain < dw1st1minFrame) {
      // 毎0分台は3600フレーム
      sec = parseInt(dwRemain / dwFramesPerSec);
      frame = parseInt(dwRemain % dwFramesPerSec);
    }
    else {
      // それ以外は3596フレーム
      var remain_min = parseInt((dwRemain - 4) / dw1minFrame);
      dwRemain -= (dw1minFrame * remain_min) + 4;
      min += remain_min;
    }
    if ((min % 10)==0) {
      sec = parseInt(dwRemain / dwFramesPerSec);
      frame = parseInt((dwRemain % dwFramesPerSec));
    }
    else {
      if (dwRemain < 56) {
        sec = 0;
        frame = parseInt(dwRemain + 4);    // 最初の4フレームをスキップする
      }
      else {
        sec = parseInt((dwRemain + 4) / dwFramesPerSec);
        frame = parseInt((dwRemain + 4) % dwFramesPerSec);
      }
    }
    pTimeCode.min = min;
    pTimeCode.sec = sec;
    pTimeCode.frame = frame;
    return 1;
  }
  else if (dwNumerator == 24000 && dwDenominator == 1001) {
    return TimecodeUtil.FrameToTimeCodeNDF(dwFrame, dwNumerator, dwDenominator, fDropped, pTimeCode);
  }
  else {
    return TimecodeUtil.FrameToTimeCodeNDF(dwFrame, dwNumerator, dwDenominator, fDropped, pTimeCode);
  }
}

// *****************************************************************************
/**
 * @brief    FrameToTime関数
 *
 * @param[in]    var      dwFrame
 * @param[in]    var      dwNumerator
 * @param[in]    var      dwDenominator
 * @param[in]    var      fDropped
 * @param[in,out]  CdrTime*    pTime
 *
 * @return    var  1:成功 / FALSE:失敗
 */
//*****************************************************************************
TimecodeUtil.FrameToTime = function( dwFrame,  dwNumerator,  dwDenominator,  fDropped, /*CdrTime*/ pTime)
{
//  if (!pTime) {
//    return FALSE;
//  }
  var timecode = new Timecode_CdrTimeCode(0,0,0,0);;
  TimecodeUtil.FrameToTimeCode(dwFrame, dwNumerator, dwDenominator, fDropped, timecode);
  pTime.hour = timecode.hour;
  pTime.min = timecode.min;
  pTime.sec = timecode.sec;
  pTime.msec = ((timecode.frame * dwDenominator * 1000 + (dwNumerator - 1)) / dwNumerator);

  return 1;
}

// *****************************************************************************
/**
 * @brief    TimeCodeToFrame関数
 *
 * @param[in]  var        dwNumerator
 * @param[in]  var        dwDenominator
 * @param[in]  var        fDropped
 * @param[in]   CdrTimeCode*  pTimeCode
 *
 * @return    var
 */
//*****************************************************************************
TimecodeUtil.TimeCodeToFrame = function( dwNumerator,  dwDenominator,  fDropped,  /*CdrTimeCode*/ pTimeCode)
{
  if (fDropped != 0) {
    return TimecodeUtil.TimeCodeToFrameDF(dwNumerator, dwDenominator, fDropped, pTimeCode);
  }
  else {
    return TimecodeUtil.TimeCodeToFrameNDF(dwNumerator, dwDenominator, fDropped, pTimeCode);
  }

  //return 0;
}

// *****************************************************************************
/**
 * @brief    TimeCodeToFrameNDF関数
 *
 * @param[in]  var        dwNumerator
 * @param[in]  var        dwDenominator
 * @param[in]  var        fDropped
 * @param[in]   CdrTimeCode*  pTimeCode
 *
 * @return    var
 */
//*****************************************************************************
TimecodeUtil.TimeCodeToFrameNDF = function( dwNumerator,  dwDenominator,  fDropped,  /*CdrTimeCode*/ pTimeCode)
{
  var dwFramesPerSec = parseInt((dwNumerator + dwDenominator - 1) / dwDenominator);

  var dwFrame = (pTimeCode.hour * 60 * 60 + pTimeCode.min * 60 + pTimeCode.sec) * dwFramesPerSec + pTimeCode.frame;

  return dwFrame;
}

// *****************************************************************************
/**
 * @brief    TimeCodeToFrameDF関数
 *
 * @param[in]  var        dwNumerator
 * @param[in]  var        dwDenominator
 * @param[in]  var        fDropped
 * @param[in]   CdrTimeCode*  pTimeCode
 *
 * @return    var
 */
//*****************************************************************************
TimecodeUtil.TimeCodeToFrameDF = function( dwNumerator,  dwDenominator,  fDropped,  /*CdrTimeCode*/ pTimeCode)
{
  var timecode = new  Timecode_CdrTimeCode( pTimeCode.hour , pTimeCode.min , pTimeCode.sec , pTimeCode.frame);

  var fIs2997fps = 0;
  if ( (dwNumerator == 60000 && dwDenominator == 1001) ) {
    fIs2997fps = 1;
  } else {
    fIs2997fps = 0;
  }

  TimecodeUtil.NormalizeTimeCode(timecode, fIs2997fps);

  if (dwNumerator == 30000 && dwDenominator == 1001) {
    var dwFramesPerSec = parseInt( (dwNumerator + dwDenominator - 1) / dwDenominator );
    var dw10minFrame = 17982;
    var dw1st1minFrame = 1800;
    var dw1minFrame = 1798;

    var dw10mins = pTimeCode.hour * 6 + parseInt(pTimeCode.min / 10);
    var dwFrames = dw10mins * dw10minFrame;
    var dwMin = pTimeCode.min % 10;
    if (dwMin != 0) {  // 1分台が1分から9分のとき
      // ちょうど1分0秒 2分0秒.... 9分0秒のときは02フレームから開始する
      dwFrames += (dwMin * dw1minFrame + 2);
      dwFrames += (pTimeCode.sec * dwFramesPerSec - 2);
      dwFrames += pTimeCode.sec == 0 ? TimecodeUtil.max(pTimeCode.frame, 2) : pTimeCode.frame;
    }
    else {
      // 1分台が0分のときはドロップしない
      dwFrames += pTimeCode.sec * dwFramesPerSec;
      dwFrames += pTimeCode.frame;
    }
    return dwFrames;
  }
  else if (dwNumerator == 60000 && dwDenominator == 1001) {
    var dwFramesPerSec = parseInt((dwNumerator + dwDenominator - 1) / dwDenominator);
    var dw10minFrame = 35964;
    var dw1st1minFrame = 3600;
    var dw1minFrame = 3596;

    var dw10mins = pTimeCode.hour * 6 + parseInt(pTimeCode.min / 10);
    var dwFrames = dw10mins * dw10minFrame;
    var dwMin = pTimeCode.min % 10;
    if (dwMin != 0) {  // 1分台が1分から9分のとき
      // ちょうど1分0秒 2分0秒.... 9分0秒のときは04フレームから開始する
      dwFrames += (dwMin * dw1minFrame + 4);
      dwFrames += (pTimeCode.sec * dwFramesPerSec - 4);
      dwFrames += pTimeCode.sec == 0 ? TimecodeUtil.max(pTimeCode.frame, 4) : pTimeCode.frame;
    }
    else {
      // 1分台が0分のときはドロップしない
      dwFrames += pTimeCode.sec * dwFramesPerSec;
      dwFrames += pTimeCode.frame;
    }
    return dwFrames;
  }
  else {
//    _ASSERTE(FALSE);    // 29.97 59.94以外は対応しません
    return TimecodeUtil.TimeCodeToFrameNDF(dwNumerator, dwDenominator, 0, pTimeCode);
  }
}

// *****************************************************************************
/**
 * @brief    NormalizeTimeCode関数
 *
 * @param[in,out]  CdrTimeCode*  pTimeCode
 *
 * @return    var  1:成功 / FALSE:失敗
 */
//*****************************************************************************
TimecodeUtil.NormalizeTimeCode = function(/*CdrTimeCode*/ pTimeCode, /*var*/ fIs2994fps)
{
  if ( (pTimeCode.min % 10) != 0 ) {
    if (pTimeCode.sec == 0) {
      if (pTimeCode.frame == 0 || pTimeCode.frame == 1) {
        pTimeCode.frame = (fIs2994fps != 0 ) ? 2 : 4;
      }
    }
  }
  return 1;
}

// *****************************************************************************
/**
 * @brief    TimeToFrame関数
 *
 * @param[in]  var        dwNumerator
 * @param[in]  var        dwDenominator
 * @param[in]  var        fDropped
 * @param[in]   CdrTime*    pTime
 *
 * @return    var
 */
//*****************************************************************************
TimecodeUtil.TimeToFrame = function( dwNumerator,  dwDenominator,  fDropped,  /*CdrTime*/ pTime)
{
//  if (!pTime) {
//    return 0;
//  }
  var frame = parseInt((pTime.msec * dwNumerator + (dwDenominator - 1)) / dwDenominator / 1000);
  var timecode = new Timecode_CdrTimeCode(0,0,0,0);
  timecode.hour = pTime.hour;
  timecode.min = pTime.min;
  timecode.sec = pTime.sec;
  timecode.frame = frame;

  return TimecodeUtil.TimeCodeToFrame(dwNumerator, dwDenominator, fDropped, timecode);
}

//*****************************************************************************
//　Javascriptで使うときに必要になった関数
//*****************************************************************************

//フレーム＞hh:㎜:ss:ff
TimecodeUtil.FrameToTimecodeString = function( totalframe , num , den , fDropped )
{
  var cdrTimeCode = new Timecode_CdrTimeCode(0,0,0,0);

  TimecodeUtil.FrameToTimeCode(totalframe , num , den , fDropped , cdrTimeCode );

  var s_h = ( '000'  + String(cdrTimeCode.hour) ).slice( -2 );
  var s_m = ( '000'  + cdrTimeCode.min ).slice( -2 );
  var s_s = ( '000'  + cdrTimeCode.sec ).slice( -2 );
  var s_frame = ( '00'  + cdrTimeCode.frame ).slice( -2 );


  return s_h + ":" + s_m + ":" +s_s + (fDropped ? ";" : ":") +s_frame;
}

//フレーム数＝＞秒
TimecodeUtil.FrameToSecond = function( frame , num , den , fDropped )
{
  var cdrTime = new Timecode_CdrTime(0,0,0,0);
  TimecodeUtil.FrameToTime(frame, num , den, fDropped , cdrTime);

  return cdrTime.hour * 60 * 60 + cdrTime.min * 60 + cdrTime.sec + cdrTime.msec / 1000.0;
}
//秒＝＞フレーム数
TimecodeUtil.SecondToFrame = function( seconds , num , den, fDropped)
{
  var s =   parseInt(seconds);

  var mili = seconds  - s;  //小数
  mili = parseInt( mili * 1000 );

  var m = parseInt( s / 60 );
  s = parseInt( s % 60 );
  var h = parseInt( m / 60 );
  m = parseInt( m % 60 );


  var cdrTime = new Timecode_CdrTime(h,m,s,mili);
  var totalframe = TimecodeUtil.TimeToFrame(num , den , fDropped ,cdrTime );


  return totalframe;

}

//秒＝＞hh:㎜:ss:ff
TimecodeUtil.SecondToTimecodeString = function( seconds , num , den, fDropped )
{
  var s =   parseInt(seconds);

  var mili = seconds  - s;  //小数
  mili = parseInt( mili * 1000 );

  var m = parseInt( s / 60 );
  s = parseInt( s % 60 );
  var h = parseInt( m / 60 );
  m = parseInt( m % 60 );


  var cdrTime = new Timecode_CdrTime(h,m,s,mili);
  var totalframe = TimecodeUtil.TimeToFrame(num , den , fDropped ,cdrTime );

  return TimecodeUtil.FrameToTimecodeString(parseInt(totalframe),num , den, fDropped);

}

export {TimecodeUtil, Timecode_CdrTime, Timecode_CdrTimeCode};
