'use strict';
var app = angular.module('limaApp');

app.controller('DlgSelectItemCtrl', ['$scope', '$http', '$translate', '$timeout', 'Modal', 'Auth', '$uibModalInstance', 'socket', 'selectItem',
function ($scope, $http, $translate, $timeout, Modal, Auth, $modalInstance, socket, selectItem) {


  $timeout(function(){
    $scope.selectItem = _.cloneDeep(selectItem);

    $scope.selectItem.datas = [];
    _.forEach(selectItem.datas , function(dt) {
      if (_.isUndefined(dt.canSelect)) {
        dt.canSelect = true;  //指定がなければチェック可能
      }
      $scope.selectItem.datas.push(angular.copy(dt)); //渡された列挙と実体は違うものにしておく
    });

   _.forEach($scope.selectItem.datas , function(cm) {
    //   var find = _.find($scope.selectItem.encoderIds , function(id) {
    //     return cm.EncoderID === id;
    //   });
    //   //長い名前はチェック
    //   cm.EncoderTitle = limaAppUtil.charcount(cm.EncoderTitle , 17*2 , 14/*fontsize*/);

       cm.checked = false;
     });
    $scope.selectItem.checkedlist = _.filter($scope.selectItem.datas, function(list){return list.checked && list.canSelect;});

  });

  ////

  $scope.UpdateCheckedList_All = function(){
    $timeout(function() {
      _.forEach($scope.dlgDatas, function(list){list.checked = $scope.dlgDatas.checkedAll;});
      $scope.selectItem.checkedlist = _.filter($scope.selectItem.datas, function(list){return (list.checked && list.canSelect);});
    });
  }

  $scope.UpdateCheckedList = function(){
    $timeout(function() {
      $scope.selectItem.checkedlist = _.filter($scope.selectItem.datas, function(list){return list.checked && list.canSelect;});
    });
  }
////

  $scope.updateDevice = function() {
    // _.forEach($scope.lists, function(list){
    //   list.groupId = $scope.selectedChannel[0].groupId;
    // });

    var selected = [];  //modalで返すのは渡された列挙。
    selected = _.filter(selectItem.datas , function(enc,index) {
      if ($scope.selectItem.datas[index].checked) {  //DLGで設定したチェック状態を見る
        return true;
      };
    });

    $modalInstance.close({selected:selected});
    $modalInstance.dismiss({selected:selected});
  }


  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
