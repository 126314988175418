'use strict';

var app = angular.module('limaApp');

app.controller('EditDeviceCtrl', ['$scope', '$location', '$translate', '$timeout', 'Modal', 'Auth', '$uibModalInstance', 'socket', 'editDevice',
function ($scope, $location, $translate, $timeout, Modal, Auth, $modalInstance, socket, editDevice) {

  // socket.connect().then(function () {
  //   return socket.call('v1:user:role:findAll');
  // }).then(function (res) {
  //   $scope.roles = res;
  //   return socket.call('v1:user:group:findOne', {groupId:editUserGroupId});
  // }).then(function (res) {
  //   $scope.editgroup = res;
  //   $scope.selectedRole = res.roles[0].id;
  // })
  $scope.enableDeviceGroup = editDevice.enableDeviceGroup;
  $scope.channels = editDevice.channels;
  $scope.lists = editDevice.lists;
  $scope.selectedChannel = {};
  $scope.selectedChannel.selected = [];
  if ($scope.lists.length === 1) {
    $scope.selectedChannel.selected = _.filter($scope.channels, function(ch) {
      var find = _.find(editDevice.lists[0].groupIds,function(gr){
        return gr.groupId === ch.groupId;
      });
      return find ? true : false;;
    });
  }

  $scope.doCheckAllowed = function(enable){
    _.forEach($scope.lists, function(item){
      item.allowed = enable;
    });
  }

  $scope.updateDevice = function() {
    // _.forEach($scope.lists, function(list){
    //   list.groupId = $scope.selectedChannel[0].groupId;
    // });

    $modalInstance.close({channels : $scope.selectedChannel.selected, lists:$scope.lists});
    $modalInstance.dismiss({channels : $scope.selectedChannel.selected, lists:$scope.lists});
  }

  $scope.clearSelect = function() {
    $timeout(function(){
      $scope.selectedChannel.selected = [];
    });
  }

  $scope.updateGroup = function(groupId) {
    $scope.editgroup.roles = [];
    $scope.editgroup.roles.push({id:$scope.selectedRole});
    socket.connect().then(function () {
      return socket.call('v1:user:group:update', {groupId:groupId, group:$scope.editgroup});
    }).then(function (res) {
      $modalInstance.close();
    }, function (err) {
      if (err.message !== 'cannot remove user management role of self') {
        var msgs = [];
        _.forEach(err.errors, function (error) {
          if (error.stack.match(/instance.group.description does not meet maximum length of \d+/)) {
            msgs.push($translate.instant('USER.USERGROUP_DESCRIPTION_LENGTH_TOO_LONG'));
          } else {
            msgs.push(error.stack);
          }
        });
        $scope.msg_error = (msgs.length) ? msgs.join(', ') : (err.message || err);
        return;
      }
      Modal.confirm.yesno({
        title: $translate.instant('USER.USER_REMOVE_SELF_ROLE_TITLE'),
        html: $translate.instant('USER.USER_REMOVE_SELF_ROLE_MESSAGE'),
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        socket.connect().then(function () {
          return socket.call('v1:user:group:update', {groupId:groupId, group:$scope.editgroup, force:true});
        }).then(function (res) {
          return Auth.updateCurrentUser();
        }).then(function (res) {
          return $modalInstance.close();
        }).then(function (res) {
          LimaRedirect.go({path:'/', clearCache:true});
        }, function (err) {
          $scope.msg_error = err.message || err;
        });
      });
    });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
