'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('addchannel', {
    url: '/channel/add',
    templateUrl: 'app/channel/channel/addChannel.html',
    controller: 'AddChannelCtrl',
    title: 'チャンネル追加',
    authenticate: true
  });
}]);
