'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('option', {
    url: '/option',
    templateUrl: 'app/option/option.html',
    controller: 'OptionCtrl',
    title: 'オプション',
    authenticate: true
  });
}]);
