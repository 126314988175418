'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('AddUser', {
    url: '/user/add',
    templateUrl: 'app/user/addUser/addUser.html',
    controller: 'AdduserCtrl',
    authenticate: true,
    functionName: 'UserManagement',
    title: 'Add User'
  });
}]);
