'use strict';

var app = angular.module('limaApp');

app.controller('EditDevicegroupCtrl', ['$scope', '$location', '$translate', '$uibModal', 'Modal', 'Auth', '$uibModalInstance', 'socket','$timeout', 'deviceGroupInfo',
function ($scope, $location, $translate, $modal, Modal, Auth, $modalInstance, socket, $timeout, deviceGroupInfo) {

  $scope.deviceGroupInfo = _.cloneDeep(deviceGroupInfo);



  $scope.modalSelectDeviceDlg = function(group){

    var modalInstance = $modal.open({
      templateUrl: 'app/deviceGroup/dialog/selectDevice.html',
      controller: 'DlgSelectDeviceCtrl',
      backdrop: 'static', keyboard: false,
      resolve: {
        selectDevices: function() {
          return {
            devices : $scope.deviceGroupInfo.groupInfo.devices,
            deviceAll  : $scope.deviceGroupInfo.devices
          };
        }
      }
    });
    modalInstance.result.then(function (results) { // OK
      console.log(results.selected);
      if (!$scope.deviceGroupInfo.groupInfo) {
        $scope.deviceGroupInfo.groupInfo = {};
      }
      //results.selected <== ids
      $scope.deviceGroupInfo.groupInfo.deviceIds = results.selected;
      //update
      $timeout(function() {
        $scope.deviceGroupInfo.groupInfo.devices = _.filter($scope.deviceGroupInfo.devices, function(dv) {
          return _.find(results.selected , function(rsId){
            return rsId === dv.deviceId;
          })
        });
      });

    }, function (res1) { // cancel
      console.log(results)
    });
  }

  $scope.modalSelectUserDlg = function(){
    var modalInstance = $modal.open({
      templateUrl: 'app/deviceGroup/dialog/selectUser.html',
      controller: 'DlgSelectUserCtrl',
      backdrop: 'static', keyboard: false,
      resolve: {
        selectUsers: function() {
          return {
            users : $scope.deviceGroupInfo.groupInfo.users,
            userAll  : $scope.deviceGroupInfo.users
          };
        }
      }
    });
    modalInstance.result.then(function (results) { // OK
      console.log(results.selected);
      if (!$scope.deviceGroupInfo.groupInfo) {
        $scope.deviceGroupInfo.groupInfo = {};
      }
      $scope.deviceGroupInfo.groupInfo.userIds = results.selected;
      //update
      $timeout(function() {
        $scope.deviceGroupInfo.groupInfo.users = _.filter($scope.deviceGroupInfo.users, function(dv) {
          return _.find(results.selected , function(rsId){
            return rsId === dv.id;
          })
        });
      });
    }, function (res1) { // cancel
      console.log(results)
    });
  }

  $scope.updateGroup = function () {
    //名前のチェック
    var findGp = _.find($scope.deviceGroupInfo.groups, function(gp) {
      if ($scope.deviceGroupInfo.groupInfo.groupId) {
        if ($scope.deviceGroupInfo.groupInfo.groupId === gp.groupId) {
          //同じやつ
        } else {
          if ($scope.deviceGroupInfo.groupInfo.name.toLowerCase() === gp.name.toLowerCase()) {
            return true;
          }
        }
      } else if ($scope.deviceGroupInfo.groupInfo.name.toLowerCase() === gp.name.toLowerCase()) {
        return true;
      }
    });
    //同じのがあるのでだめ
    if (findGp) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:$translate.instant('DEVICEGROUP.ADD.MES_EXIST'),
      modalClass:'modal-danger'});
      return;
    }

    $modalInstance.close({group:$scope.deviceGroupInfo.groupInfo});
    $modalInstance.dismiss('done');
  }

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
