'use strict';

var app = angular.module('limaApp');

app.controller('SystemDeviceCtrl', ['$scope', '$http', '$filter', '$translate', '$location', '$cookies', 'Auth','limaDiskService','limaOptionService',
function ($scope, $http, $filter, $translate, $location, $cookies, Auth,limaDiskService,limaOptionService) {

  var urlBase = $location.protocol() + '://'+ $location.host() + ":" + $location.port();
  $scope.download_bravia = {
    url : 'guide_bravia.zip'
  };
  $scope.download_bravia.urlFull = urlBase + '/info/doc/pdf/' + $scope.download_bravia.url;

  $scope.download_terms = {
    url : 'terms_of_service.pdf'
  };
  $scope.download_terms.urlFull = urlBase + '/info/doc/pdf/' + $scope.download_terms.url;



  $scope.downloadApp = {};
  $http.get('/api/v1/users/me').
  success(function(datas) {
    $scope.userinfo=datas;
    $http.get('/api/v1/device/installapp/'  + $scope.userinfo.companyId).success(function (res) {
      console.log(res);
      var nCode = 0;
      _.forEach(res, function(data){

        // file[i] = obj3.get("outputFile").toString();
        // sVersion[i] = obj3.get("versionName").toString();
        if ( data.url.indexOf('tv') !== -1 ) {
          if (data.info.apkData.versionCode > nCode) {
            nCode = data.info.apkData.versionCode;    //new version
          }
        }
      });

      if (nCode) {
        $scope.downloadApp = _.find(res, function(data){
          if ( data.url.indexOf('tv') !== -1 ) {
            if (data.info.apkData.versionCode === nCode) {
              data.urlFull = urlBase +　data.url;
              return true;
            }
          }
        });
      }
    });
  });

  //S3アップロードしてるときはSignedCookieを取得する
  $scope.awsDiskUsageInMB = {};
  $scope.awsDiskUsageInMB.licensed = 0;
  $http.get('/api/v1/configurations/AWSS3UploadServer').success(function (resAws) {
    if (resAws.enabled) {
      $http.get('/api/v1/system-info/aws').then(function (res) {
        $scope.awsDiskUsageInMB.use = {size: $filter('number')(Math.round(res.data.totalSize / 1024))};
        //$scope.awsdiskUsageInMB.available = {size: $filter('number')(Math.round(info.diskUsageInKB.available / 1024))};
      });
    } else {
      $scope.diskUsageInMB = {};
      $http.get('/api/v1/system-info').then(function (res) {
        var info = res.data;
        $scope.diskUsageInMB.total = {size: $filter('number')(Math.round(info.diskUsageInKB.total / 1024))};
        $scope.diskUsageInMB.use = {size: $filter('number')(Math.round(info.diskUsageInKB.usage / 1024))};
      });

    }
  });


  $scope.deviceTransferSizeInMB = {};
  $scope.deviceTransferSizeInMB.warning = {};
  $scope.deviceTransferSizeInMB.warning.size = limaDiskService.getTransferWarningRemainSize();  //50M



  function calcDeviceTransferedSize() {
    limaDiskService.getTransferInfo(function(data){
      $scope.deviceTransferSizeInMB.use = {size: $filter('number')(Math.round(data.size / 1024 / 1024)), data:data.size};
      $scope.deviceTransferSizeInMB.warning.message = '';
      if ($scope.deviceTransferSizeInMB.licensed.data <= $scope.deviceTransferSizeInMB.use.data ) {
        $scope.deviceTransferSizeInMB.warning.message = $translate.instant('PLAYLIST.MESSAGE_NO_DISK_TO_REFLECT');
      }
      else if ($scope.deviceTransferSizeInMB.licensed.data - $scope.deviceTransferSizeInMB.use.data < $scope.deviceTransferSizeInMB.warning.size ) {
        $scope.deviceTransferSizeInMB.warning.message = $translate.instant('PLAYLIST.MESSAGE_LITTLE_DISK_TO_REFLECT');
      }
    });
  }

  function calcDeviceLicensedSize() {
    var TransferMaxSize = limaOptionService.getTransferMaxSize(optionService);

    $scope.deviceTransferSizeInMB.licensed = {size: $filter('number')(Math.round(TransferMaxSize / 1024 / 1024)), data:TransferMaxSize};
    $scope.awsDiskUsageInMB.licensed = {size: $filter('number')(Math.round(TransferMaxSize / 1024 / 1024)), data: TransferMaxSize};
  }


  $scope.devicelists = [];
  var getDevicelist = function() {
    $http.get('/api/v1/device/device').
    success(function(datas) {
      $scope.devicelists = datas;
    });
  };
  //

  var optionService = null;
  limaOptionService.getOptionService(function(result) {
    optionService = result;
    calcDeviceLicensedSize();
    calcDeviceTransferedSize();
  });

   getDevicelist();


   $scope.getOption = function(optionId){
    var find = _.find($scope.optionService, function(sc){
      return sc.optionServiceId === optionId ? true : false;
    });
    if (find) {
      return find;
    }
    return null;
  }




  Auth.systemProperties().then(function (data) {
    var versions = [];
    if (data['webapi.revision']) versions.push({name:$translate.instant('SYSTEM.STATUS.VERSIONS.WEBAPP'), value:'rev.' + data['webapi.revision']});
    if (data['database.version']) versions.push({name:$translate.instant('SYSTEM.STATUS.VERSIONS.DATABASE'), value:'rev.' + data['database.version']});
    if (versions.length) $scope.versions = versions;
  });

}]);
