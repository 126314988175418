'use strict';

var app = angular.module('limaApp');

app.controller('DlgSelectDeviceCtrl', ['$scope', '$location', '$translate', '$timeout', 'Modal', 'Auth', '$uibModalInstance', 'socket', 'selectDevices',
function ($scope, $location, $translate, $timeout, Modal, Auth, $modalInstance, socket, selectDevices) {



  $timeout(function(){
    $scope.selectDevices = selectDevices;
    _.forEach($scope.selectDevices.devices , function(cm) {
      var find = _.find($scope.selectDevices.deviceAll , function(dv) {
        return cm.deviceId === dv.deviceId;
      });
      cm.checked = find ? true : false;
    });
  });

  $scope.UpdateCheckedList_All = function(){
    //console.log($scope.svsDatas);
    $timeout(function() {
      _.forEach($scope.decDatas, function(list){list.checked = $scope.decDatas.checkedAll;});
      $scope.selectDevices.checkedlist = _.filter($scope.selectDevices.devices, function(list){return list.checked;});
    });
  }


  $scope.updateDevice = function() {
    // _.forEach($scope.lists, function(list){
    //   list.groupId = $scope.selectedChannel[0].groupId;
    // });

    var selected = [];
    _.filter($scope.selectDevices.deviceAll , function(dv) {
      if (dv.checked) {
        selected.push(dv.deviceId);
      };
    });


    $modalInstance.close({selected:selected});
    $modalInstance.dismiss({selected:selected});
  }


  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

}]);
