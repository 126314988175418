'use strict';


var app = angular.module('limaApp');

app.controller('ScheduleItemCtrl', ['$scope', 'socket', '$stateParams', 'Auth','$http', '$timeout', '$location','$cookies','$q','$uibModal','Modal','$uibModalInstance','$translate','limaOptionService', 'LimaScheduleItemData',
function($scope, socket, $stateParams,Auth, $http, $timeout, $location, $cookieStore, $q,$modal, Modal,$modalInstance,$translate,limaOptionService, LimaScheduleItemData) {

  $scope.schedule = [];
  $scope.scheduleItems = [];
  $scope.scheduleItems_initial = null;
  $scope.scheduleItems_delete = [];


  var getScheduleItemsOnly = function(callback) {
    if (LimaScheduleItemData.data && LimaScheduleItemData.data.scheduleListId) {
      $scope.schedule = LimaScheduleItemData.data;
      $http.get('/api/v1/schedules/list/' + LimaScheduleItemData.data.scheduleListId).
      success(function(datas) {
        callback(datas);
      });
    } else {
      callback(null);
    }
  }

  function sortSchedule(datas){
    //初期ソート：日付け、毎週の順にソート（開始時刻でも）
    datas.sort(function(a,b){
      var dt_a = Number(a.startTime.replace(/:/g, ''));
      var dt_b = Number(b.startTime.replace(/:/g, ''));
      if (a.date && b.date) {
        if(new Date(a.date).getTime() > new Date(b.date).getTime()) {
          return 1;
        }
        if(new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1;
        }
      }
      if (a.dayOfWeek && b.dayOfWeek) {
        if (a.dayOfWeek.length && b.dayOfWeek.length) {
          if(a.dayOfWeek[0] > b.dayOfWeek[0]) {
            return 1;
          }
          if(a.dayOfWeek[0] < b.dayOfWeek[0]) {
            return -1;
          }
        }
        if (a.dayOfWeek.length > b.dayOfWeek.length) {
            return 1;
        }
        if (a.dayOfWeek.length < b.dayOfWeek.length) {
          return -1;
        }
      }
      if(dt_a > dt_b) {
        return 1;
      }
      if(dt_b > dt_a) {
        return -1;
      }


    });
    return datas;
  }

  var getScheduleItems = function() {
    getScheduleItemsOnly(function(datas){
      if (datas) {
        $scope.schedule = LimaScheduleItemData.data;
        $http.get('/api/v1/schedules/list/' + LimaScheduleItemData.data.scheduleListId).
        success(function(datas) {

          var datas_u = sortSchedule(datas);

          if (!$scope.scheduleItems_initial) {
            $scope.scheduleItems_initial = [];
            $scope.scheduleItems = datas_u;
            $scope.scheduleItems_initial = datas_u;
          }
          getPlaylits();
        });
      } else {
        getPlaylits();
      }
    });
  };
  getScheduleItems();

  $scope.playlists = [];
  function getPlaylits() {

    $scope.playlists = [];
    $http.get('/api/v1/playlists').
    success(function(datas) {
      //プレイリストだけを抽出
      $scope.playlists = datas.playlists;

      updateScheduleItem(true,-1);
    });
  }

  $scope.getDisplayName = function(controlName) {
    if (controlName === 'monitor-off') {
      return $translate.instant('DEVICE.TYPE_MONITOR_OFF');
    }
    else if (controlName === 'monitor-on') {
      return $translate.instant('DEVICE.TYPE_MONITOR_ON');
    }
    else if (controlName === 'reboot') {
      return $translate.instant('DEVICE.TYPE_REBOOT');
    }
    return '';
  }

  $scope.controlLists = [];
  function getControlLists() {
    $scope.controlLists = [];
    $http.get('/api/v1/controls').
    success(function(datas) {
      $scope.controlLists = datas.controls;
    });
  }
  getControlLists();

  //リストが選択されたときの処理
  $scope.selectedRow = null;
  $scope.rowHighilited = function (list) {
    _.forEach($scope.scheduleItems, function(item){
      item.checked = false;
    });
    list.checked = true;
    $scope.selectedRow = list;

  };

  $scope.getTimeString = function(time){
    var arTime = time.split(':');
    return ('00' + arTime[0]).slice(-2) + ':' + ('00' + arTime[1]).slice(-2);
  }


  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }

  $scope.getDayOfWeekString = function(val){
    var str = '';
    var array = val;

    var arString = [
      $translate.instant('DAY_OF_WEEK.SHORT_SUNDAY'), $translate.instant('DAY_OF_WEEK.SHORT_MONDAY'),
      $translate.instant('DAY_OF_WEEK.SHORT_TUESDAY'), $translate.instant('DAY_OF_WEEK.SHORT_WEDNESDAY'),
      $translate.instant('DAY_OF_WEEK.SHORT_THURSDAY'), $translate.instant('DAY_OF_WEEK.SHORT_FRIDAY'),
      $translate.instant('DAY_OF_WEEK.SHORT_SATURDAY')
    ];
    _.forEach(array,function(item){
      str += arString[item] + ' ';
    });
    return str;
  }


  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  function createScheduleItem(item , callback) {

    var httpParam = {
      url: '/api/v1/schedules/list/' + item.scheduleListId,
      method: "",
      data: item
    };

    if (item.scheduleListItemId) {
      httpParam.method = 'PUT';
      httpParam.url = '/api/v1/schedules/list/' + item.scheduleListId + '/'  + item.scheduleListItemId;
    } else {
      httpParam.method = 'POST';
    }
    $http(httpParam)
    .success(function(resItem) {
      // console.log(resItem);
      // $timeout(function(){
      //   if (!LimaScheduleItemData.data) {
      //     LimaScheduleItemData.data = {};
      //   }
      //   if (!LimaScheduleItemData.data.scheduleListId) {
      //     LimaScheduleItemData.data.scheduleListId = item.scheduleListId;
      //   }
      //   getScheduleItems();
      // });
      if (!item.scheduleListItemId) {
        item.scheduleListItemId = resItem.scheduleListItemId;
      }
      callback();
    });
  }

//   function openScheduleItem(list) {
//     var modalInstance = $modal.open({
//       templateUrl: 'app/schedule/schedule-edit.html',
//       controller: 'ScheduleEditCtrl',
// //      windowClass: 'modal-fit',
//       backdrop: 'static', keyboard: false,
//       size: 'md',
//       resolve: {
//         RafaleScheduleData: function() {
//           return {
//             data : list,
//             list: $scope.scheduleItems
//           };
//         }
//       }
//     });
//     modalInstance.result.then(function(results) {
//       console.log(results);

//       if (results.scheduleListId) {
//         //update
//         createScheduleItem(results, function(){
//           console.log("createScheduleItem1");
//         });
//       } else {
//         //new
//         var modalInstance = $modal.open({
//           templateUrl: 'app/playlist/newName.html',
//           controller: 'NewNameCtrl',
//           windowClass: 'modal-rename-window',
//           backdrop: 'static', keyboard: false,
//           size: 'md',
//           resolve: {
//             LimaNewNameData: function() {
//               return {
//                 name : $scope.formatDate(new Date() , 'YYYYMMDD_hhmmss')
//               };
//             }
//           }
//         });
//         modalInstance.result.then(function(resNewName) {
//           console.log(resNewName);

//           var postCreate = {
//             name : resNewName.name
//           }
//           $http.post('/api/v1/schedules/list', postCreate).
//           success(function(datas) {
//             results.scheduleListId = datas;
//             createScheduleItem(results, function(){
//               console.log("createScheduleItem1");

//             });
//           });
//         }, function() {
//           //
//         });
//       }


//     }, function() {
//       //
//       $timeout(function(){
//         // $scope.playlists = [];
//         // $scope.getPlaylist({playlistId:$scope.selectedRow ? $scope.selectedRow.playlistId : null});
//       });
//     });
//   }

  function getUniqueStr(myStrong){
    var strong = 1000;
    if (myStrong) strong = myStrong;
    return new Date().getTime().toString(16)  + Math.floor(strong*Math.random()).toString(16)
  }

  function updateScheduleItem(bInitial , itemuuid){
    var find_noPlaylist = null;
    _.forEach($scope.scheduleItems, function(ss){
      var find = _.find($scope.playlists, function(pl){
        if (ss.playlistId) {
          if (pl.playlistId === ss.playlistId) {
            ss.playlistName = pl.playlistName;
            return true;
          }
        } else {
          return true;
        }
      });
      if (!find) {
        find_noPlaylist = true;
      }
      if (bInitial !== true) {
        if (ss.itemuuid === itemuuid) {
          $scope.rowHighilited(ss);
        }
      }
      if (!ss.itemuuid )  {
        ss.itemuuid = getUniqueStr();
        console.log(ss.itemuuid);
      }
    });
    if (bInitial === true) {
      if (find_noPlaylist) {
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:$translate.instant('PLAYLIST.SCHEDULE.MSG.MESSAGE_CONFIRM_ADD_NOTINCLUDE_PLAYLISTITEM'), modalClass:'modal-success'},
        function () {
        });
      }
    }
  }

  var m_bEditedItem = false;
  function openScheduleItem(list) {
    var modalInstance = $modal.open({
      templateUrl: 'app/schedule/schedule-edit.html',
      controller: 'ScheduleEditCtrl',
//      windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        RafaleScheduleData: function() {
          return {
            data : list,
            list: $scope.scheduleItems
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
      m_bEditedItem = true;

      if (results.itemuuid) {
        //update
        var index = _.findIndex($scope.scheduleItems, function(item) {
          if (item.itemuuid === results.itemuuid) {
            return true;
          }
        });
        if (index !== -1) {
          $scope.scheduleItems[index] = results;
        }
        // createScheduleItem(results, function(){
        //   console.log("createScheduleItem1");
        // });
      } else {
        //new
        $scope.scheduleItems.push(results);
      }
      $timeout(function(){
        $scope.scheduleItems = sortSchedule($scope.scheduleItems);
        updateScheduleItem(false , results.itemuuid);
      });

    }, function() {
      //
      $timeout(function(){
        // $scope.playlists = [];
        // $scope.getPlaylist({playlistId:$scope.selectedRow ? $scope.selectedRow.playlistId : null});
      });
    });
  }



  $scope.createScheduleListItem = function() {
    //新規作成
    var id = null;
    if (LimaScheduleItemData.data) {
      id = LimaScheduleItemData.data.scheduleListId;
    }
    openScheduleItem({scheduleListId:id});

  }

  $scope.editScheduleListItem = function(list) {
    //編集
    openScheduleItem($scope.selectedRow);
  }

  $scope.deleteScheduleListItem = function(list) {
    //削除
    if ($scope.selectedRow) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('CONTENT.TRANSCODE_LIST.CONFIRM_DELETE_TEXT')}),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        //削除
        if ($scope.selectedRow.scheduleListItemId) {
          m_bEditedItem = true;
          $scope.scheduleItems_delete.push($scope.selectedRow);
        }
        $timeout( function() {
          $scope.scheduleItems = _.filter($scope.scheduleItems, function(item) {
            if (!angular.equals(item,$scope.selectedRow)) {
              return true;
            }
          });
          $scope.selectedRow = null;
        });
      });
    }
  }

  $scope.renameSchecule = function() {
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/newName.html',
      controller: 'NewNameCtrl',
      windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaNewNameData: function() {
          return {
            maxStringLength : 255,
            name : $scope.schedule.name
          };
        }
      }
    });
    modalInstance.result.then(function(resNewName) {
      console.log(resNewName);

      var postCreate = {
        name : resNewName.name,
        scheduleListId: $scope.schedule.scheduleListId
      }
      $http.put('/api/v1/schedules/list', postCreate).
      success(function(err) {
        $timeout(function(){
          $scope.schedule.name = resNewName.name;
        });
      });
    }, function() {
      //
    });

  };

  function getOldSchedule() {
    return _.filter($scope.scheduleItems, function(item) {
      if (item.date) {
        var today = new Date();
        var arrTime1 = item.startTime.split(':');
        var tempday = new Date(item.date);
        var setday1 = new Date(tempday.getFullYear(), tempday.getMonth(), tempday.getDate(), arrTime1[0], arrTime1[1]);
        if (setday1 < today) {
          return true;
        }
      }
    })
  }

  function deleteSchedules(items, callback){
    var requests = items.map(function (item) {
      var deferred = $q.defer();

      $http.delete('/api/v1/schedules/list/' + item.scheduleListId + '/' + item.scheduleListItemId).
      success(function(datas) {
      })
      .finally(function () {
        console.log('end');
        deferred.resolve();
      }) ;

      return deferred.promise;
    });

    $q.all(requests).finally(function (results) {
      getScheduleItemsOnly(function(datas){
        callback(datas);
      });
    });
  }

  function confirmOldSchedule(items){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('PLAYLIST.SCHEDULE.MSG.MESSAGE_CONFIRM_DELETE_OLD_SCHEDULE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) {
        //削除せずに終わる
        $modalInstance.close({scheduleItems : $scope.scheduleItems});
        $modalInstance.dismiss({scheduleItems : $scope.scheduleItems});
        return;
      }
      //削除
      deleteSchedules(items, function(datas){
        $modalInstance.close({scheduleItems : datas});
        $modalInstance.dismiss({scheduleItems : datas});
      });
    });
  }

  $scope.openNewNameDialog = function(callback){
    var modalInstance = $modal.open({
      templateUrl: 'app/playlist/newName.html',
      controller: 'NewNameCtrl',
      windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        LimaNewNameData: function() {
          return {
            maxStringLength : 255,
            name : $scope.formatDate(new Date() , 'YYYYMMDD_hhmmss')
          };
        }
      }
    });
    modalInstance.result.then(function(resNewName) {
      console.log(resNewName);

      var postCreate = {
        name : resNewName.name
      }
      $http.post('/api/v1/schedules/list', postCreate).
      success(function(datas) {
        if (datas) {
          callback({scheduleListId:datas, name: resNewName.name});
        } else {
          callback(null);
        }
      });
    }, function() {
      //
      callback(null);
    });
  }

  $scope.checkNewScheduleItemName = function(list) {
    var defer = $q.defer();

    if (!list) {
      //New => 名前つけてから渡す
       //new
      $scope.openNewNameDialog(function(result){
        if (!result) {
          defer.reject();
        } else {
          defer.resolve(result);
        }
       });
    } else {
      defer.resolve(list);
    }
    return defer.promise;
  }

  $scope.saveAll = function(items, callback) {
    var requests = items.map(function (item) {
      var deferred = $q.defer();

      createScheduleItem(item, function(){
        deferred.resolve();
      }) ;

      return deferred.promise;
    });

    $q.all(requests).finally(function (results) {
        callback();
    });

  }
  $scope.deleteAll = function(items, callback){
    var requests = items.map(function (item) {
      var deferred = $q.defer();

      $http.delete('/api/v1/schedules/list/' + item.scheduleListId + '/' + item.scheduleListItemId).
      success(function(datas) {
        //削除
        deferred.resolve();
      }) ;

      return deferred.promise;
    });

    $q.all(requests).finally(function (results) {
        callback();
    });

  }

  $scope.saveAndClose = function(){
    //save
    var list = LimaScheduleItemData.data;
    var promise = $scope.checkNewScheduleItemName(list);
    promise.then(
      function(resultItemName) { //成功
        if (!list) {
          list = resultItemName;
        }
        _.forEach($scope.scheduleItems, function(item){
          if (item.scheduleListId) {
            if (item.scheduleListId !== list.scheduleListId) {
              console.warn("scheduleListId diff!!");
            }
          }
          item.scheduleListId = list.scheduleListId;
        });

        $scope.deleteAll($scope.scheduleItems_delete , function() {
          $scope.saveAll($scope.scheduleItems, function(){
            var items = getOldSchedule();
            if (items && items.length) {
              confirmOldSchedule(items);
            } else {
              $modalInstance.close({scheduleItems : $scope.scheduleItems});
              $modalInstance.dismiss({scheduleItems : $scope.scheduleItems});
            }

          });
        })

      },
      function() { //失敗
        //なにもしない
      }
    );
  };

  $scope.close = function(){
    if (m_bEditedItem) {
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('PLAYLIST.MESSAGE_NO_SAVE_CLOSE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;

        $modalInstance.close({scheduleItems : $scope.scheduleItems_initial});
        $modalInstance.dismiss({scheduleItems : $scope.scheduleItems_initial});
      });
    } else {
      $modalInstance.close({scheduleItems : $scope.scheduleItems_initial});
      $modalInstance.dismiss({scheduleItems : $scope.scheduleItems_initial});

    }
  };
}]);
