'use strict';

var app = angular.module('limaApp');

app.controller('PlaylistCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$location','$window','$cookies', '$uibModal', 'Modal','$q', '$translate','$filter','$sce','limaOptionService','limaDiskService', 'limaPlaylistService', 'limaSocketService','limaShowWaitDialog','ContentUtil',
function ($scope, socket, Auth,$http, $timeout,$location,$window,$cookies, $modal, Modal, $q, $translate,$filter,$sce, limaOptionService,limaDiskService,limaPlaylistService, limaSocketService,limaShowWaitDialog, ContentUtil) {

  $window.document.title  = $translate.instant('NAVIGATION.PLAYLIST');

  var m_sysInfo = {}
  var m_arrPlaylistByOldLayout = [];  //保存しなおしが必要なプレイリスト
  Auth.systemProperties().then(function (data) {
    $scope.systemDebug = data['system.debug'] || 0;

    m_sysInfo = {};
    m_sysInfo.enableApplyMessage = data['setting.system.enableApplyMessage'] || false;

    m_arrPlaylistByOldLayout = [];
    m_arrPlaylistByOldLayout = data['setting.playlist.old.layout'] || [];


  });
  $scope.hasFunction = Auth.hasFunction;


  $scope.setting = {};
  $scope.setting.enableSchedule = false;

  $scope.schedulesRepeat = [];
  $scope.getschedulesRepeat = function(callback) {
    $http.get('/api/v1/schedules/repeat').
    success(function(datas) {
      $scope.schedulesRepeat  = datas;
      if (callback) {
        callback();
      }
    });
  }
  $scope.getschedulesRepeat();

  $scope.dbsetting = null;
  $scope.isEnablePlaylistOptionMenu = false;
  $scope.enableApplyMessage  = undefined;
  $scope.getdbsetting = function(callback) {
    var companyId = Auth.getCurrentUser().companyId;
    $http.get('/api/v1/setting' + '?companyId=' + companyId).
    success(function(datas) {
      if (datas.length) {
        _.find(datas, function(dt) {
          if (dt.name === "setting.ui") {
            $scope.dbsetting  = dt;
          };
        });
      }
      if ($scope.dbsetting) {
        if ($scope.dbsetting.value.exLink) {
          $scope.isEnablePlaylistOptionMenu = true;
        }
        if ($scope.dbsetting.value.enableApplyMessage ) {
          $scope.enableApplyMessage = true;
        }
      }
      if (callback) {
        callback();
      }
    });
  }
  $scope.getdbsetting();

  function isEnableApplyMessage() {
    if (!_.isUndefined($scope.enableApplyMessage)) {
      return $scope.enableApplyMessage;
    } else {
      //システムの設定はどうか
      return m_sysInfo.enableApplyMessage;
    }
  }

  //再生URL取得
  function copyToClipBoard(text , callback) {

    //コピーしたいテキストを持つ要素を取得
//    let targetEle = document.getElementById('textURL');

    //ブラウザの対応状況で分岐
    if(navigator.clipboard){
      //クリップボードにコピーを実行
      navigator.clipboard.writeText(text).then(function(){
        //コピーに成功したときの処理...
        //console.log('copied.');
        callback(true);
      },function(){
        //コピーに失敗したときの処理...
        //console.log('clipboard denied.');
        callback(false);
      });
    }else if(window.clipboardData){
      //IE用の処理...もうないので
      callback(false);

    }else if(document.execCommand){

      //「input」要素を生成して属性値とstyleを設定
      let inputEle = document.createElement('input');
      inputEle.setAttribute('type', 'text');
      inputEle.setAttribute('value', text);
      inputEle.style.position = 'fixed';
      inputEle.style.left = '-100%';
      //「input」要素をbody内に挿入
      document.body.appendChild(inputEle);
      //テキストを選択状態にする
      inputEle.select();
      //コピーを実行
      let execResult = document.execCommand('copy');
      //「input」要素を削除
      inputEle.parentNode.removeChild(inputEle);
      //結果をコンソールに表示
      //console.log('execCommand : '+execResult);
      callback(execResult);

    }else{
      //クリップボードにアクセスできなかった場合の処理
      console.log('Can not copy. No permission and execCommand died.');
      callback(false);
    }

  }

  $scope.getPlayURL = function(list){
    if ($scope.dbsetting.value) {
      if ($scope.dbsetting.value.exLink) {

        var validSec = 1 * 24 * 60 * 60;
        $http.get('/api/v1/playlists/' + list.playlistId + '/external?validSec=' + validSec).
        success(function(datas) {
          //datas.url;
          copyToClipBoard(datas.url, function(ret) {
            Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
            html:ret ? "再生URLをクリップボードにコピーしました。" : '作成に失敗しました。',
            modalClass:'modal-information'});

          });
        });

        // var modalInstance = $modal.open({
        //   templateUrl: 'app/playlist/dialog/shareURL.html',
        //   controller: 'ShareURLCtrl',
        //   windowClass: 'modal-preview-window',
        //   backdrop: 'static', keyboard: false,
        //   size: 'md',
        //   resolve: {
        //     LimaShareURL: function() {
        //       return {
        //         playlist: list
        //       };
        //     }
        //   }
        // });
        // modalInstance.result.then(function(results) {
        //   console.log("");
        // }, function(results) {
        //   //
        // });
      }
    }
  }

  //deviceplayinfo
  $scope.devicePlayInfo = [];
  $scope.getDevicePlayInfo = function(callback) {
    $scope.devicePlayInfo = [];
    $http.get('/api/v1/device/playInfo').
    success(function(datas) {
      $scope.devicePlayInfo  = datas;
      console.log(datas);

      if (callback) {
        callback();
      }
    });
  }
  $scope.getDevicePlayInfo();

  $scope.getDevicePlayInfoName = function(list){
    var findItems = _.filter($scope.devicePlayInfo , function(item){
      if ( item.playlistId === list.playlistId){
        return true;
      }
    });
    var arrName = [];
    _.forEach(findItems, function(findItem){
      _.find($scope.devices.items , function(dv){
        if (dv.deviceId === findItem.deviceId) {
          arrName.push( dv.name );
          return true;
        }
      });
    });

    var limit = 200;
    list.deviceInfoNames = '';
    _.find(arrName , function(name, index){
      list.deviceInfoNames += name;
      if (list.deviceInfoNames.length > limit) {
//        list.deviceInfoNames += ' ...';
        return true;
      } else {
        if (index !== (arrName.length-1)) {
          list.deviceInfoNames += ' / ';
        }
      }
    })
    // list.deviceInfoNames = arrName.join(' / ');
    //list.deviceInfoNames = list.deviceInfoNames.length > limit ? (list.deviceInfoNames).slice(0,limit)+"…" : list.deviceInfoNames;

//    list.deviceInfoNames_all = arrName.join("\r\n");
    list.deviceInfoNames_all = arrName.join(" / ");
  }


  $scope.getDevicePlayInfoFromPlaylist = function(){
    $timeout(function() {
      $scope.getDevicePlayInfo(function() {
        getDevice(function() {
          _.forEach($scope.playlists, function(list) {
            $scope.getDevicePlayInfoName(list);
          })
        });
      });
    });
  }

  $scope.playlists = [];
  $scope.getPlaylist = function(selectInfo) {//playlistId or index
    $scope.selectedRow = null;
    $http.get('/api/v1/playlists').
    success(function(datas) {
      //プレイリストだけを抽出
      $scope.playlists = datas.playlists;
      const defaultTimezoneOffset = new Date().getTimezoneOffset()*-1;  //min
      if ($scope.dbtimezoneString === 'UTC') {  //S3 AURORA
        $scope.playlists = _.forEach(datas.playlists, function(list){
          //UTC->SYSTEM
          const defaultTimezoneOffset = new Date().getTimezoneOffset()*-1;
          var dt = new Date(list.updatedTime);
          dt.setMinutes(dt.getMinutes() + defaultTimezoneOffset);
          list.updatedTime = dt;
        });
      }
      //新しいもの順にソート
      $scope.playlists = _.sortBy($scope.playlists, function(list) {
        return new Date(list.updatedTime);
      }).reverse();

      //選択
      if (selectInfo) {
        if (selectInfo.playlistId) {
          var find = _.find($scope.playlists, function(list){
            return list.playlistId === selectInfo.playlistId;
          });
          $scope.rowHighilited(find);
        }
        if (selectInfo.index >= 0) {
          if ($scope.playlists.length > selectInfo.index ) {
            $scope.rowHighilited($scope.playlists[selectInfo.index]);
          }
        }
      }

      _.forEach($scope.playlists, function(list) {
        $http.get('/api/v1/playlists/' + list.playlistId + '/contents').
        success(function(cont) {
          list.contents = cont;

          _.find(m_arrPlaylistByOldLayout, function(ll) {
            if (ll === list.playlistId) {
              list.isOldLayout = true;
              return true;
            }
          })
        });
      });

      //デバイス再生状況表示
      $scope.getDevicePlayInfoFromPlaylist();

    });
  }

  $scope.durationToTime = function(duration) {
    try {
      var hh = Math.floor(duration / 3600);
      var mm = Math.floor((duration -= hh * 3600) / 60);
      var ss = duration -= mm * 60;
      return sign + ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ':' + ('0' + ss).slice(-2);
    } catch (ex) {
      return '';//'N/A';
    }
  }
  var m_arrlayout= [];
  var m_arrregions = [];
  $scope.getLayoutInfo = function(callback) {
    $http.get('/api/v1/layouts').
    success(function(datas) {
      m_arrlayout = datas.layouts;

      $http.get('/api/v1/regions').
      success(function(datas) {
        m_arrregions = datas.regions;
        callback();
      });
    });
  }

  $scope.dbtimezoneString = "";
  $scope.getdbTimezone = function() {
    $http.get('/api/v1/system-info/db').
    success(function(datas) {
      console.log(datas);
      _.find(datas,function(dt){
        if (dt.Variable_name === 'system_time_zone') {
          $scope.dbtimezoneString = dt.Value;
          return true;
        }
      });

      $scope.getLayoutInfo(function() {
        $scope.getPlaylist();
      })
    });
  }
  $scope.getdbTimezone();


  $scope.formatTime = function(seconds) {
    var result = "";
    //時間計算
    var hour = Math.floor((seconds / 60) / 60);
    var min = Math.floor((seconds / 60) % 60);
    var sec = Math.floor(seconds % 60);
    //フォーマット


    result += ('00' + hour).slice(-2) + ":";
    result += ('00' + min).slice(-2) + ":";
    result += ('00' + sec).slice(-2);
    return result;
  }

  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }

  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };


  $scope.channelsInfo = {};
  $scope.channelsInfo.channels = [];
  function getChannels() {
    $scope.channelsInfo.channels = [];
    $http.get('/api/v1/channels').
    success(function(datas) {
      if ($scope.setting.enableSchedule) {
        //スケジュール用のチャンネルだけにする
        datas.channels = _.filter(datas.channels, function(ch){return ch.groupId ? false : true;});
      }
      $scope.channelsInfo.channels = datas.channels;
      // _.forEach($scope.channelsInfo.channels, function(ch) {
      //   $http.get('/api/v1/lima/layout/dcm/' + ch.id + '/layout').
      //   success(function(chInfo) {
      //     ch.chInfo = chInfo;
      //   });
      // });

    });

  }

  $scope.applyOption={};
  $scope.applyOption.options=[];
  $scope.applyOption.options.push({name:$translate.instant('PLAYLIST.TABLE.DEVICE')  , type:'device'});
  $scope.applyOption.options.push({name:$translate.instant('DEVICE.GROUP') , type:'group'});
  $scope.applyOption.select = $scope.applyOption.options[0];



  var optionService = null;
  $scope.isDeviceGroupEnable = false;
  $scope.maxPlaylistCount = 10;
  limaOptionService.getOptionService(function(result) {
    optionService = result;
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.isDeviceGroupEnable = true;
    }
    find = _.find(result, function(op){
      return op.name === 'AddPlaylist' ? true : false;
    });
    if (find) {
      $scope.maxPlaylistCount += find.amount * 10;
    }
    calcDeviceTransferedSize();

  });

  $scope.packageType = 0;
  limaOptionService.getpackageType(function(result){
    if (result) {
      $scope.packageType = result.packageType;
    }
  });

  ///////////////////
  //ディスク情報取得
  $scope.awsDiskUsageInMB = {};
  function getDiskInfo() {
    limaDiskService.getDiskInfo(function(result){
      if (result.awsDiskUsageInMB.use){
        $scope.awsDiskUsageInMB.use = result.awsDiskUsageInMB.use;
        if ($scope.awsDiskUsageInMB.use.sizeByte / $scope.awsDiskUsageInMB.licensed.data > 0.85 )
        {
          var sMes = $translate.instant('PLAYLIST.MESSAGE_LITTLE_DISK_IN_SERVER');
          sMes += "(";
          sMes += $filter('limaDisplayBytes')($scope.awsDiskUsageInMB.use.sizeByte);
          sMes +=" / "
          sMes += $filter('limaDisplayBytes')( $scope.awsDiskUsageInMB.licensed.data);
          sMes += ")";

          sMes += "<br>";
          sMes += $translate.instant('PLAYLIST.MESSAGE_LITTLE_DISK_IN_SERVER2');
          $scope.message.DiskAlert = sMes;

        }
    } else {
      // $scope.diskUsageInMB = result.diskUsageInMB;
      // if ($scope.diskUsageInMB.use.sizeByte / $scope.diskUsageInMB.licensed.data > 85.0 ) {
      //   $scope.message.DiskAlert = $translate.instant('PLAYLIST.MESSAGE_LITTLE_DISK_IN_SERVER');
      // }

    }
    });
  }
  ///////////////////

  $scope.deviceTransferSizeInMB = {};
  $scope.deviceTransferSizeInMB.warning = {message:""};

  function calcDeviceTransferedSize() {
    var TransferMaxSize = limaOptionService.getTransferMaxSize(optionService);
    var TransferWarningRemainSize = limaDiskService.getTransferWarningRemainSize();

    limaDiskService.getTransferInfo(function(data){
      if (TransferMaxSize <= data.size ) {
        $scope.deviceTransferSizeInMB.warning.message = $translate.instant('PLAYLIST.MESSAGE_NO_DISK_TO_REFLECT');
      }
      else if (TransferMaxSize - data.size < TransferWarningRemainSize ) {
        $scope.deviceTransferSizeInMB.warning.message = $translate.instant('PLAYLIST.MESSAGE_LITTLE_DISK_TO_REFLECT');
      }
      $scope.awsDiskUsageInMB.licensed = {size: $filter('number')(Math.round(TransferMaxSize / 1024 / 1024)), data: TransferMaxSize};

      getDiskInfo();
    });
  }

  $scope.deviceGroups = [];
  function getDeviceGroups(callback) {
    $scope.deviceGroups = [];
    $http.get('/api/v1/device/groups').
    success(function(datas) {
      //自分が閲覧できるグループを確認
      if (Auth.isAdmin()) {
        $scope.deviceGroups.groups = datas;
      } else {
        var userId = Auth.getCurrentUser().id;
        $scope.deviceGroups.groups = _.filter(datas, function(gr){
          if (gr.typeDefault) {
            //All
            return true;
          } else {
            var userIds = JSON.parse(gr.userIds);
            var findUser = _.find(userIds, function(id) {
              return id === userId;
            });
            return findUser;
          }
        });
      }

      //$scope.deviceGroups.groups = datas;
      $scope.deviceGroups.showgroups = _.filter($scope.deviceGroups.groups, function(dt){
        if (!dt.typeDefault) {
          return true;
        }
      });
      if ($scope.deviceGroups.showgroups.length){
        $scope.deviceGroups.select = $scope.deviceGroups.showgroups[0];
      }
      callback();
    });

  }
//  getDeviceGroups();

  var m_initDevice = false;
  function getDevice(callback) {
    if (m_initDevice) {
      if (callback) {
        callback();
      }
      return;
    }
    $scope.devices = [];
    $http.get('/api/v1/device/device').
    success(function(datas) {
      getDeviceGroups(function() {
        if (Auth.isAdmin()) {
          $scope.devices.items = datas;
        } else {
          $scope.devices.items = _.filter(datas, function(dt){
            if (dt.allowed) {
              //グループに含まれるデバイスだけにする
              var findGrp = _.find($scope.deviceGroups.groups, function(gp) {
                var deviceIds = JSON.parse(gp.deviceIds);
                var findDevice = _.find(deviceIds,function(dvId) {
                  return dvId === dt.deviceId;
                });
                return findDevice;
              });
              return findGrp;
            }
          });
        }

        if ($scope.devices.items.length) {
          _.find($scope.deviceGroups.groups, function(gr){
            if (gr.typeDefault) {
              var groupInfo = angular.copy(gr);
              groupInfo.enableGroup = false;
              var all = {
                name : $translate.instant('PLAYLIST.DEVICE_ALL'),
                groupIds: [groupInfo],
                deviceId: -1
              };
              $scope.devices.items.unshift(all);
              gr.name = $translate.instant('PLAYLIST.DEVICE_ALL');

              $scope.devices.select = all;
              return true;
            }
          });

        }

        getChannels();
        m_initDevice = true;

        if (callback) {
          callback();
        }

      });
    });

  }


  $scope.getDevicegroupName = function(list , bGroup){
    var findItems = _.filter($scope.schedulesRepeat , function(item){
      if ( item.playlistId === list.playlistId){
        return true;
      }
    });
    var arrName = [];
    _.forEach(findItems, function(findItem){
      if (findItem) {
        var findItem2 = _.find($scope.channelsInfo.channels , function(item2){
          if ( item2.channelId === findItem.channelId){
            return true;
          }
        });
        var findItem3 = null;
        if (findItem2) {
          findItem3 = _.find($scope.deviceGroups.groups , function(item3){
            if ( item3.groupId === findItem2.groupId){
              return true;
            }
          });
          if (findItem3) {
            if (bGroup) {
              arrName.push( findItem3.name );
            } else {
              if (findItem3.typeDefault) {  //すべてはデバイス・グループ関係ない
                arrName.push( findItem3.name );
              }
            }
          } else {
            if (!bGroup) {
              //デバイスの単独グループを探す
              _.find($scope.devices.items , function(dv){
                var find = _.find(dv.groupIds , function(gp){
                  if ( gp.groupId === findItem2.groupId){
                    return true;
                  }
                });
                if (find) {
                  arrName.push( dv.name );
                  return true;
                }
              });
            }
          }
        }
      }
    });

    return arrName.join(' / ');
  }




  var deletePlaylistHtml = function(selected) {
    var deferred = $q.defer();
    var promise = deferred.promise;

      var requests = _.forEach(selected, function(item){
      promise = promise.finally(function () {
        return $http
          .delete('/api/v1/playlists/html/' + item.playlistId)
          .finally(function () {
            console.log(item.playlistId);
          })
        ;
      });
    });

    promise = promise.finally(function () {
      console.log('end delete');
      $timeout(function(){
        $scope.getPlaylist();
        limaShowWaitDialog.hideWait($translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('NAVIGATION.MESSAGE_DELETED'));
      });
    });

    deferred.resolve();

  }

  //削除前の処理：使ってないコンテンツを取得
  function getContentsForDeletePlaylist(selected){

    var arr = []; //上位から指定されたプレイリストに含まれるコンテンツ
    _.forEach(selected , function(pl) {
      arr = arr.concat(pl.contents);
    });

    //上位から指定されたプレイリスト以外のプレイリスト列挙
    var arrPl_other = _.filter($scope.playlists , function(pl) {
      return !_.find(selected , function(sel_pl) {
        return sel_pl.playlistId === pl.playlistId;
      });
    })

    //上位から指定されたプレイリスト以外で使われていないもの
    var arrNotUsedContents = _.filter(arr , function(cont) {
      var findPl = _.find(arrPl_other , function(pl) {
        var findCont = _.find(pl.contents , function(pl_cont){
          return pl_cont.contentId === cont.contentId;
        });
        return findCont;
      });
      return !findPl ? true : false;
    })

    //自動更新コンテンツは省く
    arrNotUsedContents = _.filter(arrNotUsedContents , function(cont) {
      return cont.type !== ContentUtil.CONTENT_AUTOUPDATED;
    });
    //外部入力コンテンツは省く
    arrNotUsedContents = _.filter(arrNotUsedContents , function(cont) {
      return cont.contentId !== "-exinput";
    });

    //arrNotUsedContentsには同じContentIdが入っていることがあるので、重複削除
    const uniqueArray = arrNotUsedContents.filter((element, index, self) => self.findIndex(e => e.contentId === element.contentId) === index);

    return uniqueArray;
  }

  function checkDeletePlaylistContent(selected , callback) {

    var arrNotUsedContents = getContentsForDeletePlaylist(selected);

    if (arrNotUsedContents.length) {

      var arrTitle = [];
      _.forEach(arrNotUsedContents , function(cont) {
        arrTitle.push("<li>" + cont.title + "</li>");
      })
      var mes = "削除するプレイリストには、ほかのプレイリストで使われていないコンテンツが含まれています。<br>これらのコンテンツも削除しますか？";
      mes +="<br><br>";
      mes += arrTitle.join("");

      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: mes,//$translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('PLAYLIST.SELECTED_PLAYLIST')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (yes){
          callback(arrNotUsedContents);
        } else {
          callback([]);
        }
      });
    } else {
      callback([]);
    }
  }

  function deleteContents(contents, callback){
    if (contents.length) {
      //コンテンツ削除
      var async = require('async');
      async.forEachOfSeries(contents , function(cont,index,next) {
        $http
        .delete('/api/v1/content/contents/' + cont.contentId)
        .finally(function () {
          next(null);
        })
      }, function (err) {
        ///おわり
        callback(null);
      }) ;
    } else {
      callback(null);
    }
  }

  var deletePlaylistFunc = function(selected) {

    //削除
    limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('PLAYLIST.MESSAGE_DELETING'));
    {
      var deferred = $q.defer();
      var promise = deferred.promise;

        var requests = _.forEach(selected, function(item){
        promise = promise.finally(function () {
          return $http.delete('/api/v1/playlists/' + item.playlistId).finally(function () {
            console.log(item.id);
          });
        });
      });

      promise = promise.finally(function () {
        console.log('end delete');
        $timeout(function(){
          deletePlaylistHtml(selected);
        });
      });

      deferred.resolve();

    }
  }

  $scope.deletePlaylist = function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('PLAYLIST.SELECTED_PLAYLIST')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      //削除
      var  selected = _.filter($scope.playlists, function(tw){return tw.checked;});

      checkDeletePlaylistContent(selected , function(arrNeedTodeleteConts) {
        deletePlaylistFunc(selected);
        deleteContents(arrNeedTodeleteConts , function() {

        })
      });
    });
  };

  $scope.deletePlaylistFromDlg = function() {
    var datas = [];
    _.forEach($scope.playlists , function(dt) {
      datas.push({
        titleArray : [dt.playlistName],
        id : dt.playlistId
      })
    });


    var modalInstance = $modal.open({
      templateUrl: 'components/dialog/dlgSelectItem.html',
      controller: 'DlgSelectItemCtrl',
      backdrop: 'static', keyboard: false,
      size: 'md',
      resolve: {
        selectItem: function() {
          return {
            titleArray:[$translate.instant("PLAYLIST.TABLE.NAME")],
            dlgTitle : $translate.instant('NAVIGATION.PLAYLIST'),
            btnName : $translate.instant('COMMON.DELETE'),
            datas : datas
          };
        }
      }
    });
    modalInstance.result.then(function(results) {

      var  selected = _.filter($scope.playlists, function(tw){
        return _.find(results.selected , function(dt){
          return dt.id === tw.playlistId;
        })
      });
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:$translate.instant('PLAYLIST.SELECTED_PLAYLIST')}),//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
        btnClassYes: 'btn-default',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-danger',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (!yes) return;
        //削除
        checkDeletePlaylistContent(selected, function(arrNeedTodeleteConts) {
          deletePlaylistFunc(selected);
          deleteContents(arrNeedTodeleteConts , function() {

          })

        });

      });
    }, function() {
      //
    });

  }

  function getCopyName(baseName) {
    var newName = baseName;
    var find = _.find($scope.playlists, function(item) {
      return item.playlistName === newName;
    });
    if (find) {
      var index = 1;
      while(1){
        newName = baseName + "(" + index + ')';
        var find = _.find($scope.schedulelists, function(item) {
          return item.playlistName === newName;
        });
        if (find) {
          index++;
        } else {
          break;  //この名前で被りなし
        }
      }
    }
    return newName;
  }

  function calcRegion(playlistId, layoutId ){
    var findLayout = _.find(m_arrlayout, function(ly){
      if (ly.layoutId === layoutId) {return true;}
    })
    var arrRG = _.filter(m_arrregions, function(region){
      if (region.layoutId === layoutId) {
        return true;
      }
    });
    findLayout.regions = _.cloneDeep(arrRG);

    var findContentInRegion = false;  //旧バージョン（リージョンの概念無しで作られたもの）かどうか判断させる
    _.forEach(findLayout.regions , function(rg) {

      var init = true;
      if (rg) {
        if (rg.contentsInfo) {
          if (rg.contentsInfo.length) {
            init = false;
          }
        }
        if (init) {
          rg.contentsInfo = [];
        }
        if (rg.contentsRegionInfo){
          _.forEach(rg.contentsRegionInfo , function(rginfo) {
            if (playlistId === rginfo.playlistId){
              var cont_copy = angular.copy(rginfo);
              cont_copy.id = cont_copy.contentId;
              cont_copy.playIndex = rginfo.playIndex;
              cont_copy.checked = rginfo.enabled ? true : false;
              rg.contentsInfo.push(cont_copy);
              findContentInRegion = true;
            }
          });
          rg.contentsInfo = _.sortBy(rg.contentsInfo, function(ly) {
            return ly.playIndex;
          });

        }
      }
    })
    return findLayout;
  }

  $scope.copyPlaylist = function() {
    //コピー
    var  selected = _.filter($scope.playlists, function(tw){return tw.checked;});

    if ($scope.playlists.length >= $scope.maxPlaylistCount) {

      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
      modalClass:'modal-danger'});
      return;
    }

    var maxStringLength = 127;
    var copyName = getCopyName(selected[0].playlistName + "_コピー");
    if (copyName.length > maxStringLength) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:"コピー元のプレイリスト名が長すぎるため、コピーできません。<br>プレイリスト名の長さを短くしてから再度コピーしてください。",
      modalClass:'modal-danger'});
      return;
    }


    limaShowWaitDialog.showWait($scope,$translate.instant('NAVIGATION.PLAYLIST'),$translate.instant('CONTENT.PLAYLIST.MESSAGE_COPYING'));

    $http.get('/api/v1/playlists/' + selected[0].playlistId + '/contents').
    success(function(datas) {
      var postdata = {
        playlistName:copyName,
        playlistLength: selected[0].playlistLength,  //ミリ秒
        layoutId:selected[0].layoutId,
        playlistSetting: selected[0].setting,
        thumbUrl : ""
      };

      postdata.contentInfo = [];
      _.forEach(datas, function(dt){
        postdata.contentInfo.push({contentId:dt.contentId , index: dt.playIndex, enabled:dt.enabled, fixedRegionId:dt.fixedRegionId});
      })

      var findLayout = calcRegion(selected[0].playlistId, selected[0].layoutId);
			var findContentInRegion = false;  //旧バージョン（リージョンの概念無しで作られたもの）かどうか判断させる

      //############################
      ////toDo layout!!!!
      if (findLayout) {
        postdata.regions = findLayout.regions;

        _.forEach(postdata.regions , function(rg) {
          var i = 0;
          rg.contentsRegionInfo = [];
          rg.contentsInfo = _.forEach(rg.contentsInfo , function(cont,index ) {
            cont.playIndex = index;
            if (cont.checked) {
              cont.enabled = cont.checked;
            }
          });
          if (rg.contentsInfo.length) {
            findContentInRegion = true;
          }
        })

          if (!findContentInRegion) {
            if (findLayout.regions.length > 0) {
              if (postdata.contentInfo.length) {
                //プレイリストにコンテンツ登録情報はあるが
                //リージョンに登録がない＝＞旧バージョンでの保存
                ///一つ目のリージョンに全部入れておく
                findLayout.regions[0].contentsInfo = [];
                findLayout.regions[0].contentsInfo = findLayout.regions[0].contentsInfo.concat(postdata.contentInfo);
              }
            }
          }


      }

      var httpParam = {
        url: '/api/v1/playlists',
        method: "POST",
        data: postdata
        };

        $http(httpParam)
        .success(function(res) {

          $http.get('/api/v1/regions').
          success(function(rg_datas) {
            var findRg = _.find(rg_datas.regions , function(rg) {
              if (rg.layoutId === selected[0].layoutId && rg.fixed) {
                return true;
              }
            })
            var playIndex = -1;
            var findFixedCnt = _.find(postdata.contentInfo, function(cnt,index){
              playIndex = index
              return cnt.fixedRegionId;
            });
            if (findRg && findFixedCnt) {
              $http.put('/api/v1/regions/' + findRg.regionId + '/' + selected[0].playlistId + '/' + findFixedCnt.contentId + '/' + playIndex , {})
              .success(function(res2) {
                $timeout(function(){
                  console.log("fixあり")
                  $scope.getPlaylist();
                  limaShowWaitDialog.hideWait();
                });
              })
            } else {
              $timeout(function(){
                console.log("fixなし")
                $http.get('/api/v1/regions').
                success(function(datas) {
                  m_arrregions = datas.regions;
                  $scope.getPlaylist();
                });
                limaShowWaitDialog.hideWait();
              });
            }
          });


        });

    });
  };


  function getChannelIdFromDeviceGroupId(deviceGroupId,deviceId){
    var itemFind = _.find($scope.channelsInfo.channels, function(ch){
      if (ch.groupId === deviceGroupId) {
        return true;
      }
      // if (ch.deviceId === deviceId) {
      //   return true;
      // }
      return false;
    });
    if (itemFind) {
      return itemFind.channelId;
    }
    return null;
  }

  //リストが選択されたときの処理
  $scope.selectedRow = null;
  $scope.rowHighilited = function (list) {
    _.forEach($scope.playlists, function(item){
      item.checked = false;
    });
    list.checked = true;
    $scope.selectedRow = list;

  };

  function addToNoSchedule(playlistId, deviceGroupsId, deviceId) {

        //
    var selectedLists = [];
    if (playlistId) {
      selectedLists.push({playlistId:playlistId});
    } else {
      selectedLists = _.filter($scope.playlists, function(p){return p.checked;});
    }

    var findTypeDefaultGroup = _.find($scope.deviceGroups.groups, function(gr){
      if (gr.typeDefault) {
        return true;
      }
    });

    var promise = limaPlaylistService.UpdatePlaylistHTML(selectedLists[0].playlistId ,$scope.playlists );
    promise.then(
      function() { //成功
        if (!deviceGroupsId && !deviceId) {
          if ($scope.applyOption.select.type==='device') {
            var find = _.find($scope.devices.select.groupIds, function(gr){
              return !gr.enableGroup ? true : false;
            });
            if (find) {
              deviceGroupsId = find.groupId;
            }
          } else {
            deviceGroupsId = $scope.deviceGroups.select.groupId;
          }
        }
        if (deviceId) {
          _.find($scope.devices.items,function(item){
            if (item.deviceId ===deviceId){
              var find = _.find(item.groupIds, function(gr){
                return !gr.enableGroup ? true : false;
              });
              if (find) {
                deviceGroupsId = find.groupId;
              }
              return;
            }
          });
        }

        var channelId = getChannelIdFromDeviceGroupId(deviceGroupsId, deviceId);
        if (channelId === null) {
          console.log("getChannelIdFromDeviceGroupId == > null" + " groupId = " + deviceGroupsId);
        }

        var typeDefault = false;
        if (findTypeDefaultGroup){
          if (findTypeDefaultGroup.groupId === deviceGroupsId) {
            typeDefault = true;
          }
        }

        var postdata = {};
        _.forEach(selectedLists, function(list , index) {
          postdata = {
            //ScheduleID : 0,
            playlistId : list.playlistId,
            channelId : channelId,
            PlayIndex : index,
            typeDefault: typeDefault
          };

          $http.post('/api/v1/schedules/repeat' , postdata)
          .success(function(res2) {
            //console.log(res2.id);
            // Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
            // html:'登録しました。',//$translate.instant('CONTENT.ATTACHMENT.CHECK_SHOW_DETAIL'),
            // modalClass:'modal-success'});
            $scope.message.CreateUpdateSuccess = "";
            $scope.message.CreateSuccess = $translate.instant('PLAYLIST.MESSAGE_NOTIFIED_REFLECTION');
            TriggerAlertClose();


            limaSocketService.emitChannel({channelId:channelId,groupId:deviceGroupsId}, function(err){
             // $timeout(function(){
                console.log("更新");

              $timeout(function(){
                  $scope.schedulesRepeat = [];
                //repeat情報読み込み
                $scope.getschedulesRepeat();

              });

              //しばらくしてから転送量チェック
              $timeout(function() {
                calcDeviceTransferedSize();
              } , 10*1000);
            });
          });
        });
      },
      function() { //失敗
        //なにもしない
      }
    );


  };

  $scope.addToSchedule = function () {
    if (!$scope.setting.enableSchedule) {
      addToNoSchedule();
      return;
    }
    var date = $filter('date')(new Date($scope.selectSchedule.datecalender.getValue().dateValue), '');
    var hour = $scope.selectSchedule.periodDateTimeHour;
    var min = $scope.selectSchedule.periodDateTimeMin;

    // var playlist_id     = req.body.playlist_id      ;
    // var start_date_time = req.body.start_date_time  ;
    // var end_date_time   = req.body.end_date_time    ;
    // var repeat          = req.body.repeat           ;
    var selectedLists = _.filter($scope.playlists, function(p){return p.checked;});

    var startTime = new Date(date);
    var endTime = new Date(date);

    startTime.setHours(parseInt(hour[0].numberValue) , parseInt(min[0].numberValue), 0);
    endTime.setHours(parseInt(hour[1].numberValue)   , parseInt(min[1].numberValue), 0);

    var dur = endTime - startTime;  //miliSec
    var step = dur / selectedLists.length;

    var arrPost = [];
    var postdata = {};
    _.forEach(selectedLists, function(list , index) {
      postdata = {
        playlist_id : list.playlistId,
        schedule_index: 0,
        start_date_time:_.cloneDeep(startTime),
        end_date_time: _.cloneDeep(startTime),
        repeat : "off"
      };
      var sec = startTime.getMilliseconds() + index * step;
      postdata.start_date_time.setMilliseconds(sec);
      postdata.end_date_time.setMilliseconds(sec + step);
      postdata.start_date_time = postdata.start_date_time;
      postdata.end_date_time = postdata.end_date_time;

      $http.post('/api/v1/channels/' + $scope.channelsInfo.select.channelId + '/schedules' , postdata)
      .success(function(res2) {
        console.log(res2.id);
        Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
        html:$translate.instant('PLAYLIST.MESSAGE_SCHEDULE_TO_CHANNEL'),
        modalClass:'modal-success'},
        function () {
          if ($scope.bPopup) {
            $scope.closeCancel();
          }

        });


      });


    });



  }

  //反映するか確認する
  $scope.check_addToNoSchedule= function (playlistId, deviceGroupsId, deviceId) {
    if (isEnableApplyMessage()) {
      var applyName = "";
      if ($scope.applyOption.select.type==='device') {
        applyName = $scope.devices.select.name;
      } else {
        applyName = $scope.deviceGroups.select.name;
      }
      var findPl = null;
      if (playlistId){
        findPl = _.find($scope.playlists, function(list){
          return list.playlistId === Number(playlistId) ? true : false;
        });
      } else {
        findPl = _.find($scope.playlists, function(tw){return tw.checked;});
      }
      if (!findPl){
        return;
      }
      var message =  "<strong>" + findPl.playlistName + "</strong>を<br>" +
                      "<strong>" + applyName + "</strong>" + " に反映しますか?";

      var modalInstance = $modal.open({
        templateUrl: 'app/playlist/dialog/confirmCheck.html',
        controller: 'ConfirmCheckCtrl',
        windowClass: 'modal-preview-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          ConfirmCheck: function() {
            return {
              checked: false,
              messageHTML : message
            };
          }
        }
      });
      modalInstance.result.then(function(results) {
        console.log("");
      }, function(results) {
        //
        if (results === "done") {
          addToNoSchedule(playlistId, deviceGroupsId, deviceId);
        }
      });

    } else {
      addToNoSchedule(playlistId, deviceGroupsId, deviceId);
    }
  }

  var NewValue = (function () {
    var stringIsNumber = function (s) {
       if (_.isString(s)) s = Number(s);
       return _.isNumber(s) && !_.isNaN(s);
    };
    var NewValue = function (type, value, value2) {
      var self = this;
      self.type = type;
      var paramDate1 = value ? value : new Date();
      paramDate1.setSeconds(0);
      var paramDate2 = value2 ? value2 : _.cloneDeep(paramDate1);
      if (!value2) {
        paramDate2.setHours(paramDate1.getHours()+1);
      }
      paramDate2.setSeconds(0);
      self.numberValue = null;
      self.stringValue = null;
      self.dayOfWeekValue = [];
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_SUNDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_MONDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_TUESDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_WEDNESDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_THURSDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_FRIDAY')});
      self.dayOfWeekValue.push({enable: false , text: $translate.instant('DAY_OF_WEEK.SHORT_SATURDAY')});
      self.dateValue = {
        date: paramDate1,
        isOpen: false,
        openCalender: function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          self.dateValue.isOpen = !self.dateValue.isOpen;
        }
      };
      self.timeValue = {
        time: $filter('limaDateTime')(paramDate1, paramDate1)
      };
      self.endDateValue = {
        date: paramDate2,
        isOpen: false,
        openCalender: function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          self.endDateValue.isOpen = !self.endDateValue.isOpen;
        }
      };
      self.endTimeValue = {
        time: $filter('limaDateTime')(paramDate2, paramDate2)
      };
      function onChange() {
        self.changed = true; self.valid = self.isValid();
      }
      $scope.$watch(function () { return self.numberValue; }, onChange);
      $scope.$watch(function () { return self.stringValue; }, onChange);
      $scope.$watch(function () { return self.dayOfWeekValue; }, onChange);
      $scope.$watch(function () { return self.dateValue.date; }, onChange);
      $scope.$watch(function () { return self.timeValue.time; }, onChange);
      $scope.$watch(function () { return self.endDateValue.date; }, onChange);
      $scope.$watch(function () { return self.endTimeValue.time; }, onChange);
      // if (!_.isUndefined(value)) {
      // 	self.setValue(value);
      // }
    };
    NewValue.prototype.isValid = function () {
      switch (this.type) {
     case '-Number':
       return stringIsNumber(this.numberValue);
      case '-String':
         return this.stringValue ? true : false;
      case '-DayOfWeek':
        return this.dayOfWeekValue.length === 7 ? true : false;
      case '-Date':
        return angular.isDate(this.dateValue.date) ? true : false;
      case '-Period':
        if (!angular.isDate(this.dateValue.date) || !angular.isDate(this.endDateValue.date))
          return false;
        var dateValue = $filter('limaDateTime')(this.dateValue.date, this.timeValue.time);
        var endDateValue = $filter('limaDateTime')(this.endDateValue.date, this.endTimeValue.time);
        return dateValue < endDateValue ? true : false;
      }
      return false;
    };
    NewValue.prototype.updateDisplayValue = function () {
      switch (this.type) {
        case '-Number':
          var val = this.getValue();
          this.displayValue = val.displayValue;
          break;
      }
    }
    NewValue.prototype.updateValue = function () {
      switch (this.type) {
        case '-Number':
          this.numberValue = Number(this.displayValue);
        break;
      }
    }

    NewValue.prototype.setValue = function (value) {
      if (!_.isObject(value)) {
        return;
      }
      switch (this.type) {
      case '-Number':
         this.numberValue = Number(value.displayValue);
         break;
      case '-String':
         this.stringValue = value.displayValue;
         break;
      case '-DayOfWeek':
        this.dayOfWeekValue = value;
        break;
      case '-Date':
        this.dateValue.date = this.timeValue.time = new Date(value.displayValue);
        break;
      case '-Period':
        var splited = value.displayValue.split(' - ');
        this.dateValue.date = this.timeValue.time = new Date(splited[0]);
        this.endDateValue.date = this.endTimeValue.time = new Date(splited[1]);
        break;
      }
      var self = this;
      $timeout(function () { self.changed = false; }, 0);
    };
    NewValue.prototype.getValue = function () {
      var value = {};
      switch (this.type) {
      case '-Number':
         if (!stringIsNumber(this.numberValue))
           return $translate.instant('CONTENT.ATTRIBUTE.INVAID_INPUT_VALUE');
         value.numberValue = Number(this.numberValue);
         value.displayValue =  ('0' + this.numberValue).slice(-2);
         break;
      case '-String':
         value.stringValue = value.displayValue = this.stringValue || '';
         break;
      case '-DayOfWeek':
        value.dayOfWeekValue = this.dayOfWeekValue;
        break;
      case '-Date':
        if (!angular.isDate(this.dateValue.date))
          return $translate.instant('CONTENT.ATTRIBUTE.INVAID_INPUT_VALUE');
        value.dateValue = $filter('limaDateTime')(this.dateValue.date, this.timeValue.time);
        value.displayValue = $filter('date')(value.dateValue);
        break;
      case '-Period':
        if (!angular.isDate(this.dateValue.date) || !angular.isDate(this.endDateValue.date))
          return $translate.instant('CONTENT.ATTRIBUTE.INVAID_INPUT_VALUE');
        value.dateValue = $filter('limaDateTime')(this.dateValue.date, this.timeValue.time);
        value.endDateValue = $filter('limaDateTime')(this.endDateValue.date, this.endTimeValue.time);
        if (value.dateValue >= value.endDateValue)
          return $translate.instant('CONTENT.ATTRIBUTE.START_DATE_MUST_BE_BEFORE_END_DATE');
        value.displayValue = $filter('date')(value.dateValue, 'shortTime') + ' - ' + $filter('date')(value.endDateValue, 'shortTime');
        break;
      }
      return value;
    };
    return NewValue;
  })();



  $scope.selectSchedule = {
    type: '-Date',
    dayOfWeek:new NewValue('-DayOfWeek'),
    datecalender: new NewValue('-Date'),
    //dateperiod: new NewValue('-Period'),
    periodDateTimeHour: [ new NewValue('-Number') ,new NewValue('-Number') ],
    periodDateTimeMin: [ new NewValue('-Number') ,new NewValue('-Number') ],
    duration:0
  };


  $scope.incrementHours = function(index) {
    if ($scope.selectSchedule.periodDateTimeHour[index].numberValue >= 47) {
      return;
    }
    $scope.selectSchedule.periodDateTimeHour[index].numberValue++;
    $scope.selectSchedule.periodDateTimeHour[index].updateDisplayValue();
    $scope.checkHours(index);
  }
  $scope.decrementHours = function(index) {
    if ($scope.selectSchedule.periodDateTimeHour[index].numberValue <= 0) {
      return;
    }
    $scope.selectSchedule.periodDateTimeHour[index].numberValue--;
    $scope.selectSchedule.periodDateTimeHour[index].updateDisplayValue();
    $scope.checkHours(index);
  }
  $scope.incrementMinutes = function(index) {
    if ($scope.selectSchedule.periodDateTimeMin[index].numberValue >= 59) {
      $scope.selectSchedule.periodDateTimeMin[index].numberValue=0;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
      $scope.incrementHours(index);
    } else {
      $scope.selectSchedule.periodDateTimeMin[index].numberValue++;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
    }
    $scope.updateHours();
  }
  $scope.decrementMinutes = function(index) {
    if (!$scope.selectSchedule.periodDateTimeMin[index].numberValue){
      $scope.selectSchedule.periodDateTimeMin[index].numberValue = 59;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
      $scope.decrementHours(index);
    } else {
      $scope.selectSchedule.periodDateTimeMin[index].numberValue--;
      $scope.selectSchedule.periodDateTimeMin[index].updateDisplayValue();
    }
    $scope.updateHours();
  }

  $scope.getplaylistPreview = function(id) {
    return "preview/playlist/html/" + id + "/playlist.html";
  }

  var trustSrc = function(src) {
    return $sce.trustAsResourceUrl(src);
  }

  const getLayoutInfo = () => {
    return new Promise((resolve) => {
      ///api/v1/layouts
      $http.get('/api/v1/layouts').
      success(function(datas) {
        console.log($scope)
        const layouts = datas.layouts;
        const findLayout = layouts.filter((layout) => layout.layoutId === $scope.selectedRow.layoutId)
        $scope.layoutInfo = findLayout[0];
        resolve()
      });
    })
  }

  $scope.playlistPreview = function(playlist) {
    getLayoutInfo().then(() => {
      var modalInstance = $modal.open({
        templateUrl: 'app/content/modalPreview.html',
        controller: 'ModalMaterialPreviewCtrl',
        windowClass: 'modal-preview-window',
        backdrop: 'static', keyboard: false,
        size: 'md',
        resolve: {
          LimaModalPreviewData: function() {
            return {
              enableApplyMessage : isEnableApplyMessage(),
              channelsInfo: $scope.channelsInfo,
              devices: $scope.devices,
              deviceGroups: $scope.deviceGroups,
              playlistId: playlist.playlistId,
              playlistName: playlist.playlistName,
              selectTweet : {type:16,url:trustSrc(playlist.url)},
              useLayout: $scope.layoutInfo,
            };
          }
        }


      });

      modalInstance.result.then(function(results) {
        console.log("");
      }, function(results) {
        //
        if (results.event) {
          if (results.event === 'edit') {
            console.log("Edit playlist");
            $timeout(function(){$scope.editPlaylist(playlist.playlistId);},10)
          }
          else if (results.event === 'updateChannel') {
            console.log("playlist to channel");
            $timeout(function(){addToNoSchedule(playlist.playlistId, results.deviceGroupsId, results.deviceId);},10)
          }

        }
      });
    })



  }

  $scope.createPlaylist = function(bUpload) {
    if ($scope.playlists.length >= $scope.maxPlaylistCount) {

      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'),
      html:$translate.instant('PLAYLIST.MESSSAGE_PLAYLIST_COUNT_MAX'),
      modalClass:'modal-danger'});
      return;
    }


    var modalInstance = $modal.open({
      templateUrl: 'app/content/content.html',
      // controller: 'ContentModalPlaylistCtrl',
      controller: 'ContentModalPlaylistRegionCtrl',
      windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
//      size: 'lg',
      resolve: {
        LimaPlaylistModalContentData: function() {
          return {
            playlistId : undefined,
            playlistName : undefined,
            upload : bUpload
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
      $scope.message.CreateSuccess = $translate.instant('PLAYLIST.MESSAGE_SAVED_PLAYLIST');
      TriggerAlertClose();
      $timeout(function(){

        $scope.getLayoutInfo(function() { //リージョンへのコンテンツ登録情報が変わることがあるので
          $scope.playlists = [];
          $scope.getPlaylist({index:0});
        });

      });

    }, function() {
      //
      $timeout(function(){
        $scope.getLayoutInfo(function() { //リージョンへのコンテンツ登録情報が変わることがあるので
          $scope.playlists = [];
          $scope.getPlaylist({playlistId:$scope.selectedRow ? $scope.selectedRow.playlistId : null});
        });
      });
    });
  }
  $scope.message = {};
  $scope.message.CreateSuccess = "";
  $scope.message.CreateUpdateSuccess = "";
  $scope.message.CreateUpdateError = "";
  $scope.message.DiskAlert = "";

  function TriggerAlertClose() {
    window.setTimeout(function () {
        $("#message1").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateSuccess = "";
        });
    }, 3000);
  }

  function TriggerAlertClose2() {
    window.setTimeout(function () {
        $("#message2").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $timeout(function() {
              $scope.message.CreateUpdateSuccess = "";
            })
        });
    }, 3000);
  }

  function TriggerAlertErrorClose() {
    window.setTimeout(function () {
        $("#messageError").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
            $scope.message.CreateUpdateError = "";
        });
    }, 3000);
  }
  $scope.editPlaylist = function(id) {
    var  selected = {};
    if (id) {
      selected = _.find($scope.playlists, function(tw){return tw.playlistId === id ? true : false;});
    } else {
      selected = _.find($scope.playlists, function(tw){return tw.checked;});
    }
    if (!selected) {
      return;
    }

    var modalInstance = $modal.open({
      templateUrl: 'app/content/content.html',
      // controller: 'ContentModalPlaylistCtrl',
      controller: 'ContentModalPlaylistRegionCtrl',
      windowClass: 'modal-fit',
      backdrop: 'static', keyboard: false,
//      size: 'lg',
      resolve: {
        LimaPlaylistModalContentData: function() {
          return {
            playlistId : selected.playlistId,
            playlistName : selected.playlistName,
            isOldLayout : selected.isOldLayout
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      //close
      console.log(results);
      $timeout(function(){
      $scope.message.CreateSuccess = $translate.instant('PLAYLIST.MESSAGE_SAVED_PLAYLIST');
      TriggerAlertClose();
      })

      {
        var updateData = [];
        m_arrPlaylistByOldLayout = _.filter(m_arrPlaylistByOldLayout , function(ly) {
          return ly !== selected.playlistId;
        })

        updateData.push({name: 'setting.playlist.old.layout', value: JSON.stringify(m_arrPlaylistByOldLayout)});

        Auth.systemProperties({clear:true});

        socket.connect().then(function () {
          return socket.call('v1:system:system-property:update', {data:updateData});
        }).then(function (res) {
        }, function (err) {
        });

      }

      $timeout(function(){
        $scope.getLayoutInfo(function() { //リージョンへのコンテンツ登録情報が変わることがあるので
          $scope.playlists = [];
          $scope.getPlaylist({playlistId:selected.playlistId});
        });
      });

    }, function() {
      //dismiss
      $timeout(function(){
        $scope.getLayoutInfo(function() { //リージョンへのコンテンツ登録情報が変わることがあるので
          $scope.playlists = [];
          $scope.getPlaylist({playlistId:selected.playlistId});
        });
      });
    });
  }

  $scope.onResize = (function(previesize) {

    //Previewのサイズ
    var footerBar = angular.element('#footerBar')[0].clientHeight;
    var footerMesBar = angular.element('#footerMesBar')[0].clientHeight;
    var title = angular.element('#title')[0].clientHeight + 40;
    var buttonbar = angular.element('#buttonbar')[0].clientHeight;
    if (_.isUndefined(angular.element('#lima-navbar')[0])) {
      //console.log("navbar1");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    if (_.isUndefined(angular.element('#lima-navbar')[0].clientHeight)) {
      //console.log("navbar2");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    var limanavbar = angular.element('#lima-navbar')[0].clientHeight;
    if (limanavbar > 150) { //変に高さがある＝＝まだ描画され切ってない
      //console.log("navbar3");
      $timeout(function(){  //timeoutの中でapplyが起こる
        $scope.onResize();
      },100);
      return;
    }
    var bodyHeight = window.innerHeight;

    var tableHeight = bodyHeight - (footerBar + title + buttonbar + limanavbar + footerMesBar + 20 );
    //console.log("#bodyHeight  " + bodyHeight);
    //console.log("#tableList  " + tableHeight);
    angular.element('#tableList')[0].style.height = tableHeight + 'px';


  });

  function onResizeA() {
    $scope.onResize();
    $scope.$apply();
  }

  angular.element($window).on('resize', onResizeA);
  $timeout(function(){  //timeoutの中でapplyが起こる
//    console.log('resize');
    $scope.onResize();
  });


  $scope.$on('$destroy', function () {
    angular.element($window).off('resize', onResizeA);
  });


  socket.on('lima:device:channel:status:error', function (res) {
    console.log("lima:device:channel:status:error");

    var find = _.find($scope.devices.items , function(dv){
      return dv.deviceId === res.deviceId ? true : false;
    });
    var findPl = _.find($scope.playlists, function(list){
      return list.playlistId === Number(res.playlistId) ? true : false;
    });

    if (find && findPl) {
      $timeout(function() {

      if ($scope.message.CreateUpdateError.length) {
        $scope.message.CreateUpdateError += "\r\n";
      }
      $scope.message.CreateUpdateError += $translate.instant('PLAYLIST.MESSAGE_NOT_TO_REFLECT_PLAYLIST',{name:find.name,playlistName:findPl.playlistName});
      $scope.message.CreateUpdateError += "\r\n";
      if (res.error === 'download_no_chache') {
        $scope.message.CreateUpdateError += $translate.instant('PLAYLIST.MESSAGE_LITTLE_DISK');
      } else {
        $scope.message.CreateUpdateError += $translate.instant('PLAYLIST.ERROR_CODE') + res.error;
      }
      TriggerAlertErrorClose();
      })
    }

  });
  socket.on('lima:device:channel:status:updated', function (res) {
    console.log("lima:device:channel:status:updated");

    if (res) {
      if (res.type === "init") {
        return;
      }
    }
    var find = _.find($scope.devices.items , function(dv){
      return dv.deviceId === res.deviceId ? true : false;
    });
    var findPl = _.find($scope.playlists, function(list){
      return list.playlistId === Number(res.playlistId) ? true : false;
    });

    if (find && findPl) {
      $timeout(function(){
      if ($scope.message.CreateUpdateSuccess.length) {
        $scope.message.CreateUpdateSuccess += "\r\n";
      }
      $scope.message.CreateUpdateSuccess += $translate.instant('PLAYLIST.MESSAGE_REFLECT_PLAYLIST',{name:find.name,playlistName:findPl.playlistName})
      TriggerAlertClose2();
      })
    }

    //デバイス再生状況表示
    $scope.getDevicePlayInfoFromPlaylist();

  });

  socket.on('lima:device:channel:updated', function (res) {
    console.log("lima:device:channel:updated");

    //デバイス再生状況表示
    $scope.getDevicePlayInfoFromPlaylist();

  });


  //登録済みのコンテンツ
  $scope.selectContents = function(typeInfo) {
    var modalInstance = $modal.open({
      templateUrl: 'app/content/content_user.html',
      controller: 'ContentForUserCtrl',
      windowClass: 'modal-content-window',
      backdrop: 'static', keyboard: false,
      windowClass: 'modal-fit',
      resolve: {
        // LimaModalContentData: function() {
        //   return {
        //     type:typeInfo.type,
        //     type2: typeInfo.type2
        //   };
        // }
      }
    });
    modalInstance.result.then(function(results) {
      console.log(results);
    });
  }
}]);

//PlaylistCtrlの子：シンプル版
app.controller('PlaylistSimpleCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$location','$window','$uibModal', 'Modal','$q', '$translate','$filter','$controller',
function ($scope, socket, Auth,$http, $timeout,$location,$window,$modal, Modal, $q, $translate,$filter, $controller) {
  $controller('PlaylistCtrl', {$scope:$scope, socket:socket, Auth:Auth,$http:$http, $timeout:$timeout,$location:$location,$window:$window,$modal:$modal, Modal:Modal, $q:$q,$translate:$translate,$filter:$filter}); //This works

  $scope.bSimple = true;
  //socket.emit('lima:call-request:v1:device:channel',{id:$scope.channelsInfo.select.id});

}]);

//PlaylistCtrlの子：popup版
app.controller('PlaylistPopupCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$location','$window','$uibModal', 'Modal','$q', '$translate','$filter','$uibModalInstance','$controller',
function ($scope, socket, Auth,$http, $timeout,$location,$window,$modal, Modal, $q, $translate,$filter, $modalInstance, $controller) {
  $controller('PlaylistCtrl', {$scope:$scope, socket:socket, Auth:Auth,$http:$http, $timeout:$timeout,$location:$location,$window:$window,$modal:$modal, Modal:Modal, $q:$q,$translate:$translate,$filter:$filter}); //This works

  $scope.bPopup = true;
  $scope.setting.enableSchedule = true;

  $scope.closeCancel = function() {
    $modalInstance.dismiss('cancel');
  }

}]);

