'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('modalMaterialPreview', {
    url: '/content/modalPreview',
    templateUrl: 'app/content/modalPreview.html',
    controller: 'ModalMaterialPreviewCtrl',
    title: 'Top',
    authenticate: true
  });
}]);
