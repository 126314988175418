'use strict';

var app = angular.module('limaApp');

app.controller('SettingsCompanyCtrl', ['$scope', '$translate', '$location', 'Auth', '$http', '$timeout', '$window', '$cookies', 'LimaRedirect', 'LimaCreditCardInfo','Modal',
function ($scope, $translate, $location,  Auth, $http, $timeout, $window, $cookies,LimaRedirect, LimaCreditCardInfo, Modal ) {
  $scope.isAuto = Auth.isAdmin()  //pugでcreditcardボタンの有効無効を行うため

  // IE対策（クレジット処理をさせない）
  $scope.warningMessageForIE = "";
  if( LimaCreditCardInfo.isIE() ) {
    $scope.isAuto = false;
    $scope.warningMessageForIE = $translate.instant('COMPANY.CREDITCARD.WARN_FOR_IE');
  }
  // IE対策（クレジット処理をさせない）

  $scope.errors = {};
  (function (help) {
    switch (help) {
    case 'Password has expired': $translate('SETTINGS.HELP.PASSWORD_EXPIRED').then(function (res) { $scope.help = res; }); break;
    case 'Please login':
    case 'Anonymous login failed':
    case 'Failed to get anonymous login params':
    case 'Login session has expired':
    case 'Forbidden': break;
    default: $scope.help = help; break;
    }
  })(LimaRedirect.help);

  $window.document.title  = $translate.instant('NAVIGATION.COMPANY_INFO');

  $scope.optionService = [];

  /* クレジットカード情報 */
  $scope.creditCard = null;
  $scope.registServerUrl = null;


  $scope.optionService = [];  //limaの持つオプションリスト
  $scope.package = {packageType:0};
  $http.get('/api/v1/option-service').
  success(function(datas) {
    $timeout(function(){
      $scope.optionService = datas;
    });
  });

  $http.get('/api/v1/users/me').
  success(function(datas) {
    $scope.userinfo=datas;
    $http.get('/api/v1/company/' + $scope.userinfo.companyId).
    success(function(datasC) {
      $scope.user = datasC;
      /* クレジットカード情報取得 */
      $scope.updateCardInfo(function(err) {
        if (err) {

        }
      });
      $http.get('/api/v1/option-service/package/' + $scope.userinfo.companyId).
      success(function(resPackage) {
          if (resPackage && resPackage.length) {$scope.package = resPackage[0];}
          $http.get('/api/v1/option-service/' + $scope.userinfo.companyId).
          success(function(datas) {
            $scope.optionContractionInfo = datas; //契約情報
            //ソートのついでに名前も取得
            $scope.optionContractionInfo = _.sortBy($scope.optionContractionInfo, function(oc) {
              var option = $scope.getOption(oc.optionId);
              oc.name = option.name;
              return oc.optionId;
            });

            if($scope.package.packageType === 1) {
              //表示したくない基本プラン内容は削除
              $scope.optionContractionInfo = _.filter($scope.optionContractionInfo, function(oc) {
                return oc.perDevice !== 1;
              });
            }
          });
        });
    });
  });
  $scope.getOption = function(optionId){
    var find = _.find($scope.optionService, function(sc){
      return sc.optionServiceId === optionId ? true : false;
    });
    if (find) {
      return find;
    }
    return null;
  }

  $scope.getCreditCardInfoString = function() {
    const info = LimaCreditCardInfo.getInfoString($scope.creditCard)
    return info.message;
  }

  $scope.changePassword = function(form) {
    $scope.submitted = true;
    if(form.$valid && $scope.user.newPassword === $scope.user.retypePassword) {
      Auth.changePassword($scope.user.oldPassword, $scope.user.newPassword).then(function (res) {
        if (!LimaRedirect.back()) {
          $scope.message = $translate.instant('SETTINGS.MSG_PASSWORD_SUCCESS');
        }
      }, function (err) {
        if (err.data && err.data.message === 'Old password is invalid') {
          form.password.$setValidity('mongoose', false);
          $scope.errors.other = $translate.instant('SETTINGS.WARN_PASSWORD_INCORRECT');
          $scope.message = '';
        } else {
          $scope.message = err.data.message || err.data;
        }
      });
    }
  };

  /*
   * registサイトに接続してクレジット支払い関連の情報を取得する
   */
  $scope.checkCreditCardInfo = function() {
    const info = $scope.creditCard;
    if (info === null ) {
      return 0
    }
    if ( info.creditcard === 0 ) {
      return 0; //CreditCartd支払いではない
    }
    else if ( info.current_status === 0 ) {//未決済
      return 1; //CreditCartd支払い　未決済
    }
    else {
      if ( info.errorcode.length ) {
        return 3; //CreditCartd支払い　決済済み
      }
      else {
        return 2; //CreditCartd支払い　決済済み
      }
    }
  }

  $scope.updateCardInfo = function(callback) {
    LimaCreditCardInfo.getCreditCardInfo(function(err, info, registServerUrl, companyId ) {
      if ( err)  {
        callback(err);
      }
      else {
        $timeout(function(){  //timeoutで囲まないとボタンが表示されない
          $scope.registServerUrl = registServerUrl;
          if ( info != null ) {
            $scope.creditCard = info;
            if (info.disabled ) {
              $scope.isAuto = false;
            }
          }
          callback(null)
        })
      }
    });
  }

  /*
   * オプションの変更処理
   */
  $scope.gotoChangeOption = function() {
    $scope.updateCardInfo(function(err) {
      if ( err || $scope.creditCard === null ) {
        return;
      }
      if ( $scope.creditCard.active_states === false ) {
        Modal.confirm.message({
          title:$translate.instant('COMMON.CONFIRM'),
          html:$translate.instant('COMPANY.CREDITCARD.WAIT_UPDATE'),
          modalClass:'modal-warning'
        });
        return;
      }
      var message = "";
      if ( $scope.checkCreditCardInfo() === 3 ) {
        message= $translate.instant('COMPANY.CREDITCARD.CARDCHANGE_CONFIRM')
      }
      else {
        message= $translate.instant('COMPANY.CREDITCARD.SUSPEND_CONFIRM')
      }
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: message,
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (yes) {
          LimaCreditCardInfo.jumpToRegistSite($scope.registServerUrl, $scope.userinfo.companyId, 'change');
        }
      });
    });
  }

  /*
   * クレジットカードでの決済処理
   */
  $scope.gotoCredit = function() {
    LimaCreditCardInfo.jumpToRegistSite($scope.registServerUrl, $scope.userinfo.companyId, 'payment');
  }

  /*
   * クレジットカードの変更処理
   */
  $scope.gotoCardChange = function() {
    LimaCreditCardInfo.jumpToRegistSite($scope.registServerUrl, $scope.userinfo.companyId, 'cardchange');
  }

  /*
   * クレジットカードでの決済処理を停止する
   */
  $scope.gotoCreditStop = function() {
    $scope.updateCardInfo(function(err) {
      if ( err || $scope.creditCard === null ) {
        return;
      }
      if ( $scope.creditCard.active_states === false ) {
        Modal.confirm.message({
          title:$translate.instant('COMMON.CONFIRM'),
          html:$translate.instant('COMPANY.CREDITCARD.WAIT_UPDATE'),
          modalClass:'modal-warning'
        });
        return;
      }
      var message = $translate.instant('COMPANY.CREDITCARD.STOP_WITHCARD_CONFIRM');
      if ( $scope.creditCard.current_status === 0 ) { //未決済
        message = $translate.instant('COMPANY.CREDITCARD.STOP_CONFIRM');
      }
      Modal.confirm.yesno({
        title: $translate.instant('COMMON.CONFIRM'),
        html: message,
        btnClassYes: 'btn-danger',
        btnCaptionYes: $translate.instant('COMMON.YES'),
        btnClassNo: 'btn-default',
        btnCaptionNo: $translate.instant('COMMON.NO'),
        modalClass: 'modal-danger'
      }, function (yes) {
        if (yes) {
    　　   LimaCreditCardInfo.jumpToRegistSite($scope.registServerUrl, $scope.userinfo.companyId, 'stop');
        }
      });
    });
  }

}]);
