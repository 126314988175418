'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('newlayout', {
    url: '/layoutnew',
    templateUrl: 'app/layout/newlayout.html',
    controller: 'NewLayoutCtrl',
    title: 'レイアウト作成',
    authenticate: true
  });
}]);
