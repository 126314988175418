'use strict';

var app = angular.module('limaApp');

app.controller('AddChannelCtrl', ['$scope', 'socket', 'Auth', '$http', '$timeout','$location','Modal','$window','$q', '$translate','$uibModalInstance','LimaModalAddChannel',
function ($scope, socket, Auth,$http, $timeout,$location,Modal, $window, $q, $translate, $modalInstance, LimaModalAddChannel) {

  $scope.newChannel = {};
  $scope.newChannel.name = LimaModalAddChannel.name;

  $scope.apply = function() {
    if (!$scope.newChannel.name) {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"名前が設定されていません", modalClass:'modal-warning'});
      return;
    }
    if ($scope.newChannel.name.length > 0) {
      $modalInstance.close({name:$scope.newChannel.name});
    } else {
      Modal.confirm.message({title:$translate.instant('COMMON.CONFIRM'), html:"名前が設定されていません", modalClass:'modal-warning'});
    }
//    $modalInstance.dismiss('cancel');

  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }
}]);


