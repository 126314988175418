'use strict';

var app = angular.module('limaApp');

app.controller('SystemCtrl', ['$scope', '$translate', '$timeout', '$localStorage', 'Modal', 'Auth', 'socket',
function ($scope, $translate, $timeout, $localStorage, Modal, Auth, socket) {
  $scope.storage = (function () {
    var key = 'limaApp:SystemCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};
    var store = function () {
      $localStorage[key] = storage;
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();

  $translate('SYSTEM.MESSAGE_DEFAULT').then(function (s) { $scope.message = s; });

  $scope.settings = {};
  $scope.msg = {success:null, error:null};
  Auth.systemProperties().then(function (data) {
    $scope.settings.brandText = data['setting.brand.text'] || '';
    $scope.settings.styleId = data['setting.style.id'] || '';
  });

  $scope.updateSetting = function() {
    Modal.confirm.yesno({
      html: _.escape($translate.instant('SYSTEM.SETTING.SUBMIT_CONFIRM_MESSAGE')),
      trustHtml: true
    }, function (yes) {
      if (!yes) return;
      var updateData = [];
      $scope.settings.styleId = $scope.style.id;
      updateData.push({name: 'setting.brand.text', value: $scope.settings.brandText + ''});
      updateData.push({name: 'setting.style.id', value: $scope.settings.styleId + ''});

      Auth.systemProperties({clear:true});

      socket.connect().then(function () {
        return socket.call('v1:system:system-property:update', {data:updateData});
      }).then(function (res) {
        $scope.style.selectedId = $scope.settings.styleId;
        $scope.msg.success = $translate.instant('SYSTEM.SETTING.UPDATED');
        $scope.msg.error = null;
      }, function (err) {
        $scope.msg.success = null;
        $scope.msg.error = err;
      });
    });
  };

  $scope.style = (function () {
    var style = {enabled:false};
    var $styles = angular.element('.lima-bootstrap-style');
    if (!$styles.length) return style;
    function applyStyle(styleId) {
      if (!styleId) return;
      styleId = '#' + styleId;
      if (!$styles.filter(styleId).length) return;
      $styles.filter(styleId).prop('disabled', false);// .attr('rel', 'stylesheet');
      $styles.not(styleId).prop('disabled', true);// .attr('rel', 'alternate stylesheet');
    }
    $scope.$watch('style.id', function () {
      applyStyle(style.id);
    });
    $scope.$on('$destroy', function () {
      applyStyle(style.selectedId);
    });
    var enabled = $styles.filter(":enabled");
    if (enabled.length) {
      style.selectedId = style.id = enabled[0].id;
    }
    style.enabled = true;
    return style;
  })();

}]);
